import React from 'react'
import * as _ from 'ramda'
import Donut from './Donut'
import Ul from './Ul'
import Li from './Li'
import Heading from './Heading'


RADIUS = 30
WIDTH = HEIGHT = 2 * RADIUS
INNER_R = 20


export default TeamCultureProfileElement = ({element, leadershipPercentageDistribution, name, excels, struggles}) ->
  React.createElement("section", {"className": "TeamReport__teamCultureProfileElement"},
    React.createElement("div", {"className": "TeamReport__teamCultureProfileElementHeading"},
      React.createElement(Donut, { \
        "id": "TeamReport__teamCultureDonut",  \
        "data": (({label: k, value: v} for k, v of leadershipPercentageDistribution)),  \
        "colors": ((driver) -> if driver == element then driver else 'unused'),  \
        "colorAccessor": (_.prop 'label'),  \
        "width": (WIDTH),  \
        "height": (HEIGHT),  \
        "radius": (RADIUS),  \
        "innerRadius": (INNER_R)
      }),
      React.createElement("h1", {"className": "TeamReport__teamCultureProfileElementName TeamReport__teamCultureProfileElementName_#{element}"},
        (element[0]?.toUpperCase() + element.slice(1)?.toLowerCase()), " — ", (leadershipPercentageDistribution[element]), """%
""")
    ),

    React.createElement("div", {"className": "TeamReport__teamCultureProfileElementBlocks"},
      React.createElement("section", {"className": "TeamReport__teamCultureProfileElementBlock"},
        React.createElement(Heading, {"size": "sm", "className": "TeamReport__teamCultureProfileElementBlockHeading"}, """
          Where we excel
"""),
        React.createElement(Ul, {"size": "sm"},
          (for excel, i in excels
            React.createElement(Li, {"key": (i)}, (excel))
          )
        )
      ),

      React.createElement("section", {"className": "TeamReport__teamCultureProfileElementBlock"},
        React.createElement(Heading, {"size": "sm", "className": "TeamReport__teamCultureProfileElementBlockHeading"}, """
          Where we struggle
"""),
        React.createElement(Ul, {"size": "sm"},
          (for struggle, i in struggles
            React.createElement(Li, {"key": (i)}, (struggle))
          )
        )
      )
    )
  )
