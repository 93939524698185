import React from 'react'
import * as _ from 'ramda'
import {withRouter} from 'react-router'
import {composeComponent, addStatesWithHandlers} from 'utils/react-tools'
import * as ME from 'api/metrics'
import {lifecycle} from 'recompose'
import Loader from 'theme/Loader'
import DownloadButton from 'reports/DownloadReportButton'
import {fetchUser} from 'auth/requests'
import convertData from 'reports/convertData'
import {fetchTeamReport, getIndividualReport} from 'reports/requests'
import ShortReport from 'reports/ShortReport'
import LongReport from 'reports/LongReport'

import './AllIndividualInTeamReport.sass'

export default composeComponent 'AllInTeamReport',
  withRouter
  addStatesWithHandlers
    loading: false
    user: null
    reports: []
    team: null

  lifecycle
    componentDidMount: ->
      {match: {params}, setUser, t, setLoading, setTeam, setReports} = @props

      setLoading(true);

      ME.reportStateChange('All Individuals In Team Report: loading');

      Promise.all([
        fetchUser(),
        fetchTeamReport(params.slug),
      ]).then(
        ([user, team]) ->
          setUser(user);
          setTeam(team);
          Promise.all(team.members.map((member) => getIndividualReport(member.slug)))
          .then (data) -> data.filter (d) -> Object.keys(d).length isnt 0
          .then(
            (data) ->
              setReports(data);
              ME.reportStateChange('All Individuals In Team Report: loaded');
              setLoading(false);
            , (error) ->
              ME.reportIntermediateError('All Individuals In Team Report: loading error', error);
              setLoading(false);
          )
      , (error) ->
        ME.reportIntermediateError('All Individuals In Team Report: loading error', error);
        setLoading(false);
      )

  ({reports, conversation, loading, team, t}) ->
    unless reports
      return null

    React.createElement("section", {"className": "AllIndividualInTeamReport"},
      React.createElement(Loader, {"loading": (loading), "color": "bg"}),
      React.createElement(DownloadButton, {"name": "AllInTeam_#{team?.name}"}),

      React.createElement("div", {"id": "AllIndividualInTeamReportContent"},
        (reports.sort( (a,b) -> if a.slug < b.slug then -1 else 1).map (r, i) ->
          report = convertData(t).IndividualReport(r)
          org = report.orgs[0]

          if org?.reportSettings?.shortReportEnabled and not conversation
            React.createElement(ShortReport, {"key": (i), "report": (report), "noButton": (true), "user": (report)})
          else
            React.createElement(LongReport, {"key": (i), "report": (report), "conversation": (conversation), "noButton": (true), "user": (report)})
        )
      )
    )
