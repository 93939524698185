import React from 'react'

const PurposeOrganization = ({user, t, setFocusRef}) => {
  const contentItems = [1, 2, 3].map(i => ({ subtitle: t.find(`assessment.page.purpose_org.subtitle${i}`), text: t.find(`assessment.page.purpose_org.text${i}`)}));

  return (
    <section className="Assessment__org">
      <h2 tabIndex="-1" ref={setFocusRef} className="Assessment__title Assessment__orgTitle">{`${t.find('assessment.page.purpose_org.title')}`}</h2>
      <img src={user && user.org && user.org.smallLogoUrl} className="Assessment__orgLogo" alt={`${user ? user.organization.name : ''} Logo`}/>
      <div className="Assessment__orgContent">
        {contentItems.map((item, i) => 
          <React.Fragment key={i}>
            <p className="Assessment__orgContentItem__title">{item.subtitle}</p>
            <p className="Assessment__orgContentItem__text">{item.text}</p>
          </React.Fragment>
        )}
      </div>
    </section>
  )
};

export default PurposeOrganization;
