`/** @jsx R.DOM */`
import R from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import t from 'translations'

export default ({className, title, items, agendaReset, t}) ->
  R.createElement("div", {"className": (cx "ShortReport__agenda", className)},
    (if agendaReset
      R.createElement("div", {"className": "ShortReport__agendaLogo"})
    ),
    R.createElement("div", {"className": "ShortReport__agendaContent"},
      R.createElement("h1", {"className": "ShortReport__agendaTitle"}, (title)),
      (items?.map (i) ->
        R.createElement("div", {"key": (i)},
          R.createElement("h2", {"className": "ShortReport__agendaItem ShortReport__agendaResetItem"}, (t.find "report.shortReport.info.agenda.items.#{i}.title"), ":")
        )
      )
    )
  )
