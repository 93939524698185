import r from 'r-dom'
import T from 'tcomb'
import * as _ from 'ramda'
import cx from 'classnames'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import {withState} from 'recompose'
import VideoModal from 'theme/VideoModal'
import * as DRIVER_ICONS from 'theme/drivers'
import {IMG_AARON, URL_ARCHETYPES} from 'theme/assets/assets'
import PurposeOrganization from './PurposeOrganization'

# import s from './Assessment.sass'

DRIVERS_MAP =
  who: 'Impact'
  why: 'Values'
  how: 'Craft'

DRIVERS =
  who: [
    'Individual'
    'Organization'
    'Society'
  ]
  why: [
    'Karma'
    'Harmony'
  ]
  how: [
    'Community'
    'Human'
    'Knowledge'
    'Structure'
  ]

DRIVER_STEP_NUMERAL =
  who: 'first'
  why: 'second'
  how: 'final'

DIMENSION_MAP =
  'Individual': 'assessment.page.driver.title.individual'
  'Organization': 'assessment.page.driver.title.organization'
  'Society': 'assessment.page.driver.title.society'
  'Karma': 'assessment.page.driver.title.karma'
  'Harmony': 'assessment.page.driver.title.harmony'
  'Community': 'assessment.page.driver.title.community'
  'Human': 'assessment.page.driver.title.human'
  'Knowledge': 'assessment.page.driver.title.knowledge'
  'Structure': 'assessment.page.driver.title.structue'

DriversTitle =
  'who': 'assessment.page.driver.title1'
  'why': 'assessment.page.driver.title2'
  'how': 'assessment.page.driver.title3'

DriversContent =
  'Individual': 'assessment.page.driver.result.individual'
  'Organization': 'assessment.page.driver.result.organization'
  'Society': 'assessment.page.driver.result.society'
  'Karma': 'assessment.page.driver.result.karma'
  'Harmony': 'assessment.page.driver.result.harmony'
  'Knowledge': 'assessment.page.driver.result.knowledge'
  'Structure': 'assessment.page.driver.result.structure'
  'Human': 'assessment.page.driver.result.human'
  'Community': 'assessment.page.driver.result.community'

PurposeType = composeComponent 'Assessment.PurposeType', ({user, t, setFocusRef}) ->
  archetype = t.find "pattern.archetypes.#{user.patternString}.title"
  r.section {}, [
    r.div tabIndex: "-1", ref: setFocusRef, className: "margin-bottom-5 Assessment__title", t.find 'assessment.page.purpose.title'
    # TODO (in future) i18n for images
    r.img className: "Assessment__purposeTitle", src: "#{URL_ARCHETYPES}/#{archetype}.png", alt: archetype
    r.p className: 'margin-top-5 font-w-regular', t.find('assessment.page.purpose.description') + ' ' + archetype + '.'
  ]

Driver = composeComponent 'Assessment.Driver', ({user, question, t, setFocusRef}) ->
  driverName = user?.pattern[question.id]
  dimensions = DRIVERS[question.id]
  activeDriver = question.id

  r.section {}, [
    r.div tabIndex: "-1", ref: setFocusRef, className: cx("Assessment__question", 'margin-bottom-5'), t.find DriversTitle[question.id]
    r.div className: "Assessment__drivers", role: "button", tabIndex: "0", "aria-label": "Your #{DRIVER_STEP_NUMERAL[question.id]} Purpose Driver is #{driverName}", [
      dimensions.map (dimension) ->
        r.div
          key: dimension
          className: cx "Assessment__dimension", (if driverName is dimension then activeDriver), active: driverName is dimension
          [
            r.img src: DRIVER_ICONS[dimension], alt: ""
            r.p {}, t.find DIMENSION_MAP[dimension]
          ]
    ]
    r.p
      className: cx "Assessment__desc", activeDriver, 'font-w-regular'
      t.find DriversContent[driverName]
  ]

Statement = composeComponent 'Assessment.PurposeStatement', ({user, t, setFocusRef}) ->
  archetype = t.find "pattern.archetypes.#{user.patternString}.title"

  r.section className: "Assessment__purposeStatement", [
    r.div tabIndex: "-1", ref: setFocusRef, className: cx('margin-bottom-3 text-left', "Assessment__title"), t.find 'assessment.page.statement.title'
    r.p className: 'font-w-regular margin-bottom-3 text-left',
      t.find('assessment.page.statement.description') + " #{archetype}:"
    r.div {}, [
      r.div
        className: "Assessment__row"
        r.h3 {}, t.find 'assessment.page.statement.characteristic.start'
      r.div
        className: "Assessment__row who"
        [
          r.h3 {},
            t.find "pattern.archetypes.#{user.patternString}.imperative.who"
          r.h5 {},
            t.find "pattern.drivers.who.#{user.pattern.who}.title"
        ]
      r.div
        className: "Assessment__row why"
        [
          r.h3 {},
            t.find "pattern.archetypes.#{user.patternString}.imperative.why"
          r.h5 {},
            t.find "pattern.drivers.why.#{user.pattern.why}.title"
        ]
      r.div
        className: "Assessment__row how"
        [
          r.h3 {},
            t.find "pattern.archetypes.#{user.patternString}.imperative.how"
          r.h5 {},
            t.find "pattern.drivers.how.#{user.pattern.how}.title"
        ]
    ]
    r.p className: 'font-w-regular margin-top-3 text-left', t.find 'assessment.page.statement.result'
  ]

Video = composeComponent 'Assessment.Video',
  withState 'videoModalVisible', 'toggleVideoModal', false
  ({user, videoModalVisible, toggleVideoModal, videoThumbnail, t, setFocusRef}) ->
    r.section {}, [
      r.div className: "Assessment__videoHeader", [
        r.img src: IMG_AARON, alt: ""
        r.h3 {tabIndex: "-1", ref: setFocusRef},
          "#{user?.firstName}, #{t.find 'assessment.page.video.description'}"
      ]
      r.div className: "Assessment__video", [
        r.i
          className: "Assessment__videoArrow fas fa-caret-right"
        r.div
          className: "Assessment__videoMask"
          onClick: -> toggleVideoModal true
        r.img
          id: 'Assessment__readyImage'
          src: videoThumbnail?.image
          alt: ""
      ]
      if videoModalVisible
        r VideoModal,
          id: videoThumbnail?.id
          close: -> toggleVideoModal false
    ]

export default composeComponent 'Assessment.Result',
  setPropTypes
    user: T.Object

  ({question, user, videoThumbnail, t, setFocusRef}) ->
    if !user
      return r.div {}

    r.div
      className: "Assessment__item"
      switch question.id
        when 'purposeType'
          r PurposeType, {user, t, setFocusRef}
        when 'who', 'why', 'how'
          r Driver, {user, question, t, setFocusRef}
        when 'purposeStatement'
          r Statement, {user, t, setFocusRef}
        when 'purposeOrg'
          r PurposeOrganization, {user, t, setFocusRef}
        when 'video'
          r Video, {user, videoThumbnail, t, setFocusRef}
        else r.div {}
