import React from 'react';
import cx from 'classnames';
import * as emojis from 'theme/emojis';

const RIG = {
  relationship: 'Relationships',
  impact: 'Impact',
  growth: 'Growth',
}

const RIG_OPTIONS = [
  'Frustrated',
  'Unhappy',
  'Meh',
  'Happy',
  'PumpedUp',
]

const LABELS_MAP = {
  'Frustrated': 'assessment.rig.answer1',
  'Unhappy': 'assessment.rig.answer2',
  'Meh': 'assessment.rig.answer3',
  'Happy': 'assessment.rig.answer4',
  'PumpedUp': 'assessment.rig.answer5'
}

const RIGScores = (props) => {
  const {conversation, t, rigScore, activeColor} = props;
  const partnerName = conversation.partner && conversation.partner.member.firstName;

  const note = `This is a summary of your pre-conversation check-in response from today. This is for your personal reflection and not visible to ${partnerName}.`;
  const rigScoreMapped = (rigScore && rigScore[0] && rigScore[0].scores) || {};

  const scores = Object.keys(RIG).map((key) => {
    const score = rigScoreMapped[key] && rigScoreMapped[key].value;
    return {
      key,
      label: RIG[key],
      icon: emojis[RIG_OPTIONS[score]],
      value: t.find(LABELS_MAP[RIG_OPTIONS[score]])
    }
  });

  return (
    <div className="Questions__questionRIGScores">
      <div className="Questions__carouselRig">
        {scores.map((dim) => (
          <div className="Questions__carouselRigItem" key={dim.label}>
            <span className={cx("Questions__carouselRigLabel Questions__carouselTitle", activeColor)}>{dim.label}</span>
            <img src={dim.icon} alt="" />
            <span className="Questions__carouselRigValue">{dim.value}</span>
          </div>
        ))}
      </div>
      <span className="Questions__carouselInfo">
        {note}
      </span>
    </div>
  )
}

export default RIGScores;
