import {composeComponent} from 'utils/react-tools'
import {withState, lifecycle} from 'recompose'
import cx from 'classnames'
import r from 'r-dom'
import * as d3 from 'd3'

import './PercentChart.sass'


margin =
  top: 0
  right: 0
  bottom: 0
  left: 0
width = 400 - margin.left - margin.right
height = 400 - margin.top - margin.bottom
radius = Math.min(width, height) / 2

createChart = (rootRef, data, className) ->

  svg = d3.select rootRef
  .append 'div'
  .classed 'svg-container', true
  .append 'svg'
  .attr "preserveAspectRatio", "xMinYMin meet"
  .attr "viewBox", "0 0 #{width + margin.left + margin.right} #{height + margin.top + margin.bottom + 10}"
  .classed "svg-content-responsive", true
  .append 'g'
  .attr 'transform', "translate(#{width / 2}, #{height / 2})"

  drawPath(data, rootRef)


drawPath = (data, rootRef) ->
  pie = d3.pie()
    .sort null

  arc = d3.arc()
  .outerRadius radius - 10
  .innerRadius radius - 30

  svg = d3.select rootRef
  .select 'g'

  svg.selectAll '*'
  .remove()

  path = svg.selectAll "path"
  .data pie [data, 100 - data]
  .enter()
  .append 'path'
  .attr 'd', arc
  .attr 'class', (d, i) -> cx "AssessmentChart__path", if i is 0 then "filled"

  text = svg.append "text"
  .text Math.round data
  .attr 'transform', 'translate(0, 45)'
  .attr 'text-anchor', 'middle'
  .attr 'class', "AssessmentChart__value"

  # getting width/height of element
  percentPosition = text.node().getBBox().width + text.node().getBBox().x

  svg.append "text"
  .text '%'
  .attr 'transform', "translate(#{percentPosition}, -15)"
  .attr 'class', "AssessmentChart__percent"



export default composeComponent 'Assessment.PercentChart',
  withState 'rootRef', 'setRef', null

  lifecycle
    shouldComponentUpdate: (props) ->
      props.rootRef isnt @props.rootRef or props.data isnt @props.data

    componentDidUpdate: (prevProps) ->
      if prevProps.rootRef isnt @props.rootRef
        createChart @props.rootRef, @props.data, @props.className
      else
        drawPath @props.data, @props.rootRef

  ({data, setRef, className}) ->
    r.div
      className: cx "AssessmentChart", className
      ref: setRef
      r.div className: "AssessmentChart__value",
        r.div className: "AssessmentChart__valueContent"
