import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Request from './Request'
import Prompt from './Prompt'

const Reset = () => (
  <Switch>
    <Route exact path="/reset/:token" component={Prompt} />
    <Route exact path="/reset" component={Request} />
  </Switch>
);

export default Reset;
