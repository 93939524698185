import r from 'r-dom'
import cx from 'classnames'
import * as d3 from 'd3'
import * as _ from 'ramda'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import {withProps, withHandlers, withState, mapProps} from 'recompose'

import s from './Analytics.sass'

MARGIN =
  top: 30
  right: 0
  bottom: 30
  left: 0


export default composeComponent 'Analytics.BarChart',
  withProps ({data, axes = {x: true, y: true}, maxValue, minValue, width = 300, height = 300, padding = 0.2}) ->
    width = width - MARGIN.left - MARGIN.right
    height = height - MARGIN.top - MARGIN.bottom
    xDomain = _.pluck 'label', data
    minY = d3.min data, (d) -> d.value
    maxY = d3.max data, (d) -> d.value
    yDomain = [
      minValue ? minY
      maxValue ? maxY
    ]

    x = d3.scaleBand()
      .rangeRound [0, width]
      .domain xDomain
      .padding padding
    y = d3.scaleLinear()
      .range [height, 0]
      .domain yDomain

    xAxis = if axes.x then d3.axisBottom(x) else []

    {x, y, xAxis, width, height}

  ({data, x, y, xAxis, width, height, barClass, className, axes = {x: true, y: true}, valueText}) ->
    r.svg
      preserveAspectRatio: 'xMinYMin meet'
      viewBox: "0 0 #{width} #{height}"
      width: width + MARGIN.left + MARGIN.right
      height: height + MARGIN.top + MARGIN.bottom
      className: cx "Analytics__svgContentResponsive", "Analytics__chart", className
      [
        r.g
          transform: "translate(#{MARGIN.left}, #{MARGIN.top})"
          [
            data.map (d, i) ->
              r.g transform: "translate(#{x d.label}, 0)", key: i, [
                r.rect
                  x: 0
                  y: Math.min y(0), y(d.value)
                  width: x.bandwidth()
                  height: Math.abs(y(d.value) - y(0))
                  className: cx "Analytics__rect-#{d.label}", "Analytics__rect", barClass?(d)
                if axes?.x
                  r.g transform: "translate(0, #{height + 20})",
                    r.text
                      x: x.bandwidth() / 2
                      d.label
                r.g transform: "translate(0, #{Math.min(y(0), y(d.value)) - 10})",
                  r.text
                    x: x.bandwidth() / 2
                    valueText?(d.value) ? d.value
              ]
            r.g
              className: "Analytics__axis"
              r.line
                y1: y 0
                y2: y 0
                x2: width
          ]
      ]
