import {URL_JOB_HACKS} from 'theme/assets/assets'

Connect = [
  "#{URL_JOB_HACKS}/job_hack_connect_1.jpg"
  "#{URL_JOB_HACKS}/job_hack_connect_2.jpg"
  "#{URL_JOB_HACKS}/job_hack_connect_3.jpg"
  "#{URL_JOB_HACKS}/job_hack_connect_4.jpg"
  "#{URL_JOB_HACKS}/job_hack_connect_5.jpg"
  "#{URL_JOB_HACKS}/job_hack_connect_6.jpg"
  "#{URL_JOB_HACKS}/job_hack_connect_7.jpg"
]

Do = [
  "#{URL_JOB_HACKS}/job_hack_do_1.jpg"
  "#{URL_JOB_HACKS}/job_hack_do_2.jpg"
  "#{URL_JOB_HACKS}/job_hack_do_3.jpg"
  "#{URL_JOB_HACKS}/job_hack_do_4.jpg"
  "#{URL_JOB_HACKS}/job_hack_do_5.jpg"
  "#{URL_JOB_HACKS}/job_hack_do_6.jpg"
  "#{URL_JOB_HACKS}/job_hack_do_7.jpg"
  "#{URL_JOB_HACKS}/job_hack_do_8.jpg"
]

Read = [
  "#{URL_JOB_HACKS}/job_hack_read_1.jpg"
  "#{URL_JOB_HACKS}/job_hack_read_2.jpg"
  "#{URL_JOB_HACKS}/job_hack_read_3.jpg"
  "#{URL_JOB_HACKS}/job_hack_read_4.jpg"
  "#{URL_JOB_HACKS}/job_hack_read_5.jpg"
  "#{URL_JOB_HACKS}/job_hack_read_6.jpg"
  "#{URL_JOB_HACKS}/job_hack_read_7.jpg"
  "#{URL_JOB_HACKS}/job_hack_read_8.jpg"
]

Think = [
  "#{URL_JOB_HACKS}/job_hack_think_1.jpg"
  "#{URL_JOB_HACKS}/job_hack_think_2.jpg"
  "#{URL_JOB_HACKS}/job_hack_think_3.jpg"
  "#{URL_JOB_HACKS}/job_hack_think_4.jpg"
  "#{URL_JOB_HACKS}/job_hack_think_5.jpg"
  "#{URL_JOB_HACKS}/job_hack_think_6.jpg"
  "#{URL_JOB_HACKS}/job_hack_think_7.jpg"
  "#{URL_JOB_HACKS}/job_hack_think_8.jpg"
]

images = {Connect, Do, Read, Think}

getImage = (microLesson) ->
  imagesForDrtc = images[microLesson.jobHack.drtc]
  unless imagesForDrtc
    return null
  imageNumber = parseInt(microLesson.jobHack.idHash) % imagesForDrtc.length
  imagesForDrtc[imageNumber]

export {getImage}
