import r from 'r-dom'
import T from 'tcomb'
import * as _ from 'ramda'
import cx from 'classnames'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import {mapProps, withProps} from 'recompose'
import RoleDropdown from './RoleDropdown'
import Radio from './Radio'
import RadioMulti from './RadioMulti'
import RadioCustom from './RadioCustom'
import IconsRadio from './IconsRadio'
import Slider from './Slider'

# import './Assessment.sass'

CONTROL_MAP =
  'option-radio': Radio
  'option-radio-custom': RadioCustom
  'option-slider': Slider
  'option-list': RoleDropdown
  'option-dropdown': RoleDropdown
  'option-icons': IconsRadio
  'option-radio-multi': RadioMulti

export default  composeComponent 'Assessment.Question',
  setPropTypes
    question: T.interface
      structure: T.enums.of [
        'option-radio'
        'option-radio-custom'
        'option-slider'
        'option-list'
        'option-dropdown'
        'option-icons'
        'option-radio-multi'
      ]
    answer: T.Any
    error: T.maybe T.String
    onChange: T.Function

  withProps ({t}) ->
    rigQuestionMap:
      relationship: () -> t.find 'assessment.page.2.title'
      impact: () -> t.find 'assessment.page.3.title'
      growth: () -> t.find 'assessment.page.4.title'

  mapProps (props) -> _.evolve(
    question: (q) ->
      return q unless q.section is 'rig'
      _.evolve {message: props.rigQuestionMap[q.id] || _.identity}, q
  )(props)

  ({question, answer, error, onChange, t, setFocusRef}) ->
    errorMessage = if error is 'required' 
      t.find 'assessment.general.error.missing_answer'
    else
      t.find 'assessment.general.error.something_wrong'

    r.div className: "Assessment__item",
      [
        r.header
          className: cx "Assessment__question", 'margin-bottom-5', "Assessment__questionSlider": question.structure is 'option-slider'
          tabIndex: -1
          ref: setFocusRef
          if question.section isnt 'rig'
            if question.message
              t.find question.message
            else
              t.find "surveys.purpose-assessment.questions.#{question.id}"
          else
            t.find(question.message)

        r CONTROL_MAP[question.structure],
          _.merge {onChange, value: answer, question, t, error}, question

        if error
          r.p 
            className: "Assessment__err"
            id: "errorMsg",
            r.span {}, errorMessage
      ]
