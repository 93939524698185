import React from 'react';

const IntersitialDemographic = ({t, setFocusRef}) => (
  <section>
    <header className="Assessment__title" tabIndex="-1" ref={setFocusRef}>
      {t.find('assessment.page.demographic.title')}
    </header>
    <p className="margin-top-5">
      {t.find('assessment.page.demographic.description1')}
    </p>
    <p className="margin-top-5">
      <strong>{t.find('assessment.page.demographic.description2') + ' '}</strong>
      {t.find('assessment.page.demographic.description3')}
    </p>
    <div className="Assessment__img Assessment__img_peer_network"/>
  </section>
);

export default IntersitialDemographic;
