import * as _ from 'ramda'

DRIVERS = ['who', 'why', 'how']

convertData = (translations) ->
  patternString = (user) ->
    if user.pattern?
      "#{user.pattern.who}#{user.pattern.why}#{user.pattern.how}"
    else if user.assessmentResult?
      "#{user.assessmentResult?.drivers?.who.driver}#{user.assessmentResult?.drivers?.why.driver}#{user.assessmentResult?.drivers?.how.driver}"
    else if user.assessment?
      "#{user.assessment?.drivers?.who.driver}#{user.assessment?.drivers?.why.driver}#{user.assessment?.drivers?.how.driver}"
    else ''

  findDominant = (user) ->
    user.pattern?.dominant ? DRIVERS.reduce(
      (res, d) ->
        driver =  user.assessmentResult?.drivers[d]
        if driver?.dominant then driver?.driver else res
      null
    )


  archetype = (user) ->
    pattern = patternString user

    title: translations?.find "pattern.archetypes.#{pattern}.title"
    cta: translations?.find "pattern.archetypes.#{pattern}.description.first"
    description: translations?.find "pattern.archetypes.#{pattern}.description.second"
    moments: translations?.getSplitted "pattern.archetypes.#{pattern}.moments"
    problems: translations?.getSplitted "pattern.archetypes.#{pattern}.problems"
    quote:
      author: translations?.find "pattern.archetypes.#{pattern}.quote.author"
      value: translations?.find "pattern.archetypes.#{pattern}.quote.value"
    volunteerBottomLine: translations?.find "pattern.archetypes.#{pattern}.volunteerBottomLine"
    whatsNext: translations?.getSplitted "pattern.archetypes.#{pattern}.whatsNext"

  imperative = (user) ->
    pattern = patternString user

    prefix:
      my: translations?.find "pattern.imperative-prefix.my"
      your: translations?.find "pattern.imperative-prefix.your"
    who: translations?.find "pattern.archetypes.#{pattern}.imperative.who"
    why: translations?.find "pattern.archetypes.#{pattern}.imperative.why"
    how: translations?.find "pattern.archetypes.#{pattern}.imperative.how"

  drivers = (user) ->
    translateDriver = (driver) ->
      title = user.pattern?[driver] ? user.assessmentResult?.drivers[driver].driver
      dominant = findDominant(user)?.toLowerCase() is driver

      "#{driver}":
        actions: translations?.getSplitted "pattern.drivers.#{driver}.#{title}.actions"
        description: translations?.find "pattern.drivers.#{driver}.#{title}.description.you"
        descriptionCommunity: translations?.find "pattern.drivers.#{driver}.#{title}.description.community"
        dominant: dominant
        explication: translations?.find "pattern.drivers.#{driver}.#{title}.explication"
        keywords: translations?.getSplitted "pattern.drivers.#{driver}.#{title}.words"
        moments: translations?.getSplitted "pattern.drivers.#{driver}.#{title}.moments"
        potentialBiases: translations?.getSplitted "pattern.drivers.#{driver}.#{title}.potentialBiases"
        powerUpBullets: translations?.getSplitted "pattern.drivers.#{driver}.#{title}.powerUpBullets"
        powerUpParagraph: translations?.find "pattern.drivers.#{driver}.#{title}.powerUpParagraph"
        questions: translations?.find "pattern.drivers.#{driver}.#{title}.questions"
        quote:
          author: translations?.find "pattern.drivers.#{driver}.#{title}.quote.author"
          value: translations?.find "pattern.drivers.#{driver}.#{title}.quote.value"
        id: title
        title: translations?.find "pattern.drivers.#{driver}.#{title}.title"
        volunteerParagraph: translations?.getSplitted "pattern.drivers.#{driver}.#{title}.volunteerParagraph"

    DRIVERS.reduce(
      (result, driver) ->
        _.merge result, translateDriver driver
      {}
    )

  meAndColleague = (colleagueDriver, myDriver) ->
    cooperation:
      listening: translations?.find "colleagues.cooperation.listening.#{myDriver}.#{colleagueDriver}"
      speaking: translations?.find "colleagues.cooperation.speaking.#{myDriver}.#{colleagueDriver}"
    leadership:
      colleague: translations?.find "colleagues.leadershipStyle.mine.#{myDriver}"
      me: translations?.find "colleagues.leadershipStyle.colleague.#{colleagueDriver}"
      together: translations?.find "colleagues.leadershipStyle.together.#{myDriver}.#{colleagueDriver}"

  leadershipStyle = (user) ->
    dominant = findDominant(user)?.toLowerCase()

    challenges: translations?.getSplitted "pattern.leadershipStyle.dominantDriver.#{dominant}.challenges"
    name: translations?.find "pattern.leadershipStyle.dominantDriver.#{dominant}.name"
    paragraph: translations?.find "pattern.leadershipStyle.dominantDriver.#{dominant}.paragraph"
    description: translations?.find "pattern.leadershipStyle.dominantDriver.#{dominant}.description"
    strengths: translations?.getSplitted "pattern.leadershipStyle.dominantDriver.#{dominant}.strengths"

  coreBeliefs = (user) ->
    DRIVERS.reduce(
      (result, driver) ->
        title = user.pattern?[driver] ? user.assessmentResult?.drivers?[driver]?.driver ? user.assessment?.drivers?[driver]?.driver
        _.merge result, "#{driver}": translations?.find "pattern.drivers.#{driver}.#{title}.coreBeliefs"
        {}
    )

  rolesIn = (maybeOrg) -> [].concat(maybeOrg?.roles ? {})

  isCpl = (user) ->
    orgs = user.orgs

    # true
    org =
      if orgs?.length > 1
        orgs.find ({code}) -> code != 'imperative'
      else
        orgs?[0]

    (rolesIn org).some ({leader, type}) -> leader || type == 'Leader'

  IndividualReport = (user) ->
    _.merge user,
      archetype: archetype user
      coreBeliefs: coreBeliefs user
      drivers: drivers user
      imperative: imperative user
      leadershipStyle: leadershipStyle user

  User = (user) ->
    org = if user.organization
      _.merge user.organization, user.organizationSettings
    else null

    if user.pattern? or user.assessmentResult? or user.assessment?
      _.merge user,
        archetype: archetype user
        coreBeliefs: coreBeliefs user
        drivers: drivers user
        imperative: imperative user
        leadershipStyle: leadershipStyle user
        isCPL: isCpl user
        org: org
        enabledPeerCoaching: org?.partnerConversationsSettings.enabled
    else
      user

  Individuals = (user) ->
    if user.pattern? or user.assessmentResult? or user.assessment?
      pattern = patternString user

      _.merge user,
        archetype:
          title: translations?.find "pattern.archetypes.#{pattern}.title"
    else
      user

  Colleague = (user) ->
    _.merge user,
      archetype: archetype user
      coreBeliefs: coreBeliefs user
      drivers: drivers user
      imperative: imperative user
      leadershipStyle: leadershipStyle user

  Walkthrough = (w) ->
    _.merge w,
      individual: User w.individual

  Member = (user, myDriver) ->
    driversCat = [
      {
        type: 'Who'
        name: 'impact'
      }
      {
        type: 'Why'
        name: 'values'
      }
      {
        type: 'How'
        name: 'craft'
      }
    ]

    member = _.merge user,
      archetype: archetype user
      drivers: drivers user
      imperative: imperative user
      leadershipStyle: leadershipStyle user
      meAndColleague: meAndColleague driversCat.find((d) -> d.type is user.pattern.dominant).name, driversCat.find((d) -> d.type is myDriver).name

  {
    archetype
    imperative
    drivers
    coreBeliefs
    leadershipStyle
    IndividualReport
    User
    Member
    Colleague
    Walkthrough
    Individuals
  }

export default convertData
