import React from 'react'
import * as _ from 'ramda'
import {withRouter} from 'react-router'
import {composeComponent, addStatesWithHandlers} from 'utils/react-tools'
import * as ME from 'api/metrics'
import {lifecycle, withHandlers} from 'recompose'
import Loader from 'theme/Loader'
import DownloadButton from 'reports/DownloadReportButton'
import {fetchUser} from 'auth/requests'
import convertData from 'reports/convertData'
import {getLanguage, getAvailableLanguages, setLanguage} from 'translations'
import {fetchTeamReport, getIndividualReport, fetchOrganization} from 'reports/requests'
import customGriffith from 'reports/ShortReport/custom-griffith'
import ShortReport from 'reports/ShortReport'
import LongReport from 'reports/LongReport'
import Sample from 'reports/ShortReport/Sample'
import LanguageDropdown from 'theme/LanguageDropdown'

import './PurposeSummarySample.sass'

languageDropdownVisible = window.config.pdf && window.config.pdf.language

DRIVERS = {
  who: [
    "Individual",
    "Organization",
    "Society",
  ],
  why: [
    "Harmony",
    "Karma",
  ]
  how: [
    "Community",
    "Human",
    "Knowledge",
    "Structure",
  ]
}

driversCombination = _.flatten ['Who', 'Why', 'How'].map((dominant) =>
  DRIVERS.who.map((a) =>
    DRIVERS.why.map((b) =>
      DRIVERS.how.map((c) =>  
        {
          who: a,
          why: b,
          how: c,
          dominant: dominant
        }
      )
    )
  )
)

export default composeComponent 'PurposeSummarySample',
  withRouter
  addStatesWithHandlers
    organization: null
    loading: false
    currentLanguage: 'English'
    languageOptions: []

  lifecycle
    componentDidMount: ->
      {match: {params}, setOrganization, t, setLoading, setLanguageOptions} = @props

      t.useUserValues(true);

      setLoading(true);

      ME.reportStateChange('All Individuals In Team Report: loading');
      
      orgReq = fetchOrganization(params.orgSlug)
      .then(
        (org) ->
          setOrganization(org)
          setLoading(false)
        , (error) ->
          setLoading(false);
      )

      isGriffith = params.orgSlug?.includes 'griffith'
      availableLangs = getAvailableLanguages()
      .then (data) ->
        data.map (d) -> _.merge d, {label: d.name, value: d.name}
        .filter (d) ->
          return true unless isGriffith
          customGriffith.languages.includes d.name
      .then(setLanguageOptions)

      Promise.all([
        orgReq,
        availableLangs,
      ]).then(
        () -> setLoading(false),
        () -> setLoading(false)
      )

    componentWillUnmount: ->
      @props.t.useUserValues(false);

  withHandlers
    changeLanguage: ({setLoading, currentLanguage, t, user, setCurrentLanguage}) -> (language) ->
      setLoading(true);

      t.changeLanguage(language, user)

      .then(-> setCurrentLanguage(language))
      .then(
        () => setLoading(false),
        () => setLoading(false)
      )

  ({t, organization, loading, languageOptions, changeLanguage, currentLanguage}) ->
    reports = organization && driversCombination.map((pattern) => {orgs: [organization], pattern})

    unless reports
      return null

    React.createElement("section", {"className": "PurposeSummarySample"},
      React.createElement(Loader, {"loading": (loading), "color": "bg"}),
      React.createElement(DownloadButton, {"name": "PurposeSummary", "onClick": (window.print)}),
      (if (organization.code.includes('griffith') or languageDropdownVisible) and languageOptions?.length > 1
        React.createElement(LanguageDropdown, { \
          "className": "ShortReport__languageDropdown",  \
          "options": (languageOptions),  \
          "onChange": (changeLanguage),  \
          "placeholder": "Change language - #{currentLanguage}"
        })
      ),

      React.createElement("div", {"id": "AllIndividualInTeamReportContent"},
        (reports.map (r, i) ->
          report = convertData(t).IndividualReport(r)
          org = report.orgs[0]

          React.createElement(Sample, {"key": (i), "report": (report), "noButton": (true), "user": (report), "currentLanguage": (currentLanguage), "languageOptions": (languageOptions), "changeLanguage": (changeLanguage)})
        )
      )
    )
