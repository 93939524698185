import React, {useCallback, useEffect, useRef} from 'react';
import cx from 'classnames';

import './Form.sass'

const AutosizeInput = (props) => {
  const {className, value, maxLength, placeholder, onChange, required} = props;

  const inputEl = useRef(null);

  const resize = () => {
    inputEl.current.style.height = 'auto';
    inputEl.current.style.height = inputEl.current.scrollHeight+'px';
  }

  const delayedResize = () => {
    window.setTimeout(resize, 0);
  }

  const changeWithAutosize = useCallback((e) => {
    onChange && onChange(e);
    return e;
  }, [value, onChange, resize])

  useEffect(() => {
    if(required && !value && onChange) {
      onChange('');
    }
    resize();
  }, []);

  useEffect(() => {
    resize();
  }, [value]);

  return (
    <div className={cx("Form__field embed-submit-field", className)}>
      <textarea
        ref={inputEl}
        className="Form__fieldText small autosize"
        rows="1"
        onChange={(e) => changeWithAutosize(e)}
        onCut={delayedResize}
        onPaste={delayedResize}
        onDrop={delayedResize}
        onKeyDown={delayedResize}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    </div>
  )
}

export default AutosizeInput;
