
import React, {useState, useEffect} from 'react';
import {getOneWord,
        updateOneWord}  from 'conversations/requests'
import Markdown from 'theme/Markdown'
import Loader from 'theme/Loader'
import {Text} from 'conversations/Questions/Fields'
import Card from './Card';

const OneWord = (props) => {
  const {user, conversation, t} = props;
  const [loading, setLoading] = useState(false);
  const [oneWord, setOneWord] = useState(null);
  const [editedOneWord, setEditedOneWord] = useState(null);

  const loadOneWord = () => {
    setLoading(true);
    return getOneWord(conversation.id)
    .then((oneWords) => {
      const forUser = oneWords.find((w) => user && w.forMemberId == user.id);
      setOneWord(forUser);
      setLoading(false);
    }, () => setLoading(false));
  };

  const saveOneWord = (data) => {
    if(!conversation || !oneWord || !data || !data.preConvWord || !data.postConvWord)
      return;
    setLoading(true);
    const dto = {
      ...(data.preConvWord && {preConvWord: data.preConvWord}),
      ...(data.postConvWord && {postConvWord: data.postConvWord})
    }
    return updateOneWord(conversation.id, oneWord.id, dto)
    .then(() => {
      loadOneWord()
      .then(() => {
        setEditedOneWord(null);
        setLoading(false);
      })
    }, () => setLoading(false))
  }

  useEffect(() => {
    setLoading(true);
    loadOneWord()
    .then(
      () => setLoading(false),
      () => setLoading(false)
    )
  }, []);

  const editing = editedOneWord != null;

  if(!oneWord) return null;

  const title =
    <h4 className="Conversation__subtitle">
      <span>
        {t.find("In a Word")}
        <span className="Conversation__trademark">&trade;</span>
      </span>
    </h4>

  const titleActions = editing
    ?
      <div className="Conversation__cardContentButtons">
        <button className="btn btn_secondary btn_outlined_bluePurple" onClick={() => setEditedOneWord(null)}>
          {t.find("Cancel Edit")}
        </button>
        <button
          onClick={() => saveOneWord(editedOneWord)}
          className="btn btn_secondary btn_solid_bluePurple"
          disabled={!editedOneWord || !editedOneWord.preConvWord || !editedOneWord.postConvWord}
        >
          {t.find("Save")}
        </button>
      </div>
    :
      <div>
        <button className="btn btn_secondary btn_solid_bluePurple" onClick={() => setEditedOneWord(oneWord)}>
          {t.find("Edit")}
        </button>
      </div>

  return (
    <Card title={title} buttons={titleActions}>
      <Loader loading={loading} />
      <div className="Conversation__question">
        <div className="Conversation__questionContent space-between">
          <div className="Conversation__questionRow col-3">
            <div className="Conversation__questionOneWord">
              <div className="Conversation__questionOneWord-title">
                Before
              </div>
              {editing
              ?
                <Text
                  value={editedOneWord.preConvWord || ''}
                  onChange={(preConvWord) => setEditedOneWord((o) => ({...o, preConvWord}))}
                  placeholder="Type your one word..."
                  maxLength="20"
                  rich={false}
                />
              :
                <div className="Conversation__questionAnswer-field">
                  <Markdown source={oneWord.preConvWord} />
                </div>
              }
            </div>
            <div className="Conversation__questionArrow">
              <span className="fal fa-arrow-alt-right" />
            </div>
            <div className="Conversation__questionOneWord">
              <div className="Conversation__questionOneWord-title">
                After
              </div>
              {editing
              ?
                <Text
                  value={editedOneWord.postConvWord || ''}
                  onChange={(postConvWord) => setEditedOneWord((o) => ({...o, postConvWord}))}
                  placeholder="Type your one word..."
                  maxLength="20"
                  rich={false}
                />
              :
                <div className="Conversation__questionAnswer-field">
                  <Markdown source={oneWord.postConvWord} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default OneWord;
