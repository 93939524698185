import moment from 'moment-timezone';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Datepicker from 'react-datepicker';
import Select, { components } from 'react-select';
import cx from 'classnames';
import { loadTimezones } from 'utils/react-tools';
import { getAllowedTimezones } from '../../utils/timezones';

const TIMEZONES = getAllowedTimezones(loadTimezones());

const DEFAULT_HOURS = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];

const PARTS = ['am', 'pm'];

const DEFAULT_MINUTES = ['00', '15', '30', '45'];

const DEFAULT_TIME_OPTIONS = DEFAULT_HOURS.reduce((result, h) => {
  return [
    ...result,
    ...DEFAULT_MINUTES.map((m) => {
      return `${h}:${m}`;
    }),
  ];
}, []);

const DEFAULT_TIME_OPTIONS_WITH_MERIDIEM = PARTS.reduce(
  (result, a) => [...result, ...DEFAULT_TIME_OPTIONS.map((t) => `${t}${a}`)],
  []
);

export const suggestedDate = (date, firstConversation = false, subtractHour = true) => {
  const subtracted = moment(date)
    .add(2, firstConversation ? 'days' : 'weeks')
    .subtract(subtractHour ? 1 : 0, 'hours');
  if (subtracted.isoWeekday() % 6 == 0 || subtracted.isoWeekday() % 7 == 0) {
    subtracted.add(8 - (subtracted.isoWeekday() % 8), 'days');
  }
  if (subtracted.minutes() >= 45) return moment(subtracted).minutes(60);
  if (subtracted.minutes() >= 15) return moment(subtracted).minutes(30);
  else return moment(subtracted).minutes(0);
};

const Scheduler = (props) => {
  const {
    onChange,
    scheduleDate,
    minDate,
    scheduleTimezone,
    firstConversation = false,
    showTimezonePicker = true,
    showDatepicker = true,
  } = props;

  const initialDate = useMemo(() => {
    if (scheduleDate) {
      return moment(scheduleDate);
    }
    return suggestedDate(moment.tz(scheduleTimezone), firstConversation);
  }, [scheduleDate, scheduleTimezone]);

  const [timezone, setTimezone] = useState(scheduleTimezone || moment.tz.guess());
  const [date, setDate] = useState(() => moment.tz(initialDate, timezone).toDate());
  const [time, setTime] = useState(() => moment.tz(initialDate, timezone).format('h:mma'));
  const [timeOptions, setTimeOptions] = useState(DEFAULT_TIME_OPTIONS_WITH_MERIDIEM);

  useEffect(() => {
    if (date && time != null && timezone) {
      const formattedDate = moment.tz(date, timezone).format('DD/MM/YYYY');
      const parsed = moment.tz(`${formattedDate} ${time}`, 'DD/MM/YYYY h:mma', timezone);
      onChange && onChange(parsed.valueOf(), timezone);
    }
  }, [date, time, timezone]);

  useEffect(() => {
    const formattedDate = moment.tz(date, timezone).format('DD/MM/YYYY');
    const filteredTimeOptions = DEFAULT_TIME_OPTIONS_WITH_MERIDIEM.filter((time) => {
      const parsed = moment.tz(`${formattedDate} ${time}`, 'DD/MM/YYYY h:mma', timezone);
      return parsed.isAfter(moment(minDate));
    });
    setTimeOptions(filteredTimeOptions);
    if (
      (moment.tz(`${formattedDate} ${time}`, 'DD/MM/YYYY h:mma', timezone).isBefore(moment()) ||
        moment.tz(`${formattedDate} ${time}`, 'DD/MM/YYYY h:mma', timezone).isBefore(moment.tz(minDate, timezone))) &&
      filteredTimeOptions.length
    ) {
      setTime(filteredTimeOptions[0]);
    }
  }, [date, minDate, timezone]);

  const pickedTimezone = TIMEZONES.find((t) => t.name === timezone);

  useEffect(() => {
    if (timezone && !pickedTimezone) {
      const offset = moment().tz(timezone).utcOffset();
      const foundTimezone = TIMEZONES.find((t) => t.currentTimeOffsetInMinutes == offset);
      setTimezone((foundTimezone && foundTimezone.name) || moment.tz.guess());
    }
  }, [timezone, pickedTimezone]);

  const endTime = moment.tz(time, 'h:mma', timezone).add(1, 'h').format('h:mma');

  const CustomInput = forwardRef(({ onClick, ...props }, ref) => {
    return (
      <div className="Questions__schedulerDatepickerInput" onClick={onClick}>
        <input {...props} ref={ref} />
        <i className="far fa-calendar-day" aria-hidden />
      </div>
    );
  });

  const Option = (props) => {
    const splittedDescription = props.data.label.split(')');
    return (
      <components.Option
        {...props}
        className={cx('Questions__schedulerOption', props.className)}
        innerProps={{ 'aria-label': props.data.label, role: 'option' }}
      >
        <span className="Questions__schedulerOffset" aria-hidden>
          {splittedDescription[0]})
        </span>
        <div className="Questions__schedulerOptionName" aria-hidden>
          {splittedDescription[1]}
        </div>
      </components.Option>
    );
  };

  const SingleValue = ({ children, ...props }) => {
    const splittedDescription = props.data.label.split(')');
    return (
      <components.SingleValue {...props} className={cx('Questions__schedulerOption', props.className)}>
        <span className="Questions__schedulerOffset" aria-hidden>
          {splittedDescription[0]})
        </span>
        <div className="Questions__schedulerOptionName" aria-hidden>
          {splittedDescription[1]}
        </div>
      </components.SingleValue>
    );
  };

  const mappedTimeOptions = timeOptions.map((o) => ({ label: o, value: o }));
  const selectedTimeI = timeOptions.indexOf(time);

  const mappedTimezones = TIMEZONES.map((o) => ({
    label: o.description,
    value: o.name,
    offset: o.currentTimeOffsetInMinutes / 60,
  }));
  const selectedTimezone = mappedTimezones.find((t) => pickedTimezone && t.value === pickedTimezone.name);

  return (
    <div className="Questions__scheduler">
      {showDatepicker && (
        <>
          <Datepicker
            selected={date}
            onChange={(o) => setDate(o)}
            dateFormat="dd MMM yyyy"
            minDate={moment(minDate).toDate()}
            className={cx('Questions__schedulerDatepicker', { hasValue: date })}
            placeholderText="Select a date"
            customInput={<CustomInput />}
          />
          <Select
            className={cx('react-select Questions__schedulerSelect', { hasValue: time !== null })}
            classNamePrefix="react-select"
            placeholder="h:mm"
            value={mappedTimeOptions[selectedTimeI]}
            onChange={(o) => setTime(o.value)}
            options={mappedTimeOptions}
            components={{ IndicatorsContainer: () => null }}
            menuShouldScrollIntoView={true}
          />
          to {endTime}
        </>
      )}
      {showTimezonePicker && (
        <Select
          className={cx('react-select Questions__schedulerSelect wide', { hasValue: timezone !== null })}
          classNamePrefix="react-select"
          value={selectedTimezone}
          onChange={(o) => setTimezone(o.value)}
          options={mappedTimezones}
          placeholder="Select a timezone"
          components={{ Option, SingleValue }}
          aria-label={selectedTimezone ? selectedTimezone.label : 'Select a timezone'}
        />
      )}
    </div>
  );
};

export default Scheduler;
