import React from 'react'
import cx from 'classnames'
import {composeComponent} from 'utils/react-tools'

import './Checkbox.sass'

export default composeComponent 'theme/Checkbox',
  ({checked = false, onChange, className, disabled, value, id}) ->
    React.createElement("div", { \
      "className": (cx "Checkbox", className, {disabled, checked})
    },
      React.createElement("input", { \
        "type": "checkbox",  \
        "checked": (checked ? value),  \
        "onChange": (onChange),  \
        "disabled": (disabled),  \
        "id": (id)
      }),
      (if checked
        React.createElement("span", {"className": "Checkbox__icon fas fa-check"})
      )
    )
