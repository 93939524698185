import dayjs from 'dayjs';
import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { useStore } from 'stores/stores';
import Avatar from 'theme/Avatar';
import Loader from 'theme/Loader';

import NameCard from 'user/NameCard';
import { Finished, isBefore } from 'utils/states';
import TranslationsContext from 'translations';
import './ProfileCard.sass';
import content from './content';
import 'tippy.js/dist/tippy.css';

const ProfileCard = (props) => {
  const t = useContext(TranslationsContext);
  const [loading, setLoading] = useState(false);
  const { userStore } = useStore();
  const { user } = userStore;
  const { groupedSetsByPeer, networkStats, activeConversationSet, activeSetMatched } = props;

  const { peer, sets } = useMemo(() => activeConversationSet || {}, [activeConversationSet]);

  const upcomingConversation = useMemo(() => {
    const set = (sets || []).find((s) => !s.archived || !s.finished);
    const conv = set && set.convs.find((c) => isBefore(c.state, Finished));
    return conv;
  }, [sets]);

  const orgName = (user && user.organization && user.organization.name) || '';

  const communityTip = `Welcome to ${orgName}'${
    orgName.slice(-1) != 's' ? 's' : ''
  } community on Imperative, a group of purpose-driven people investing in their fulfillment and relationships at work.`;

  const optInEnabled = useMemo(() => {
    return !!user && !!user.organizationSettings && user.organizationSettings.optInEnabled;
  }, [user]);

  const optInResponse = useMemo(() => {
    const { optIn } = (user && user.matchRequest) || {};
    if (optIn === true) return 'open';
    if (optIn === false) return 'notOpen';
    return undefined;
  }, [user]);

  const peers = useMemo(() => {
    return Object.keys(groupedSetsByPeer)
      .sort((member1, member2) => {
        const set1 = groupedSetsByPeer[member1].sets[0];
        const set2 = groupedSetsByPeer[member2].sets[0];
        return set2.datePaired - set1.datePaired;
      })
      .map((memberId) => {
        const group = groupedSetsByPeer[memberId];
        return group && group.peer;
      });
  }, [groupedSetsByPeer]);

  if (!user) return null;

  const CommunityLink = (props) => (
    <Tippy content={communityTip} placement="bottom" className="tip">
      {user.organizationSettings &&
      user.organizationSettings.communityPageSettings &&
      user.organizationSettings.communityPageSettings.communityPageEnabled ? (
        <Link to="/community" {...props} />
      ) : (
        <div {...props} />
      )}
    </Tippy>
  );

  const Profile = () => (
    <div className="ProfileCard__user">
      <div className="ProfileCard__userAvatar">
        <Link to={`/community/members/${user.id}`} className="section-head color-primary">
          <Avatar for={user} size="96" />
        </Link>
      </div>
      {optInEnabled && (
        <div className="ProfileCard__matching">
          <Tippy content={content.matchStatusTip} placement="bottom" className="tip">
            <div tabIndex="0" aria-label={content.matchStatusTip}>
              <span className={cx('ProfileCard__matchingResponse', optInResponse)} id="match-status">
                {!optInResponse && 'Undeclared'}
                {optInResponse == 'open' && 'Open to matching'}
                {optInResponse == 'notOpen' && 'Not open to matching'}
              </span>
              <span className="caption-caps">
                Current Match Status
                <i className="ci-info_circle_outline" aria-hidden />
              </span>
            </div>
          </Tippy>
          <Link to="/match-status" className="btn-primary" id="update-match-status-btn">
            Update Status
          </Link>
          <div className="divider horizontal" />
        </div>
      )}
    </div>
  );

  const PartnerNameSection = () =>
    peer ? (
      <div className="ProfileCard__partner">
        <h3 className="ProfileCard__partnerTitle section-head">Current Match Partner</h3>
        <div className="ProfileCard__partnerDetails">
          <Link to={`/community/members/${peer.memberId}`}>
            <Avatar size="48" for={peer} />
          </Link>
          <div className="ProfileCard__partnerName">
            <Link to={`/community/members/${peer.memberId}`} className="section-head color-primary">
              {peer.firstName} {peer.lastName}
            </Link>
            <span className="p-2">{peer.email}</span>
          </div>
        </div>
      </div>
    ) : null;

  const PartnerSection = () => {
    const scheduledConversation = upcomingConversation && !!upcomingConversation.scheduleDate;

    return (
      <div id="partner-profile-section">
        {!peer && optInEnabled && optInResponse == 'notOpen' && (
          <div className="ProfileCard__partner">
            <div className="ProfileCard__partnerInfo">{content.notOpen}</div>
          </div>
        )}
        {!peer && optInEnabled && optInResponse == 'open' && (
          <div className="ProfileCard__partner">
            <div className="ProfileCard__partnerInfo">{content.open}</div>
          </div>
        )}
        {!optInEnabled || (optInResponse && <PartnerNameSection />)}
        {optInEnabled && (
          <>
            {(!optInResponse && activeSetMatched) && <PartnerNameSection />}
            {(!optInResponse && !activeSetMatched) && (
              <div className="ProfileCard__partner">
                <div className="ProfileCard__partnerInfo">{content.undeclared}</div>
              </div>
            )}
          </>
        )}
        {peer && upcomingConversation && (
          <div className="text-center margin-top-3">
            {!scheduledConversation && (
              <p className="p-new margin-bottom-3">
                You have no upcoming conversation scheduled with {peer.firstName}. Schedule time to connect using the
                button below.
              </p>
            )}
            {scheduledConversation && (
              <>
                <p className="p-new">
                  <b>{dayjs(upcomingConversation.scheduleDate).format('MMMM D, ')}</b>
                  {dayjs(upcomingConversation.scheduleDate).format('hh:mm A')}
                </p>
                <span className="caption-caps">Upcoming conversation</span>
              </>
            )}
            <Link className="btn-secondary" to={`${location.pathname}?action=reschedule&id=${upcomingConversation.id}`}>
              {scheduledConversation ? 'Reschedule' : 'Schedule'}
            </Link>
          </div>
        )}
      </div>
    );
  };

  const NetworkCount = () => (
    <>
      <div className="divider horizontal" />
      <div className="ProfileCard__count">
        <Tippy content={content.networkTip} placement="bottom" className="tip">
          <div
            className="ProfileCard__count-item"
            tabIndex="0"
            aria-label={peers.length + ' Network. ' + content.networkTip}
          >
            <>
              <span className="section-head">{peers.length}</span>
              <span className="caption-caps">
                Personal Network
                <i className="ci-info_circle_outline" />
              </span>
            </>
          </div>
        </Tippy>
        <CommunityLink
          className="ProfileCard__count-item"
          aria-label={((networkStats && networkStats.sameRoleUsers) || 0) + ' Community. ' + communityTip}
        >
          <span className="section-head">{(networkStats && networkStats.communityCount) || 0}</span>
          <span className="caption-caps">
            Community
            <i className="ci-info_circle_outline" />
          </span>
        </CommunityLink>
      </div>
    </>
  );

  if (optInEnabled)
    return (
      <div className="ProfileCard card relative">
        <Loader loading={loading} color="white" />
        <Profile />
        <PartnerSection />
        <NetworkCount />
      </div>
    );

  return (
    <div className="ProfileCard relative">
      <Loader loading={loading} color="white" />
      <NameCard for={user} showPauseButton={false} showInfo={false} withoutButtons>
        <div className="divider horizontal" />
        <PartnerSection />
        <NetworkCount />
      </NameCard>
    </div>
  );
};

export default observer(ProfileCard);
