import React from 'react'
import {composeComponent, withModal} from 'utils/react-tools'
import * as states from 'utils/states'
import {withRouter, Link} from 'react-router-dom'
import * as _ from 'ramda'
import qs from 'qs'
import {fetchUser} from 'auth/requests'
import {withHandlers, withStateHandlers, lifecycle, mapProps, withProps} from 'recompose'
import moment from 'moment-timezone'
import {parse} from 'json2csv'
import cx from 'classnames'
import {getUser} from 'api'
import * as ME from 'api/metrics'
import Avatar from 'theme/Avatar'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Modal from 'theme/Modal'
import {Collapse} from 'react-collapse'
import Toast from 'theme/Toast'
import Subnav from 'theme/Subnav'
import RSelect, {components} from 'react-select'
import {getConversations,
        getWorkshops,
        getConversationsDefinitions,
        deleteConversation,
        updateConversation,
        getOrganizations
        } from 'conversations/requests'
import {getMatchingGroups,
        getMatchingPairs,
        getMatches
        } from 'matchingGroups/requests'

import './Conversations.sass'

MESSAGES =
  error:
    loading: 'Cannot load data. Please try again later or contact the administrator.'
    delete: 'Something went wrong while deleting conversation.'
    sthWrong: 'Something went wrong'
  success:
    delete: 'Successfully deleted conversation.'
    stateChanged: 'This conversation is now completed'


MemberOption = (props) ->
  React.createElement(components.Option, Object.assign({},  props),
    React.createElement("div", null,
      (if props.data.member
        React.createElement(Avatar, {"className": "Conversations__avatar", "for": (props.data.member)})
      ),
      (props.label)
    )
  )

MemberValue = ({children, ...props}) ->
  React.createElement(components.SingleValue, Object.assign({},  props),
    React.createElement("div", null,
      React.createElement(Avatar, {"className": "Conversations__avatar", "for": (props.data.member), "size": "xxs"}),
      (children)
    )
  )


export default composeComponent 'Conversations',
  withRouter
  withModal

  withStateHandlers
    status: null
    user: null
    loading: false
    pairsLoading: false
    organization: null
    conversations: []
    workshops: []
    organizations: []
    conversationsDefinitions: []
    activeWorkshop: null
    openedInfos: []
    displayMode: 'Pairs' # pairs, individuals
    filteredMember: null
    matchingGroups: []
    matchingPairs: null
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    endPairsLoading: -> -> pairsLoading: false
    startPairsLoading: -> -> pairsLoading: true
    setStatus: -> (type, reason, error) ->
      status: {type, reason, error}
    clearStatus: -> -> status: null
    setConversations: -> (conversations) -> {conversations}
    setWorkshops: -> (workshops) -> {workshops}
    setDefinitions: -> (conversationsDefinitions) -> {conversationsDefinitions}
    setWorkshop: -> (activeWorkshop) -> {activeWorkshop}
    setOrganization: -> (organization) -> {organization}
    setOrganizations: -> (organizations) -> {organizations}
    setMatchingGroups: -> (matchingGroups) -> {matchingGroups}
    setMatchingPairs: ({matchingPairs}) -> (pairs, groupId, clearPairs) ->
      if clearPairs
        matchingPairs: null
      else
        matchingPairs: {...matchingPairs, ...(if groupId then [groupId]: pairs)}
    openInfo: ({openedInfos}) -> (conv) ->
      if conv.id in openedInfos
        openedInfos: _.without [conv.id], openedInfos
      else if conv is 'clear'
        openedInfos: []
      else openedInfos: _.append conv.id, openedInfos
    changeDisplayMode: ({displayMode}) -> (mode) ->
      if mode
        displayMode: mode
      else
        displayMode: if displayMode is 'Pairs' then 'Individuals' else 'Pairs'
    filterByMember: -> (m) -> filteredMember: m?.member?.memberId

  withHandlers
    setError: ({setStatus}) -> (reason, details = undefined) -> (error) ->
      ME.reportIntermediateError "CPL PeerCoaching/ConversationStatus: #{reason} error", error, details
      setStatus 'error', reason, error
    setSuccess: ({setStatus}) -> (reason) -> ->
      setStatus 'success', reason

  withHandlers
    filterByMemberAndClear: ({filterByMember, openInfo, setMatchingPairs}) -> (member) ->
      filterByMember member
      openInfo 'clear'
      setMatchingPairs _,_,'clear'

  withHandlers
    selectOrganization: ({setOrganization, setConversations, setMatchingGroups, setWorkshops, setWorkshop, history }) -> (org) ->
      setOrganization org
      getWorkshops org.code
      .then (workshops) ->
        search = qs.parse history.location.search, ignoreQueryPrefix: true
        foundWorkshop = if search.workshop then workshops.find (w) -> w.id is JSON.parse search.workshop

        workshop = foundWorkshop ? workshops[0]

        if workshop
          setWorkshop  _.merge workshop,
            label: workshop.name
            value: workshop.id

          getMatchingGroups workshop
          .then setMatchingGroups
        else
          setWorkshop null
          setMatchingGroups([])

        a = setWorkshops(workshops)
        b = if workshop
            getConversations(org.code, workshopId: workshop.id).then(setConversations)
          else
            setConversations([])
            Promise.resolve()
        Promise.all([a, b])

  withHandlers
    loadData: ({user, setUser, startLoading, endLoading, selectOrganization, setDefinitions, setOrganizations, setError}) -> ->
      ME.reportStateChange "CPL PeerCoaching/ConversationStatus: data loading"
      startLoading()

      a = fetchUser()
        .then (user) ->
          setUser user
          mPromise = selectOrganization(user.organization)
          orgPromise =
            if user.isSuperAdmin
              getOrganizations().then(setOrganizations)
            else
              Promise.resolve()
          Promise.all([mPromise, orgPromise])

      b = getConversationsDefinitions()
      .then setDefinitions

      Promise.all [a, b]
      .then _, setError 'loading'
      .finally endLoading

    changeWorkshop: ({startLoading, endLoading, setConversations, setMatchingGroups, setWorkshop, activeWorkshop, organization, openInfo, setMatchingPairs}) -> (workshop) ->
      if activeWorkshop.id is workshop.id
        return

      ME.reportStateChange "CPL PeerCoaching/ConversationStatus: workshop changing", {workshop: workshop?.id}
      startLoading()
      setWorkshop workshop
      setMatchingPairs _,_,'clear'
      openInfo 'clear'

      ME.reportStateChange "CPL PeerCoaching/ConversationStatus: conversations loading"
      getConversations organization.code, workshopId: workshop.id
      .then setConversations
      .then _
      , (err) ->
        ME.reportIntermediateError "CPL PeerCoaching/ConversationStatus: conversations loading error", err
        setWorkshop activeWorkshop
      .finally endLoading

      ME.reportStateChange "CPL PeerCoaching/ConversationStatus: matchGroups loading"
      getMatchingGroups workshop
      .then setMatchingGroups
      .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/ConversationStatus: matchGroups loading error", err

    isMemberPicked: ({pickedMembers}) -> (member) ->
      member.id in _.pluck 'id', pickedMembers

    getPairs: ({setMatchingPairs, activeWorkshop, startPairsLoading, endPairsLoading, filteredMember}) -> (group) ->
      ME.reportStateChange "CPL PeerCoaching/ConversationStatus: matches loading", {groupId: group?.id, workshop: group?.workshopId}
      startPairsLoading()

      getMatches activeWorkshop.id, group?.id
      .then (matches) ->
        pcmId = if matches.length > 0 then matches[0].id else null
        if pcmId
          getMatchingPairs activeWorkshop.id, pcmId, filteredMember
          .then ({pcs}) -> setMatchingPairs _.map(
            ((p) ->
              [program, set, conv] = _.split '/', p.label
              _.merge p, {program, set, conv}
            ),
            pcs
          ), group?.id
          .finally endPairsLoading
        else
          setMatchingPairs null
          endPairsLoading()
      .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/ConversationStatus: matches loading error", err

  withHandlers
    deleteConversation: ({loadData, setSuccess, setError, closeModal, organization, activeWorkshop, setConversations, getPairs}) -> (conversation, group) ->
      ME.reportStateChange "CPL PeerCoaching/ConversationStatus: conversations deleting"
      deleteConversation conversation
      .then () ->
        getConversations organization.code, workshopId: activeWorkshop.id
        .then setConversations
        getPairs group
      .then closeModal
      .then setSuccess('delete'), setError 'delete'
    markAsComplete: ({ setSuccess, setError, organization, activeWorkshop, setConversations, getPairs, startLoading, endLoading }) -> ({conv, group}) ->
      ME.reportStateChange "CPL PeerCoaching/ConversationStatus: marking as complete"
      startLoading()

      updateConversation conv, { state: states.Finished, forcedUpdate: true }
      .then () ->
        getConversations organization.code, workshopId: activeWorkshop.id
        .then setConversations
        getPairs group
        .then -> endLoading()
      .then setSuccess('stateChanged')
      .catch (err) ->
        setError('sthWrong')(err)
        endLoading()

  lifecycle
    componentDidMount: ->
      @props.loadData()

  withProps ({conversations}) ->
    allMembers: _.uniqBy _.path(['member', 'memberId']), _.flatten _.pluck 'participant', conversations

  mapProps (props) -> _.evolve(
    workshops: _.map (workshop) ->
      _.merge workshop,
        label: workshop.name
        value: workshop.id

    conversations: _.pipe(
      _.filter (conv) ->
        return true unless props.filteredMember
        conv.participant?.some (p) -> p.member.memberId is props.filteredMember
      _.map (conv) ->
        [program, set, convLabel] = _.split '/', conv.label
        stateToDisp = states.simplifyConvState conv.state
        participant = conv.participant?.map (p) ->
          stateToDisp = states.simplifyConvState p.state
          _.merge p, {stateToDisp}
        _.merge conv, {participant, program, set, convLabel, stateToDisp}
    )

    matchingGroups: _.map (group) ->
      [program, set] = _.split '/', group.peerConversationSetLabel
      _.merge group, {program, set}
  ) props

  withProps ({allMembers, filteredMember}) ->
    members:
      if not filteredMember
        allMembers
      else allMembers.filter (m) -> m.member.memberId is filteredMember

  withHandlers
    downloadCsv: ({conversations, t, activeWorkshop, members}) -> ->
      ME.reportStateChange "CPL PeerCoaching/ConversationStatus: download csv report"
      mappedData = []
      conversations.forEach (conv) ->
        p1Id = 0
        p2Id = 0
        p1Name = ''
        p2Name = ''
        finalCS = states.simplifyConvState conv.state
        conv.participant.slice(0, 1).map (p) ->
          p1Id = p.member.memberId
          p1Name = p.member.firstName + " " + p.member.lastName
        conv.participant.slice(1).map (p) ->
          p2Id = p.member.memberId
          p2Name = p.member.firstName + " " + p.member.lastName

        p1Member = activeWorkshop.members.filter (m) -> m.id == p1Id
        p2Member = activeWorkshop.members.filter (m) -> m.id == p2Id

        p1Email = p1Member[0].email if p1Member != null && p1Member.length > 0
        p2Email = p2Member[0].email if p2Member != null && p2Member.length > 0

        mappedData.push
          'Cohort': activeWorkshop.name
          'Member Name': p1Name
          'Partner Name': p2Name
          'Conversation Program': t.find "peerconversation.program.#{conv.program}.title"
          'Conversation Set': t.find "peerconversation.set.#{conv.set}.title"
          'Conversation Name': t.find "peerconversation.conversation.#{conv.convLabel}.title"
          'Coversation State': conv.state
          'Desired Conversation State': finalCS
          'Email 1 (Member)': p1Email
          'Email 2 (Partner)': p2Email

      try
        filename = "Cohort - #{activeWorkshop.name} - Conversation Status.csv"
        csv = parse (mappedData ? []), {header: true}

        blob = new Blob [csv], type: 'text/csv;charset=utf-8;'
        if (navigator.msSaveBlob)
          navigator.msSaveBlob(blob, filename)
        else
          encodedUri = URL.createObjectURL blob
          link = document.createElement "a"
          link.setAttribute("href", encodedUri)
          link.setAttribute("download", filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
      catch err
        console.error err

  ({
    loading
    pairsLoading
    current
    finished
    workshops
    activeWorkshop
    changeWorkshop
    isMemberPicked
    conversations
    history
    user
    deleteConversation
    status
    clearStatus
    t
    openedInfos
    openInfo
    modal
    showModal
    closeModal
    displayMode
    changeDisplayMode
    members
    filteredMember
    filterByMemberAndClear
    allMembers
    downloadCsv
    selectOrganization
    organization
    organizations
    matchingGroups
    getPairs
    matchingPairs
    markAsComplete
  }) ->

    memberOptions = _.prepend {label: 'All', value: null}, allMembers

    React.createElement("div", {"className": "Conversations page"},
      React.createElement(Header, {"user": (user), "cpl": (true)}),

      React.createElement("div", {"className": "container", "id": "main"},
        React.createElement("div", {"className": "Conversations__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "Conversations__title"}, """
              Conversations Index
"""),
            React.createElement("div", {"className": "Conversations__headingFilter"},
              ( if user?.isSuperAdmin
                React.createElement("div", {"className": "Conversations__label"}, "Organization")
              ),
              ( if user?.isSuperAdmin
                React.createElement(RSelect, { \
                  "className": "Conversations__select",  \
                  "classNamePrefix": "Conversations__select",  \
                  "placeholder": "Select an organization",  \
                  "options": (organizations),  \
                  "getOptionValue": ((o) -> o),  \
                  "getOptionLabel": ((o) -> o.name),  \
                  "value": (organization),  \
                  "onChange": (selectOrganization),  \
                  "disabled": (loading)
                })
              ),
              React.createElement("div", {"className": "Conversations__label"}, "Cohort"),
              React.createElement(RSelect, { \
                "className": "Conversations__select",  \
                "classNamePrefix": "Conversations__select",  \
                "placeholder": "Select a Cohort",  \
                "options": (workshops),  \
                "value": (activeWorkshop),  \
                "onChange": (changeWorkshop),  \
                "disabled": (loading)
              }),
              React.createElement("div", {"className": "Conversations__label"}, "Member"),
              React.createElement(RSelect, { \
                "className": "Conversations__select",  \
                "classNamePrefix": "Conversations__select",  \
                "placeholder": "Filter by member",  \
                "options": (memberOptions),  \
                "components": (Option: MemberOption, SingleValue: MemberValue),  \
                "getOptionLabel": ((o) -> if o.member then "#{o.member.firstName} #{o.member.lastName}" else 'All'),  \
                "getOptionValue": ((o) -> if o.member then o.member.memberId else o.value),  \
                "value": (if filteredMember then memberOptions.find (o) -> o.member?.memberId is filteredMember else null),  \
                "onChange": (filterByMemberAndClear),  \
                "disabled": (loading)
              })
            )

          ),

          React.createElement("div", {"className": "Conversations__options"},
            React.createElement("div", {"className": "Conversations__toggler"},
              React.createElement("span", {"className": (if displayMode is 'Pairs' then 'active')}, "Pairs"),
              React.createElement("div", {"className": "Conversations__toggler-bar", "onClick": (-> changeDisplayMode())},
                React.createElement("div", {"className": (cx "Conversations__toggler-handle", right: displayMode is 'Individuals')})
              ),
              React.createElement("span", {"className": (if displayMode is 'Individuals' then 'active')}, "Individuals")
            ),
            (if displayMode is 'Pairs'
              React.createElement("button", { \
                "onClick": (downloadCsv),  \
                "className": "btn btn_secondary btn_outlined_pink left"
                }, """
                Download CSV
""")
            ),
            React.createElement("div", {"className": "Conversations__link"},
              React.createElement(Link, { \
                "className": "btn btn_secondary btn_outlined_purple left",  \
                "to": "/peer-coaching/conversations/all"
              }, """
                Orphaned conversations
""")
            )
          ),

          React.createElement("div", {"className": "relative"},
            React.createElement(Loader, {"loading": (loading)}),

            (switch displayMode
              when 'Pairs'
                React.createElement("div", {"className": "Conversations__table"},
                  React.createElement("table", null,
                    React.createElement("thead", null,
                      React.createElement("tr", null,
                        React.createElement("th", {"colSpan": "5"}, "Program"),
                        React.createElement("th", {"colSpan": "3"}, "Set"),
                        React.createElement("th", {"colSpan": "3"}, "Description"),
                        React.createElement("th", {"className": "invisible"}, "Actions")
                      )
                    ),

                    React.createElement("tbody", null,
                      (matchingGroups.map (group) ->
                        opened = group.id in openedInfos
                        closed = not opened
                        [
                          React.createElement("tr", { \
                            "key": ('ProgramSet-' + group.id),  \
                            "onClick": ( () ->
                              openInfo group
                              unless opened
                                getPairs group
                            ),  \
                            "className": (cx "Conversations__matchingGroup", {opened})
                          },
                            React.createElement("td", {"colSpan": "5"},
                              (t.find "peerconversation.program.#{group.program}.title")
                            ),
                            React.createElement("td", {"colSpan": "3"},
                              (t.find "peerconversation.set.#{group.set}.title")
                            ),
                            React.createElement("td", {"colSpan": "3"},
                              (group.description)
                            ),
                            React.createElement("td", null,
                              (if opened
                                React.createElement("span", {"className": "far fa-chevron-up"})
                              else
                                React.createElement("span", {"className": "far fa-chevron-down"})
                              )
                            )
                          )
                          React.createElement("tr", { \
                            "key": ('title-' + group.id),  \
                            "className": (cx "Conversations__more Conversations__innerHeader", {opened, closed})
                          },
                            React.createElement("td", {"colSpan": "2"}, ('Conversation Name')),
                            React.createElement("td", null, ('Member #1')),
                            React.createElement("td", null, ('#1 State')),
                            React.createElement("td", null, ('#1 Last modified')),
                            React.createElement("td", null, ('Member #2')),
                            React.createElement("td", null, ('#2 State')),
                            React.createElement("td", null, ('#2 Last modified')),
                            React.createElement("td", {"colSpan": "3"}, ('State')),
                            React.createElement("td", null)
                          )

                          matchingPairs?[group.id]?.map ({conv, id, state, participant}) ->
                            p0 = participant?[0]
                            p1 = participant?[1]

                            React.createElement("tr", {"key": ('Conv-' + id), "className": (cx "Conversations__more", {opened, closed})},
                              React.createElement("td", {"colSpan": "2"}, (t.find "peerconversation.conversation.#{conv}.title")),
                              React.createElement("td", null,
                                React.createElement("span", null,
                                  React.createElement(Avatar, {"for": (p0?.member), "className": "Conversations__avatar"}),
                                  React.createElement("span", null, (p0?.member?.firstName), " ", (p0?.member?.lastName))
                                )
                              ),
                              React.createElement("td", null, (states.simplifyConvState p0?.state)),
                              React.createElement("td", null,
                                React.createElement("div", null,
                                  React.createElement("div", null, (moment(p0?.modifiedAt).tz(user?.timeZone)?.format 'lll')),
                                  React.createElement("div", null, "(", (user?.timeZone), ")")
                                )
                              ),
                              React.createElement("td", null,
                                React.createElement("span", null,
                                  React.createElement(Avatar, {"for": (p1?.member), "className": "Conversations__avatar"}),
                                  React.createElement("span", null, (p1?.member.firstName), " ", (p1?.member.lastName))
                                )
                              ),
                              React.createElement("td", null, (states.simplifyConvState p1?.state)),
                              React.createElement("td", null,
                                React.createElement("div", null,
                                  React.createElement("div", null, (moment(p1?.modifiedAt).tz(user?.timeZone)?.format 'lll')),
                                  React.createElement("div", null, "(", (user?.timeZone), ")")
                                )
                              ),
                              React.createElement("td", {"colSpan": "3"}, (states.simplifyConvState state)),
                              React.createElement("td", null,
                                React.createElement("div", {"className": "Conversations__actions"},
                                  (if (states.simplifyConvState state) is states.STATES.inProgress
                                    React.createElement("button", {"className": "btn-icon Conversations__action", "onClick": (-> markAsComplete {conv: {id}, group}), "aria-label": "check"},
                                      React.createElement("i", {"className": "far fa-clipboard-check"}),
                                      React.createElement("span", {"className": "Conversations__action-tooltip"}, ('Mark as complete'))
                                    )
                                  ),
                                  React.createElement("button", {"className": "btn-icon Conversations__action", "onClick": (-> showModal {conv: {id}, group}), "aria-label": "trash"},
                                    React.createElement("i", {"className": "far fa-trash-alt"}),
                                    React.createElement("span", {"className": "Conversations__action-tooltip"}, ('Delete conversation'))
                                  )
                                )
                              )
                            )

                          unless matchingPairs?[group.id]
                            React.createElement("tr", { \
                              "key": ('NoPairs-' + group.id),  \
                              "className": (cx "Conversations__more", {opened, closed})
                            },
                              (
                                if pairsLoading
                                  React.createElement("td", {"colSpan": "12"},
                                    React.createElement("span", {"className": "far fa-circle-notch fa-1x fa-spin "})
                                  )
                                else
                                  React.createElement("td", {"colSpan": "12"}, ('NO MATCHINGS'))
                              )
                            )
                        ]
                      )
                    )
                  )
                )

              when 'Individuals'
                React.createElement("div", {"className": "Conversations__table members"},
                  React.createElement("table", null,
                    React.createElement("thead", null,
                      React.createElement("tr", null,
                        React.createElement("th", null, "Member"),
                        React.createElement("th", null, "Program"),
                        React.createElement("th", null, "Set"),
                        React.createElement("th", null, "Conversation"),
                        React.createElement("th", null, "State")
                      )
                    ),

                    React.createElement("tbody", null,
                      (members?.map (m) ->
                        [firstConv, ...convs] = conversations?.filter (c) ->
                          c.participant.some (p) -> p.member.memberId is m.member.memberId

                        convCells = (c) ->
                          return null unless c
                          p = c.participant.find (p) -> p.member.memberId is m.member.memberId
                          [
                            React.createElement("td", {"key": "0"},
                              (t.find "peerconversation.program.#{c.program}.title")
                            )
                            React.createElement("td", {"key": "1"},
                              (t.find "peerconversation.set.#{c.set}.title")
                            )
                            React.createElement("td", {"key": "2"},
                              (t.find "peerconversation.conversation.#{c.convLabel}.title")
                            )
                            React.createElement("td", {"key": "3"},
                              (p.state)
                            )
                          ]

                        [
                          React.createElement("tr", {"key": (m.member.memberId + "1")},
                            React.createElement("td", {"className": "Conversations__member-name", "rowSpan": (convs.length + 1)},
                              React.createElement(Avatar, {"for": (m.member), "className": "Conversations__avatar"}),
                              React.createElement("span", {"className": "margin-left-4"}, (m.member.firstName), " ", (m.member.lastName))
                            ),
                            (convCells firstConv)
                          )
                          convs.map (c) ->
                            React.createElement("tr", {"key": (m.member.memberId + c.id)},
                              (convCells c)
                            )
                        ]
                      )
                    )
                  )
                )
            )
          )
        )

      ),

      React.createElement(Footer, null),

      React.createElement(Modal, {"isOpen": (modal?.visible), "className": "confirm-modal", "close": (closeModal)},
        React.createElement("h2", {"className": "title"}, "Warning"),
        React.createElement("p", null, "Do you really want to delete this conversation?"),
        React.createElement("div", {"className": "btns"},
          React.createElement("button", {"className": "btn btn_secondary btn_outlined_bluePurple", "onClick": (closeModal)}, """
            Cancel
"""),
          React.createElement("button", {"className": "btn btn_secondary btn_solid_bluePurple", "onClick": (-> deleteConversation modal?.conv, modal?.group)}, """
            Delete
""")
        )
      ),

      React.createElement(Toast, { \
        "visible": (status?),  \
        "type": (status?.type),  \
        "message": (MESSAGES[status?.type]?[status?.reason]),  \
        "close": (clearStatus)
      })

    )
