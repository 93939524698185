export const Accepted = 'Accepted'
export const Conducted = 'Conducted'
export const Created = 'Created'
export const Finished  = 'Finished'
export const PostActionsFilled = 'PostActionsFilled'
export const Rejected = 'Rejected'
export const Scheduled = 'Scheduled'
export const Started = 'Started'

// legacy states, if you want to delete it - first check if still exist in db (old accounts)
export const Actions = 'Actions' // legacy
export const Assigned = 'Assigned' // legacy
export const InProgress = 'InProgress' // legacy
export const NotStarted = 'NotStarted' // legacy


export const statesOrder = [
  Rejected,
  Created,
  Accepted,
  Scheduled,
  Started,
  Conducted,
  Actions, // legacy
  PostActionsFilled,
  Finished,
];

export const isAfter = (state, beforeState) => {
  const stateI = statesOrder.indexOf(state);
  const beforeStateI = statesOrder.indexOf(beforeState);

  return stateI > beforeStateI;
};

export const isBefore = (state, nextState) => {
  const stateI = statesOrder.indexOf(state);
  const nextStateI = statesOrder.indexOf(nextState);

  return stateI < nextStateI;
};

export const STATES = {
  complete: 'Complete',
  notStarted: 'Not started',
  inProgress: 'In-progress',
  na: 'N/A',
};

export const simplifyConvState = (state) => {
  if (state === Finished || state === PostActionsFilled)
    return STATES.complete
  else if (state === Accepted  || state === Created || state === Scheduled || state === Assigned  || state === NotStarted)
    return STATES.notStarted
  else if (state === Started || state === Rejected || state === Conducted || state === InProgress)
    return STATES.inProgress
  else
    return STATES.na
};
