import React, { useCallback, useEffect, useState } from 'react'
import {useLocation} from 'react-router'
import qs from 'qs'
import {reduxForm, Field, SubmissionError} from 'redux-form'
import validate, * as V from 'validate'
import {TextField, BtnContainer} from 'auth/Form'
import TextFieldWithValidationBullets from 'auth/Form/TextFieldWithValidationBullets'
import Layout, {Link, Hint} from 'auth/Layout'
import Loader from 'theme/Loader'
import {register} from 'auth/requests'
import {find as findInvitation} from 'invitations/requests'

import './Signup.sass'

const ERRORS = {
  unknown: 'Something went wrong. Please try again later.',
  signInBySSO: 'Please use your organization’s SSO page to register or sign-in into the Imperative Platform.',
  alreadyRegistered: `You already have an account. You can ${<Link to='/signin'>login</Link>} or ${<Link to='/reset'>'reset your password'</Link>}.`,
  invalidInvitation: 'The invitation is invalid',
  unknownEmailDomain: 'You are not allowed to register',
  subscriptionExpired: 'Your organization is no longer subscribed to Imperative',
  organizationDomainNotFound: 'Your email organization domain has not been found',
  seatsLimitExceeded: 'Please contact your program administrator about joining Imperative'
};

const Form = ({handleSubmit, emailByInvitation, invalid, submitting, submitFailed, error}) => {
  const [passwordErrors, setPasswordErrors] = useState([]);

  const validatePassword = useCallback((value, allValues) => {
    const errors = [
      {value: V.required()(value, allValues), message: 'Enter a password please.'},
      {value: V.minlen(8)(value, allValues), inBullets: true, message: 'Eight characters minimum'},
      {value: V.pattern(/[A-Z]/)(value, allValues), inBullets: true, message: 'One uppercase letter (A-Z)'},
      {value: V.pattern(/[a-z]/)(value, allValues), inBullets: true, message: 'One lowercase letter (a-z)'},
      {value: V.pattern(/[0-9]/)(value, allValues), inBullets: true, message: 'One number (0-9)'},
      {value: V.pattern(V.SPECIAL_CHARS)(value, allValues), inBullets: true, message: 'One special character (#@!)'},
      {value: V.doesntInclude(['firstName', 'lastName'])(value, allValues), message: 'Password cannot contain your name.'}
    ];
    if (passwordErrors.length === 0 || errors.some((obj, index) => obj.value !== passwordErrors[index].value)) {
      setPasswordErrors(errors);
    }

    if (errors.find(e => e.value != undefined)) {
      return(errors)
    }
  }, [passwordErrors]);

  return(
    <form onSubmit={handleSubmit}>
      <div className="Signup__formFields">
        <Field
          id="Signup__fieldFirstName"
          name="firstName"
          label="First Name"
          placeholder="First Name"
          component={TextField}
          autocompleteOff
        />
        <Field
          id="Signup__fieldLastName"
          name="lastName"
          label="Last Name"
          placeholder="Last Name"
          component={TextField}
          autocompleteOff
        />
        <Field
          id="Signup__fieldEmail"
          name="email"
          label="Email Address"
          placeholder="Work email address"
          disabled={emailByInvitation}
          component={TextField}
          className="Signup__rowFull"
          normalize={(val) => val.trim()}
        />
        <div className="Signup__passwords">
          <Field
            id="Signup__fieldPassword"
            className="Signup__passwords-password"
            name="password"
            type="password"
            label="Password"
            placeholder="Choose a password"
            component={TextFieldWithValidationBullets}
            validate={validatePassword}
            errors={passwordErrors}
            autocompleteOff
            />
          <Field
            id="Signup__fieldPassword2"
            className="Signup__passwords-password2"
            name="password2"
            type="password"
            label="Repeat Password"
            placeholder="Confirm your password"
            component={TextField}
            autocompleteOff
          />
        </div>
      </div>

      {submitFailed && error &&
        <div className="Signup__error">
          {ERRORS[error] || error}
        </div>
      }

      <div className="Signup__textBox Signup__text">
        By continuing, you agree to our <a href="https://www.imperative.com/terms-of-service" target="_blank">Terms of Service</a>
      </div>

      <BtnContainer>
        <button
          id='Signup__signupBtn'
          className="btn btn_primary btn_solid_pink"
          type="submit"
          disabled={invalid || submitting}
          onMouseDown={(e) => e.preventDefault()}
        >
          Get Started
        </button>
      </BtnContainer>

      <div className="Signup__textBox Signup__text">
        <a href="https://www.imperative.com/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
    </form>
  )
};

const SignupForm = reduxForm({
  form: 'signup',
  enableReinitialize: true,
  validate: validate({
    firstName: V.required('Enter your first name please.'),
    lastName: V.required('Enter your last name please.'),
    email: [
      V.required('Enter an email please'),
      V.email('Enter a valid email please')
    ],
    password2: [
      V.required('Confirm your password please.'),
      V.match('password', 'The passwords should match.')
    ]
  })
})(Form);

const Overlay = () => (
  <div className="Signup__overlay">
    <h3>Activate Purpose</h3>
    <h4>Unlock your potential</h4>
  </div>
);

const Signup = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [invitationObj, setInvitationObj] = useState(null);

  const email = invitationObj && invitationObj.email;

  const submit = (values) => {
    return register({
      ...values,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      tosAndPpAccepted: true
    }).then(
      (res) => res,
      (err) => {
        const e = {_error: (err && err.message) || 'unknown'}
        throw new SubmissionError(e);
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    const {invitation} = qs.parse(location.search, {ignoreQueryPrefix: true})
    const invitationReq = !invitation ?
        Promise.resolve(null) :
        findInvitation(invitation).then(invitationObj => setInvitationObj(invitationObj));

    invitationReq
    .then(
      () => setLoading(false),
      () => setLoading(false)
    );
  }, [location]);

  return (
    <Layout
      headline="Create Your Account"
      overlay={Overlay()}
    >
      <div className="Signup relative">
        <Loader loading={loading} color="white" />

        <SignupForm
          onSubmit={submit}
          initialValues={{email}}
          emailByInvitation={email}
        />
        <Hint id='Signup__signinLink'>
          Already have an account?
          <Link to="/signin">Log in now {">"}</Link>
        </Hint>
      </div>
    </Layout>
  )
};

const SignupWrapper = () => {
  const authPagesBase = config && config.onboarding && config.onboarding.base;
  const local = !!config && config.local; //show signup page when on localhost

  const redirectToOnboardingSignup = () => {
    const signUpUrl = authPagesBase && `${authPagesBase}${config.onboarding.signUp}`;
    window.location.replace(signUpUrl);
  }

  if(local) {
    return <Signup />
  }

  redirectToOnboardingSignup();
  return null;
}

export default SignupWrapper;
