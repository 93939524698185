import React from 'react';
import { Link } from 'react-router-dom';

const Landing = ({navLinks}) => {
  return (
    <div className="Settings__landing">
      <div className="Settings__title">
        <h1 className="h3">Account settings</h1>
        <p>Manage your info to give you more personalized experiences and make Imperative work for you.</p>
      </div>

      <div className="Settings__cards">
        {navLinks.map((t, i) =>
          <Link
            key={i}
            to={t.url}
            className="Settings__card"
          >
            <div className="Settings__cardText">
              <h2>{t.cardTitle || t.title}</h2>
              <p>{t.description}</p>

              <span>{t.linkTitle}</span>
            </div>
            {t.cardIcon || t.icon}
          </Link>
        )}
      </div>
    </div>
  )
}

export default Landing;
