import {URL_EMOJIS} from 'theme/assets/assets'

Frustrated = "#{URL_EMOJIS}/emoji_frustrated.png"
Unhappy = "#{URL_EMOJIS}/emoji_unhappy.png"
Meh = "#{URL_EMOJIS}/emoji_meh.png"
Happy = "#{URL_EMOJIS}/emoji_happy.png"
PumpedUp = "#{URL_EMOJIS}/emoji_pumped.png"

calculateLevel = (score) ->
  if score > 3 then 'PumpedUp'
  else if score > 2 then 'Happy'
  else if score > 1 then 'Meh'
  else 'Unhappy'


export {Frustrated, Unhappy, Meh, Happy, PumpedUp, calculateLevel}
