const buttonStyle = {
  borderRadius: 4,
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  padding: '8px 20px',
};

const styles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#4F62C8',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#515a68',
    width: undefined,
    zIndex: 1000,
  },
  tooltipContent: {
    padding: '12px',
  },
  buttonNext: {
    ...buttonStyle,
  },
  buttonBack: {
    ...buttonStyle,
    border: '1px solid #515a68',
    color: '#515a68',
  },
  buttonSkip: {
    color: '#515a68',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
};

export default styles;
