import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as states from 'utils/states'
import {withRouter, Link} from 'react-router-dom'
import * as _ from 'ramda'
import debounce from 'debounce-promise'
import {fetchUser} from 'auth/requests'
import {withHandlers, withStateHandlers, lifecycle, mapProps, withProps} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import {Collapse} from 'react-collapse'
import {getUser} from 'api'
import * as ME from 'api/metrics'
import AsyncSelect from 'react-select/async'
import Avatar from 'theme/Avatar'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Subnav from 'theme/Subnav'
import Competencies from 'Competencies'
import {getStats} from './requests'
import BarChart from './BarChart'

import './Analytics.sass'

monthsSubtract = [5, 4, 3, 2, 1, 0]


export default composeComponent 'Analytics',
  withRouter

  withStateHandlers
    user: null
    loading: false
    stats: null
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setStats: -> (stats) -> {stats}

  withHandlers
    loadData: ({user, setUser, startLoading, endLoading, setStats}) -> ->
      startLoading()
      ME.reportStateChange "CPL PeerCoaching/Analytics: data loading"
      u =
        if user
          Promise.resolve user
        else
          fetchUser()
          .then (u) ->
            setUser u
            u

      u.then (user) ->
        getStats(user)
        .then (stats) ->
          ME.reportStateChange "CPL PeerCoaching/Analytics: data loaded"
          setStats stats
          endLoading()
      .catch (err) ->
        ME.reportIntermediateError "CPL PeerCoaching/Analytics: data loading error", err
        endLoading()

  lifecycle
    componentDidMount: ->
      @props.loadData()

  withProps ({stats}) ->
    if stats?
      values = _.flatten (_.concat [stats?.total], stats?.days).map (d) -> [d.completed, d.started]

      stats =
        if stats?
          maxValue: _.reduce _.max, 0, values
          total: Object.keys(stats?.total ? {}).map (label) ->
            {label, value: stats?.total[label]}
          days: stats?.days?.map (day) ->
            label: day.label
            data: Object.keys(_.omit ['label'], day).map (label) ->
              {label, value: day[label]}

    {stats}


  ({
    loading
    user
    t
    stats
  }) ->

    React.createElement("div", {"className": "Analytics page"},
      React.createElement(Header, {"user": (user), "cpl": (true)}),

      React.createElement("div", {"className": "container"},

        React.createElement("div", {"className": "Analytics__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "Analytics__title"}, """
              Analytics
""")
          )
        ),

        React.createElement("div", {"className": "card"},
          React.createElement("div", {"className": "Analytics__subtitle"}, """
            Adoption
"""),
          React.createElement("p", {"className": "title-description"}, "Started and Completed conversation sets."),
          (if stats?.total.length
            React.createElement("div", {"className": "row"},
              React.createElement("div", {"className": "row-group"},
                React.createElement(BarChart, {"data": (stats.total), "maxValue": (stats.maxValue), "minValue": (0)}),
                React.createElement("div", {"className": "Analytics__chartLabel"}, "Total")
              ),
              (stats?.days.map (day) ->
                React.createElement("div", {"className": "row-group"},
                  React.createElement(BarChart, {"data": (day.total), "maxValue": (day.maxValue), "minValue": (0)}),
                  React.createElement("div", {"className": "Analytics__chartLabel"}, (day?.label), " Days")
                )
              )
            )
          )
        ),

        React.createElement(Loader, {"loading": (loading)})
      ),

      React.createElement(Footer, null)


    )
