import QueryString from "qs";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { getRedirUrl } from 'api';
import SamlSigninPage from './SamlSigninPage';

const config = window.config;

const SamlSignin = (props) => {
  const authPagesBase = config && config.onboarding && config.onboarding.base;
  const location = useLocation();
  const redirUrl = useRef(getRedirUrl());

  const params = QueryString.parse(location.search, {ignoreQueryPrefix: true});

  const redirectToOnboardingAuth = () => {
    window.location.replace(authPagesBase);
  }

  if(params.jwt || redirUrl.current || params.error) {
    return <SamlSigninPage {...props} redirUrl={redirUrl.current} />
  }

  redirectToOnboardingAuth();
  return null;
}

export default SamlSignin;