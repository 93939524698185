import React from 'react'
import {addStatesWithHandlers, composeComponent} from 'utils/react-tools'
import {withHandlers, withStateHandlers, lifecycle} from 'recompose'
import {withRouter} from 'react-router-dom'
import qs from 'qs'
import * as _ from 'ramda'
import * as ME from 'api/metrics'

import ShortReport from 'reports/ShortReport'
import LongReport from 'reports/LongReport'
import customGriffith from 'reports/ShortReport/custom-griffith'
import {getIndividualReport, getMember} from 'user/requests'
import convertData from 'reports/convertData'
import {getLanguage, getAvailableLanguages, setLanguage} from 'translations'
import Loader from 'theme/Loader'


export default composeComponent 'IndividualReport',
  withRouter
  addStatesWithHandlers
    loading: false
    user: null
    currentLanguage: null
    report: null
    languageOptions: []

  lifecycle
    componentDidMount: ->
      {match: {params}, setUser, t, setLoading, setReport, setCurrentLanguage, setLanguageOptions} = @props

      t.useUserValues(true);

      setLoading(true);
      ME.reportStateChange('Individual Report: loading');

      getMember(params.slug)
      .then (user) ->
        setUser(user);
        isGriffith = user?.orgs[0]?.code.includes 'griffith'
        availableLangs = getAvailableLanguages()
        .then (data) ->
          data.map (d) -> _.merge d, {label: d.name, value: d.name}
          .filter (d) ->
            return true unless isGriffith
            customGriffith.languages.includes d.name
        .then(setLanguageOptions)

        lang = getLanguage(user.slug)
        .then(setCurrentLanguage)

        Promise.all([
          getIndividualReport(params.slug),
          availableLangs,
          lang
        ]).then(
          ([report]) ->
            setReport(report);
            ME.reportStateChange('Individual Report: loaded');
            setLoading(false);
          , (error) ->
            ME.reportIntermediateError('Individual Report: error', error);
            setLoading(false);
        )

    componentWillUnmount: ->
      @props.t.useUserValues(false);

  withHandlers
    changeLanguage: ({setLoading, currentLanguage, t, user, setCurrentLanguage}) -> (language) ->
      setLoading(true);

      t.changeLanguage(language, user)
      .then(-> setCurrentLanguage(language))
      .then(
        () => setLoading(false),
        () => setLoading(false)
      )

  ({report, user, translations, t, loading, currentLanguage, languageOptions, changeLanguage, changingLanguage}) ->
    search = location.search
    {conversation} = qs.parse search, ignoreQueryPrefix: true

    org = user?.orgs[0]
    converted = report && convertData(t).IndividualReport(report);


    React.createElement("div", {"className": "relative"},
      React.createElement(Loader, {"loading": (loading), "color": "bg"}),
      (if report && !loading
        if org?.reportSettings?.shortReportEnabled and not conversation
          React.createElement(ShortReport, {"report": (converted), "user": (user), "currentLanguage": (currentLanguage), "languageOptions": (languageOptions), "changeLanguage": (changeLanguage)})
        else
          React.createElement(LongReport, {"report": (converted), "conversation": (conversation), "user": (user)})
      )
    )
