import React, { useContext } from 'react';
import TranslationsContext from 'translations';
import * as DRIVER_ICONS from 'theme/drivers';
import cx from 'classnames';

const DRIVERS = {
  who: [
    'Individual',
    'Organization',
    'Society',
  ],
  why: [
    'Harmony',
    'Karma',
  ],
  how: [
    'Community',
    'Human',
    'Knowledge',
    'Structure',
  ]
}

const HowItWorks = () => {
  const t = useContext(TranslationsContext);

  return (
    <>
      <div className="PurposeProfile__page">
        <h3 className="section-head margin-top-5">How was my purpose report generated?</h3>
        <p>
          The Imperative Purpose Report has been used by people around the globe and across every industry to gain insights into how they derive and create meaning, motivation, and fulfillment in their careers.
        </p>
        <p>
          Your purpose report was generated based on your responses to the <b>purpose questionnaire</b> that you completed when you joined Imperative. The purpose questionnaire was developed in collaboration with academics and employers to be an instrument to assist you in developing self-awareness about what creates meaning for you in your work and to provide specific recommendations on how to intentionally design your career and thrive.
        </p>
        <p>
          The purpose questionnaire is <b>not a personality assessment</b> and there is no correlation between your personality (e.g. being introverted or extroverted) and your purpose. The questionnaire is designed to help define purpose for a range of workforce orientations and to help guide people, regardless of their embrace of purpose overall.
        </p>
        <p>
          Your <b>purpose archetype</b> is one of 24 possible archetypes that are based on a specific combination of drivers across 3 dimensions of purpose: who you endeavor to impact (impact), the core values that drive why you seek to make an impact (value), and how you bring your purpose to fruition (craft).
        </p>
        <p>
          Your preference for a given driver falls on a continuum between the options within that dimension. Your <b>dominant purpose driver</b> is the one you indicated the strongest preference for. While your dominant driver is not likely to change during your career, the other drivers might, depending on your degree of preference.
        </p>
        <p>
          To learn more, explore <a className="link" href="http://www.imperative.com/resources" target="_blank">
            Imperative research & whitepapers
            <i className="far fa-external-link" aria-hidden />
          </a>
        </p>
      </div>

      <div className="PurposeProfile__infoDimensions margin-top-5">
        <div className="PurposeProfile__page">
          <h3 className="section-head">The 3 dimensions of purpose</h3>
          <div className="PurposeProfile__row col-1-1-1">
            {['who', 'why', 'how'].map(dim => {
              return (
                <div key={dim} className="margin-top-3">
                  <div className="section-intro">
                    {t.find(`pattern.leadershipStyle.dominantDriver.${dim}.name`)}
                  </div>
                  <div className={cx("PurposeProfile__driver info", dim)}>
                    {DRIVERS[dim].map(driver =>
                      <div key={driver} className="PurposeProfile__driverIcon">
                        <img src={DRIVER_ICONS[driver]} alt="" />
                        <div className="section-intro">{driver}</div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default HowItWorks;
