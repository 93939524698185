import api from 'api';

const optInStorageKey = 'opt_in_requested';

const setOptInStorageTimestamp = () => {
  try {
    window.localStorage.setItem(optInStorageKey, Date.now());
  } catch {
    return;
  }
};

export const getOptInStorageTimestamp = () => {
  try {
    const data = window.localStorage.getItem(optInStorageKey);
    return JSON.parse(data);
  } catch {
    return null;
  }
};

export const removeOptInStorageTimestamp = () => {
  window.localStorage.removeItem(optInStorageKey);
};

export const requestOptIn = ({ memberSlug, reason = '', endConversations = false }) => {
  return api
    .post(`/members/${memberSlug}/opt-in`, { optIn: true, reason, endConversations })
    .then(setOptInStorageTimestamp);
};

export const requestOptOut = ({ memberSlug, reason, endConversations = false, activeConversationId }) => {
  return api
    .post(
      `/members/${memberSlug}/opt-out`,
      {
        optIn: false,
        reason,
        endConversations,
        peerConversationId: activeConversationId
      })
    .then(setOptInStorageTimestamp);
};
