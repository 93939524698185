import React, {useState, useEffect} from 'react';
import {Editor, EditorState, RichUtils, convertToRaw, getDefaultKeyBinding, convertFromRaw, SelectionState, Modifier} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import cx from 'classnames';

import './RichTextEditor.sass';


const RichTextEditor = (props) => {
  const [editorState, setEditorState] = useState(() => {
    if(props.initialValue) {
      const markdownString = props.initialValue;
      const rawData = markdownToDraft(markdownString);
      const contentState = convertFromRaw(rawData);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });
  const [focused, focus] = useState(props.initiallyFocused || false);
  const [editorRef, setEditorRef] = useState(null);

  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const rawObject = convertToRaw(content);
  const markdownString = draftToMarkdown(rawObject);

  useEffect(() => {
    props.onChange && props.onChange(markdownString);
  }, [editorState])

  const focusEditor = () => {
    editorRef && editorRef.focus();
  }

  const handleList = (type) => {
    const newState = RichUtils.toggleBlockType(editorState, type);
    const content = newState.getCurrentContent();
    const block = content.getLastBlock();
    const newSelection = new SelectionState({
      anchorKey: block.getKey(),
      anchorOffset: 0,
      focusKey: block.getKey(),
      focusOffset: type == 'unordered-list-item' ? 1 : 2
    });
    const contentReplaced = Modifier.replaceText(
      content,
      newSelection,
      ''
    );
    const newEditorState = EditorState.push(
      newState,
      contentReplaced,
      'replace-text'
    )
    setEditorState(EditorState.moveFocusToEnd(newEditorState));
  }

  const handleKey = (e) => {
    const contentState = editorState.getCurrentContent();
    const block = contentState.getLastBlock();
    const blockType = block.getType();

    if (e.key === 'Backspace') {
      if(block.getLength() > 0 || blockType == 'unstyled') {
        return getDefaultKeyBinding(e);
      }
      const newState = RichUtils.toggleBlockType(editorState, blockType);
      setEditorState(newState);
      return 'removeBlockStyle';
    }
    return getDefaultKeyBinding(e)
  }

  const handleBeforeInput = (char) => {
    const contentState = editorState.getCurrentContent();
    const block = contentState.getLastBlock();
    const blockText = block.getText();
    const blockType = block.getType();

    if(char == ' ') {
      if ((blockText == '-' || blockText == '*') && blockType != 'unordered-list-item') {
        handleList('unordered-list-item');
        return 'handled';
      }
      else if ((blockText == '1.') && blockType != 'ordered-list-item') {
        handleList('ordered-list-item');
        return 'handled';
      }
    };
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState && command != 'underline') {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  const toggleStyle = (s) => (e) => {
    e.preventDefault();
    const newState = s.block ? RichUtils.toggleBlockType(editorState, s.type) : RichUtils.toggleInlineStyle(editorState, s.type);
    setEditorState(newState);
  }

  const styles = [
    {label: 'Bold', type: 'BOLD', icon: 'far fa-bold', block: false},
    {label: 'Italic', type: 'ITALIC', icon: 'far fa-italic', block: false},
    {label: 'UL', type: 'unordered-list-item', icon: 'far fa-list-ul', block: true},
    {label: 'OL', type: 'ordered-list-item', icon: 'far fa-list-ol', block: true},
    {label: 'H4', type: 'header-four', icon: 'far fa-heading', block: true},
    {label: 'Blockquote', type: 'blockquote', icon: 'fas fa-quote-right', block: true},
  ]

  return (
    <div
      className={cx("RichTextEditor", {focused})}
      onClick={focusEditor}
    >
      <div
        className="RichTextEditor__input"
      >
        <Editor
          ref={setEditorRef}
          editorState={editorState}
          onFocus={() => focus(true)}
          onBlur={() => focus(false)}
          placeholder={props.placeholder}
          spellCheck={true}
          keyBindingFn={handleKey}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          handleBeforeInput={handleBeforeInput}
          blockStyleFn={(block) => `RichTextEditor__block ${block.getType()}`}
        />
      </div>

      <div className={cx("RichTextEditor__controls", {focused})}>
        {styles.map((s) => {
          const blockType = s.block && editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();
          const currentStyle = editorState.getCurrentInlineStyle();
          return (
            <span
              key={s.type}
              onMouseDown={toggleStyle(s)}
              className={cx("RichTextEditor__button btn-icon", {
                active: s.block ? s.type == blockType : currentStyle.has(s.type)
              })}
            >
              <span className={s.icon} />
            </span>
          )
        })}
        <span className="RichTextEditor__button-separator" />
      </div>
    </div>
  )
}

export default RichTextEditor;
