import React, {useContext} from 'react';
import cx from 'classnames';
import {arc, pie} from 'd3'
import moment from 'moment';
import TranslationsContext from 'translations';

import './Goals.sass';


const Progress = ({step = 0, total = 1}) => {
  const WIDTH = 500;
  const HEIGHT = 500;
  const radius = WIDTH / 2;
  const innerRadius = radius - 50;

  const data = [(step/total), (1 - step/total)];

  const chartArc =
    arc()
    .outerRadius(radius)
    .innerRadius(innerRadius)

  const chartPie =
    pie()
    .sort(null)
    .value((d) => d)

  return (
    <svg
      preserveAspectRatio="xMinYMin meet"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={WIDTH}
      height={HEIGHT}
      className="ConversationGoals__donut"
    >
      <g transform={`translate(${WIDTH / 2}, ${HEIGHT / 2})`}>
        {chartPie(data).map((d, i) =>
          <path
            key={i}
            d={chartArc.cornerRadius(innerRadius)(d)}
            className={i == 0 ? 'filled' : 'hidden'}
          />
        )}
        <path d={chartArc(chartPie([1])[0])} />
      </g>
    </svg>
  )
}


const ConversationGoals = (props) => {
  const {className, conversation, conversations = [], datePaired} = props;
  const t = useContext(TranslationsContext);

  const convIInSet = conversations.findIndex((conv) => conversation && conv.label == conversation.convLabel)

  const dateCompleteBy = datePaired && moment(datePaired).add(90, 'd');

  if(!conversation)
    return null;

  return (
    <div className={cx("ConversationGoals", className)}>
      <div className="ConversationGoals__title">
        <span className="label">
          {t.find(`peerconversation.set.${conversation.set}.title`)}
        </span>
        <div className="ConversationGoals__progress">
          <Progress step={convIInSet + 1} total={conversations.length} />
          <span>{convIInSet + 1}/{conversations.length}</span>
        </div>
      </div>
      <h3>{t.find(`peerconversation.conversation.${conversation.convLabel}.title`)}</h3>
      {conversation.goals && conversation.goals.length > 0 &&
        <div className="ConversationGoals__goals">
          {conversation.goals.length > 1
          ?
            <ul>
              {conversation.goals.map((goal, i) =>
                <li key={i}>{goal}</li>
              )}
            </ul>
          :
            <div>{conversation.goals[0]}</div>
          }
        </div>
      }
      {datePaired && dateCompleteBy &&
        <div className="ConversationGoals__dates">
          <div className="ConversationGoals__date">
            <div className="label">Matched</div>
            {moment(datePaired).format('MMM D, YYYY')}
          </div>
          <div className="ConversationGoals__date">
            <div className="label">Complete By</div>
            {moment(dateCompleteBy).format('MMM D, YYYY')}
          </div>
        </div>
      }
    </div>
  )
}

export default ConversationGoals;
