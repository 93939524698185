import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withState} from 'recompose'
import Header from 'theme/Header'
import Footer from 'theme/Footer'
import {getUser} from 'api'

import './NetworkError.sass'


export default composeComponent 'NetworkError',
  ({error}) ->
    user = getUser()
    React.createElement("div", {"className": "NetworkError page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container", "id": "main"},
        React.createElement("h1", null, "Ooops!"),
        React.createElement("p", null, "Could not load the app."),
        React.createElement("p", null, (error.data?.message ? 'Network Error'))
      ),
      React.createElement(Footer, null)
    )