import axios from "axios";

export const getThumbnail = (id) => {
  return axios.get(`https://vimeo.com/api/v2/video/${id}.json`)
  .then(({data}) => {
    return {
      id: data[0].id,
      image: data[0].thumbnail_large,
      title: data[0].title,
      duration: data[0].duration
    }
  })
}
