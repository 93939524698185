import React from 'react'
import {withRouter} from 'react-router-dom'
import {composeComponent} from 'utils/react-tools'
import {withHandlers, withState} from 'recompose'
import {reduxForm} from 'redux-form'
import {requestEmailConfirmation} from 'auth/requests'
import Layout, {Result, Link} from 'auth/Layout'
import {BtnContainer} from 'auth/Form'


ConfirmationForm = composeComponent 'AuthPages.ConfirmationForm',
  reduxForm
    form: 'confirmation'
  ({submitting, handleSubmit}) ->
    React.createElement("form", {"onSubmit": (handleSubmit)},
      React.createElement(BtnContainer, null,
        React.createElement("button", {"disabled": (submitting), "className": "btn btn_primary btn_solid_pink"}, """
          Request confirmation
""")
      )
    )


export default composeComponent 'AuthPages.ConfirmRequest',
  withState 'state', 'setState', null

  withHandlers
    submit: ({submit, setState}) -> (data) ->
      requestEmailConfirmation(data).then setState, setState

  ({state, submit}) ->
    React.createElement(Layout, {"headline": "Request email confirmation"},
      (switch state
        when 'success'
          React.createElement(Result, null,
            ("We've sent you an email with further instructions.")
          )
        when 'alreadyConfirmed'
          React.createElement(Result, null, """
            Email is already confirmed.
""", React.createElement("br", null),
            ('Go to the '),
            React.createElement(Link, { \
              "style": "pink",  \
              "href": "/"
            }, """
              home page
"""), """
            .
""")
        else
          React.createElement(ConfirmationForm, {"onSubmit": (submit)})
      )
    )
