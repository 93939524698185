`/** @jsx R.DOM */`
import R from 'react'
import cx from 'classnames'
import PT from 'prop-types'
import debounce from 'debounce-promise'

require './Search.sass'


SEARCH_DEBOUNCE = 500


export default class Search extends R.Component
  @propTypes:
    className: PT.string
    search: PT.func.isRequired
    placeholder: PT.string

  constructor: ->
    super()
    @_search = debounce @_search, SEARCH_DEBOUNCE
    @state =
      query: ''
      focus: false
      searching: false

  search: (query = '') =>
    @setState {query, searching: true}
    @_search query

  _onChange: (e) =>
    @search e.target.value

  _search: (query) =>
    @props.search query
    @setState searching: false
    , => @setState searching: false

  clear: =>
    @search()

  render: ->
    {className, placeholder} = @props
    {query, searching} = @state
    {_onChange, search, clear} = @

    R.createElement("div", {"className": (cx "Search", className)},
      R.createElement("input", { \
        "className": "Search__input",  \
        "placeholder": (placeholder ? 'Search'),  \
        "value": (query),  \
        "onChange": (_onChange),  \
        "aria-label": "search"
      }),
      R.createElement("a", {"className": "Search__button"},
        (if not searching and query
          R.createElement("i", { \
            "className": "fal fa-times",  \
            "onClick": (clear)
          })
        else if searching
          R.createElement("i", { \
            "className": "fal fa-spinner fa-spin",  \
            "onClick": (clear)
          })
        )
      )
    )
