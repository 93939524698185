import React, { useEffect, useState } from 'react';
import {split, trim} from 'ramda';
import {getUser} from 'api'
import * as translateConversations from './conversations';
import {getTranslation as getT, changeLanguage as changeL} from './helpers';
import { useLocation } from 'react-router-dom';

export const TranslationsContext = React.createContext({});

const userDynamicRegex = /\{\{([a-zA-Z0-9\_\-\.]*?)\}\}/g;
const userReplaceRegex = /[\{\{\}\}]/g;
const dynamicKeyRegex = /\{\[([a-zA-Z0-9\_\-\.]*?)\]\}/g;
const dynamicReplaceRegex = /[\[\{\}\]]/g;

const userDynamicFields = {
  dominant: (user) => (user && user.pattern && user.pattern.dominant) || '',
  dominantdriver: (user) => (user && user.pattern && user.pattern[user.pattern.dominant.toLowerCase()]) || '',
  archetype: (user, {t}) => {
    if(!user || !user.pattern) return '';
    const pattern = `${user.pattern.who}${user.pattern.why}${user.pattern.how}`;
    return t.find(`pattern.archetypes.${pattern}.title`)
  },
  whodriver: (user) => (user && user.pattern && user.pattern.who) || '',
  whydriver: (user) => (user && user.pattern && user.pattern.why) || '',
  howdriver: (user) => (user && user.pattern && user.pattern.how) || '',
  "member.organizationname": (user) => (user && user.organization && user.organization.name) || '',
  "member.firstname": (user) => (user && user.firstName) || '',
  "member.lastname": (user) => (user && user.lastName) || '',
  "member.selectedfunction": (user) => {
    const jobFunctionText = (user && user.jobInformation && user.jobInformation.jobFunctionText) || user.jobFunctionText;
    if(jobFunctionText) return jobFunctionText;
    return (user && user.jobInformation && user.jobInformation.jobFunction) || '';
  },
  "member.selectedrole": (user) => (user && user.selectedRole) || '',
  "member.jobfunction": (user, {t}) => {
    const jobFunctionText = (user && user.jobInformation && user.jobInformation.jobFunctionText) || user.jobFunctionText;
    if(jobFunctionText) return jobFunctionText;
    const func = (user && user.jobInformation && user.jobInformation.jobFunction) || user.jobFunction;
    if (func == 'Other')
      return 'your career'
    else if (func)
      return t.find(`dictionaries.jobfunction.${func}.name`);
    else return '';
  },
  "member.tenure": (user) => (user && user.tenure) || '',
  "conversation.current.oneword.before": (user, {conv} = {}) => translateConversations.getOneWordBeforeForConversation(user, conv),
  "conversation.current.oneword.after": (user, {conv} = {}) => translateConversations.getOneWordAfterForConversation(user, conv),
  "conversation.recent.oneword.before": (user, {convs} = {}) => translateConversations.getOneWordBeforeForRecentConversation(user, convs),
  "conversation.recent.oneword.after": (user, {convs} = {}) => translateConversations.getOneWordAfterForRecentConversation(user, convs),
  "conversations.onewords.before": (user, {convs} = {}) => translateConversations.getOneWordBeforeForAllConversations(user, convs),
  "conversations.onewords.after": (user, {convs} = {}) => translateConversations.getOneWordAfterForAllConversations(user, convs),
  "conversation.current.rig.relationships": (user, {conv, t} = {}) => translateConversations.getRIGRelationshipsForConversation(user, conv, t),
  "conversation.current.rig.impact": (user, {conv, t} = {}) => translateConversations.getRIGImpactForConversation(user, conv, t),
  "conversation.current.rig.growth": (user, {conv, t} = {}) => translateConversations.getRIGGrowthForConversation(user, conv, t),
  "conversations.rig.relationships.bydates": (user, {convs, t} = {}) => translateConversations.getRIGRelationshipsByDatesForConversation(user, convs, t),
  "conversations.rig.impact.bydates": (user, {convs, t} = {}) => translateConversations.getRIGImpactByDatesForConversation(user, convs, t),
  "conversations.rig.growth.bydates": (user, {convs, t} = {}) => translateConversations.getRIGGrowthByDatesForConversation(user, convs, t),
}

export const TranslationsProvider = ({children}) => {
  const location = useLocation();
  const [values, setValues] = useState({});
  const [userValues, setUserValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [translatingError, setTranslationError] = useState(false);
  const [useUserValues, setUseUserValues] = useState(false);

  useEffect(() => {
    setTranslationError(false);
  }, [location]);

  const find = (key, member, {i = 0, extracted = false, _reactReplace = false, ...args} = {}) => {
    try {
      if (!key)
        return '';
      if (i > 10)
        return key;

      const user = member || getUser();

      let value =
        useUserValues
          ? (userValues[key.toLowerCase()] || key)
          : (values[key.toLowerCase()] || key)

      const memberContent = value.match(userDynamicRegex);

      if (memberContent) {
        memberContent.map((k) => {
          const memberKey = k.replace(userReplaceRegex, '').toLowerCase();
          if (user && userDynamicFields[memberKey]) {
            const replacedValue = userDynamicFields[memberKey](user, {t: {find}, ...args});
            value = value.slice(0).replace(k, replacedValue);
            value = find(value, user, {i: i + 1, ...args});
          }
        })
      }

      const dynamicContent = value.match(dynamicKeyRegex);

      if (dynamicContent) {
        if (!extracted){
          dynamicContent.map((k) => {
            const dynamicKey = k.replace(dynamicReplaceRegex, '');
            value = value.slice(0).replace(k, find(dynamicKey, user, {i: i + 1, ...args}));
          });
        } else {
          newValue = 'Based on your purpose profile:\n\n'
          dynamicContent.map((k) => {
            const dynamicKey = k.replace(dynamicReplaceRegex, '');
            newValue = newValue.concat(find(dynamicKey, user, {i: i + 1, ...args}));
          });
          value = newValue;
        }
      }

      return value;
    } catch (err) {
      console.error("Translation Lookup Error", key, err);
      setTranslationError(true);
      return '';
    }
  }

  const exists = (key) => {
    if(useUserValues)
      return !!userValues[key.toLowerCase()]
    else
      return !!values[key.toLowerCase()]
  }

  const existsDynamicContent = (key) => {
    const value =
      useUserValues
        ? (userValues[key.toLowerCase()] || key)
        : (values[key.toLowerCase()] || key);
    const dynamicContent = value && value.match(/{\[.*?\]}/g);
    return !!(dynamicContent && dynamicContent.length > 0);
  }

  const splitMarkdownToParts = (key, member, args = {}) => {
    const value =
      useUserValues
        ? (userValues[key.toLowerCase()] || key)
        : (values[key.toLowerCase()] || key);

    return value.split(/\r?\n/)
      .map(trim)
      .filter(a => a.length)
      .map((line, i) => ({
        title: i === 0,
        dynamic: existsDynamicContent(line),
        value: find(line, member, args)
      }))
  }

  const getSplitted = (key, member, args = {}) => {
    const v = find(key, member, args)
    const value = v && v.replace(/\n+$/, '');
    return value ? split('\n', value) : [''];
  }

  const getTranslation = (user) => {
    setLoading(true);
    return getT(user)
    .then(([translations, userTranslations]) => {
      setValues((translations && translations.values) || {});
      setUserValues((userTranslations && userTranslations.values) || {});
      setLoading(false);
    }, (error) => {
      setError(error);
      setLoading(false);
    });
  }

  useEffect(() => {
    const user = getUser();
    getTranslation(user);
  }, []);

  const changeLanguage = (language, user) => {
    setLoading(true);
    return changeL(language, user)
    .then(({values}) => {
      setUserValues(values);
      setLoading(false);
    });
  }

  const findCustomQuote = (key, org, quotePart) => {
    const result = find(key);
    if (result === key) {
      if (quotePart === 'overview')
        return org && org.purposeOverview && org.purposeOverview.overview;
      else
        return org && org.purposeOverview && org.purposeOverview.quote[quotePart];
    }
    return result;
  }

  const findCustom = (key, orgDrivers, drivers, driver) => {
    const result = find(key);

    if(result === key)
      return orgDrivers[drivers[driver].id.toLowerCase()];

    return result;
  }

  return (
    <TranslationsContext.Provider
      value={{
        find: find,
        loading: loading,
        getSplitted: getSplitted,
        existsDynamicContent: existsDynamicContent,
        error: error,
        exists: exists,
        translatingError: translatingError,
        splitMarkdownToParts: splitMarkdownToParts,
        changeLanguage: changeLanguage,
        getTranslation: getTranslation,
        useUserValues: setUseUserValues,
        findCustomQuote: findCustomQuote,
        findCustom: findCustom,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}
