import React from 'react';
import T from 'tcomb';
import cx from 'classnames';


const RadioCustom = ({options, value, onChange, question, t, error}) => {
  const radioValue = value && value.answer;
  const textValue = (value && value.answerText) || '';

  return (
    <div>
      {options.map((o, i) => {
        const selected = o.value == radioValue;
        return (
          <React.Fragment key={i}>
            <label
              className={cx("Assessment__option", {selected})}
              onClick={() => onChange({...value, answer: o.value})}
            >
              <input
                type="checkbox"
                checked={selected}
                onChange={() => onChange({...value, answer: o.value, hasTextField: Boolean(o.hasTextField), answerText: undefined})}
                id={`Assessment__option${o.value}`}
              />
              <div>
                <p className="Assessment__radioLabel">
                  {t.find(`surveys.purpose-assessment.questions.${question.id}.options.${o.value}`)}
                </p>
                <i className={cx("Assessment__radioMark",
                  {
                    'fas fa-check-circle': selected,
                    'fal fa-circle': !selected
                  }
                )} />
              </div>
            </label>
            {selected && o.hasTextField &&
              <div
                key="openField"
                className={cx("Assessment__optionText", {filled: textValue, error: error == 'required'})}
              >
                <input
                  type="text"
                  placeholder="Please share"
                  id="Assessment__optionOpenField"
                  maxLength="50"
                  value={textValue}
                  onChange={(e) => onChange({...value, answerText: e.target.value})}
                />
                <div className="Assessment__optionTextInfos">
                  <p>Maximum 50 characters</p>
                  {error == 'required' &&
                    <p className="Assessment__optionTextInfos_error">This field is required</p>
                  }
                </div>
              </div>
            }
          </React.Fragment>
        );
      })}
    </div>
  )
}

export default RadioCustom;
