import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'

import Quote from './Quote'
import {LEADERSHIP} from './content'


export default ({className}) ->
  drivers = ['who', 'why', 'how']

  React.createElement("div", {"className": (cx "LongReport__leadership", className)},
    React.createElement("h1", {"className": "LongReport__title LongReport__title_withWhitespace"}, "Purpose Based Leadership"),
    React.createElement("div", {"className": "LongReport__row"},
      React.createElement("div", {"className": "LongReport__columnTwo LongReport__columnTwo_bordered"},
        React.createElement(Quote, {"author": "Sheryl Sandberg", "className": "LongReport__quote_big"}, """
          Leadership is the expectation that you can
          use your voice for good. That you can make the world a better place.
"""),

        (LEADERSHIP.paragraphs.map (p, i) ->
          React.createElement("p", {"key": (i), "className": "LongReport__text LongReport__text_bold LongReport__text_left"},
            (p)
          )
        )
      ),
      React.createElement("div", {"className": "LongReport__columnTwo LongReport__columnTwo_bordered"},
        (drivers.map (driver) ->
          React.createElement("div", {"key": (driver), "className": "LongReport__text LongReport__text_bold"},
            React.createElement("span", {"className": (cx "LongReport__subtitle LongReport__subtitle_smaller", driver)}, """
              : """, (LEADERSHIP.drivers[driver].title)
            ),
            React.createElement("p", {"className": "LongReport__text LongReport__text_bold LongReport__text_left"},
              (LEADERSHIP.drivers[driver].info)
            )
          )
        )
      )
    )
  )
