`/** @jsx R.DOM */`
import R from 'react'
import PT from 'prop-types'
import * as _ from 'ramda'
import cx from 'classnames'
import t from 'translations'


export default ({report, t}) ->
  {imperative} = report ? {}

  R.createElement("section", {"className": "ShortReport__archetype"},
    R.createElement("div", {"className": "ShortReport__row"},
      R.createElement("h2", {"className": "ShortReport__title ShortReport__title_small ShortReport__title_pink"},
        ("#{imperative?.prefix.your} #{imperative?.who} #{imperative?.why} #{imperative?.how}.")
      )
    ),
    R.createElement("div", {"className": "ShortReport__row ShortReport__row_bottom"},
      R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_pink ShortReport__subtitle_small ShortReport__subtitle_uppercase"},
        (t.find 'report.shortReport.archetype.subtitle')
      ),
      R.createElement("p", {"className": "ShortReport__text ShortReport__text_noMargin ShortReport__text_bigger ShortReport__text_borderedPink"},
        (report?.drivers?.how?.explication)
      )
    )
  )
