import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import {composeComponent} from 'utils/react-tools'
import {lifecycle} from 'recompose'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import Page from 'reports/PrintPage'
import DownloadButton from 'reports/DownloadReportButton'
# import t from 'translations'
import LanguageDropdown from 'theme/LanguageDropdown'
import {
  URL_ARCHETYPES,
  URL_DRIVERS,
  IMG_LOGO_BLUE
} from 'theme/assets/assets'
import TranslationsContext from 'translations'

import Agenda from './Agenda'
import Archetype from './Archetype'
import DriverOverview from './DriverOverview'
import Driver from './Driver'
import Overview from './Overview'
import Purpose from './Purpose'

import {
  ACTIVATE_PURPOSE
  AGENDA
  ANIMALS
  DRIVERS_OVERVIEW_QUESTIONS
  DRIVERS
  INFO
} from './content'

import './ShortReport.sass'

languageDropdownVisible = window.config.pdf && window.config.pdf.language

export default composeComponent 'ShortReport',
  withRouter
  ({report, pdfUrl, redirectUrl, user, noButton, currentLanguage, languageOptions, changeLanguage}) ->
    React.createElement(TranslationsContext.Consumer, null,
      ((t) ->
        unless report
          return null

        number = 2 # page numbering start

        dimensions = ['who', 'why', 'how']

        org =
          if report.orgs.length > 1
            report.orgs.find ({code}) -> code != 'imperative'
          else
            report.orgs[0]

        fullReport =
          if org?
            (org.roles.filter (r) -> r != 'Applicant').length > 0
          else
            false

        name = "#{report?.firstName} #{report?.lastName}"
        date = moment().format 'MMMM Do, YYYY'
        coverDate = moment().format 'MMMM D, YYYY'

        footer = (number, visible) ->
          if visible
            React.createElement("span", {"className": (cx \
            "ShortReport__footer"
            , right: number %% 2
            )},
              (unless number %% 2
                React.createElement("span", {"className": "ShortReport__footerNumber"}, (number))
              ),
              (t.find 'report.shortReport.footer'), " ", (name), " • ", (date), """ • Ⓒ Imperative
""", (if number %% 2
                React.createElement("span", {"className": "ShortReport__footerNumber"}, (number))
              )
            )

        React.createElement("section", {"className": "ShortReport"},
          (unless noButton
            React.createElement(DownloadButton, {"name": "ShortReport_#{report?.firstName}_#{report?.lastName}"})
          ),
          (if (org.code.includes('griffith') or languageDropdownVisible) and languageOptions?.length > 1
            React.createElement(LanguageDropdown, { \
              "className": "ShortReport__languageDropdown",  \
              "options": (languageOptions),  \
              "onChange": (changeLanguage),  \
              "placeholder": "Change language - #{currentLanguage}"
            })
          ),

          React.createElement(Page, { \
            "background": (React.createElement("div", {"className": "ShortReport__coverBg"})),  \
            "className": "ShortReport__cover"
          },
            React.createElement("div", { \
              "className": "ShortReport__archetypeTitle",  \
              "style": (backgroundImage: "url(#{URL_ARCHETYPES}/#{report?.archetype.title}.png)")
            }),
            React.createElement("div", {"className": "ShortReport__page ShortReport__page_bottom"},
              React.createElement("h1", {"className": "ShortReport__coverTitle"},
                (if fullReport
                  React.createElement("span", null, (t.find 'report.shortReport.title.1'))
                else
                  React.createElement("span", null, (t.find 'report.shortReport.title.2'))
                )
              ),
              React.createElement("h2", {"className": "ShortReport__coverName"}, (name)),
              React.createElement("h3", {"className": "ShortReport__coverText"},
                (t.find 'report.shortReport.coverText.1'), " ", (org?.name ? 'work'), ".", React.createElement("br", null),
                (t.find 'report.shortReport.coverText.2'), " ", (coverDate)
              ),
              React.createElement("img", {"src": (IMG_LOGO_BLUE), "className": "ShortReport__coverLogo", "alt": "Imperative logo"})
            )
          ),

          React.createElement(Page, {"footer": (footer number, true)},
            React.createElement(Overview, {"org": (org), "t": (t)})
          ),

          React.createElement(Page, {"footer": (footer number+=1, false), "className": "ShortReport__page_pink"},
            React.createElement(Archetype, {"report": (report), "t": (t)})
          ),

          React.createElement(Page, {"footer": (footer number+=1, true)},
            React.createElement(DriverOverview, {"report": (report), "user": (user), "t": (t)})
          ),

          React.createElement(Page, {"footer": (footer number+=1, true)},
            React.createElement(Driver, {"drivers": (_.pick ['who', 'why'], report?.drivers ? {}), "org": (org), "user": (user), "t": (t)})
          ),

          React.createElement(Page, {"footer": (footer number+=1, true)},
            React.createElement(Driver, {"drivers": (_.pick ['how'], report?.drivers ? {}), "org": (org), "user": (user), "t": (t)})
          ),

          React.createElement(Page, {"footer": (footer number+=1, true)},
            React.createElement(Purpose, {"t": (t)})
          ),

          React.createElement(Page, {"footer": (footer number+=1, false)},
            React.createElement("div", {"className": "ShortReport__columnWide ShortReport__columnWide_padding-right"},
              React.createElement("h2", {"className": "ShortReport__subtitle"}, (t.find 'report.shortReport.subtitle')),
              React.createElement("div", {"className": "ShortReport__profileInfo"},
                ([0..5].map (i) ->
                  React.createElement("p", {"key": (i), "className": (cx "ShortReport__text ShortReport__text_condensed", "ShortReport__text_margin-top": i==0)},
                    (t.find "report.shortReport.info.paragraphs.#{i}.text")
                  )
                )
              )
            ),
            React.createElement("div", {"className": "ShortReport__columnNarrow"},
              (dimensions.map (dimension) ->
                DRIVERS[dimension].map (driver) ->
                  React.createElement("div", {"key": (dimension+driver), "className": (cx "ShortReport__infoDriver", dimension)},
                    React.createElement("img", {"src": "#{URL_DRIVERS}/#{dimension}#{driver}.png", "className": "ShortReport__driverIcon ShortReport__driverIcon_small", "alt": (driver)}),
                    React.createElement("span", {"className": "ShortReport__subtitle ShortReport__subtitle_smaller ShortReport__subtitle_black ShortReport__subtitle_bold ShortReport__driverName"},
                      (t.find "assessment.page.driver.title.#{if driver is 'Structure' then 'structue' else driver}")
                    )
                  )
              ),

              React.createElement(Agenda, {"title": (t.find "report.shortReport.info.agenda.title"), "items": ([0..1]), "className": "ShortReport__rights", "t": (t)})
            )
          )

        )

      )
    )
