import React, { useEffect, useMemo, useState, useContext, useRef } from 'react';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import TranslationsContext from 'translations';
import {components} from 'react-select';
import Modal, * as M from 'theme/Modal';
import Loader from 'theme/Loader';
import {snoozeAssessmentNotification, isAssessmentNotificationSnoozed} from 'api';
import {Select, SelectCustom} from 'theme/Form/FormNew';
import {updateDemographicData} from 'assessment/requests';
import { updateUser } from 'user/requests';
import { loadTimezones } from "utils/react-tools";
import { getAllowedTimezones } from 'utils/timezones';
import { Field, Formik } from 'formik';

import './DemographicQuestionsCheck.sass';

const shouldCheckInformation = (confirmedOn, onboardingUpdateAt) => {
  if(!confirmedOn) return true;
  if(!onboardingUpdateAt) return false;
  return dayjs().diff(dayjs(confirmedOn), 'months') >= 6 || dayjs(onboardingUpdateAt).isAfter(dayjs(confirmedOn));
}

const TIMEZONES = getAllowedTimezones(loadTimezones());

const mappedTimezones = TIMEZONES.map(o => ({label: o.description, value: o.name, offset: (o.currentTimeOffsetInMinutes / 60), currentTimeOffsetInMinutes: o.currentTimeOffsetInMinutes}));

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.label || props.data.value}
    </components.SingleValue>
  )
}

const FIELDS = {
  "107": 'Tenure',
  "110": 'Job Function',
  "112": 'Role'
}

const validateDemographic = (question) => (value) => {
  if(!question) return;
  if(!value.answer) {
    return "Please select an option";
  }
  const foundOption = question.options && question.options.find(o => o.id == value.answer && (!o.answerText || o.answerText == value.answerText));
  if(!foundOption || (foundOption && foundOption.hasTextField && !value.answerText)) {
    return "This field is required"
  };
}

const DemographicForm = ({onSubmit, snooze, formError, initialValues = {}}) => {
  const t = useContext(TranslationsContext);

  const translateOption = (questionId, questionType, optionId) => {
    const option = t.find(`surveys.purpose-assessment.questions.${questionId}.options.${optionId}`);
    if (questionType && t.exists(`dictionaries.${questionType.replace('-', '')}.${option}.name`))
      return t.find(`dictionaries.${questionType.replace('-', '')}.${option}.name`)
    else return option;
  };

  const demographicQuestionMap = (q, i) => {
    const optionsWithCustom = q.options && q.options
    .map(o => ({...o, value: o.id, label: translateOption(q.id, q.questionType, o.id)}))
    .reduce((list, o) => {
      if(!o.customOptions) {
        return [...list, o];
      }
      return [
        ...list,
        ...o.customOptions.filter(co => !!co).map((co, i) => ({id: o.id, value: `${o.id}_${i}`, label: co, answerText: co}))
      ]
    }, []);

    return {
      ...q,
      name: `demographic.${i}`,
      placeholder: 'Select Option',
      options: optionsWithCustom,
      validate: validateDemographic({...q, options: optionsWithCustom})
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {props => {
        const errors = Object.keys(props.errors).length > 0;
        return (
          <form onSubmit={props.handleSubmit}>
            <Loader loading={props.isSubmitting} />
            {initialValues.demographic && initialValues.demographic.map((q, i) =>
              <Field
                key={q.id}
                {...demographicQuestionMap(q, i)}
                label={FIELDS[q.id]}
                component={SelectCustom}
                selectClassName="react-select"
                disabled={props.isSubmitting}
                withoutError
              />
            )}
            <Field
              name="timeZone"
              label="Time Zone"
              placeholder="Select Option"
              options={mappedTimezones}
              component={Select}
              selectClassName="react-select"
              selectComponents={{SingleValue}}
              disabled={props.isSubmitting}
            />
            {formError &&
              <div className='DemographicQuestionsUpdateModal__error error'>
                <i className='fas fa-exclamation-triangle' />
                Something went wrong. Please try again later or contact Imperative team.
              </div>
            }
            <div className='DemographicQuestionsCheck__btns'>
              <button
                className='btn-secondary'
                onClick={snooze}
                disabled={props.isSubmitting}
              >Remind me later</button>
              <button
                className='btn-primary'
                disabled={props.isSubmitting || errors}
                type="submit"
              >
                {(props.dirty || errors) ? 'Update' : 'Looks good'}
              </button>
            </div>
          </form>
        )}
      }
    </Formik>
  )
}

const DemographicQuestionsUpdateModal = ({assessmentData = [], user}) => {
  const [modalVisible, showModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    if(!user) return;
    const demographic = assessmentData.filter(q => q.ignoreArv).sort((a, b) => a.order - b.order);
    if(shouldCheckInformation(user.profileConfirmedOn, user.organizationSettings.onboardingUpdateAt) && !isAssessmentNotificationSnoozed()) {
      setQuestions(demographic);
      showModal(true);
    }
  }, [assessmentData, user]);

  const snooze = () => {
    if(snoozeAssessmentNotification()) {
      showModal(false);
    }
  }

  const timezoneObj = useMemo(() => {
    const userTimezone = user.timeZone || moment.tz.guess();
    const selectedTimezone =
      mappedTimezones.find(t => t.value === userTimezone)
      || mappedTimezones.find(t => t.currentTimeOffsetInMinutes ==  moment().tz(userTimezone).utcOffset())
    return selectedTimezone;
  }, [user.timeZone]);

  const initialValues = useMemo(() => ({
    timeZone: timezoneObj && timezoneObj.value,
    demographic: questions.filter(q => FIELDS[q.id])
  }), [questions, timezoneObj]);

  const prepareDemographicQuestionDto = (question, omitPrev = false) => {
    const prevValue = initialValues.demographic.find(d => d.id == question.id);
    if(!omitPrev && prevValue && prevValue.answer == question.answer && prevValue.answerText == question.answerText) {
      return null;
    }

    const dto = {
      value: question.answer,
      answerText: question.answerText || undefined,
      questionId: question.id,
      modified: moment().valueOf()
    };

    return dto;
  }

  const submit = (values = {demographic: []}) => {
    const demographicDto = values.demographic.map(d => prepareDemographicQuestionDto(d)).filter(Boolean);
    const demographicDtoNotChanged = values.demographic.map(d => prepareDemographicQuestionDto(d, true));
    const demographicReq = demographicDto.length ? updateDemographicData(demographicDto) : updateDemographicData(demographicDtoNotChanged);
    const updateTimezone = values.timeZone != initialValues.timeZone ? updateUser({timeZone: values.timeZone}) : Promise.resolve('Not changed');

    return Promise.all([
      demographicReq,
      updateTimezone
    ]).then(
      () => {
        showModal(false);
      },
      (err) => {
        setError(err);
      }
    );
  }

  return (
    <Modal
      isOpen={modalVisible && assessmentData.length > 0}
      className="DemographicQuestionsCheck DemographicQuestionsUpdateModal no-padding"
      overlayClassName="DemographicQuestionsCheck__overlay"
    >
      <M.Header className="white">
        Does everything look correct?
      </M.Header>
      <M.Content>
        <div className="relative">
          <p className='p-new'>
            Your profile settings help us optimize your Imperative experience. Please take a moment to confirm or update your information below.
          </p>
          <DemographicForm
            user={user}
            onSubmit={submit}
            snooze={snooze}
            formError={error}
            initialValues={initialValues}
          />
        </div>
      </M.Content>
    </Modal>
  )
}

export default DemographicQuestionsUpdateModal;
