import React from 'react'
import r from 'r-dom'
import {composeComponent} from 'utils/react-tools'
import {withRouter, Link} from 'react-router-dom'
import debounce from 'debounce-promise'
import {Collapse} from 'react-collapse'
import qs from 'qs'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, mapProps, withProps, withState} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import {getUser} from 'api'
import * as ME from 'api/metrics'
import Avatar from 'theme/Avatar'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Subnav from 'theme/Subnav'
import Toast from 'theme/Toast'
import Search from 'Search'
import {fetchUser} from 'auth/requests'
import {createWorkshop, getWorkshop, addAllMembers, addMembersToWorkshop, deleteMemberFromWorkshop, getAllMembersInOrg} from 'workshops/requests'
import {reminder} from 'invitations/requests'
import Invite from 'workshops/Invite'

import './Workshop.sass'

MESSAGES =
  error:
    loading: 'Cannot load user group data. Please try again later or contact the administrator.'
    addMembers: 'Could not add member(s) to user group. Please try again later.'
    deleteMember: 'Something went wrong while deleting this member. Please try again later.'
    remindMember: 'Something went wrong while sending reminder. Please try again later.'
    invite: 'Cannt send invitation(s). Please try again later or contact the administrator.'
  success:
    addMembers: 'Successfully added member(s).'
    deleteMember: 'Successfully deleted member.'
    remindMember: 'Reminder sent'

DEFAULT_MEMBER_PULL_COUNT = 100

export default composeComponent 'Workshop',
  withRouter
  withState 'pendingExpanded', 'expandPending', false
  withState 'completedExpanded', 'expandCompleted', false

  withStateHandlers
    user: null
    loading: false
    loadingMembers: false
    workshop: null
    status: null
    query: ""
    orgMembers: []
    allMembersLoaded: false
    results: []
    assessmentStatus: null
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setLoadingMembers: -> (loadingMembers) -> {loadingMembers}
    setWorkshop: -> (workshop) -> {workshop}
    setStatus: -> (type, reason, error) ->
      status: {type, reason, error}
    clearStatus: -> -> status: null
    setQuery: -> (query) -> {query}
    setOrgMembers: -> (orgMembers) -> {orgMembers}
    setAllMembersLoaded: -> (allMembersLoaded) -> {allMembersLoaded}
    setResults: -> (results) -> {results}
    filterByAssessmentStatus: ({assessmentStatus}) -> (newStatus) ->
      if assessmentStatus == newStatus
        {assessmentStatus: null}
      else {assessmentStatus: newStatus}

  withHandlers
    filterByQuery: -> (query, members) ->
      if(!members)
        return []
      if(!query)
        return members
      members.filter((m) =>
        m.firstName?.toLowerCase().includes(query.toLowerCase()) ||
        m.lastName?.toLowerCase().includes(query.toLowerCase()) ||
        m.email?.toLowerCase().includes(query.toLowerCase())
      )

    loadMembers: ({setOrgMembers, setLoadingMembers, setAllMembersLoaded}) -> (workshop, allMembers) ->
      ME.reportStateChange "CPL PeerCoaching/Cohort: members loading"
      setLoadingMembers true
      getAllMembersInOrg workshop.organizationCode, allMembers, DEFAULT_MEMBER_PULL_COUNT
      .then ({results, total}) ->
        ME.reportStateChange "CPL PeerCoaching/Cohort: members loaded"
        setOrgMembers results
        if(results.length == total)
          setAllMembersLoaded true
        else
          setAllMembersLoaded false
      .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/Cohort: members loading error", err, {workshop: workshop?.id}
      .finally ->
        setLoadingMembers false

    loadWorkshop: ({setWorkshop}) -> (id) ->

  withHandlers
    setError: ({setStatus}) -> (reason, details = undefined) -> (error) ->
      ME.reportIntermediateError "CPL PeerCoaching/Cohort: #{reason} error", error, details
      setStatus 'error', reason, error
    setSuccess: ({setStatus}) -> (reason) -> ->
      setStatus 'success', reason

  withHandlers
    loadData: ({loadMembers, user, setUser, setError, startLoading, endLoading, setWorkshop, match: {params}}) -> ->
      ME.reportStateChange "CPL PeerCoaching/Cohort: data loading", {workshop: params?.workshopId}
      startLoading()

      a = new Promise (resolve, reject) ->
        fetchUser()
        .then (user) ->
          setUser user
          ME.reportStateChange "CPL PeerCoaching/Cohort: workshop loading", {workshop: params?.workshopId}
          getWorkshop params.workshopId
          .then (workshop) ->
            setWorkshop workshop
            loadMembers workshop
            workshop
          .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/Cohort: workshop loading error", err, {workshop: params?.workshopId}
          .then resolve, reject

      a
      .then _, setError 'loading'
      .finally endLoading

  withHandlers
    invite: ({workshop, loadData, setWorkshop, loadMembers, user, setResults, setError}) -> (emails) ->
      ME.reportStateChange "CPL PeerCoaching/Cohort: members inviting"
      new Promise((resolve, reject) =>
        addMembersToWorkshop workshop, emails
        .then(
          (result) ->
            ME.reportStateChange "CPL PeerCoaching/Cohort: members invited", {result}
            a = getWorkshop workshop.id
            .then (workshop) ->
              setWorkshop workshop
            b = loadMembers(workshop)
            Promise.all([a, b]).then(
              -> resolve(result)
              reject
            )
            setResults result
            result
          , (err) ->
            setError('invite')(err)
            reject(err)
        )
      )

    addMember: ({user, workshop, loadData, setError, setSuccess}) -> (member) ->
      ME.reportStateChange "CPL PeerCoaching/Cohort: member adding"
      addMembersToWorkshop workshop, [member.email]
      .then loadData
      .then ME.reportStateChange "CPL PeerCoaching/Cohort: member added"
      .then setSuccess 'addMembers'
      .catch setError('addMembers')

    addAll: ({user, workshop, loadData, setError, setSuccess}) -> ->
      ME.reportStateChange "CPL PeerCoaching/Cohort: all member in org adding"
      addAllMembers workshop
      .then loadData
      .then ME.reportStateChange "CPL PeerCoaching/Cohort: all member in org added"
      .then setSuccess 'addMembers'
      .catch setError('addMembers', {details: "all members adding error"})

    deleteMember: ({workshop, loadData, setError, setSuccess}) -> (member) ->
      ME.reportStateChange "CPL PeerCoaching/Cohort: member deleting"
      deleteMemberFromWorkshop workshop, member
      .then loadData
      .then ME.reportStateChange "CPL PeerCoaching/Cohort: member deleted"
      .then setSuccess 'deleteMember'
      .catch setError 'deleteMember'

    sendReminder: ({setError, setSuccess}) -> (member) ->
      ME.reportStateChange "CPL PeerCoaching/Cohort: reminder sending"
      reminder [member]
      .then (responses) ->
        response = responses[0]
        if response?.type is 'error' then (setError 'remindMember') response
        if response?.type is 'success' then (setSuccess 'remindMember') response

    loadAllMembers: ({loadMembers, workshop}) -> ->
      loadMembers workshop, true

    boldString: -> (text, query) ->
      if(!text || !query)
        return React.createElement("span", null, (text || ""))

      groupedString = text.split(RegExp("(#{query})", 'i', 'g'))

      React.createElement("span", null,
        (groupedString.map((fragment, i) =>
          if(fragment.toLowerCase() == query.toLowerCase())
            React.createElement("b", {"key": (i)}, (fragment))
          else
            React.createElement("span", {"key": (i)}, (fragment))
        ))
      )

  lifecycle
    componentDidMount: ->
      @props.loadData()

  mapProps (props) -> _.evolve(
    workshop:
      members: _.pipe(
        _.sortBy (u) -> [u?.slug, u?.firstName?.toUpperCase(), u?.lastName?.toUpperCase()]
      )
  ) props

  withProps ({query, workshop, orgMembers}) ->
    orgMemberNotInCohort = orgMembers.filter((m) =>
      !workshop?.members.find((workshopM) => workshopM.id == m.id)
    )
    {orgMemberNotInCohort}

  ({
    loading, status, user, workshop
    addMember, addAll, clearStatus, deleteMember, sendReminder
    history, query, orgMembers, allMembersLoaded
    loadAllMembers, setWorkingMemberList
    loadDisplayLists, boldString, invite,
    setQuery, orgMemberNotInCohort, filterByQuery, results,
    pendingExpanded, completedExpanded, expandCompleted, expandPending,
    filterByAssessmentStatus, assessmentStatus
  }) ->

    searchLoc = qs.parse history.location.search, ignoreQueryPrefix: true
    navigatedFromConversations = searchLoc.conversation?

    filteredWorkshopMembers = filterByQuery(query, workshop?.members)?.sort((a, b) => b.created - a.created)
    filteredOrgMembers = filterByQuery(query, orgMemberNotInCohort)

    [assessed, notAssessed] = _.partition(((m) => m.assessmentResult?), filteredWorkshopMembers)

    [registered, notRegistered] = _.partition(((m) => m.registeredAt?), notAssessed)

    filterFunc = (m) => !assessmentStatus ||
      if assessmentStatus == 'notAssessed'
        m.registeredAt && !m.assessmentResult
      else if assessmentStatus == 'notRegistered'
        !m.registeredAt
      else if assessmentStatus == 'assessed'
        m.assessmentResult

    filteredNotAssessed = notAssessed.filter(filterFunc)
    filteredAssessed = assessed.filter(filterFunc)

    React.createElement("div", {"className": "Workshop page"},
      React.createElement(Header, {"user": (user), "cpl": (true), "queryParams": (workshop: workshop?.id)}),

      React.createElement("div", {"className": "container", "id": "main"},
        React.createElement(Loader, {"loading": (loading)}),

        React.createElement("div", {"className": "Workshop__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "Workshop__title"},
              React.createElement(Link, {"to": (if navigatedFromConversations then "/peer-coaching/manage?workshop=#{workshop.id}" else "/peer-coaching/user-groups")},
                React.createElement("span", {"className": "fas fa-chevron-left"}),
                (workshop?.name), " Cohort (", (workshop?.members?.length), " member", (if workshop?.members?.length is 1 then '' else 's'), """)
""")
            )
          )
        ),

        React.createElement(Invite, { \
          "workshop": (workshop),  \
          "invite": (invite)
        }),

        React.createElement("div", {"className": "Workshop__row grey-section margin-top-4"},
          React.createElement("div", {"className": "Workshop__searchContainer"},
            React.createElement("div", {"className": "Workshop__heading"},
              React.createElement("h2", null, "Search Members")
            ),
            React.createElement(Search, { \
              "className": "Workshop__search",  \
              "search": (setQuery),  \
              "placeholder": "Search for members",  \
              "name": "searchBox",  \
              "value": (query)
            })
          ),
          React.createElement("div", {"className": "Workshop__searchBtns"},
            React.createElement("button", {"className": "btn btn_block btn_secondary btn_outlined_bluePurple", "onClick": (addAll)}, """
              Add all in the organization
""")
          )
        ),

        React.createElement("div", {"className": "Workshop__matching"},
          React.createElement("div", {"className": "Workshop__column"},
            React.createElement("div", {"className": "grey-section"},
              React.createElement("h3", null, """
                Current Members in """, (workshop?.name), """ Cohort
""", React.createElement("span", null, "(", (filteredWorkshopMembers.length), ")")
              ),
              React.createElement("div", {"className": "Workshop__progress"},
                React.createElement("div", { \
                  "className": (cx "Workshop__progressPart assessed", {active: assessmentStatus == 'assessed', notActive: assessmentStatus?}),  \
                  "style": ({width: "#{assessed.length / workshop?.members?.length * 100}%"}),  \
                  "onClick": (-> filterByAssessmentStatus 'assessed')
                },
                  (if (assessed.length + registered.length) / workshop?.members?.length > 0.2
                    React.createElement("div", {"className": "Workshop__progressLabel bottom"},
                      React.createElement("span", null, "Assessed")
                    )
                  )
                ),
                React.createElement("div", { \
                  "className": (cx "Workshop__progressPart notAssessed", {active: assessmentStatus == 'notAssessed', notActive: assessmentStatus?}),  \
                  "style": ({width: "#{registered.length / workshop?.members?.length * 100}%"}),  \
                  "onClick": (-> filterByAssessmentStatus 'notAssessed')
                },
                  (if (registered.length + notRegistered.length) / workshop?.members?.length * 100 > 0.2
                    React.createElement("div", {"className": "Workshop__progressLabel top"},
                      React.createElement("span", null, "Registered")
                    )
                  )
                ),
                React.createElement("div", { \
                  "className": (cx "Workshop__progressPart notRegistered", {active: assessmentStatus == 'notRegistered', notActive: assessmentStatus?}),  \
                  "style": ({width: "#{notRegistered.length / workshop?.members?.length * 100}%"}),  \
                  "onClick": (-> filterByAssessmentStatus 'notRegistered')
                },
                  (if notRegistered.length / workshop?.members?.length * 100 > 0.2
                    React.createElement("div", {"className": "Workshop__progressLabel bottom"},
                      React.createElement("span", null, "Not registered")
                    )
                  )
                )
              )
            ),
            React.createElement("div", {"className": "card"},
              React.createElement("div", {"className": "Workshop__listHeading", "onClick": (-> expandPending !pendingExpanded)}, """
                Pending Assessments (""", (filteredNotAssessed.length), """)
""", React.createElement("span", {"className": "far fa-chevron-down"})
              ),
              React.createElement(Collapse, {"isOpened": (pendingExpanded)},
                React.createElement("div", {"className": "Workshop__list"},
                  (filteredNotAssessed.map (m) ->
                    React.createElement("div", {"key": (m.slug), "className": "Workshop__listItem"},
                      React.createElement("div", null,
                        React.createElement(Avatar, {"for": (m), "size": "xs", "className": "Workshop__avatar", "withDominant": true}),
                        (boldString m.firstName, query), " ", (boldString m.lastName, query), " (", (boldString m.email, query), """)
"""),
                      React.createElement("div", {"className": "Workshop__listItemActions"},
                        (if m.assessmentResult
                          React.createElement("div", {"className": "Workshop__listItemAction success"},
                            React.createElement("span", {"className": "far fa-check-circle"}),
                            React.createElement("span", {"className": "Workshop__listItemActionTooltip"}, """
                              Assessment completed
""")
                          )
                        else if m.registeredAt
                          React.createElement(React.Fragment, null,
                            React.createElement("div", {"className": "Workshop__listItemAction warning"},
                              React.createElement("span", {"className": "far fa-exclamation-triangle"}),
                              React.createElement("span", {"className": "Workshop__listItemActionTooltip"}, """
                                Registered
""")
                            ),
                            React.createElement("button", { \
                              "className": "Workshop__listItemAction action btn-icon",  \
                              "onClick": (-> sendReminder m),  \
                              "aria-label": "send-reminder"
                            },
                              React.createElement("span", {"className": "far fa-share"}),
                              React.createElement("span", {"className": "Workshop__listItemActionTooltip"}, """
                                Send reminder
""")
                            )
                          )
                        else
                          invited = results.find((res) => res.email == m.email)
                          if invited && invited.status is 'Created'
                            React.createElement("span", {"className": "invited"}, """
                              invited
""")
                          else
                            React.createElement(React.Fragment, null,
                              React.createElement("div", {"className": "Workshop__listItemAction error"},
                                React.createElement("span", {"className": "far fa-exclamation-triangle"}),
                                React.createElement("span", {"className": "Workshop__listItemActionTooltip"}, """
                                  Not registered
""")
                              ),
                              React.createElement("button", { \
                                "className": "Workshop__listItemAction action btn-icon",  \
                                "onClick": (-> sendReminder m),  \
                                "aria-label": "send-reminder"
                              },
                                React.createElement("span", {"className": "far fa-share"}),
                                React.createElement("span", {"className": "Workshop__listItemActionTooltip"}, """
                                  Send reminder
""")
                              )
                            )
                        ),
                        React.createElement("button", { \
                          "className": "btn-icon error",  \
                          "onClick": (-> deleteMember m),  \
                          "aria-label": "trash"
                        },
                          React.createElement("i", {"className": "far fa-trash-alt"})
                        )
                      )
                    )
                  )
                )
              )
            ),
            React.createElement("div", {"className": "card"},
              React.createElement("div", {"className": "Workshop__listHeading", "onClick": (-> expandCompleted !completedExpanded)}, """
                Completed Assessments (""", (filteredAssessed.length), """)
""", React.createElement("span", {"className": "far fa-chevron-down"})
              ),
              React.createElement(Collapse, {"className": "Workshop__list", "isOpened": (completedExpanded)},
                React.createElement("div", {"className": "Workshop__list"},
                  (filteredAssessed.map (m) ->
                    React.createElement("div", {"key": (m.slug), "className": "Workshop__listItem"},
                      React.createElement("div", null,
                        React.createElement(Avatar, {"for": (m), "size": "xs", "className": "Workshop__avatar", "withDominant": true}),
                        (boldString m.firstName, query), " ", (boldString m.lastName, query), " (", (boldString m.email, query), """)
"""),
                      React.createElement("div", {"className": "Workshop__listItemActions"},
                        (if m.assessmentResult
                          React.createElement("div", {"className": "Workshop__listItemAction success"},
                            React.createElement("span", {"className": "far fa-check-circle"}),
                            React.createElement("span", {"className": "Workshop__listItemActionTooltip"}, """
                              Assessment completed
""")
                          )
                        else if m.registeredAt
                          React.createElement("div", {"className": "Workshop__listItemAction warning"},
                            React.createElement("span", {"className": "far fa-exclamation-triangle"}),
                            React.createElement("span", {"className": "Workshop__listItemActionTooltip"}, """
                              Registered
""")
                          )
                        else
                          invited = results.find((res) => res.email == m.email)
                          if invited && invited.status is 'Created'
                            React.createElement("span", {"className": "invited"}, """
                              invited
""")
                          else
                            React.createElement("div", {"className": "Workshop__listItemAction error"},
                              React.createElement("span", {"className": "far fa-exclamation-triangle"}),
                              React.createElement("span", {"className": "Workshop__listItemActionTooltip"}, """
                                Not registered
""")
                            )
                        ),
                        React.createElement("button", { \
                          "className": "btn-icon error",  \
                          "onClick": (-> deleteMember m),  \
                          "aria-label": "trash"
                        },
                          React.createElement("i", {"className": "far fa-trash-alt"})
                        )
                      )
                    )
                  )
                )
              )
            )
          ),

          React.createElement("div", {"className": "Workshop__column"},
            React.createElement("div", {"className": "grey-section"},
              React.createElement("h3", null, """
                Org Members not in cohort
""", React.createElement("span", null, "(", (filteredOrgMembers.length), ")")
              ),
              (if (!allMembersLoaded)
                React.createElement("div", null,
                  React.createElement("button", {"className": "btn btn_secondary btn_solid_yellow", "onClick": (loadAllMembers)}, """
                    Load ALL users
"""),
                  React.createElement("div", {"className": "Workshop__note"}, "(This can take some time depending on your organization size)")
                )
              )
            ),
            React.createElement("div", {"className": "card"},
              React.createElement("div", {"className": "Workshop__list"},
                (filteredOrgMembers.map (m) ->
                  React.createElement("div", {"key": (m.slug), "className": "Workshop__listItem"},
                    React.createElement("div", null,
                      React.createElement(Avatar, {"for": (m), "size": "xs", "className": "Workshop__avatar", "withDominant": true}),
                      (boldString m.firstName, query), " ", (boldString m.lastName, query), " (", (boldString m.email, query), """)
"""),
                    React.createElement("div", {"className": "Workshop__listItemActions"},
                      React.createElement("button", { \
                        "className": "btn-icon success",  \
                        "onClick": (-> addMember m),  \
                        "aria-label": "add member"
                      },
                        React.createElement("i", {"className": "far fa-user-plus"})
                      )
                    )
                  )
                )
              )
            )
          )
        )
      ),

      React.createElement(Footer, null),

      React.createElement(Toast, { \
        "visible": (status?),  \
        "type": (status?.type),  \
        "message": (MESSAGES[status?.type]?[status?.reason]),  \
        "close": (clearStatus)
      })

    )
