import React, {useState, useEffect} from 'react';
import Modal, * as M from 'theme/Modal';
import {IMG_AARON} from 'theme/assets/assets';

import "./Onboarding.scss";


const content = [
  {
    title: "Welcome to Peer Coaching on Imperative",
    body: (name) =>
      <>
        <p>
          {name}, you are about to begin your first peer coaching conversation and we are thrilled you are here.
        </p>
        <p>
          Let's review a few quick and simple tips to get you started!
        </p>
      </>
  },
  {
    title: "Make Your Job As Awesome As You Are",
    body: () =>
      <>
        <p>
          Peer coaching is YOUR time. It is a chance to step back from your day-to-day and do your best thinking.
        </p>
        <ul>
          <li>Problem solve current challenges</li>
          <li>Celebrate your successes</li>
          <li>Organize your thinking</li>
        </ul>
      </>
  },
  {
    title: "Imperative Makes Peer Coaching Easy",
    body: (name) =>
      <>
        <p>
          {name} - You’re ready exactly as you are. We designed peer coaching to be simple, requiring no preparation or training.
        </p>
        <p>The platform will prompt you to:</p>
        <ul>
          <li>Ask guided questions and tips</li>
          <li>Capture brief notes for your partner</li>
          <li>Answer the questions when it’s your turn</li>
        </ul>
      </>
  },
  {
    title: "Peer Coaches are Active Listeners",
    body: () =>
      <>
        <p>
          Being a helpful peer coach can be simple - it involves being present, being an active listener, and creating an environment for your coaching partner to share openly. Here are some tips to get you started:
        </p>
        <ul>
          <li>Follow the prompts</li>
          <li>Honor privacy</li>
          <li>Show up authentically</li>
        </ul>
        <p>You’ll find your groove as you follow the process. Jump in and be yourself!</p>
      </>
  },
]


const Onboarding = (props) => {
  const {user} = props;
  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState(0);
  const pagesCount = content.length;

  useEffect(() => {
    setOpened(props.opened);
  }, [props.opened])

  const nextPage = () => {
    if(page + 1 >= pagesCount)
      setOpened(false);
    else
      setPage(page + 1);
  }

  return (
    <Modal
      isOpen={opened}
      close={() => setOpened(false)}
      withClose={false}
      className="Questions__onboardingModal"
    >
      <M.Header>
        {content[page] && content[page].title}
      </M.Header>
      <M.Content>
        <div className="Questions__onboardingModalRow">
          <img src={IMG_AARON} alt="" />
          <div className="Questions__onboardingModalContent">
            {content[page] && content[page].body(user && user.firstName)}
          </div>
        </div>
        <div className="Questions__onboardingModalBtn">
          <button
            onClick={nextPage}
            className="btn btn_secondary btn_solid_bluePurple"
          >
            Next
          </button>
        </div>
      </M.Content>
    </Modal>
)}

export default Onboarding;
