import React from 'react';
import api from 'api';
import * as _ from 'ramda';
import customGriffith from './custom-griffith';

DIR_MAP =
  LeftToRight: 'ltr'
  RightToLeft: 'rtl'

LangNamesMap =
  Portugese: 'Portuguese'

apiLangToProperName = (lang) ->
  LangNamesMap[lang] ? lang

properNameToApiLang = (name) ->
  Object.keys(LangNamesMap).find((key) -> LangNamesMap[key] is name) ? name

saveLangDataToStorage = (storage, name, data) ->
  try
    window[storage].setItem name, JSON.stringify data
    data
  catch err
    null

getLangDataFromStrorage = (storage, name) ->
  try
    JSON.parse window[storage].getItem(name) ? ''
  catch err
    null

setDirection = (language) ->
  mappedLangName = apiLangToProperName language
  try
    languageData = _.find _.propEq('name', mappedLangName), getLangDataFromStrorage 'localStorage', 'imperative-language-options'
    document.body.dir = DIR_MAP[languageData.direction]
  catch err
    document.body.dir = 'ltr'

export getLanguage = (slug) ->
  if slug
    api.get "/members/#{slug}/settings/language"
    .then (data) -> apiLangToProperName data.language
    .catch -> Promise.reject()
  else
    try
      data = JSON.parse localStorage.getItem 'imperative-language-data'
      if data
        return Promise.resolve(apiLangToProperName data?.name)
      else return Promise.resolve 'English'
    catch err
      console.error err
      Promise.reject err

export changeLanguage = (language, user) ->
  setDirection language

  getReadyTranslation properNameToApiLang(language), user
  .catch ->
    getReadyTranslation 'English', user
    setDirection 'English'

withoutLanguage = ->
  # To remove in future
  languageAvailable = location.host.search(/localhost/) isnt -1 || location.host.search(/app-test/) isnt -1 # to remove in future

  if languageAvailable
    return navigator.language.slice 0, 2
  else
    return 'en'

getNavigatorLanguageName = (availableLanguages) ->
  languageCode = withoutLanguage()
  languageData = availableLanguages.find((l) -> l.code is languageCode) ? availableLanguages.find (l) -> l.code is 'en'
  languageData?.name ? 'English'

getLanguageName = (user, availableLanguages) ->
  storageName = if user then 'sessionStorage' else 'localStorage'
  storageItemName = if user then 'imperative-language-user-data' else 'imperative-language-data'

  promise = new Promise (resolve, reject) ->
    if user
      getLanguage user.slug
      .then (lang) ->
        if _.find _.propEq('name', lang), availableLanguages
          resolve lang
        else reject()
      .catch ->
        storageLang = getLangDataFromStrorage storageName, storageItemName
        if storageLang
          resolve storageLang.name
        else reject()
    else
      resolve 'English'

  promise.then(
    (lang) -> lang
    ->
      language = getNavigatorLanguageName availableLanguages
      if user
        setLanguage user.slug, language
      Promise.resolve language
  )

getLanguageCode = (user = true) ->
  try
    if user
      getUserLanguageDataFromStorage()?.code
    else
      getDefaultLanguageDataFromStorage()?.code
  catch err
    return 'en'

getUserLanguageDataFromStorage = ->
  getLangDataFromStrorage 'sessionStorage', 'imperative-language-user-data'

saveUserDataToStorage = (data) ->
  saveLangDataToStorage 'sessionStorage', 'imperative-language-user-data', data

getDefaultLanguageDataFromStorage = ->
  getLangDataFromStrorage 'localStorage', 'imperative-language-data'

saveDefaultLanguageDataToStorage = (data) ->
  saveLangDataToStorage 'localStorage', 'imperative-language-data', data

isSameVersion = (localData) -> (translation) ->
  translation.version is localData?.version and translation.modified is localData.modified and translation.name is localData.name

getDefaultLanguageData = (availableLanguages) ->
  localData = getDefaultLanguageDataFromStorage()
  defaultLanguage = 'English'

  if localData and _.find(isSameVersion(localData), availableLanguages) and \
  not(localData.values instanceof Array) and \
  localData.values instanceof Object
    return Promise.resolve localData

  else
    getReadyTranslation defaultLanguage
    .then (langData) ->
      languageOption = _.find _.propEq('name', defaultLanguage), availableLanguages ? []

      saveDefaultLanguageDataToStorage _.merge(languageOption, langData)
      langData

getUserLanguageData = (user, availableLanguages, fromStorage) ->
  localData = getUserLanguageDataFromStorage()
  unless user
    return Promise.resolve {values: []}

  languageNameReq = new Promise (resolve, reject) ->
    getLanguage user.slug
    .then(
      (lang) ->
        if _.find _.propEq('name', lang), availableLanguages
          lang
        else null
      -> Promise.resolve null
    )
    .then (lang) ->
      if !lang            #if user doesn't have set language - use browser language
        language = getNavigatorLanguageName availableLanguages
        setLanguage user.slug, language
        .then(
          -> resolve language
          -> resolve 'English'
        )
      else resolve lang

  new Promise (resolve, reject) ->
    languageNameReq.then (userLanguage) ->
      if localData and \
      (fromStorage or (userLanguage is localData.name and _.find(isSameVersion(localData), availableLanguages))) and \
      not(localData.values instanceof Array) and \
      localData.values instanceof Object
        return resolve localData

      else
        lang = if fromStorage then localData?.name else userLanguage
        getReadyTranslation lang
        .then (langData) ->
          languageOption = _.find _.propEq('name', lang), availableLanguages ? []
          saveUserDataToStorage _.merge(languageOption, langData)
          resolve langData

export getTranslation = (user, fromStorage = false) -> # returnUserTranslations: Boolean
  new Promise (resolve, reject) ->
    getAvailableLanguages()
    .then(
      (availableLanguages) ->
        saveLangDataToStorage 'localStorage', 'imperative-language-options', availableLanguages
        defaultTransReq = getDefaultLanguageData availableLanguages
        userTransReq = getUserLanguageData(user, availableLanguages, fromStorage)
          .then _.identity, -> Promise.resolve defaultTransReq

        Promise.all([defaultTransReq, userTransReq])
        .then resolve, reject
      , reject
    )

getReadyTranslation = (lang, availableLanguages) ->
  api.get "/cms/translation/map/#{properNameToApiLang lang}", {withCredentials: false}
  .then (langData) ->
    values: langData

getTempTranslation = (lang) ->
  api.get "/cms/translation/map/#{properNameToApiLang lang}", {withCredentials: false}
  .then (response) ->
    availableLanguages = getLangDataFromStrorage 'localStorage', 'imperative-language-options'
    languageOption = _.find _.propEq('name', lang), availableLanguages ? []
    langData = {values: response}

    saveUserDataToStorage _.merge(languageOption, langData)
    langData

export getAvailableLanguages = ->
  api.get "/cms/translation", {withCredentials: false}
  .then (translations) ->
    translations
    .map (t) -> _.merge t, name: apiLangToProperName t.name
    .filter (t) ->
      customGriffith.languages.indexOf(t.name) > -1


export setLanguage = (slug, language) ->
  api.put "/members/#{slug}/settings/language/#{properNameToApiLang language}"
