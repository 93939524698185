import React, {useState, useEffect} from 'react'
import moment from 'moment-timezone';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { sortWith, prop, descend } from 'ramda';
import {Formik, Field, FieldArray, Form} from 'formik';
import {TextField, Select} from 'theme/Form/FormNew';
import { useStore } from 'stores/stores';
import Modal, * as M from 'theme/Modal';
import Avatar from 'theme/Avatar';
import {email} from 'validate';
import {IMG_AARON} from 'theme/assets/assets'
import * as ME from 'api/metrics'
import Loader from 'theme/Loader';
import {inviteColleagues, getInvitations} from './requests';

import './Community.sass';

const emailValid = email('Enter a valid email address please');

const relationshipValidate = (value) => {
  console.log('validate', value);
  if(!value) return 'Select a relationship please';
}

const emailValidate = (value) => {
  if(!value) return 'Enter an email address please';
  return emailValid(value);
}

const RelationshipOptions = [
  "My Manager",
  "My Direct report",
  "Teammate",
  "Friend",
  "Coworker outside of team",
  "Other",
].map(a => ({label: a, value: a}));

const emptyValues = {
  firstName: '',
  lastName: '',
  email: '',
  relationshipType: '',
  note: ''
};

const InvitesList = ({push, remove, form, errors = []}) => {
  const {isSubmitting, values, isValid, dirty} = form;
  return (
    <div>
      <Loader loading={isSubmitting} color="white" />
      {values.invitees.map((invite, i) => {
        const conflict = errors && errors.find(e => e.email == invite.email);
        return (
          <div className={cx("Community__invitee")} key={i}>
            {conflict &&
              <div className='Community__inviteeError error'>
                <i className="far fa-exclamation-circle" aria-hidden />
                Already invited
              </div>
            }
            <div className="Community__inviteeFields">
              <Field
                name={`invitees.${i}.firstName`}
                type="text"
                label="First name"
                placeholder="First name"
                info="Optional"
                component={TextField}
              />
              <Field
                name={`invitees.${i}.lastName`}
                type="text"
                label="Last name"
                placeholder="Last name"
                info="Optional"
                component={TextField}
              />
              <Field
                name={`invitees.${i}.email`}
                type="text"
                label="Email"
                placeholder="Email"
                component={TextField}
                validate={emailValidate}
              />
              <Field
                name={`invitees.${i}.relationshipType`}
                type="text"
                label="Relationship"
                validate={relationshipValidate}
                component={Select}
                placeholder="Select"
                options={RelationshipOptions}
                selectClassName="react-select"
              />
              <Field
                name={`invitees.${i}.note`}
                type="text"
                textarea
                label="Personal Note"
                info="Optional"
                placeholder="Include a personal note to explain why you’ve enjoyed Imperative…"
                maxLength="280"
                component={TextField}
              />
            </div>
            {values.invitees.length > 1 &&
              <button
                className="btn-secondary blue"
                type="button"
                disabled={isSubmitting}
                onClick={() => remove(i)}
                aria-label="Remove"
              >
                <i className="material-icons">clear</i>
              </button>
            }
          </div>
        )
      })}
      <div className="Community__inviteBtns">
        <div>
          <button
            className="btn-secondary blue"
            type="button"
            disabled={isSubmitting}
            onClick={() => push(emptyValues)}
          >
            <i className="material-icons">add</i>
            <span>Add more</span>
          </button>
        </div>
        <button
          className="btn-primary"
          type="submit"
          disabled={!isValid || !dirty || isSubmitting}
        >
          {isSubmitting
            ? <i className="far fa-circle-notch fa-spin" />
            : 'Invite'
          }
        </button>
      </div>
    </div>
  )
}

const InvitesForm = ({onSubmit, error}) => {
  return (
    <Formik initialValues={{invitees: [emptyValues]}} onSubmit={onSubmit}>
      <Form>
        <FieldArray
          name="invitees"
          component={props => <InvitesList {...props} errors={error && error.data} />}
        />
      </Form>
    </Formik>
  )
}

const Invite = () => {
  const {userStore} = useStore();
  const {user} = userStore;

  const [loading, setLoading] = useState(true);
  const [inviting, setInviting] = useState(false);
  const [invites, setInvites] = useState([]);
  const [error, setError] = useState(null);
  const [errorModalVisible, showErrorModal] = useState(false);

  const getInvitees = (slug) => {
    return getInvitations(slug)
    .then(
      setInvites,
      (error) => ME.reportIntermediateError("community-invite-loading_invitations-error", error)
    );
  }

  useEffect(() => {
    if(user) {
      setLoading(true)
      getInvitees(user.slug)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
    }
  }, [user]);

  const submit = (values = {}) => {
    setInviting(true);
    return inviteColleagues(values)
    .then(
      () => {
        getInvitees(user.slug)
        .then(setInviting(false))
      },
      (err) => {
        setInviting(false);
        const e = (err && ((err.data && err.data.message) || err.data)) || 'unknown';
        const error = {data: e, status: err.status};
        setError(error);
        return e;
      })
  };

  useEffect(() => {
    if(error && error.status == 409 && error.data instanceof Array) {
      showErrorModal(true);
    }
  }, [error]);

  const sortedInvitations = sortWith([
    descend(prop('createdAt')),
    descend(prop('invitedAt'))
  ])(invites);

  if(!user) return null;

  const moreThanOneConflict = error && error.data.length > 1;

  return (
    <div className="Community__invite">
      <div className="Community__title">
        <h1 className="page-title">Invite</h1>
        <p>Share your love for building new connections with someone at {user.organization.name}.</p>
      </div>

      <div className="relative">
        <div className='relative'>
          <InvitesForm
            onSubmit={submit}
            error={error}
          />

          <Modal
            isOpen={errorModalVisible}
            close={() => showErrorModal(false)}
            className="no-padding"
          >
            <M.Content className='Community__inviteModal'>
              <img src={IMG_AARON} alt="" />
              <div className='section-intro'>
                It looks like {moreThanOneConflict ? 'these' : 'this'} peer coach{moreThanOneConflict ? 'es' : ''} {moreThanOneConflict ? 'are' : 'is'} already on their way to owning their fulfillment on the platform!
                <ul>
                  {error && error.data && error.data.map((d, i) =>
                    <li key={i}>{d.email}</li>  
                  )}
                </ul>
              </div>
              <div className='text-center'>
                <button
                  type="button"
                  onClick={() => showErrorModal(false)}
                  className="btn-primary"
                >Okay</button>
              </div>
            </M.Content>
          </Modal>
        </div>
        {invites.length> 0 &&
          <div className="Community__invites margin-top-3 relative">
            <Loader loading={loading} color="white" />
            <h2 className="section-head">My invites</h2>
            <div className='Community__invitesList'>
              {sortedInvitations.map((member, i) => (
                <div className='Community__invitesMember' key={i}>
                  <div className='Community__invitesMemberName Community__invitesMemberCell'>
                    <Avatar size="xs" for={{...member, avatars: {normal: member.avatarUrl}}}  />
                    <p>
                      <span className='Community__invitesMemberName_bold'>
                        {(member.firstName || member.lastName)
                          ? <>{member.firstName} {member.lastName}</>
                          : member.email
                        }
                      </span>
                      {(member.firstName || member.lastName) &&
                        <span className='caption-new'>{member.email}</span>
                      }
                    </p>
                  </div>
                  <div className={cx('Community__invitesMemberDate Community__invitesMemberCell', {green: member.createdAt})}>
                    {member.createdAt
                    ? <>Joined {moment(member.createdAt).format('MM/DD/YY')}</>
                    : <>Invited {moment(member.invitedAt).format('MM/DD/YY')}</>
                    }
                  </div>
                  <div className='Community__invitesMemberConvs Community__invitesMemberCell'>
                    {member.completedConversations > 0 &&
                      <>
                        Completed {member.completedConversations} conversation{member.completedConversations > 1 ? 's' : ''}
                      </>
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default observer(Invite);
