import { getAuth, isLoggedIn, setUser } from 'api';
import jwt_decode from 'jwt-decode';
import { makeAutoObservable, reaction } from 'mobx';
import { reportIntermediateError } from 'api/metrics';
import { fetchUser, updateUser, uploadAvatar } from 'user/requests';

class User {
  rootStore = null;
  user = null;
  loading = false;
  error = null;
  roles = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.loading = true;

    (isLoggedIn() ? this.getUser() : Promise.resolve()).finally(() => (this.loading = false));

    makeAutoObservable(this);

    reaction(
      () => this.user,
      (u) => {
        if (u) {
          setUser(u);
          this.getRoles();
        }
      }
    );
  }

  handleError(error) {
    console.error('User store error:', error);
    this.error = error;
  }

  logout() {
    this.user = null;
    this.roles = [];
  }

  getRoles() {
    const auth = getAuth();
    if (!auth || !auth.jwt || !this.user) return;

    const decoded = jwt_decode(auth.jwt);

    const jwtRoles = decoded ? decoded.roles : [];
    const userRoles = this.user ? this.user.roles : [];
    this.roles = [...userRoles, ...jwtRoles];
    return this.roles;
  }

  async getUser() {
    return fetchUser().then(
      (u) => {
        this.user = u;
        return u;
      },
      (error) => {
        reportIntermediateError('Loading user error', error);
        this.error = error;
        return error;
      }
    );
  }

  async updateUser(userData) {
    try {
      this.loading = true;
      await updateUser(userData);
      const user = await this.getUser().catch(this.handleError);
      this.loading = false;
      return user;
    } catch (err) {
      this.handleError(err);
      return this.user;
    } finally {
      this.loading = false;
    }
  }

  async updateSeenTutorial() {
    try {
      await updateUser({ seenTutorial: true });
      const user = await this.getUser().catch(this.handleError);
      return user;
    } catch (err) {
      this.handleError(err);
      return this.user;
    }
  }

  async changeAvatar(fd) {
    try {
      this.loading = true;
      const avatar = await uploadAvatar(fd);
      await this.getUser();
      return avatar;
    } catch (err) {
      this.handleError(err);
      return null;
    } finally {
      this.loading = false;
    }
  }
}

export default User;
