import React, { useState } from 'react';
import cx from 'classnames';
import Modal, * as M from 'theme/Modal';
import {TextField, Select} from 'theme/Form';
import {email, required} from 'validate';
import { reduxForm, FieldArray, Field } from 'redux-form';
import {inviteColleagues} from 'conversations/requests';

import './InviteColleague.sass';

const emailRequired = required('Enter an email address please');
const emailValid = email('Enter a valid email address please');
const relationshipRequired = required('Select a relationship please');

const RelationshipOptions = [
  "My Manager",
  "My Direct report",
  "Teammate",
  "Friend",
  "Coworker outside of team",
  "Other",
].map(a => ({label: a, value: a}));

const InviteeField = ({fields, meta: {submitting}, exit}) => {
  return (
    <div>
      {fields.map((invite, i) => 
        <div className="InviteColleague__invitee" key={i}>
          <Field
            name={`${invite}.email`}
            type="text"
            label="Email"
            validate={[emailRequired, emailValid]}
            placeholder="Email address"
            component={TextField}
          />
          <Field
            name={`${invite}.relationshipType`}
            type="text"
            label="Relationship"
            validate={relationshipRequired}
            component={Select}
            placeholder="Select"
            options={RelationshipOptions}
            selectClassName="react-select"
          />
          {fields.length > 1 &&
            <button
              className="btn-secondary blue"
              type="button"
              disabled={submitting}
              onClick={() => fields.remove(i)}
              aria-label="Remove"
            >
              <i className="material-icons">clear</i>
            </button>
          }
        </div>
      )}
      <div className="InviteColleague__btns">
        <div>
          <button
            className="btn-secondary blue"
            type="button"
            disabled={submitting}
            onClick={() => fields.push({})}
          >
            <i className="material-icons">add</i>
            <span>Add more</span>
          </button>
        </div>
        <button
          className="btn-secondary"
          type="button"
          disabled={submitting}
          onClick={exit}
        >No thanks</button>
        <button
          className="btn-primary"
          type="submit"
          disabled={submitting}
        >
          {submitting
            ? <i className="far fa-circle-notch fa-spin" />
            : 'Invite'
          }
        </button>
      </div>
    </div>
  )
}

const InvitesForm = reduxForm({
  form: 'InvitesForm',
  initialValues: {invitees: [{}]}
})((props) => {
  const {handleSubmit, exit, submitFailed, error} = props;

  return (
    <form onSubmit={handleSubmit}>
      {submitFailed && error &&
        <div className="InviteColleague__error">
          {error}
          <i className="fas fa-exclamation-circle" aria-hidden />
        </div>
      }
      <FieldArray
        name="invitees"
        component={InviteeField}
        exit={exit}
      />
    </form>
  )

});


const InviteColleague = ({user, conversationId, opened, close = () => {}}) => {
  const [confirmationVisible, showConfirmation] = useState(false);
  const submit = (values = {}) => {
    return new Promise((resolve, reject) => {
      const resolveInvite = (success) => {
        showConfirmation(true);
        resolve(success);
      };

      inviteColleagues({peerConversationId: conversationId, ...values})
      .then(
        resolveInvite,
        (error) => {
          if(error && error.status == 409 && error.data instanceof Array) {
            const acceptedData = {invitees: values.invitees.filter(i => !error.data.find(d => d.email == i.email))};
            if(acceptedData.invitees.length) {
              inviteColleagues({peerConversationId: conversationId, ...acceptedData})
              .then(resolveInvite, reject)
            } else resolveInvite();
          }
          else reject(error);
        }
      );
    });
  }

  const closeModal = () => {
    showConfirmation(false);
    close();
  }

  return (
    <Modal
      isOpen={opened}
      className="InviteColleague no-padding"
      onClose={closeModal}
    >
      {confirmationVisible
      ? <M.Header close={closeModal}>Thank you for sharing the love!</M.Header>
      : <M.Header close={closeModal}>Share your love of connecting</M.Header>
      }
      {confirmationVisible
      ?
        <M.Content>
          <p className="p-new">
            You are part of helping grow a community of people prioritizing fulfillment at {user && user.organization && user.organization.name}. We have sent an email inviting your referral(s) to learn more about Imperative.
          </p>
          <div className="InviteColleague__finish">
            <button
              onClick={closeModal}
              className="btn-primary"
            >Finish</button>
          </div>
        </M.Content>
      :
        <M.Content>
          <p className="p-new">
            Help your coworkers make connections on Imperative. Who would you like to invite?
          </p>
          <InvitesForm
            onSubmit={submit}
            exit={closeModal}
          />
        </M.Content>
      }
    </Modal>
  )
}

export default InviteColleague;
