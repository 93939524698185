import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'

import Quote from './Quote'


export default ({className}) ->

  React.createElement("div", {"className": (cx "LongReport__volunteering", className)},
    React.createElement("h1", {"className": "LongReport__title LongReport__title_withBigWhitespace"}, "Volunteering + Philanthropy"),
    React.createElement(Quote, {"className": "LongReport__quote_big", "author": "Aaron Hurst"}, """
      To thrive, we must learn, earn and return throughout
      our careers.
"""),
    React.createElement("p", {"className": "LongReport__text LongReport__text_firstBig"}, """
      Volunteering and giving that is aligned with your purpose drivers creates
      a deeply fulfilling virtuous cycle with substantial career benefits.
      People who bring the same purpose and talents from their jobs into the community
      report that volunteering not only increases their overall fulfillment but
      significantly accelerates their careers and development as leaders (2017 Imperative member survey).
"""),
    React.createElement("hr", {"className": "LongReport__line"}),
    React.createElement("div", {"className": "LongReport__row"},
      React.createElement("div", {"className": "LongReport__columnThree"},
        React.createElement("h3", {"className": "LongReport__subtitle LongReport__subtitle_smaller LongReport__subtitle_pink"}, """
          RELATIONSHIPS
"""),
        React.createElement("p", {"className": "LongReport__text LongReport__text_bold LongReport__text_left"}, """
          Design your volunteering and giving to deepen your current relationships
          inside your organization as well as develop new ones in the community and beyond.
""")
      ),
      React.createElement("div", {"className": "LongReport__columnThree"},
        React.createElement("h3", {"className": "LongReport__subtitle LongReport__subtitle_smaller LongReport__subtitle_pink"}, """
          IMPACT
"""),
        React.createElement("p", {"className": "LongReport__text LongReport__text_bold LongReport__text_left"}, """
          Align your purpose drivers with needs in the community where you can
          have the greatest impact and be most inspired. It will maximize the benefits
          for everyone involved.
""")
      ),
      React.createElement("div", {"className": "LongReport__columnThree"},
        React.createElement("h3", {"className": "LongReport__subtitle LongReport__subtitle_smaller LongReport__subtitle_pink"}, """
          GROWTH
"""),
        React.createElement("p", {"className": "LongReport__text LongReport__text_bold LongReport__text_left"}, """
          Be intentional about how your investment in the community can provide
          opportunities to develop your purpose powers and strengthen as a leader. It
          is an ideal way to get a purpose power-up.
""")
      )
    )
  )
