import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import Avatar from 'theme/Avatar';
import Loader from 'theme/Loader';
import CollapsibleSection from './CollapsibleSection';

const Text = (props) => {
  const {
    save,
    info,
    btnLabel,
    input,
    successMsg,
    placeholder,
    disabled,
    label,
    name,
    ariaLabel,
    meta: { touched, error, invalid, submitFailed },
  } = props;

  const [saving, setSaving] = useState(false);
  const [opened, setOpened] = useState(false);
  const [successToastVisible, showSuccessToast] = useState(false);
  const timeout = useRef();

  const onClick = useCallback(() => {
    if (!save) return;
    setSaving(true);
    save(input.value).then(
      () => {
        setSaving(false);
        setOpened(false);
        showSuccessToast(true);
        timeout.current = setTimeout(() => showSuccessToast(false), 8000);
      },
      () => setSaving(false)
    );
  }, [input, name]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      timeout.current = null;
    };
  }, []);

  useEffect(() => {
    if (!touched) {
      setOpened(false);
    }
  }, [touched]);

  useEffect(() => {
    if (submitFailed && error) {
      setOpened(true);
    }
  }, [submitFailed, error]);

  return (
    <CollapsibleSection
      label={label}
      ariaLabel={ariaLabel}
      opened={opened}
      open={() => setOpened(true)}
      close={() => setOpened(false)}
    >
      {successMsg && successToastVisible && (
        <div className="Settings__toast success">
          <i className="fas fa-check-circle" aria-hidden />
          {successMsg}
        </div>
      )}
      {opened && (
        <div className="Settings__field">
          <Loader loading={saving} color="white" />
          {info && <p>{info}</p>}
          <input
            className={cx('Settings__field-text', { err: touched && error })}
            type="text"
            value={input.value}
            onChange={input.onChange}
            aria-label={label}
            {...input}
          />
          {touched && error && (
            <span className="Settings__field-error">
              <i className="fas fa-exclamation-circle" aria-hidden />
              {error}
            </span>
          )}
          <button
            type="button"
            className="Settings__field-btn btn-primary"
            onClick={onClick}
            disabled={disabled || saving || invalid}
          >
            {btnLabel}
          </button>
        </div>
      )}
      {!opened && input.value}
    </CollapsibleSection>
  );
};

const MultiText = (props) => {
  const {
    save,
    info,
    btnLabel,
    input,
    successMsg,
    placeholder,
    disabled,
    label,
    name,
    ariaLabel,
    meta: { touched, error, invalid, submitFailed },
  } = props;

  const [saving, setSaving] = useState(false);
  const [opened, setOpened] = useState(false);
  const [successToastVisible, showSuccessToast] = useState(false);
  const timeout = useRef();
  const displayValue = Object.keys(input.value || {})
    .map((a) => input.value[a])
    .join(' ');

  const onClick = useCallback(() => {
    if (!save) return;
    setSaving(true);
    save(input.value).then(
      () => {
        setSaving(false);
        setOpened(false);
        showSuccessToast(true);
        timeout.current = setTimeout(() => showSuccessToast(false), 8000);
      },
      () => setSaving(false)
    );
  }, [input, name]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      timeout.current = null;
    };
  }, []);

  useEffect(() => {
    if (!touched) {
      setOpened(false);
    }
  }, [touched]);

  useEffect(() => {
    if (submitFailed && error) {
      setOpened(true);
    }
  }, [submitFailed, error]);

  return (
    <CollapsibleSection
      label={label}
      ariaLabel={ariaLabel}
      opened={opened}
      open={() => setOpened(true)}
      close={() => setOpened(false)}
    >
      {successMsg && successToastVisible && (
        <div className="Settings__toast success">
          <i className="fas fa-check-circle" aria-hidden />
          {successMsg}
        </div>
      )}
      {opened && (
        <div className="Settings__field">
          <Loader loading={saving} color="white" />
          {info && <p>{info}</p>}
          <div className="Settings__fieldRow">
            {Object.keys(input.value || {}).map((name) => {
              return (
                <div className="Settings__fieldRow-item" key={name}>
                  <input
                    className={cx('Settings__field-text', { err: touched && error && error[name] })}
                    type="text"
                    placeholder={placeholder[name]}
                    value={input.value[name]}
                    aria-label={placeholder[name]}
                    onChange={(e) => input.onChange({ ...input.value, [name]: e.target.value })}
                  />
                  {touched && error && error[name] && (
                    <span className="Settings__field-error">
                      <i className="fas fa-exclamation-circle" aria-hidden />
                      {error[name]}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
          <button
            type="button"
            className="Settings__field-btn btn-primary"
            onClick={onClick}
            disabled={disabled || saving || invalid}
          >
            {btnLabel}
          </button>
        </div>
      )}
      {!opened && displayValue}
    </CollapsibleSection>
  );
};

const Radio = (props) => {
  const {
    save,
    info,
    btnLabel,
    input,
    successMsg,
    placeholder,
    disabled,
    label,
    name,
    options,
    parseValue,
    displayValueFunc,
    ariaLabel,
    meta: { touched, error, invalid, submitFailed },
  } = props;

  const [saving, setSaving] = useState(false);
  const [opened, setOpened] = useState(false);
  const [successToastVisible, showSuccessToast] = useState(false);
  const [focusIndex, setFocusIndex] = useState(-1);
  const timeout = useRef();

  const mappedOptions = options.reduce((list, o) => {
    if (!o.customOptions) {
      return [...list, { ...o, value: o.id, label: parseValue ? parseValue(o.id) : o.id }];
    }
    return [
      ...list,
      ...o.customOptions
        .filter((co) => !!co)
        .map((co, i) => ({ id: o.id, value: `${o.id}_${i}`, label: co, answerText: co })),
    ];
  }, []);

  const selectedValue = input.value && input.value.answer;
  const textValue = input.value && input.value.answerText;
  const selectedOption =
    mappedOptions.find((o) => o.id == selectedValue && (!o.answerText || o.answerText == textValue)) || {};

  const displayValue =
    selectedValue &&
    (input.value.answerText ||
      (displayValueFunc
        ? displayValueFunc(input.value, mappedOptions)
        : parseValue
        ? parseValue(selectedValue, mappedOptions)
        : selectedValue));

  const onClick = useCallback(() => {
    if (!save) return;
    setSaving(true);
    save(input.value).then(
      () => {
        setSaving(false);
        setOpened(false);
        showSuccessToast(true);
        timeout.current = setTimeout(() => showSuccessToast(false), 8000);
      },
      () => setSaving(false)
    );
  }, [input, name]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      timeout.current = null;
    };
  }, []);

  useEffect(() => {
    if (!touched) {
      setOpened(false);
    }
  }, [touched]);

  useEffect(() => {
    if (submitFailed && error) {
      setOpened(true);
    }
  }, [submitFailed, error]);

  const selectOption = (option) => {
    if (option.hasTextField) {
      return input.onChange({
        ...input.value,
        answer: option.id,
        answerText: '',
      });
    } else {
      return input.onChange({
        ...input.value,
        answer: option.id,
        answerText: option.answerText,
      });
    }
  };

  const handleKeyPress = (event, option) => {
    if (event.key === 'Enter') {
      selectOption(option);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      setFocusIndex((prevIndex) => (prevIndex + 1) % mappedOptions.length);
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      setFocusIndex((prevIndex) => (prevIndex - 1 + mappedOptions.length) % mappedOptions.length);
      event.preventDefault();
    } else if (event.key === 'Enter' && focusIndex >= 0) {
      selectOption(mappedOptions[focusIndex]);
    }
  };

  useEffect(() => {
    if (focusIndex >= 0 && opened) {
      const optionId = `Settings__option${mappedOptions[focusIndex].value}`;
      const optionElement = document.getElementById(optionId);
      if (optionElement) {
        optionElement.focus();
      }
    }
  }, [focusIndex, opened]);

  return (
    <CollapsibleSection
      label={label}
      ariaLabel={ariaLabel}
      opened={opened}
      open={() => setOpened(true)}
      close={() => setOpened(false)}
      onKeyDown={handleKeyDown}
    >
      {successMsg && successToastVisible && (
        <div className="Settings__toast success">
          <i className="fas fa-check-circle" aria-hidden />
          {successMsg}
        </div>
      )}
      {opened && (
        <div className="Settings__field">
          <Loader loading={saving} color="white" />
          {info && <p>{info}</p>}
          <div className="Settings__field-radio" role="radiogroup">
            {mappedOptions.map((o, i) => {
              const selected = !!selectedOption && o.value == selectedOption.value;
              return (
                <React.Fragment key={i}>
                  <label
                    className={cx('Settings__option', { selected, focused: focusIndex === i })}
                    onClick={() => selectOption(o)}
                    aria-label={o.label}
                    tabIndex="0"
                    onKeyPress={(e) => handleKeyPress(e, o)}
                    id={`Settings__option${o.value}`}
                  >
                    <input
                      type="radio"
                      role="radio"
                      checked={selected}
                      onChange={() => selectOption(o)}
                      aria-checked={selected ? 'true' : 'false'}
                    />
                    <div className={cx('Settings__optionMark', { selected })} />
                    <p className="Settings__radioLabel">{o.label}</p>
                  </label>
                  {selected && o.hasTextField && (
                    <div key="openField" className={cx('Settings__optionText', { filled: textValue })}>
                      <input
                        type="text"
                        className={cx('Settings__field-text', { err: touched && error })}
                        placeholder="Please share"
                        id="Settings__optionOpenField"
                        maxLength="50"
                        value={textValue || ''}
                        onChange={(e) => input.onChange({ ...input.value, answerText: e.target.value })}
                      />
                      {touched && error ? (
                        <span className="Settings__field-error">
                          <i className="fas fa-exclamation-circle" aria-hidden />
                          {error}
                        </span>
                      ) : (
                        <span className="caption-new">Maximum 50 characters</span>
                      )}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {touched && error && (!selectedOption || !selectedOption.hasTextField) && (
            <span className="Settings__field-error">
              <i className="fas fa-exclamation-circle" aria-hidden />
              {error}
            </span>
          )}
          <button
            type="button"
            className="Settings__field-btn btn-primary"
            onClick={onClick}
            disabled={disabled || saving || invalid}
          >
            {btnLabel}
          </button>
        </div>
      )}
      {!opened && (displayValue || <span className="Settings__placeholder">{placeholder}</span>)}
    </CollapsibleSection>
  );
};

const AvatarField = (props) => {
  const {
    save,
    info,
    btnLabel,
    input,
    successMsg,
    placeholder,
    disabled,
    label,
    name,
    user = {},
    assistiveMsg,
    accept = [],
    ariaLabel,
    optional = false,
    meta: { touched, error, initial, invalid, submitFailed },
  } = props;

  const [saving, setSaving] = useState(false);
  const [opened, setOpened] = useState(false);
  const [successToastVisible, showSuccessToast] = useState(false);
  const timeout = useRef();

  const onDrop = useCallback(([file]) => {
    input.onChange(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: accept.join(', '),
  });

  const onClick = useCallback(() => {
    if (!save) return;
    setSaving(true);
    save(input.value).then(
      () => {
        setSaving(false);
        setOpened(false);
        showSuccessToast(true);
        timeout.current = setTimeout(() => showSuccessToast(false), 8000);
      },
      () => setSaving(false)
    );
  }, [input, name]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
      timeout.current = null;
    };
  }, []);

  useEffect(() => {
    if (!touched) {
      setOpened(false);
    }
  }, [touched]);

  useEffect(() => {
    if (submitFailed && error) {
      setOpened(true);
    }
  }, [submitFailed, error]);

  const avatarPreview = useMemo(() => {
    if (invalid) {
      return initial;
    }
    if (input.value instanceof File) {
      return URL.createObjectURL(input.value);
    }
    return input.value;
  }, [input, invalid, initial]);

  const fileSelected = useMemo(() => {
    return input.value instanceof File;
  });

  return (
    <CollapsibleSection
      className="Settings__collapsible-top"
      label={label}
      ariaLabel={ariaLabel}
      opened={opened}
      optional={optional}
      open={() => setOpened(true)}
      close={() => setOpened(false)}
    >
      {successMsg && successToastVisible && (
        <div className="Settings__toast success">
          <i className="fas fa-check-circle" aria-hidden />
          {successMsg}
        </div>
      )}
      {opened && (
        <div className="Settings__field avatar">
          <Loader loading={saving} color="white" />
          <div {...getRootProps()} className="Settings__avatarDropzone">
            <Avatar for={{ ...user, avatars: { normal: avatarPreview } }} size="md-lg" />
            <input {...getInputProps()} />
            {info && <p>{info}</p>}
            {(!fileSelected || invalid) && (
              <>
                <button className="btn-secondary" type="button" aria-label="Choose a file">
                  Choose a file
                </button>
                {!error && assistiveMsg && <span className="caption-new">{assistiveMsg}</span>}
              </>
            )}
            {touched && error && (
              <span className="Settings__field-error">
                <i className="fas fa-exclamation-circle" aria-hidden />
                {error}
              </span>
            )}
          </div>
          {fileSelected && !invalid && (
            <button
              type="button"
              className="Settings__field-btn btn-primary"
              onClick={onClick}
              disabled={disabled || saving}
            >
              {btnLabel}
            </button>
          )}
        </div>
      )}
      {!opened && <Avatar for={{ ...user, avatars: { normal: avatarPreview } }} size="md-lg" />}
    </CollapsibleSection>
  );
};

export { Text, AvatarField, Radio, MultiText };
