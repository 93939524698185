import React from 'react'
import cx from 'classnames'


export default MemberList = ({className, members}) ->
  React.createElement("table", {"className": (cx "TeamReport__memberList", className)},
    React.createElement("thead", null,
      React.createElement("tr", null,
        React.createElement("th", null),
        React.createElement("th", {"className": "TeamReport__memberListDimension TeamReport__memberListDimension_who"}, "Impact"),
        React.createElement("th", {"className": "TeamReport__memberListDimension TeamReport__memberListDimension_why"}, "Values"),
        React.createElement("th", {"className": "TeamReport__memberListDimension TeamReport__memberListDimension_how"}, "Craft")
      )
    ),
    React.createElement("tbody", null,
      (for m in members
        React.createElement("tr", {"key": (m.slug)},
          React.createElement("td", {"className": (cx "TeamReport__memberListName", leader: m.isLead)},
            (m.firstName), " ", (m.lastName)
          ),
          React.createElement("td", { \
            "className": (cx "TeamReport__memberListDriver",
              "TeamReport__memberListDriver_who",
              dominant: m.assessmentResult.drivers.who.dominant
            )
          }, (m.assessmentResult.drivers.who.driver)),
          React.createElement("td", { \
            "className": (cx "TeamReport__memberListDriver",
              "TeamReport__memberListDriver_why",
              dominant: m.assessmentResult.drivers.why.dominant
            )
          }, (m.assessmentResult.drivers.why.driver)),
          React.createElement("td", { \
            "className": (cx "TeamReport__memberListDriver",
              "TeamReport__memberListDriver_how",
              dominant: m.assessmentResult.drivers.how.dominant
            )
          }, (m.assessmentResult.drivers.how.driver))
        )
      )
    )
  )
