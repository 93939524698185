import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useStore } from 'stores/stores';
import steps from './steps';
import styles from './styles';

const Tutorial = () => {
  const { userStore } = useStore();
  const { user } = userStore;

  const [stepIndex, setStep] = useState(0);
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      userStore.updateSeenTutorial();
      setStep(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStep(nextStepIndex);
    }
  };

  useEffect(() => {
    user && setRun(!user.seenTutorial);
  }, [user]);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={run}
      steps={steps}
      stepIndex={stepIndex}
      showProgress
      showSkipButton
      disableScrolling
      disableOverlayClose
      hideCloseButton
      styles={styles}
    />
  );
};

export default Tutorial;
