import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withRouter, Link} from 'react-router-dom'
import {fetchUser} from 'auth/requests'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, mapProps, withProps} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import {getUser} from 'api'
import {Collapse} from 'react-collapse'
import Checkbox from 'theme/Checkbox'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Subnav from 'theme/Subnav'

import './NewGroup.sass'

STATE_MAP = [
  'Select partner'
  'Schedule conversation'
  'Conduct conversation'
  'Capture your insights'
  'Rate and mark as completed'
  'Finished'
]


export default composeComponent 'NewGroup',
  withRouter

  withStateHandlers
    loading: false
    chosenConversationPath: null
    description: ""
    autoAssign: false
  ,
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    pickProgram: -> (program) ->
      chosenConversationPath: {program}
    pickSet: ({chosenConversationPath}) -> (set) ->
      chosenConversationPath: _.merge chosenConversationPath, {set}
    setDescription: -> (e) -> description: e.target.value
    setAutoAssign: -> (autoAssign) -> {autoAssign}

  withHandlers
    submit: ({chosenConversationPath, autoAssign, submit, description, startLoading, endLoading}) -> ->
      dto =
        peerConversationSetLabel: "#{chosenConversationPath.program.label}/#{chosenConversationPath.set.label}"
        description: description
        # autoAssign: autoAssign

      startLoading()
      submit dto
      .catch endLoading

  withProps ({chosenConversationPath, matchingGroups, conversationsDefinitions}) ->
    mappedPrograms = conversationsDefinitions.map((program) =>
      sets = program.sets.map((set) =>
        setDisabled = matchingGroups.findIndex((mg) => mg.program == program.label && mg.set == set.label) > -1
        {...set, disabled: setDisabled}
      )
      programDisabled = sets.every((set) => set.disabled)
      {...program, sets, disabled: programDisabled}
    )

    programActive = not chosenConversationPath?.program?
    setActive = programActive or not chosenConversationPath?.set?
    activeSection: if programActive
        'program'
      else if setActive
        'set'
    conversationsDefinitions: mappedPrograms

  ({
    loading
    conversationsDefinitions
    chosenConversationPath
    pickProgram
    pickSet
    activeSection
    submit
    setDescription
    description
    workshop
    t
    autoAssign
    setAutoAssign
  }) ->

    React.createElement("div", {"className": "NewGroup"},
      React.createElement("div", {"className": "NewGroup__heading"},
        React.createElement("div", null,
          React.createElement("h1", {"className": "NewGroup__title"}, """
            Assign Conversations to Cohort (""", (workshop?.name), """)
"""),
          React.createElement("p", {"className": "title-description"}, "Pick a Conversation Set from Program below.")
        )
      ),

      React.createElement("div", {"className": "NewGroup__table"},

        React.createElement("div", {"className": (cx "row heading",
          greyed: activeSection isnt 'program'
        )},
          React.createElement("div", null, "1. Program"),
          (if chosenConversationPath?.program?
            React.createElement("div", {"className": "NewGroup__tableAll"},
              React.createElement("button", {"onClick": (-> pickProgram null)}, """
                Choose different
""")
            )
          )
        ),

        React.createElement(Collapse, {"isOpened": (true)},
          React.createElement("div", {"className": "NewGroup__tableGroup"},
            (conversationsDefinitions.map (program) ->
              React.createElement("div", { \
                "key": (program.label),  \
                "className": (cx "row clickable",
                  hidden: chosenConversationPath?.program and chosenConversationPath?.program?.label isnt program.label
                  disabled: program.disabled
                ),  \
                "onClick": (-> !program.disabled && pickProgram program)
              },
                React.createElement("div", null,
                  (t.find "peerconversation.program.#{program.label}.title")
                )
              )
            )
          )
        ),

        React.createElement("div", {"className": (cx "row heading",
          greyed: activeSection isnt 'set'
          disabled: not chosenConversationPath?.program
        )},
          React.createElement("div", null, "2. Set"),
          (if chosenConversationPath?.set?
            React.createElement("div", {"className": "NewGroup__tableAll"},
              React.createElement("button", {"onClick": (-> pickSet null)}, """
                Choose different
""")
            )
          )
        ),
        React.createElement(Collapse, { \
          "isOpened": (chosenConversationPath?.program?)
        },
          React.createElement("div", {"className": "NewGroup__tableGroup"},
            (chosenConversationPath?.program?.sets.map (set) ->
              React.createElement("div", { \
                "key": (set.label),  \
                "className": (cx "row clickable",
                  hidden: chosenConversationPath?.set and chosenConversationPath?.set?.label isnt set.label
                  disabled: set.disabled
                ),  \
                "onClick": (-> !set.disabled && pickSet set)
              },
                React.createElement("div", null,
                  (t.find "peerconversation.set.#{set.label}.title")
                )
              )
            )
          )
        )
      ),

      (if false and chosenConversationPath?.set?.label
        React.createElement("div", {"className": "margin-top-3 NewGroup__checkbox", "onClick": (-> setAutoAssign !autoAssign)},
          React.createElement(Checkbox, {"checked": (autoAssign)}),
          React.createElement("label", {"htmlFor": "description"}, "Auto assign conversations")
        )
      ),

      (if chosenConversationPath?.set?.label
        React.createElement("div", {"className": "margin-top-3"},
          React.createElement("label", {"htmlFor": "description"}, "Description"),
          React.createElement("textarea", { \
            "onChange": (setDescription),  \
            "value": (description),  \
            "name": "description",  \
            "placeholder": "Add optional description",  \
            "aria-label": "description"
          })
        )
      ),

      React.createElement("div", {"className": "text-center margin-top-3"},
        React.createElement("button", { \
          "className": "btn btn_secondary btn_solid_bluePurple",  \
          "disabled": (not chosenConversationPath?.program or not chosenConversationPath?.set),  \
          "onClick": (submit)
        }, "Assign")
      ),

      React.createElement(Loader, {"loading": (loading)})

    )
