import api from 'api'
import {pluck, filter, merge} from 'ramda'

export createWorkshop = (organizationCode, name) ->
  api.post "/workshops", {organizationCode, name}

# export getWorkshop = (user, id, orgCode) ->
#   api.get "/organizations/#{orgCode}/workshops", params: {id}
#   .then ([workshop]) -> workshop

export getWorkshop = (id) ->
  api.get "/workshops/byId/#{id}"

export getAllMembersInOrg = (organizationCode, loadAllUsers, count, opts = {}) ->
  params = {page: 0, size: count if (!loadAllUsers), sort: 'slug', role: ['Employee', 'FriendOrFamily'], ...opts}

  api.get "organizations/#{organizationCode}/members", {params}

export deleteWorkshop = (slug) ->
  api.delete "/workshops/#{slug}"

export getOrganizations = ->
 api.get "/organizations"

export getWorkshops = (org) ->
  api.get "/organizations/#{org.code}/workshops"

export addAllMembers = (workshop) ->
  params = {role: ['Employee', 'FriendOrFamily']}

  new Promise (resolve, reject) ->
    api.get "organizations/#{workshop.organizationCode}/members", {params}
    .then ({results}) ->
      emails = pluck 'email', results
      addMembersToWorkshop workshop, emails
      .then resolve, reject
    , reject

export addMembersToWorkshop = (workshop, emails) ->
  api.post "/workshops/#{workshop.slug}/invitations", {emails}

export deleteMemberFromWorkshop = (workshop, member) ->
  api.delete "/workshops/#{workshop.slug}/members/#{member.slug}"
