import SSO from './configure'

config = window.config

export default do ->
  cfg = config.sso
  providers = ['samlPwc', 'samlHasbro', 'samlIcf', 'samlIntuitive', 'samlZillow', 'samlOswaldCompanies', 'samlBostonScientific', 'samlSiteImprove', 'samlAirbnb', 'samlSeattleCityLight']
  providersUrls =
    samlPwc: 'samlPwcUrl'
    samlHasbro: 'samlHasbroUrl'
    samlEquitable: 'samlEquitableUrl'
    samlOswaldCompanies: 'samlOswaldCompaniesUrl'
    samlBostonScientific: 'samlBostonScientificUrl'
    samlSiteImprove: 'samlSiteImproveUrl'
    samlIcf: 'samlIcfUrl'
    samlIntuitive: 'samlIntuitiveUrl'
    samlZillow: 'samlZillowUrl'
    samlAirbnb: 'samlAirbnbUrl'
    samlSeattleCityLight: 'samlSeattleCityLightUrl'

  ids = {}
  urls = {}
  callback = '/sso-callback'

  for p in providers
    ids[p] = cfg[p]
  for p, url of providersUrls
    urls[p] = cfg[url] if url of cfg

  # for p in providers
  #   throw new Error "SSO provider configuration missing: #{p}" unless ids[p]

  throw new Error 'SSO callback configuration missing' unless callback

  SSO callback, ids, urls
