import React, { useMemo, useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import cx from 'classnames';
import Dropdown from './Dropdown';

import './NavItem.sass'

const NavItem = (props) => {
  const {children, title, withoutActive, external, url, depth = 0, exact, color = 'blue', newTab, dropdownPosition, icon, label} = props;
  const location = useLocation();

  const activeState = useMemo(() => {
    if(exact)
      return location.pathname === url;
    return location.pathname.indexOf(url) == 0;
  }, [location, url]);

  const active = useMemo(() => {
    if(withoutActive) return false;
    return props.active || activeState;
  }, [activeState, props.active, withoutActive]);

  if(!children && !url) {
    console.error('Header NavItem: url not specified');
    return null;
  }

  const NavLink = (props) => external
    ? <a tabIndex="0" href={url} className={cx("NavItem__link", {active})} role="button" {...props} />
    : (
      activeState
      ? <a tabIndex="0" href="" className={cx("NavItem__link", {active})} role="button" {...props} />
      : <Link tabIndex="0" to={url} className={cx("NavItem__link", {active})} role="button" {...props} />
    )

  return (
    <div className={cx("NavItem", color)}>
      {!children &&
        <NavLink target={newTab && '_blank'}>
          {icon}
          <span title={title}>{title}</span>
          {label}
        </NavLink>
      }
      {children &&
        <Dropdown
          title={title}
          position={dropdownPosition || (depth == 0 ? 'below right' : 'right top')}
          over={depth > 0}
          withOverlay={depth === 0}
          trigger={({onClick}) =>
            <a
              className={cx("NavItem__link", {active})}
              onClick={onClick}
              href={void(0)}
              role="button"
              tabIndex="0"
            >
              {icon}
              <span title={title}>{title}</span>
              {depth == 0 &&
                <i className="ci-chevron_down" />
              }
              {depth > 0 &&
                <i className="ci-chevron_right" />
              }
              {label}
            </a>
          }
        >
          {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {depth: depth + 1});
            }
            return child;
          })}
        </Dropdown>
      }
    </div>
  )
}

export default NavItem;
