import React from 'react'
import cx from 'classnames'
import {IMG_LOGO_BLUE} from 'theme/assets/assets'


export default Logo = ({className}) ->
  React.createElement("img", { \
    "className": (cx "TeamReport__logo", className),  \
    "alt": "Imperative logo",  \
    "src": (IMG_LOGO_BLUE)
  })
