import moment from 'moment-timezone';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { AvatarUpload } from 'theme/Avatar';
import Loader from 'theme/Loader';
import TranslationsContext from 'translations';
import './NameCard.sass';

//flow = [row, column]

const NameCard = ({
  for: member,
  withoutButtons = false,
  loading,
  flow = 'column',
  uploadAvatar,
  avatarLink,
  nameContent,
  showPauseButton = true,
  showInfo = true,
  children,
}) => {
  const t = useContext(TranslationsContext);
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    setAvatar(member && (member.avatar || (member.avatars && member.avatars.normal)));
  }, [member]);

  const jobFunction = useMemo(() => {
    const translateJobFunction = (code) =>
      t.exists(`dictionaries.jobFunction.${code}.name`) ? t.find(`dictionaries.jobFunction.${code}.name`) : code;
    if (!member) return;
    const custom = (member.jobInformation && member.jobInformation.jobFunctionText) || member.jobFunctionText;
    const jf = (member.jobInformation && member.jobInformation.jobFunction) || member.jobFunction;

    if (custom) return custom;
    if (jf) return translateJobFunction(jf);
    else return '';
  }, [member, t]);

  if (!member) return null;

  const timeZone = member.timeZone || moment.tz.guess();
  const archived = member.archivedAt;

  const changeAvatar =
    uploadAvatar &&
    ((file) => {
      const fd = new FormData();
      fd.append('image', file);

      return uploadAvatar(fd).then((avatarUrl) => {
        setAvatar(avatarUrl);
      });
    });

  return (
    <div className={cx('NameCard card relative', { archived }, `NameCard__flow_${flow}`)}>
      <Loader loading={loading} color="white" />
      <div className="NameCard__content">
        <AvatarUpload size="96" for={!archived && { ...member, avatar }} upload={changeAvatar} link={avatarLink}>
          {archived && (
            <span className="material-icons-outlined NameCard__archivedIcon" aria-hidden>
              inventory_2
            </span>
          )}
        </AvatarUpload>
        {!withoutButtons && flow == 'row' && (
          <div className="NameCard__btns">
            <Link to="/settings/profile" className="btn-secondary">
              Edit profile
            </Link>
            {member &&
              member.organizationSettings &&
              member.organizationSettings.pauseSettings.isMemberPauseEnabled &&
              !member.isPaused && (
                <Link to="/settings/account" className="btn-secondary">
                  Update status
                </Link>
              )}
          </div>
        )}
      </div>
      <div className="NameCard__content">
        {!nameContent && (
          <>
            {archived ? (
              <div className="section-head">User Archived</div>
            ) : (
              <div className="section-head">
                {member.firstName} {member.lastName}
              </div>
            )}
            <span className="link p-new">{member.email}</span>
            {member && member.pattern && (
              <div className="NameCard__drivers">
                {['who', 'why', 'how'].map((d) => {
                  const driver = t.find(`assessment.page.driver.title.${member.pattern[d]}`);
                  const dominant = member.pattern.dominant.toLowerCase() == d;
                  return (
                    <span
                      className={cx({ dominant })}
                      key={d}
                      aria-label={driver + (dominant ? ' - dominant driver' : '')}
                    >
                      {driver}
                    </span>
                  );
                })}
              </div>
            )}
          </>
        )}
        {nameContent}
        {showInfo && (
          <div className="NameCard__info">
            <div className="NameCard__row">
              <i className="ci-user_circle" />
              {member.assessmentCompletedAt && (
                <span>Member since {moment(member.assessmentCompletedAt).format('YYYY')}</span>
              )}
            </div>
            {jobFunction && (
              <div className="NameCard__row">
                <i className="material-icons-outlined" aria-hidden>
                  work_outline
                </i>
                {jobFunction}
              </div>
            )}
            <div className="NameCard__row">
              <i className="material-icons-outlined" aria-hidden>
                access_time
              </i>
              {moment.tz(moment(), timeZone).format('LT')} ({timeZone})
            </div>
          </div>
        )}
        {!withoutButtons && flow == 'column' && (
          <div className="NameCard__btns">
            <Link to="/settings/profile" className="btn-secondary">
              Edit profile
            </Link>
            {showPauseButton &&
              member &&
              member.organizationSettings &&
              member.organizationSettings.pauseSettings.isMemberPauseEnabled &&
              !member.isPaused && (
                <Link to="/settings/account" className="btn-secondary">
                  Update status
                </Link>
              )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default NameCard;
