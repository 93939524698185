import React, { useMemo } from 'react';
import {Link, useLocation} from 'react-router-dom';
import cx from 'classnames';
import {getAuth} from 'api';
import Avatar, {AvatarUpload} from 'theme/Avatar';
import Dropdown from './Dropdown';
import NavItem from './NavItem';

import './User.sass'

const config = window.config;

const www = config.www.address;
const adminUrl = config.admin.address;
const adminPortalUrl = config.admin.portal;

const User = (props) => {
  const {user, color = 'blue', changeAvatar, avatar, roles} = props;
  const location = useLocation();
  const auth = getAuth();
  const token = auth && encodeURIComponent(auth.jwt);

  const settingsActive = useMemo(() => {
    return location.pathname.indexOf('/settings') == 0;
  }, [location]);

  const profileActive = useMemo(() => {
    return location.pathname.indexOf('/purpose-profile') == 0;
  }, [location]);

  const isOrgAdmin = useMemo(() => {
    if(!roles || !roles.length) return;
    return roles.findIndex(r => r.name == "client-dashboard-user-role") > -1;
  }, [user]);

  const isSuperAdmin = useMemo(() => {
    if(!user || !roles || !roles.length) return;
    return user.isSuperAdmin || (
      roles && roles.findIndex(r => r.name == "super-admin") > -1
    );
  }, [user]);

  const HelpIcon = ({onClick, opened}) => (
    <button
      className={cx("User__help User__icon", {opened})}
      onClick={onClick}
      aria-expanded={opened}
      tabIndex="0"
      aria-label='Help'
      >
      {opened
        ? <i className="ci-help_circle" aria-hidden />
        : <i className="ci-help_circle_outline" aria-hidden />
      }
      <span className="User__iconTooltip">
        Help
      </span>
    </button>
  )

  const UserAvatar = ({onClick, opened}) => (
    <button
      onClick={onClick}
      aria-label="User menu"
      aria-expanded={opened}
      className={cx("User__avatarNav", {active: profileActive})}
    >
      <Avatar
        size="36"
        for={{...user, avatar}}
      >
        <span className="User__iconTooltip left">
          Imperative Account
          <span>{user.firstName} {user.lastName}</span>
          <span>{user.email}</span>
        </span>
      </Avatar>
    </button>
  )

  return (
    <div className={cx("User", color)}>
      <Dropdown trigger={HelpIcon} position="below left">
        <h4>Help center</h4>
        <NavItem
          url="https://help.imperative.com/support/home"
          title="Contact us"
          newTab
          external
        />
        <NavItem
          url={`${www}/about`}
          title="About Imperative"
          newTab
          external
        />
        <h4>Legal</h4>
        <NavItem
          url={`${www}/terms-of-service`}
          title="Terms of service"
          newTab
          external
        />
        <NavItem
          url={`${www}/privacy-policy`}
          title="Privacy policy"
          newTab
          external
        />
      </Dropdown>
      <Link
        className={cx("User__help User__icon", {opened: settingsActive})}
        aria-label="Settings"
        to="/settings"
      >
        {settingsActive
          ? <i className="ci-settings_filled" aria-hidden />
          : <i className="ci-settings" aria-hidden />
        }
        <span className="User__iconTooltip">
          Settings
        </span>
      </Link>
      <Dropdown trigger={UserAvatar} position="below left">
        <div className="User__dropdown">
          <AvatarUpload size="sm" for={{...user, avatars: {normal: avatar}}} tabIndex="-1" upload={changeAvatar}  />
          <h3>{user && `${user.firstName} ${user.lastName}`}</h3>
          <h5>{user && user.email}</h5>
        </div>
        <NavItem
          url="/"
          exact
          title="Home"
          icon={<i className="ci-home_heart" aria-hidden/>}
          withoutActive
        />
        <NavItem
          url="/purpose-profile"
          exact
          title="Purpose Profile"
          icon={<i className="material-icons-outlined" aria-hidden>account_circle</i>}
          withoutActive
        />
        <NavItem
          url="/settings"
          exact
          title="Settings"
          icon={<i className="ci-settings" aria-hidden/>}
          withoutActive
        />
        {(isSuperAdmin || isOrgAdmin) &&
          <>
            <h4>Admin</h4>
            {(isSuperAdmin || isOrgAdmin) &&
              <NavItem
                url="/portal"
                title="Portal"
                icon={<i className="ci-line_chart_up" aria-hidden/>}
              />
            }
            {isSuperAdmin &&
              <NavItem
                url={adminUrl}
                title="Imperative Dashboard"
                icon={<i className="ci-bar_chart_circle" aria-hidden/>}
                external
                />
              }
          </>
        }
        <span className="User__divider" aria-hidden />
        <NavItem
          url="/logout"
          title="Sign out"
        />
      </Dropdown>
    </div>
  )
}

export default User;
