`/** @jsx R.DOM */`
import R from 'react'
import PT from 'prop-types'
import t from 'translations'

import {PURPOSE} from './content'


export default ({appUrl, t}) ->
  R.createElement("div", {"className": "ShortReport__purpose"},
    R.createElement("h1", {"className": "ShortReport__title"},
      (t.find 'report.shortReport.purpose.subtitle')
    ),
    R.createElement("p", {"className": "ShortReport__text"},
      (t.find 'report.shortReport.purpose.intro')
    ),
    ([1..5].map (arrow) ->
      R.createElement("div", {"className": "ShortReport__arrow ShortReport__arrow_#{arrow+1}", "key": (arrow)},
        R.createElement("span", {"className": "fa fa-#{PURPOSE.arrows[arrow].icon}"}),
        R.createElement("div", {"className": "ShortReport__arrowContainer"},
          R.createElement("h3", {"className": "ShortReport__subtitle ShortReport__subtitle_black ShortReport__subtitle_smaller"},
            (t.find "report.shortReport.purpose.arrows.#{arrow}.title")
          ),
          R.createElement("p", {"className": "ShortReport__text ShortReport__text_bold ShortReport__text_pink ShortReport__text_small"},
            (t.find "report.shortReport.purpose.arrows.#{arrow}.text")
          )
        )
      )
    )
  )
