import {URL_PURPOSE_PROFILE_DRIVERS} from 'theme/assets/assets'

Community = "#{URL_PURPOSE_PROFILE_DRIVERS}/CRAFT_COMMUNITY.png"
Human = "#{URL_PURPOSE_PROFILE_DRIVERS}/CRAFT_HUMAN.png"
Knowledge = "#{URL_PURPOSE_PROFILE_DRIVERS}/CRAFT_KNOWLEDGE.png"
Structure = "#{URL_PURPOSE_PROFILE_DRIVERS}/CRAFT_STRUCTURE.png"
Individual = "#{URL_PURPOSE_PROFILE_DRIVERS}/IMPACT_INDIVIDUAL.png"
Organization = "#{URL_PURPOSE_PROFILE_DRIVERS}/IMPACT_ORG.png"
Society = "#{URL_PURPOSE_PROFILE_DRIVERS}/IMPACT_SOCIETY.png"
Harmony = "#{URL_PURPOSE_PROFILE_DRIVERS}/VALUES_HARMONY.png"
Karma = "#{URL_PURPOSE_PROFILE_DRIVERS}/VALUES_KARMA.png"


impact = {
  Individual
  Organization
  Society
}

values = {
  Harmony
  Karma
}

craft = {
  Community
  Human
  Knowledge
  Structure
}


export {
  Individual
  Organization
  Society
  Harmony
  Karma
  Community
  Human
  Knowledge
  Structure
  impact
  values
  craft
}
