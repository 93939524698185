import cx from 'classnames';
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { URL_ARCHETYPES } from 'theme/assets/assets';
import * as DRIVER_ICONS from 'theme/drivers';
import Loader from 'theme/Loader';
import Markdown from 'theme/Markdown';
import * as PURPOSE_PROFILE_ICONS from 'theme/purpose-profile-drivers';
import VideoModal from 'theme/VideoModal';
import TranslationsContext from 'translations';
import { getThumbnail } from 'vimeo/requests';

const questions = {
  who: '"What is the impact we want to make?"',
  why: '"What is the right thing to do?"',
  how: '"What is the right way to do it?"',
}

const DRIVERS = {
  who: [
    'Individual',
    'Organization',
    'Society',
  ],
  why: [
    'Harmony',
    'Karma',
  ],
  how: [
    'Community',
    'Human',
    'Knowledge',
    'Structure',
  ]
}

const thumbnailIds = {
  Society: '257548081',
  Individual: '257547893',
  Organization: '257548003',
  Harmony: '257547742',
  Karma: '257547935',
  Knowledge: '257547962',
  Structure: '257548126',
  Human: '257547860',
  Community: '257547727',
}

const thumbnailLinks = {
  Impact: '259778786',
  Craft: '259896290',
  Values: '261210165',
}

const Results = ({user}) => {
  const t = useContext(TranslationsContext);
  const [loadingThumbnails, setLoadingThumbnails] = useState(false);
  const [thumbnails, setThumbnails] = useState([])
  const [videoModal, setVideoModal] = useState({});

  const patternString = useMemo(() => {
    if(!user) return '';
    return `${user.pattern.who}${user.pattern.why}${user.pattern.how}`
  }, [user]);

  const dominantDimension = ((user && user.pattern && user.pattern.dominant) || '').toLowerCase();
  const dominant = (user && user.pattern && user.pattern[dominantDimension]);
  const dominantDriverTitle = t.find(`pattern.drivers.${dominantDimension}.${dominant}.title`);
  const leadershipStyleName = t.find(`pattern.leadershipStyle.dominantDriver.${dominantDimension}.name`);

  const imperative = useMemo(() => {
    return {
      prefix: {
        my: t.find(`pattern.imperative-prefix.my`),
        your: t.find(`pattern.imperative-prefix.your`),
      },
      who: t.find(`pattern.archetypes.${patternString}.imperative.who`),
      why: t.find(`pattern.archetypes.${patternString}.imperative.why`),
      how: t.find(`pattern.archetypes.${patternString}.imperative.how`),
    }
  }, [t, patternString]);

  const archetype = useMemo(() => {
    return t.exists(`pattern.archetypes.${patternString}.title`) ? t.find(`pattern.archetypes.${patternString}.title`) : null;
  }, [patternString, t]);

  const showVideoModal = useCallback(id => {
    setVideoModal({visible: true, id});
  }, []);
  const closeVideoModal = useCallback(() => {
    setVideoModal(s => ({...s, visible: false}));
  }, []);

  useEffect(() => {
    if(!user || !thumbnailLinks[leadershipStyleName]) return;
    setLoadingThumbnails(true);
    const leadershipStyle = getThumbnail(thumbnailLinks[leadershipStyleName]);
    const driversReq = ['who', 'why', 'how'].map((dimension) => {
      const title = t.find(`pattern.drivers.${dimension}.${user.pattern[dimension]}.title`);
      return getThumbnail(thumbnailIds[title]);
    })

    Promise.all([
      leadershipStyle,
      ...driversReq
    ]).then(
      ([leadershipStyle, who, why, how]) => setThumbnails({leadershipStyle, who, why, how}),
      (err) => ME.reportIntermediateError('Purpose Profile: videos loading error', err)
    ).then(
      () => setLoadingThumbnails(false),
      () => setLoadingThumbnails(false)
    )
  }, [user, leadershipStyleName]);

  return (
    <div>
      <h3 className="section-head margin-top-5">What's your imperative?</h3>
      <div className="card PurposeProfile__row col-1-1">
        {archetype && <img className="PurposeProfile__archetype" src={`${URL_ARCHETYPES}/${archetype}.png`} alt={archetype} />}
        <div>
          {imperative.prefix.your}
          {['who', 'why', 'how'].map(dim => {
            return (
              <div key={dim} className={`PurposeProfile__row col-1-1 color-${dim} driver`}>
                <span>{imperative[dim]}</span>
                <span>{t.find(`assessment.page.driver.title.${user.pattern[dim]}`)}</span>
              </div>
            )
          })}
        </div>
      </div>
      <h3 className="section-head margin-top-5">What drives you?</h3>
      <div className="margin-top-3">
        {['who', 'why', 'how'].sort((a) => a == dominantDimension ? -1 : 0).map(dim => {
          const isDominant = user.pattern.dominant.toLowerCase() == dim;
          const driverOrgDescription = user.organizationSettings && user.organizationSettings.purposeOverview && user.organizationSettings.purposeOverview.drivers &&
            user.organizationSettings.purposeOverview.drivers[user.pattern[dim].toLowerCase()];
          const driverQuestions = t.getSplitted(`pattern.drivers.${dim}.${user.pattern[dim]}.questions`);

          return (
            <div className={`card PurposeProfile__section ${dim}`} key={dim}>
              <div className={`PurposeProfile__sectionName ${dim}`}>
                {t.find(`pattern.leadershipStyle.dominantDriver.${dim}.name`)}
              </div>
              {isDominant &&
                <div className="PurposeProfile__row col-1-1 align-center PurposeProfile__sectionItem">
                  <div>
                    <div className="PurposeProfile__video">
                      <Loader loading={loadingThumbnails} size="sm" color="white" />
                      {thumbnails && thumbnails.leadershipStyle &&
                        <>
                          <span className="fas fa-caret-right" title={`Play ${thumbnails.leadershipStyle.title} video`} />
                          <div className="PurposeProfile__videoMask" onClick={() => showVideoModal(thumbnails.leadershipStyle.id)} />
                          <img src={thumbnails.leadershipStyle.image} alt="" />
                        </>
                      }
                    </div>
                  </div>
                  <div className="PurposeProfile__leadership">
                    <img src={PURPOSE_PROFILE_ICONS[dominantDriverTitle]} alt={thumbnails && thumbnails.leadershipStyle && thumbnails.leadershipStyle.title} />
                    <p>You lead by always asking: <b>{questions[user.pattern.dominant.toLowerCase()]}</b></p>
                  </div>
                </div>
              }
              <div className="PurposeProfile__row col-1-1 PurposeProfile__sectionItem">
                <div>
                  <div className="PurposeProfile__dimensions">
                    {DRIVERS[dim].sort(driver => user.pattern[dim] == driver ? -1 : 1).map((driver, i) =>
                      <div className={cx("PurposeProfile__dimensions-item", {active: i == 0})} key={i}>
                        <img src={DRIVER_ICONS[driver]} alt="" />
                        {/* <p>{t.find(`assessment.page.driver.title.${driver}`)}</p> */}
                        <p>{driver}</p>
                      </div>
                    )}
                  </div>
                  <p>{t.find(`assessment.page.driver.result.${user.pattern[dim]}`)}</p>
                </div>
                <div>
                    <div className="PurposeProfile__video">
                      <Loader loading={loadingThumbnails} size="sm" color="white" />
                      {thumbnails && thumbnails[dim] &&
                        <>
                          <span className="fas fa-caret-right" title={`Play your ${dim} driver video`} />
                          <div className="PurposeProfile__videoMask" onClick={() => showVideoModal(thumbnails[dim].id)} />
                          <img src={thumbnails[dim].image} alt="" />
                        </>
                      }
                    </div>
                  </div>
              </div>
              {user.organization &&
                <div className="PurposeProfile__row col-1-2 PurposeProfile__sectionItem PurposeProfile__organization">
                  <img src={user.organization.smallLogoUrl} alt={`${user.organization.name} logo`} />
                  <div>
                    {driverOrgDescription &&
                      <div>
                        <p>At {user.organization.name}</p>
                        <Markdown source={driverOrgDescription} />
                      </div>
                    }
                    {!driverOrgDescription && driverQuestions.map((q, i) =>
                      <p key={i}>
                        <i className="fas fa-caret-right" />
                        {q}
                      </p>
                    )}
                  </div>
                </div>
              }
            </div>
          )
        })}
      </div>
      {videoModal.visible && videoModal.id &&
        <VideoModal id={`${videoModal.id}`} close={closeVideoModal} />
      }
    </div>
  )
}

export default Results;
