import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { useStore } from 'stores/stores';
import Avatar from 'theme/Avatar';
import Loader from 'theme/Loader';
import TranslationsContext from 'translations';
import './PeerNetwork.sass';
import { getRoleQuestion } from './requests';
import 'tippy.js/dist/tippy.css';

const avatar1 = 'https://assets.imperative.com/static.imperative.com/images/peer_network/network1.jpg';
const avatar2 = 'https://assets.imperative.com/static.imperative.com/images/peer_network/network2.jpg';
const avatar3 = 'https://assets.imperative.com/static.imperative.com/images/peer_network/network3.jpg';

const PAGE_SIZE = 7;
const avatarSize = 64;
const gapSize = 8;

const PeerNetwork = (props) => {
  const t = useContext(TranslationsContext);
  const [loading, setLoading] = useState(false);
  const [roleQuestion, setRoleQuestion] = useState(null);
  const [page, setPage] = useState(0);
  const { userStore } = useStore();
  const { user } = userStore;
  const { groupedSetsByPeer, activePeerId, setActivePeerId } = props;

  const peers = useMemo(() => {
    return Object.keys(groupedSetsByPeer)
      .sort((member1, member2) => {
        const set1 = groupedSetsByPeer[member1].sets[0];
        const set2 = groupedSetsByPeer[member2].sets[0];
        return set2.datePaired - set1.datePaired;
      })
      .map((memberId) => {
        const group = groupedSetsByPeer[memberId];
        return group && group.peer;
      });
  }, [groupedSetsByPeer]);

  useEffect(() => {
    if (!activePeerId && peers.length) {
      setActivePeerId(peers[0].memberId);
    }
  }, [peers, activePeerId]);

  useEffect(() => {
    setLoading(true);
    getRoleQuestion().then(
      (roleQuestion) => {
        setRoleQuestion(roleQuestion);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, []);

  const role = useMemo(() => {
    if (roleQuestion && roleQuestion.answer) {
      const text = t
        .find(`surveys.purpose-assessment.questions.${roleQuestion.id}.options.${roleQuestion.answer}`)
        .toLowerCase();
      return text.replace('individual contributor', 'peer').replace('manager of managers', 'leader').concat('s');
    }
  }, [roleQuestion, t]);

  const nextPageBtnVisible = useMemo(() => {
    return Math.floor((page + 1) * PAGE_SIZE) < peers.length;
  }, [page, peers]);

  const listPosition = useMemo(() => {
    if (nextPageBtnVisible) {
      return { left: `-${page * (PAGE_SIZE * avatarSize + PAGE_SIZE * gapSize)}px` };
    } else {
      const left = peers.length % PAGE_SIZE;
      return {
        left: `-${
          (page - 1) * (PAGE_SIZE * avatarSize + PAGE_SIZE * gapSize) + (left * avatarSize + left * gapSize)
        }px`,
      };
    }
  }, [page, nextPageBtnVisible, peers]);

  const peerVisible = useCallback(
    (i) => {
      if (nextPageBtnVisible) {
        return i < PAGE_SIZE * (page + 1) && i >= PAGE_SIZE * page;
      } else {
        return i < peers.length && i >= peers.length - PAGE_SIZE;
      }
    },
    [page, nextPageBtnVisible, peers]
  );

  if (!user) return null;

  return (
    <div className="PeerNetwork relative">
      <Loader loading={loading} color="white" />
      <div className="PeerNetwork__content">
        {peers.length === 0 && (
          <>
            <div className="PeerNetwork__empty">
              <Avatar for={{ avatars: { normal: avatar1 } }} size="48" />
              <Avatar for={{ avatars: { normal: avatar2 } }} size="48" />
              <Avatar for={{ avatars: { normal: avatar3 } }} size="48" />
            </div>
            {role && (
              <p>
                Imperative is your space for building a trusted network of connections. Once matched for conversations,
                you'll find your first connection here. We are excited for you!
              </p>
            )}
          </>
        )}
        {peers.length > 0 && (
          <div className={cx('PeerNetwork__peers', { offset: page > 0 })}>
            <button
              className={cx('btn-icon left', { visible: page > 0 })}
              onClick={() => setPage((p) => p - 1)}
              aria-label="Peers - previous page"
            >
              <span className="material-icons" aria-hidden>
                chevron_left
              </span>
            </button>
            <div
              className="PeerNetwork__peersList"
              style={{ maxWidth: PAGE_SIZE * avatarSize + (PAGE_SIZE - 1) * gapSize }}
            >
              <div className="PeerNetwork__peersListContent" id="partners-list" style={{ ...listPosition }}>
                {peers.map((p, i) => (
                  <Tippy
                    key={p.memberId}
                    content={p.archivedAt ? 'User Archived' : `${p.firstName} ${p.lastName}`}
                    placement="bottom"
                    className="tip"
                  >
                    <button
                      key={p.memberId}
                      className={cx('PeerNetwork__avatar', { active: activePeerId == p.memberId })}
                      onClick={() => setActivePeerId(p.memberId)}
                      disabled={!peerVisible(i)}
                      aria-label={`Select peer - ${p.firstName} ${p.lastName}`}
                    >
                      <Avatar
                        size="64"
                        for={!p.archivedAt && p}
                        active={activePeerId == p.memberId}
                        noInitials={p.archivedAt}
                      >
                        {p.archivedAt && (
                          <span className="material-icons-outlined PeerNetwork__avatarArchived" aria-hidden>
                            person
                          </span>
                        )}
                      </Avatar>
                    </button>
                  </Tippy>
                ))}
              </div>
            </div>
            <button
              className={cx('btn-icon right', { visible: nextPageBtnVisible })}
              onClick={() => setPage((p) => p + 1)}
              aria-label="Peers - next page"
            >
              <span className="material-icons" aria-hidden>
                chevron_right
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(PeerNetwork);
