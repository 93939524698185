import api, {saveAuth, getAuth, getUser, setUser, saveConsents, getConsentsFromStorage, authorizationHeader} from 'api'
import history from 'api/history'
import {merge, any, identity} from 'ramda'
import moment from 'moment-timezone'
import { identify as posthogIdentify } from 'api/posthog';
import sso from './configureSSO'
import qs from 'qs'
import {store} from 'stores'
import * as errors from './errors'

export fetchConsents = (slug, auth) =>
  new Promise (resolve, reject) ->
    authorizationHeader(auth)
    .then(
      (headers) => 
        api.get "/members/#{slug}/consents", {headers}
        .then(resolve, reject)
      , reject
    )

export fetchMe = (auth) ->
  new Promise (resolve, reject) ->
    authorizationHeader(auth)
    .then(
      (headers) => 
        api.get "/me", {headers}
        .then(resolve, reject)
      , reject
    )

export guessTimezone = (auth) ->
  new Promise (resolve, reject) ->
    authorizationHeader(auth)
    .then(
      (headers) =>
        api({
          method: 'patch'
          url: '/members/me'
          headers
          data: {timeZone: moment.tz.guess()}
        }).then(resolve, reject)
      , reject
    )

export initUser = (auth, provider) ->
  saveAuth(auth, provider)
  new Promise (resolve, reject) ->
    guessTimezone(auth)
    .then () =>
      store.userStore.getUser()
      .then (user) ->
        fetchConsents(user.slug, auth)
        .then (consents) ->
          posthogIdentify(user)
          setUser(user)
          saveConsents consents
          resolve user
    .catch reject


export signinWithJwt = (jwt) ->
  if !jwt
    return Promise.reject('No JWT token provided')

  initUser({jwt})

export signin = (credentials) ->
  new Promise (resolve, reject) ->
    api(
      method: 'post'
      url: '/session'
      data: merge credentials,
        timeZone: moment.tz.guess()
    )
    .then (auth) ->
      initUser(auth).then(resolve)
    .catch reject

export fetchUser = ->
  new Promise((resolve, reject) =>
    api.get "/me"
    .then(
      (user) ->
        api.get "/members/#{user.slug}/consents"
        .then (consents) ->
          saveConsents(consents)
          auth = getAuth()
          org = merge user.organization, user.organizationSettings

          enabledPeerCoaching = org.partnerConversationsSettings.enabled

          isCPL = 'Leader' in (user.roles ? [])
          isEmployee = 'Employee' in (user.roles ? [])
          isTrial = 'Trial' in (user.roles ? [])
          patternString =
            if user.pattern?
              "#{user.pattern.who}#{user.pattern.why}#{user.pattern.how}"
            else if user.assessmentResult?
              "#{user.assessmentResult?.drivers?.who.driver}#{user.assessmentResult?.drivers?.why.driver}#{user.assessmentResult?.drivers?.how.driver}"
            else if user.assessment?
              "#{user.assessment?.drivers?.who.driver}#{user.assessment?.drivers?.why.driver}#{user.assessment?.drivers?.how.driver}"
            else ''

          mappedUser = merge {isCPL, org, enabledPeerCoaching, isTrial, isEmployee, consents}, user

          savedAuth = saveAuth Object.assign {}, auth, user: mappedUser
          {pattern} = mappedUser
          hasPattern = ->
            Boolean pattern && pattern.who && pattern.why && pattern.how
          resolve({...mappedUser, hasPattern, patternString, roles: (savedAuth.user && savedAuth.user.roles) || []})
      , reject
    )
  )

empty = -> undefined

handle = (f) -> ({status, data}) ->
  f ?= empty
  err = f(status, data) or new errors.UnknownAuthError status, data
  if err instanceof errors.UnknownAuthError
    console.log 'auth: unknown error', status, data
  Promise.reject err

export register = (values, {invitationToken, rejectUnknownEmailDomain} = {}) ->
  new Promise (resolve, reject) ->
    data = Object.assign {}, values, {invitationToken, rejectUnknownEmailDomain}, {timeZone: moment.tz.guess(true), rejectUnknownEmailDomain: true}

    api.post "#{api.members}", data
    .then identity, handle (status, response) ->
      if status == 409 and response.message == 'Already registered'
        new errors.AlreadyRegistered
      else if status == 409 and response.message == "Please use your organization’s SSO page to register or sign-in into the Imperative Platform."
        new errors.SignInBySSO
      else if status == 404 and response.message == 'Invitation not found'
        new errors.InvalidInvitation
      else if status == 404 and response.message == 'Seats limit exceeded'
        new errors.SeatsLimitExceeded
      else if status == 404 and response.message == 'Organization domain not found'
        new errors.OrganizationDomainNotFound
      else if status == 403 and response.message == 'Unknown email domain'
        new errors.UnknownEmailDomain
      else if status == 403 and response.message == 'Referenced organization is no longer subscribed to Imperative'
        new errors.SubscriptionExpired
    .then(
      (auth) ->
        initUser(auth)
        .then -> history.push '/'
      , reject
    )

export ssoAuthenticateAndLogin = (provider, invitationToken) ->
  sso.authenticate provider
  .then (data) ->
    data.invitationToken = invitationToken if invitationToken
    ssoLogin provider, data
  .catch (e) ->
    Promise.reject e

export ssoLogin = (provider, data) ->
  new Promise (resolve, reject) ->
    api.post "#{api.sessions}?method=#{provider}", data
    .then identity, handle (status, {message}) ->
      if status == 400 and message.match /authentication failed$/
        new errors.SSOAuthFailed provider
      else if status == 400 and message == "Can't merge with unconfirmed account"
        new errors.SSOMergeFailed
      else if status == 400 and message.match /registration failed/
        new errors.SSONoAccount
      else if status == 403 and message == 'No longer subscribed to Imperative'
        new errors.SubscriptionExpired
    .then(
      (auth) ->
        initUser(auth, provider)
        .then -> history.push '/'
      , reject
    )

export requestPasswordReset = ({email}) ->
  api.post "#{api.passwords}", {email}

export resetPassword = (token, {password}) ->
  api(
    url: "#{api.passwords}/#{token}"
    method: 'PATCH'
    data: {password}
  )
  .then empty, handle (status, {message}) ->
    if status == 403 and message == 'Invalid mail token'
      new errors.BadResetToken
  .then(
    ->
      'success'
  , (err) ->
    if err instanceof errors.BadResetToken
      'badToken'
    else 'error resetting pass'
  )

export requestEmailConfirmation = ->
  api.post "#{api.members}/me/confirm"
  .then empty, handle (status, {message}) ->
    if status == 400 and message == 'Member email is already confirmed'
      new errors.EmailAlreadyConfirmed
  .then ->
    'success'
  , (err) ->
    if err instanceof errors.EmailAlreadyConfirmed
      'alreadyConfirmed'
    else
      'error requesting confirmation'

export confirmEmail = (token) ->
  api.patch "#{api.members}/#{token}/confirm"
  .then empty, handle (status, {message}) ->
    if status == 403 and message == 'Invalid confirmation token'
      new errors.BadConfirmationToken
  .then ->
    'success'
  , (err) ->
    if err instanceof errors.BadConfirmationToken
      'badToken'
    else
      $l.debug 'error confirming email', err
      'error confirming email'

export updateUser = (dto) ->
  api.patch "/members/me", dto

export getConsents = (slug) =>
  api.get("/members/#{slug}/consents");

export updateConsents = (slug, data) =>
  api.patch("/members/#{slug}/consents", data)
  .then saveConsents

export checkIsSSOEnabled = (domain) =>
  api.get("/auth/is-sso-only", {params: {domain}})

export getAuthBySocial = (params) =>
  api.get("/sessions/bysocial/authn", {params})

export pauseMember = (slug, dto) =>
  api.post("/members/#{slug}/pause", dto)

export updatePauseMember = (slug, dto) =>
  api.patch("/members/#{slug}/pause", dto)

export unpauseMember = (slug) =>
  api.delete("/members/#{slug}/pause")

export checkIfWorkOSEnabled = (domain) =>
  api.get("/sessions/workos/#{domain}/auth")

export signinWithWorkOS = (domain, code) =>
  new Promise((resolve, reject) =>
    api.post("/sessions/workos/#{domain}", qs.stringify({code}))
    .then(({jwt}) =>
      signinWithJwt(jwt)
      .then(() => resolve(jwt))
    , reject)
  )
