import * as _ from 'ramda'

ELEMENTS = ['impact', 'values', 'craft']

DESCRIPTIONS_AND_ASIDES =
  'Impact, Values and Craft': ({who, why, how}) ->
    CONTENTS =
      Harmony:
        'the idea that we must secure a level playing field for there to be progress'
      Karma:
        'the idea that hard work and determination will bring progress'
      Human:
        'empathetic, human-centered solutions'
      Community:
        'communities who take ownership of issues and solutions'
      Structure:
        'structures and systems that ensure success'
      Knowledge:
        'uncovering insights and sharing vital information'

    CONTENT_LINK =
      Karma:
        Knowledge: 'through'
        Structure: 'by creating'
        Human: 'through'
        Community: 'through'
      Harmony:
        Knowledge: 'through'
        Structure: 'by creating'
        Human: 'by developing'
        Community: 'by developing'

    content = ({driver}) -> CONTENTS[driver]

    contentLink = (why, how) -> CONTENT_LINK[why.driver][how.driver]

    description:
      """
      A triple threat! We seek to impact #{inflectDriver who} with
      #{content why} #{contentLink why, how} #{content how}.
      """

    asides: [
      """
      We feel most invigorated when we celebrate and recognize our team
      using our WHO, WHY, and HOW drivers: #{who.driver}, #{why.driver}, #{how.driver}.
      """
      """
      There is a good chance that we all share at least one driver in common
      with each other and our team drivers. This creates cohesion within the
      team, enables smooth collaboration, and makes it easy to share best
      practices and grow competencies.
      """
      """
      Pretty much any way we choose to celebrate each other’s work will be
      appreciated and will help people be valued and appreciated!
      """
      """
      Groupthink is a risk due to a homogenous culture. Pro-actively seek
      input from team members or people outside the team with diverse drivers
      when you are brainstorming, innovating, or have a big growth agenda.
      """
    ]

  'Impact and Values': ({who, why, how}) ->
    description:
      """
      This is what makes our team unique. This is likely how other teams
      see us. Collaboration will come easy when it is oriented around
      impacting #{inflectDriver who} and rooted in values related to #{inflectDriver why}.
      We might need to put a little extra effort into ensuring we continue to
      develop and use a diverse approach to problem solving, but once our team
      forms a shared sense of purpose around impacting #{inflectDriver who} with
      valued based in #{inflectDriver why}, we will be purpose-charged!
      """

    asides: [
      """
      Orient team goals toward impacting #{inflectDriver who} with strategy
      rooted in #{inflectDriver why}.
      """
      """
      Discuss ways we can use the diversity within our team’s HOW drivers
      to accomplish those “Big, Hairy, Audacious Goals” (BHAGs)!
      """
      """
      When goals have been achieved, recognize and celebrate the team by
      noting progress toward achieving strategic goals and how it impacted
      #{inflectDriver who}.
      """
      """
      To help with innovation and growth, avoid groupthink by encouraging
      those with drivers that are not the dominant team driver to contribute
      different perspectives.
      """
      """
      Leverage the diversity of HOW drivers within the team, and encourage
      team members to create a network of people who share the same craft to
      ensure they develop!
      """
    ]

  'Values and Craft': ({who, why, how}) ->
    CONTENTS =
      Harmony: 'fairness'
      Karma: 'hard work'
      Human: 'human-centered design'
      Community: 'building ownership within communities'
      Structure: 'developing structures'
      Knowledge: 'knowledge and creating insights'

    content = ({driver}) -> CONTENTS[driver]

    SUMMARY =
      Knowledge:
        """
        We might need to put a little extra effort into ensuring we have a shared
        vision of who we want to impact, but once we form a shared sense of purpose
        around #{content why} through knowledge and creating insights, we will be unstoppable!
        """
      Human: 'We will be unstoppable!'
      Community: 'We will be unstoppable!'
      Structure: 'We will be unstoppable!'

    summary = ({driver}) -> SUMMARY[driver]

    description:
      """
      This is what makes our team unique. This is how other teams see us.
      Collaboration comes easy when we root in #{inflectDriver why} and
      problem-solve based on #{content how}. #{summary how}
      """

    asides: [
      "Orient our team values in #{inflectDriver why}"
      """
      Discuss ways we can use the strength of our craft culture to achieve
      "Big, Hairy, Audacious Goals" (BHAGs)!
      """
      """
      When goals have been achieved, recognize and celebrate the team by
      noting how it impacted #{inflectDriver who}
      """
      """
      To help with innovation and growth, avoid groupthink by encouraging
      those with drivers that are not the dominant team driver to contribute
      different perspectives.
      """
    ]

  'Impact and Craft': ({who, why, how}) ->
    CONTENTS =
      Human: 'human-centered design'
      Community: 'building communities'
      Structure: 'developing structures'
      Knowledge: 'knowledge and creating insights'

    content = ({driver}) -> CONTENTS[driver]

    description:
      """
      This is what makes our team unique. This is likely how other teams
      see us. We might need to put a little extra effort into ensuring we
      have a set of shared values to help the team operate as one unit, but
      once we form a shared sense of purpose towards impacting #{inflectDriver who}
      through #{content how}, we will be unstoppable!
      """

    asides: [
      "Orient team goals toward impacting #{inflectDriver who}"
      """
      Discuss ways we can use the strength of our craft culture to achieve
      the "Big, Hairy, Audacious Goals" (BHAGs)
      """
      """
      When goals have been achieved, recognize and celebrate the team by
      noting progress toward achieving strategic goals and how it impacted
      #{inflectDriver who}
      """
      """
      To help with innovation and growth goals, avoid group think by
      encouraging those with drivers that are not the dominant team driver
      to contribute different perspectives.
      """
    ]

  'Impact': ->
    description:
      """
      The impact driver has the highest signal within the team. This means that our team culture
      is naturally impact-oriented. How cool is that? This comes with advantages and disadvantages.
      Our strong impact driver is a good place for the team to find common ground, but we might
      need to put effort into our blind spots and to actively encourage different perspectives.
      With only a little effort, the team will become purpose-charged!
      """

    asides: [
      "Make sure our team goals clearly describe who is impacted by our work."
      "Recognize and celebrate the team accomplishments by describing their impact."
      "Consider what would incentivize a team with a strong impact culture."
      """
      Help team members who do not share the impact driver or do not have a strong WHO signal to
      recognize their roles in the team, and be sure that their voices can be heard.
      """
    ]

  'Values': ->
    description:
      """
      The WHY driver has the highest signal within the team. This means that our team culture is
      naturally values-oriented. Our team easily connects and norms, placing high importance on values.
      With a strong set of shared values as the foundation of the team, we can navigate any challenge,
      weather any storm, and use those values as guardrails to guide the team toward impact!
      We might need to put effort into our blind spots and to actively encourage different perspectives,
      to avoid the possibility of this driver overpowering the diversity of perspectives required for innovation.
      """

    asides: [
      "Make sure our team goals clearly reflect our team’s shared values. (Have you defined them yet?)"
      "Consider what would incentivize a team with a strong strategic culture."
      "Recognize and celebrate the team accomplishments by noting progress toward achieving strategic goals."
      """
      Help team members who do not share the same WHY driver or do not have a strong
      WHY driver signal to recognize their roles in the team, and be sure that their voices can be heard.
      """
    ]

  'Craft': ->
    description:
      """
      The HOW driver has the highest signal within the team. This means that our team
      culture is naturally craft-oriented. A team with a craft culture is one that easily
      shares best practices and methods, and its members learn from one another.
      We can quickly collaborate and swap tasks or share responsibilities because
      we can easily relate to how it’s done. We might need to put a little extra effort
      into keeping the strategy and impact in focus.
      """

    asides: [
      "Create a mini-cohort within the team for best-practice sharing, if it hasn’t been formed already."
      "Recognize and celebrate the team accomplishments by acknowledging the way the problem was solved"
      "Consider what would incentivize a team with a strong craft culture."
      """
      Help team members who do not share the same HOW driver or who do not have
      a strong HOW driver signal to recognize their roles in the team and to have a voice,
      to avoid disillusionment. They are important in helping the team see new perspectives!
      """
    ]

EXCELS =
  impact: [
    'We act with the customer in mind'
    'We prioritize based on impact'
    'We celebrate the impact of our work'
    'We are optimistic about our potential impact'
    'We are bottom-line oriented'
  ]
  values: [
    'We create clear principles that guide decisions'
    'We practice what we preach (integrity)'
    'We are trusted by others inside and outside of the organization'
    'We invest in setting a shared strategy to ensure that we are on the same page'
  ]
  craft: [
    'We have the skills and talents needed to excel'
    'We celebrate the quality of the work we do'
    'We are seen as experts'
    'We hire people who are exceptional at their craft'
    'We constantly learn and improve the quality of our work'
  ]

STRUGGLES =
  impact: [
    "We set unrealistic goals"
    "We change direction too often based on external feedback"
    "We put stakeholders' needs ahead of the organization"
    "We underestimate the effort needed to make an impact"
    "We compromise values and process to make an impact"
  ]
  values: [
    "We are stubborn or myopic about a specific definition of right and wrong"
    "We make it hard for diverse values to be heard"
    "We are overly sensitive"
    "We require a lot of time to norm"
  ]
  craft: [
    'We compromise the achievement of results if we feel the quality of our work product would otherwise be negatively affected'
    'We fail to see that there are many ways to achieve a goal'
  ]


inflectDriver = ({driver}) ->
  switch driver
    when 'Individual', 'Organization' then "#{driver}s"
    else driver

getTitle = (elements) ->
  ds = elements.map (e) ->
    e[0]?.toUpperCase() + e.slice(1)?.toLowerCase()
  title = switch ds.length
    when 3 then "#{ds[0]}, #{ds[1]} and #{ds[2]}"
    when 2 then "#{ds[0]} and #{ds[1]}"
    else ds[0]

getDescriptionAndAsides = (title, {who, why, how}) ->
  DESCRIPTIONS_AND_ASIDES[title] {who, why, how}


export default ({drivers, leadershipDistribution}) ->
  greatestCmp = (a, b) -> leadershipDistribution[b] - leadershipDistribution[a]
  elements = _.sort greatestCmp, ELEMENTS.filter (e) -> leadershipDistribution[e] > 0
  title = getTitle [elements[0]]
  {description, asides} = getDescriptionAndAsides title, drivers
  excels = _.pick elements, EXCELS
  struggles = _.pick elements, STRUGGLES
  {elements, title, description, asides, excels, struggles}
