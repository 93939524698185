import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as _ from 'ramda'
import {withStateHandlers, withProps, withHandlers} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import {Collapse} from 'react-collapse'
import Avatar from 'theme/Avatar'
import Markdown from 'theme/Markdown'
import * as Fields from 'conversations/Questions/Fields'

import 'theme/scaffolding/scaffolding-styles.sass'
import './Conversation.sass'

export default composeComponent 'Conversation.Answers',
  withProps ({conversation, answers, user}) ->
    filterMyAnswers = (ans) ->
      ans.memberId is user?.id

    grouped = if answers then _.groupBy _.prop('questionId'), answers else {}

    withMemberSorted = Object.keys(grouped).reduce (obj, label) ->
        answers = grouped[label]
        sorted =
          answers
          .map (ans) ->
            _.merge ans,
              writes: conversation?.participant?.find (p) ->
                p.member.memberId is ans.memberId
              speaks: conversation?.participant?.find (p) ->
                p.member.memberId isnt ans.memberId
          .sort filterMyAnswers
        _.merge obj, "#{label}": sorted
      , {}

    questions = _.uniqBy _.prop('label'), conversation?.questions.filter (q) -> q.answerType isnt 'Prework' and not q.action

    {answers: withMemberSorted, questions}

  withHandlers
    editioning: ({editioning}) -> (v) -> editioning v

  ({
    conversation
    user
    t
    answers
    questions
    edition
    editioning
    updateAnswers
    updatingAnswers
  }) ->

    React.createElement("div", null,
      (questions?.map (question, i) ->
        questionContent = question.questionContent
        answersForQuestion = answers[question.label]
        answer = answersForQuestion?[0]
        Field = Fields?[question.questionType]
        React.createElement("div", {"className": "Conversation__question", "key": (question.label)},
          React.createElement("div", { \
            "className": "Conversation__questionTitle"
          },
            React.createElement("span", null, "Question ", (i + 1), ":"),
            React.createElement("div", {"className": "Conversation__questionTitleDescription"},
              (questionContent.map (c, i) ->
                React.createElement(Markdown, {"key": (i), "source": (c.value)})
              )
            )
          ),
          React.createElement(Collapse, {"isOpened": (true)},
            React.createElement("div", {"className": "Conversation__questionAnswers"},
              (answersForQuestion?.filter((a) -> a.memberId isnt user.id)
              .map (a) ->
                React.createElement("div", {"className": "Conversation__questionAnswer", "key": (a.memberId)},
                  (if edition
                    React.createElement(Field, { \
                      "value": (a?.answer),  \
                      "label": (question.label),  \
                      "max": (question.optionsCount),  \
                      "placeholder": "Your partner's answer",  \
                      "optionsCount": (question.optionsCount),  \
                      "sliderLabel": (false),  \
                      "onChange": (editioning(a))
                    })
                  else
                    React.createElement("div", {"className": "Conversation__questionAnswer-field"},
                      React.createElement(Markdown, {"source": (a?.answer)})
                    )
                  )
                )
              )
            )
          )
        )
      ),
      (if edition
        React.createElement("div", {"className": "Conversation__questionAnswersButton"},
          React.createElement("button", {"onClick": (() -> updateAnswers()), "disabled": (updatingAnswers), "className": "btn btn_secondary btn_solid_bluePurple margin-bottom-3"}, """
            Save
""")
        )
      )
    )
