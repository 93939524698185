import api, {getUser, apiWholeRequest} from 'api'
import * as _ from 'ramda'

BothWriteAnswerOneByOne = 'BothWriteAnswerOneByOne'
Action = 'Action'
mapTo = ['FirstWriteAnswer', 'SecondWriteAnswer']


mapWithDefinitions = (conversation, definitions) ->
  [programLabel, setLabel, convLabel] = _.split '/', conversation?.label ? ''
  program = definitions.find (d) -> d.label is programLabel
  set = program.sets.find (s) -> s.label is setLabel
  conv = set.definitions.find (c) -> c.label is convLabel
  questions = conv?.questions.reduce(
    (arr, q, i) ->
      if q.answerType isnt BothWriteAnswerOneByOne and q.answerType isnt Action
        _.append _.mergeRight(q, _i: i), arr
      else
        _.concat arr, mapTo.map (answerType) ->
          _.mergeRight q, {answerType, oneByOne: true, _i: i, action: q.answerType is Action}
    []
  )

  orderI = set?.definitions?.findIndex (c) -> c.label is convLabel
  order = if orderI isnt -1 then "#{orderI + 1} of #{set?.definitions?.length}"

  _.mergeAll [conv, questions: questions, conversation, {order}]

getConversationsDefinitions = ->
    api.get '/peerconversationdefinition/list'

export fetchConversation = (conversationId) ->
  new Promise (resolve, reject) ->
    api.get "/peerconversations?id=#{conversationId}"
    .then _.head
    .then (conversation) ->
      getConversationsDefinitions()
      .then (definitions) ->
        resolve mapWithDefinitions conversation, definitions
      , reject
    , reject

export fetchConversationsInWorkshop = (workshopId, organizationCode) ->
  new Promise (resolve, reject) ->
    api.get "peerconversations/cplquery", params: {workshopId, organizationCode}
    .then (conversations) ->
      getConversationsDefinitions()
      .then (definitions) ->
        resolve conversations.map (conversation) ->
          mapWithDefinitions conversation, definitions
      , reject
    , reject

export fetchTeamReport = (teamSlug) ->
  api.get "/teams/#{teamSlug}/report"

export getIndividualReport = (slug) =>
  api.get "/members/#{slug}/report"

export fetchFeedbacks = ->
  new Promise (resolve, reject) ->
    api.get "/feedback-received"
    .then (feedbacks) =>
      return resolve([]) unless feedbacks
      api.get "/feedback-received-members"
      .then (members) ->
        resolve members.map (m) ->
          feedback = feedbacks.find (f) -> f.giverId is m.id
          Object.assign {}, m, selectedPowerUps: feedback.selectedPowerUps
    .catch (err) ->
      resolve []

export fetchPowerUps = ->
  new Promise (resolve, reject) ->
    api.get "/power-ups/me/all"
    .then (allPowerUps) ->
      api.get "/self-assessment"
      .then (selfAssessment) ->
        resolve Object.keys(allPowerUps).reduce(
          (result, dimension) ->
            Object.assign {}, result, "#{dimension}": allPowerUps[dimension]?.map (powerUp) ->
              foundScore = selfAssessment?.assessments?.find ({powerUpId}) -> powerUpId is powerUp.id
              Object.assign {}, powerUp, score: foundScore?.score
          {}
        )
    .catch (err) ->
      resolve {}

export fetchColleague = (memberSlug) ->
  api.get "/colleagues/#{memberSlug}"

export fetchOrganization = (orgSlug) ->
  new Promise (resolve, reject) =>
    api.get "/organizations/#{orgSlug}"
    .then(
      (org) ->
        api.get "/organizations/#{orgSlug}/purpose-overview"
        .then(
          (purposeOverview) ->
            resolve({...org, purposeOverview})
          , reject
        )
      , reject
    )
