import React from 'react'
import PT from 'prop-types'
import * as _ from 'ramda'
import cx from 'classnames'

import Quote from './Quote'

export default ({t})  ->
  React.createElement("section", {"className": "LongReport__overview"},
    React.createElement("div", {"className": "LongReport__row"},
      React.createElement("div", {"className": "LongReport__columnWide"},
        React.createElement(Quote, {"author": "Howard Thurman"}, """
          Don’t ask what the world needs.
          Ask what makes you come alive, and go do it.
          Because what the world needs is people who have come alive.
"""),

        React.createElement("p", {"className": "LongReport__text"},
          React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_black"}, "Purpose is "),
          React.createElement("span", {"className": "LongReport__text"}, """
            what makes you awesome and irreplaceable. It is you at your best.
            People who work with purpose outperform their peers, develop better relationships,
            experience deeper growth, and (bonus!) they experience significantly deeper levels of fulfillment.
""")
        ),

        React.createElement("p", {"className": "LongReport__text"},
          React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_black"}, "This is the opportunity "),
          React.createElement("span", {"className": "LongReport__text"}, """
            you’ve been waiting for — an opportunity to unlock your superpowers by working
            with purpose! There is no reason to wait until after work to feel fulfilled.
""")
        ),

        React.createElement("p", {"className": "LongReport__text"},
          React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_black"}, "With this profile "),
          React.createElement("span", {"className": "LongReport__text"}, """
            you will tap into your motivations, discover your natural leadership style,
            and learn how to grow your purpose super-powers. This is the guide to unlocking
            potential no matter your role, level or organization.
""")
        ),

        React.createElement("p", {"className": "LongReport__text"},
          React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_black"}, "It’s all up to you how you use it."),
          React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_bullet LongReport__listItem_wide"},
            React.createElement("span", {"className": "LongReport__listItemContent"}, """
              Onboarding into a new role? Tailor your work for maximum fulfillment
""")
          ),
          React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_bullet LongReport__listItem_wide"},
            React.createElement("span", {"className": "LongReport__listItemContent"}, """
              Looking to develop? Identify mentors, development plans, and next steps
""")
          ),
          React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_bullet LongReport__listItem_wide"},
            React.createElement("span", {"className": "LongReport__listItemContent"}, """
              Leading a team? Get in touch with your natural leadership approach
""")
          ),
          React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_bullet LongReport__listItem_wide"},
            React.createElement("span", {"className": "LongReport__listItemContent"}, """
              Frustrated or nearing burn-out? Learn how to break-through!
""")
          )
        ),

        React.createElement("p", {"className": "LongReport__text"},
          React.createElement("span", {"className": "LongReport__subtitle LongReport__subtitle_italic LongReport__subtitle_black"}, """
            So, let’s get started.
""")
        )
      ),
      React.createElement("div", {"className": "LongReport__columnNarrow"}
      )
    )
  )
