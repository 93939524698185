const timezonesAllowList = [
  {
    id: 1,
    description: "Eniwetok, Kwajalein",
    name: "Pacific/Kwajalein"
  }, {
    id: 2,
    description: "Midway Island, Samoa",
    name: "Pacific/Midway"
  }, {
    id: 3,
    description: "Hawaii",
    name: "Pacific/Honolulu"
  }, {
    id: 4,
    description: "Alaska",
    name: "America/Anchorage"
  }, {
    id: 5,
    description: "Pacific Time (US & Canada)",
    name: "America/Los_Angeles"
  }, {
    id: 6,
    description: "Mountain Time (US & Canada)",
    name: "America/Dawson_Creek"
  }, {
    id: 50,
    description: "Arizona MST",
    name: "America/Phoenix"
  }, {
    id: 51,
    description: "Tuba City MDT",
    name: "America/Denver"
  }, {
    id: 39,
    description: "Central America, El Salvador",
    name: "America/El_Salvador"
  }, {
    id: 7,
    description: "Central Time (US & Can.), Mexico",
    name: "America/Chicago"
  }, {
    id: 8,
    description: "Eastern Time (US & Can.)",
    name: "America/New_York"
  }, {
    id: 38,
    description: "Caracas",
    name: "America/Caracas"
  }, {
    id: 9,
    description: "Atlantic Time (Can.)",
    name: "America/Halifax"
  }, {
    id: 10,
    description: "Newfoundland",
    name: "America/St_Johns"
  }, {
    id: 11,
    description: "Brazil, Buenos Aires, Georgetown",
    name: "America/Sao_Paulo"
  }, {
    id: 12,
    description: "Mid-Atlantic",
    name: "Atlantic/South_Georgia"
  }, {
    id: 13,
    description: "Azores",
    name: "Atlantic/Azores"
  }, {
    id: 40,
    description: "Coordinated Universal Time",
    name: "Africa/Banjul"
  }, {
    id: 36,
    description: "Reykjavik",
    name: "Atlantic/Reykjavik"
  }, {
    id: 14,
    description: "Western Europe Time, London, Lisbon",
    name: "Europe/London"
  }, {
    id: 15,
    description: "Brussels, Copenhagen, Madrid",
    name: "Europe/Brussels"
  }, {
    id: 37,
    description: "South African Standard Time",
    name: "Africa/Johannesburg"
  }, {
    id: 16,
    description: "Athens, Cairo",
    name: "Europe/Athens"
  }, {
    id: 17,
    description: "Baghdad, Moscow, Riyadh",
    name: "Europe/Moscow"
  }, {
    id: 18,
    description: "Tehran",
    name: "Asia/Tehran"
  }, {
    id: 19,
    description: "Abu Dhabi, Muscat",
    name: "Asia/Muscat"
  }, {
    id: 20,
    description: "Kabul",
    name: "Asia/Kabul"
  }, {
    id: 21,
    description: "Islamabad, Karachi",
    name: "Asia/Karachi"
  }, {
    id: 22,
    description: "Bombay, Calcutta, Colombo, New Delhi",
    name: "Asia/Kolkata"
  }, {
    id: 41,
    description: "Nepal Standard Time",
    name: "Asia/Kathmandu"
  }, {
    id: 23,
    description: "Almaty, Dhaka",
    name: "Asia/Almaty"
  }, {
    id: 24,
    description: "Bangkok, Hanoi, Jakarta",
    name: "Asia/Bangkok"
  }, {
    id: 25,
    description: "Beijing, Perth, Singapore, Hong Kong",
    name: "Asia/Singapore"
  }, {
    id: 26,
    description: "Tokyo, Seoul, Osaka",
    name: "Asia/Tokyo"
  }, {
    id: 27,
    description: "Adelaide",
    name: "Australia/Adelaide"
  }, {
    id: 34,
    description: "Brisbane",
    name: "Australia/Brisbane"
  }, {
    id: 28,
    description: "Canberra, Melbourne, Sydney",
    name: "Australia/Sydney"
  }, {
    id: 32,
    description: "Lord Howe Island",
    name: "Australia/Lord_Howe"
  }, {
    id: 29,
    description: "Solomon Isl., New Caledonia",
    name: "Pacific/Guadalcanal"
  }, {
    id: 30,
    description: "Auckland, Fiji, Kamchatka",
    name: "Pacific/Fiji"
  }, {
    id: 33,
    description: "Chatham Islands",
    name: "Pacific/Chatham"
  }, {
    id: 35,
    description: "New Zealand Daylight Time, Tonga",
    name: "Pacific/Tongatapu"
  }
];

const getAllowedTimezoneDescriptionWithTimeOffest = (zone) => (
  `(GMT${zone.currentTimeFormat.split(' ')[0]}) ${timezonesAllowList.find(allowed => allowed.name === zone.name).description}`
);

export const getAllowedTimezones = (timezones) => (
  timezones
    .filter(zone => timezonesAllowList.find(allowed => allowed.name === zone.name))
    .map(zone => ({...zone, description: getAllowedTimezoneDescriptionWithTimeOffest(zone)}))
);
