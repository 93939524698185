import React from 'react';
import cx from 'classnames';
import {remove} from 'ramda';


const RadioMulti = (props) => {
  const {options, value, onChange, question, t} = props;
  const change = (option) => {
    const val = value || [];
    const foundI = val.indexOf(option);
    if(foundI > -1)
      return onChange(remove(foundI, 1, val))
    return onChange(val.concat(option));
  }
  return (
    <div>
      {options.map((o, i) => {
        const selected = value && value.indexOf(o.value) > -1;
        return (
          <label
            key={i}
            className={cx("Assessment__option", {selected})}
            >
            <input
              type="checkbox"
              checked={Boolean(selected)}
              onChange={() => change(o.value)}
              id={`Assessment__option${o.value}`}
            />
            <div>
              <p className="Assessment__radioLabel">
                {t.find(`surveys.purpose-assessment.questions.${question.id}.options.${o.value}`)}
              </p>
              <i className={cx("Assessment__radioMark", selected ? 'fas fa-check-circle' : 'fal fa-circle')} />
            </div>
          </label>
        )
      })}
    </div>
  )
}

export default RadioMulti;
