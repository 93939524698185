import api from 'api'
import {merge, mergeAll} from 'ramda'

export listCompetencies = ->
  api.get '/competency'

export getConversations = ->
  api.get '/peerconversations'

export getConversationsDefinitions = ->
  api.get '/peerconversationdefinition/list'

export saveCompetencies = (dto) ->
  api.post "/peerconversationset/finish", dto

export getFinishedSets = () ->
  api.get "/peerconversationset"

export getConversationTree = ->
  Promise.all([
    getConversations()
    getConversationsDefinitions()
  ]).then ([conversations, programs]) ->
      programs.map (program, i) ->
        merge program,
          sets: program.sets.map (set) ->
            merge set,
              definitions: set.definitions.map (conv) ->
                pathLabel = "#{program.label}/#{set.label}/#{conv.label}"
                mergeAll [
                  conv
                  pathLabel: pathLabel
                  conversations: conversations.filter (c) ->
                      c.label is pathLabel
                ]
