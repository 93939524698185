import React from 'react'
import cx from 'classnames'
import * as _ from 'ramda'
import {Link} from 'react-router-dom'
import RSelect, {components} from 'react-select'
import Checkbox from 'theme/Checkbox'

import './Form.sass'

export const TextField = ({input, label, required, placeholder, id, className, textarea, disabled,
  meta: {touched, error, warning}, type, maxlength, withoutError, setRef, hint, tooltip, autocompleteOff}) => (
  <div className={cx("AuthForm__field", className, disabled)}>
    <div className="AuthForm__infos">
      <span className={cx("AuthForm__fieldError", {withLabel: label}, {visible: touched && error && !withoutError})}>
        {error}
      </span>
      <span className={cx("AuthForm__fieldWarning", {withLabel: label}, {visible: touched && warning})}>
        {warning}
      </span>
    </div>
    <div className="AuthForm__input">
      <label className={cx({required})} htmlFor={id}>
        {label}
        {textarea &&
          <textarea
            placeholder={placeholder}
            className={cx({
              'has-value': input.value && input.value !== '',
              err: touched && error,
              warn: touched && warning
            })}
            disabled={disabled}
            id={id}
            maxLength={maxlength}
            ref={setRef}
            {...input}
          />
        } 
        {!textarea &&
          <input
            type={type || "text"}
            placeholder={placeholder}
            autoComplete={(autocompleteOff && `new-${input.name}`) || input.name}
            className={cx({
              'has-value': input.value && input.value !== '',
              err: touched && error,
              warn: touched && warning
            })}
            maxLength={maxlength}
            id={id}
            disabled={disabled}
            ref={setRef}
            {...input}
          />
        }
      </label>
      {touched && warning &&
        <span className="AuthForm__fieldIcon fas fa-exclamation-triangle" />
      }
      {tooltip &&
        <div className="AuthForm__tooltip">
          <span className="fas fa-info-circle AuthForm__tooltipInfo" />
          <div className="AuthForm__tooltipContent">
            {tooltip}
          </div>
        </div>
      }
      {hint &&
        <Link className="AuthForm__hint" to={hint.to}>
          {hint.text}
        </Link>
      }
    </div>
  </div>
);

export const CheckboxField = ({input, label, required, id, className, disabled, meta: {touched, error, warning}, hint, tooltip}) => (
  <div className={cx("AuthForm__field", className, {disabled})} onClick={() => input.onChange(!Boolean(input.value))}>
    <div className="AuthForm__input checkbox">
      <label htmlFor={id} className={cx({required})}>
        {label}
        <Checkbox
          className={cx({
            err: touched && error,
            warn: touched && warning
          })}
          disabled={disabled}
          checked={Boolean(input.value)}
          onChange={(e) => input.onChange(!Boolean(input.value))}
        />
      </label>
      {tooltip &&
        <div className="AuthForm__tooltip">
          <span className="fas fa-info-circle AuthForm__tooltipInfo" />
          <div className="AuthForm__tooltipContent">
            {tooltip}
          </div>
        </div>
      }
      {hint &&
        <Link className="AuthForm__hint" to={hint.to}>
          {hint.text}
        </Link>
      }
    </div>
  </div>
);

export const Select = ({
  input,
  label,
  required,
  placeholder,
  options,
  meta,
  className,
  disabled,
  isMulti,
  optionClassName
}) => {

  const [selectedOption, setOption] = useState(() => {
    if (!input.value || !options) return null;
    if (isMulti)  {
      return options.filter(o => o.value in input.value)
    }
    else {
      return options.find(o => o.value === input.value)
    };
  });

  const handleChange = (option) => {
    const value = isMulti ? _.pluck('value', option) : option.value;
    setOption(option);
    input.onChange(value);
  };

  const {touched, error} = meta;

  const Option = (props) => (
    <div className={cx("AuthForm__selectOption", optionClassName && props.data)}>
      <components.Option {...props} />
    </div>
  );

  return (
    <div className={cx("AuthForm__field", className, {disabled})}>
      {label &&
        <label className={cx({required})} htmlFor={input.name}>
          {label}
        </label>
      }
      <div className="AuthForm__input">
        {touched && error &&
          <span className="AuthForm__fieldError">{error}</span>
        }
        <RSelect
          className={cx("AuthForm__select", {err: touched && error})}
          classNamePrefix="AuthForm__select"
          options={options}
          onChange={handleChange}
          value={selectedOption}
          placeholder={placeholder}
          disabled={disabled}
          autoBlur
          isMulti={isMulti}
          components={{Option}}
        />
      </div>
    </div>
  )
};

export const BtnContainer = ({children}) => (
  <div className="AuthForm__btnContainer">
    {children}
  </div>
);
