
import React, {useCallback, useState, useEffect, useMemo} from 'react'
import cx from 'classnames'
import {getPreviousCommitment,
        updateActionConfirmation,
        getActionAreas,
        getActionConfirmation,
        getActionCommitment,
        getSubInvestmentAreas,
        updateActionCommitment}         from 'conversations/requests'
import Markdown from 'theme/Markdown'
import Loader from 'theme/Loader';
import {Text, Radio} from 'conversations/Questions/Fields';
import Card from './Card';


const Actions = (props) => {
  const {t, conversation, user} = props;
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [previousActionCommitment, setActionAnswer] = useState(null);
  const [areas, setAreas] = useState([]);
  const [editedConfirmation, setEditedConfirmation] = useState(null);
  const [actionCommitment, setCommitment] = useState(null);
  const [editedCommitment, setEditedCommitment] = useState(null);
  const [openFieldsVisible, showOpenFields] = useState(false);
  const [openFieldsTimeout, setOpenFieldsTimeout] = useState(null);
  const [textFieldForOtherVisible, showTextFieldForOther] = useState(false);
  const [subInvestmentAreas, setSubImvestmentAreas] = useState([]);


  const selectedAreaForNext = useMemo(() => {
    return actionCommitment && areas.find(a => actionCommitment.areaId == a.id)
  }, [actionCommitment, areas]);
  const selectedAreaForPrevious = useMemo(() => {
    return previousActionCommitment && areas.find(a => a.id == previousActionCommitment.areaId);
  }, [previousActionCommitment, areas]);
  const editedSelectedArea = useMemo(() => {
    return editedCommitment && areas.find(a => editedCommitment.areaId == a.id)
  }, [editedCommitment, areas]);
  const subAreasForSelectedArea = useMemo(() => {
    return ((editedSelectedArea && subInvestmentAreas.find(g => g.areaId == editedSelectedArea.id)) || []).subInvestmentAreas;
  }, [editedSelectedArea, subInvestmentAreas]);

  const mappedSubAreas =
  (!subAreasForSelectedArea || subAreasForSelectedArea.length == 0)
    ? []
    : subAreasForSelectedArea.map(label => ({label})).concat({label: "Other", customOption: true});

  const loadActionCommitment = useCallback(() => {
    setLoading(true);
    return getActionCommitment(conversation.id)
    .then((commitments) => {
      const forUser = commitments.find((w) => user && w.forMemberId == user.id);
      setCommitment(forUser);
      setLoading(false);
    }, () => setLoading(false));
  }, [conversation, user]);

  const loadActionAreas = useCallback(() => {
    return getActionAreas(conversation.id).then(setAreas);
  }, [conversation]);

  const loadSubInvestmentAreas = useCallback(() => {
    return getSubInvestmentAreas(conversation.id).then(setSubImvestmentAreas);
  }, [conversation]);

  const loadActionAnswers = useCallback(() => {
    return getPreviousCommitment(conversation.id)
    .then((answers) => {
      const myAction = answers.find((a) => a.forMemberId == user.id);
      setActionAnswer(myAction);
    });
  }, [conversation, user]);

  const loadConfirmation = useCallback(() => {
    return getActionConfirmation(conversation.id).then((confirmations) => {
      const myConfirmation = confirmations.find((c) => c.memberId == user.id);
      setConfirmation(myConfirmation)
    })
  }, [conversation, user]);

  const editConfirmation = useCallback((edit) => {
    return setEditedConfirmation(edit ? confirmation : null);
  }, [confirmation]);

  const editActionImpact = useCallback((values) => {
    return setEditedConfirmation((editedConfirmation) => ({...editedConfirmation, ...values}));
  }, [editedConfirmation]);

  const updateConfirmation = useCallback(() => {
    setLoading(true);
    return updateActionConfirmation(conversation.id, confirmation.id, editedConfirmation)
    .then(() => {
      editConfirmation();
      loadConfirmation()
      .then(
        () => setLoading(false),
        () => setLoading(false),
      )
    })
  }, [conversation, editedConfirmation]);

  const saveActionCommitment = useCallback((data) => {
    if(!conversation || !actionCommitment || !data)
      return Promise.resolve();
    setLoading(true);
    return updateActionCommitment(conversation.id, actionCommitment.id, editedCommitment)
    .then(() => {
      loadActionCommitment()
      .then(() => {
        setEditedCommitment(null);
        setLoading(false);
      })
    }, () => setLoading(false))
  }, [conversation, actionCommitment, editedCommitment]);

  const selectImpactedArea = useCallback((area) => {
    const impactedAreasIds = editedConfirmation.impactedAreasIds || [];
    const selectedImpactAreaI = impactedAreasIds.indexOf(area.id);
    if(selectedImpactAreaI == -1) {
      editActionImpact({impactedAreasIds: impactedAreasIds.concat(area.id)});
    } else {
      editActionImpact({impactedAreasIds: impactedAreasIds.slice(0, selectedImpactAreaI).concat(impactedAreasIds.slice(selectedImpactAreaI + 1))});
    }
  }, [editedConfirmation]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      loadActionAnswers(),
      loadConfirmation(),
      loadActionAreas(),
      loadActionCommitment(),
      loadSubInvestmentAreas()
    ]).then(
      () => setLoading(false),
      () => setLoading(false)
    )
  }, []);


  const selectArea = (area) => {
    setEditedCommitment((c) => ({...c, subInvestmentArea: null}));
    showTextFieldForOther(false);
    setEditedCommitment((c) => ({...c, areaId: area.id}));
  }

  const selectOption = (o) => {
    if(o.customOption) {
      setEditedCommitment((c) => ({...c, subInvestmentArea: null}));
      showTextFieldForOther(true);
      showOpenFields(false);
    }
    else {
      showTextFieldForOther(false);
      setEditedCommitment((c) => ({...c, subInvestmentArea: o.label}));
    }
  };

  const cancelEdit = useCallback(() => {
    setEditedCommitment(null);
    setEditedConfirmation(null);
  }, []);

  const edit = useCallback(() => {
    setEditedCommitment(actionCommitment);
    setEditedConfirmation(confirmation);
  }, [actionCommitment, confirmation]);

  const save = useCallback(() => {
    if(!conversation)
      return Promise.resolve();
    setLoading(true);
    return Promise.all([
      editedCommitment && updateActionCommitment(conversation.id, actionCommitment.id, editedCommitment),
      editedConfirmation && updateActionConfirmation(conversation.id, confirmation.id, editedConfirmation)
    ])
    .then(() => {
      Promise.all([
        loadActionCommitment(),
        loadConfirmation()
      ])
      .then(() => {
        setEditedCommitment(null);
        setEditedConfirmation(null);
        setLoading(false);
      })
    }, () => setLoading(false))
  }, [editedCommitment, editedConfirmation, conversation]);

  useEffect(() => {
    const subArea = editedCommitment && editedCommitment.subInvestmentArea;
    if(!subArea) return;
    const otherOption = !mappedSubAreas.find(a => a.label == subArea);
    if(otherOption) {
      setOpenFieldsTimeout(setTimeout(() => showOpenFields(true), 2000));
    } else {
      showOpenFields(true);
    }

    return () => {
      clearTimeout(openFieldsTimeout);
    }
  }, [editedCommitment]);

  const editing = editedConfirmation != null || editedCommitment != null;

  if(!loading && !confirmation && !previousActionCommitment && !actionCommitment)
    return null;

  const title =
    <h4 className="Conversation__subtitle">
      <span>
        My
        <img alt="Imperative" className="Conversation__subtitle-img" src="https://assets.imperative.com/static.imperative.com/images/logos/logo_blue.png" />
      </span>
    </h4>

  const titleActions = editing
    ?
      <div className="Conversation__cardContentButtons">
        <button className="btn btn_secondary btn_outlined_bluePurple" onClick={cancelEdit}>
          {t.find("Cancel Edit")}
        </button>
        <button onClick={save} className="btn btn_secondary btn_solid_bluePurple">
          {t.find("Save")}
        </button>
      </div>
    :
      <div>
        <button className="btn btn_secondary btn_solid_bluePurple" onClick={edit}>
          {t.find("Edit")}
        </button>
      </div>

  return (
    <Card title={title} buttons={titleActions} expanded>
      <Loader loading={loading} />
      {confirmation && previousActionCommitment &&
        <div className="Conversation__question">
          <div className="Conversation__questionRow col-2">
            <div>
              <div className="Conversation__questionTitle actions">
                Previous
              </div>
              <div className="Conversation__questionContent actions previous">
                {previousActionCommitment.areaId != null
                ?
                  <div className="Conversation__questionAction">
                    <b>I commited to</b> {selectedAreaForPrevious && selectedAreaForPrevious.areaName} - {t.find(previousActionCommitment.subInvestmentArea, user)}<br />
                    <b>My <i>Imperative</i> was</b> {previousActionCommitment.what}.<br />
                    <b>It was an <i>Imperative</i> for me because</b> {previousActionCommitment.why}.
                  </div>
                :
                  <div className="Conversation__questionAction">
                    I commited to <b>{previousActionCommitment.what}</b>
                  </div>
                }
              </div>
            </div>
            <div className="Conversation__questionConfirmation notes">
              <div className="Conversation__questionConfirmation-info">
                <p>Achieved?</p>
                <p>{confirmation.performed ? 'Yes' : 'No'}</p>
              </div>
              <div className={cx("Conversation__questionConfirmation-icon", {"active": confirmation.performed})}>
                <i className="fad fa-bullseye-arrow" />
              </div>
            </div>
          </div>
          <div className="Conversation__questionContent actions">
            {confirmation.performed &&
              <div className="Conversation__questionAction margin-top-2">
                <p>The action impacted my:</p>
                {!editedConfirmation
                  ?
                    <ol>
                      {areas.filter(area => confirmation.impactedAreasIds && confirmation.impactedAreasIds.indexOf(area.id) > -1).map(area =>
                        <li key={area.id}>{area.areaName}</li>
                      )}
                    </ol>
                  :
                    <Radio
                      isOptionActive={(o) => editedConfirmation.impactedAreasIds.indexOf(o.id) > -1}
                      onChange={(o) => selectImpactedArea(o)}
                      loading={loading}
                      options={areas}
                      labelTranslated={(o) => o.areaName}
                      type="multi"
                      className="rig"
                    />
                }
              </div>
            }
          </div>
          {(confirmation.answer || editedConfirmation) &&
            <div className="Conversation__questionAnswers">
              <div className="Conversation__questionAnswer">
                {editedConfirmation
                ?
                  <Text
                    value={editedConfirmation.answer}
                    onChange={(answer) => editActionImpact({answer})}
                    placeholder={editedConfirmation.performed ? "What was the impact of taking that action?" : "What happened?"}
                  />
                :
                  <div className="Conversation__questionAnswer-field">
                    <Markdown source={confirmation.answer} />
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }
      {actionCommitment &&
        <div className="Conversation__question">
          <div className="Conversation__questionTitle actions">
            Next Focus
          </div>
          {actionCommitment.areaId == null &&
            <div className="Conversation__questionContent actions">
              <div className="Conversation__questionAction">
                {editedCommitment
                ?
                  <div>
                    <div>
                      What one action is an <i>Imperative</i> to do before our next conversation?
                    </div>
                    <Text
                      value={editedCommitment.what || ''}
                      onChange={(what) => setEditedCommitment((c) => ({...c, what}))}
                      placeholder="Type your answer..."
                      rich={false}
                    />
                  </div>
                : <>
                    I commit to
                    <div className="Conversation__questionAnswer-field">
                      {actionCommitment.what}
                    </div>
                  </>
                }
              </div>
            </div>
          }
          {actionCommitment.areaId != null &&
            <div className="Conversation__questionContent actions">
              <div className="Conversation__questionAction">
                {editedCommitment
                ?
                  <>
                    What area feels like an <i>Imperative</i> to focus on before our next conversation?
                    <div className="Questions__actionSubareas">
                      {areas.map((o) => {
                        return (
                          <button
                            key={o.id}
                            className={cx("Questions__actionSubareas-btn", {
                              active: o.id == editedCommitment.areaId
                            })}
                            onClick={() => selectArea(o)}
                          >
                            {"My " + o.areaName}
                          </button>
                        )
                      })}
                    </div>
                    {editedSelectedArea &&
                      <div className="margin-top-2">
                        {editedSelectedArea.areaName && editedSelectedArea.areaName.toLowerCase() == 'relationships' && t.find("Which relationship(s)?")}
                        {editedSelectedArea.areaName && editedSelectedArea.areaName.toLowerCase() == 'impact' && t.find("Where do you want to make an impact? ")}
                        {editedSelectedArea.areaName && editedSelectedArea.areaName.toLowerCase() == 'growth' && t.find("In what area do you want to grow?")}
                        <div className="Questions__actionSubareas">
                          {mappedSubAreas.map((o) => {
                            return (
                              <button
                                key={o.label}
                                className={cx("Questions__actionSubareas-btn", {
                                  active: o.label == editedCommitment.subInvestmentArea || (textFieldForOtherVisible && o.customOption)
                                })}
                                onClick={() => selectOption(o)}
                              >
                                {t.find(o.label)}
                              </button>
                            )
                          })}
                        </div>
                        {textFieldForOtherVisible &&
                          <Text
                            className="margin-top-2 small-input"
                            value={editedCommitment.subInvestmentArea || ''}
                            onChange={(subInvestmentArea) => setEditedCommitment((c) => ({...c, subInvestmentArea}))}
                            rich={false}
                            maxLength="50"
                            placeholder="Type your option..."
                          />
                        }
                      </div>
                    }
                  </>
                :
                  <>
                    <b>
                      I commit to
                    </b>
                    {" "}{selectedAreaForNext && selectedAreaForNext.areaName} - {t.find(actionCommitment.subInvestmentArea, user)}
                  </>
                }
              </div>
              <div className="Conversation__questionAction">
                {editedCommitment
                ?
                  (editedCommitment.subInvestmentArea && openFieldsVisible &&
                    <div className="margin-top-2">
                      <div>
                        What one action is an <i>Imperative</i> to do before our next conversation
                        {editedSelectedArea && editedSelectedArea.areaName.toLowerCase() == 'relationships' &&
                          " to build your relationship with your "
                        }
                        {editedSelectedArea && editedSelectedArea.areaName.toLowerCase() == 'impact' &&
                          " to make an impact on "
                        }
                        {editedSelectedArea && editedSelectedArea.areaName.toLowerCase() == 'growth' &&
                          " to grow personally and professionally by working on your "
                        }
                        {t.find(editedCommitment.subInvestmentArea && editedCommitment.subInvestmentArea.toLowerCase())}
                        ?
                      </div>
                      <Text
                        value={editedCommitment.what || ''}
                        onChange={(what) => setEditedCommitment((c) => ({...c, what}))}
                        placeholder="Type your answer..."
                        rich={false}
                      />
                    </div>
                  )
                :
                  <>
                    <b>
                      My <i>Imperative</i> is
                    </b>
                    {" "}{actionCommitment.what}
                  </>
                }
              </div>
              <div className="Conversation__questionAction">
                {editedCommitment
                ?
                  (editedCommitment.subInvestmentArea && openFieldsVisible &&
                    <div className="margin-top-2">
                      Why is this an <i>Imperative</i> to do before our next conversation?
                      <Text
                        value={editedCommitment.why || ''}
                        onChange={(why) => setEditedCommitment((c) => ({...c, why}))}
                        placeholder="Type your answer..."
                        rich={false}
                      />
                    </div>
                  )
                :
                  <>
                    <b>
                      This is an <i>Imperative</i> for me because
                    </b>
                    {" "}{actionCommitment.why}
                  </>
                }
              </div>
            </div>
          }
        </div>
      }
    </Card>
  )
}

export default Actions;
