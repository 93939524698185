import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as _ from 'ramda'
import {logger} from 'api'
import Video from 'twilio-video'
import { GaussianBlurBackgroundProcessor } from '@twilio/video-processors'
import {withRouter, Link} from 'react-router-dom'
import {withHandlers, withState, withStateHandlers, lifecycle, mapProps, withProps} from 'recompose'
import cx from 'classnames'
import moment from 'moment'
import {URL_PEER_CONVS} from 'theme/assets/assets'
import {getTwilioToken} from 'conversations/requests'
import * as NetworkIcons from './icons'
import Notification from './Notification'
import DeviceSelector from './DeviceSelector'
import './Video.sass'

aspectRatio = 1.33
isChromium = !!window.chrome;

blurBackgroundProcessor =
  if isChromium
    new GaussianBlurBackgroundProcessor({
      assetsPath: "https://assets.imperative.com/static.imperative.com/twilio-video-processors-assets/v1.0.1/"
    })
  else null

trackSubscribed = (div, track) ->
  div.appendChild(track.attach())

trackUnsubscribed = (track) ->
  track.detach().forEach (element) -> element.remove()

export default composeComponent 'Video',
  withState 'room', 'setRoom', null
  withState 'authToken', 'setToken', null
  withState 'status', 'setStatus', null
  withState 'error', 'setError', null
  withState 'time', 'setTime', null
  withState 'timeInterval', 'setTimeInterval', null
  withState 'localNetworkQ', 'setLocalNetworkQ', null
  withState 'remoteNetworkQ', 'setRemoteNetworkQ', null
  withState 'settingsVisible', 'showSettings', false

  withProps ({room}) ->
    videoTracks = Array.from(room?.localParticipant?.videoTracks?.values() ? []).map (t) -> t.track
    audioTracks = Array.from(room?.localParticipant?.audioTracks?.values() ? []).map (t) -> t.track
    audioTracks: audioTracks
    videoTracks: videoTracks
    audioTrack: audioTracks?[0]
    videoTrack: videoTracks?[0]
    localTracks: room?.localParticipant?.tracks?.values() && Array.from(room?.localParticipant?.tracks?.values()).map (t) -> t.track

  withHandlers
    addTime: ({time, setTime}) -> ->
      setTime time?.clone().add(1, 's')

  withProps ({conversation, status, user, room}) ->
    ua = navigator.userAgent
    isIE = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1
    partnerConnected = room?.participants.size > 0

    {isIE, partnerConnected}

  withHandlers
    participantConnected: ({setStatus, setTimeInterval, setTime, timeInterval, addTime, externalVideoId, setRemoteNetworkQ}) -> (participant) ->
      div = document.createElement 'div'
      div.id = participant.sid

      externalDiv = do ->
        a = document.createElement 'div'
        a.id = participant.sid + '_external'
        a

      participant.on('networkQualityLevelChanged', (qualityLevel) ->
        setRemoteNetworkQ(qualityLevel)
      );

      participant.on('trackSubscribed', (track) ->
        trackSubscribed(div, track)
        if externalDiv and track.kind is 'video'
          trackSubscribed(externalDiv, track)
      )
      participant.on('trackUnsubscribed', trackUnsubscribed)

      participant.tracks.forEach (publication) ->
        if (publication.isSubscribed)
          trackSubscribed(div, publication.track)

          if externalDiv and track.kind is 'video'
            trackSubscribed(externalDiv, publication.track)

      document.getElementById('remote-media')?.appendChild(div)
      if externalDiv?
        document.getElementById(externalVideoId)?.appendChild(externalDiv)

      unless timeInterval
        setTime moment.duration(0, 's')
        setTimeInterval setInterval addTime, 1000

    participantDisconnected: ({setStatus, room, setTimeInterval, setTime, timeInterval}) -> (participant) ->
      document.getElementById(participant.sid).remove()
      document.getElementById("#{participant.sid}_external")?.remove()
      setStatus 'partnerDisconnected'

      if timeInterval
        clearInterval timeInterval
        setTimeInterval null
        setTime null

    attachTracks: -> (tracks, container) ->
      tracks.forEach (track) ->
        container?.appendChild(track.attach())
      tracks

    detachTracks: -> (tracks) ->
      tracks.forEach (track) ->
        if track
          track.detach().forEach (detachedElement) ->
            detachedElement.remove()

    stopTracks: -> (tracks) ->
      tracks.forEach (track) ->
        if track
          track.stop()

    blurBackground: -> (track, value) ->
      if(!blurBackgroundProcessor)
        return
      if(track && value && !track.processor)
        blurBackgroundProcessor.loadModel()
        .then(() =>
          track.addProcessor(blurBackgroundProcessor)
          Promise.resolve()
        )
        .catch((err) =>
          console.error('processor error:', err)
          Promise.reject(err)
        )
      else if(track && !value && track.processor)
        track.removeProcessor(blurBackgroundProcessor)
        Promise.resolve()

  withHandlers
    startLocalTrack: ({isIE, localTracks, videoEnabled, attachTracks}) -> ({withoutVideo} = {}) ->
      if localTracks && localTracks.length > 0
        Promise.resolve localTracks
      else if isIE
        Promise.reject 'IEError'
      else
        Video.createLocalTracks({
          audio: true,
          video: if videoEnabled? and videoEnabled and not withoutVideo
              {aspectRatio}
            else false
        })
        .then (tracks) ->
          localMediaContainer = document.getElementById('local-media')
          attachTracks(tracks, localMediaContainer)


    updateVideoDevice: ({room, stopTracks, detachTracks, attachTracks}) -> (deviceId) ->
      if !room
        return Promise.reject('not connected to the room')
      localParticipant = room.localParticipant
      if deviceId != null && localParticipant
        Video.createLocalVideoTrack({
          deviceId: {exact: deviceId},
          aspectRatio: aspectRatio
        }).then((localVideoTrack) ->
          tracks = Array.from(localParticipant.videoTracks.values()).map((t) -> t.track)
          localParticipant.unpublishTracks(tracks)
          detachTracks(tracks);
          stopTracks(tracks);

          localParticipant.publishTrack(localVideoTrack)
          localMediaContainer = document.getElementById('local-media')
          attachTracks([localVideoTrack], localMediaContainer)
          localVideoTrack
        )
      else
        return Promise.reject('no device selected')

    updateAudioDevice: ({room, detachTracks, stopTracks, attachTracks}) -> (deviceId) ->
      if !room
        return Promise.reject('not connected to the room')
      localParticipant = room.localParticipant
      if deviceId != null
        Video.createLocalAudioTrack({
          deviceId: {exact: deviceId},
        }).then((localAudioTrack) ->
          tracks = Array.from(localParticipant.audioTracks.values()).map((t) -> t.track)
          localParticipant.unpublishTracks(tracks)
          detachTracks(tracks);
          stopTracks(tracks);

          localParticipant.publishTrack(localAudioTrack)
          localMediaContainer = document.getElementById('local-media')
          attachTracks([localAudioTrack], localMediaContainer)
          localAudioTrack
        )
      else
        return Promise.reject('no device selected')

    stopLocalTracks: ({room, detachTracks, stopTracks}) -> ->
      if room?.localParticipant?.tracks
        tracks = Array.from(room.localParticipant.tracks.values()).map((t) -> t.track)
        room.localParticipant.unpublishTracks(tracks)
        detachTracks(tracks);
        stopTracks(tracks);

  withHandlers
    roomJoined: ({updateParticipantStatus, conversation, setRoom, setLocalMediaAvailable, partnerStatus, joinRoom, user, participantConnected, participantDisconnected, setStatus, setError, stopLocalTracks, setTime, setTimeInterval, time, timeInterval, addTime, videoTrack, setLocalNetworkQ}) -> (room) ->
      if partnerStatus is 'CameraOff'
        videoTrack?.disable()
      else if partnerStatus is 'Connected'
        videoTrack?.enable()

      setError null
      setStatus 'connected'

      room.localParticipant.on('networkQualityLevelChanged', (qualityLevel) ->
        setLocalNetworkQ(qualityLevel)
      );

      room.participants.forEach(participantConnected);
      room.on('participantConnected', participantConnected);

      room.on('participantDisconnected', participantDisconnected);

      room.on('reconnecting', (error) =>
        if (error)
          if (error.code == 53001)
            setError type: 'twilio', message: "Twilio connection lost. Reconnecting..."
          else if (error.code == 53405)
            setError type: 'twilio', message: "Camera or microphone connection lost. Reconnecting..."
      );
      room.on('reconnected', () =>
        setError null
      );

      room.on('participantReconnecting', (remoteParticipant) ->
        setError type: 'twilio', message: "Your partner lost connection. Reconnecting..."
      );
      room.on('participantReconnected', (remoteParticipant) ->
        setError null
      );

      room.on 'disconnected', (room, error) ->
        if (error)
          if (error.code == 20104)
            setError type: 'twilio', message: "AccessToken expired. Disconnected"
          else if (error.code == 53000)
            setError type: 'twilio', message: "Tried to reconnect too many times. Disconnected"
          else if (error.code == 53204)
            setError type: 'twilio', message: "Reconnection took too long. Disconnected"

        stopLocalTracks()
        room.participants.forEach(participantDisconnected)
        clearInterval timeInterval
        setTimeInterval null
        setTime null
        setStatus 'disconnected'
        updateParticipantStatus 'Disconnected'

      window.addEventListener('beforeunload', () =>
        room.disconnect();
      )

      setRoom room

  withHandlers
    startVideo: ({updateParticipantStatus, conversation, previewTracks, roomJoined, user, setStatus, setError, startLocalTrack, error}) -> ->
      startLocalTrack()
      .then (tracks) ->
        setStatus 'deviceStarted'
        getTwilioToken(user.id)
        .then (token) ->
          Video.connect token, {
            name: conversation.id,
            tracks: tracks,
            networkQuality: {
              local: 1,
              remote: 2
            },
            preferredVideoCodecs: 'auto',
            bandwidthProfile: {
              video: {
                contentPreferencesMode: 'auto',
                clientTrackSwitchOffControl: 'auto'
              }
            }
          }
          .then roomJoined
          , (err) ->
            setError type: 'twilio', message: err.data.message # 'Something went wrong. Contact administrator'
            console.error 'error: ', err
        , (err) ->
          setError type: 'twilio', message: err.data.message # 'Something went wrong. Contact administrator'
          console.error 'error: ', err
      , (err) ->
        if err is 'IEError'
          updateParticipantStatus 'IEError'
        else
          updateParticipantStatus 'CameraError'
          setError type: 'camera', message: 'Cannot connect to camera'
          console.error 'error: ', err

    endVideo: ({room}) -> ->
      room?.disconnect()

    toggleVideo: ({enableVideo, updateParticipantStatus, videoTrack, conversation, user}) -> ->
      videoEnabled = videoTrack?.isEnabled
      enableVideo(!videoEnabled)
      if videoEnabled then videoTrack?.disable() else videoTrack?.enable()

    toggleAudio: ({audioTrack}) -> ->
      if audioTrack?.isEnabled then audioTrack?.disable() else audioTrack?.enable()

  lifecycle
    componentDidMount: ->
      @props.startVideo()

    componentDidUpdate: (prevProps) ->
      {enableVideo, updateParticipantStatus, setError, error, displayMode, videoTrack, partnerStatus, conversation, user} = @props
      if partnerStatus isnt prevProps.partnerStatus
        if partnerStatus is 'CameraOff' and prevProps.partnerStatus is 'Connected'
          enableVideo(false)
          videoTrack?.disable()
        else if prevProps.partnerStatus is 'CameraOff' and partnerStatus is 'Connected'
          enableVideo(true)
          videoTrack?.enable()

    componentWillUnmount: ->
      @props.endVideo()
      clearInterval @props.timeInterval

  withProps ({status, error, videoEnabled, videoTrack, videoTracks, displayMode, partnerStatus, isIE, partnerConnected}) ->
    waitingIE: isIE and not partnerConnected
    waiting: not isIE and not partnerConnected
    partnerOnlineIE: isIE and partnerConnected
    partnerIE: partnerStatus is 'IEError'
    partnerCameraError: partnerStatus is 'CameraError'
    partnerCameraOff: partnerStatus is 'CameraOff'
    audioOnly: !videoEnabled or !videoTracks?.every (t) -> t.isEnabled
    videoDisabled: displayMode is 'OFF' and !error?

  lifecycle
    componentDidUpdate: (prevProps) ->
      prev = _.pick ['isIE', 'partnerConnected', 'room'], prevProps
      props = _.pick ['isIE', 'partnerConnected', 'room'], @props

      if(!_.equals(prev, props))
        logger && logger.info("DEBUG: Waiting for partner to connect. conversationId: #{@props.conversation?.id}", {
          userId: @props.user?.id,
          userSlug: @props.user?.slug,
          conversationId: @props.conversation?.id,
          connectedToRoom: @props.room?,
          isIE: @props.isIE,
          partnerConnected: @props.partnerConnected,
          partnerStatus: @props.partnerStatus,
          videoEnabled: @props.videoEnabled,
          displayMode: @props.displayMode,
          error: @props.error
        })

  ({
    t
    user
    conversation
    startVideo
    room
    endVideo
    status
    error
    toggleVideo
    toggleAudio
    videoTrack
    audioTrack
    time
    videoEnabled
    displayMode
    isIE
    videoDisabled
    videoNotAllowed
    audioOnly
    partnerStatus
    partnerConnected
    waiting
    waitingIE
    partnerOnlineIE
    partnerIE
    partnerCameraError
    partnerCameraOff
    localNetworkQ
    remoteNetworkQ
    showSettings
    settingsVisible
    updateVideoDevice
    updateAudioDevice
    blurBackground
    notificationSent
  }) ->
    formattedTime = time?.minutes() + ":" + time?.seconds()
    bigVideo = partnerIE or (waiting and error?.type isnt 'twilio' and error?.type isnt 'camera' and not partnerCameraError)
    smileIconUrl = "#{URL_PEER_CONVS}/video_icon_comment.svg"
    videoPlaceholderUrl = "#{URL_PEER_CONVS}/video_bg_placeholder_v2.jpeg"

    remoteAudioElement = document.getElementById('remote-media')?.querySelector('audio');

    NetworkIndicator = ({level}) ->
      if level?
        Icon = NetworkIcons["signal#{level ? 0}"]
        React.createElement("div", {"className": "Video__quality"},
          React.createElement(Icon, {"className": (cx({low: level < 2, medium: level >= 2 && level < 3}))})
        )
      else React.createElement("span", null)

    actionButtons =
      React.createElement("div", {"className": (cx("Video__btns", {visible: settingsVisible}))},
        (if status isnt 'disconnected'
          React.createElement("button", { \
            "type": "button",  \
            "onClick": (toggleAudio),  \
            "className": (cx "btn btn-video btn-empty", disabled: not audioTrack?),  \
            "aria-label": (if audioTrack?.isEnabled then "mute" else "unmute")
          },
            (if audioTrack?.isEnabled
              React.createElement("i", {"className": "far fa-microphone"},
                React.createElement("span", null, "microphone")
              )
            else
              React.createElement("i", {"className": "far fa-microphone-slash"},
                React.createElement("span", null, "microphone enabled")
              )
            )
          )
        ),
        (if status is 'disconnected'
          React.createElement("button", {"type": "button", "onClick": (startVideo), "className": "btn btn-call", "aria-label": "Call"},
            React.createElement("i", {"className": "fas fa-phone"})
          )
        else
            React.createElement("button", {"type": "button", "onClick": (endVideo), "className": "btn btn-end", "aria-label": "End call"},
              React.createElement("i", {"className": "fas fa-phone"})
            )
        ),
        (if status isnt 'disconnected'
          React.createElement("button", { \
            "type": "button",  \
            "onClick": (toggleVideo),  \
            "className": (cx "btn btn-video btn-empty", disabled: not videoTrack?),  \
            "aria-label": (if videoTrack?.isEnabled then "disable video" else "enable video")
          },
            (if videoTrack?.isEnabled
              React.createElement("i", {"className": "far fa-video"},
                React.createElement("span", null, "video")
              )
            else
              React.createElement("i", {"className": "far fa-video-slash"},
                React.createElement("span", null, "video enabled")
              )
            )
          )
        )
      )

    React.createElement("div", null,
      (unless videoDisabled
        React.createElement("div", {"className": (cx "Video", big: bigVideo, audioOnly: audioOnly)},
          React.createElement("div", {"className": "Video__remote"},
            React.createElement("div", {"id": "remote-media"}),
            (if partnerConnected and !audioOnly
              React.createElement("div", {"className": "Video__participant"},
                React.createElement(NetworkIndicator, {"level": (remoteNetworkQ)}),
                (conversation?.partner?.member.firstName)
              )
            )
          ),
          React.createElement("div", {"className": (cx "Video__local", fullSize: not partnerConnected, audioOnly: audioOnly)},
            React.createElement("div", {"id": "local-media"}),
            (if partnerConnected and !audioOnly
              React.createElement("div", {"className": "Video__participant local"},
                React.createElement(NetworkIndicator, {"level": (localNetworkQ)}),
                (user?.firstName)
              )
            )
          ),

          React.createElement("div", {"className": (cx("Video__settings", {visible: settingsVisible}))},
            React.createElement("button", { \
              "type": "button",  \
              "onClick": (-> showSettings(!settingsVisible)),  \
              "className": (cx("btn btn-video btn-empty", {active: settingsVisible})),  \
              "aria-expanded": (settingsVisible),  \
              "aria-controls": "deviceSelector",  \
              "aria-label": (if settingsVisible then "Hide video settings" else "Show video settings")
            },
              React.createElement("i", {"className": "far fa-cog", "aria-hidden": true})
            ),
            React.createElement(DeviceSelector, { \
              "visible": (settingsVisible),  \
              "onClose": (-> showSettings(false)),  \
              "blurBackground": (if isChromium then ((val) => blurBackground(videoTrack, val)) else null),  \
              "updateAudioDevice": (updateAudioDevice),  \
              "updateVideoDevice": (updateVideoDevice),  \
              "remoteAudioElement": (remoteAudioElement)
            })
          ),

          (if error?.type is 'camera' or not waiting and (not partnerConnected or status is 'disconnected' or audioOnly)
            React.createElement("div", {"key": "bg", "className": "Video__placeholder", "style": ({backgroundImage: "url(#{videoPlaceholderUrl})"})})
          ),

          (if error?.type is 'twilio' and status isnt 'disconnected'
            React.createElement(Notification, {"type": "error"},
              React.createElement("h3", {"className": "Video__notificationTitle"}, "There appears to be an error trying to connect the call"),
              React.createElement("p", null, "There appears to be an issue with the video feed. Please check that your browser settings are correct, or contact your IT specialist to troubleshoot.")
            )
          else if error?.type is 'camera'
            React.createElement(Notification, {"type": "error"},
              React.createElement("div", {"className": "Video__notificationRow"},
                React.createElement("div", {"className": "Video__notificationRow-full"},
                  React.createElement("h3", {"className": "Video__notificationTitle"}, "There appears to be an error trying to connect the call"),
                  React.createElement("p", null, ("Either you blocked camera in browser or it's broken. Please check browser settings and try again."))
                ),
                React.createElement("div", {"className": "Video__notificationActions"},
                  React.createElement("button", {"type": "button", "onClick": (startVideo), "className": "btn btn-call", "aria-label": "Call again"},
                    React.createElement("i", {"className": "fas fa-phone"})
                  ),
                  React.createElement("p", null, "Call again")
                )
              )
            )
          else if partnerCameraError
            React.createElement(Notification, {"type": "error"},
              React.createElement("h3", {"className": "Video__notificationTitle"}, "Your partner is online, but their camera isn’t working."),
              React.createElement("p", null, ("It may be because your partner needs to give their camera permission."))
            )
          else if partnerOnlineIE
            React.createElement(Notification, {"type": "info"},
              React.createElement("h3", {"className": "Video__notificationTitle"}, "Your partner is online..."),
              React.createElement("p", null, ("It requires your partner to use a different browser as their browser doesn't support video. Have them try Chrome, Edge or Safari."))
            )
          else if partnerIE
            React.createElement(Notification, {"type": "info", "className": "big"},
              React.createElement("div", {"className": "Video__waiting"},
                React.createElement("img", {"src": (smileIconUrl), "alt": ""}),
                React.createElement("h3", {"className": "Video__notificationTitle"}, "We would love to turn on video for you."),
                React.createElement("p", null, ("It requires your partner to use a different browser as their browser doesn't support video. Have them try Chrome, Edge or Safari."))
              )
            )
          else if waitingIE
            React.createElement(Notification, {"type": "info"},
              React.createElement("h3", {"className": "Video__notificationTitle"}, "Activate Video."),
              React.createElement("p", null, ("We’d love to turn on video for you. It requires using a different browser as yours doesn't support video. Please use Chrome, Edge or Safari."))
            )
          else if waiting
            React.createElement(Notification, {"type": "info"},
              React.createElement("div", {"className": "Video__waiting"},
                React.createElement("img", {"src": (smileIconUrl), "alt": ""}),
                React.createElement("h3", {"className": "Video__notificationTitle"}, "Waiting for your partner..."),
                (if !partnerConnected && partnerStatus
                  React.createElement("p", null, ("Your partner has just disconnected. Please wait until they connect again."))
                ),
                (if !partnerConnected && !partnerStatus && notificationSent
                  React.createElement("p", null,
                    ("We let your partner know you're waiting. Hang on around 5 minutes; they’re likely on their way. If they haven’t arrived after 5 minutes, we recommend messaging them to confirm if now is still a good time to meet.")
                  )
                )
              )
            )
          else if status is 'disconnected'
            React.createElement(Notification, {"type": "info"},
              React.createElement("div", {"className": "Video__notificationRow"},
                React.createElement("div", {"className": "Video__notificationRow-full"},
                  React.createElement("h3", {"className": "Video__notificationTitle"}, ("You’ve disconnected the call")),
                  React.createElement("p", null, "If you’d like to reconnect press button on the right.")
                ),
                React.createElement("div", {"className": "Video__notificationActions"},
                  (actionButtons),
                  React.createElement("p", null, "Call again")
                )
              )
            )
          else if audioOnly
            React.createElement(Notification, {"type": "info"},
              (actionButtons)
            )
          else actionButtons
          )
        )
      )
    )
