import React from 'react'
import * as _ from 'ramda'
import cx from 'classnames'
import {withRouter} from 'react-router'
import moment from 'moment'
import {composeComponent, addStatesWithHandlers} from 'utils/react-tools'
import * as ME from 'api/metrics'
import {lifecycle, withProps} from 'recompose'
import {fetchUser} from 'auth/requests'
import Loader from 'theme/Loader'
import {IMG_LOGO_BLUE} from 'theme/assets/assets'
import Page from 'reports/PrintPage'
import convertData from 'reports/convertData'
import DownloadButton from 'reports/DownloadReportButton'
import DriverPage from 'reports/DriversAtGlance/DriverPage'
import {fetchColleague} from 'reports/requests'

import './ColleagueGuide.sass'

drivers =
  who: ['Individual', 'Organization', 'Society']
  why: ['Harmony', 'Karma']
  how: ['Community', 'Human', 'Knowledge', 'Structure']

convertGuide = (type, driver, t) ->
  prefix = "colleagues.guide.#{type}.#{driver}"
  title: t.find "#{prefix}.title"
  heading: t.find "#{prefix}.heading"
  description: t.find "#{prefix}.description"
  goals: ['take turns answering each question', 'Take notes for your partner; return them when youre done', 'Complete the two prompts on the back'] #t.getSplitted "#{prefix}.goals"
  frame: t.find "#{prefix}.frame"
  guide:
    description: t.find "#{prefix}.guide.description"
    questions: [
        question: t.find "#{prefix}.guide.questions.question1"
        tip: t.find "#{prefix}.guide.questions.tip1"
      ,
        question: t.find "#{prefix}.guide.questions.question2"
        tip: t.find "#{prefix}.guide.questions.tip2"
      ,
        question: t.find "#{prefix}.guide.questions.question3"
        tip: t.find "#{prefix}.guide.questions.tip3"
      ,
        question: t.find "#{prefix}.guide.questions.question4"
        tip: t.find "#{prefix}.guide.questions.tip4"
    ]

export default composeComponent 'ColleagueGuide',
  withRouter
  addStatesWithHandlers
    loading: false
    user: null
    member: null
  lifecycle
    componentDidMount: ->
      {match: {params}, setUser, setLoading, setMember} = @props

      setLoading(true);
      ME.reportStateChange('Colleague Guide: loading');

      Promise.all([
        fetchUser(),
        fetchColleague(params.slug)
      ]).then(
        ([user, member]) ->
          setUser(user);
          setMember(member);
          ME.reportStateChange('Colleague Guide: loaded');
          setLoading(false);
        , (error) ->
          ME.reportIntermediateError('Colleague Guide: loading error', error);
          setLoading(false);
      )

  ({user, member: m, loading, match: {params}, t}) ->
    unless m
      return null

    member = convertData(t).Colleague(m)

    {type, driver} = params

    number = 1 # page numbering start

    report = convertGuide type, driver, t

    if not report? then return React.createElement("div", null, "ERROR")

    date = moment().format 'MMMM D, YYYY'

    footer =
      React.createElement("div", {"className": "ColleagueGuide__footer"},
        React.createElement("span", {"className": "ColleagueGuide__footer_left"}, (t.find 'colleagues.guide.copyright')),
        React.createElement("img", {"src": (IMG_LOGO_BLUE), "alt": "Imperative"})
      )

    React.createElement("section", {"className": "ColleagueGuide"},
      React.createElement(Loader, {"loading": (loading), "color": "bg"}),
      React.createElement(DownloadButton, {"name": "ConversationGuide_#{type}_#{driver}"}),

      React.createElement("div", {"id": "ReportContent"},
        React.createElement(Page, {"footer": (footer), "marginSize": "sm"},
          React.createElement("h2", {"className": "ColleagueGuide__coachee"}, (t.find 'colleagues.guide.coachee')),
          React.createElement("h1", {"className": "ColleagueGuide__text-color #{driver}"},
            (report.title),
            React.createElement("span", {"className": "ColleagueGuide__icons"},
              React.createElement("span", {"className": "fas fa-feather-alt"}),
              React.createElement("span", {"className": "fas fa-comments"})
            )
          ),
          React.createElement("h2", {"className": "ColleagueGuide__heading"}, (report.heading)),
          React.createElement("p", {"className": "ColleagueGuide__description"}, (report.description)),

          React.createElement("div", {"className": "ColleagueGuide__goals #{driver}"},
            React.createElement("div", {"className": "ColleagueGuide__goals_title"}, "STEPS"),
            React.createElement("div", {"className": "ColleagueGuide__goals_itemContentBackground"},
            (report?.goals.map (g, i) ->
              React.createElement("div", {"key": (i), "className": "ColleagueGuide__goals_item ColleagueGuide__goals_item#{i} #{driver}"},
                React.createElement("span", {"className": "ColleagueGuide__goals_itemContent"}, (g))
              )
            )
            )
          ),

          React.createElement("table", {"className": "ColleagueGuide__questions #{driver}"},
            React.createElement("thead", null,
              React.createElement("tr", null,
                React.createElement("th", {"className": "#{driver}"}, "DISCUSSION QUESTIONS"),
                React.createElement("th", {"className": "#{driver}"}, "TIPS")
              )
            ),
            React.createElement("tbody", null,
              (report?.guide.questions.map (q,i) ->
                React.createElement("tr", {"key": (i)},
                  React.createElement("td", {"className": "#{driver}"},
                    React.createElement("span", null,
                      (q.question)
                    )
                  ),
                  React.createElement("td", {"className": "#{driver}"}, (q.tip))
                )
              )
            )
          )
        ),

        React.createElement(Page, {"footer": (footer), "marginSize": "sm"},
          React.createElement("h2", {"className": "ColleagueGuide__coachee"}, (t.find 'colleagues.guide.coachee')),
          React.createElement("h1", {"className": "ColleagueGuide__text-color #{driver}"},
            (report.title),
            React.createElement("span", {"className": "ColleagueGuide__icons"},
              React.createElement("span", {"className": "fas fa-feather-alt"}),
              React.createElement("span", {"className": "fas fa-comments"})
            )
          ),
          React.createElement("h2", {"className": "ColleagueGuide__heading"}, (report.heading)),
          React.createElement("div", {"className": "ColleagueGuide__quote"},
            React.createElement("span", {"className": "ColleagueGuide__quoteValue"},
              ('"'), (t.find "colleagues.guide.#{type}.#{driver}.quote.value"), ('" ')
            ),
            React.createElement("span", {"className": "ColleagueGuide__quoteAuthor"}, """
              - """, (t.find "colleagues.guide.#{type}.#{driver}.quote.author")
            )
          ),

          React.createElement("table", {"className": "ColleagueGuide__action"},
            React.createElement("tbody", null,
              React.createElement("tr", null,
                React.createElement("td", {"className": "#{driver}"},
                  React.createElement("span", null, (t.find 'colleagues.guide.takeaway'))
                ),
                React.createElement("td", {"className": "#{driver}"},
                  (t.find "colleagues.guide.#{type}.#{driver}.takeway"),
                  React.createElement("span", null)
                )
              ),
              React.createElement("tr", null,
                React.createElement("td", {"className": "#{driver}"},
                  React.createElement("span", null, (t.find 'colleagues.guide.action'))
                ),
                React.createElement("td", {"className": "#{driver}"},
                  (t.find "colleagues.guide.#{type}.#{driver}.action"),
                  React.createElement("span", null)
                )
              )
            )
          )
        ),

        (drivers[driver]?.map (d) ->
          React.createElement(DriverPage, {"dimension": (driver), "driver": (d), "key": (d), "t": (t)})
        )
      )

    )
