import React, { useEffect, useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import cx from 'classnames';
import * as ME from 'api/metrics';
import { fetchUser } from 'auth/requests';
import Footer from 'theme/Footer';
import Header from 'theme/Header';
import Loader from 'theme/Loader';
import Account from './Account';
import Landing from './Landing';
import Profile from './Profile';
import Region from './Region';

const Settings = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const getUser = async () => {
    try {
      const u = await fetchUser();
      setUser(u);
      return u;
    } catch (error) {
      ME.reportIntermediateError('Settings: loading user error', error);
      setError(error);
    }
  };

  const loadData = async () => {
    try {
      getUser();
    } catch (error) {
      setError(error);
    }
  };

  useEffect(
    () => {
      setLoading(true);
      ME.reportStateChange('Settings: loading');

      loadData().then(
        () => {
          setLoading(false);
          ME.reportStateChange('Settings: loaded');
        },
        () => setLoading(false)
      );
    },
    [] //only on mount
  );

  const navLinks = [
    {
      title: 'Edit profile',
      cardTitle: 'Edit profile',
      description: 'Manage your personal info and public profile',
      icon: (
        <i className="material-icons-outlined account" aria-hidden>
          account_circle
        </i>
      ),
      url: '/settings/profile',
      linkTitle: 'Manage your profile',
    },
    {
      title: 'Region',
      description: 'Control settings related to your location',
      icon: (
        <i className="material-icons-outlined" aria-hidden>
          language
        </i>
      ),
      url: '/settings/region',
      linkTitle: 'Manage your region',
    },
    {
      title: 'Manage account',
      cardTitle: 'Manage account',
      description: 'Control settings related to your account',
      icon: (
        <span className="Settings__icon-stacked" aria-hidden>
          <i className="material-icons">settings</i>
          <i className="material-icons">settings</i>
        </span>
      ),
      cardIcon: (
        <i className="material-icons-outlined" aria-hidden>
          settings
        </i>
      ),
      url: '/settings/account',
      linkTitle: 'Manage your account',
    },
  ];

  const onLanding = location.pathname == '/settings';

  return (
    <div className="Settings page">
      <Header user={user} />
      <div className="container">
        {!onLanding && (
          <nav className="Settings__nav">
            {navLinks.map((l, i) => (
              <Link key={i} to={l.url} className={cx('navItem', { active: location.pathname.indexOf(l.url) == 0 })}>
                {l.icon}
                <span>{l.title}</span>
              </Link>
            ))}
          </nav>
        )}
        <main className="container" id="main">
          <Loader loading={loading} />
          <div className="Settings__page">
            <Switch>
              <Route exact path="/settings/profile" component={Profile} />
              <Route path="/settings/account" component={Account} />
              <Route exact path="/settings/region" component={Region} />
              <Route>
                <Landing navLinks={navLinks} />
              </Route>
            </Switch>
          </div>
          <Footer position={!onLanding && 'static'} maxWidth={onLanding && 1040} />
        </main>
      </div>
    </div>
  );
};

export default Settings;
