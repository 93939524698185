import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { assoc, identity, merge, omit as ramdaOmit } from 'ramda';
import axios from 'axios';
import qs from 'qs';
import { identify as posthogIdentify } from 'api/posthog';
import history from './history';
import { resetUser } from './posthog';

const tokenStoreKey = 'auth__token';
const refreshTokenStoreKey = 'auth__refresh_token';
const consentsStoreKey = 'user__consents';
const assessmentNotificationStoreKey = 'user__assessment_notification';
const redirSSOStoreKey = 'redir_param';
const optInSnoozeStorageKey = 'opt_in_snoozed';
const domainStoreKey = 'org_domain';

const config = window.config;
const logger = window.DD_LOGS && window.DD_LOGS.createLogger('State change');
logger && logger.setLevel('warn');

let refreshingTokenPromise = null;
let createRefreshTokenPromise = null;

export const getRedirUrl = () => {
  try {
    const redir = sessionStorage.getItem(redirSSOStoreKey);
    sessionStorage.removeItem(redirSSOStoreKey);
    if (redir && typeof redir == 'string' && redir != 'null' && redir != 'undefined' && redir.length > 0) {
      return decodeURIComponent(redir);
    } else return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const saveRedirUrl = (path) => {
  try {
    sessionStorage.setItem(redirSSOStoreKey, decodeURIComponent(path));
    return path;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const saveDomain = (domain) => {
  try {
    sessionStorage.setItem(domainStoreKey, decodeURIComponent(domain));
    return domain;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getDomain = () => {
  try {
    const domain = sessionStorage.getItem(domainStoreKey);
    sessionStorage.removeItem(domainStoreKey);
    return decodeURIComponent(domain);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const authorizationHeader = async (a = {}) => {
  try {
    const auth = a || getAuth();
    const token = await getJWTToken(auth.jwt);
    return { Authorization: `Bearer ${token}` };
  } catch (err) {
    throw new Error('JWT Error: ' + err);
  }
};

export const getJWTToken = (jwt) => {
  let refreshToken = getRefreshToken();

  return (refreshToken ? Promise.resolve(refreshToken) : createRefreshToken(jwt)).then((refreshToken) => {
    if (JWTValid(jwt)) {
      return Promise.resolve(jwt);
    }
    return refreshJWT(refreshToken);
  });
};

const JWTValid = (jwt) => {
  if (!jwt) return false;
  const decoded = jwt_decode(jwt);
  return decoded && moment().isBefore(moment.unix(decoded.exp));
};

const refreshJWT = (refreshToken) => {
  if (refreshingTokenPromise) {
    return refreshingTokenPromise;
  }

  return (refreshingTokenPromise = api({
    method: 'post',
    url: '/session/refresh-tokens',
    data: { refreshToken },
  }).then((jwt) => {
    const auth = getAuth();
    saveAuth({ ...auth, jwt });
    refreshingTokenPromise = null;
    return jwt;
  }));
};

export const getAuth = () => {
  try {
    const auth = localStorage.getItem(tokenStoreKey);
    if (!auth) return {};
    return JSON.parse(auth);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const isLoggedIn = () => {
  const { jwt } = getAuth() || {};
  const refreshToken = getRefreshToken();
  if (!JWTValid(jwt) && !refreshToken) {
    return false;
  }
  return true;
};

export const getUser = () => {
  if (!isLoggedIn()) {
    return null;
  }

  const auth = getAuth();
  if (!auth || !auth.user || !auth.user.organization || !auth.user.organizationSettings) return null;

  const org = merge(auth.user.organization, auth.user.organizationSettings);

  return assoc('org', org, auth.user);
};

const saveToLocalStore = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return value;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const saveRefreshToken = (token) => {
  return saveToLocalStore(refreshTokenStoreKey, token);
};

export const getRefreshToken = () => {
  try {
    const token = localStorage.getItem(refreshTokenStoreKey);
    return token && JSON.parse(token);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const setUser = (user) => {
  const auth = getAuth();
  const oldU = getUser();
  const newAuth = merge(auth, { user: merge(oldU || {}, user) });

  posthogIdentify(newAuth.user);

  return saveToLocalStore(tokenStoreKey, newAuth);
};

const decodejwt = (jwt) => {
  try {
    return jwt && jwt_decode(jwt);
  } catch (err) {
    return null;
  }
};

export const saveAuth = (auth = {}, method = 'emailpass') => {
  const { valid, user = {}, jwt } = auth;
  const decoded = decodejwt(jwt);
  const u = {
    ...user,
    ...{
      ...decoded,
      roles: [...(user.roles || []), ...((decoded && decoded.roles) || [])],
    },
  };
  const exp = (decoded && decoded.exp) || valid;
  const token = decoded ? { exp, method, user: u, jwt } : null;
  return saveToLocalStore(tokenStoreKey, token);
};

export const snoozeAssessmentNotification = () => {
  return Boolean(saveToLocalStore(assessmentNotificationStoreKey, moment().valueOf()));
};

export const isAssessmentNotificationSnoozed = () => {
  try {
    const date = localStorage.getItem(assessmentNotificationStoreKey);
    if (!date) return false;
    return moment().diff(moment(parseInt(date)), 'hours') < 12;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const saveConsents = (consents) => {
  return saveToLocalStore(consentsStoreKey, consents);
};

export const getConsentsFromStorage = () => {
  try {
    const consents = localStorage.getItem(consentsStoreKey);
    if (!consents) return null;
    return JSON.parse(consents);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const snoozeOptIn = () => {
  try {
    localStorage.setItem(optInSnoozeStorageKey, 'true');
  } catch {
    return;
  }
};

export const optInSnoozed = () => {
  try {
    const data = localStorage.getItem(optInSnoozeStorageKey);
    return !!JSON.parse(data);
  } catch {
    return false;
  }
};

const api = axios.create({
  baseURL: config.api.base,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const apiWholeRequest = axios.create({
  baseURL: config.api.base,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const reportApi = axios.create({
  baseURL: '/',
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const removeStorage = () => {
  localStorage.removeItem(tokenStoreKey);
  localStorage.removeItem(consentsStoreKey);
  localStorage.removeItem(assessmentNotificationStoreKey);
  localStorage.removeItem(refreshTokenStoreKey);
  localStorage.removeItem(optInSnoozeStorageKey);
};

export const logout = () => {
  const auth = getAuth();
  const decoded = auth && decodejwt(auth.jwt);
  resetUser();
  if (decoded && moment().isBefore(moment.unix(decoded.exp))) {
    return api.delete('/session').then(removeStorage, removeStorage);
  } else {
    removeStorage();
    return Promise.resolve('Removed storage, token expired');
  }
};

export const createRefreshToken = (token) => {
  if (createRefreshTokenPromise) {
    return createRefreshTokenPromise;
  }

  if (!token) {
    return Promise.reject('Cannot create refresh token: no JWT token provided');
  }

  const deviceId = navigator.userAgent.replace(/(\/|\s)/g, '');

  createRefreshTokenPromise = api({
    method: 'post',
    url: `/session/devices/${deviceId}/refresh-tokens`,
    headers: { Authorization: `Bearer ${token}` },
  }).then(({ value }) => {
    createRefreshTokenPromise = null;
    return saveRefreshToken(value);
  }, removeStorage);

  return createRefreshTokenPromise;
};

const requestInterceptor = (config) => {
  return new Promise((resolve, reject) => {
    const isIE = false || !!document.documentMode;
    const withTimestamp =
      config && config.method == 'get' && isIE
        ? Object.assign({}, config, { params: Object.assign({}, config.params, { _timestamp: moment().format() }) })
        : config;

    const auth = getAuth();
    if (config.withCredentials !== false && isLoggedIn() && config.url.indexOf('/refresh-tokens') == -1) {
      authorizationHeader(auth).then(
        (authHeader) => {
          const headers = Object.assign({}, config.headers, authHeader);
          resolve(Object.assign({}, withTimestamp, { headers }));
        },
        () => {
          reject(Object.assign({}, withTimestamp));
          history.push('/logout');
        }
      );
    } else resolve(withTimestamp);
  });
};

const reportRequestInterceptor = (config) => {
  return new Promise((resolve, reject) => {
    const isIE = false || !!document.documentMode;
    const withTimestamp =
      config && config.method == 'get' && isIE
        ? Object.assign({}, config, { params: Object.assign({}, config.params, { _timestamp: moment().format() }) })
        : config;

    const auth = getAuth();
    if (isLoggedIn() && config.url.indexOf('/refresh-tokens') == -1) {
      const refreshToken = getRefreshToken();
      authorizationHeader(auth).then(
        (authHeader) => {
          const authorization = refreshToken ? { 'refresh-token': refreshToken } : authHeader;
          const headers = Object.assign({}, config.headers, authorization);
          resolve(Object.assign({}, withTimestamp, { headers }));
        },
        () => {
          resolve(Object.assign({}, withTimestamp));
          history.push('/logout');
        }
      );
    } else resolve(withTimestamp);
  });
};

const responseErrorInterceptor = (error) => {
  const user = getUser() || {};
  if (error.response && error.response.status == 401 && history.location.pathname != '/signin') {
    history.push('/logout');
  }
  const status = error.response && error.response.status;
  const message = error.response && error.response.data && error.response.data.message;
  logger &&
    logger.error(`Request error ${status}: ${message}`, {
      error: ramdaOmit(['config'], { ...error, response: ramdaOmit(['config'], error.response || {}) }),
      user_id: user.id,
    });
  return Promise.reject(ramdaOmit(['config'], error.response || error));
};

api.interceptors.request.use(requestInterceptor);
apiWholeRequest.interceptors.request.use(requestInterceptor);
reportApi.interceptors.request.use(reportRequestInterceptor);

api.interceptors.response.use((response) => response.data, responseErrorInterceptor);

apiWholeRequest.interceptors.response.use(identity, responseErrorInterceptor);

const pdfUrl = (config.pdf && config.pdf.base) || '/print-reports';

export const getReport = (filename) => {
  return reportApi
    .post(
      pdfUrl,
      {
        html: document.getElementsByTagName('html')[0].innerHTML,
        filename,
      },
      { responseType: 'blob' }
    )
    .then(
      (response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        //for microsoft IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, `${filename}.pdf`);
        }
        //other browsers
        else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filename}.pdf`;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      },
      (err) => {
        console.error('error generating pdf', err.data);
        return err.data;
      }
    );
};

api.metricsBase = '/metrics';
api.applicants = '/applicants';
api.members = '/members';
api.passwords = '/members/passwords';
api.organizations = '/organizations';
api.sessions = '/sessions';
api.surveys = '/surveys';
api.contactRequests = '/contact-requests';
api.patternStatistics = '/pattern/statistics';
api.industryStatistics = '/pattern/statistics/industry';
api.archetypeStatitics = '/pattern/statistics/archetype';
api.roleStatistics = '/pattern/statistics/job-function';

export const getAppVersion = () => {
  return axios
    .get(`${location.origin}/version?timestamp=${moment().valueOf()}`)
    .then((response) => response.data && response.data.trim());
};

const appRegex = /.*\/pcp\/app\/app.(test|v[0-9\.a-z]+)\.js/;

export const getScriptVersion = () => {
  let appScript = null;
  const scripts = document.querySelectorAll('script');
  scripts.forEach((s) => {
    if (appRegex.test(s.src)) {
      appScript = s;
    }
  });
  if (appScript) {
    const matches = appRegex.exec(appScript.src);
    const version = matches[1];
    logger && logger.addContext('appVersion', version);
    window.APP_VERSION = version;
    return version;
  } else return null;
};

getScriptVersion();

export { logger, apiWholeRequest };
export default api;
