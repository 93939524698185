import moment from 'moment-timezone';
import { path } from 'ramda';
import { Field, reduxForm } from 'redux-form';
import * as V from 'validate';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as ME from 'api/metrics';
import { assessmentQuestionNames, getAssessmentData, sendAnswer, updateDemographicData } from 'settings/requests';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import { useStore } from 'stores';
import TranslationsContext from 'translations';
import CollapsibleSection from './CollapsibleSection';
import { AvatarField, MultiText, Radio, Text } from './Fields';
import './Settings.sass';

const tenureYears = {
  158: 0,
  159: 1,
  160: 2,
  161: 3,
  162: 4,
  163: 5,
};

const avatarFileFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff'];

const successMsg = {
  'user.name.firstName': 'name',
  'user.name.lastName': 'name',
  'user.email': 'email',
  avatar: 'profile photo',
  'gender.answer': 'gender identity',
  'gender.answerText': 'gender identity',
  'role.answer': 'role',
  'role.answerText': 'role',
  'jobFunction.answer': 'job function',
  'jobFunction.answerText': 'job function',
  'race.answer': 'racial or ethnic identity',
  'race.answerText': 'racial or ethnic identity',
  'tenure.answer': 'tenure',
  'tenure.answerText': 'tenure',
};

const validators = {
  name: [
    (value) => {
      if (!value) return;
      const { firstName, lastName } = value;
      const msgRequired = 'Please enter a value';
      const msgLen = '100 character max. Valid characters: a-z, A-Z, and -';
      const reqFirst =
        (!firstName && msgRequired) || ((!/^[a-zA-Z\-\s]*$/.test(firstName) || firstName.length > 100) && msgLen);
      const reqLast =
        (!lastName && msgRequired) || ((!/^[a-zA-Z\-\s]*$/.test(lastName) || lastName.length > 100) && msgLen);
      if (!reqFirst && !reqLast) return;
      return {
        firstName: reqFirst,
        lastName: reqLast,
      };
    },
  ],
  email: [V.required('Enter an email please'), V.email('Enter a valid email please')],
  avatar: [
    //format
    (file) => {
      if (!file || !(file instanceof File)) return;
      if (avatarFileFormats.indexOf(file.type) == -1)
        return 'Invalid file format. Please choose a .jpg, .png, .gif or .tiff file';
    },
    //size
    (file) => {
      if (!file || !(file instanceof File)) return;
      if (file.size > 10000000) return 'File is too big. Please choose max 10MB file';
    },
  ],
  demographic: [
    (question) => {
      if (!question) return;
      if (!question.answer) {
        return 'Please select an option';
      }
      const foundOption = question.options && question.options.find((o) => o.id == question.answer);
      if (foundOption && foundOption.hasTextField && !question.answerText) {
        return 'Please enter a value';
      }
    },
  ],
};

const ProfileForm = reduxForm({
  form: 'SettingsProfile',
  enableReinitialize: true,
  touchOnChange: true,
})((props) => {
  const {
    initialValues,
    handleSubmit,
    saveName,
    saveEmail,
    saveAvatar,
    t,
    saveDemographicQuestion,
    reset,
    dirty,
    cancel,
  } = props;

  const translateOption = (question, optionId) => {
    const questionId = question.id;
    const questionType = question.questionType;

    const option = t.find(`surveys.purpose-assessment.questions.${questionId}.options.${optionId}`);
    if (questionType && t.exists(`dictionaries.${questionType.replace('-', '')}.${option}.name`))
      return t.find(`dictionaries.${questionType.replace('-', '')}.${option}.name`);
    else return option;
  };

  const translateAnswer = (q) => {
    if (!q) return '';
    if (q.answerText) return q.answerText;
    return translateOption(q, q.answer);
  };

  const displayTenure = (question) => {
    if (!question) return '';
    const foundOption = question.options && question.options.find((o) => o.id == question.answer);
    if (!foundOption || !question.modified) {
      return '';
    }
    const yearsCountInTenure = tenureYears[foundOption.id];
    if (yearsCountInTenure === undefined) {
      return translateAnswer(question);
    }
    const res = moment().diff(moment(question.modified), 'years') + yearsCountInTenure;
    if (res == 0) return translateAnswer(question);
    return `${res} year${res > 1 ? 's' : ''}`;
  };

  return (
    <form className="Settings__form" onSubmit={handleSubmit}>
      <h2 className="section-head">Basic info</h2>
      <div className="card">
        <div className="Settings__section">
          <Field
            label="Full name"
            name="user.name"
            component={MultiText}
            validate={validators.name}
            btnLabel="Save name"
            save={saveName}
            ariaLabel="Open this collapse section to update your full name"
            placeholder={{ firstName: 'First name', lastName: 'Last name' }}
            info={
              <>
                Let people know what you prefer to be called.{' '}
                {initialValues && initialValues.user && initialValues.user.name && (
                  <>
                    Your full name is currently set to{' '}
                    <b>
                      {initialValues.user.name.firstName}{' '}
                      {initialValues && initialValues.user && initialValues.user.name.lastName}
                    </b>
                    .
                  </>
                )}
              </>
            }
            successMsg="Your name was updated successfully"
          />
        </div>
        <div className="Settings__section">
          {initialValues &&
          initialValues.user &&
          initialValues.user.organizationSettings &&
          initialValues.user.organizationSettings.ssoSettings &&
          initialValues.user.organizationSettings.ssoSettings.ssoOnly ? (
            <CollapsibleSection label="Email" ariaLabel="Open this collapse section to update your email address">
              <div className="Settings__organization">
                <p>{initialValues && initialValues.user && initialValues.user.email}</p>
                <span className="caption-new">
                  Your organization uses Single Sign-on (SSO). You cannot change your email from within Imperative.
                </span>
              </div>
            </CollapsibleSection>
          ) : (
            <Field
              label="Email"
              name="user.email"
              component={Text}
              validate={validators.email}
              btnLabel="Save email"
              save={saveEmail}
              placeholder="Email"
              ariaLabel="Open this collapse section to update your email address"
              normalize={(val) => val.trim()}
              info={
                <>
                  Enter the email address you will use to sign in to Imperative. We recommend using your work email.
                  {initialValues && initialValues.user && initialValues.user.email && (
                    <>
                      Your email address is currently set to{' '}
                      <b>{initialValues && initialValues.user && initialValues.user.email}</b>.
                    </>
                  )}
                </>
              }
              successMsg="Your email address was updated successfully"
            />
          )}
        </div>
        <div className="Settings__section">
          <Field
            label="Profile photo"
            user={initialValues && initialValues.user}
            name="avatar"
            component={AvatarField}
            validate={validators.avatar}
            accept={avatarFileFormats}
            save={saveAvatar}
            btnLabel="Save photo"
            ariaLabel="Open this collapse section to update your profile photo"
            info="Add a photo to personalize your account. Your photo will display in your connections’ networks."
            successMsg="Your profile photo was updated successfully"
            assistiveMsg="10MB max file size. Valid file formats: .jpg, .png, .gif, .tiff"
            optional
          />
        </div>
      </div>

      {initialValues && (initialValues.gender || initialValues.race) && (
        <>
          <h2 className="section-head">Personal info</h2>
          <p>
            Inclusion is a key priority at Imperative and we are committed to creating an environment that is
            representative of all people. Providing this information is optional. Please share as much or as little as
            you feel comfortable.
          </p>
          <div className="card">
            {initialValues && initialValues.gender && (
              <div className="Settings__section">
                <Field
                  label="Gender identity"
                  name="gender"
                  component={Radio}
                  btnLabel="Save gender"
                  validate={validators.demographic}
                  save={saveDemographicQuestion}
                  ariaLabel="Open this collapse section to update your gender identity"
                  placeholder="Not provided"
                  info={
                    <>
                      Your gender may be used for personalization, including how Imperative and your coaches refer to
                      you.
                      {initialValues.gender.answer && (
                        <>
                          Your gender identity is currently set to <b>{translateAnswer(initialValues.gender)}</b>.
                        </>
                      )}
                    </>
                  }
                  options={initialValues.gender.options}
                  parseValue={(optionId) => translateOption(initialValues.gender, optionId)}
                  successMsg="Your gender identity was updated successfully"
                />
              </div>
            )}
            {initialValues && initialValues.race && (
              <div className="Settings__section">
                <Field
                  label="Racial or ethnic identity"
                  name="race"
                  component={Radio}
                  btnLabel="Save race or ethnicity"
                  validate={validators.demographic}
                  save={saveDemographicQuestion}
                  placeholder="Not provided"
                  ariaLabel="Open this collapse section to update your racial or ethnic identity"
                  info={
                    <>
                      Your race or ethnicity may be used for personalization and matching you with peer coaches.
                      {initialValues.race.answer && (
                        <>
                          Your racial or ethnic identity is currently set to{' '}
                          <b>{initialValues && translateAnswer(initialValues.race)}</b>.
                        </>
                      )}
                    </>
                  }
                  options={initialValues.race.options}
                  parseValue={(optionId) => translateOption(initialValues.race, optionId)}
                  successMsg="Your racial or ethnic identity was updated successfully"
                />
              </div>
            )}
          </div>
        </>
      )}

      <h2 className="section-head">Work info</h2>
      <div className="card">
        <div className="Settings__section">
          <CollapsibleSection label="Organization" ariaLabel="This is the name of your organization">
            <div className="Settings__organization">
              <img src={initialValues && initialValues.user && initialValues.user.organization.smallLogoUrl} alt="" />
              <p>{initialValues && initialValues.user && initialValues.user.organization.name}</p>
              <span className="caption-new">
                This information is set by your organization’s admin and cannot be changed
              </span>
            </div>
          </CollapsibleSection>
        </div>
        {initialValues && initialValues.jobFunction && (
          <div className="Settings__section">
            <Field
              label="Job function"
              name="jobFunction"
              component={Radio}
              btnLabel="Save job function"
              validate={validators.demographic}
              save={saveDemographicQuestion}
              placeholder="Not provided"
              ariaLabel="Open this collapse section to update your job function"
              info={
                <>
                  Let people know what your do at{' '}
                  {initialValues && initialValues.user && initialValues.user.organization.name}.
                  {initialValues.jobFunction.answer && (
                    <>
                      Your job function is currently set to <b>{translateAnswer(initialValues.jobFunction)}</b>.
                    </>
                  )}
                </>
              }
              options={initialValues.jobFunction.options}
              parseValue={(optionId) => translateOption(initialValues.jobFunction, optionId)}
              successMsg="Your job function was updated successfully"
            />
          </div>
        )}
        {initialValues && initialValues.role && (
          <div className="Settings__section">
            <Field
              label="Role"
              name="role"
              component={Radio}
              btnLabel="Save role"
              validate={validators.demographic}
              save={saveDemographicQuestion}
              ariaLabel="Open this collapse section to update your role"
              placeholder="Not provided"
              info={
                <>
                  Let people know what your role is at{' '}
                  {initialValues && initialValues.user && initialValues.user.organization.name}.
                  {initialValues.role.answer && (
                    <>
                      Your role is currently set to <b>{translateAnswer(initialValues.role)}</b>.
                    </>
                  )}
                </>
              }
              options={initialValues.role.options}
              parseValue={(optionId) => translateOption(initialValues.role, optionId)}
              successMsg="Your role was updated successfully"
            />
          </div>
        )}
        {initialValues && initialValues.tenure && (
          <div className="Settings__section">
            <Field
              label="Tenure"
              name="tenure"
              component={Radio}
              btnLabel="Save tenure"
              save={saveDemographicQuestion}
              validate={validators.demographic}
              placeholder="Not provided"
              ariaLabel="Open this collapse section to update your tenure"
              displayValueFunc={displayTenure}
              info={
                <>
                  As of today, how long have you been working at{' '}
                  {initialValues && initialValues.user && initialValues.user.organization.name}?
                  {initialValues.tenure.answer && (
                    <>
                      Your tenure is currently set to <b>{translateAnswer(initialValues.tenure)}</b>.
                    </>
                  )}
                </>
              }
              options={initialValues.tenure.options}
              parseValue={(optionId) => translateOption(initialValues.tenure, optionId)}
              successMsg="Your tenure was updated successfully"
            />
          </div>
        )}
      </div>
      <div className="Settings__formBtns">
        {dirty ? (
          <button type="button" onClick={reset} className="btn-tertiary-new">
            Reset all
          </button>
        ) : (
          <span />
        )}
        <div>
          <button type="button" onClick={cancel} className="btn-secondary">
            Cancel
          </button>
          <button disabled={!dirty} className="btn-primary">
            Save changes
          </button>
        </div>
      </div>
    </form>
  );
});

const Profile = () => {
  const t = useContext(TranslationsContext);
  const { userStore } = useStore();
  const { user } = userStore;
  const [loading, setLoading] = useState(false);
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [error, setError] = useState();
  const [modalVisible, showModal] = useState(false);
  const [changedFields, setChangedFields] = useState([]);
  const successTimeout = useRef(null);

  const demographicQuestions = useMemo(() => {
    const questions = assessmentQuestions.filter((q) => q.ignoreArv);
    const grouped = questions.reduce((obj, q) => {
      if (!q) {
        return obj;
      }
      return { ...obj, [assessmentQuestionNames[q.id]]: q };
    }, {});
    return grouped;
  }, [assessmentQuestions]);

  const formValues = useMemo(() => {
    return {
      ...demographicQuestions,
      user: user && { ...user, name: { firstName: user.firstName, lastName: user.lastName } },
      avatar: user && user.avatars && user.avatars.normal,
    };
  }, [demographicQuestions, user]);

  const getAssessmentQuestions = useCallback(() => {
    return getAssessmentData().then(
      (d) => setAssessmentQuestions(d.questions),
      (error) => {
        ME.reportIntermediateError('Settings profile: loading assessment data error', error);
        setError(error);
        return error;
      }
    );
  }, []);

  const changeAvatar = (file) => {
    const fd = new FormData();
    fd.append('image', file);

    return userStore.changeAvatar(fd);
  };

  const userUpdate = (data) => {
    return userStore.updateUser(data);
  };

  const saveDemographicQuestion = (question) => {
    const dto = {
      value: question.answer,
      answerText: question.answerText || undefined,
      questionId: question.id,
      modified: moment().valueOf(),
    };
    return sendAnswer(dto).then(getAssessmentQuestions);
  };

  const saveChanges = (values) => {
    const userDto = {
      firstName: values.user.name.firstName.trim(),
      lastName: values.user.name.lastName.trim(),
      email: values.user.email,
    };

    const changes = Object.keys(successMsg).reduce((list, field) => {
      const changedValue = path(field.split('.'), values);
      const initialValue = path(field.split('.'), formValues);
      const msg = successMsg[field];
      if (changedValue != initialValue && list.indexOf(msg) == -1) {
        return [...list, msg];
      }
      return list;
    }, []);

    const avatarReq = values.avatar instanceof File ? changeAvatar(values.avatar) : Promise.resolve();

    const userReq =
      user.firstName == userDto.firstName && user.lastName == userDto.lastName && user.email == userDto.email
        ? Promise.resolve('Not changed')
        : userUpdate(userDto);

    const demographicsReq = new Promise((resolve, reject) => {
      const demographicDto = ['gender', 'role', 'jobFunction', 'race', 'tenure'].reduce((result, key) => {
        const question = values[key];

        if (!question) return result;

        const initialValue = Object.keys(formValues)
          .map((key) => formValues[key])
          .find((v) => v.id == question.id);

        if (initialValue && initialValue.answer == question.answer && initialValue.answerText == question.answerText) {
          return result;
        }

        return [
          ...result,
          {
            value: question.answer,
            answerText: question.answerText || undefined,
            questionId: question.id,
            modified: moment().valueOf(),
          },
        ];
      }, []);

      if (!demographicDto.length) {
        return resolve('Not changed');
      }

      return updateDemographicData(demographicDto)
        .then(() => getAssessmentQuestions().then(resolve, reject), reject)
        .then(() => {
          setChangedFields(changes);
          successTimeout.current = setTimeout(() => setChangedFields([]), 8000);
        });
    });

    return Promise.all([userReq, avatarReq, demographicsReq]);
  };

  useEffect(() => {
    setLoading(true);

    getAssessmentQuestions().then(
      () => {
        setLoading(false);
      },
      () => setLoading(false)
    );

    return () => {
      clearTimeout(successTimeout.curent);
      successTimeout.current = null;
    };
  }, []);

  const successsMsg =
    changedFields.length > 1 ? (
      <>
        <b>{changedFields.slice(0, -1).join(', ')}</b> and <b>{changedFields.slice(-1).join(', ')}</b>
      </>
    ) : (
      <b>{changedFields.join(', ')}</b>
    );

  return (
    <div className="Settings__profile">
      <div className="Settings__title">
        <h1 className="page-title">Edit profile</h1>
      </div>

      {changedFields.length > 0 && (
        <div className="Settings__toast success margin-bottom-5">
          <i className="fas fa-check-circle" aria-hidden />
          <span>
            Your {successsMsg} {changedFields.length > 1 ? 'were' : 'was'} updated successfully
          </span>
        </div>
      )}

      <div className="relative">
        <Loader loading={loading} />
        {formValues && user && (
          <ProfileForm
            onSubmit={saveChanges}
            initialValues={formValues}
            saveName={({ firstName, lastName }) =>
              userUpdate({ firstName: firstName.trim(), lastName: lastName.trim() })
            }
            saveEmail={(email) => userUpdate({ email })}
            saveDemographicQuestion={saveDemographicQuestion}
            saveAvatar={changeAvatar}
            t={t}
            cancel={() => showModal(true)}
          />
        )}
      </div>

      <Modal
        isOpen={modalVisible}
        close={() => showModal(false)}
        className="Settings__modal"
        overlayClassName="Settings__modalOverlay"
      >
        <h4 className="section-head">Discard changes?</h4>
        <p>Closing this window will cancel any unsaved changes.</p>
        <div className="Settings__modalBtns">
          <button className="btn-secondary" onClick={() => showModal(false)}>
            Keep editing
          </button>
          <Link className="btn-primary" to="/">
            Close & discard
          </Link>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
