import React, {useState, useCallback} from 'react';
import { Link } from 'react-router-dom';
import {useDropzone} from 'react-dropzone'
import cx from 'classnames';
import Loader from 'theme/Loader';

import './Avatar.sass';


const initials = ({firstName, lastName, slug} = {}) => {
  if(firstName && lastName)
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  if(slug)
    return slug.slice(0, 2);
  return <i className="material-icons">person</i>
}


const Avatar = ({link, size = 'xs', active, className, bordered, for: subject = {}, children, noInitials, ...props}) => { //['xxs', 'xs', 'xs-sm', 'sm', 'md', 'md-lg', 'lg', 'lg-xl', 'xl']
  const dominant = subject.pattern
    ? (subject.pattern.dominant.toLowerCase() ||
        (subject.assessmentResult && ['who', 'why', 'how'].find((d) => subject.assessmentResult.drivers[d].dominant)))
    : subject.dominant && subject.dominant.toLowerCase();

  const avatar = subject.avatar || (subject.avatars && subject.avatars.normal);

  const Comp = !!link ? Link : "span"

  return (
    <Comp {...props} to={link} className={cx("Avatar", `_sz_${size}`, className, dominant, {active, bordered, hasAvatar: avatar})}>
      {avatar
      ? <span
          className="Avatar__photo"
          style={{backgroundImage: `url(${avatar})`}}
        />
      : (!noInitials &&
          <span className={cx("Avatar__initials", dominant)}>
            {initials(subject)}
          </span>
        )
      }
      {children}
    </Comp>
  )
}

const AvatarUpload = ({upload, ...props}) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(([file]) => {
    if(!file || !upload) return;
    setLoading(true);
    upload(file)
    .then(
      () => setLoading(false),
      () => setLoading(false)
    )
  }, [upload]);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 10000000,
    accept: ["image/jpeg", "image/png", "image/gif", "image/tiff", "application/pdf"]
  });

  if(!upload) return <Avatar {...props} />

  return (
    <div {...getRootProps()} className="AvatarUpload" role="button" aria-label="Change avatar">
      <Loader loading={loading} color="white" size="1" />
      <Avatar {...props}>
        {!loading &&
          <div className="AvatarUpload__camera">
            <span className="material-icons">camera_alt</span>
          </div>
        }
      </Avatar>
      <input {...getInputProps()} title="avatar"/>
    </div>
  )
}

export default Avatar;
export {AvatarUpload};

