module.exports =
  es: 
    quote: "Combinamos la dedicación y creatividad para ayudar a nutrir al mundo"
    author: ""
    overview: "El propósito de Griffith Foods y el contexto que dirige, explica 'por qué existimos'. Abarca todo lo que hacemos, y une a nuestra gente y empresa mientras apoyamos quiénes somos y cómo ganamos como empresa. 
      \n\nMientras Griffith Foods vuelve realidad su propósito, cada empleado tiene su propia conexión y aportación a cómo 'combinamos la dedicación y la creatividad para ayudar a nutrir al mundo'. Por lo tanto, en esta fase de nuestro viaje, queremos que explores tu propósito: la intersección de tus talentos individuales y una necesidad significativa en la sociedad. Este Perfil de Propósito Personal es una forma de que entiendas tu propósito y cómo puedes fortalecer tu desempeño en el trabajo y a lo largo de tu vida. 
      \n\nMientras lees este documento, descubrirás nuevas ideas de cómo tu función en Griffith Foods puede ser una plataforma para vivir tu propósito personal, mientras también apoyas el propósito de la organización: ¿A quién quieres impactar? ¿Por qué te preocupa? ¿Cómo quieres crecer? ¿Cómo cobra vida lo que dirige tu propósito en tu trabajo en Griffith?
      \n\nTenemos la esperanza de que, a través de entender tu propósito, cada uno de nosotros se conecte con más fuerza al propósito de Griffith, y a la empresa como una fuerza del bien"
    drivers:
      society: "¿Cómo influyes en el nivel SOCIAL? ¿De qué formas podrías tener más que un impacto en este nivel?"
      organization: "¿Cómo influyes a nivel ORGANIZACIONAL? ¿De qué formas podrías tener más que un impacto en este nivel?"
      individual: "¿Cómo influyes a nivel INDIVIDUAL? ¿De qué formas podrías tener más que un impacto en este nivel?"
      harmony: "¿Cómo involucras la igualdad y el cuidado con tu función en Griffith Foods? ¿Cómo sería si enfatizaras con más fuerza estos valores en tu trabajo?"
      karma: "¿Cómo involucras las oportunidades y la competencia en tu función en Griffith Foods? ¿Cómo sería si enfatizaras con más fuerza estos valores a tu trabajo?"
      community: "¿En qué comunidades inmpactas con tu función en Griffith Foods? ¿Cómo resuelves problemas y agregas valor al crear o hacer crecer una comunidad?"
      structure: "¿Cuáles son las estructuras en las que impactas con tu función en Griffith Foods? ¿Cómo resuelves problemas y agregas valor con respeto para crear o implementar una estructura?"
      human: "¿Quiénes son las personas en las que influyes en tu función en Griffith Foods? ¿Cómo resuelves problemas y agregas valor a través de tu empatía y comprensión de la gente?"
      knowledge: "¿Cómo puedes usar el conocimiento para impactar con tu función en Griffith Foods? ¿Cómo resuelves problemas y agregas valor para aprovechar el conocimiento?"
  pt: 
    quote: "Combinamos Cuidado e Criatividade para Nutrir o Mundo."
    author: ""
    overview: "O Propósito da Griffith Foods, e a estrutura que o impulsiona, explica o 'motivo de existirmos.' Ele engloba tudo o que fazemos e une nossas pessoas e nossos negócios, ao mesmo tempo em que sustenta quem somos e o modo como temos sucesso como empresa. 
      \n\nEnquanto a Griffith Foods concretiza seu propósito, cada um dos funcionários possui sua própria conexão e contribuição para o modo como 'combinamos cuidado e criatividade para nutrir o mundo'.  Portanto, nesta fase de nossa jornada, queremos que você explore o seu propósito – a interseção entre seus talentos individuais e uma necessidade significativa da sociedade.  Este Perfil de Propósito Pessoal é uma maneira para você compreender seu propósito e como você pode aumentar a sua realização no trabalho e ao longo da vida. 
      \n\nAo ler este relatório, você descobrirá novos insights sobre como sua função na Griffith Foods pode ser uma plataforma para a concretização do seu propósito pessoal, ao mesmo tempo em auxilia no propósito da organização – quem você quer impactar? Por que você se importa? Como você quer crescer? Como seus propósitos são concretizados no seu trabalho na Griffith?
      \n\nEsperamos que, através da compreensão do seu propósito, cada um de nós possa conectar-se mais energicamente ao propósito da Griffith e ao negócio como uma força para o bem."
    drivers:
      society: "Qual é o seu impacto na SOCIEDADE? De que maneira você poderia ter um impacto maior nesse nível?"
      organization: "Qual é o seu impacto na ORGANIZAÇÃO? De que maneira você poderia ter um impacto maior nesse nível?"
      individual: "Qual é o seu impacto nos INDIVÍDUOS? De que maneira você poderia ter um impacto maior nesse nível?"
      harmony:  "Como você utiliza a igualdade e o cuidado em sua função na Griffith Foods? Como seria se você desse mais ênfase a esses valores no seu trabalho?"
      karma: "Como você utiliza a oportunidade e a competitividade em sua função na Griffith Foods? Como seria se você desse mais ênfase a esses valores no seu trabalho?"
      community: "Que comunidades você influencia em sua função na Griffith Foods? Como você soluciona problemas e agrega valor na criação ou no crescimento da comunidade?"
      structure: "Quais estruturas você influencia em sua função na Griffith Foods? Como você soluciona problemas e agrega valor no que diz respeito à criação ou implementação da estrutura?"
      human: "Quem são os indivíduos que você influencia em sua função na Griffith Foods? Como você soluciona problemas e agrega valor através da sua empatia e compreensão das pessoas?"
      knowledge: "Como você usa seu conhecimento para influenciar em sua função na Griffith Foods? Como você soluciona problemas e agrega valor utilizando esse conhecimento?"
  ja: 
    quote: "思いやりと創造性の調和をもって、より豊かな世界を目指します"
    author: ""
    overview: "グリフィスフーズのパーパス（目的、ミッション）とこれに基づくフレームワークが「私たちの存在意義」を解き明かします。それは私たちのなすことすべてを網羅し、私たちのあり方、会社としての勝ち方を支えつつ、私たちとビジネスを結びつけます。 
      \n\nグリフィスフーズは、パーパスを現実のものにします。社員1人1人がいかに「思いやりと創造性の調和をもって、より豊かな世界を目指すか」に関わり、貢献しています。そこで、旅のこの段階では、あなたのパーパス、つまり、あなたが持つ才能と社会の重要なニーズが交わる点を探っていただきたいのです。個人パーパスプロファイルを使えば、自身のパーパスのほか、仕事と人生をどのように充実させていけるかを理解できます。 
      \n\n本レポートを読めば、グリフィスフーズでのあなたの役割が、いかにして個人のパーパスを果たしつつ、組織のパーパスをも支援する基盤になり得るのか、新たな発見があるでしょう。誰にインパクトを与えたいですか？なぜ大切に思うのですか？どのように成長したいですか？グリフィスでの仕事において、パーパスのドライバーがどのように体現されますか。
      \n\n自分のパーパスを理解することで、各自がより強くグリフィスのパーパスとビジネスにプラスの力としてつながっていけるよう願っています。"
    drivers:
      society: "あなたは社会レベルでどのようなインパクトを与えていますか？このレベルで、あなたはどのように影響力を増すことができますか？"
      organization: "あなたは組織レベルでどのようなインパクトを与えていますか？このレベルで、あなたはどのように影響力を増すことができますか？"
      individual: "あなたは個人レベルでどのようなインパクトを与えていますか？このレベルで、あなたはどのように影響力を増すことができますか？"
      harmony: "グリフィスフーズにおけるあなたの役割に、どのように平等さと思いやりを取り入れていますか？あなたの仕事でこれらの価値観をより強調すると、どのようになるでしょうか？"
      karma: "グリフィスフーズにおけるあなたの役割に、どのように機会と競争をもたらしていますか？あなたの仕事でこれらの価値観をより強調すると、どのようになるでしょうか？"
      community: "グリフィスフーズにおけるあなたの役割は、どのコミュニティに影響を与えていますか？コミュニティを作り、育てる上でどのように問題を解決し、価値を付け加えますか？"
      structure: "グリフィスフーズにおけるあなたの役割は、どの仕組みに影響を与えていますか？仕組みを作り、実行する上でどのように問題を解決し、価値を付け加えますか？"
      human: "グリフィスフーズにおけるあなたの役割は、どの個人に影響を与えていますか？共感し、人々を理解しながらどのように問題を解決し、価値を付け加えますか？"
      knowledge: "グリフィスフーズにおけるあなたの役割で影響を与える上で、どのように知識を用いますか？知識を活用しながら、どのように問題を解決し、価値を付け加えますか？"
  nl: 
    quote: "We combineren zorg en creativiteit om de wereld te voeden."
    author: ""
    overview: "Het doel van Griffith Foods en het kader dat hen leidt, verklaart “waarom wij bestaan.” Het omvat alles wat we doen en verbindt onze mensen en het bedrijf met elkaar, terwijl het ondersteunt wie we zijn en hoe we als bedrijf winnen. \n\n
      Terwijl Griffith Foods zijn doel nastreeft, heeft elke medewerker zijn eigen connectie en levert zijn eigen bijdrage aan hoe ‘we zorg en creativiteit combineren om de wereld te voeden.’ Daarom willen we, dat je je doel onderzoekt in deze fase van onze reis - het kruispunt van je individuele talenten en een belangrijke maatschappelijke behoefte. Dit Persoonlijk Profiel van je Doel is voor jou een manier om je doel te begrijpen en hoe je je gevoel van voldoening uit je werk en tijdens je leven kunt versterken. \n\n
      Terwijl je dit rapport leest, zul je nieuwe inzichten ontdekken over hoe je functie bij Griffith Foods een platform kan zijn voor het nastreven van je persoonlijke doel, terwijl je ook het doel van de organisatie ondersteunt - Op wie wil je invloed uitoefenen? Wat gaat jou dat aan? Hoe wil je groeien? Hoe komen de drijfveren van je doel tot leven in je werk bij Griffith? \n\n
      Het is onze hoop dat door het begrijpen van je doel, dit begrip, als een kracht voor het goede, ieder van ons sterker zal verbinden met het doel van Griffith en het bedrijf."
    drivers:
      society: "Wat is je invloed op  MAATSCHAPPELIJK niveau? Op welke manieren kun je op dit niveau meer invloed uitoefenen?"
      organization: "Wat is je invloed op ORGANISATIE niveau? Op welke manieren kun je op dit niveau meer invloed uitoefenen?"
      individual: "Wat is je invloed op het INDIVIDUELE niveau? Op welke manieren kun je op dit niveau meer invloed uitoefenen?"
      harmony: "Hoe creëer je gelijkheid en zorg in je functie bij Griffith Foods? Hoe zou het eruit zien als je in je werk deze waarden sterker zou benadrukken?"
      karma: "Hoe creëer je kansen en concurrentie in je functie bij Griffith Foods? Hoe zou het eruit zien als je in je werk deze waarden sterker zou benadrukken?"
      community: "Welke gemeenschappen beïnvloedt je in je functie bij Griffith Foods? Hoe los je problemen op en voeg je waarde toe aan de creatie of groei van een gemeenschap?"
      structure: "Welke structuren beïnvloedt je in je functie bij Griffith Foods? Hoe los je problemen op en voeg je waarde toe met betrekking tot het creëren of implementeren van structuur?"
      human: "Wie zijn de individuele mensen, die je in je functie bij Griffith Food, beïnvloedt? Hoe los je problemen op en voeg je waarde toe door je medeleven en begrip voor de mensen? "
      knowledge: "Hoe gebruik je in je functie bij Griffith Foods kennis om invloed uit te oefenen? Hoe los je problemen op en voeg je waarde toe bij het toepassen van kennis? "
  en:
    quote: "We Blend Care and Creativity to Nourish the World."
    author: ""
    overview: "Griffith Foods’ Purpose, and the framework that it drives, explains “why we exist.” It encompasses everything that we do, and ties our people and business together while supporting who we are and how we win as a company. \n\n
      As Griffith Foods brings its purpose to life, each employee has their own connection and contribution to how ‘we blend care and creativity to nourish the world.’ Therefore, in this phase of our journey, we want you to explore your purpose – the intersection of your individual talents and a significant need in society. This Personal Purpose Profile is a way for you to understand your purpose and how you can strengthen your fulfillment at work and throughout your life. \n\n
      As you read this report, you will uncover new insights in how your role at Griffith Foods can be a platform for living your personal purpose, while also supporting the organization’s purpose - Who do you want to impact? Why do you care? How do you want to grow? How do your purpose drivers come to life in your work at Griffith? \n\n
      It is our hope that through understanding your purpose, each of us can connect more powerfully to Griffith’s purpose, and business as a force for good."
    drivers:
      society: "How are you impacting at the SOCIETAL level? In what ways could you have more of an impact at this level?"
      organization: "How are you impacting at the ORGANIZATIONAL level? In what ways could you have more of an impact at this level?"
      individual: "How are you impacting at the INDIVIDUAL level? In what ways could you have more of an impact at this level?"
      harmony: "How do you bring equality and care into your role at Griffith Foods? What would it look like if you more strongly emphasized these values in your work?"
      karma: "How do you bring opportunity and competition into your role at Griffith Foods? What would it look like if you more strongly emphasized these values in your work?"
      community: "What communities do you influence in your role at Griffith Foods? How do you solve problems and add value in creating or growing community?"
      structure: "What structures do you influence in your role at Griffith Foods? How do you solve problems and add value with respect to creating or implementing structure?"
      human: "Who are the individual people you influence in your role at Griffith Foods? How do you solve problems and add value through your empathy and understanding of people?"
      knowledge: "How do you use knowledge to influence in your role at Griffith Foods? How do you solve problems and add value in leveraging knowledge?"
  th:
    quote: "เราผสมผสานความใส่ใจและความคิดสร้างสรรค์เพื่อหล่อเลี้ยงโลก"
    author: ""
    overview: "เป้าหมายของ Griffith Foods และกรอบการทำงานซึ่งมันขับเคลื่อนอธิบายว่า “ทำไมเราจึงดำรงอยู่“ มันรวมทุกสิ่งที่เราทำไว้ด้วยกัน และผูกคนกับธุรกิจของเราไว้ด้วยกัน ในขณะที่สนับสนุนตัวตนของเรา และวิธีที่เราจะชนะในฐานะที่เป็นบริษัท 
      \n\nในขณะที่ Griffith Foods ทำเป้าหมายให้เป็นจริง พนักงานแต่ละคนก็มีความเชื่อมโยงกันและมีส่วนร่วมในวิธีที่ 'เราผสมผสานความใส่ใจและความคิดสร้างสรรค์เพื่อหล่อเลี้ยงโลก' ด้วยเหตุนี้ ในการเดินทางช่วงระยะนี้ของเรา เราจึงอยากให้คุณสำรวจเป้าหมายของคุณ ซึ่งเป็นจุดที่พรสวรรค์ส่วนตัวของคุณมาบรรจบกับความต้องการที่สำคัญในสังคม โพรไฟล์เป้าหมายส่วนตัวนี้ คือหนทางสำหรับคุณในการเข้าใจเป้าหมายของคุณ และวิธีที่คุณจะบรรลุเป้าหมายในที่ทำงานและตลอดชีวิตของคุณ 
      \n\nขณะที่คุณอ่านรายงานฉบับนี้ คุณจะค้นพบข้อมูลเชิงลึกใหม่ ๆ ว่าบทบาทของคุณที่ Griffith Foods  สามารถเป็นเวทีสำหรับดำเนินชีวิตตามเป้าหมายส่วนตัวของคุณ ในขณะที่สนับสนุนเป้าหมายขององค์กรไปด้วยในขณะเดียวกัน...คุณต้องการมีผลกระทบต่อใครกันล่ะ? ทำไมคุณจึงแคร์? คุณอยากเติบโตก้าวหน้าอย่างไร? ผู้ขับเคลื่อนเป้าหมายของคุณมีชีวิตขึ้นมาได้อย่างไรในการทำงานของคุณที่ Griffith?
      \n\nเราหวังว่าการเข้าใจเป้าหมายของคุณจะช่วยให้เราแต่ละคนสามารถเชื่อมโยงกับเป้าหมายของ Griffith และกับธุรกิจในฐานะที่เป็นแรงบันดาลใจได้อย่างมีประสิทธิภาพมากขึ้น"
    drivers:
      society: "คุณสร้างผลกระทบในระดับ 'สังคม' อย่างไร? คุณสามารถสร้างผลกระทบในระดับนี้มากกว่าเดิมได้อย่างไรบ้าง?"
      organization: "คุณสร้างผลกระทบในระดับ 'องค์กร' อย่างไร? คุณสามารถสร้างผลกระทบในระดับนี้มากกว่าเดิมได้อย่างไรบ้าง?"
      individual: "คุณสร้างผลกระทบในระดับ 'ปัจเจกบุคคล' อย่างไร? คุณสามารถสร้างผลกระทบในระดับนี้มากกว่าเดิมได้อย่างไรบ้าง?"
      harmony: "คุณนำความเท่าเทียมและความใส่ใจมาสู่บทบาทของคุณที่  Griffith Foods อย่างไร? มันจะเป็นอย่างไรหากคุณเน้นค่านิยมเหล่านี้ในการทำงานอย่างชัดเจน?"
      karma: "คุณนำโอกาสและการแข่งขันมาสู่บทบาทของคุณที่  Griffith Foods อย่างไร? มันจะเป็นอย่างไรหากคุณเน้นค่านิยมเหล่านี้ในการทำงานอย่างชัดเจน?"
      community: "ชุมชนใดที่คุณมีอิทธิพลในบทบาทของคุณที่  Griffith Foods? คุณแก้ไขปัญหาและเพิ่มคุณค่าในการสร้างชุมชนหรือทำให้มันเติบโตอย่างไร?"
      structure: "โครงสร้างใดที่คุณมีอิทธิพลในบทบาทของคุณที่  Griffith Foods? คุณแก้ไขปัญหาและเพิ่มคุณค่าด้วยการเคารพในการสร้างโครงสร้างหรือทำให้มันสำเร็จอย่างไร?"
      human: "ใครคือปัจเจกบุคคลที่คุณมีอิทธิพลในบทบาทของคุณที่  Griffith Foods? คุณแก้ไขปัญหาและเพิ่มคุณค่าผ่านความเห็นอกเห็นใจและเข้าอกเข้าใจผู้คนอย่างไร?"
      knowledge: "คุณใช้ความรู้เพื่อมีอิทธิพลในบทบาทของคุณที่  Griffith Foods อย่างไร? คุณแก้ไขปัญหาและเพิ่มคุณค่านการยกระดับความรู้อย่างไร?"
  cn:
    quote: "我们融合关怀和创造力来滋养世界。"
    author: ""
    overview: "Griffith Foods’的目标，以及它驱动的体系，解释了“为什么我们存在”。 它涵盖了我们所做的一切，并将我们的员工和业务捆绑在一起，同时证明了我们是谁以及我们如何作为一个公司而取得胜利。
      \n\n正如Griffith Foods将目标变为现实一样，每位员工对于“我们融合关怀和创造力来滋养世界”都有自己的联系和贡献。 因此，在这段旅程中，我们想要你探索自己的目标——你的个人才能和重要社会意义的交集。 此个人目标简介是一种途径，它可以帮你了解你的目标以及如何加强您在工作和生活中的成就感。
      \n\n你在阅读这篇报告的时，会发现新的见解，了解Griffith Foods的这个角色如何能成为你实现个人目标的平台，同时也支撑组织的目标-您想影响谁？ 你为什么在乎？ 你想如何成长？ 你在Griffith工作期间如何将自己的目标变为现实？
      \n\n我们希望，通过了解您的目标，我们每个人都可以与Griffith的目标更紧密地联系在一起，并将业务作为一种有益的力量。"
    drivers:
      society: "你如何在社会层面产生影响? 你可以通过什么方式在这个层面上产生更大的影响？"
      organization: "你如何在组织层面产生影响？ 你可以通过什么方式在这个层面上产生更大的影响？"
      individual: "你如何在个人层面产生影响？ 你可以通过什么方式在这个层面上产生更大的影响？"
      harmony: "你如何把平等和关怀带到Griffith Foods员工的这个角色中？ 如果你在工作中更加强调这些价值观，将会是什么样？"
      karma: "你如何把机会和竞争带到Griffith Foods员工的这个角色中？ 如果你在工作中更加强调这些价值观，将会是什么样？"
      community: "你作为 Griffith Foods员工的角色会影响哪些团体？ 你如何在正在创建和发展的团体中解决问题和增加价值？"
      structure: "作为 Griffith Foods员工的角色会影响哪些组织？ 你如何在创造和实施结构方面解决问题和增加价值？"
      human: "作为 Griffith Foods员工的角色，谁是你会影响的个人？ 你如何通过对人的同情和理解来解决问题和增加价值？"
      knowledge: "如何利用知识来影响你在Griffith Foods的职位？ 你如何利用知识解决问题和增加价值？"

  languages: [
    "Chinese"
    "Dutch"
    "English"
    "Japanese"
    "Portuguese"
    "Spanish"
    "Thai"
  ]
