import React from 'react'
import * as d3 from 'd3'


LABEL_X_OFFSET = '-24pt'

export default Donut = ({data, colors, width, height, radius, innerRadius, colorAccessor, showOuterLabels}) ->
  arc = d3.arc()
  .outerRadius radius
  .innerRadius innerRadius

  pie = d3.pie()
  .sort null
  .value (d) -> d.value

  React.createElement("div", null,
    React.createElement("svg", {"width": (width), "height": (height)},
      React.createElement("g", {"transform": "translate(#{width / 2}, #{height / 2})"},
        (pie(data).map (d) ->
          fill = colors colorAccessor d.data
          dist = radius + 42
          angle = (d.startAngle + d.endAngle) / 2
          x = dist * 1.1 * Math.sin angle
          y = -dist * Math.cos angle

          if d.data.label instanceof Array
            [label, subLabel, topSubLabel] = d.data.label

          React.createElement("g", {"key": (d.index)},
            React.createElement("path", {"d": (arc d), "fill": (fill)}),
            (if showOuterLabels
              React.createElement("g", {"transform": "translate(#{x}, #{y})", "className": "TeamReport__donutLabelContainer"},
                React.createElement("text", {"fill": (fill), "dx": (LABEL_X_OFFSET), "className": "TeamReport__donutLabel"},
                  (label)
                ),
                (if subLabel
                  React.createElement("text", {"fill": (fill), "dx": (LABEL_X_OFFSET), "dy": "1.2em", "className": "TeamReport__donutSublabel"},
                    (subLabel)
                  )
                ),
                (if topSubLabel
                  React.createElement("text", {"fill": (fill), "dx": (LABEL_X_OFFSET), "dy": "-1.5em", "className": "TeamReport__donutSublabel"},
                    (topSubLabel)
                  )
                )
              )
            )
          )
        )
      )
    )
  )
