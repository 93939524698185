import React from 'react';

const Summary = ({t, setFocusRef}) => (
  <div key='summary'>
    <div className="Assessment__nav">
      <p className='p margin-bottom-4' tabIndex="-1" ref={setFocusRef}>
          {t.find('assessment.page.summary.title')}
          <br/>
          {t.find('assessment.page.summary.info')}
      </p>
    </div>
  </div>
);

export default Summary;
