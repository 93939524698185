import React from 'react';

const image1 = 'https://assets.imperative.com/static.imperative.com/images/getting_started/page-1.png';
const image2 = 'https://assets.imperative.com/static.imperative.com/images/getting_started/page-2.png';
const image3 = 'https://assets.imperative.com/static.imperative.com/images/getting_started/page-3.png';
const image4 = 'https://assets.imperative.com/static.imperative.com/images/getting_started/page-4.png';
const image5 = 'https://assets.imperative.com/static.imperative.com/images/getting_started/page-5.png';
const image6 = 'https://assets.imperative.com/static.imperative.com/images/getting_started/page-6.png';

const steps = [
  {
    title: "Let's get started",
    content: (user) => (
      <>
        <p>
          While we are looking for your first match, here is what you can look forward to on Imperative. You'll be
          introduced to your first connection at {user && user.organization && user.organization.name} for a series of
          guided conversations.
        </p>
        <ul>
          <li>Dedicated time for you to own your fulfillment</li>
          <li>Space to reflect and hear a diverse perspective</li>
          <li>Conversations that build meaningful relationships</li>
        </ul>
      </>
    ),
    image: image1,
  },
  {
    title: 'Set the tone and create space',
    content: () => (
      <>
        <p>
          The first few minutes will guide you and your partner to check in with each other. You`ll be guided through a
          few check-in questions and prompts. The goal is to help create a transition from your busy day-to-day into a
          meaningful conversation.
        </p>
        <ul>
          <li>Supportive, non-judgmental space for reflection</li>
        </ul>
      </>
    ),
    image: image2,
  },
  {
    title: 'Follow the conversation guide',
    content: () => (
      <>
        <p>
          Ever worry about what to say or how to break the ice? We'll guide you so you can get the most out of your time
          together. Simply take turns asking and answering several questions.
        </p>
        <ul>
          <li>No preparation or special training required</li>
          <li>Tips and follow-up questions help you dive deeper</li>
        </ul>
      </>
    ),
    image: image3,
  },
  {
    title: 'Stay committed',
    content: () => (
      <>
        <p>
          Next, you'll apply what you've learned by making a commitment to take one action before your next
          conversation.
        </p>
        <ul>
          <li>Your connection will be your "accountability buddy"</li>
          <li>Friendly email reminders help you stay on track</li>
        </ul>
      </>
    ),
    image: image4,
  },
  {
    title: 'Wrap up the conversation',
    content: () => (
      <>
        <p>
          Lastly, you'll reflect on how helpful the conversation was for you and schedule the next conversation. We
          recommend that you meet every 2 weeks.
        </p>
        <ul>
          <li>Don't worry, it's easy to reschedule if needed</li>
          <li>Review your conversation notes any time</li>
        </ul>
      </>
    ),
    image: image5,
  },
  {
    title: 'Maintain momentum',
    content: () => (
      <>
        <p>
          You will be introduced to more connections and conversations on Imperative throughout your experience. Each
          match provides fresh insight and perspective with new conversations to explore.
        </p>
        <ul>
          <li>Complete all conversations before they expire!</li>
          <li>You'll be notified before a new introduction occurs</li>
        </ul>
      </>
    ),
    image: image6,
  },
  {
    title: 'Review your purpose profile',
    content: (user) => (
      <>
        <p>
          You have superpowers and we are thrilled to welcome you to the Imperative community at{' '}
          {user && user.organization && user.organization.name}.
        </p>
        <p>While you wait for your first match, take the time to learn more about your purpose profile.</p>
      </>
    ),
    image: image1,
  },
];

export default steps;
