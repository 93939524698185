import T from 'tcomb'
import qs from 'querystring'

###
sso: popup flow:
generate the proper auth page url

open a popup window with the auth page
wait (poll) while the window address changes to the callback
store the query string and close the popup

process query string and extract sso credentials

sso provider methods:
generate auth page url
process qs
###


class SSOError extends Error
  constructor: (args...) ->
    super()
    @arguments = args

export class Cancelled extends SSOError

class BadProvider extends SSOError

class Failed extends SSOError
  constructor: (provider) ->
    super()
    @provider = provider

providerConstructors =
  samlPwc: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlPwc', arguments...

      token: token

  samlHasbro: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlHasbro', arguments...

      token: token

  samlIcf: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlIcf', arguments...

      token: token

  samlIntuitive: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlIntuitive', arguments...

      token: token

  samlZillow: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlZillow', arguments...

      token: token

  samlAirbnb: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlAirbnb', arguments...

      token: token

  samlSeattleCityLight: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlSeattleCityLight', arguments...

      token: token

  samlOswaldCompanies: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlOswaldCompanies', arguments...

      token: token

  samlBostonScientific: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlBostonScientific', arguments...

      token: token

  samlSiteImprove: (id, callback, url) ->
    url: url

    process: ({token}) ->
      unless token
        throw new Failed 'samlSiteImprove', arguments...

      token: token

requestPopup = (url, callback) ->
  w = window.open url
  new Promise (resolve, reject) ->
    interval = setInterval ->
      try
        if w.closed
          clearInterval interval
          reject new Cancelled
        else if w.location.href.startsWith callback
          clearInterval interval
          _qs = w.location.search.substr(1) or w.location.hash.substr(1)
          resolve qs.parse _qs
          w.close()
      catch e
    , 100


authenticate = (provider, callback) ->
  requestPopup(provider.url, callback).then provider.process, null


mkSSO = (callback, providers) ->
  authenticate: (provider) ->
    throw new BadProvider provider unless p = providers[provider]

    authenticate p, callback


initProviders = (constructors, callback, ids, urls) ->
  providers = {}
  for name, id of ids
    pc = constructors[name]
    T.assert pc?, "no such provider constructor: '#{name}'"
    providers[name] = pc id, callback, urls?[name]

  providers


mkSSODefault = (callback, ids, urls, constructors = providerConstructors) ->
  callback = window.location.origin + callback
  mkSSO callback, initProviders constructors, callback, ids, urls


export default Object.assign mkSSODefault, {
  Cancelled
  BadProvider
  Failed
  providerConstructors
  mkSSO
  initProviders
}
