import React from 'react';
import cx from 'classnames';
import './Settings.sass';

const CollapsibleSection = ({
  label,
  children,
  disabled = false,
  opened = false,
  open,
  close,
  className,
  ariaLabel,
  onKeyDown,
  optional,
}) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      opened ? close && close() : open && open();
    }
  };

  return (
    <fieldset
      className={cx('Settings__collapsible', { opened }, className)}
      tabIndex="0"
      aria-label={ariaLabel}
      onKeyPress={handleKeyPress}
      onKeyDown={onKeyDown}
    >
      <legend className="caption-caps">{label}</legend>
      {optional && <span className="Settings__optional">optional</span>}
      {!disabled && open && (
        <button
          type="button"
          className="btn-icon Settings__collapsibleToggler"
          aria-expanded={opened}
          onClick={(e) => {
            e.stopPropagation();
            opened ? close && close() : open && open();
          }}
        >
          <i
            title="open section"
            aria-label={opened ? 'Collapse section' : 'Expand section'}
            className={cx({ 'far fa-chevron-down': !opened, 'far fa-chevron-up': opened })}
          />
        </button>
      )}
      <div className="Settings__collapsibleContent" onClick={() => !opened && open && open()}>
        {children}
      </div>
    </fieldset>
  );
};

export default CollapsibleSection;
