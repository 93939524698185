import React, {useCallback, useMemo, useState} from 'react';
import cx from 'classnames';
import {pickBy, without} from 'ramda';
import Checkbox from 'theme/Checkbox';

import './Community.sass';


const Filters = (props) => {
  const {filters = [], filter = () => Promise.resolve()} = props;
  const [query, setQuery] = useState('');
  const [filtersVisible, showFilters] = useState(false);
  const [openedFilters, setOpenedFilters] = useState([]);
  const [filteredBy, setFilteredBy] = useState(filters.reduce((acc, f) => ({...acc, [f.key]: []}), {}));

  const applyFilters = useCallback((query, filteredBy) => {
    filter({
      ...filteredBy,
      query
    });
  }, []);

  const filterBy = useCallback((key, option) => {
    let res = {};
    if(filteredBy[key] && filteredBy[key].indexOf(option) > -1) {
      res = {...filteredBy, [key]: without([option], filteredBy[key])};
    }
    else {
      res = {...filteredBy, [key]: (filteredBy[key] || []).concat(option)};
    }
    setFilteredBy(res);
    applyFilters(query, res);
  }, [query, filteredBy]);

  const onChangeQuery = useCallback((query) => {
    setQuery(query);
    applyFilters(query, filteredBy);
  }, [filteredBy]);

  const toggleFilter = useCallback((filterName) => {
    setOpenedFilters(openedFilters => {
      if(openedFilters.indexOf(filterName) > -1) {
        return without([filterName], openedFilters);
      }
      return openedFilters.concat(filterName);
    })
  }, [isFilterOpened]);

  const isFilterOpened = useCallback((filterName) => {
    return openedFilters.indexOf(filterName) > -1;
  }, [openedFilters]);

  const anyFiltersApplied = useMemo(() => {
    return Object.keys(pickBy(
      (val) => val.length > 0,
      filteredBy
    )).length > 0;
  }, [filteredBy]);

  return (
    <div className="Community__membersFilters">
      {filtersVisible &&
        <>
          <div className='Community__membersFiltersGroup Community__membersFiltersName Community__membersFiltersTitle'>
            <i className='far fa-filter' aria-hidden />
            <span>Filters</span>
            <button
              className='btn-icon'
              onClick={() => showFilters(false)}
              aria-label="Close filters"
            ><i className='far fa-times' aria-hidden /></button>
          </div>
          {filters.map((f) =>
            <div className='Community__membersFiltersGroup' key={f.key}>
              <div className='Community__membersFiltersName' onClick={() => toggleFilter(f.key)}>
                <span>
                  {f.name}
                  {filteredBy[f.key] && filteredBy[f.key].length > 0 &&
                    <span className='Community__membersFiltersCount'>{filteredBy[f.key].length}</span>
                  }
                </span>
                <button
                  className='btn-icon'
                  aria-label={(isFilterOpened(f.key) ? 'Close' : 'Open') + ` ${f.name} filter options`}
                >
                  {isFilterOpened(f.key)
                    ? <i className='far fa-minus' aria-hidden />
                    : <i className='far fa-plus' aria-hidden />
                  }
                </button>
              </div>
              {isFilterOpened(f.key) &&
                <div className='Community__membersFiltersOptions'>
                  {f.options.sort((a, b) => a.label.localeCompare(b.label)).map(o =>
                    <div key={o.value} className='Community__membersFiltersOption'>
                      <Checkbox
                        onChange={() => filterBy(f.key, o.value)}
                        checked={!!filteredBy[f.key] && filteredBy[f.key].indexOf(o.value) > -1}
                        id={f.key + '_' + o.value}
                      />
                      <label htmlFor={f.key + '_' + o.value}>
                        {o.label}
                      </label>
                    </div>
                  )}
                </div>
              }
            </div>
          )}
        </>
      }

      <div className='Community__membersInfo'>
        <div className='Community__membersSearch'>
          <i className='material-icons-outlined' aria-hidden>search</i>
          <input
            type="text"
            placeholder="Search by member name or email..."
            value={query}
            onChange={e => onChangeQuery(e.target.value)}
          />
          {!!query &&
            <button
              className='btn-icon'
              arial-label="Clear search input"
              onClick={() => onChangeQuery('')}
              ><i className='fas fa-times-circle' aria-hidden /></button>
          }
        </div>
        <div className='Community__membersFilter'>
          <button
            className={cx('btn-icon', {active: filtersVisible || anyFiltersApplied})}
            onClick={() => showFilters(!filtersVisible)}
            aria-label={filtersVisible ? 'Hide filters' : 'Show filters'}
          >
            <i className='far fa-filter' aria-hidden />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Filters;
