import React from 'react'
import Page from 'reports/PrintPage'
import {URL_DRIVERS} from 'theme/assets/assets'

import './DriversAtGlance.sass'

export default ({dimension, driver, footer, t}) ->
  v = "#{URL_DRIVERS}/#{dimension}#{driver}.png"
  base = "pattern.drivers.#{dimension}.#{driver}"
  moments = t.getSplitted "#{base}.moments"
  powerUps = t.getSplitted "#{base}.powerUpBullets"
  biases = t.getSplitted "#{base}.potentialBiases"
  keywords = t.getSplitted "#{base}.words"

  React.createElement(Page, {"footer": (footer), "marginSize": "xs", "key": (driver)},
    React.createElement("div", {"className": "DriversAtGlance__section"},
      React.createElement("img", {"className": "DriversAtGlance__icon", "src": (v), "alt": (driver)}),
      React.createElement("div", {"className": "DriversAtGlance__driver #{dimension}"},
        React.createElement("p", {"className": "DriversAtGlance__driverTitle"}, (driver)),
        React.createElement("p", {"className": "DriversAtGlance__driverCore"}, (t.find "#{base}.coreBeliefs"))
      )
    ),
    React.createElement("div", {"className": "DriversAtGlance__description"},
      React.createElement("p", {"className": "DriversAtGlance__descriptionQuote"}, "“", ( t.find "#{base}.quote.value"), "” – ", (t.find "#{base}.quote.author")),
      React.createElement("p", {"className": "DriversAtGlance__descriptionExplication"}, ( t.find "#{base}.explication"))
    ),
    React.createElement("div", {"className": "DriversAtGlance__columns"},
      React.createElement("div", {"className": "DriversAtGlance__columnsColumn"},
        React.createElement("p", null, "Purpose Moments"),
        (moments.map (moment, j) ->
          React.createElement("span", {"className": "DriversAtGlance__listItem DriversAtGlance__listItem_bullet", "key": (j)},
            React.createElement("span", {"className": "DriversAtGlance__listItemContent"},
              (moment)
            )
          )
        )
      ),
      React.createElement("div", {"className": "DriversAtGlance__columnsColumn"},
        React.createElement("p", null, "Purpose Power-Ups"),
        (powerUps.map (powerUp, j) ->
          React.createElement("span", {"className": "DriversAtGlance__listItem DriversAtGlance__listItem_bullet", "key": (j)},
            React.createElement("span", {"className": "DriversAtGlance__listItemContent"},
              (powerUp)
            )
          )
        )
      ),
      React.createElement("div", {"className": "DriversAtGlance__columnsColumn"},
        React.createElement("p", null, "Potential Biases"),
        (biases.map (biase, j) ->
          React.createElement("span", {"className": "DriversAtGlance__listItem DriversAtGlance__listItem_bullet", "key": (j)},
            React.createElement("span", {"className": "DriversAtGlance__listItemContent"},
              (biase)
            )
          )
        )
      ),
      React.createElement("p", {"className": "DriversAtGlance__pageKeywords #{dimension}"},
        (keywords.map (keyword, j) ->
          React.createElement("span", {"className": "DriversAtGlance__pageKeyword", "key": (j)},
            (keyword)
          )
        )
      )
    )
  )