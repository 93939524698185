import r from 'r-dom'
import T from 'tcomb'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import {lifecycle, withStateHandlers} from 'recompose'
import Modal from 'react-modal'
import moment from 'moment'
import cx from 'classnames'
# import {Tracker} from 'tracking'

import './VideoModal.sass'


export default composeComponent 'VideoModal',
  setPropTypes
    close: T.maybe T.Function
    id: T.String

  withStateHandlers ->
    landscape: screen?.orientation?.angle is 90 || screen?.orientation?.angle is 270
  ,
    changePosition: -> -> landscape: screen?.orientation?.angle is 90 || screen?.orientation?.angle is 270

  lifecycle
    componentDidMount: ->
      {id, changePosition} = @props

      window.addEventListener 'orientationchange', ->
        changePosition()

      # t = new Tracker window
      player = new Vimeo.Player(document.getElementById("vimeoVideo"))
      player.getDuration().then (duration) ->
        part = duration / 10

        for times in [1..9]
          player.addCuePoint(part * times, {percent: "#{times * 10}%"})

      # player.on 'play', ->
      #   t.event category: 'Video', action: 'Start', label: id

      # player.on 'cuepoint', ({data}) ->
      #   t.event category: 'Video', action: "#{data.percent}", label: id

      # player.on 'ended', ->
      #   t.event category: 'Video', action: 'Ended', label: id

  ({
    close
    id
    landscape
    children
  }) ->
    mobile = window.innerWidth < 813

    r.div className: cx("VideoModal__overlay", if mobile then "VideoModal__overlayMobile"),
      r.div className: cx("VideoModal__content", if mobile and landscape then "VideoModal__contentLandscape"),
        [
          r.div className:cx("VideoModal__videoContainer", if mobile and landscape then "VideoModal__videoContainerLandscape"),
            r.iframe
              id: 'vimeoVideo'
              className: "VideoModal__video"
              src: "https://player.vimeo.com/video/#{id}?transparent=0"
              allowFullScreen: true
              frameBorder: 0
          r.button
            className: "btn VideoModal__exit"
            onClick: close
            r.span
              className: "fal fa-times"
          children
        ]
