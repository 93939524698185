import React from 'react'
import {composeComponent} from 'utils/react-tools'
import cx from 'classnames'
import {defaultProps} from 'recompose'
import RModal from 'react-modal'

import './Modal.sass'

RModal.setAppElement 'body'

export default composeComponent 'Modal',
  defaultProps
    withClose: true

  ({className, isOpen, close, children, withClose, onClose, overlayClassName, ariaMessage = ''}) ->
    React.createElement(RModal, { \
      "className": (cx "Modal Modal__content", className),  \
      "overlayClassName": (cx("Modal__overlay", overlayClassName)),  \
      "bodyOpenClassName": "Modal__body_opened",  \
      "ariaHideApp": (false),  \
      "contentLabel": (ariaMessage || "Modal"),  \
      "isOpen": (isOpen),  \
      "onRequestClose": (close ? onClose),  \
      "closeTimeoutMS": (500),  \
      "aria": ({
        modal: isOpen
      })
    },
      (if withClose and close?
        React.createElement("button", { \
          "className": "Modal__exit btn",  \
          "onClick": (close),  \
          "aria-label": "close"
        },
          React.createElement("i", {"className": "fal fa-times"})
        )
      ),
      (children)
    )

export Header = ({children, close, className}) ->
  React.createElement("div", {"className": (cx "Modal__header", className)},
    (children),
    (if close?
      React.createElement("button", { \
        "className": "Modal__exit btn",  \
        "onClick": (close),  \
        "aria-label": "close"
      },
        React.createElement("i", {"className": "fal fa-times"})
      )
    )
  )

export Content = ({children, className}) ->
  React.createElement("div", {"className": (cx "Modal__body", className)},
    (children)
  )
