import React, {useState, useEffect, useMemo, useContext, useCallback} from 'react'
import cx from 'classnames';
import moment from 'moment';
import AutosizeInput from 'theme/Form/AutosizeInput';
import Loader from 'theme/Loader';
import Avatar from 'theme/Avatar';

const PAGE_SIZE = 3;


const Comments = (props) => {
  const {user, comments = [], sendComment, changeComment, removeComment, activityId} = props;
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(null);
  const [visibleCommentsCount, setVisibleCommentsCount] = useState(PAGE_SIZE);

  const showMore = useCallback(() => {
    setVisibleCommentsCount(c => c + PAGE_SIZE);
  }, []);

  const send = useCallback((comment) => {
    setLoading('new');
    setError(null);
    sendComment(activityId, comment)
    .then(
      () => {
        setComment('');
        setLoading(false);
      },
      (e) => {
        setError(e);
        setLoading(false);
      }
    );
  }, [activityId]);

  const update = useCallback((commentId, comment) => {
    setLoading(commentId);
    setError(null);
    changeComment(activityId, commentId, comment)
    .then(
      () => {
        setLoading(false)
        setEditing(null);
      },
      (e) => {
        setError(e);
        setLoading(false);
      }
    );
  }, [activityId]);

  const remove = useCallback((commentId) => {
    setLoading(commentId);
    setError(null);
    removeComment(activityId, commentId)
    .then(
      () => setLoading(false),
      (e) => {
        setError(e);
        setLoading(false);
      }
    );
  }, [activityId]);

  const onChangeEditField = (e) => {
    e.persist();
    setEditing(val => ({...val, comment: e.target.value}));
    setError(null);
  }

  return (
    <div className='Community__comments'>
      <form className='Community__commentsInput'>
        <Avatar for={user} size="36" />
        <div>
          <div className='relative'>
            <AutosizeInput
              value={comment}
              onChange={(e) => {e.persist(); setComment(e.target.value); setError(null)}}
              placeholder="Write comment..."
              disabled={loading}
              maxLength="3000"
            />
            <div className='Community__commentsInputBtns'>
              <button
                className='btn-tertiary-new'
                onClick={() => send(comment)}
                disabled={!comment || loading}
              >
                <Loader loading={loading == 'new'} size="xs" />
                {loading != 'new' && 'Send'}
              </button>
            </div>
          </div>
          {comment.length > 0 &&
            <span className='Community__commentsInputCount'>
              {comment.length}/3000
            </span>
          }
        </div>
        {error &&
          <div className='Community__commentsError'>
            <i className="fas fa-exclamation-triangle" aria-hidden />
            Cannot send message. Try again later.
          </div>
        }
      </form>
      {comments.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))).slice(0, visibleCommentsCount).map((c, i) =>
        <div className={cx('Community__comment', {blurred: editing && editing.commentId != c.commentId})} key={i}>
          <Avatar size="36" for={{...c, avatars: {normal: c.avatars}}} />
          <div className={cx('Community__commentContent', {loading: loading == c.commentId})}>
            <Loader loading={loading == c.commentId} size="xs" />
            <div className='Community__commentName'>
              {c.firstName} {c.lastName}
              <div className='Community__commentDate'>
                {moment(c.createdAt).format('D MMM - h:mm A')}
                {c.memberId == user.id && (loading != c.commentId) && (!editing || (editing.commentId != c.commentId)) &&
                  <div className='Community__commentActions'>
                    <button
                      className='btn-tertiary-new edit'
                      onClick={() => setEditing(c)}
                    >Edit</button>
                    <button
                      className='btn-tertiary-new delete'
                      onClick={() => remove(c.commentId)}
                    >Delete</button>
                  </div>
                }
              </div>
            </div>
            {editing && editing.commentId == c.commentId &&
              <form className='Community__commentInput Community__commentsInput'>
                <div>
                  <div className='relative'>
                    <AutosizeInput
                      value={editing.comment}
                      onChange={onChangeEditField}
                      placeholder="Edit comment..."
                      disabled={loading}
                      maxLength="3000"
                    />
                    <div className='Community__commentsInputBtns'>
                      <button
                        type="button"
                        className='btn-tertiary-new'
                        onClick={() => setEditing(null)}
                      >
                        Cancel
                      </button>
                      <button
                        className='btn-tertiary-new save'
                        onClick={() => update(editing.commentId, editing.comment)}
                        disabled={!c.comment || loading}
                      >
                        {loading && <span className='far fa-circle-notch fa-spin' />}
                        {!loading && 'Save'}
                      </button>
                    </div>
                  </div>
                  {comment.length > 0 &&
                    <span className='Community__commentsInputCount'>
                      {comment.length}/3000
                    </span>
                  }
                </div>
                {error &&
                  <div className='Community__commentsError'>
                    <i className="fas fa-exclamation-triangle" aria-hidden />
                    Cannot send message. Try again later.
                  </div>
                }
              </form>
            }
            {(!editing || (editing.commentId != c.commentId)) && c.comment}
          </div>
        </div>
      )}
      {comments.length > visibleCommentsCount &&
        <button
          className='btn-small transparent'
          onClick={showMore}
        >Show more</button>
      }
    </div>
  )
}

export default Comments;
