`/** @jsx R.DOM */`
import R from 'react'
import PT from 'prop-types'
import {URL_DRIVERS} from 'theme/assets/assets'
import Page from '../PrintPage'
import Heading from './Heading'
import P from './P'
import Ul from './Ul'
import Li from './Li'


whoDrivers = ['Individual', 'Organization', 'Society']
whyDrivers = ['Karma', 'Harmony']
howDrivers = ['Knowledge', 'Human', 'Structure', 'Community']
whoType = PT.oneOf whoDrivers
whyType = PT.oneOf whyDrivers
howType = PT.oneOf howDrivers
anyDriver = PT.oneOf [].concat whoDrivers, whyDrivers, howDrivers

dimensions = ['who', 'why', 'how']
dimensionType = PT.oneOf dimensions

driversShape = PT.shape
  who: whoType.isRequired
  why: whyType.isRequired
  how: howType.isRequired


ME_AND_TEAM_DRIVER_DATA =
  who:
    Individual:
      majority:
        description:
          '''
          You are aligned with your team culture, to make an impact on individuals.
          You will find that the goals, impact, and celebrations within the team are
          inherently meaningful. You will be able to make decisions and act quickly,
          prioritizing your work with confidence.
          '''
        tipStart: 'Here are some tips for optimizing this shared focus on impacting Individuals:'
        tips: [
          'Voice excitement about the impact of your work and the work of your
          colleagues on Individuals to bring energy to the team.'
          'Share insights about what really matters to the individuals you serve and
          model different approaches to serving them in order to push the group
          to become increasingly effective.'
          'Actively encourage team members who don’t share the Individual driver
          to share their views. This can be a huge value-add for innovation and
          growth goals, as well as creative problem-solving.'
          'Deepen your collaborative relationships with those who share this
          driver with you and build empathy for those who do not share this driver,
          learning how to see the world through their eyes.'
        ]
      minority:
        description: (team) ->
          """
          Because you are driven to impact Individuals, and the team is primarily
          driven to impact #{team}, you provide a unique perspective to the team,
          but you may need to adapt in some areas for the team to become high performing.
          """
        tipStart: 'Here are some tips to help you work with purpose and contribute to the team purpose:'
        tips: (team) -> [
          'Ensure you stay motivated by making room for the Individual in your
          work, and cognitively connecting the impact on Individuals to your
          goals and celebrations.'
          'Your unique orientation will make you an asset in creative problem
          solving, innovation, or times of change. Act as counsel for those on
          the team looking for differing perspectives from the majority.'
          "Deepen your relationships by building empathy, learn how to orient
          your communication toward the impact on #{team}, and provide feedback
          and recognition based on their orientation."
          'Focus your personal growth on building self-awareness, listening, and
          influencing skills to bridge the gap between your drivers and the team
          drivers.'
        ]
    Organization:
      majority:
        description:
          '''
          You are aligned with your team culture, to make an impact on Organizations
          (and teams as mini-organizations). You will find that the goals, impact,
          and celebrations within the team are inherently meaningful. You will be able to
          make decisions and act quickly, prioritizing your work with confidence.
          '''
        tipStart: 'Here are some tips for optimizing this shared focus on impacting Organizations:'
        tips: [
          'Celebrate the impact of your team’s work on Organizations and teams.'
          'Share insights about what really matters to Organizations and teams
          you serve and model different approaches to serving them in order to
          push the group to become increasingly effective.'
          'Actively encourage team members who don’t share the Organization driver
          to share their views. This can be a huge value-add for innovation and
          growth goals, as well as creative problem-solving.'
          'Deepen your collaborative relationships with those who share this driver
          with you and build empathy for those who do not share this driver,
          learning how to see the world through their eyes.'
        ]
      minority:
        description: (team) ->
          """
          Because you are driven to impact Organizations (and teams as mini-organizations),
          and the team is primarily driven to impact #{team}, you provide a unique perspective
          to the team, but you may need to adapt in some areas for the team to become high performing.
          """
        tipStart: 'Here are some tips to help you work with purpose and contribute to the team purpose:'
        tips: (team) -> [
          'Ensure you stay motivated by making room for the Organizations in your
          work, and cognitively connecting the impact on Organizations and Teams to your
          goals and celebrations.'
          'Your unique orientation will make you an asset in creative problem
          solving, innovation, or times of change. Act as counsel for those on
          the team looking for differing perspectives from the majority'
          "Deepen your relationships by building empathy, learn how to orient
          your communication toward the impact on #{team}, and provide feedback
          and recognition based on their orientation."
          'Focus your personal growth on building self-awareness, listening,
          and influencing skills to bridge the gap between your drivers and the
          team drivers.'
        ]
    Society:
      majority:
        description:
          '''
          You are aligned with your team culture, to make an impact on Society. You will
          find that the goals, impact, and celebrations within the team are inherently meaningful.
          You will be able to make decisions and act quickly, prioritizing your work with
          confidence.
          '''
        tipStart: 'Here are some tips for optimizing this shared focus on impacting Society:'
        tips: [
          'Celebrate the impact of your team’s work on Organizations and teams.'
          'Share insights about what really matters to Society and teams you
          serve and model different approaches to serving them in order to push
          the group to become increasingly effective.'
          'Actively encourage team members who don’t share the Society driver
          to share their views. This can be a huge value-add for innovation and
          growth goals, as well as creative problem-solving.'
          'Deepen your collaborative relationships with those who share this
          driver with you and build empathy for those who do not share this
          driver, learning how to see the world through their eyes.'
        ]
      minority:
        description: (team) ->
          """
          Because you are driven to impact Society, and the team is primarily driven to impact
          #{team}, you provide a unique perspective to the team, but you may need to adapt in 
          some areas for the team become high performing. Here are some tips to help you work 
          with purpose and contribute to the team purpose:
          """
        tipStart: 'Here are some tips to help you work with purpose and contribute to the team purpose:'
        tips: (team) -> [
          'Ensure you stay motivated by making room for Society in your work,
          and cognitively connecting the impact on Society to your goals and
          celebrations.'
          'Your unique orientation will make you an asset in creative problem
          solving, innovation, or times of change. Act as counsel for those on
          the team looking for differing perspectives from the majority'
          "Deepen your relationships by building empathy, learn how to orient
          your communication toward the impact on #{team}, and provide feedback
          and recognition based on their orientation."
          'Focus your personal growth on building self-awareness, listening,
          and influencing skills to bridge the gap between your drivers and
          the team drivers.'
        ]
  why:
    Karma:
      majority:
        description:
          '''
          You are united with the team in the belief that progress is achieved through hard work, continually striving for excellence, and creating incentives to challenge people to realize their potential. Since this driver is largely connected with values and worldview, you will find it easy to norm, collaborate, and execute with colleagues who share it.
          '''
        tipStart: 'Here are some tips for your contribution to creating a high-performing team:'
        tips: [
          'The dynamics of the team will consistently push you to better yourself, contributing to your personal and professional growth.'
          'Learn to master the art of inclusiveness from your Harmony-driven colleagues and the art of motivation from your Karma-driven colleagues.'
          'Deepen relationships with your Harmony-driven colleagues. They help the team spot opportunities which might otherwise be missed. They can also help with creative problem-solving, since diversity is a necessary ingredient for successful teams, especially related to innovation and growth.'
        ]
      minority:
        description:
          '''
          With your "hard work" mentality, you provide a unique voice on this team and, as such, it may be harder for you to connect with others on the team who are focused on leaving no one behind. Since the WHY driver is largely connected to values, you will likely be challenged in new ways and may need to work harder to earn the trust of the team. With time and effort to orient your communication toward the team’s dominant driver, you will quickly be seen as a valuable team member!
          '''
        tipStart: 'Here are some tips to help your contribution toward creating a high-performing team:'
        tips: [
          "Deepen relationships by finding ways to genuinely connect with a Harmony-driven perspective, learning to spot it and seeking to understand how Harmony and Karma are two sides of the same coin. Develop yourself into a stronger leader by validating others."
          "Don’t shy away from problem-solving or from contributing to innovation and growth initiatives. Diversity of perspectives is necessary for high-performing teams!"
          "So that your breakthrough ideas are heard, focus your growth on building empathy and on improving communication skills. When you feel priorities are off, appeal to the team's harmonious tendencies."
        ]
    Harmony:
      majority:
        description:
          '''
          You are united with the team in the belief that progress is achieved through fostering inclusiveness, providing access, giving everyone a chance to live up to their potential, creating opportunities for equality, and leading and creating change for the common good. Since this driver is largely connected with values and worldview, you will find it easy to norm, collaborate, and execute with colleagues who share it.
          '''
        tipStart: 'Here are some tips for your contribution to creating a high-performing team:'
        tips: [
          'The dynamics of the team will immerse you in the values of equality and fairness, giving ample opportunity for personal and professional growth related to your Harmony driver.'
          'Learn to master the art of inclusiveness from your Harmony-driven colleagues and the art of motivation from your Karma-driven colleagues.'
          'Deepen relationships with your Karma-driven colleagues. They help the team spot efficiencies, make real progress, and produce impact. They can also help with creative problem-solving, since diverse insights are a necessary ingredient for successful teams, especially related to innovation and growth.'
        ]
      minority:
        description:
          '''
          This team seeks to achieve excellence and to be first to market but, in the rush to the top, it may miss some key opportunities. You provide the voice on the team for those who are disenfranchised and often overlooked or those who have been underserved. But be aware that your "leave no one behind” mentality may cause friction in a Karma-driven team. Since the WHY driver is largely connected to values, you will likely be challenged in new ways and will need to work harder to earn the trust of the team. With time and effort to orient your communication toward the team’s dominant driver, you will quickly be seen as a valuable team member!
          '''
        tipStart: 'Here are some tips for your contribution to creating a high-performing team:'
        tips: [
          'Deepen relationships by finding ways to genuinely connect with a Karma-driven perspective, learning to spot it and seeking to understand how Harmony and Karma are two sides of the same coin. Develop yourself into a stronger leader by validating others.'
          "Don’t shy away from problem-solving or from contributing to innovation and growth initiatives. Diversity of perspectives is necessary for high-performing teams!"
          "So that your breakthrough ideas are heard, focus your growth on building empathy and on improving communication skills. To ensure that opportunities to level the playing field aren’t missed, appeal to the team's hard work tendencies."
        ]
  how:
    Human:
      majority:
        description:
          '''
          You are aligned with your team’s preferred approach to solving problems, which makes it easy to be in flow with your colleagues and to have a natural sense of shared ownership.
          '''
        tipStart: 'Keep in mind:'
        tips: [
          'To ensure that you are innovating, evolving, and growing, seek out those with different problem-solving approaches.'
          'Make it a point to empathize with your team members who have different drivers by understanding how they solve problems and by recognizing them for their efforts. This not only helps you build stronger relationships but also helps you develop your communication and negotiation skills, as you learn how to work with others with different drivers.'
          'Challenge your teammates who also prefer a human-centered approach to problem-solving to consistently share best practices and to identify new ways to stretch your talents.'
        ]
      minority:
        description:
          '''
          Your affinity for human-centered problem-solving makes you a critical member of a team that doesn’t inherently have that perspective. Leverage your natural orientation to help develop empathy in the team for the end user which will, in turn, make the team’s products be more relevant.
          '''
        tipStart: 'Keep in mind:'
        tips: [
          'To understand where you can widen or deepen impact, focus on understanding the dominant driver of the team. By embracing the diversity in the team, you will ensure innovation and growth.'
          'Share your unique perspective with the team by taking risks and by putting yourself out there. This will help other team members understand your approach and impact.'
          'Seize challenges as opportunities to grow yourself as a leader, since you will increase your communication and influencing skills. You’ll also be exposed to a variety of problem-solving approaches, which will help you build empathy and will broaden your perspective.'
        ]
    Community:
      majority:
        description:
          '''
          You are aligned with your team’s preferred approach to solving problems by bringing together communities to identify issues and create ownership.
          '''
        tipStart:
          '''
          You are likely in flow with your colleagues and have a natural sense of shared ownership. To maximize this alignment, while making sure you don’t become complacent:
          '''
        tips: [
          'Challenge your teammates who share the Community driver to identify new ways to stretch your talents. Share and seek best practices to ensure that you all grow together!'
          'When celebrating team accomplishments, cite how communities were used to solve the challenges, but don’t forget about those with drivers other than community!'
          'To avoid groupthink and to add new perspectives, seek out those with different HOW drivers, especially when your team needs breakthrough ideas, to innovate or to go through a change process. Be aware of your blind spots!'
        ]
      minority:
        description:
          '''
          Your affinity for community-centered problem-solving makes you a critical member of a team that doesn’t inherently have that perspective.
          '''
        tipStart:
          '''
          You might need to put effort into developing a shared sense of ownership with team goals rooted in the team’s dominant HOW driver, as well as to build a community of stakeholders outside your team to amplify the impact of your work.
          '''
        tips: [
          'To understand where you can widen or deepen impact, focus on understanding the dominant driver of the team. By embracing the diversity in the team, you will ensure innovation and growth.'
          'Share your unique perspective with the team by taking risks and putting yourself out there. This will help other team members understand your approach and impact.'
          'Seize challenges as opportunities to grow yourself as a leader, since you will increase your communication and influencing skills. You’ll also be exposed to a variety of problem-solving approaches, which will help you build empathy and broaden your perspective.'
        ]
    Structure:
      majority:
        description:
          '''
          You are aligned with your team’s preferred approach to solving problems, which makes it easy to be in flow with your colleagues and to have a natural sense of shared ownership.
          '''
        tipStart: 'Keep in mind:'
        tips: [
          'To ensure that you are innovating, evolving, and growing, seek out those with different problem-solving approaches.'
          'Make it a point to empathize with your team members who have different drivers by understanding how they solve problems and by recognizing them for their efforts. This not only helps you build stronger relationships but also helps you develop your communication and negotiation skills, as you learn how to work with others with different drivers.'
          'Challenge your teammates who also prefer a structure-oriented approach to problem-solving to consistently share best practices and to identify new ways to stretch your talents.'
      ]
      minority:
        description:
          '''
          Your affinity for structurally focused problem-solving makes you a critical member of a team that doesn’t inherently have that perspective. Leverage your structured perspective to help push the team to innovate and deliver consistent impact.
          '''
        tipStart: 'Keep in mind:'
        tips: [
          'To understand where you can widen or deepen impact, focus on understanding the dominant driver of the team. By embracing the diversity in the team, you will ensure innovation and growth.'
          'Share your unique perspective with the team by taking risks and putting yourself out there. This will help other team members understand your approach and impact and will also push people to get beyond the talk and actually perform.'
          'Seize challenges as opportunities to grow yourself as a leader, since you will grow your communication and influencing skills. You’ll also be exposed to a variety of problem-solving approaches, which will help you build empathy and broaden your perspective.'
        ]
    Knowledge:
      majority:
        description:
          '''
          You are aligned with your team’s preferred approach to solving problems, which makes it easy to be in flow with your colleagues and to have a natural sense of shared ownership.
          '''
        tipStart: 'Keep in mind:'
        tips: [
          'Seek out those with different problem-solving approaches to ensure that you are innovating, evolving, and growing.'
          'Make it a point to empathize with your team members who have different drivers by understanding how they solve problems and by recognizing them for their efforts. This not only helps you build stronger relationships but also helps you develop your communication and negotiation skills, as you learn how to work with others with different drivers.'
          'Challenge your teammates who also prefer a knowledge-oriented approach to problem-solving to consistently share best practices and to identify new ways to stretch your talents.'
        ]
      minority:
        description:
          '''
          Your affinity for uncovering and contributing insights makes you a critical member of a team which doesn’t inherently have the investigative approach. Leverage your natural orientation by unearthing new insights and information through rigorous research, in-depth exploration, and analysis.
          '''
        tipStart: 'Keep in mind:'
        tips: [
          'Focus on understanding the dominant driver of the team to discover where you can widen or deepen impact. By embracing the diversity in the team, you will ensure innovation and growth.'
          'Share your value-add with the team by taking risks and putting yourself out there. You can research root causes and use evidence-based approaches, and that will ultimately lead to team members seeking you out for your unique perspective.'
          'Seize challenges as opportunities to grow yourself as a leader, since you will increase your communication and influencing skills. You’ll also be exposed to a variety of problem-solving approaches, which will help you build empathy and broaden your perspective.'
        ]

MeAndTeamOverview = ({me, team}) ->
  R.createElement("div", {"className": "TeamReport__meAndMyTeamOverview"},
    R.createElement("div", {"className": "TeamReport__meAndMyIntro"},
      R.createElement(P, null, """
        What drives purpose for you and how does that relate to what drives
        purpose for the team as a whole? When you are aligned with your team,
        you will find it easy to learn and grow from other like-minded people,
        sharing best practices and challenging each other to hone your purpose
        powers. When you are less aligned with the team, your growth will be
        deep and challenging, and extremely fruitful, learning to work with
        people who have different world views and building skills in communication
        and negotiation.
"""),
      R.createElement(P, null, """
        The guiding principle for how you contribute to your team’s shared purpose
        is to build on your shared drivers, and for the ones that are not shared,
        recognize that you will likely have to adapt a lot of your communication
        for your input to resonate. You can be the force of innovation and creative
        perspective for the team!
""")
    ),

    R.createElement("div", {"className": "TeamReport__meAndMyTeamDriversContainer"},
      R.createElement("section", {"className": "TeamReport__meAndMyTeamDrivers TeamReport__meAndMyTeamDrivers_mine"},
        R.createElement(Heading, {"size": "sm", "className": "TeamReport__meAndMyTeamDriversHeader"}, """
          My Drivers
"""),
        (for d in dimensions
          R.createElement("div", { \
            "key": (d),  \
            "className": "TeamReport__meAndMyTeamDriver TeamReport__meAndMyTeamDriver_#{d}"
          },
            R.createElement("img", { \
              "className": "TeamReport__meAndMyTeamDriverIcon",  \
              "src": "#{URL_DRIVERS}/#{d}#{me[d]}.png",  \
              "alt": (me[d])
            }),
            R.createElement("div", {"className": "TeamReport__meAndMyTeamDriverName"},
              (me[d])
            )
          )
        )
      ),

      R.createElement("section", {"className": "TeamReport__meAndMyTeamDrivers TeamReport__meAndMyTeamDrivers_team"},
        R.createElement(Heading, {"size": "sm", "className": "TeamReport__meAndMyTeamDriversHeader"}, """
          Team Drivers
"""),
        (for d in dimensions
          R.createElement("div", { \
            "key": (d),  \
            "className": "
              TeamReport__meAndMyTeamDriverContainer
              TeamReport__meAndMyTeamDriverContainer_#{d}
            "
          },
            R.createElement("div", { \
              "className": "TeamReport__meAndMyTeamDriver TeamReport__meAndMyTeamDriver_#{d}"
            },
              R.createElement("img", { \
                "className": "TeamReport__meAndMyTeamDriverIcon",  \
                "src": "#{URL_DRIVERS}/#{d}#{team[d]}.png",  \
                "alt": (team[d])
              }),
              R.createElement("div", {"className": "TeamReport__meAndMyTeamDriverName"},
                (team[d])
              )
            )
          )
        )
      )
    )
  )

MeAndTeamDriver = ({dimension, me, team, description, tipStart, tips = []})  ->
  myIcon = "#{URL_DRIVERS}/#{dimension}#{me}.png"
  teamIcon = "#{URL_DRIVERS}/#{dimension}#{team}.png"

  R.createElement("article", {"className": "TeamReport__meAndTeamDriver TeamReport__meAndTeamDriver_#{dimension}"},
    R.createElement("header", {"className": "TeamReport__meAndTeamDriverHeader"},
      R.createElement("div", {"className": "TeamReport__meAndTeamDriverHeaderDriverContainer"},
        R.createElement("div", {"className": "TeamReport__meAndTeamDriverHeading"}, "My Driver:"),
        R.createElement("div", {"className": "TeamReport__meAndTeamDriverDriver"}, (me)),
        R.createElement("img", { \
          "className": "TeamReport__meAndTeamDriverIcon",  \
          "src": (myIcon),  \
          "alt": (me)
        })
      ),
      R.createElement("div", {"className": "TeamReport__meAndTeamDriverHeaderDriverContainer"},
        R.createElement("div", {"className": "TeamReport__meAndTeamDriverHeading"}, "Team Driver:"),
        R.createElement("div", {"className": "TeamReport__meAndTeamDriverDriver"}, (team)),
        R.createElement("img", { \
          "className": "TeamReport__meAndTeamDriverIcon",  \
          "src": (teamIcon),  \
          "alt": (team)
        })
      )
    ),
    R.createElement("section", {"className": "TeamReport__meAndTeamDriverSection"},
      R.createElement("div", {"className": "TeamReport__meAndTeamDriverHeading"}, "What it means"),
      R.createElement("div", {"className": "TeamReport__meAndTeamDriverContent"},
        R.createElement(P, {"size": "sm"}, (description)),
        R.createElement(P, {"size": "sm"}, (tipStart)),
        R.createElement(Ul, {"size": "sm"},
          (for tip, i in tips
            R.createElement(Li, {"key": (i)}, (tip))
          )
        )
      )
    )
  )


MeAndTeamWhoDriver = ({me, team}) ->
  role = if me == team then 'majority' else 'minority'
  inflectedTeam = switch team
    when 'Society' then team.toLowerCase()
    when 'Individual', 'Organization' then "#{team.toLowerCase()}s"

  {
    description
    tipStart
    tips
  } = ME_AND_TEAM_DRIVER_DATA.who[me]?[role] ? {}

  if typeof description is 'function'
    description = description inflectedTeam
  if typeof tips is 'function'
    tips = tips inflectedTeam

  R.createElement(MeAndTeamDriver, { \
    "dimension": "who",  \
    "me": (me),  \
    "team": (team),  \
    "description": (description),  \
    "tipStart": (tipStart),  \
    "tips": (tips)
  })


MeAndTeamWhyDriver = ({me, team}) ->
  role = if me == team then 'majority' else 'minority'
  {
    description
    tipStart
    tips
  } = ME_AND_TEAM_DRIVER_DATA.why[me]?[role] ? {}

  R.createElement(MeAndTeamDriver, { \
    "dimension": "why",  \
    "me": (me),  \
    "team": (team),  \
    "description": (description),  \
    "tipStart": (tipStart),  \
    "tips": (tips)
  })


MeAndTeamHowDriver = ({me, team}) ->
  role = if me == team then 'majority' else 'minority'
  {
    description
    tipStart
    tips
  } = ME_AND_TEAM_DRIVER_DATA.how[me]?[role] ? {}

  R.createElement(MeAndTeamDriver, { \
    "dimension": "how",  \
    "me": (me),  \
    "team": (team),  \
    "description": (description),  \
    "tipStart": (tipStart),  \
    "tips": (tips)
  })


MeAndTeamOverviewPage = ({number, footer, me, team}) ->
  R.createElement(Page, {"number": (number), "footer": (footer)},
    R.createElement(Heading, {"size": "huge"}, "Me + My Team"),
    R.createElement(MeAndTeamOverview, {"me": (me), "team": (team)})
  )


MeAndTeamWhoDriverPage = ({number, footer, myName, teamName, me, team}) ->
  R.createElement(Page, {"number": (number), "footer": (footer)},
    R.createElement(Heading, null,
      (myName), " + ", (teamName), " Team:", R.createElement("br", null), """
      Impact Driver
"""),
    R.createElement(MeAndTeamWhoDriver, {"me": (me), "team": (team)})
  )


MeAndTeamWhyDriverPage = ({number, footer, myName, teamName, me, team}) ->
  R.createElement(Page, {"number": (number), "footer": (footer)},
    R.createElement(Heading, null,
      (myName), " + ", (teamName), " Team:", R.createElement("br", null), """
      Values Driver
"""),
    R.createElement(MeAndTeamWhyDriver, {"me": (me), "team": (team)})
  )


MeAndTeamHowDriverPage = ({number, footer, myName, teamName, me, team}) ->
  R.createElement(Page, {"number": (number), "footer": (footer)},
    R.createElement(Heading, null,
      (myName), " + ", (teamName), " Team:", R.createElement("br", null), """
      Craft Driver
"""),
    R.createElement(MeAndTeamHowDriver, {"me": (me), "team": (team)})
  )


export {
  MeAndTeamOverviewPage
  MeAndTeamWhoDriverPage
  MeAndTeamWhyDriverPage
  MeAndTeamHowDriverPage
}
