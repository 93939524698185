import React from 'react'
import {composeComponent, withModal} from 'utils/react-tools'
import {withRouter, Link} from 'react-router-dom'
import * as _ from 'ramda'
import qs from 'qs'
import {fetchUser} from 'auth/requests'
import {withHandlers, withStateHandlers, lifecycle, mapProps, withState} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import {getUser} from 'api'
import * as ME from 'api/metrics'
import Avatar from 'theme/Avatar'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Modal from 'theme/Modal'
import Subnav from 'theme/Subnav'
import RSelect from 'react-select'
import {getWorkshops, createWorkshop, deleteWorkshop, getOrganizations } from 'workshops/requests'
import Form from 'workshops/WorkshopForm'

import 'theme/btn/btn-styles.sass'
import 'theme/scaffolding/scaffolding-styles.sass'
import './Workshops.sass'

STATE_MAP = [
  'Select partner'
  'Schedule conversation'
  'Conduct conversation'
  'Capture your insights'
  'Rate and mark as completed'
  'Finished'
]


export default composeComponent 'Workshops',
  withRouter
  withModal

  withStateHandlers ({history}) ->
    {search} = history.location
    {new: workshopFormVisible} = qs.parse search, ignoreQueryPrefix: true

    user: null
    organization: null
    loading: false
    workshopFormVisible: workshopFormVisible and JSON.parse workshopFormVisible
    workshops: []
    organizations: []
  ,
    setUser: -> (user) -> {user}
    showWorkshopForm: (_, {history}) -> (show) ->
      history.push pathname: history.location.pathname, search: qs.stringify new: show, addQueryPrefix: true
      workshopFormVisible: show
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setOrganization: -> (organization) -> {organization}
    setOrganizations: -> (organizations) -> {organizations}
    setWorkshops: -> (workshops) -> {workshops}


  withHandlers
    selectOrganization: ({setOrganization, setWorkshops}) -> (org) ->
      ME.reportStateChange "CPL PeerCoaching/Cohorts: organization selecting", {orgId: org?.id, orgCode: org?.code}
      setOrganization org
      getWorkshops(org)
      .then(setWorkshops)
      .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/Cohorts: workshops loading error", err

  withHandlers
    loadData: ({user, setUser, startLoading, endLoading, setOrganizations, selectOrganization }) -> ->
      ME.reportStateChange "CPL PeerCoaching/Cohorts: data loading"
      startLoading()

      a = new Promise (resolve, reject) ->
        fetchUser()
        .then (user) ->
          setUser user
          orgPromise =
            if user.isSuperAdmin
              getOrganizations().then(setOrganizations)
            else
              Promise.resolve()
          workshopsPromise = selectOrganization(user.organization)
          Promise.all([orgPromise, workshopsPromise])
          .then resolve, reject
        , reject

      a
      .finally endLoading

    addWorkshop: ({organization, workshops, setWorkshops, showWorkshopForm, history}) -> (name) ->
      ME.reportStateChange "CPL PeerCoaching/Cohorts: workshop adding", {newWorkshop: name}
      createWorkshop organization.code, name
      .then (w) ->
        ME.reportStateChange "CPL PeerCoaching/Cohorts: workshop added"
        search = qs.parse history.location.search, ignoreQueryPrefix: true
        history.push pathname: "/peer-coaching/user-groups/#{w.id}", search: "?#{if search.conversation? then 'conversation' else ''}"
      .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/Cohorts: workshop adding error", err

  withHandlers
    deleteWorkshop: ({closeModal, loadData}) -> (workshop) ->
      deleteWorkshop workshop.slug
      .then loadData
      .then closeModal

  lifecycle
    componentDidMount: ->
      @props.loadData()

  ({
    loading
    workshops
    organization
    organizations
    user
    history
    workshopFormVisible
    selectOrganization
    addWorkshop
    showWorkshopForm
    showModal
    closeModal
    modal
    deleteWorkshop
  }) ->

    React.createElement("div", {"className": "Workshops page"},
      React.createElement(Header, {"user": (user), "cpl": (true)}),

      React.createElement("div", {"className": "container", "id": "main"},

        React.createElement("div", {"className": "Workshops__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "Workshops__title"}, """
              Manage Cohorts
"""),
            ( if user?.isSuperAdmin
              React.createElement("div", {"className": "Workshops__label"}, "Organization")
            ),
            ( if user?.isSuperAdmin
              React.createElement(RSelect, { \
                "className": "Workshops__select",  \
                "classNamePrefix": "Workshops__select",  \
                "placeholder": "Select an organization",  \
                "options": (organizations),  \
                "getOptionValue": ((o) -> o),  \
                "getOptionLabel": ((o) -> o.name),  \
                "value": (organization),  \
                "onChange": (selectOrganization),  \
                "disabled": (loading)
              })
            )
          ),
          React.createElement("button", {"onClick": (-> showWorkshopForm true), "className": "btn btn_secondary btn_solid_bluePurple"}, """
            Create new
""")
        ),

        (if workshopFormVisible
          React.createElement(Form, { \
            "onSubmit": (addWorkshop),  \
            "cancel": (-> showWorkshopForm false),  \
            "className": "margin-bottom-3"
          })
        ),

        React.createElement("div", {"className": "relative"},
          React.createElement(Loader, {"loading": (loading)}),

          React.createElement("table", null,
            React.createElement("thead", null,
              React.createElement("tr", null,
                React.createElement("th", null, "Name"),
                React.createElement("th", null, "Members"),
                React.createElement("th", {"className": "invisible"}, "Actions")
              )
            ),

            React.createElement("tbody", null,
              (workshops.map (workshop) ->
                React.createElement("tr", { \
                  "key": (workshop.id),  \
                  "onClick": (-> history.push "/peer-coaching/user-groups/#{workshop.id}")
                },
                  React.createElement("td", null,
                    (workshop.name)
                  ),
                  React.createElement("td", null,
                    (workshop.members.length), """ member(s)
"""),
                  React.createElement("td", {"className": "Workshops__actions"},
                    React.createElement("div", null,
                      React.createElement("button", {"className": "btn btn_compact btn_solid_bluePurple"}, """
                        Manage members
"""),
                      React.createElement("button", { \
                        "className": "btn btn_compact btn_solid_red",  \
                        "onClick": ((e) ->
                          e.stopPropagation()
                          showModal payload: workshop
                        )
                      }, """
                        Delete
""")
                    )
                  )
                )
              )
            )
          )
        )
      ),


    React.createElement(Footer, null),

    React.createElement(Modal, {"isOpen": (modal?.visible), "className": "confirm-modal", "close": (closeModal)},
      React.createElement("h2", {"className": "title"}, """
        Warning!
"""),
      React.createElement("p", null, """
        Are you sure you want to delete
""", React.createElement("strong", null, (modal?.payload?.name)), """
        cohort?
"""),
      React.createElement("div", {"className": "btns"},
        React.createElement("button", {"onClick": (closeModal), "className": "btn btn_secondary btn_outlined_bluePurple"}, """
          Cancel
"""),
        React.createElement("button", {"onClick": (-> deleteWorkshop modal?.payload), "className": "btn btn_secondary btn_solid_bluePurple"}, """
          Delete
""")
      )
    )

    )
