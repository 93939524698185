import React, {useCallback, useEffect, useRef} from 'react';
import cx from 'classnames';

import './Questions.sass'

const AutosizeInput = (props) => {
  const {className, value, maxLength, placeholder, onChange, required} = props;

  const inputEl = useRef(null);

  const resize = () => {
    inputEl.current.style.height = 'auto';
    inputEl.current.style.height = inputEl.current.scrollHeight+'px';
    if(inputEl.current.scrollHeight > 100) {
      inputEl.current.style.overflow = 'auto';
    }
  }

  const delayedResize = () => {
    window.setTimeout(resize, 0);
  }

  const changeWithAutosize = useCallback((value) => {
    onChange && onChange(value);
    resize();
  }, [value, onChange, resize])

  useEffect(() => {
    if(required && !value && onChange) {
      onChange('');
    }

    resize();
  }, []);

  return (
    <div className={cx("Questions__field embed-submit-field", className)}>
      <textarea
        ref={inputEl}
        className="Questions__fieldText small autosize"
        rows="1"
        onChange={(e) => changeWithAutosize(e.target.value)}
        onCut={delayedResize}
        onPaste={delayedResize}
        onDrop={delayedResize}
        onKeyDown={delayedResize}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder || "Type your partner's answer..."}
      />
    </div>
  )
}

export default AutosizeInput;
