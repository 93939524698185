import React, {useState, useEffect, useMemo, useContext, useRef, useCallback} from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from 'stores/stores';
import * as ME from 'api/metrics'
import Loader from 'theme/Loader';
import Avatar from 'theme/Avatar';
import TranslationsContext from 'translations';
import Comments from './Comments';
import Reactions from './Reactions';
import { ordinal } from 'utils/utils';
import { fetchActivityFeed, fetchActivity, postReaction, deleteReaction, postComment, updateComment, deleteComment, fetchNotSeenEventsCount, updateLastSeenTimestamp } from './requests';

import './Community.sass';

const EVENTS_PER_PAGE = 15;

const titleTip = "This recognition feed is meant to celebrate the community of those prioritizing their fulfillment and supporting others at work";

const formatDate = (date) => {
  if(moment(date).year() == moment().year()) {
    return moment(date).format('D MMM - h:mm A')
  }
  return moment(date).format('D MMM YYYY - h:mm A')
}

const messages = {
  "UserCommunityJoin": (e, t) => {
    const jobFunctionIsOther = e.member.function == 'Other' && !e.member.functionText;
    const memberJobFunction = !jobFunctionIsOther && (e.member.functionText || (e.member.function && t.find(`dictionaries.jobfunction.${e.member.function}.name`)));
    const patternString = e.member.pattern && `${e.member.pattern.who}${e.member.pattern.why}${e.member.pattern.how}`;
    const statement = patternString && (
      t.find(`pattern.archetypes.${patternString}.imperative.who`) + " " +
      t.find(`pattern.archetypes.${patternString}.imperative.why`) + " " +
      t.find(`pattern.archetypes.${patternString}.imperative.how`)
    );
    return (
      <>
        {e.member.firstName} {e.member.lastName} just joined your community.
        {" "}
        {memberJobFunction && e.member.tenure &&
          <>
            {e.member.firstName} has worked at {e.member.organizationName} for {e.member.tenure} and is in {memberJobFunction}.
            {" "}
          </>
        }
        {memberJobFunction && !e.member.tenure &&
          <>
            {e.member.firstName} is in {memberJobFunction} at {e.member.organizationName}.
            {" "}
          </>
        }
        {(!memberJobFunction) && e.member.tenure &&
          <>
            {e.member.firstName} has worked at {e.member.organizationName} for {e.member.tenure}.
            {" "}
          </>
        }
        {e.member.firstName}{e.member.firstName[e.member.firstName.length - 1] == 's' ? "'" : "'s"} purpose is {statement}.
      </>
    )
  },
  "CompletedConversationsMilestone": (e, t) => {
    if(e.data.conversationCount == 1) {
      return (
        <>
          {e.member.firstName} {e.member.lastName} just completed their first conversation.
        </>
      )
    } else if (e.data.conversationCount > 1) {
      return (
        <>
          {e.member.firstName} {e.member.lastName} reached a conversation milestone: {e.data.conversationCount} completed conversations.
        </>
      )
    }
  },
  "ConnectionsMilestone": (e, t) => {
    const count = ordinal(e.data.connectionCount);
    const partner = e.partner;
    const jobFunctionIsOther = partner.function == 'Other' && !partner.functionText;
    const partnerJobFunction = !jobFunctionIsOther && (partner.functionText || (partner.function && t.find(`dictionaries.jobfunction.${partner.function}.name`)));
    const dominantDimension = ((partner && partner.pattern && partner.pattern.dominant) || '').toLowerCase();
    const leadershipStyle = t.find(`pattern.leadershipStyle.dominantDriver.${dominantDimension}.name`).toLowerCase();
    return (
      <>
        {e.member.firstName} {e.member.lastName} just made their {count} connection. {" "}
        {partner &&
          <>
            They are now connected with {partner.firstName} {partner.lastName}, {leadershipStyle == 'impact' ? 'an' : 'a'} {leadershipStyle}-driven leader
            {" "}
            {partnerJobFunction &&
              <>working in {partnerJobFunction} {" "}</>
            }
            at {e.member.organizationName}.
          </>

        }
      </>
    )
  },
  "PeerConversationBreakthrough": (e, t) => {
    return (
      <>
        {e.member.firstName} {e.member.lastName} had a breakthrough conversation.
      </>
    )
  }
}


const Activity = () => {
  const {userStore} = useStore();
  const {user} = userStore;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState({});
  const [page, setPage] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const feedTimeout = useRef();
  const [hasMoreEvents, setHasMoreEvents] = useState(true);
  const t = useContext(TranslationsContext);

  const nav = [
    {
      title: 'All Recognition',
      id: '',
      count: 'allActivitiesCount'
    },
    {
      title: 'My Connections',
      id: 'connections',
      params: {peerCoachesOnly: true},
      count: 'peerCoachesCount'
    },
    {
      title: 'My Notifications',
      id: 'my',
      params: {forMemberId: user && user.id},
      label: (user) => user && !user.activityVisibilityEnabled && 'Only visible to you',
      count: 'myNotificationsCount'
    }
  ]

  const filteredBy = useMemo(() => {
    const pathname = /\/community\/activity\/*/;
    const id = location.pathname.replace(pathname, '');
    return nav.find(n => n.id == id);
  }, [location, nav]);

  const filterBy = (e) => {
    history.push(`/community/activity/${e.id}`);
    setPage(1);
    setHasMoreEvents(true);
    setLoading(true)
    getEvents({...e.params, page: 1})
    .then(
      (e) => {
        setEvents(e);
        setLoading(false);
      },
      () => setLoading(false)
    )
  }

  const eventsWithTenure = useMemo(() => events.filter(e => {
    if(e.activityType === 'ConnectionsMilestone' && !e.partner) {
      return false;
    }
    return true;
  }).map(e => {
    return {
      ...e,
      member: {
        ...(e.member || {}),
        organizationName: user && user.organization.name,
        tenure: e.data && e.data.tenure
      },
    }
  }), [events, user]);

  const getEvents = useCallback((params = {}) => {
    return fetchActivityFeed({...params, size: params.size || EVENTS_PER_PAGE, page: params.page || page})
    .then(
      e => e,
      (error) => {
        ME.reportIntermediateError("community-activity-loading_user-error", error);
        return Promise.reject(error);
      }
    );
  }, [page]);

  const getMoreEvents = useCallback(() => {
    if(!loadingEvents) {
      setLoadingEvents(true);
      return fetchActivityFeed({...filteredBy.params, size: EVENTS_PER_PAGE, page: page + 1})
      .then(e => {
        if(e.length == 0) {
          setHasMoreEvents(false);
        } else {
          setEvents(ee => [...ee, ...e]);
        }
        setPage(p => p + 1);
        setLoadingEvents(false)
        return e;
      }, (error) => {;
        ME.reportIntermediateError("community-activity-loading_user-error", error);
        setLoadingEvents(false);
      })
    }
  }, [filteredBy, loadingEvents, events, page]);

  const getEventsAfterUpdate = useCallback((activityId) => {
    setLoadingEvents(true);
    return fetchActivity(activityId)
    .then(e => {
      setEvents(events => {
        const i = events.findIndex(({id}) => id == activityId);
        return [].concat(events.slice(0, i), e, events.slice(i + 1));
      });
      setLoadingEvents(false);
    })
  }, []);

  const sendReaction = (activityId, reaction) => {
    return new Promise((resolve, reject) => {
      postReaction(activityId, reaction)
      .then(
        () => {
          getEventsAfterUpdate(activityId)
          .then(resolve, reject)
        },
        reject
      )
    });
  }

  const changeReaction = (activityId, reaction) => {
    return new Promise((resolve, reject) => {
      deleteReaction(activityId)
      .then(() => 
        postReaction(activityId, reaction)
        .then(
          () => {
            getEventsAfterUpdate(activityId)
            .then(resolve, reject)
          },
          reject
        ),
        reject
      )
    });
  }

  const removeReaction = (activityId, reaction) => {
    return new Promise((resolve, reject) => {
      deleteReaction(activityId, reaction)
      .then(
        () => {
          getEventsAfterUpdate(activityId)
          .then(resolve, reject)
        },
        reject
      )
    });
  }

  const sendComment = (activityId, comment) => {
    return new Promise((resolve, reject) => {
      postComment(activityId, comment)
      .then(
        () => {
          getEventsAfterUpdate(activityId)
          .then(resolve, reject)
        },
        reject
      )
    });
  }

  const changeComment = (activityId, commentId, comment) => {
    return new Promise((resolve, reject) => {
      updateComment(activityId, commentId, comment)
      .then(
        () => {
          getEventsAfterUpdate(activityId)
          .then(resolve, reject)
        },
        reject
      ),
      reject
    });
  }

  const removeComment = (activityId, commentId) => {
    return new Promise((resolve, reject) => {
      deleteComment(activityId, commentId)
      .then(
        () => {
          getEventsAfterUpdate(activityId)
          .then(resolve, reject)
        },
        reject
      )
    });
  }

  const getLastSeen = () => {
    return fetchNotSeenEventsCount().then(
      (response) => {
        setNotificationsCount(response);
        updateLastSeenTimestamp();
      }, (error) => {
        if(error.status == 404) {
          updateLastSeenTimestamp();
        }
      }
    );
  }

  useEffect(() => {
    setLoading(true);
    getLastSeen()
    .then(() => {
      setLoading(false);
    }, (err) => {
      setError(err);
      setLoading(false);
    });
    // getAllEvents(u);

    return () => {
      clearTimeout(feedTimeout.current);
    }
  }, []);

  return (
    <div className="Community__activity">
      <div className="Community__title">
        <Tippy content={titleTip} placement="right" className="tip">
          <h1 className="page-title">
            Recognition
            <i className="ci-info_circle_outline" />
          </h1>
        </Tippy>
      </div>
      <nav className="Community__title Community__activityNav">
        {nav.map((elem, i) => {
          const count = notificationsCount[elem.count];
          const title = `${elem.title} ${count > 0 ? `(${count})` : ''}`;
          return (
            <button
              key={i}
              title={title}
              className={cx('Community__activityNavItem', {active: filteredBy && filteredBy.id == elem.id})}
              onClick={() => filterBy(elem)}
            >
              {title}
              {elem.label &&
                <span className='label'>{elem.label(user)}</span>
              }
            </button>
          )
        })}
      </nav>
      <div className="relative">
        <Loader loading={loading} color="white" />
        {user &&
          <div className="Community__activityList">
            {eventsWithTenure.length == 0 && !error &&
              <p className='text-center'>There are no posts yet.</p>
            }
            {eventsWithTenure.length == 0 && error &&
              <p className='text-center'>Something went wrong. Cannot load recognition feed.</p>
            }
            <InfiniteScroll
              loadMore={getMoreEvents}
              hasMore={hasMoreEvents}
              loader={<Loader key="loader" loading={loadingEvents} size="1" className="static" color="white" />}
              threshold={0}
            >
              {eventsWithTenure.map(e =>
                <div className="Community__activityEvent" key={e.id}>
                  {e.member &&
                    <Avatar for={{...e.member, avatars: {normal: e.member.avatars}}} size="md" />
                  }
                  <div className='Community__activityEventContent'>
                    <div className='Community__activityEventName'>
                      {e.member.firstName} {e.member.lastName}
                      <span className='Community__activityEventType'>
                        {e.activityType == 'UserCommunityJoin' &&
                          <>
                            <span className='twa twa-waving-hand' />
                            Welcome
                          </>
                        }
                        {(e.activityType == 'CompletedConversationsMilestone' || e.activityType == 'ConnectionsMilestone') && 
                          <>
                            <span className='twa twa-trophy' />
                            Milestone
                          </>
                        }
                        {e.activityType == 'PeerConversationBreakthrough' && 
                          <>
                            <span className='twa twa-glowing-star' />
                            Breakthrough
                          </>
                        }
                      </span>
                      <span className='Community__activityEventDate'>
                        {formatDate(e.createdAt)}
                      </span>
                    </div>
                    {e.member && messages[e.activityType] &&
                      <p className="p-new">
                        {messages[e.activityType](e, t)}
                      </p>
                    }
                    <Reactions
                      user={user}
                      activity={e}
                      reactions={e.reactions}
                      sendReaction={sendReaction}
                      changeReaction={changeReaction}
                      removeReaction={removeReaction}
                    />
                    <Comments
                      user={user}
                      activityId={e.id}
                      comments={e.comments}
                      sendComment={sendComment}
                      changeComment={changeComment}
                      removeComment={removeComment}
                    />
                  </div>
                </div>
              )}
              {!hasMoreEvents &&
                <p style={{ textAlign: 'center' }}>
                  Yay! You have seen it all
                </p>
              }
            </InfiniteScroll>
          </div>
        }
      </div>
    </div>
  )
}

export default observer(Activity);
