#TODO: factor out and publish as a separate module
import * as _ from 'ramda'

EMAIL_RE = /// ^
  [a-z0-9!#$%&'*+\/=?^_`{|}~.-]+
  @
  ([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+
  [a-z0-9][a-z0-9]+
  $ ///i

ALPHABETIC = /^[a-zA-Z() ]*$/
ALPHANUMERIC = /^[a-z0-9]+$/i

PASSWORD_RE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-\.\/:;<=>?@\[\]^_`{|}~])[A-Za-z\d@!"#$%&'()*+,\-\.\/:;<=>?@\[\]^_`{|}~]*$/
SPECIAL_CHARS = /[!"#$%&'()*+,\-\.\/:;<=>?@\[\]^_`{|}~]/

required = (message='required') -> (value) -> if _.isNil value then message else undefined
minlen = (min, message='minlen') -> (value) -> message if _.lt _.length(value), min
maxlen = (max, message='maxlen') -> (value) -> message if _.gt _.length(value), max
alpha = (message='alpha') -> (value) -> message if _.test ALPHABETIC, value
alphanum = (message='alphanum') -> (value) -> message if _.test ALPHANUMERIC, value
num = (message='alphanum') -> (value) -> message if isNaN(value)
oneof = (values, message='oneof') -> (value) -> message if value not in values
match = (field, message='match') -> (value, allValues) -> message if value != allValues[field]
doesntInclude = (fields=[], message="doesn't include") -> (value, allValues) ->
  message if (value && !fields.every((f) -> !allValues[f] || value.toLowerCase().indexOf(allValues[f].toLowerCase()) == -1))
pattern = (pattern, msg='pattern') -> (value) ->
  if value and not pattern?.test?(value) then msg
email = (message='email') -> (value) -> message if value and not EMAIL_RE.test value
password = (message='password') -> pattern(PASSWORD_RE, message)

export {SPECIAL_CHARS, PASSWORD_RE, required, email, password, pattern, minlen, maxlen, alpha, match, alphanum, num, oneof, doesntInclude}

formValidator = (validateFields) -> (values) ->
  Object.keys(validateFields).reduce(
    (res, fieldName) ->
      functions =
        if validateFields[fieldName] instanceof Array
          validateFields[fieldName]
        else [validateFields[fieldName]]

      msg = functions.map((f) ->
        f?(values[fieldName], values)
      ).find((msg) -> msg?)

      if msg
        {...res, [fieldName]: msg}
      else res
    , {}
  )

export default formValidator
