import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import cx from 'classnames';
import {last} from 'ramda';
import Tippy from '@tippyjs/react';
import * as emojis from 'theme/emojis';
import Markdown from 'theme/Markdown';

import './Carousel.scss';

const BULLETS_TO_PICK = 3;
const BREAK_LINE = '\n';

const RIG = {
  relationship: 'Relationships',
  impact: 'Impact',
  growth: 'Growth',
}

const RIG_OPTIONS = [
  'Frustrated',
  'Unhappy',
  'Meh',
  'Happy',
  'PumpedUp',
]

const LABELS_MAP = {
  'Frustrated': 'assessment.rig.answer1',
  'Unhappy': 'assessment.rig.answer2',
  'Meh': 'assessment.rig.answer3',
  'Happy': 'assessment.rig.answer4',
  'PumpedUp': 'assessment.rig.answer5'
}

const tabsData = {
  question: {
    title: 'Question',
    icon: 'fa-comment',
    i: 0
  },
  profile: {
    title: 'Purpose Profile',
    icon: 'fa-user',
    i: 1
  },
  fulfillment: {
    title: 'Fulfillment',
    icon: 'fa-smile',
    i: 2
  },
  followUp: {
    title: 'Follow-up',
    icon: 'fa-comment-dots',
    i: 3
  },
};

const ControlBtn = (props) => {
  const {name, icon, text, activeTab, setCarouselElem, activeColor} = props;
  const active = useMemo(() => activeTab == name, [name, activeTab]);

  if(!name)
    return null;

  return (
    <button className={cx("Questions__carouselControlsItem", {active}, activeColor)} onClick={() => setCarouselElem(name)}>
      {icon &&
        <i className={cx("icon", icon, {"active fas": active, "fad": !active})}></i>
      }
      <span>{text}</span>
    </button>
  )
}


const Controls = ({content, isMobile, toasts, activeTab, setCarouselElem, activeColor}) => {
  return (
    <div className="Questions__carouselControls">
      {content.map((q, i) => {
        const tab = tabsData[q.tab];
        const toast = q.tab in toasts && toasts[q.tab];

        if(toast)
          return (
            <Tippy
              key={i}
              className="Questions__tip"
              content={toast.content}
              arrow
              visible={toast.visible}
              placement={isMobile ? 'top' : 'left'}
            >
              <div>
                <ControlBtn
                  name={q.tab}
                  icon={tab.icon}
                  text={tab.title}
                  activeTab={activeTab}
                  setCarouselElem={setCarouselElem}
                  activeColor={activeColor}
                />
              </div>
            </Tippy>
          )

        return (
          <ControlBtn
            key={i}
            name={q.tab}
            icon={tab.icon}
            text={tab.title}
            activeTab={activeTab}
            setCarouselElem={setCarouselElem}
            activeColor={activeColor}
          />
        )
      })}
    </div>
  )
}

const carouselOrder = Object.keys(tabsData);

const isMobile = window.innerWidth < 813;

const Carousel = (props) => {
  const {question, imAsking, conversation, t, toasts = {}, rigScore, user, activeColor, convsWithScores = []} = props;
  const [activeTab, setActiveTab] = useState('question');
  const [carouselDirection, setCarouselDirection] = useState('next');
  const partnerName = conversation.partner && conversation.partner.member.firstName;
  const translationsData = useMemo(() => {
    return {convs: convsWithScores, conv: convsWithScores.find(s => conversation && s.id == conversation.id)}
  }, [convsWithScores, conversation]);

  const answeringMember = imAsking ? conversation.partner : conversation.me;

  useEffect(() => {
    setActiveTab('question');
  }, [imAsking]);

  if (!question || !conversation)
    return null;

  const setCarouselElem = useCallback((carouselElemNew) => {
    const newI = carouselOrder.indexOf(carouselElemNew);
    const lastI = carouselOrder.indexOf(activeTab);
    if (newI > lastI)
      setCarouselDirection('next')
    else setCarouselDirection('prev');
    setActiveTab(carouselElemNew);
  }, [])

  const createBulletPoints = (dynamic, memberId) => {
    if(!dynamic || !memberId)
      return dynamic;

    const allBullets = dynamic.value.substring(1)
    .split(BREAK_LINE);

    const idSum = memberId.toString().split('').reduce(
      (sum, num) => sum + parseInt(num)
      , 0
    )
    const shift = Math.max(idSum % (allBullets.length - BULLETS_TO_PICK + 1), 0);
    const pickedBullets = allBullets.slice(shift, shift + BULLETS_TO_PICK);

    const result = pickedBullets
    .map((point) => point.indexOf('- ') == 0 ? point : `- ${point}`)
    .join(BREAK_LINE);

    return result;
  }

  const content = useMemo(() => {
    if(question.action) {
      const rigScoreMapped = (rigScore && rigScore[0] && rigScore[0].scores) || {};
      const result = [
        {
          tab: 'question',
          value: "Now that we've been able to reflect, let's commit to applying what we've learned before our next conversation. This is an important step in getting the most value out of our conversation today.\n\nWhat is one action you want to commit to doing before our next conversation? What impact do you expect it to make on your fulfillment and effectiveness?"
        },
        {
          tab: 'fulfillment',
          rig: !imAsking && Object.keys(RIG).map((key) => {
            const score = rigScoreMapped[key] && rigScoreMapped[key].value;
            return {
              key,
              label: RIG[key],
              icon: emojis[RIG_OPTIONS[score]],
              value: t.find(LABELS_MAP[RIG_OPTIONS[score]])
            }
          }),
          note: `This is a summary of your pre-conversation check-in response from today. This is for your personal reflection and not visible to ${partnerName}.`,
          value: imAsking && `${partnerName} is able to view and reflect on their response to their pre-conversation check-in today. It’s a helpful reminder when brainstorming a meaningful action that will help their fulfillment and effectiveness.`
        },
        imAsking && {
          tab: 'followUp',
          value: "Let's make sure you have a plan to complete your action and make the most out of it. How do you plan to accomplish it given what we discussed today? What barriers might you encounter and how will you overcome them? Do you need help from anyone?"
        }
      ].filter(Boolean);
      return result;
    }
    else {
      const titleSecondVersion = (conversation.me.meFirst && !imAsking) || (!conversation.me.meFirst && imAsking);
      const questionContent = t.splitMarkdownToParts(`peerconversation.question.${question.label}.${titleSecondVersion ? 'short_title' : 'title'}`, answeringMember && answeringMember.member, translationsData)
        .map((c) => ({...c, tab: c.dynamic ? 'profile' : 'question'}));
      const dynamicI = questionContent.findIndex((c) => c.dynamic);
      const dynamic = questionContent[dynamicI];

      const withBullets =
        dynamic && dynamic.value.includes(BREAK_LINE)
        ?
          questionContent.slice(0, dynamicI)
          .concat({tab: 'profile', dynamic: true, value: createBulletPoints(dynamic, answeringMember.member.memberId)})
          .concat(questionContent.slice(dynamicI + 1))
        : questionContent;

      const result = withBullets.reduce(
        (result, item) => {
          const lastItem = result[result.length - 1];
          if(!lastItem)
            return [item];
          if(lastItem.dynamic || item.dynamic)
            return result.concat(item)
          return result.slice(0, -1).concat({
            ...lastItem,
            value: lastItem.value.concat('\n\n', item.value)
          })
        }
        , []
      ).concat(
        imAsking
        ? {
            tab: 'followUp',
            value: t.find(`peerconversation.question.${question.label}.description`, conversation.partner, translationsData)
          }
        : []
      )
      return result;
    }
  }, [question, rigScore, conversation, imAsking, partnerName, answeringMember, translationsData]);

  const activeTabContent = content.find((c) => c.tab == activeTab);

  return (
    <div className="Questions__carousel">
      <Controls 
        content={content}
        isMobile={isMobile}
        toasts={toasts}
        activeTab={activeTab}
        setCarouselElem={setCarouselElem}
        activeColor={activeColor}
      />

      <div className={cx("Questions__carouselBox", {
        top: carouselOrder.indexOf(activeTab) == 0,
        bottom: carouselOrder.indexOf(activeTab) == carouselOrder.length - 1
      })}>
        <TransitionGroup
          className={cx("Questions__carouselTransition", {
            "next": carouselDirection == 'next',
            "prev": carouselDirection == 'prev'
          })}
        >
          <CSSTransition
            key={activeTab}
            timeout={400}
            classNames="carousel"
          >
            <div className="Questions__carouselContent">
              {activeTab == 'question' && imAsking &&
                <span className={cx("Questions__carouselTitle", activeColor)}>
                  Ask {partnerName}
                </span>
              }
              {activeTab == 'followUp' &&
                <span className={cx("Questions__carouselTitle", activeColor)}>
                  Follow-up
                </span>
              }
              {activeTab == 'profile' &&
                <span className={cx("Questions__carouselTitle", activeColor)}>
                  From {
                    imAsking
                    ? `${partnerName}${last(partnerName) == 's' ? "'" : "'s"}`
                    : 'My'
                  } Purpose Profile
                </span>
              }
              {activeTab == 'fulfillment' && activeTabContent && activeTabContent.rig
                ?
                  <div className="Markdown">
                    <div className="Questions__carouselRig">
                      {activeTabContent.rig.map((dim) => (
                        <div className="Questions__carouselRigItem" key={dim.label}>
                          <span className={cx("Questions__carouselRigLabel Questions__carouselTitle", activeColor)}>{dim.label}</span>
                          <img src={dim.icon} alt="" />
                          <span className="Questions__carouselRigValue">{dim.value}</span>
                        </div>
                      ))}
                    </div>
                    <span className="Questions__carouselInfo">
                      {activeTabContent.note}
                    </span>
                  </div>
                : <Markdown source={activeTabContent && activeTabContent.value} />
              }
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  )
}

export default Carousel;
