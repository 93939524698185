import { optInSnoozed } from 'api';
import dayjs from 'dayjs';
import moment from 'moment';
import { ascend, descend, groupBy, mapObjIndexed, prop, sortWith, values } from 'ramda';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import qs from 'qs';
import Competencies from 'Competencies';
import * as ME from 'api/metrics';
import { getConversationsDefinitions, getUserConversations } from 'conversations/requests';
import MatchStatus from 'opt-in/MatchDialog';
import UnmatchDialog from 'opt-in/UnmatchDialog';
import { useStore } from 'stores/stores';
import Footer from 'theme/Footer';
import Header from 'theme/Header';
import Loader from 'theme/Loader';
import Toast from 'theme/Toast';
import { getNetworkStatistics } from 'user/requests';
import * as states from 'utils/states';
import TranslationsContext from 'translations';
import './Dashboard.sass';
import Fulfillment from './Fulfillment';
import GettingStarted from './GettingStarted/GettingStarted';
import NetworkGrowing from './NetworkGrowing';
import PeerNetwork from './PeerNetwork';
import ProfileCard from './ProfileCard';
import Purpose from './Purpose';
import Scheduler from './Scheduler';
import SetCardNew from './SetCardNew';
import Tutorial from './Tutorial';
import { getColleague } from './requests';

const Dashboard = ({ location }) => {
  const t = useContext(TranslationsContext);
  const history = useHistory();
  const search = location.search ? qs.parse(location.search, { ignoreQueryPrefix: true }) : {};
  const { userStore } = useStore();

  const { user } = userStore;

  const [activePeerId, setActivePeerId] = useState();
  const pauseConfirmation = useMemo(() => search && !!search.pauseConfirmation);
  const resetConfirmation = useMemo(() => search && !!search.resetConfirmation);
  const unmatched = useMemo(() => search && !!search.unmatched);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [definitions, setDefinitions] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [activeSet, setActiveSet] = useState(null);
  const [partner, setPartner] = useState();
  const [loadingPartner, setLoadingPartner] = useState(false);
  const [networkStats, setNetworkStats] = useState({});
  const [toastVisible, setToastVisible] = useState(false);

  const loadingPage = loading || userStore.loading;

  const loadDefinitions = () => {
    return getConversationsDefinitions().then(setDefinitions, (error) => {
      ME.reportIntermediateError('Dashboard: loading conversation definitions', error);
      setError(error);
    });
  };
  const loadConversations = () => {
    return getUserConversations().then(setConversations, (error) => {
      ME.reportIntermediateError('Dashboard: loading conversations error', error);
      setError(error);
    });
  };

  const loadNetworkStats = () => {
    if (!user) return Promise.resolve();
    return getNetworkStatistics(user.slug).then(setNetworkStats, (error) => {
      ME.reportIntermediateError('Dashboard: loading network statistics error', error);
      setError(error);
    });
  };

  useEffect(() => {
    setLoading(true);
    ME.reportStateChange('Dashboard: loading');
    Promise.all([loadDefinitions(), loadConversations(), loadNetworkStats()]).then(
      () => {
        setLoading(false);
        ME.reportStateChange('Dashboard: loaded');
      },
      (error) => {
        ME.reportIntermediateError('Dashboard: loading error', error);
        setLoading(false);
      }
    );
  }, []);

  const scheduleUpdated = () => {
    return loadConversations();
  };

  const sets = useMemo(
    () =>
      definitions.reduce((resultSets, program) => {
        const s = (program.sets || []).map((set) => ({ ...set, programSetLabel: `${program.label}/${set.label}` }));
        return resultSets.slice().concat(s);
      }, []),
    [definitions]
  );

  const mappedConvs = useMemo(() => {
    if (!user) return [];
    return conversations
      .filter((conv) => {
        const set = sets.find((s) => conv.label.indexOf(s.programSetLabel) == 0);
        const [p, s, label] = conv.label.split('/');
        const presentInDefinitions = !!set && !!set.definitions && !!set.definitions.find((d) => d.label == label);
        const stateValid = conv.state != states.Rejected;
        const moreThanOneParticipant = conv.participant.length > 1;
        return stateValid && moreThanOneParticipant && presentInDefinitions;
      })
      .map((conv) => {
        const partner = conv.participant.find((p) => p.member.memberId != user.id);
        const me = conv.participant.find((p) => p.member.memberId == user.id);
        const [programLabel, setLabel, convLabel] = conv.label.split('/');
        return { ...conv, setLabel, programLabel, convLabel, partner, me };
      });
  }, [user, conversations, sets]);

  const groupedSetsByPeer = useMemo(() => {
    const groupedByPartner = groupBy((conv) => {
      const partner = conv.participant.find((p) => p.member.memberId != user.id);
      return partner && partner.member.memberId;
    }, mappedConvs);
    const groupedBySet = mapObjIndexed((convsersations) => {
      const grouped = groupBy((conv) => {
        const programSetLabel = `${conv.programLabel}/${conv.setLabel}`;
        return programSetLabel;
      }, convsersations);
      const mappedGrouped = Object.keys(grouped).map((programSetLabel) => {
        const convs = grouped[programSetLabel];
        const peer = convs[0] && convs[0].partner && convs[0].partner.member;
        const set = sets.find((s) => s.programSetLabel == programSetLabel);

        const datePaired = convs.reduce((result, conv) => {
          const date = conv.me.createdAt;
          if (!result) return date;
          const firstDate = result.me ? result.me.createdAt : result;
          return firstDate > date ? date : firstDate;
        }, null);

        const lastModified = convs.reduce((modified, conv) => {
          if (!modified || conv.me.modifiedAt > modified) return conv.me.modifiedAt;
          return modified;
        }, null);

        const finished = convs.every((conv) => conv.state == states.Finished);

        const dateFinished =
          finished &&
          convs.reduce((result, conv, i) => {
            const date = conv.me.modifiedAt;
            const firstDate = result.me ? result.me.modifiedAt : result;
            return firstDate < date ? date : firstDate;
          });

        const dateCompleteBy = !finished ? moment(datePaired).add(90, 'd').valueOf() : null;

        const archived = dateCompleteBy && moment(dateCompleteBy).isBefore(moment(), 'day');

        return {
          programSetLabel,
          convs: convs
            .sort((a, b) => {
              if (!set || !set.definitions) return 0;
              return (
                set.definitions.findIndex((d) => d.label == a.convLabel) -
                set.definitions.findIndex((d) => d.label == b.convLabel)
              );
            })
            .map((c, i) => ({ ...c, convIInSet: i })),
          peer,
          lastModified,
          finished,
          dateFinished,
          dateCompleteBy,
          datePaired,
          archived,
        };
      });
      const peer = mappedGrouped && mappedGrouped[0] && mappedGrouped[0].peer;
      return {
        sets: sortWith([ascend(prop('finished')), ascend(prop('archived')), descend(prop('datePaired'))])(
          mappedGrouped
        ),
        peer,
      };
    }, groupedByPartner);
    return groupedBySet;
  }, [mappedConvs, user, sets]);

  const peers = useMemo(() => {
    return Object.keys(groupedSetsByPeer)
      .sort((member1, member2) => {
        const set1 = groupedSetsByPeer[member1].sets[0];
        const set2 = groupedSetsByPeer[member2].sets[0];
        return set2.datePaired - set1.datePaired;
      })
      .map((memberId) => {
        const group = groupedSetsByPeer[memberId];

        const mostRecentSet = group.sets.reduce((mostRecent, currentSet) => {
          if (!mostRecent || currentSet.datePaired > mostRecent.datePaired) {
            return currentSet;
          }
          return mostRecent;
        }, null);

        return {
          ...group?.peer,
          datePaired: mostRecentSet.datePaired,
        };
      });
  }, [groupedSetsByPeer]);

  const mostRecentlyPairedPeer = useMemo(() => {
    return peers.reduce((mostRecent, currentPeer) => {
      if (!mostRecent || currentPeer.datePaired > mostRecent.datePaired) {
        return currentPeer;
      }
      return mostRecent;
    }, null);
  }, [peers]);

  const activeConversations = conversations.filter(({ state }) =>
    state === states.Scheduled || state === states.Accepted || state === states.InProgress
  );

  const countActivelyMatchedPeerMemberIds = useCallback(() => {
    const uniqueMemberIds = new Set();

    activeConversations.forEach(c => {
      c.participant.forEach(({ member }) => {
        if (member.memberId !== user?.id) {
          uniqueMemberIds.add(member.memberId);
        }
      });
    });

    return uniqueMemberIds.size;
  }, [activeConversations, user]);
  const activelyMatchedPeerCount = countActivelyMatchedPeerMemberIds();

  const activePeerGroup = useMemo(() => {
    return groupedSetsByPeer[activePeerId];
  }, [activePeerId, groupedSetsByPeer]);

  const activeSetMatched = useMemo(() => {
    return activeSet && (!activeSet.archived && !activeSet.finished);
  }, [activeSet]);

  useEffect(() => {
    const seenTutorial = user?.seenTutorial;
    const undeclared = user && (!user.matchRequest || user.matchRequest.optIn === undefined);
    const sets = Object.values(groupedSetsByPeer).reduce((list, { sets }) => list.concat(sets), []);
    const matchStatusUpdatedAt = user?.matchRequest?.updatedAt;
    const matchStatusUpdatedBeforeFinishedSet = sets.some((set) => {
      return (
        !set.archived && set.dateFinished && dayjs(matchStatusUpdatedAt).isBefore(dayjs(set.dateFinished), 'second')
      );
    });
    if (!seenTutorial) return;
    if (user?.assessmentCompletedAt && !loadingPage) {
      if (
        (!optInSnoozed() && undeclared) || matchStatusUpdatedBeforeFinishedSet
      ) {
        history.push('/match-status');
      }
    }
  }, [groupedSetsByPeer, user, history, loadingPage]);

  useEffect(() => {
    if (!activePeerGroup || !activePeerGroup.peer) return;
    if (activePeerGroup.peer.archivedAt) {
      setPartner(activePeerGroup.peer);
      return;
    }
    setLoadingPartner(true);
    getColleague(activePeerGroup.peer.slug)
      .then((p) => setPartner({ ...activePeerGroup.peer, ...p }))
      .then(
        () => setLoadingPartner(false),
        () => setLoadingPartner(false)
      );
  }, [activePeerGroup]);

  const inActiveConversation = useMemo(() => {
    if (!activePeerGroup) return false;
    const sets = activePeerGroup.sets;
    const set = (sets || []).find((s) => !s.archived || !s.finished);
    const conv = set?.convs.find((c) => states.isBefore(c.state, states.Finished));
    return !!conv;
  }, [activePeerGroup]);

  return (
    <div className="Dashboard page">
      <Tutorial />
      <Header user={user} />
      {user?.isPaused && (
        <div className="Dashboard__paused">
          <i className="fal fa-pause-circle" />
          <span>
            <b>You are currently paused.</b> Click <Link to="/settings/account">here</Link> if you are ready to restart
            peer coaching or feel free to contact <a href="mailto:support@imperative.com">support@imperative.com</a> if
            you need help.
          </span>
        </div>
      )}
      <main className="container-1040" id="main">
        <Loader loading={loadingPage} color="white" />
        {conversations.length > 0 && <h1 className="page-title blue bold">Building a trusted network</h1>}
        {conversations.length == 0 && (
          <h1 className="page-title blue bold">Welcome to Imperative, {user?.firstName}!</h1>
        )}
        <div className="Dashboard__peerCoaching">
          <ProfileCard
            groupedSetsByPeer={groupedSetsByPeer}
            networkStats={networkStats}
            activeConversationSet={activePeerGroup}
            activeSetMatched={activeSetMatched}
          />
          <div className="Dashboard__peerCoachingConversations">
            <PeerNetwork
              groupedSetsByPeer={groupedSetsByPeer}
              activePeerId={activePeerId}
              setActivePeerId={setActivePeerId}
              networkStats={networkStats}
            />
            {conversations.length > 0 && activePeerGroup?.peer && (
              <h3 className="section-head">Your Conversations with {activePeerGroup.peer.firstName}</h3>
            )}
            {conversations.length == 0 && <h3 className="section-head">Building connections</h3>}
            <div className="Dashboard__convos relative">
              {conversations.length > 0 && activePeerGroup?.peer && (
                <SetCardNew
                  key={activePeerGroup.peer.memberId}
                  group={activePeerGroup}
                  sets={sets}
                  user={user}
                  toastVisible={toastVisible}
                  showToast={setToastVisible}
                  setActiveSet={setActiveSet}
                  setActiveConversation={setActiveConversation}
                />
              )}
              {conversations.length == 0 && <GettingStarted user={user} />}
              {conversations.length == 0 && <NetworkGrowing networkStats={networkStats} />}
            </div>
          </div>
        </div>
        <div className="divider horizontal" />
        {user && (
          <Fulfillment loading={loading} user={user} conversations={conversations} networkStats={networkStats} />
        )}
        <div className="divider horizontal" />
        {user && <Purpose user={user} />}
      </main>

      {definitions.length > 0 && conversations.length > 0 && (
        <Competencies t={t} user={user} programs={definitions} conversations={conversations} />
      )}

      <Toast
        className="Dashboard__pauseToast"
        type="info"
        visible={pauseConfirmation || resetConfirmation || unmatched}
        close={() => history.replace('/')}
        timeout={5000}
        title={false}
        position="top"
        autoClose={false}
      >
        <div className="Dashboard__pauseToastContent">
          <i className="fal fa-pause-circle" />
          {pauseConfirmation && (
            <>Thanks! We have received your request to pause. Please check your inbox for confirmation.</>
          )}
          {resetConfirmation && (
            <>Thanks! We have received your request to reset peer coaching. Please check your inbox for confirmation.</>
          )}
          {unmatched && (
            <>You have been unmatched</>
          )}
        </div>
      </Toast>
      <Footer />
      <Scheduler
        groupedSetsByPeer={values(groupedSetsByPeer)}
        sets={sets}
        user={user}
        scheduleUpdated={scheduleUpdated}
      />

      <Route exact path="/match-status">
        <MatchStatus
          inActiveConversation={inActiveConversation}
          mostRecentlyPairedPeer={mostRecentlyPairedPeer}
          activelyMatchedPeerCount={activelyMatchedPeerCount}
        />
      </Route>

      <Route exact path="/unmatch">
        <UnmatchDialog
          partner={activePeerGroup?.peer}
          activeConversationId={activeConversation?.id}
          activelyMatchedPeerCount={activelyMatchedPeerCount}
        />
      </Route>
    </div>
  );
};

export default observer(Dashboard);
