import React, {useCallback, useState} from 'react'
import * as _ from 'ramda'
import {useRouteMatch} from 'react-router-dom'
import {reduxForm, Field} from 'redux-form'
import * as V from 'validate'
import {resetPassword} from 'auth/requests'
import Layout, {Result, Link} from 'auth/Layout'
import {BtnContainer} from 'auth/Form'
import TextFieldWithValidationBullets from 'auth/Form/TextFieldWithValidationBullets'

const getErrors = (value, allValues) => [
  {value: V.required()(value, allValues), message: 'Enter a password please.'},
  {value: V.minlen(8)(value, allValues), inBullets: true, message: '8 characters minimum'},
  {value: V.pattern(/[A-Z]/)(value, allValues), inBullets: true, message: 'One uppercase letter (A-Z)'},
  {value: V.pattern(/[a-z]/)(value, allValues), inBullets: true, message: 'One lowercase letter (a-z)'},
  {value: V.pattern(/[0-9]/)(value, allValues), inBullets: true, message: 'One number (0-9)'},
  {value: V.pattern(V.SPECIAL_CHARS)(value, allValues), inBullets: true, message: 'One special character (eg. ,#@!)'},
];

const PromptForm = ({
  submitting,
  invalid,
  handleSubmit,
  disabled,
}) => {
  const [passwordErrors, setPasswordErrors] = useState(getErrors(undefined, {}));

  const validatePassword = useCallback((value, allValues) => {
    const errors = getErrors(value, allValues);

    setPasswordErrors(errors);

    if (errors.find((e) => e.value !== undefined)) {
      return errors;
    };
    return undefined;
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="password"
        placeholder="Password"
        type="password"
        className="margin-bottom-2"
        component={TextFieldWithValidationBullets}
        validate={validatePassword}
        errors={passwordErrors}
        autocompleteOff
      />
      <BtnContainer>
        <button
          className="btn btn_primary btn_solid_pink margin-top-2"
          disabled={disabled || submitting || invalid}
          onMouseDown={(e) => e.preventDefault()}
          type="submit"
        >
          Update your password
        </button>
      </BtnContainer>
    </form>
  );
};

const Form = reduxForm({form: 'promptForm'})(PromptForm);

const ResetPrompt = () => {
  const [state, setState] = useState(null);

  const match = useRouteMatch();

  const submit = useCallback(((data) => {
    return resetPassword(match.params.token, data)
      .then(
        (res) => {
        setState(res);
        },
        (err) => {
          setState(err);
        }
      )
  }), []);

  const renderSuccess = state === 'success' &&
    <Result>
      Your password has been updated.
      <br />
      {'Please '}
      <Link
        style="pink"
        to="/signin"
      >
        sign in
      </Link>
      .
    </Result>;

  const renderBadToken = state === 'badToken' &&
    <Result>
      Your reset token is invalid.
      <br />
      {'Please, try requesting  '}
      <Link
        style="pink"
        to="/reset"
      >
        password reset
      </Link>
      {' again.'}
    </Result>;

  const render = renderSuccess || renderBadToken || <Form onSubmit={submit} />;

  return (
    <Layout headline="Update your password">
      {render}
    </Layout>
  );
};

export default ResetPrompt;
