class AuthError extends Error
  name: 'Auth.Error'
  _message: -> 'Unknown auth error'
  constructor: (@arguments...) ->
    super(arguments...)
    @message = @_message @arguments...
    if Error.captureStackTrace
      Error.captureStackTrace @, @constructor

class AlreadyRegistered extends AuthError
  name: 'Auth.AlreadyRegistered'
  _message: -> 'Already registered'

class InvalidInvitation extends AuthError
  name: 'Auth.InvalidInvitation'
  _message: -> 'Invalid invitation'

class SeatsLimitExceeded extends AuthError
  name: 'Auth.SeatsLimitExceeded'
  _message: -> 'Seats limit exceeded'

class OrganizationDomainNotFound extends AuthError
  name: 'Auth.OrganizationDomainNotFound'
  _message: -> 'Organization domain not found'

class InvalidInvitationEmail extends AuthError
  name: 'Auth.InvalidInvitationEmail'
  _message: -> 'Invalid invitation email'

class TokenRequired extends AuthError
  name: 'Auth.TokenRequired'
  _message: -> 'Invitation token required'

class UnknownEmailDomain extends AuthError
  name: 'Auth.UnknownEmailDomain'
  _message: -> 'Unknown email domain'

class InvalidRegisterMethod extends AuthError
  name: 'Auth.InvalidRegisterMethod'
  _message: -> 'Invalid register method'

class BadCredentials extends AuthError
  name: 'Auth.BadCredentials'
  _message: -> 'Incorrect login data'

class BadResetToken extends AuthError
  name: 'Auth.BadResetToken'
  _message: -> 'Invalid reset token'

class EmailAlreadyConfirmed extends AuthError
  name: 'Auth.EmailAlreadyConfirmed'
  _message: -> 'Email already confirmed'

class BadConfirmationToken extends AuthError
  name: 'Auth.BadConfirmationToken'
  _message: -> 'Invalid confirmation token'

class SSOAuthFailed extends AuthError
  name: 'Auth.SSOAuthFailed'
  _message: (provider) -> "SSO authentication via #{provider} failed"
  constructor: (provider) ->
    super(provider)
    @provider = provider

class SignInBySSO extends AuthError
  name: 'Auth.SignInBySSO'
  _message: -> 'Please use your organization’s SSO page to register or sign-in into the Imperative Platform.'

class SSONoAccount extends AuthError
  name: 'Auth.SSONoAccount'
  #TODO: proper message
  _message: 'SSO no account'

class SSOMergeFailed extends AuthError
  name: 'Auth.SSOMergeFailed'
  _message: 'SSO merge failed'

class SSOCancelled extends AuthError
  name: 'Auth.SSOCancelled'
  _message: 'SSO cancelled'

class SubscriptionExpired extends AuthError
  name: 'Auth.SubscriptionExpired'
  _message: 'Subscription expired'

class UnknownAuthError extends AuthError
  name: 'Auth.UnknownError'

class AccountInactive extends AuthError
  name: 'Auth.AccountInactive'
  _message: -> 'Account inactive'

export {
  AlreadyRegistered
  InvalidInvitation
  InvalidInvitationEmail
  UnknownEmailDomain
  InvalidRegisterMethod
  BadCredentials
  BadResetToken
  EmailAlreadyConfirmed
  BadConfirmationToken
  OrganizationDomainNotFound
  SeatsLimitExceeded
  SignInBySSO
  SSOAuthFailed
  SSOMergeFailed
  SSONoAccount
  SSOCancelled
  SubscriptionExpired
  UnknownAuthError
  AccountInactive
  TokenRequired
}
