import { snoozeOptIn } from 'api';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RSelect from 'react-select';
import cx from 'classnames';
import { useStore } from 'stores/stores';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import Radio from 'theme/Radio';
import TranslationContext from 'translations';
import './OptIn.scss';
import { requestOptIn, requestOptOut } from './requests.js';

const SELECT_OPTIONS = [
  { value: 'in', label: 'Open to matching' },
  { value: 'out', label: 'Not open to matching' },
];

const END_CONV_OPTIONS = [
  {
    value: true,
    label: (
      <>
        <b>End current conversation</b>
        <span>End current conversation immediately. Do not match me with any new connections.</span>
      </>
    ),
  },
  {
    value: false,
    label: (
      <>
        <b>Continue current conversation</b>
        <span>
          Continue conversation with current partner until the set is completed. Do not match me with any new
          connections.
        </span>
      </>
    ),
  },
];

const MatchStatus = ({ inActiveConversation = false, afterClose: closeClb }) => {
  const history = useHistory();
  const t = useContext(TranslationContext);
  const { userStore, loading: userLoading } = useStore();
  const { user } = userStore;

  const rSelectRef = useRef(null);

  const afterClose = useCallback(() => {
    if (closeClb) closeClb();
    else history.push('/');
  }, [history, closeClb]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null); //['in', 'out']
  const [reason, setReason] = useState(undefined);
  const [reasonVisible, setReasonVisible] = useState(false);
  const [endConversations, setEndConversations] = useState(undefined);

  const optInResponse = useMemo(() => {
    const { optIn } = user?.matchRequest || {};
    if (optIn === true) return 'open';
    if (optIn === false) return 'notOpen';
    return undefined;
  }, [user]);

  const reasons = useMemo(() => {
    return t.getSplitted('settings.pause.reasons').map((r) => ({ label: r, value: r }));
  }, [t]);

  const saveChanges = useCallback(() => {
    if (!response) return;

    setLoading(true);

    const req =
      response === 'in'
        ? requestOptIn({ memberSlug: user.slug, endConversations })
        : requestOptOut({ memberSlug: user.slug, reason, endConversations });

    req
      .then(() =>
        setTimeout(() => {
          userStore.getUser().then(() => {
            setLoading(false);
            afterClose();
          });
        }, 1000)
      )
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [user, response, reason, endConversations, afterClose]);

  const handleChange = useCallback(
    (option) => {
      if (optInResponse === undefined || !inActiveConversation) {
        setReasonVisible(true);
      } else {
        setReasonVisible(false);
      }
      setResponse(option.value);
      setEndConversations(undefined);
      setReason(undefined);
    },
    [inActiveConversation]
  );

  const close = useCallback(() => {
    snoozeOptIn();
    afterClose();
  }, [afterClose]);

  useEffect(() => {
    if (reasonVisible) {
      if (rSelectRef.current) rSelectRef.current.focus();
    }
  }, [reasonVisible]);

  return (
    <Modal isOpen close={close} className="MatchStatus">
      <div>
        <Loader loading={loading || userLoading} color="white" />
        <h1>Update Match Status</h1>
        <p className="p-new">
          Your match status reflects whether or not you are ready to be introduced to a new connection on Imperative.
        </p>
        <p className="p-new">
          If you are ready for a new connection, select “open to matching” and you'll be matched as soon as we find the
          right match.
        </p>
        <div className="p-new">
          <h2>Current match status</h2>
          <span className={cx('MatchStatus__current', optInResponse)}>
            {!optInResponse && 'Undeclared'}
            {optInResponse == 'open' && 'Open to matching'}
            {optInResponse == 'notOpen' && 'Not open to matching'}
          </span>
        </div>
        <fieldset className="p-new">
          <legend className="MatchStatus__legend">Select your new match status</legend>
          <RSelect
            inputId="match_status"
            className="react-select MatchStatus__select"
            classNamePrefix="MatchStatus__select react-select"
            options={SELECT_OPTIONS}
            onChange={handleChange}
            value={SELECT_OPTIONS.find((o) => o.value == response)}
            placeholder="Select match status"
            autoBlur
            isSearchable={false}
            ref={rSelectRef}
          />
        </fieldset>
        {response && response === 'out' && !reasonVisible && (
          <fieldset className="p-new">
            <legend>
              <h2>Do you want to end your current conversation or continue until all sets are complete?</h2>
            </legend>
            <Radio
              id="matchStatusEndConversation"
              options={END_CONV_OPTIONS}
              onChange={setEndConversations}
              value={endConversations}
            />
          </fieldset>
        )}
        {response === 'out' && reasonVisible && (
          <fieldset className="p-new">
            <legend className="MatchStatus__legend">Select reason</legend>
            <RSelect
              inputId="match_status_reason"
              className="react-select MatchStatus__select"
              classNamePrefix="MatchStatus__select react-select"
              options={reasons}
              onChange={(o) => setReason(o.value)}
              value={reasons.find((o) => o.value == reason)}
              placeholder="Please select"
              autoBlur
            />
          </fieldset>
        )}

        <div className="MatchStatus__footer">
          <p className="caption-new">
            If you need additional help, please reach out to{' '}
            <a className="link" href="mailto:support@imperative.com">
              support@imperative.com
            </a>
          </p>
          <button className="btn-secondary" onClick={close}>
            Cancel
          </button>
          {(reasonVisible || response === 'in') && (
            <button className="btn-primary" onClick={saveChanges} disabled={response === 'out' && !reason}>
              Save changes
            </button>
          )}
          {response === 'out' && !reason && (
            <button
              className="btn-primary"
              onClick={() => setReasonVisible(true)}
              disabled={endConversations === undefined}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MatchStatus;
