import r from 'r-dom'
import T from 'tcomb'
import cx from 'classnames'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import {defaultProps, withProps, lifecycle} from 'recompose'
import ReactSlider from 'react-slider'

import './Slider.sass'

LABELS = [
  'Strongly Disagree'
  'Moderately Disagree'
  'Slightly Disagree'
  'Neutral'
  'Slightly Agree'
  'Moderately Agree'
  'Strongly Agree'
]

LABELS_MAP =
  'Strongly Disagree': 'assessment.general.slider1'
  'Moderately Disagree': 'assessment.general.slider2'
  'Slightly Disagree': 'assessment.general.slider3'
  'Neutral': 'assessment.general.slider4'
  'Slightly Agree': 'assessment.general.slider5'
  'Moderately Agree': 'assessment.general.slider6'
  'Strongly Agree': 'assessment.general.slider7'

MIN = -3
DEFAULT_VALUE = 0


export default composeComponent 'Assessment.Slider',
  defaultProps
    labels: LABELS
    min: MIN
    defaultValue: DEFAULT_VALUE
  setPropTypes
    labels: T.list T.String
    min: T.Number
    onChange: T.Function
    value: T.maybe T.Number
    defaultValue: T.Number
    question: T.Any

  withProps ({options}) ->
    sliderOptions: if options.length > 1 then options

  lifecycle
    componentDidMount: ->
      if !@props.value?
        @props.onChange(
          if @props.sliderOptions
            @props.sliderOptions[Math.floor(@props.sliderOptions.length / 2)]?.id
          else @props.defaultValue
        )

  withProps ({labels, min, value, defaultValue, t, question, options, sliderOptions}) ->
    min: if sliderOptions then sliderOptions[0]?.id else min
    max: if sliderOptions then sliderOptions[sliderOptions.length - 1]?.id else labels.length + min - 1
    labels: if sliderOptions then sliderOptions.map((o) -> t.find "surveys.purpose-assessment.questions.#{question.id}.options.#{o.id}") else labels
    value: value ? defaultValue
    dir: document.body.dir
    sliderOptions: sliderOptions

  ({onChange, labels, min, max, value, className, options, dir, question, t, sliderOptions}) ->
    Thumb = (props) =>
      r.div Object.assign {}, props, className: cx "AssessmentSlider__thumb", flip: dir is 'rtl'

    r.div className: className, [
      if options.length == 1
        r.p className: "AssessmentSlider__message", t.find "surveys.purpose-assessment.questions.#{question.id}.options.#{options?[0]?.id}"
      r.div className: "AssessmentSlider__sliderContainer", [
        r.div className: "AssessmentSlider__labelContainer",
          r.div
            className: "AssessmentSlider__label"
            style:
              if !dir or dir is 'ltr'
                left: "#{(value - min) / (max - min) * 100}%"
              else right: "#{(value - min) / (max - min) * 100}%"
            r.div className: cx("AssessmentSlider__labelText", flip: dir is 'rtl'),
              r.span {"aria-hidden": "true"},
                if sliderOptions
                  labels[value - min]
                else t.find LABELS_MAP[labels[value - min]]

        r.div className: "AssessmentSlider__notchContainer",
          [min .. max - 1].map -> r.div className: cx "AssessmentSlider__notch", flip: dir is 'rtl'

        r ReactSlider,
          className: "AssessmentSlider__slider"
          renderThumb: Thumb
          id: "Assessment__option#{options?[0]?.id}"
          value: value
          invert: dir is 'rtl'
          min: min
          max: max
          onChange: onChange
          ariaLabelledby:
            if sliderOptions
              labels[value - min]
            else labels.map (l) -> t.find(LABELS_MAP[l])
          ariaValuetext: ->
            if sliderOptions
              labels[value - min]
            else t.find LABELS_MAP[labels[value - min]]
      ]
    ]
