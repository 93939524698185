import { isLoggedIn } from 'api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import cx from 'classnames';
import qs from 'qs';
import { fetchNotSeenEventsCount } from 'Community/requests';
import SkipLink from 'theme/SkipLink';
import { useStore } from 'stores';
import Dropdown from './Dropdown';
import './Header.sass';
import NavItem from './NavItem';
import User from './User';
import * as Logos from './logos';

const config = window.config;

const Header = (props) => {
  const { className, cpl = false, logo = true, children, withUser = true, transparent = false, queryParams } = props;

  const { userStore } = useStore();
  const { roles } = userStore;

  const location = useLocation();
  const [menuOpened, openMenu] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [newActivity, setNewActivity] = useState(0);

  const rtl = useMemo(() => {
    return document.body.dir === 'rtl';
  }, []);

  const user = useMemo(() => {
    if (withUser) {
      return userStore.user;
    }
    return null;
  }, [userStore.user, withUser]);

  useEffect(() => {
    setAvatar(user && user.avatars && user.avatars.normal);
  }, [user]);

  const loggedIn = isLoggedIn();

  useEffect(() => {
    loggedIn && loadCommunityNotifications();
  }, [location, loggedIn]);

  const loadCommunityNotifications = () => {
    return fetchNotSeenEventsCount()
      .then((notificationsCount) => {
        setNewActivity(notificationsCount.myActivitiesCommentsCount + notificationsCount.myActivitiesReactionsCount);
      })
      .catch((err) => {
        if (err.status == 404 && err.data && err.data.message == 'Notification timestamp not found') {
          return Promise.resolve();
        }
      });
  };

  const enabledPeerCoaching = useMemo(() => {
    if (!user) return false;
    return user.organizationSettings && user.organizationSettings.partnerConversationsSettings.enabled;
  }, [user]);

  const NavItemWithCPL = (props) => (
    <NavItem {...props} url={props.external ? props.url : mapUrl(props.url)} color={cpl ? 'pink' : 'blue'} />
  );

  const mapUrl = useCallback(
    (url) => {
      if (queryParams)
        return {
          pathname: url,
          search: qs.stringify(queryParams, { addQueryPrefix: true }),
        };
      return url;
    },
    [queryParams]
  );

  const changeAvatar = (file) => {
    const fd = new FormData();
    fd.append('image', file);

    return userStore.changeAvatar(fd).then((avatarUrl) => {
      setAvatar(avatarUrl);
    });
  };

  const AdminLinks = ({ depth = 0 }) =>
    user && cpl && !children ? (
      <>
        <NavItemWithCPL
          title="Individuals"
          url={`${config.dashboard.address}/cpl/invitations`}
          external
          depth={depth}
        />
        <NavItemWithCPL title="Teams" url={`${config.dashboard.address}/cpl/teams`} external depth={depth} />
        <NavItemWithCPL title="Workshops" url={`${config.dashboard.address}/cpl/workshops`} external depth={depth} />
        {enabledPeerCoaching && (
          <NavItemWithCPL title="Peer Coaching" url="/peer-coaching" depth={depth}>
            <NavItemWithCPL title="Conversations" url="/peer-coaching/manage" />
            <NavItemWithCPL title="Conversation Status" url="/peer-coaching/conversations" />
            <NavItemWithCPL title="Cohorts" url="/peer-coaching/user-groups" />
            <NavItemWithCPL title="Analytics" url="/peer-coaching/analytics" />
          </NavItemWithCPL>
        )}
        <NavItemWithCPL title="Analytics" url={`${config.dashboard.address}/cpl/analytics`} external depth={depth} />
      </>
    ) : (
      <></>
    );

  const communityLabel = (
    <>
      {newActivity > 0 && (
        <div className="Header__notification">
          <span className="fal fa-comment-lines" />
        </div>
      )}
      <div className="Header__label">New</div>
    </>
  );

  const UserLinks = ({ depth = 0 }) =>
    user && !cpl && !children ? (
      <>
        <NavItemWithCPL
          title="Home"
          url="/"
          exact
          active={location.pathname.indexOf('/match-status') == 0 || location.pathname.indexOf('/unmatch') == 0}
          depth={depth}
        />
        <NavItemWithCPL title="My Purpose" url="/purpose-profile" exact depth={depth} />
        {user.organizationSettings &&
          user.organizationSettings.communityPageSettings &&
          user.organizationSettings.communityPageSettings.communityPageEnabled && (
            <NavItemWithCPL title="My Community" url="/community" depth={depth} label={communityLabel} />
          )}
      </>
    ) : (
      <></>
    );

  const mobileMenuTrigger = ({ onClick }) => (
    <button className="btnLink" onClick={onClick}>
      <i className="fas fa-ellipsis-v" title="Menu" />
    </button>
  );

  return (
    <header className={cx('Header', className, { cpl, transparent })} id="Header__main" role="banner">
      <SkipLink />
      <div className="Header__content" dir={rtl ? 'rtl' : null}>
        {user && (
          <Dropdown trigger={mobileMenuTrigger} className="Header__burger">
            <UserLinks depth={1} />
            <AdminLinks depth={1} />
          </Dropdown>
        )}
        {logo && (
          <Link to="/" className="Header__logoLink">
            {transparent && (
              <>
                <img src={Logos.white} className="Header__logo desktop" aria-label="Imperative" />
                <img src={Logos.blue} className="Header__logo mobile" aria-label="Imperative" />
              </>
            )}
            {!cpl && !transparent && <img src={Logos.blue} className="Header__logo" aria-label="Imperative" />}
            {cpl && !transparent && <Logos.pink className="Header__logo" aria-label="Imperative" />}
          </Link>
        )}
        <div className="Header__mobileSeparator" />
        {user && !cpl && !children && (
          <nav role="navigation" className={cx('Header__nav', { visible: menuOpened })}>
            <UserLinks />
          </nav>
        )}
        {user && cpl && !children && (
          <nav role="navigation" className={cx('Header__nav', { visible: menuOpened })}>
            <AdminLinks />
          </nav>
        )}
        {children && (
          <nav role="navigation" className={cx('Header__nav', { visible: menuOpened })}>
            {children}
          </nav>
        )}
        {user && (
          <User user={user} color={cpl ? 'pink' : 'blue'} changeAvatar={changeAvatar} avatar={avatar} roles={roles} />
        )}
      </div>
    </header>
  );
};

export default observer(Header);
