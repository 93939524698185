module.exports =
  ANIMALS: [
    'crock'
    'bear'
    'lion'
    'dolphin'
    'duck'
    'chick'
    'elephant'
    'commonfox'
    'giraffe'
    'lizard'
    'sheep'
    'octopus'
    'robin'
    'panda'
    'penguin'
    'pig'
    'bunny'
    'rhino'
    'seal'
    'sloth'
    'tiger'
    'beaver'
    'fox'
    'zebra'
  ]

  DRIVERS:
    who: [
      "Individual"
      "Organization"
      "Society"
    ]
    why: [
      "Karma"
      "Harmony"
    ]
    how: [
      "Human"
      "Community"
      "Structure"
      "Knowledge"
    ]

  DRIVERS_OVERVIEW_QUESTIONS:
    who: "Impact: Who do you want to impact?"
    why: "Values: Why is change needed?"
    how: "Craft: How do you like to solve problems?"

  AGENDA:
    title: "In this report"
    items: ({leadershipVisibility = false, volunteeringVisibility = false} = {}) ->
      [
        title: "Your In-depth Purpose Results"
        subitems: [
          "Purpose Type"
          "Drivers"
        ]
      ,
        title: "Your Purpose in Action"
        subitems: [
          "Leadership Style" if leadershipVisibility
          "Volunteering + Philanthropy" if volunteeringVisibility
          "Potential Biases"
        ]
      ]

  ACTIVATE_PURPOSE: [
      title: "Utilize"
      subtitle: "the online tools to help you work with purpose:"
      items: [
        "Use the “Imperative Statement” builder to deepen understanding of your purpose."
        "Print your Superhero poster (pick your favorite animal!) and post it at your desk."
        "Find colleagues and connect on the platform."
      ]
    ,
      title: "Strive"
      subtitle: "for deeper understanding and take action:"
      items: [
        "Journal to capture examples of when you feel fulfilled, and ideas for applying your drivers."
        "Share your profile with your managers, collaborators and colleagues so they can know how to help you stay motivated, get feedback and feel recognized."
        "Consciously align your daily work, goals, projects, and tasks with your drivers."
      ]
    ,
      title: "Check"
      subtitle: "yourself regularly on potential biases:"
      items: [
        "Pick one potential bias to keep top of mind every week. Start with your Dominant Driver."
        "Find someone with contrasting drivers to explore worldviews. Determine tactics for helping each other work with purpose."
      ]
    ,
      title: "Exercise"
      subtitle: "your Purpose Power-ups for maximum effect:"
      items: [
        "Rate your Purpose Power-ups from 1-3 within each driver (1 = opportunity to improve, 3 = already strong), with special attention to your Dominant driver."
        "Include any that you have prioritized for improvment in your Development Plan."
      ]
  ]

  INFO:
    paragraphs: [
      """
      The Imperative Personal Purpose Profile has been used by people around the globe and across every industry to
      gain insights into how they derive and create meaning, motivation, and fulillment in their careers.
      """
      """
      The assessment that generated this report was developed in collaboration with academics and employers to be a
      valid instrument to develop your self-awareness about what creates meaning in your work as well as specifc
      recommendations on how to design your work intentionally to thrive.
      """
      """
      You have one of 24 different purpose types - combinations of the three dimensions. Your results and recommendations
      are even more specific for your given dominant driver, the one where you had the strongest preference. There are
      216 different Personal Purpose Profiles generated by the assessment including yours.
      """
      """
      The results are based on identifying your preferences within three dimensions of purpose with a total of nine drivers
      between them. Your preference for a given driver falls on a continuum between the different options. The one that
      is showing up on your report under each dimension is the one for which you expressed the highest preference.
      """
      """
      The reliability of the results is dependent on the strength of your preference within each dimension. Your dominant
      driver is likely to not change during your career but the other drivers might depending on your degree of preference.
      """
      """
      This is not a personality assessment and there is no correlation between your personality (e.g. being introverted
      or extroverted) and your purpose. This assessment is designed to help define purpose for a range of workforce
      orientations and help guide people regardless of their embrace of purpose overall.
      """
    ]
    agenda:
      title: "All rights reserved"
      items: [
          title: """
          No part of this publication may be reproduced, distributed, or transmitted in any form or by any
          means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of
          the publisher, except inthe case of brief quotations embodied in critical reviews and certain other non-commercial uses permitted by copyright law.
          """
        ,
          title: "Requests for permission may be sent to info@imperative.com"
      ]

  LEADERSHIP:
    paragraphs: [
      """
        You realize your potential as an authentic leader when your voice and actions are grounded in purpose.
      """
      """
        There are three types of purpose-driven leaders. This section reveals which is yours and provides the key insights
        to help you to act with courage and find your strongest and most inspiring voice.
      """
      """
        Purpose based leadership is you at your best.
      """
    ]
    drivers:
      who:
        title: "“Impact Driven Leader”"
        info:
          """
            These leaders assess their performance as well as their colleagues’ on the
            impact of their work. Making an impact is their bottom line. They
            inspire us to always be customer centric and recall why we do what we do.
          """
      why:
        title: "“Values Driven Leader”"
        info:
          """
            These leaders find their strength and voice in seeking to “do the right
            thing” and put that before everything else. They judge themselves and
            their colleagues first and foremost on integrity and inspire us to be our best and most principled selves.
          """
      how:
        title: "“Craft Driven Leader”"
        info:
          """
            When a leader is anchored to craft, quality is the measuring stick for
            success. Doing things properly and producing high quality work is the goal
            of every effort. They inspire us to do our best work and master our craft.
          """
