import React from 'react';
import * as _ from 'ramda'
import Chart from './PercentChart'

const RoleStats = ({t, roleStats, user, functions, setFocusRef}) => {
  const dominant = user && user.pattern && user.pattern.dominant.toLowerCase();

  const role = user && user.jobInformation && roleStats && roleStats.find(r => r.jobFunction === user.jobInformation.jobFunction);

  const percentage =  role && Math.round(_.sum(_.values(role.dominantDrivers[dominant])) * 1000) / 10;

  const roleName = role && functions && functions.find (f => f === role.jobFunction);
  const roleTranslation = t.find(`dictionaries.jobfunction.${roleName}.name`);

  return (
    <section className="Assessment__roleStats">
      <Chart
        className="chart"
        data={percentage}
      />
      <div>
        <p className="font-w-bold text-left margin-bottom-3" tabIndex="-1" ref={setFocusRef}>
          {`${t.find('assessment.page.role_stats.title1')} ${Math.round(percentage)}% ${t.find('assessment.page.role_stats.title2')} ${roleTranslation} ${t.find('assessment.page.role_stats.title3')}:`}
        </p>
        <div className="Assessment__roleStatsItem">
          <div className="Assessment__roleStatsLogo"/>
          <p>{t.find('assessment.page.role_stats.result.relationships')}</p>
        </div>
        <div className="Assessment__roleStatsItem">
          <div className="Assessment__roleStatsLogo"/>
          <p>{t.find('assessment.page.role_stats.result.impact')}</p>
        </div>
        <div className="Assessment__roleStatsItem">
          <div className="Assessment__roleStatsLogo"/>
          <p>{t.find('assessment.page.role_stats.result.growth')}</p>
        </div>
        <p className="Assessment__caption">Imperative/LinkedIn Global Workforce Purpose Index</p>
      </div>
    </section>
  );
};

export default RoleStats;
