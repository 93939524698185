`/** @jsx R.DOM */`
import R from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import {replace, pickBy, pluck, values, find, propEq, prop} from 'ramda'
import Markdown from './Markdown'
import t from 'translations'
import {URL_DRIVERS} from 'theme/assets/assets'
import griffith from './custom-griffith'

import {DRIVER} from './content'


export default ({drivers, org, user, t}) ->
  {who, why, how} = drivers
  orgDrivers = pickBy ((k, v) -> v in pluck('id', values drivers).map (id) -> id.toLowerCase()), org?.purposeOverview?.drivers
  isGriffith = org.code.includes 'griffith'
  code = JSON.parse(sessionStorage.getItem('imperative-language-user-data'))?.code

  R.createElement("div", {"className": "ShortReport__driverContainer"},
    (if who and why
      ['who', 'why'].map (driver) ->
        R.createElement("div", {"className": "ShortReport__driver ShortReport__driver_#{driver}", "key": (driver)},
          R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_uppercase ShortReport__subtitle_smaller ShortReport__subtitle_#{driver}"},
            (if driver is 'who' then t.find 'report.shortReport.driver.subtitle.Impact' else t.find 'report.shortReport.driver.subtitle.Values')
          ),
          R.createElement("p", {"className": "ShortReport__text"},
            (t.find "report.shortReport.driver.#{driver}.description")
          ),
          R.createElement("div", {"className": "ShortReport__row ShortReport__row_margin-top"},
            R.createElement("div", {"className": (cx "ShortReport__columnWide ShortReport__columnWideOverview", wide: !isGriffith and !orgDrivers[drivers[driver]?.id.toLowerCase()]?.length > 0)},
              R.createElement("img", { \
                "className": "ShortReport__driverIcon ShortReport__driverIcon_medium ShortReport__driverIcon_top",  \
                "src": "#{URL_DRIVERS}/#{driver}#{user?.pattern[driver]}.png",  \
                "alt": (user?.pattern[driver])
              }),
              R.createElement("div", {"className": (cx "ShortReport__driverColumn ShortReport__driverColumnOverview ShortReport__driver_#{driver}", wide: !isGriffith and !orgDrivers[drivers[driver]?.id.toLowerCase()]?.length > 0)},
                R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_uppercase ShortReport__subtitle_smaller ShortReport__subtitle_#{driver}"},
                  (drivers[driver].title)
                ),
                R.createElement("p", {"className": "ShortReport__text ShortReport__text_condensed"},
                  (drivers[driver].explication)
                )
              )
            ),
            (if isGriffith
              R.createElement("div", {"className": "ShortReport__columnNarrow ShortReport__columnNarrowOverview"},
                R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_smaller ShortReport__subtitle_#{driver}"},
                  (t.find 'report.shortReport.at'), " ", (org?.name)
                ),
                  R.createElement(Markdown, {"source": (griffith[code]?.drivers[user?.pattern[driver].toLowerCase()])})
              )
            else if Object.keys(orgDrivers).length is 2 and orgDrivers[drivers[driver].id.toLowerCase()]?.length > 0

              R.createElement("div", {"className": "ShortReport__columnNarrow ShortReport__columnNarrowOverview"},
                R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_smaller ShortReport__subtitle_#{driver}"},
                  (t.find 'report.shortReport.at'), " ", (org?.name)
                ),
                R.createElement("div", {"className": "ShortReport__text"},
                  R.createElement(Markdown, {"source": (t.findCustom "org.custom.purpose.drivers.#{drivers[driver].id}", orgDrivers, drivers, driver)})
                )
              )
            )
          )
        )
    else if how
      R.createElement("div", {"className": "ShortReport__driver ShortReport__driver_how"},
        R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_uppercase ShortReport__subtitle_smaller ShortReport__subtitle_how"},
          (t.find 'report.shortReport.driver.subtitle.Craft')
        ),
        R.createElement("p", {"className": "ShortReport__text"},
          (t.find "report.shortReport.driver.how.description")
        ),
        R.createElement("div", {"className": "ShortReport__row ShortReport__row_margin-top"},
          R.createElement("div", {"className": (cx "ShortReport__columnWide ShortReport__columnWideOverview", wide: !isGriffith and !orgDrivers[drivers['how']?.id.toLowerCase()]?.length > 0)},
            R.createElement("img", { \
              "className": "ShortReport__driverIcon ShortReport__driverIcon_medium ShortReport__driverIcon_top",  \
              "src": "#{URL_DRIVERS}/how#{user?.pattern.how}.png",  \
              "alt": (user?.pattern.how)
            }),
            R.createElement("div", {"className": (cx "ShortReport__driverColumn ShortReport__driverColumnOverview ShortReport__driver_how", wide: !isGriffith and !orgDrivers[drivers['how']?.id.toLowerCase()]?.length > 0)},
              R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_uppercase ShortReport__subtitle_smaller ShortReport__subtitle_how"},
                (drivers.how.title)
              ),
              R.createElement("p", {"className": "ShortReport__text ShortReport__text_condensed"},
                (drivers.how.explication)
              )
            )
          ),

          (if isGriffith
            R.createElement("div", {"className": "ShortReport__columnNarrow ShortReport__columnNarrowOverview"},
              R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_smaller ShortReport__subtitle_how"},
                (t.find 'report.shortReport.at'), " ", (org?.name)
              ),
                R.createElement(Markdown, {"source": (griffith[code]?.drivers[user?.pattern.how.toLowerCase()])})
            )
          else if Object.keys(orgDrivers).length and orgDrivers[drivers['how'].id.toLowerCase()]?.length > 0

            R.createElement("div", {"className": "ShortReport__columnNarrow ShortReport__columnNarrowOverview"},
              R.createElement("h2", {"className": "ShortReport__subtitle ShortReport__subtitle_smaller ShortReport__subtitle_how"},
                (t.find 'report.shortReport.at'), " ", (org?.name)
              ),
              R.createElement("div", {"className": "ShortReport__text"},
                R.createElement(Markdown, {"source": (t.findCustom "org.custom.purpose.drivers.#{how.id}", orgDrivers, drivers, 'how')})
              )
            )
          )
        )
      )
    )
  )
