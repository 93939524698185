import moment from 'moment';
import { ascend, descend, groupBy, mapObjIndexed, prop, sortWith } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import * as ME from 'api/metrics';
import { fetchUser, updateUser } from 'auth/requests';
import { getConversationsDefinitions, getUserConversations } from 'conversations/requests';
import MatchDialog from 'opt-in/MatchDialog';
import { deleteMember } from 'settings/requests';
import Loader from 'theme/Loader';
import Toggler from 'theme/Toggler';
import * as states from 'utils/states';
import PauseModal from './PauseModal';
import './Settings.sass';

const Account = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingPause, setLoadingPause] = useState(false);
  const [loadingPrivacy, setLoadingPrivacy] = useState(false);
  const [pauseOpened, openPause] = useState(false);
  const [definitions, setDefinitions] = useState([]);
  const [conversations, setConversations] = useState([]);
  const history = useHistory();

  const getUser = () => {
    return fetchUser().then(
      (u) => {
        setUser(u);
        return u;
      },
      (error) => ME.reportIntermediateError('settings-account-loading_user-error', error)
    );
  };

  const loadDefinitions = () => {
    return getConversationsDefinitions().then(setDefinitions, (error) => {
      ME.reportIntermediateError('Settings: loading conversation definitions', error);
      setError(error);
    });
  };
  const loadConversations = () => {
    return getUserConversations().then(setConversations, (error) => {
      ME.reportIntermediateError('Settings: loading conversations error', error);
      setError(error);
    });
  };

  useEffect(() => {
    setLoading(true);
    getUser().then((u) =>
      Promise.all([loadDefinitions(u), loadConversations(u)]).then(
        () => setLoading(false),
        () => setLoading(false)
      )
    );
  }, []);

  const onDelete = () => {
    if (!window.confirm(`Are you sure you want to delete ${user.firstName} ${user.lastName}'s account?`)) {
      return;
    }
    deleteMember(user.slug).then(() => history.push('/logout'));
  };

  const updateActivtyVisibility = (enabled) => {
    setLoadingPrivacy(true);
    updateUser({ activityVisibilityEnabled: enabled }).then(
      () => {
        getUser();
        setLoadingPrivacy(false);
      },
      () => {
        setLoadingPrivacy(false);
      }
    );
  };

  const mappedConvs = useMemo(
    () =>
      user
        ? conversations
            .filter((conv) => conv.state != states.Rejected && conv.participant.length > 1)
            .map((conv) => {
              const partner = conv.participant.find((p) => p.member.memberId != user.id);
              const me = conv.participant.find((p) => p.member.memberId == user.id);
              const [programLabel, setLabel, convLabel] = conv.label.split('/');
              return { ...conv, setLabel, programLabel, convLabel, partner, me };
            })
        : [],
    [user, conversations]
  );

  const groupedSetsByPeer = useMemo(() => {
    const groupedByPartner = groupBy((conv) => {
      const partner = conv.participant.find((p) => p.member.memberId != user.id);
      return partner && partner.member.memberId;
    }, mappedConvs);
    const groupedBySet = mapObjIndexed((convs) => {
      const grouped = groupBy((conv) => {
        const programSetLabel = `${conv.programLabel}/${conv.setLabel}`;
        return programSetLabel;
      }, convs);
      const mappedGrouped = Object.keys(grouped).map((programSetLabel) => {
        const convs = grouped[programSetLabel];
        const peer = convs[0] && convs[0].partner && convs[0].partner.member;

        const datePaired = convs.reduce((result, conv) => {
          const date = conv.me.createdAt;
          const firstDate = result.me ? result.me.createdAt : result;
          return firstDate > date ? date : firstDate;
        });

        const lastModified = convs.reduce((modified, conv) => {
          if (!modified || conv.me.modifiedAt > modified) return conv.me.modifiedAt;
          return modified;
        }, null);

        const finished = convs.every((conv) => conv.state == states.Finished);

        const dateFinished =
          finished &&
          convs.reduce((result, conv, i) => {
            const date = conv.me.modifiedAt;
            const firstDate = result.me ? result.me.modifiedAt : result;
            return firstDate < date ? date : firstDate;
          });

        const dateCompleteBy = !finished ? moment(datePaired).add(90, 'd').valueOf() : null;

        const archived = dateCompleteBy && moment(dateCompleteBy).isBefore(moment(), 'day');

        return {
          programSetLabel,
          convs,
          peer,
          lastModified,
          finished,
          dateFinished,
          dateCompleteBy,
          datePaired,
          archived,
        };
      });
      const peer = mappedGrouped && mappedGrouped[0] && mappedGrouped[0].peer;
      return {
        sets: sortWith([ascend(prop('finished')), ascend(prop('archived')), descend(prop('datePaired'))])(
          mappedGrouped
        ),
        peer,
      };
    }, groupedByPartner);
    return groupedBySet;
  }, [mappedConvs, user]);

  const inActiveConversation = useMemo(() => {
    const sets = Object.values(groupedSetsByPeer).reduce((result, group) => result.concat(group.sets), []);
    const set = (sets || []).find((s) => !s.archived && !s.finished);
    const conv = set && set.convs.find((c) => states.isBefore(c.state, states.Finished));
    return !!conv;
  }, [groupedSetsByPeer]);

  const pauseDates = useMemo(() => {
    if (!user || !user.pauseInformation) return {};
    return {
      startDate: user.isPaused ? user.pauseInformation.pauseStartDate : user.pauseInformation.pauseScheduleStartDate,
      endDate: user.isPaused ? user.pauseInformation.pauseEndDate : user.pauseInformation.pauseScheduleEndDate,
    };
  }, [user]);

  const hasPauseDates = useMemo(() => {
    return pauseDates.startDate || pauseDates.endDate;
  }, [pauseDates]);

  return (
    <div className="Settings__account">
      <div className="Settings__title">
        <h1 className="page-title">Manage account</h1>
      </div>

      <div className="relative">
        <Loader loading={loading} color="white" />
        {user && user.organizationSettings && user.organizationSettings.pauseSettings.isMemberPauseEnabled && (
          <>
            {hasPauseDates && (
              <div className="Settings__section">
                <h2 className="section-head">Your scheduled pause</h2>
                {user.isPaused && (
                  <div className="warning">
                    <i className="fas fa-exclamation-triangle" />
                    <b>Pause is ON.</b> Peer coaching is currently paused for your account
                  </div>
                )}
                <p>
                  You have chosen to pause your peer coaching from {moment(pauseDates.startDate).format('DD MMM YYYY')}{' '}
                  to {moment(pauseDates.endDate).format('DD MMM YYYY')}. If you need to modify or cancel your pause, you
                  can do so below.
                </p>
                <PauseModal
                  user={user}
                  open={openPause}
                  opened={pauseOpened}
                  pause={updatePause}
                  groupedConversations={groupedSetsByPeer}
                  inline
                  cancel={unpause}
                />
              </div>
            )}
            {!hasPauseDates && user.isPaused && (
              <div className="Settings__section">
                <h2 className="section-head">Restart peer coaching</h2>
                <div className="warning">
                  <i className="fas fa-exclamation-triangle" />
                  <b>Pause is ON.</b> Peer coaching is currently paused for your account
                </div>
                <p>
                  Ready to jump back in to peer coaching? You will be matched with a peer coach in the next round of
                  introductions.
                </p>
                <button className="btn-primary Settings__restart" onClick={unpause} disabled={loadingPause}>
                  <Loader loading={loadingPause} size="1" />
                  Restart peer coaching
                </button>
              </div>
            )}
            {user.organizationSettings && user.organizationSettings.optInEnabled && (
              <div className="Settings__section">
                <h2 className="section-head">Updating Your Match Availability</h2>
                <p>Your match status reflects whether you are open to new matches or unavailable.</p>
                <p>If you are ready to update your match status, you can always do so here.</p>
                <Link className="btn-secondary btn-inline-block margin-top-2" to="/settings/account/match-status">
                  Update Match Status
                </Link>
              </div>
            )}
          </>
        )}
        {user &&
          user.organizationSettings &&
          user.organizationSettings.communityPageSettings &&
          user.organizationSettings.communityPageSettings.communityPageEnabled && (
            <div className="Settings__section">
              <h2 className="section-head">Manage privacy</h2>
              <div className="Settings__togglerField">
                <p>
                  Share your peer coaching accomplishments with your community and allow others to celebrate you. Your
                  organization’s community on Imperative can see your milestones and achievements.
                </p>
                <div className="relative">
                  <Loader loading={loadingPrivacy} color="white" size="1" />
                  <Toggler
                    checked={user.activityVisibilityEnabled}
                    onChange={updateActivtyVisibility}
                    disabled={loadingPrivacy}
                    ariaLabels={{
                      on: 'Manage Privacy toggle button, pressed',
                      off: 'Manage Privacy toggle button, not pressed',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        {user && (
          <div className="Settings__section">
            <h2 className="section-head">Delete account</h2>
            <p>
              You may request permanent deletion of your account at any time. Please consider the following common
              problems and their solutions before continuing:
            </p>
            <ul>
              <li>
                Taking a leave of absence?
                <Link className="link" to="/settings/account/match-status">
                  Update your match status
                </Link>
              </li>
              <li>
                Wrong email address on your Imperative ID?
                <Link className="link" to="/settings/profile">
                  Update your account ID
                </Link>
              </li>
            </ul>
            <p>The account you are requesting to be deleted is {user.email}</p>
            <button className="btn-secondary destructive" onClick={onDelete}>
              Delete account
            </button>
          </div>
        )}
      </div>

      <Route exact path="/settings/account/match-status">
        <MatchDialog inActiveConversation={inActiveConversation} afterClose={() => history.push('/settings/account')} />
      </Route>
    </div>
  );
};

export default Account;
