import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import steps from './steps';

const NetworkGrowing = ({ user }) => {
  const [activeStep, setActiveStep] = useState(0);

  const step = useMemo(() => {
    return steps[activeStep];
  }, [activeStep]);

  useEffect(() => {
    steps.forEach((s) => {
      const img = new Image();
      img.src = s.image;
    });
  }, []);

  return (
    <div className="GettingStarted SetCardNew card-shadow">
      <div className="SetCardNew__header">
        <div className="section-intro">How does connecting on Imperative work?</div>
      </div>
      <div className="SetCardNew__progress">
        {steps.map((_, i) => {
          const active = i == activeStep;
          const finished = i < activeStep;
          return (
            <button key={i} className={cx('SetCardNew__progress-item', { active })} onClick={() => setActiveStep(i)}>
              <div className={cx('SetCardNew__progress-dot finished', { active })} />
            </button>
          );
        })}
      </div>
      {step && (
        <div className="SetCardNew__details">
          <div className="section-intro">{step.title}</div>
          <div className="SetCardNew__row">
            <div className="GettingStarted__content">{step.content(user)}</div>
            <img src={step.image} alt="" />
          </div>
        </div>
      )}
      {step && (
        <div className="SetCardNew__buttons">
          {activeStep > 0 && (
            <button className="btn-secondary large" onClick={() => setActiveStep(activeStep - 1)}>
              <span>Previous</span>
            </button>
          )}
          {activeStep > 0 && activeStep < steps.length - 1 && (
            <button className="btn-primary large" onClick={() => setActiveStep(activeStep + 1)}>
              <span>Next</span>
            </button>
          )}
          {activeStep == 0 && (
            <button className="btn-primary large" onClick={() => setActiveStep(activeStep + 1)}>
              <span>Get started</span>
            </button>
          )}
          {activeStep == steps.length - 1 && (
            <Link className="btn-primary large" to="/purpose-profile/report/results">
              <span>Learn more</span>
              <i className="material-icons">open_in_new</i>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default NetworkGrowing;
