import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'theme/Modal';

import './TimeoutCountdown.sass';

const SESSION_TIMEOUT_STORAGE_KEY = 'session-timeout-seconds';
const promptTimeout = 1 * 60 * 1000; // 60 sec
const defaultSessionTimeout = 65 * 60 * 1000; // 65 minutes (prompt timeout not included)

const getSessionTimeout = () => {
  try {
    const timeoutInSeconds = JSON.parse(localStorage.getItem(SESSION_TIMEOUT_STORAGE_KEY));
    const timeout = timeoutInSeconds * 1000;
    if(timeoutInSeconds !== null && timeout < defaultSessionTimeout) {
      return timeout - promptTimeout;
    }
    return defaultSessionTimeout - promptTimeout;
  } catch {
    return defaultSessionTimeout - promptTimeout;
  }
}


const TimeoutCountdown = () => {
  const history = useHistory();
  const timeout = useRef();
  const [promptVisible, showPrompt] = useState(false);

  const {
    getRemainingTime,
    start
  } = useIdleTimer({
    timeout: getSessionTimeout(),
    promptTimeout: promptTimeout,
    onPrompt: () => showPrompt(true),
    onIdle: () => history.push('/logout?sessionExpired')
  });

  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  const reset = useCallback(() => {
    showPrompt(false);
    start();
  }, [start, showPrompt]);

  const secondsLeft = useMemo(() => {
    return Math.ceil(remainingTime / 1000);
  }, [remainingTime]);

  useEffect(() => {
    if(promptVisible) {
      setRemainingTime(getRemainingTime());
      timeout.current = setInterval(() => setRemainingTime(getRemainingTime()), 1000);
    }
    return () => {
      clearInterval(timeout.current);
    }
  }, [promptVisible]);

  return (
    <Modal
      isOpen={Boolean(promptVisible)}
      className="TimeoutCountdown"
    >
      <div className='TimeoutCountdown__content'>
        <i className='fal fa-exclamation-triangle' aria-hidden  />
        <div>
          <p>Your session is about to expire.</p>
          <p>You will be logged out in {secondsLeft} second{secondsLeft > 1 ? 's' : ''}.</p>
        </div>
      </div>
      <div className='TimeoutCountdown__buttons'>
        <Link className='btn-secondary' to="/logout">Log Out</Link>
        <button className='btn-primary' onClick={reset}>Continue Session</button>
      </div>      
    </Modal>
  )
}

export default TimeoutCountdown;
