import React, {useEffect, useState, useContext, useMemo, useCallback} from 'react';
import * as _ from 'ramda';
import Loader from 'theme/Loader';
import TranslationsContext from 'translations';
import Modal, * as M from 'theme/Modal';
import {Radio, Slider, Multi} from 'theme/Form';

import './Questions.sass';

const SLIDER_IDS = [4, 5];
const MULTI_IDS = [2, 3];

const RelationshipModal = (props) => {
  const {opened, save, conversation, question, loading, firstAnswer} = props;
  const t = useContext(TranslationsContext);
  const [answer, setAnswer] = useState([]);
  const [error, setError] = useState(null);

  const sliderQuestion = useMemo(() => {
    return question && SLIDER_IDS.indexOf(question.question.id) > -1;
  }, [question]);

  const multiselectQuestion = useMemo(() => {
    return question && MULTI_IDS.indexOf(question.question.id) > -1;
  }, [question]);

  useEffect(() => {
    if(sliderQuestion) {
      const midOptionI = Math.floor(question.options.length / 2);
      setAnswer([question.options[midOptionI]]);
    }
  }, [question, sliderQuestion]);

  const saveBtnDisabled = useMemo(() => {
    return answer == null;
  }, [answer]);

  const options = useMemo(() => {
    if(!question) return [];
    return question.options.map(value => ({value, label: t.find(`peerconversation.relationshipQuestion.${question.question.id}.answer.${value}`)}));
  }, [question, t]);

  const submit = useCallback(() => {
    if(!question) return;
    setError(null);
    save(answer, question.question.id)
    .then(_, setError);
  }, [answer, question]);

  if(!conversation || !question) return null;

  const firstAnswerValue = firstAnswer ? firstAnswer.answer : 4;

  return (
    <Modal
      isOpen={opened}
      className="Questions__modalQuestion no-padding"
    >
      <M.Header className="Questions__modalQuestion-title title">
        {t.find(`peerconversation.relationshipQuestion.${question.question.id}.header`, (conversation.partner && conversation.partner.member) || {})}
      </M.Header>
      <M.Content className="Questions__modalQuestion-content">
        <Loader loading={loading}  />
        <div className="Questions__modalQuestion_question">
          {question.question.id == 4
            ?
              <>
                {firstAnswerValue <= 3
                  ? <div className="Questions__modalQuestion_title">{t.find(`peerconversation.relationshipQuestion.${question.question.id}.title.low`, (conversation.partner && conversation.partner.member) || {})}</div>
                  : <div className="Questions__modalQuestion_title">{t.find(`peerconversation.relationshipQuestion.${question.question.id}.title.high`, (conversation.partner && conversation.partner.member) || {})}</div>
                }
              </>
            : <div className="Questions__modalQuestion_title">{t.find(`peerconversation.relationshipQuestion.${question.question.id}.title`, (conversation.partner && conversation.partner.member) || {})}</div>
          }
          {sliderQuestion
          ?
            <Slider
              options={options}
              value={answer[0]}
              onChange={(v) => setAnswer([v])}
            />
          : (multiselectQuestion
            ?
              <Multi
                options={options}
                value={answer}
                onChange={setAnswer}
              />
            :
              <Radio
                options={options}
                value={answer[0]}
                onChange={(v) => setAnswer([v])}
              />
          )}
        </div>
        {error &&
          <p className='Questions__modalQuestion_error error'>
            <i className="fas fa-exclamation-circle" aria-hidden />
            Something went wrong while saving. Please try again later or contact Imperative Team.
          </p>
        }
        <div className='Questions__modalQuestion_btn'>
          <button
            className='btn btn_primary btn_solid_bluePurple'
            disabled={saveBtnDisabled}
            onClick={submit}
          >
            {t.find(`peerconversation.relationshipQuestion.${question.question.id}.save`, (conversation.partner && conversation.partner.member) || {})}
          </button>
        </div>
      </M.Content>
    </Modal>
  )
}

export default RelationshipModal;
