import React from 'react'
import Markdown from 'react-markdown'
import cx from 'classnames'

import './Markdown.sass'


export default ({source, className}) ->
  React.createElement(Markdown, {"className": (cx "Markdown", className)},
    (source)
  )
