import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import * as _ from 'ramda'
import {URL_DOMINANT, URL_DRIVERS} from 'theme/assets/assets'
import Page from '../PrintPage'
import Logo from './Logo'
import P from './P'
import Heading from './Heading'
import Ul from './Ul'
import Li from './Li'
import Quote from './Quote'
import Spacer from './Spacer'
import MemberList from './MemberList'
import TeamCultureProfileElement from './TeamCultureProfileElement'
import {WhoTeamDriver, WhyTeamDriver, HowTeamDriver} from './TeamDriver'
import {
  MeAndTeamOverviewPage
  MeAndTeamWhoDriverPage
  MeAndTeamWhyDriverPage
  MeAndTeamHowDriverPage
} from './MeAndTeam'
import Donut from './Donut'
import calcCulture from './culture'
import driversDescription from './drivers-description'

import './TeamPrintReport.sass'


MEMBERS_ON_FIRST_PAGE = 20
MEMBERS_PER_PAGE = 30

STATUS_MAP =
  loweffort: "Low effort required"
  moderateeffort: "Moderate effort required"
  higheffort: "High effort required"


sortMembersBy = ({isLead, firstName, lastName}) ->
  "#{if isLead then ' ' else ''}#{firstName} #{lastName}"

paginateMembers = (members, perFirstPage, perPage) ->
  members = _.sortBy sortMembersBy, members
  [firstPage, rest] = _.splitAt perFirstPage, members
  paginated = _.splitEvery perPage, rest
  {firstPage, paginated}

fractionToPercent = (value) ->
  Math.round(value * 100)

TeamReportContent = ({report, teamMember}) ->
  teamDrivers = _.pluck 'driver', report.drivers

  number = 2 # page numbering start
  date = moment().format 'MMM Do, YYYY'
  footer = "Team Report for #{report.name} \u00b7 #{date} \u00b7 \u00a9 Imperative"

  members = paginateMembers report.members, MEMBERS_ON_FIRST_PAGE, MEMBERS_PER_PAGE
  dimensions = ['who', 'why', 'how']

  name = if teamMember?
    "#{teamMember.firstName} #{teamMember.lastName}"

  teamMemberDrivers = if teamMember?
    _.pluck 'driver', teamMember.assessmentResult.drivers

  culture = if report.leadershipDistribution?
    calcCulture report

  leadershipPercentageDistribution = if report.leadershipDistribution?
    impact: fractionToPercent(report.leadershipDistribution.impact)
    values: fractionToPercent(report.leadershipDistribution.values)
    craft: fractionToPercent(report.leadershipDistribution.craft)

  teamLeader = report.members.find (m) -> m.isLead

  dominant = dimensions.find (d) ->
    report.drivers[d].dominant

  return \
  React.createElement("section", {"className": "TeamReport__content"},
    React.createElement(Page, {"background": (React.createElement("div", {"className": "TeamReport__coverBgTeam"})), "className": "TeamReport__firstPage"},
      React.createElement("div", {"className": "TeamReport__coverImage"},
        React.createElement("img", { \
          "className": (cx "TeamReport__dominant_#{dominant}"),  \
          "src": "#{URL_DOMINANT}/dominant__#{dominant}.png",  \
          "alt": (dominant)
        })
      ),
      React.createElement("div", {"className": "TeamReport__coverTextBlockTeam"},
        React.createElement(P, {"color": "pink", "size": "lg", "className": "TeamReport__summaryText"},
          (
            {intro, rest} = driversDescription report.drivers
            React.createElement("span", null, React.createElement("strong", null, (report.name), " ", (intro)), " ", (rest))
          )
        )
      ),
      React.createElement("div", {"className": "TeamReport__summaryFooter"},
        React.createElement(Heading, {"className": "TeamReport__summaryFooterHeading"}, """
          Team Guide
"""),
        React.createElement(Heading, {"className": "TeamReport__summaryFooterHeadingTeam"},
          (report.name)
        ),
        React.createElement(P, {"className": "TeamReport__summaryFooterDetails"}, """
          The guide to accelerating your team\'s potential.
""", React.createElement("br", null),
          (if name?
            React.createElement("span", null, "Prepared for ", (name), " on ", (date), ".")
          ),
          (if not name?
            React.createElement("span", null, "Prepared on ", (date), ".")
          )
        ),
        React.createElement(Logo, {"className": "TeamReport__summaryFooterLogo"})
      )
    ),

    React.createElement(Page, {"number": (number++), "footer": (footer)},
      React.createElement(Quote, {"author": "Margaret Mead"}, """
        Never doubt that a small group of thoughtful, committed citizens
        can change the world; indeed, it\'s the only thing that ever has.
"""),

      React.createElement("div", {"className": "TeamReport__introTextBlock"},
        React.createElement(P, null, """
          Now is your chance to take fulfillment to the next level!
"""),
        React.createElement(P, null, """
          Hopefully you’ve spent substantial time getting in touch with what
          drives purpose for you. The next step is to see yourself within the
          context of your team. When teams form, new dynamics emerge.
          Drivers and motivation may compete or compliment, and it all relies
          on your ability to work with purpose, enable others, and form a
          shared purpose with the team. This unlocks new dimensions in
          fulfillment at work.
"""),
        React.createElement(P, null, """
          When it comes down to it, Purpose-Oriented Teams out-perform their
          peers. They leverage their individual purpose in their individual
          work. They use team purpose to bond and thrive. They adopt a sense
          of shared purpose that makes goals and celebrations more meaningful.
          If a team wants to be rock-stars, then purpose is their “north star.”
"""),
        React.createElement(P, null, """
          This report will help you with:
"""),
        React.createElement(Ul, null,
          React.createElement(Li, null, """
            Team start-up
"""),
          React.createElement(Li, null, """
            Team goal setting
"""),
          React.createElement(Li, null, """
            Building team identity and brand
"""),
          React.createElement(Li, null, """
            Disciplined collaboration and situational leadership
"""),
          React.createElement(Li, null, """
            Feedback and celebrations
""")
        ),
        React.createElement(P, {"size": "lg"}, """
          So, let’s get started!
""")
      )
    ),

    React.createElement(Page, {"number": (number++), "footer": (footer)},
      React.createElement(Heading, {"size": "huge"}, "Our Team", React.createElement("br", null), "At a Glance"),
      React.createElement(P, {"className": "TeamReport__glanceIntro"}, """
        This is an overview of each team members’ purpose drivers,
        as well as which driver is dominant (in color*).
        Use this to improve collaboration, communication, and to empower each other.
"""),
      React.createElement("div", {"className": "TeamReport__glanceContent"},
        React.createElement(MemberList, { \
          "className": "TeamReport__glanceMemberList",  \
          "members": (members.firstPage)
        }),
        React.createElement("section", {"className": "TeamReport__glanceMargin"},
          React.createElement("h1", {"className": "TeamReport__glanceMarginHeading"}, "Think About"),
          React.createElement("p", {"className": "TeamReport__glanceMarginP"}, """
            Who shares the most\x2Fleast drivers with you?
"""),
          React.createElement("p", {"className": "TeamReport__glanceMarginP"}, """
            What are some examples of your team member’s drivers in action?
"""),
          React.createElement("p", {"className": "TeamReport__glanceMarginP"}, """
            How can this chart enable better communication and collaboration?
"""),
          React.createElement("p", {"className": "TeamReport__glanceMarginP"}, """
            How can you help each team member reach their purpose potential?
""")
        )
      )
    ),

    (members.paginated.map (members, i) ->
      React.createElement(Page, {"number": (number++), "footer": (footer), "key": (i)},
        React.createElement(MemberList, { \
          "className": "TeamReport__glanceContinuedMemberList",  \
          "members": (members)
        })
      )
    ),

    React.createElement(Page, {"number": (number++), "footer": (footer)},
      React.createElement(Heading, {"size": "huge"}, "Team Drivers Overview"),
      React.createElement(P, {"className": "TeamReport__teamDriversIntro"}, """
        The team drivers are determined by calculating the amount of each driver,
        with a heavier weight placed on dominant drivers. This provides insights
        about how a team sees themselves as well as indicates where additional
        work is needed to help the team norm and gel.
"""),
      React.createElement("h1", {"className": "TeamReport__teamDriversHeading"},
        (report.name), " ", ("Team's Dominant Drivers")
      ),
      React.createElement("section", {"className": "TeamReport__teamDriversDrivers"},
        (for d in dimensions
          {driver} = report.drivers[d]
          React.createElement("div", { \
            "key": (d),  \
            "className": "TeamReport__teamDriversDriver TeamReport__teamDriversDriver_#{d}"
          },
            React.createElement("h2", {"className": "TeamReport__teamDriversDriverTitle"}, (driver)),
            React.createElement("img", { \
              "className": "TeamReport__teamDriversDriverIcon",  \
              "src": "#{URL_DRIVERS}/#{d}#{driver}.png",  \
              "alt": (driver)
            })
          )
        )
      ),
      React.createElement(P, {"className": "TeamReport__teamDriversDescription"},
        (report.name), " ", (driversDescription(report.drivers).whole)
      )
    ),

    React.createElement(Page, {"number": (number++), "footer": (footer)},
      React.createElement(Heading, null, "Optimizing Team ‘Impact’ Driver"),
      React.createElement(WhoTeamDriver, {"driver": (report.drivers.who.driver)})
    ),

    React.createElement(Page, {"number": (number++), "footer": (footer)},
      React.createElement(Heading, null, "Optimizing Team ‘Values’ Driver"),
      React.createElement(WhyTeamDriver, {"driver": (report.drivers.why.driver), "who": (report.drivers.who.driver)})
    ),

    React.createElement(Page, {"number": (number++), "footer": (footer)},
      React.createElement(Heading, null, "Optimizing Team ‘Craft’ Driver"),
      React.createElement(HowTeamDriver, {"driver": (report.drivers.how.driver)})
    ),

    (if culture?
      React.createElement(Page, {"number": (number++), "footer": (footer)},
        React.createElement(Heading, {"size": "lg"}, "Team Orientation Intro"),
        React.createElement("div", {"className": "TeamReport__drivingPurposeIntroBlock"},
          React.createElement(Quote, {"author": "Peter Drucker", "className": "TeamReport__drivingPurposeIntroQuote"}, """
            Culture eats strategy for breakfast.
"""),
          React.createElement("div", {"className": "TeamReport__drivingPurposeDescription"},
            React.createElement(P, {"size": "sm"}, """
              Purpose also has a significant impact on team culture. When each
              team member took the individual assessment, they chose how strongly
              they cared about a question. The result, when combined with other
              team members, can reveal patterns of what the team cares most about.
"""),
            React.createElement(P, {"size": "sm"}, """
              For example, if a team cares deeply about ‘who they impact’
              (rating it more important), but cares less about ‘why they work’
              or ‘how they solve problems,’ this team will focus more on impact.
              If they care about both ‘who they impact’ and ‘why they work,’
              they are both impact and values driven.
"""),
            React.createElement(P, {"size": "sm"}, """
              Embracing a team’s natural orientation will drive fulfillment
              through purpose. Going against this default is like swimming
              against the current.
"""),
            React.createElement(P, {"size": "sm"}, """
              On the next page is an analysis of what matters most to your team:
              Impact (WHO), Values (WHY), and\x2For Craft (HOW).
              Your team might care about only one of these, two of these, or all three!
""")
          )
        ),
        React.createElement("div", {"className": "TeamReport__drivingPurposeDriversBlock"},
          React.createElement(P, {"size": "sm"}, """
            If your team cares a lot about something, but has a high diversity
            of drivers (ex: cares about ‘why they work,’ (are Values-driven),
            but team is split between Karma and Harmony), this will create
            energetic discussion and possibly tension. In either case,
            the places where there are energy are the places where you can
            develop team purpose!
"""),
          React.createElement("h1", {"className": "TeamReport__drivingPurposeDriverHeading TeamReport__drivingPurposeDriverHeading_who"}, """
            Impact-driven team
"""),
          React.createElement(P, {"className": "TeamReport__drivingPurposeDriverDescription"}, """
            These teams begin and end everything with a discussion about who
            they impact. If it doesn’t make an impact, it isn’t worth doing.
"""),
          React.createElement("h1", {"className": "TeamReport__drivingPurposeDriverHeading TeamReport__drivingPurposeDriverHeading_why"}, """
            Values-driven team
"""),
          React.createElement(P, {"className": "TeamReport__drivingPurposeDriverDescription"}, """
            These teams focus significant energy on integrity and doing what is
            right as their first concern. Nothing will demotivate this team more
            than being asked to betray their principles.
"""),
          React.createElement("h1", {"className": "TeamReport__drivingPurposeDriverHeading TeamReport__drivingPurposeDriverHeading_how"}, """
            Craft-driven team
"""),
          React.createElement(P, {"className": "TeamReport__drivingPurposeDriverDescription"}, """
            These teams gain tremendous meaning from doing things elegantly and
            professionally. Taking short-cuts and compromising quality deflates them.
""")
        )
      )
    ),

    (if culture?
      React.createElement(Page, {"number": (number++), "footer": (footer)},
        React.createElement(Heading, null, "What Drives Our Team:", React.createElement("br", null), (culture.title)),
        React.createElement("div", {"className": "TeamReport__ourCultureMainBlock"},
          React.createElement(P, {"className": "TeamReport__ourCultureDescription"},
            (culture.description)
          ),

          React.createElement("div", {"className": "TeamReport__ourCultureDonut"},
            React.createElement(Donut, { \
              "id": "TeamReport__ourCultureDonut",  \
              "colors": ((driver) -> driver),  \
              "colorAccessor": (({label: [driver]}) -> driver),  \
              "data": (
                ({label: [k[0]?.toUpperCase() + k.slice(1)?.toLowerCase(), 'driven', v + '%'], value: v} for k, v of leadershipPercentageDistribution)
                  .filter ({value}) -> value != 0
              ),  \
              "width": (440),  \
              "height": (400),  \
              "radius": (120),  \
              "innerRadius": (70),  \
              "showOuterLabels": (true)
            })
          )
        ),

        React.createElement("section", {"className": "TeamReport__ourCultureMargin"},
          React.createElement("h1", {"className": "TeamReport__ourCultureMarginHeading"}, """
            Amplifying Team Purpose
"""),
          (for aside, i in culture.asides
            React.createElement("p", {"key": (i), "className": "TeamReport__ourCultureMarginAside"}, (aside))
          )
        )
      )
    ),

    (if culture?
      React.createElement(Page, {"number": (number++), "footer": (footer)},
        React.createElement(Heading, null, "Team Profile"),
        (for element in culture.elements[0..1]
          React.createElement(TeamCultureProfileElement, { \
            "key": (element),  \
            "element": (element),  \
            "leadershipPercentageDistribution": (leadershipPercentageDistribution),  \
            "excels": (culture.excels[element]),  \
            "struggles": (culture.struggles[element])
          })
        )
      )
    ),

    (if culture? and culture.elements.length > 2
      React.createElement(Page, {"number": (number++), "footer": (footer)},
        (for element in culture.elements[2..]
          React.createElement(TeamCultureProfileElement, { \
            "key": (element),  \
            "element": (element),  \
            "leadershipPercentageDistribution": (leadershipPercentageDistribution),  \
            "excels": (culture.excels[element]),  \
            "struggles": (culture.struggles[element])
          })
        )
      )
    ),

    (if teamMember
      React.createElement(MeAndTeamOverviewPage, { \
        "number": (number++),  \
        "footer": (footer),  \
        "me": (teamMemberDrivers),  \
        "team": (teamDrivers)
      })
    ),

    (if teamMember
      React.createElement(MeAndTeamWhoDriverPage, { \
        "number": (number++),  \
        "footer": (footer),  \
        "myName": (name),  \
        "teamName": (report.name),  \
        "me": (teamMemberDrivers.who),  \
        "team": (teamDrivers.who)
      })
    ),

    (if teamMember
      React.createElement(MeAndTeamWhyDriverPage, { \
        "number": (number++),  \
        "footer": (footer),  \
        "myName": (name),  \
        "teamName": (report.name),  \
        "me": (teamMemberDrivers.why),  \
        "team": (teamDrivers.why)
      })
    ),

    (if teamMember
      React.createElement(MeAndTeamHowDriverPage, { \
        "number": (number++),  \
        "footer": (footer),  \
        "myName": (name),  \
        "teamName": (report.name),  \
        "me": (teamMemberDrivers.how),  \
        "team": (teamDrivers.how)
      })
    ),

    React.createElement(Page, {"number": (number++), "footer": (footer)},
      React.createElement("div", {"className": "TeamReport__legalBlock"},
        React.createElement("h1", {"className": "TeamReport__legalHeading"}, "All Rights Reserved"),
        React.createElement("p", {"className": "TeamReport__legalP"}, """
          No part of this publication may be reproduced, distributed, or transmitted
          in any form or by any means, including photocopying, recording, or other
          electronic or mechanical methods, without the prior written permission of
          the publisher, except in the case of brief quotations embodied in critical
          reviews and certain other non-commercial uses permitted by copyright law.
"""),
        React.createElement("p", {"className": "TeamReport__legalP"}, """
          Requests for permission may be sent to info@imperative.com
""")
      )
    )
  )

export default TeamReportContent
