import React from 'react'
import * as _ from 'ramda'
import T from 'tcomb'
import r from 'r-dom'
import cx from 'classnames'
import {composeComponent, addStatesWithHandlers} from 'utils/react-tools'
import {withHandlers, lifecycle, withState, withProps} from 'recompose'
import {withRouter} from 'react-router'
import qs from 'qs'
import {reduxForm, Field, SubmissionError} from 'redux-form'
import validate, * as V from 'validate'
import {TextField, CheckboxField, BtnContainer} from 'auth/Form'
import Layout, {Link, Hint} from 'auth/Layout'
import Loader from 'theme/Loader'
import * as F from 'theme/Form'
import {getConsents, fetchUser, updateUser, updateConsents} from 'auth/requests'

import "./CompleteUserData.sass";

ERRORS =
  unknown: 'Something went wrong. Please try again later.'

firstNameValidator = V.required('Enter your first name please.')
lastNameValidator = V.required('Enter your first name please.')


Form = composeComponent 'AuthPages.CompleteData',
  reduxForm
    form: 'CompleteData'
    enableReinitialize: true
  ({handleSubmit, submitFailed, submitting, invalid, error, emailByInvitation, languageOptions, changeLanguage, currentLanguage}) ->
    React.createElement("form", {"onSubmit": (handleSubmit), "className": "CompleteUserData"},
      React.createElement(Field, { \
        "id": "CompleteUserData__fieldFirstName",  \
        "name": "user.firstName",  \
        "label": "First Name",  \
        "placeholder": "First Name",  \
        "validate": (firstNameValidator),  \
        "component": (TextField)
      }),
      React.createElement(Field, { \
        "id": "CompleteUserData__fieldLastName",  \
        "name": "user.lastName",  \
        "label": "Last Name",  \
        "placeholder": "Last Name",  \
        "validate": (lastNameValidator),  \
        "component": (TextField)
      }),

      React.createElement("div", {"className": "CompleteUserData__textBox CompleteUserData__text"}, """
        By continuing, you agree to our """, React.createElement("a", {"href": "https://www.imperative.com/terms-of-service", "target": "_blank"}, "Terms of Service")
      ),

      (if submitFailed and error
        React.createElement("div", {"className": "CompleteUserData__error"},
          (submitFailed and error and (ERRORS[error] ? error))
        )
      ),

      React.createElement(BtnContainer, null,
        React.createElement("button", { \
          "id": 'CompleteUserData__signupBtn',  \
          "className": "btn btn_primary btn_solid_pink",  \
          "type": "submit",  \
          "disabled": (invalid or submitting)
        }, """
          Proceed to Imperative
""")
      ),

      React.createElement("div", {"className": "CompleteUserData__textBox CompleteUserData__text"},
        React.createElement("a", {"href": "https://www.imperative.com/privacy-policy", "target": "_blank"}, "Privacy Policy")
      )
    )


overlay =
  r.div className: "CompleteUserData__overlay", [
    r.h3 'Activate Purpose'
    r.h4 'Unlock your potential'
  ]

export default composeComponent 'AuthPages.CompleteData',
  withRouter
  addStatesWithHandlers
    loading: false
    user: null
    consents: null

  withHandlers
    submit: ({user: u, history}) -> (values) ->
      {user} = values
      consents = {consentTermsOfServiceAndPrivacyPolicyAccepted: true}
      Promise.all([
        updateConsents(u.slug, consents),
        updateUser({...user, firstName: user.firstName.trim(), lastName: user.lastName.trim()})
      ]).then(
        -> history.push('/')
      )

    getUser: ({setUser, setError}) -> ->
      fetchUser()
      .then(
        (user) =>
          setUser(user)
          user
        (error) =>
          ME.reportIntermediateError('Complete User Data: loading user error', error)
          setError(error)
      )
    loadConsents: ({setConsents, setError}) -> (slug) ->
      getConsents(slug)
      .then(
        setConsents,
        (error) =>
          ME.reportIntermediateError('Complete User Data: loading consents error', error)
          setError(error)
      )

  lifecycle
    componentDidMount: ->
      {setLoading, getUser, loadConsents, history} = @props
      setLoading(true)

      getUser()
      .then(
        (user) =>
          if (user.firstName && user.lastName)
            history.push('/')
          else
            loadConsents(user.slug)
            .then(
              => setLoading(false)
            )
      )
      .catch( => setLoading(false))

  ({user, loading, submit, consents}) ->
    React.createElement(Layout, { \
      "headline": "Complete Your Profile",  \
      "overlay": (overlay),  \
      "withUser": (false)
    },
      React.createElement(Loader, {"loading": (loading), "color": "white"}),

      React.createElement("div", {"className": "Signup relative"},
        (if user && consents
          React.createElement(Form, { \
            "onSubmit": (submit),  \
            "initialValues": (
              user:
                firstName: user.firstName
                lastName: user.lastName
              consents: consents
            )
          })
        ),
        React.createElement(Hint, {"id": 'CompleteUserData__signinLink'},
          React.createElement(Link, {"to": "/logout"}, "Log out ", (">"))
        )
      )
    )
