import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import './PrintPage.sass'


export default ({children, className, number, background, footer, marginSize = 'normal'}) ->
  [bgColor, background] = [background, null] if typeof background is 'string'

  React.createElement("article", {"className": (cx "PrintPage__page", className, bgColor and "PrintPage__page_#{bgColor}", marginSize)},
    (React.createElement("div", {"className": "PrintPage__pageBg"}, (background)) if background),
    (React.createElement("span", {"className": "PrintPage__pageNumber"}, (number)) if number),
    React.createElement("section", {"className": "PrintPage__pageContent"}, (children)),
    (React.createElement("footer", {"className": (cx "PrintPage__pageFooter", marginSize)}, (footer)) if footer)
  )
