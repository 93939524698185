import r from 'r-dom'
import T from 'tcomb'
import cx from 'classnames'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import * as emojis from './emojis'

# import s from './Assessment.sass'

LABELS_MAP =
  'Frustrated': 'assessment.rig.answer1'
  'Unhappy': 'assessment.rig.answer2'
  'Meh': 'assessment.rig.answer3'
  'Happy': 'assessment.rig.answer4'
  'Pumped Up': 'assessment.rig.answer5'


export default composeComponent 'Assessment.IconsRadio',
  setPropTypes
    options: T.list T.interface
      value: T.Any
      label: T.String
    onChange: T.Function
    value: T.Any

  ({options, value, onChange, t}) ->
    r.div className: "Assessment__row Assessment__icons", options.map (o) ->
      selected = o.value is value
      r.label
        key: o.value
        className: cx "Assessment__iconOption", selected and "selected"
        [
          r.input
            type: 'checkbox'
            checked: selected
            id: "Assessment__option#{o.value}"
            onChange: -> onChange o.value
          r.div {}, [
            r.img
              src: emojis[if o.label is 'Pumped Up' then 'PumpedUp' else o.label]
              alt: ""
            r.p className: "Assessment__iconLabel", t.find LABELS_MAP[o.label]

          ]
        ]
