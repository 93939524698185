import React, {useState, useEffect, useMemo, useContext, useCallback} from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { useStore } from 'stores/stores';
import debounce from 'debounce-promise';
import { Link } from 'react-router-dom';
import * as ME from 'api/metrics'
import Loader from 'theme/Loader';
import Avatar from 'theme/Avatar';
import TranslationsContext from 'translations';
import {archetypes} from 'theme/drivers'
import Filters from './Filters';
import { fetchCommunityMembers, getAssessmentData } from './requests';

import './Community.sass';

const MEMBERS_PER_PAGE = 15;

const titleTip = "The community represents all the peers within your organization currently on Imperative.";
const purposeTip = "A star indicates this member shares the same purpose type as you.";

const formatDate = (date) => {
  if(!date) return '';
  return dayjs(date).format('MM/DD/YYYY');
}

const translateOption = (t) => (o = {id: 1}) => {
  if(o.custom) return {label: o.value, value: o.value};
  const {questionId, questionType} = o;
  const optionId = o.value;
  const option = t.find(`surveys.purpose-assessment.questions.${questionId}.options.${optionId}`);
  if (questionType && t.exists(`dictionaries.${questionType.replace('-', '')}.${option}.name`))
    return {
      value: option,
      label: t.find(`dictionaries.${questionType.replace('-', '')}.${option}.name`)
    };
  else return {value: option, label: option};
};

const COLUMNS = (functions = []) => [
  {
    name: 'Since',
    sortKey: 'Created',
  },
  {
    name: 'Purpose Type',
    sortKey: 'Archetype',
    filterKey: 'archetype',
    filterName: 'Purpose Type',
    filterOptions: (t) => archetypes.map(a => {
      const o = t.find(`pattern.archetypes.${a}.title`);
      return {value: o, label: o}
    })
  },
  {
		name: 'Function',
		sortKey: 'JobFunction',
    filterKey: 'function',
    filterName: 'Function',
    filterOptions: (t) => functions.map(translateOption(t))
	},
  {
		name: 'Connections',
		sortKey: 'Connections',
	},
  {
		name: 'Conversations',
		sortKey: 'Conversations',
	},
  {
		name: 'Actions',
		sortKey: 'Commitments',
	},
];

const Members = () => {
  const t = useContext(TranslationsContext);
  const {userStore} = useStore();
  const {user} = userStore;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [loadingMoreMembers, setloadingMoreMembers] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalMembersCount, setTotalMembersCount] = useState(-1);
  const [appliedFilters, applyFilters] = useState({});
  const [jobFunctionQuestion, setJobFunctionQuestion] = useState(null);
  const [moreToLoad, setMoreToLoad] = useState(true);

  const jobFunctions = useMemo(() => {
    if(!jobFunctionQuestion) return [];
    const options = jobFunctionQuestion.options.reduce((list, o) => {
      if(!o.customOptions) {
        return [...list, {
          value: o.id,
          questionId: jobFunctionQuestion.id,
          questionType: jobFunctionQuestion.questionType
        }];
      }
      return [
        ...list,
        ...o.customOptions.filter(co => !!co).map(co => ({
          value: co,
          custom: true,
          questionId: jobFunctionQuestion.id,
          questionType: jobFunctionQuestion.questionType
        }))
      ]
    }, [])
    return options;
  }, [jobFunctionQuestion]);

  const columns = COLUMNS(jobFunctions);
  const [sortedBy, sortBy] = useState(null);

  const filters = columns.filter(c => c.filterKey && c.filterOptions && c.filterOptions(t).length).map(c => ({name: c.filterName, key: c.filterKey, options: c.filterOptions(t)}));

  const filteredBy = useMemo(() => {
    return {
      ...(sortedBy ? {sort: sortedBy} : {}),
      ...appliedFilters
    }
  }, [sortedBy, appliedFilters]);

  const debouncedGetMembers = debounce((...args) => {
    setLoadingMembers(true);
    return fetchCommunityMembers(...args)
    .then(
      (res) => {
        setLoadingMembers(false);
        return res;
      }, (err) => {
        setLoadingMembers(false);
        ME.reportIntermediateError("community-members-loading-error", err);
        return err;
      }
    )
  }, 500);

  const getMembers = useCallback((params = {}, debounced = false) => {
    const req = debounced ? debouncedGetMembers : fetchCommunityMembers;
    return req({...params, size: params.size || MEMBERS_PER_PAGE, page: params.page || page})
    .then(
      data => data,
      (error) => {
        ME.reportIntermediateError("community-members-loading-error", error);
        return Promise.reject(error);
      }
    );
  }, [page]);

  const hasMore = useMemo(() => {
    if(totalMembersCount == -1) return true;
    return moreToLoad && (totalMembersCount > members.length);
  }, [members, totalMembersCount, moreToLoad]);

  const getMoreMembers = useCallback(() => {
    if(!loadingMoreMembers) {
      setloadingMoreMembers(true);
      return getMembers({...filteredBy, size: MEMBERS_PER_PAGE, page: page + 1})
      .then(data => {
        setMoreToLoad(data.members.length > 0);
        setTotalMembersCount(data.total);
        setMembers(m => [...m, ...data.members]);
        setPage(p => p + 1);
        setloadingMoreMembers(false);
        return data;
      }, (error) => {;
        ME.reportIntermediateError("community-members-loading-error", error);
        setloadingMoreMembers(false);
      })
    }
  }, [filteredBy, loadingMoreMembers, members, page]);

  useEffect(() => {
    setLoading(true);
    getJobFunctions()
    .then(() => {
      setLoading(false);
    });
  }, []);

  const getJobFunctions = useCallback(() => {
    return getAssessmentData()
    .then(
      d => {
        const q = d.questions.find(({questionType}) => questionType == 'job-function');
        setJobFunctionQuestion(q);
      }
      ,(error) => {
        ME.reportIntermediateError('Settings profile: loading assessment data error', error)
        setError(error);
        return error;
      }
    )
  }, []);

  const searchMembers = useCallback((sort, appliedFilters) => {
    setLoadingMembers(true);
    getMembers({...appliedFilters, sort, page: 1}, true)
    .then(data => {
      setPage(1);
      setMoreToLoad(data.members.length > 0);
      setTotalMembersCount(data.total);
      setMembers(data.members);
      setLoadingMembers(false);
      return data;
    });
  }, [getMembers]);

  const sortByColumn = useCallback(headerName => {
    const s = sortedBy;
    let res = undefined;
    const sort = s || '';
    const dir = sort.slice(0, 1) === '-';
    const col = dir ? sort.slice(1) : sort;
    if(headerName === col) {
      if(dir) res = col;
      if(!dir) res = undefined;
    } else {
      res = `-${headerName}`
    }
    sortBy(res);
    searchMembers(res, appliedFilters);
  }, [sortedBy, appliedFilters, searchMembers]);

  const filterMembers = useCallback((f) => {
    applyFilters(f);
    searchMembers(sortedBy, f);
  }, [searchMembers, sortedBy])

  const getSortDirectionForHeader = useCallback(headerName => {
    if(!sortedBy) return '';
    const dir = sortedBy.slice(0, 1) === '-';
    const col = dir ? sortedBy.slice(1) : sortedBy;
    if(col !== headerName) return '';
    return dir ? 'descending' : 'ascending';
  }, [sortedBy]);

  const sameArchetypeAsUser = useCallback((member) => {
    if(!member.pattern || !user.pattern) return false;
    const memberArchetype = `${member.pattern.who}${member.pattern.why}${member.pattern.how}`;
    const userArchetype = `${user.pattern.who}${user.pattern.why}${user.pattern.how}`;
    return memberArchetype == userArchetype;
  }, [user]);

  const inviteColleagueEnabled = useMemo(() => {
    return !user || (
      !!user.organizationSettings && !!user.organizationSettings.partnerConversationsSettings && !!user.organizationSettings.partnerConversationsSettings.colleagueInviteEnabled
    );
  }, [user]);

  return (
    <div className="Community__members" id="CommunityMembers">
      <Loader loading={loading} color="white" />
      <div className="Community__title">
        <Tippy content={titleTip} placement="top" className="tip">
          <h1 className="page-title">
            {user && user.organization && user.organization.name} Community ({totalMembersCount > -1 ? totalMembersCount : 0})
            {inviteColleagueEnabled &&
              <Link to="/community/invite" title='Invite members' className='btn-icon'>
                <i className="material-icons-outlined" aria-hidden>person_add</i>
              </Link>
            }
          </h1>
        </Tippy>
      </div>
      {user &&
        <div className="Community__membersList relative">
          {totalMembersCount == 0 && !error &&
            <p className='text-center'>There are no members in your community yet.</p>
          }
          {error &&
            <p className='text-center'>Something went wrong. Cannot load members list.</p>
          }
          <Loader className='Community__membersListLoader' loading={loadingMembers && !loading} color="white" />
          <Filters
            filters={filters}
            filter={filterMembers}
          />
          <InfiniteScroll
            loadMore={getMoreMembers}
            hasMore={hasMore}
            threshold={0}
            loader={<Loader key="loader" loading={loadingMoreMembers} size="1" className="static" color="white" />}
            style={{
              overflowY: 'hidden',
              overflowX: 'auto'
            }}
          >
            <table className='Community__membersTable' aria-label="Community members">
              <thead className='Community__membersTableRow Community__membersTableRow_head'>
                <tr>
                  <th className='Community__membersTableHeader'>
                    Member Name
                  </th>
                  {columns.map((column, i) =>
                    <th
                      key={i}
                      className='Community__membersTableHeader sortable'
                      aria-sort={getSortDirectionForHeader(column.sortKey)}
                      onClick={() => sortByColumn(column.sortKey)}
                    >
                      <span className='Community__membersTableHeaderSortable'>
                        {column.name}
                        <span className={cx('fa-stack', getSortDirectionForHeader(column.sortKey))}>
                          <i className='fas fa-caret-up fa-stack-1x' /> 
                          <i className='fas fa-caret-down fa-stack-1x' /> 
                        </span>
                      </span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {members.map(m =>
                  <tr className="Community__membersTableRow" key={m.memberId}>
                    <td className='Community__membersTableCell'>
                      <div className='Community__membersName'>
                        <Link to={`/community/members/${m.memberId}`}>
                          <Avatar for={{...m.member, avatars: {normal: m.avatar}}} size="32" />
                        </Link>
                        <div>
                          <Link to={`/community/members/${m.memberId}`}>
                            <b>{m.firstName} {m.lastName}</b>
                          </Link>
                          <a className='caption-new' href={`mailto:${m.email}`}>{m.email}</a>
                        </div>
                      </div>
                    </td>
                    <td className='Community__membersTableCell'>
                      {formatDate(m.assessmentCompletedAt)}
                    </td>
                    <td className='Community__membersTableCell'>
                      {m.pattern &&
                        <div className='Community__membersArchetype'>
                          {t.find(`pattern.archetypes.${m.pattern.who}${m.pattern.why}${m.pattern.how}.title`)}
                          {sameArchetypeAsUser(m) &&
                            <Tippy content={purposeTip} placement="top" className="tip Community__membersTip">
                              <span className='material-icons-outlined Community__membersStar' tabIndex="0">star</span>
                            </Tippy>
                          }
                        </div>
                      }
                    </td>
                    <td className='Community__membersTableCell wider'>
                      {m.jobFunction && (m.jobFunctionText || t.find(`dictionaries.jobFunction.${m.jobFunction}.name`))}
                    </td>
                    <td className='Community__membersTableCell'>
                      {m.connectionsCount}
                    </td>
                    <td className='Community__membersTableCell'>
                      {m.completedConversationsCount}
                    </td>
                    <td className='Community__membersTableCell'>
                      {m.commitmentsCount}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      }
    </div>
  )
}

export default observer(Members);
