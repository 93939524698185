import React, { useEffect, useMemo, useState } from 'react'
import * as _ from 'ramda'
import qs from 'qs';
import {reduxForm, Field, SubmissionError} from 'redux-form'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {required, email} from 'validate'
import Header from 'theme/Header';
import Loader from 'theme/Loader';
import {TextField, BtnContainer} from 'auth/Form'
import {signin, signinWithJwt} from 'auth/requests'
import Layout, {Hint} from 'auth/Layout'

import 'theme/btn/btn-styles.sass'
import './Signin.sass'

const config = window.config;

const emailRequired = required('Enter an email address please');
const emailValid = email('Enter a valid email address please');
const passwordRequired = required('Enter a password please');

const Form = ({handleSubmit, submitFailed, submitting, invalid, error, tokenError}) => {
  const err = (submitFailed && error) ? error : tokenError;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        placeholder="Email address"
        name="email"
        label="Email"
        component={TextField}
        validate={[emailRequired, emailValid]}
        normalize={(val) => val.trim()}
      />
      <Field
        name="password"
        label="Password"
        type="password"
        placeholder="Password"
        component={TextField}
        validate={passwordRequired}
        hint={{
          to: '/reset',
          text: 'Forgot?'
        }}
      />

      {err && 
        <div className="Signin__error">
          {err === 'unknown' ? 'Something went wrong. Please try again later.' : err}
        </div>
      }

      <BtnContainer>
        <button
          className="btn btn_primary btn_solid_pink"
          type="submit"
          disabled={submitting || (invalid && submitFailed)}
        >
          {submitting ? 'Signing In...' : 'Sign In'}
        </button>
      </BtnContainer>
    </form>
  );
}

const SigninForm = reduxForm({
  form: 'signin'
})(Form);

const Signin = ({jwt}) => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const nextUrl = useMemo(() => {
    const {next} = qs.parse(location.search, {ignoreQueryPrefix: true});
    if(!next || next === '/undefined') return '/';
    return decodeURIComponent(next);
  }, [location]);

  const sessionExpiredMessage = useMemo(() => {
    const {sessionExpired} = qs.parse(location.search, {ignoreQueryPrefix: true});
    return sessionExpired !== undefined;
  }, [location]);

  useEffect(() => {
    if(jwt) {
      setLoading(true);
      signinWithJwt(jwt)
      .then(
        () => {
          history.push(nextUrl || '/');
        },
        err => {
          const queryParams = new URLSearchParams(location.search);
          queryParams.delete('session_token')
          history.replace({
            search: queryParams.toString(),
          })
          setLoading(false);
          setError((err.data && err.data.message) || 'unknown');
        }
      )
    }
  }, [jwt]);

  const handleSubmit = (values) => {
    return signin(values)
      .then(
        () => {
          history.push(nextUrl || '/');
        },
        err => {
          throw new SubmissionError({
            _error: (err.data && err.data.message) || 'unknown'
          })
        }
      )
  };

  const message = (
    <div className='Signin__expired'>
      <i className='fal fa-history'  />
      <p>Your session has expired due to inactivity.</p>
    </div>
  )

  if(jwt) {
    return (
      <div className="Signin page">
        <Header className="AuthLayout__header" transparent />
        <div className='container-1040'>
          <Loader loading={loading} color="white" />
        </div>
      </div>
    )
  }

  return(
    <Layout headline="Sign In" beforeHeadline={sessionExpiredMessage && message}>
      <div className="Signin relative">
        <Loader loading={loading} color="white" />
        <SigninForm onSubmit={handleSubmit} tokenError={error} />
        <div className="Signin__sso">
          <div className="Signin__separator">
            <Hint>
              Or log in with
            </Hint>
          </div>
          <BtnContainer>
            <Link
              className="btn btn_primary btn_solid_grey"
              to="/signin/sso"
            >
              <span className="far fa-building" />
              Your company account
            </Link>
          </BtnContainer>
        </div>

        <div className="margin-top-5">
          <Hint>
            No account yet
            <Link to="/signup">Sign up now {">"}</Link>
          </Hint>
        </div>
      </div>
    </Layout>
  )
};


const SigninWrapper = () => {
  const authPagesBase = config && config.onboarding && config.onboarding.base;
  const local = !!config && config.local; //show signin page when on localhost
  const location = useLocation();

  const redirectToOnboardingAuth = () => {
    window.location.replace(authPagesBase);
  }

  const jwt = useMemo(() => {
    const search = qs.parse(location.search, {ignoreQueryPrefix: true});
    return search.session_token || search.jwt;
  }, [location]);

  if(jwt || local) {
    return <Signin jwt={jwt} />
  }

  redirectToOnboardingAuth();
  return null;
}

export default SigninWrapper;
