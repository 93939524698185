import React from 'react'
import * as _ from 'ramda'
import {Link as L} from 'react-router-dom'
import Footer from 'theme/Footer'
import Header from 'theme/Header'

import './Layout.sass'

const Layout = ({headline = 'It starts with you', children, overlay, footer = true, withUser = true, beforeHeadline}) => (
  <div className="AuthLayout page">
    <Header className="AuthLayout__header" withUser={withUser} transparent />
    <div className="AuthLayout__container">
      <div className="AuthLayout__image">
        {overlay && typeof overlay !== 'boolean' &&
          <div className="AuthLayout__imageOverlay">
            {overlay}
          </div>
        }
        {overlay && typeof overlay === 'boolean' &&
          <div className="AuthLayout__imageOverlay"/>
        }
      </div>
      <div className="AuthLayout__imagePlaceholder"/>
      <main className="AuthLayout__content" id="main">
        <div className="AuthLayout__contentAligned">
          {beforeHeadline}
          <h1 className="AuthLayout__heading">
            {headline}
          </h1>
          <div className="AuthLayout__contents">
            {children}
          </div>
        </div>
      </main>
    </div>
    {footer && <Footer/>}
  </div>
);

export const Subheading = ({children}) => (
  <div className={`AuthLayout__subheading`}>
    {children}
  </div>
);

export const Result = ({children}) => (
  <p className={`AuthLayout__result`}>
    {children}
  </p>
);

export const Msg = ({children}) => (
  <div className={`AuthLayout__msg`}>
    {children}
  </div>
);

export const Hint = ({children}) => (
  <p className={`AuthLayout__hint`}>
    {children}
  </p>
);

// const linkStyles = ['default', 'white', 'pink', 'dark'];

export const Link = ({to, style='default', children, className, props}) => (
  <L className={`AuthLayout__link_${style} ${className}`} to={to} {...props}>
    {children}
  </L>
);

export default Layout;
