import React from 'react'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import {withState, lifecycle, withProps} from 'recompose'
import cx from 'classnames'

import './Toast.sass'


export default composeComponent 'Toast',
  withState 'timeoutId', 'saveTimeoutId', null
  withProps ({autoClose}) ->
    autoClose: autoClose ? true

  lifecycle
    componentDidUpdate: (prevProps) ->
      {visible, saveTimeoutId, close, autoClose, timeout} = @props
      if autoClose
        if visible and visible isnt prevProps.visible
          saveTimeoutId window.setTimeout(
            -> close?()
            (timeout || 10000)
          )

    componentWillUnmount: ->
      window.clearTimeout @props.timeoutId

  ({type = 'success', message, visible, close, children, title, opacity, className, noPadding, position = 'bottom'}) ->
    React.createElement("div", { \
      "className": (cx "Toast", visible: visible, type, className, position, {noPadding}),  \
      "onClick": (-> close?()),  \
      "style": (if opacity and visible then {opacity})
    },
      (if close?
        React.createElement("button", {"className": "btn-icon Toast__close", "onClick": (close), "aria-label": "close"},
          React.createElement("i", {"className": "far fa-times"})
        )
      ),
      (if not title? or title isnt false
        switch type
          when 'error'
            React.createElement("div", {"className": "Toast__title"},
              React.createElement("span", {"className": "far fa-exclamation-circle"}), """
              Error
""")
          when 'warning'
            React.createElement("div", {"className": "Toast__title"},
              React.createElement("span", {"className": "far fa-exclamation-triangle"}), """
              Warning
""")
          when 'info'
            React.createElement("div", {"className": "Toast__title"},
              React.createElement("span", {"className": "far fa-info-circle"}), """
              Info
""")
          when 'success'
            React.createElement("div", {"className": "Toast__title"},
              React.createElement("span", {"className": "far fa-check-circle"}), """
              Success
""")
      ),
      (if title? and title isnt false
        React.createElement("div", {"className": "Toast__title"},
          (title)
        )
      ),
      (message),
      (children)
    )
