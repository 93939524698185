import React, { useEffect, useMemo, useState } from 'react';
import {reduxForm, Field, SubmissionError} from 'redux-form';
import {parse} from 'qs';
import { useLocation } from 'react-router-dom';
import {required, email} from 'validate';
import {saveRedirUrl, saveDomain} from 'api';
import {TextField, BtnContainer} from 'auth/Form';
import Layout, {Link, Hint} from 'auth/Layout';
import {checkIsSSOEnabled, getAuthBySocial, checkIfWorkOSEnabled} from 'auth/requests';

import './SSO.sass';

const config = window.config;

const emailRequired = required('Enter an email address please');
const emailValid = email('Enter a valid email address please');

const samlUrl = config && config.sso && config.sso.samlUrl;
const samlHasbroUrl = config && config.sso && config.sso.samlHasbroUrl;
const samlEquitableUrl = config && config.sso && config.sso.samlEquitableUrl;
const samlPwcUrl = config && config.sso && config.sso.samlPwcUrl;
const samlIcfUrl = config && config.sso && config.sso.samlIcfUrl;
const samlIntuitiveUrl = config && config.sso && config.sso.samlIntuitiveUrl;
const samlZillowUrl = config && config.sso && config.sso.samlZillowUrl;
const samlOswaldCompaniesUrl = config && config.sso && config.sso.samlOswaldCompaniesUrl;
const samlBostonScientificUrl = config && config.sso && config.sso.samlBostonScientificUrl;
const samlSiteImproveUrl = config && config.sso && config.sso.samlSiteImproveUrl;
const samlAirbnbUrl = config && config.sso && config.sso.samlAirbnbUrl;
const samlSeattleCityLightUrl = config && config.sso && config.sso.samlSeattleCityLightUrl;

const SSOOrgs = ['pwc', 'hasbro','axa','oswald-companies','boston-scientific','siteimprove','icf','intuitive-surgical','zillow','airbnb','seattle-city-light'];
const authNMethods = ['samlHasbro', 'samlEquitable', 'samlOswaldCompanies', 'samlBostonScientific', 'samlSiteImprove', 'samlSeattleCityLight', 'samlIcf'];

const getProvider = (organizationName) => {
  switch (organizationName) {
    case 'pwc': return 'samlPwc';
    case 'hasbro': return 'samlHasbro';
    case 'axa': return 'samlEquitable';
    case 'oswald-companies': return 'samlOswaldCompanies';
    case 'boston-scientific': return 'samlBostonScientific';
    case 'siteimprove': return 'samlSiteImprove';
    case 'icf': return 'samlIcf';
    case 'intuitive-surgical': return 'samlIntuitive';
    case 'zillow': return 'samlZillow';
    case 'airbnb': return 'samlAirbnb';
    case 'seattle-city-light': return 'samlSeattleCityLight';
    default: return 'saml';
  }
};

const getSSOUrl = (organizationName) => {
  switch (organizationName) {
    case 'pwc': return samlPwcUrl;
    case 'hasbro': return samlHasbroUrl;
    case 'axa': return samlEquitableUrl;
    case 'oswald-companies': return samlOswaldCompaniesUrl;
    case 'boston-scientific': return samlBostonScientificUrl;              
    case 'siteimprove': return samlSiteImproveUrl;              
    case 'icf': return samlIcfUrl;
    case 'intuitive-surgical': return samlIntuitiveUrl;
    case 'zillow': return samlZillowUrl;
    case 'airbnb': return samlAirbnbUrl;
    case 'seattle-city-light': return samlSeattleCityLightUrl;
    default: return samlUrl;
  }
};

const getMethodName = (organizationName) => {
  switch (organizationName) {
    case 'axa': return 'equitableSaml';
    case 'oswald-companies': return 'oswaldCompaniesSaml';
    case 'boston-scientific': return 'bostonScientificSaml';
    case 'siteimprove': return 'siteImproveSaml';
    case 'seattle-city-light': return 'seattleCityLightSaml'
    default: return organizationName+'Saml';
  }
};

const SSOEmailForm = reduxForm({
  form: 'signin',
  enableReinitialize: true
})(({handleSubmit, submitFailed, submitting, invalid, error, formError}) =>
    <form onSubmit={handleSubmit}>
      <Field
        placeholder="Email address"
        name="email"
        label="Email"
        component={TextField}
        validate={[emailRequired, emailValid]}
      />

      {((submitFailed && error) || formError) &&
        <div className="Signin__error">
          {formError || (error === 'unknown' ? 'Something went wrong. Please try again later.' : error)}
        </div>
      }

      <BtnContainer>
        <button
          className="btn btn_primary btn_solid_pink margin-top-5"
          type="submit"
          disabled={submitting || (invalid && submitFailed)}
        >
          {submitting ? 'Signing In...' : 'Single Sign-On'}
        </button>
      </BtnContainer>
    </form>
)

const SSO = () => {
  const location = useLocation();
  const [error, setError] = useState();

  const onSubmit = ({email = ''}) => {
    const domain = email.replace(/.*@/, "");

    return checkIsSSOEnabled(domain)
    .then(
      (res) => {
        if(!res.ssoOnly) {
          throw new SubmissionError({_error: 'This email is not associated with an SSO account'});
        }
        else {
          checkIfWorkOSEnabled(domain)
          .then(
            (url) => {
              saveDomain(domain);
              window.location.href = url;
            }, (err) => { // legacy SSO
              const provider = getProvider(res.organizationName);
              const url = getSSOUrl(res.organizationName);
              const methodName = getMethodName(res.organizationName);

              if(authNMethods.indexOf(provider) > -1) {
                getAuthBySocial({samlMethod: methodName})
                  .then(
                    (res) => {
                      window.location.href = url+'?SAMLRequest='+encodeURIComponent(res);
                    }, (err) => {
                      throw new SubmissionError({_error: err.data ? err.data.message : 'Authorization by social error'});
                    }
                  )
              } else {
               window.location.href = url;
              }
            }
          )
        }
      }, (err) => {
        throw new SubmissionError({_error: err.data ? err.data.message : 'SSO disabled for organization'});
      }
    )
  }

  const configurationMissing = useMemo(() => {
    return !samlPwcUrl || !samlUrl || !samlIcfUrl || !samlIntuitiveUrl || !samlHasbroUrl || !samlEquitableUrl || !samlOswaldCompaniesUrl || !samlBostonScientificUrl || !samlSiteImproveUrl || !samlSeattleCityLightUrl;
  }, [samlPwcUrl, samlUrl, samlIcfUrl, samlIntuitiveUrl, samlHasbroUrl, samlEquitableUrl, samlOswaldCompaniesUrl, samlBostonScientificUrl, samlSiteImproveUrl, samlSeattleCityLightUrl]);

  const searchParams = useMemo(() => {
    return parse(location.search, {ignoreQueryPrefix: true});
  }, [location]);

  const defaultEmail = useMemo(() => {
    const {email} = searchParams;
    return email || '';
  }, [location]);

  useEffect(() => {
    const {redir} = searchParams;
    saveRedirUrl(redir);
  }, [location]);

  useEffect(() => {
    const {error} = searchParams;
    if(error === 'workos') {
      setError('WorkOS authorization error');
    }
  }, [searchParams]);

  return (
    <Layout headline="Single Sign On">
      {configurationMissing &&
        <p>Missing SSO Configuration</p>
      }
      {!configurationMissing &&
        <div className="SSO">
          <p className="SSO__info text-center">
            Single sign-on allows you to sign in to Imperative using your organization’s authentication.
          </p>
          <SSOEmailForm onSubmit={onSubmit} initialValues={{email: defaultEmail}} formError={error} />

          <Hint>
            <Link style="dark" to="/signin">
              Return to sign in
            </Link>
          </Hint>

          <div className="SSO__info text-center">
            <b>{"Don't have an account in this organization yet?"}</b><br/>
            Contact your administrator for an invitation.
          </div>

          <div className="SSO__privacy">
            <div className="Signup__textBox Signup__text">
              By continuing, you agree to our <a href="https://www.imperative.com/terms-of-service" target="_blank">Terms of Service</a>
            </div>
            <div className="Signup__textBox Signup__text">
              View our <a href="https://www.imperative.com/privacy-policy" target="_blank">Privacy Policy</a>
            </div>
          </div>
        </div>
      }
    </Layout>
  )
}

export default SSO;