import React from 'react';

const IntersitialRIG = ({t, setFocusRef}) => (
  <section>
    <header tabIndex="-1" ref={setFocusRef} className="Assessment__title">{t.find('assessment.page.1.title')}</header>
    <p className='margin-top-5'>
      {t.find('assessment.page.1.description1')}
    </p>
    <p className='margin-top-5'>
      {t.find('assessment.page.1.description2')}
    </p>
  </section>
);

export default IntersitialRIG;
