import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as states            from 'utils/states'
import qs from 'qs'
import {withRouter, Link} from 'react-router-dom'
import * as _ from 'ramda'
import {getUser} from 'api'
import {withHandlers, withStateHandlers, mapProps, withProps, withState, lifecycle} from 'recompose'
import moment from 'moment-timezone'
import cx from 'classnames'
import debounce from 'debounce-promise'
import {Collapse} from 'react-collapse'
import AsyncSelect from 'react-select/async'
import {fetchUser} from 'auth/requests'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Toast from 'theme/Toast'
import Modal, * as M from 'theme/Modal'
import Avatar from 'theme/Avatar'
import Subnav from 'theme/Subnav'
import {IMG_TIP} from 'theme/assets/assets'
import Competencies from 'Competencies'
import Calendar from './Calendar'
import Answers from './Answers'
import * as Fields from 'conversations/Questions/Fields'
import computeProps from 'conversations/computeAdditionalProps';
import Goals from 'conversations/Goals'
import Checkbox from 'theme/Checkbox'
import {getMembers,
        addPartner,
        updateConversation,
        getConversation,
        getUserConversations,
        getAnswers,
        sendMessage,
        getMessages,
        getJobHack,
        getRIGScores,
        getOneWords,
        updateJobHack,
        getConversationsDefinitions,
        updateAnswer}         from 'conversations/requests'
import MicroLesson from 'microLessons/MicroLesson'
import axios from 'axios'
import Actions from './Actions'
import OneWord from './OneWord'
import QualityRate from './QualityRate'
import Markdown from 'theme/Markdown'
import Card from './Card';
import InviteColleague from './InviteColleague';

import 'theme/scaffolding/scaffolding-styles.sass'
import './Conversation.sass'
import 'theme/type.sass'


debouncedSearch = debounce getMembers, 500
BREAK_LINE = '\n'
BULLETS_TO_PICK = 3

MESSAGES =
  error:
    loading: 'Cannot load data. Please try again later or contact the administrator.'
    partnerNotConducted: "Your partner hasn't finished the conversation yet. Please wait until they complete the conversation."

foundationsLinks = [
  {id: '279557844'}
  {id: '279557887'}
  {id: '279557990'}
  {id: '279558101'}
  {id: '279558167'}
]

export default composeComponent 'Conversation',
  withRouter
  withState 'dates', 'setDates', []
  withState 'allOneWords', 'setAllOneWords', []
  withState 'rigScores', 'setRigScores', []
  withState 'updatingAnswers', 'setUpdatingAnswers', false
  withState 'modalVisible', 'setModalVisibility', false
  withState 'modalMicroLesson', 'setModalToShow', null
  withState 'date', 'setDate', null
  withState 'programs', 'setPrograms', []
  withState 'vimeoError', 'setVimeoError', false
  withState 'widthSize', 'setWidthSize', 'normal'
  withState 'ratingConversations', 'setRatingConversations', false
  withState 'finished', 'setFinished', false
  withState 'inviteColleagueVisible', 'showInviteColleague', false
  withState 'isCompetenciesModalOpen', 'setIsCompetenciesModalOpen', false

  withStateHandlers ({history}) ->
    search = qs.parse history.location.search, ignoreQueryPrefix: true

    user: null
    loading: false
    conversation: null
    selectedPartner: null
    answers: []
    openedQuestions: []
    conversations: []
    status: null
    foundationsVideos: []
    jobHacks: null
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setConversation: -> (conversation) -> {conversation}
    setConversations: -> (conversations) -> {conversations}
    setAnswers: -> (answers) -> {answers}
    openQuestion: ({openedQuestions}) -> (q) ->
      if q.label in openedQuestions
        openedQuestions: _.without [q.label], openedQuestions
      else
        openedQuestions: _.append q.label, openedQuestions
    setOpenedQuestions: -> (questions) ->
      openedQuestions: _.pluck 'label', questions
    setStatus: -> (type, reason, error) ->
      status: {type, reason, error}
    clearStatus: -> -> status: null
    setFoundationsVideos: -> (foundationsVideos) -> {foundationsVideos}
    setJobHacks: -> (jobHacks) -> {jobHacks}

  withProps ({conversations, conversation, user, answers}) ->
    partner = conversation?.participant?.find (p) -> p.member.memberId isnt user.id
    questions = conversation?.questions.filter (question) ->
      (question?.answerType is 'BothWriteAnswer') or
      (partner?.meFirst and question?.answerType is 'FirstWriteAnswer') or
      (not partner?.meFirst and question?.answerType is 'SecondWriteAnswer')
    ans = questions?.map (q) ->
      foundAns = answers.find (a) -> a.memberId is partner?.member.memberId and a.questionId is q.label
      if foundAns?
        _.merge {
          questionId: q.label
          answer: ''
          memberId: partner?.member.memberId
        }, foundAns
      else {
        questionId: q.label
        answer: ''
        memberId: partner?.member.memberId
      }

    finishedConversations = conversations.filter (c) ->
      [programLabel, setLabel, convLabel] = _.split '/', c?.label ? ''
      me = c.participant?.find (m) -> m.member.memberId is user?.id
      c.label isnt conversation?.label and
        me?.state in [states.Finished] and
        not convLabel.includes 'intro_peer_coaching'

    firstConversation: finishedConversations.length is 0
    answers: ans ? []

  withStateHandlers
    notesEdition: false
    defaultAnswers: []
    newAnswers: []
  ,
    setNewAnswers: -> (newAnswers) -> {newAnswers}
    editNotes: ({answers, defaultAnswers}, {setAnswers}) -> (editing, oldAnswers) ->
      if editing
        {defaultAnswers: _.clone(oldAnswers ? answers), newAnswers: _.clone(oldAnswers ? answers), notesEdition: true}
      else
        setAnswers(oldAnswers ? defaultAnswers)
        {defaultAnswers: [], newAnswers: [], notesEdition: false}

  withHandlers
    setError: ({setStatus}) -> (reason) -> (error) ->
      setStatus 'error', reason, error
    setSuccess: ({setStatus}) -> (reason) -> ->
      setStatus 'success', reason
    showML: ({setModalVisibility, setModalToShow, jobHacks}) -> (label) ->
      jobHack = jobHacks.find (jh) ->
        jh.jobHack.label is label
      setModalVisibility true
      setModalToShow jobHack
    updateJH: ({setModalVisibility, showRatingModal}) ->
      (microLesson, optionToChange) ->
        changedOption = {"#{optionToChange}": !microLesson[optionToChange]}
        updateJobHack microLesson.jobHack.id, changedOption
        .then ->
          setModalVisibility false
        .then ->
          if changedOption?.completed
            showRatingModal microLesson
    closeMLModal: ({setModalVisibility}) -> -> setModalVisibility false

  withHandlers
    loadConversation: ({setJobHacks, setConversation, match: {params: {conversationId}}, history, user, setDates}) -> (u) ->
      uu = user ? u
      getConversation conversationId
      .then (conversation) ->
        if conversation.state in [states.Accepted, states.Scheduled, states.Started, states.Conducted]
          history.replace "/peer-coaching/#{conversation?.id}/conduct"
          null
        else
          setConversation conversation
          me = conversation.participant?.find (p) -> p.member.slug is uu.slug
          if me?.suggestedDates.length
            setDates me?.suggestedDates
          if conversation.scheduleDate and me.state is states.Accepted
            updateConversation conversation, state: states.Scheduled
            .then setConversation
          Promise.all conversation.jobhacksLabels.map (jh) -> getJobHack label: jh
          .then setJobHacks
          conversation
    createBulletPoints: -> (dynamic, memberId) ->
      unless dynamic or memberId
        return dynamic

      allBullets = dynamic.value.substring 1
      .split(BREAK_LINE)

      idSum = memberId.toString().split('').reduce(
        (sum, num) -> sum + parseInt(num)
        0
      )
      shift = Math.max(idSum % (allBullets.length - BULLETS_TO_PICK + 1), 0)
      pickedBullets = allBullets.slice(shift, shift + BULLETS_TO_PICK)

      pickedBullets
      .map (point) ->
        if point.indexOf('- ') is 0 then point else "- #{point}"
      .join(BREAK_LINE)

  withHandlers
    loadData: ({
      user, setUser, setDates, startLoading, endLoading, setConversation, setConversations
      setAnswers, loadConversation, match: {params: {conversationId}}, setOpenedQuestions,
      setFoundationsVideos, setVimeoError, history, editNotes, setPrograms, setRigScores
    }) -> ->
      startLoading()

      u =
        if user
          Promise.resolve user
        else
          fetchUser()
          .then (uu) ->
            setUser uu
            uu

      c =
        getUserConversations()
        .then setConversations

      t =
        getConversationsDefinitions()
        .then setPrograms

      f =
        Promise.all foundationsLinks.map (link) ->
          axios.get "https://vimeo.com/api/v2/video/#{link.id}.json"
          .then ({data}) ->
            id: data[0].id
            image: data[0].thumbnail_large
            name: link.name
            title: data[0].title
            duration: data[0].duration
          .catch () ->
            setVimeoError true

      rigReq =
        getRIGScores()
        .then setRigScores

      Promise.all [u, f, c, t, rigReq]
      .then ([user, foundationsVideos]) ->
        loadConversation user
        .then (conversation) ->
          if conversation
            setFoundationsVideos foundationsVideos
            setOpenedQuestions conversation.questions
            getAnswers(conversation.id)
            .then (ans) ->
              setAnswers ans
              search = qs.parse history.location.search, ignoreQueryPrefix: true
              editingNotes = search?.edit?
              if editingNotes
                editNotes true, ans
              ans
            conversation
      .finally endLoading

    loadOneWordsForConvId: ({setAllOneWords}) -> (pcId) ->
      getOneWords({pcId})
      .then setAllOneWords

  lifecycle
    componentDidMount: ->
      @props.loadData()

  withHandlers
    goToRating: ({setRatingConversations}) -> -> setRatingConversations true

    backToFinish: ({setRatingConversations}) -> -> setRatingConversations false

    finishConversation: ({conversation, setConversation, history}) -> ->
      updateConversation conversation,
        state: states.Finished
      .then setConversation
      .then window.location.href = '/'
      .catch setError 'partnerNotConducted'

    saveQualityRating: ({conversation, setConversation, showInviteColleague}) -> (rate, feedback) ->
      updateConversation conversation,
        qualityRate: rate,
        qualityFeedback: feedback
      .then setConversation
      .then( () =>
        rate > 2 && showInviteColleague(true)
      )

    editioning: ({setNewAnswers, answers}) -> (answer) -> (value) ->
      tempAnswers = answers.map (a) ->
        if a.questionId is answer.questionId
          a.answer = value
        a

      setNewAnswers tempAnswers

    updateAnswers: ({answers, user, defaultAnswers, setAnswers, conversation, editNotes, setUpdatingAnswers}) -> ->
      setUpdatingAnswers(true)
      updateAnswerPromises = answers.filter (a, i) ->
        def = defaultAnswers?.find (d) -> d.questionId is a.questionId and d.memberId is a.memberId
        user.id isnt a.memberId and a.answer isnt def?.answer
      .reduce((previousPromise, answer) ->
        previousPromise.then () ->
          updateAnswer(conversation.id, answer.answerId, answer.answer)
      , Promise.resolve())

      updateAnswerPromises.then () ->
        setUpdatingAnswers(false)
        getAnswers(conversation.id)
        .then (ans) ->
          setAnswers ans
          editNotes false, ans

  mapProps (props) ->
    {t, answers, createBulletPoints} = props
    _.evolve(
      conversation: (conversation) ->
        user = props.user
        grouped = _.groupBy _.prop('questionId'), answers
        [program, set, convLabel] = _.split '/', conversation?.label ? ''
        participant = conversation?.participant?.find (p) -> p.member.slug is user?.slug
        partnerWithDates = conversation?.participant?.find (p) -> p.member.slug isnt user?.slug and p.suggestedDates.length
        partners = conversation?.participant?.filter (p) -> p.member.slug isnt user?.slug

        _.merge conversation,
          program: program
          set: set
          convLabel: convLabel
          state: participant?.state
          image: if convLabel then t.find "peerconversation.conversation.#{convLabel}.image"
          me: participant
          partners: partners
          partner: partners?[0]
          goals: t.getSplitted "peerconversation.conversation.#{convLabel}.goals"
          suggestedDates: if partnerWithDates?.suggestedDates.length then partnerWithDates?.suggestedDates
          prework: conversation?.questions.filter (q) -> q.answerType is 'Prework'
    ,
      props
    )

  withProps (props) ->
    {conversation, programs, conversations, allOneWords, user, rigScores} = props
    if (!conversation || !conversation.convLabel || programs.length == 0)
      return

    {datePaired, prevConvsWithPartner} = computeProps(conversation, programs, conversations, user)

    convsWithScores = if !conversation then [] else
      [].concat(prevConvsWithPartner, conversation).filter(Boolean).map((c) =>
        participant = c.participant.map((p) =>
          oneWord = (allOneWords || []).find((o) => c && o.peerConversationId == c.id && p.member.memberId == o.forMemberId);
          rig = rigScores.filter((r) => c && r.peerConversationId == c.id && p.member.memberId == user.id);
          {...p, oneWord, rig}
        )
        {...c, participant}
      )
    translationsData = {convs: convsWithScores, conv: convsWithScores.find((s) => conversation && s.id == conversation.id)}

    {datePaired, prevConvsWithPartner, translationsData}

  lifecycle
    componentDidUpdate: (prevProps) ->
      {prevConvsWithPartner, allOneWords, conversation, loadOneWordsForConvId} = @props
      if (prevConvsWithPartner && !prevProps.prevConvsWithPartner)
        if (conversation && !allOneWords.length && prevConvsWithPartner)
          pcId = [].concat(prevConvsWithPartner, conversation).map((c) => c.id)
          loadOneWordsForConvId(pcId)

  mapProps (props) ->
    {t, answers, createBulletPoints, user, translationsData} = props
    _.evolve(
      conversation: (conversation) ->
        mappedQuestions = conversation?.questions?.map (question) ->
          content =  t.splitMarkdownToParts "peerconversation.question.#{question?.label}.title", user, translationsData
          dynamicI = content?.findIndex (c) -> c.dynamic
          dynamic = content[dynamicI]
          _.merge question, questionContent:
            if dynamic and dynamic.value.includes(BREAK_LINE)
              content.slice(0, dynamicI)
              .concat({title: false, dynamic: true, value: createBulletPoints(dynamic, user?.id)})
              .concat(content.slice(dynamicI + 1))
            else content
        _.merge conversation,
          questions: mappedQuestions ? []
    ,
      props
    )

  withProps ({conversation, dates, answers, user, conversations, programs}) ->
    setLabel = conversation?.set
    programLabel = conversation?.program

    program = programs.find (p) -> p.label is programLabel
    set = program?.sets.find (s) -> s.label is setLabel

    conversationsList: set?.definitions
    datesNotChanged: (dates.length is conversation?.me?.suggestedDates?.length) and
      conversation?.me?.suggestedDates?.every (d) -> dates.indexOf(moment(d).format()) isnt -1
    finishedPrework: conversation?.prework?.every (q) ->
      answers.find (a) -> a.questionId is q.label and a.memberId is user.id

  ({
    loading
    conversation
    conversationsList
    selectedPartner
    user
    t
    datesNotChanged
    answers
    openQuestion
    openedQuestions
    status
    clearStatus
    finishedPrework
    firstConversation
    modalVisible
    updateJH
    modalMicroLesson
    foundationsVideos
    closeMLModal
    ratingModalVisible
    closeRatingModal
    showML
    editNotes
    notesEdition
    editioning
    newAnswers
    updateAnswers
    vimeoError
    widthSize
    goToRating
    backToFinish
    ratingConversations
    finishConversation
    finished
    saveQualityRating
    programs
    updatingAnswers
    datePaired
    match: {params: {conversationId}}
    inviteColleagueVisible
    showInviteColleague
    isCompetenciesModalOpen
    setIsCompetenciesModalOpen
  }) ->
    scheduleDate = if conversation?.partner?.suggestedDates[0] then moment(conversation?.partner?.suggestedDates[0]).tz(user?.timeZone) else moment.tz(user?.timeZone).startOf 'day'

    timeZone = conversation?.partner?.member?.timeZone
    suggesting = not conversation.partner?.suggestedDates?.length
    canInviteColleague = user && user.organizationSettings && user.organizationSettings.partnerConversationsSettings && user.organizationSettings.partnerConversationsSettings.colleagueInviteEnabled

    Field = Fields.Text

    if conversation.state is states.Rejected
      return React.createElement("div", null)

    React.createElement("div", {"className": "Conversation page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("main", {"className": "container", "id": "main"},

        React.createElement("div", {"className": "Conversation__cards"},
          React.createElement("div", {"className": "Conversation__left hidden-xs"},
            React.createElement("div", {"className": "Conversation__goals"},
              React.createElement(Loader, {"loading": (loading), "color": "white"}),
              React.createElement(Goals, { \
                "conversation": (conversation),  \
                "conversations": (conversationsList),  \
                "className": "Conversation__goalsCard",  \
                "datePaired": (datePaired)
              })
            )
          ),

          React.createElement("div", {"className": "Conversation__right"},
            React.createElement("div", {"className": "Conversation__card"},
              React.createElement(Loader, {"loading": (loading), "color": "white"}),
              React.createElement("div", {"className": "Conversation__cardHeading blue"},
                React.createElement("h3", {"className": "Conversation__cardTitle text-center"}, """
                  Conversation Recap
"""),
                React.createElement(Avatar, {"for": (conversation?.me?.member), "size": "md", "className": "Conversation__cardHeading-avatar left hidden-xs"}),
                React.createElement(Avatar, {"for": (conversation?.partner?.member), "size": "md", "className": "Conversation__cardHeading-avatar right hidden-xs"})
              ),
              React.createElement("div", {"className": "Conversation__conversationResume"},
                React.createElement("div", null,
                  React.createElement("h1", null, """
                    Congratulations!
"""),
                  React.createElement("p", null, """
                    These notes that """, (conversation?.partner?.member.firstName), " ", (conversation?.partner?.member.lastName), """ took during your conversation were sent to your email.
"""),
                  React.createElement("div", {"className": "Conversation__tip"},
                    React.createElement("div", {"className": "Conversation__tipIcon"},
                      React.createElement("span", {"className": "Conversation__tipIconBg"},
                        React.createElement("img", {"src": (IMG_TIP), "alt": ""})
                      )
                    ),
                    React.createElement("span", null, "TIP:"), """
                    Use your notes here as a career development journal and reference them when preparing for career conversations.
""")
                ),
                  React.createElement("span", null,
                    React.createElement("div", {"className": "Conversation__cardDivider"}),
                    React.createElement("div", {"className": "text-center"},
                      React.createElement(Link, {"to": "/", "className": "btn btn_secondary btn_solid_bluePurple"}, """
                        Back To Dashboard
""")
                    )
                  )
              )
            ),
            (if(states.isAfter(conversation?.me?.state, states.Started))
              React.createElement(Actions, {"user": (user), "conversation": (conversation), "t": (t)})
            ),
            (if(states.isAfter(conversation?.me?.state, states.Started))
              React.createElement(OneWord, {"user": (user), "conversation": (conversation), "t": (t)})
            ),

            (if conversation?.questions?.length and ((conversation?.state is states.Conducted) or states.isAfter conversation?.state, states.Conducted)
              title =
                React.createElement("h4", {"className": "Conversation__subtitle"}, """
                  Your Conversation Notes (from partner)
""")
              titleActions =
                if notesEdition
                  React.createElement("div", {"className": "Conversation__cardContentButtons"},
                    React.createElement("button", {"className": "btn btn_secondary btn_outlined_bluePurple", "onClick": (-> editNotes false)}, """
                      Cancel Edit
"""),
                    React.createElement("button", {"onClick": (-> updateAnswers()), "disabled": (updatingAnswers), "className": "btn btn_secondary btn_solid_bluePurple"}, """
                      Save
""")
                  )
                else
                  React.createElement("div", null,
                    React.createElement("button", {"className": "btn btn_secondary btn_solid_bluePurple", "onClick": (-> editNotes true, answers)}, """
                      Edit
""")
                  )
              React.createElement(Card, {"title": (title), "buttons": (titleActions)},
                React.createElement(Loader, {"loading": (updatingAnswers), "color": "white"}),
                React.createElement(Answers, { \
                  "t": (t),  \
                  "conversation": (conversation),  \
                  "answers": (answers),  \
                  "user": (user),  \
                  "edition": (notesEdition),  \
                  "editioning": (editioning),  \
                  "updateAnswers": (updateAnswers),  \
                  "updatingAnswers": (updatingAnswers)
                })
              )
            )
          )
        ),

        (if conversation?.state is states.Finished and user and programs?.length
          React.createElement(Competencies, {"t": (t), "user": (user), "programs": (programs), "setIsCompetenciesModalOpen": (setIsCompetenciesModalOpen)})
        )

      ),

      React.createElement(Footer, null),

      React.createElement(Toast, { \
        "visible": (status?),  \
        "type": (status?.type),  \
        "message": (MESSAGES?[status?.type]?[status?.reason] ? status?.error),  \
        "close": (clearStatus)
      }),

      React.createElement(InviteColleague, { \
        "opened": (inviteColleagueVisible && canInviteColleague),  \
        "close": (() => showInviteColleague(false)),  \
        "user": (user),  \
        "conversationId": (conversationId)
      }),

      React.createElement(Modal, { \
        "isOpen": (modalVisible),  \
        "className": (cx "Questions__modal")
      },
        (if modalMicroLesson?.jobHack?.contentType is 'Video' and vimeoError
          React.createElement("div", {"className": "Conversation__vimeoError"},
            React.createElement("span", {"className": "fal fa-times", "onClick": (closeMLModal)}),
            React.createElement("h1", null, "Currently, video is not available."),
            React.createElement("h1", null, "If the problem occurs, please come back in a while.")
          )
        else
          React.createElement(MicroLesson, { \
            "updateMicroLesson": (updateJH),  \
            "microLesson": (modalMicroLesson),  \
            "modalState": (true),  \
            "user": (user),  \
            "foundationsVideos": (foundationsVideos),  \
            "close": (closeMLModal),  \
            "t": (t)
          })
        )
      ),

      (if conversation?.me and !loading and !isCompetenciesModalOpen
        React.createElement(QualityRate, { \
          "t": (t),  \
          "conversation": (conversation),  \
          "submit": (saveQualityRating),  \
          "user": (user)
        })
      )

    )
