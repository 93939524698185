import api from 'api';
import * as _ from 'ramda'
import * as ME from 'api/metrics'

export find = (token) ->
  api.get "/invitations/#{token}"

export findFor = ({slug}) ->
  api.get "/members/#{slug}/invitations"

export findInOrganization = ({code}, {slug: inviterSlug}, {query, size, accepted, sort}) ->
  params = _.pickBy _.compose(_.not, _.isNil), {accepted: accepted ? false, query, inviterSlug, size, sort}
  endpoint = "/organizations/#{code}/members/invitations"
  api.get endpoint, {params}

export sendFor = ({slug, org, isSuperAdmin}) -> (emails, organization, emailInvitation) ->
  ME.reportStateChange 'invitations_new-invitation-sending'
  organizationCode =
    if isSuperAdmin and organization
      organization.code
    else org?.code

  unless organizationCode
    return Promise.reject new Error "not in any organization"

  data = {emails: emails, emailInvitation: emailInvitation}

  api.post "/organizations/#{organizationCode}/members/invitations", data
  .then (data) ->
    ME.reportStateChange 'invitations_new-invitation-sended'
    Promise.resolve data
  .catch (error) ->
    Promise.reject new Error error

export teamInvite = (emails, slug, emailInvitation) ->
  data = {emails: emails, emailInvitation: emailInvitation}

  api.post "/teams/#{slug}/invitations", data
  .catch (error) -> new Error error

export workshopInvite = (emails, slug, emailInvitation) ->
  data = {emails: emails, emailInvitation: emailInvitation}

  api.post "/workshops/#{slug}/invitations", data
  .then (data) -> data
  .catch (error) -> new Error error

export revokeTeamInvitation = ({slug}, {token}) ->
  api.delete "/teams/#{slug}/invitations/#{token}"

export revoke = (memberId) ->
  api.delete "/invitations/byMember/#{memberId}"

export search = (query, opts) ->
  console.log 'TODO', search
  # I.filter where:
  #   isAccepted: false
  #   email: contains: query
  # , opts

export reminder = (emails) ->
  Promise.all emails.map (e) ->
    api.post "/surveys/purpose-assessment/reminders", email: e.email
    .then (data) -> {type: 'success', data}
    .catch (error) -> {type: 'error', error}
