import React from 'react'
import cx from 'classnames'
import * as _ from 'ramda'
import RSelect, {components} from 'react-select'
import {composeComponent} from 'utils/react-tools'
import {withStateHandlers, withHandlers} from 'recompose'
export {default as Slider} from './Slider'


import './Form.sass'

export TextField = composeComponent 'Form.TextField',
  ({input, label, required, placeholder, className, textarea, disabled, info,
  meta: {touched, error, warning}, type, maxlength, withoutError, setRef}) ->
    React.createElement("div", {"className": (cx("Form__field", className, {disabled}))},
      React.createElement("div", {"className": "Form__label"},
        (if label
          React.createElement("label", {"className": (cx({required})), "htmlFor": (input.name)},
            (label)
          )
        ),
        React.createElement("div", {"className": "Form__labelInfos"},
          (if touched and error and not withoutError
            React.createElement("span", {"className": (cx "Form__fieldError", withLabel: label?)}, (error))
          ),
          (if touched and warning
            React.createElement("span", {"className": (cx "Form__fieldWarning", withLabel: label?)}, (warning))
          ),
          (!error? && !warning? && info &&
            React.createElement("span", {"className": (cx "Form__fieldInfo", withLabel: label?)}, (info))
          )
        )
      ),
      React.createElement("div", {"className": "Form__input"},
        (if textarea
          React.createElement("textarea", Object.assign({ \
            "placeholder": (placeholder),  \
            "className": (cx({
              'has-value': input.value and input.value isnt '',
              err: touched and error
              warn: touched and warning
            })),  \
            "disabled": (disabled),  \
            "maxLength": (maxlength),  \
            "ref": (setRef)
            }, input
          ))
        else
          React.createElement("input", Object.assign({ \
            "type": (type || "text"),  \
            "placeholder": (placeholder),  \
            "className": (cx({
              'has-value': input.value and input.value isnt '',
              err: touched and error
              warn: touched and warning
            })),  \
            "maxLength": (maxlength),  \
            "disabled": (disabled),  \
            "ref": (setRef)
            }, input
          ))
        ),
        (if touched and warning
          React.createElement("span", {"className": "Form__fieldIcon fas fa-exclamation-triangle"})
        )
      )
    )

export Select =
  ({input
    label
    required
    placeholder
    options
    meta
    search
    creatable
    className
    disabled
    loading
    isMulti
    optionClassName
    selectClassName
    withoutError
  }) =>
    {touched, error, warning} = meta

    Option = (props) ->
      React.createElement("div", {"className": (cx "Form__selectOption", optionClassName? props.data)},
        React.createElement(components.Option, Object.assign({},  props ))
      )

    selectedOption =
      if isMulti
        options?.filter (o) -> o.value in input.value
      else options?.find (o) -> o.value is input.value

    handleChange = (option) ->
      value = if isMulti then _.pluck 'value', option else option.value
      input.onChange value

    React.createElement("div", {"className": (cx("Form__field", className, {disabled}))},
      React.createElement("div", {"className": "Form__label"},
        (if label
          React.createElement("label", {"className": (cx({required})), "htmlFor": (input.name)},
            (label)
          )
        ),
        React.createElement("div", {"className": "Form__labelInfos"},
          (if touched and error and not withoutError
            React.createElement("span", {"className": (cx "Form__fieldError", withLabel: label?)}, (error))
          ),
          (if touched and warning
            React.createElement("span", {"className": (cx "Form__fieldWarning", withLabel: label?)}, (warning))
          )
        )
      ),
      React.createElement("div", {"className": "Form__input"},
        React.createElement(RSelect, { \
          "className": (cx("Form__select", {err: touched && error}, selectClassName)),  \
          "classNamePrefix": (cx("Form__select", selectClassName)),  \
          "options": (options),  \
          "onChange": (handleChange),  \
          "value": (selectedOption),  \
          "placeholder": (placeholder),  \
          "disabled": (disabled),  \
          "autoBlur": true,  \
          "isMulti": (isMulti),  \
          "components": ({Option})
        })
      )
    )

export Radio =
  ({options, value, onChange, question, className, disabled = false}) =>
    React.createElement("div", {"className": (cx("Form__field", className, {disabled}))},
      (options.map (o, i) ->
        selected = o.value is value
        React.createElement("label", { \
          "key": (i),  \
          "className": (cx("Form__option radio", selected and "selected")),  \
          "onClick": (-> onChange(o.value))
        },
          React.createElement("input", { \
            "type": "checkbox",  \
            "checked": (selected),  \
            "onChange": (-> onChange(o.value)),  \
            "id": "Form__option#{o.value}"
          }),
          React.createElement("div", null,
            React.createElement("p", {"className": "Form__radioLabel"},
              (o.label)
            ),
            React.createElement("i", {"className": (cx("Form__radioMark", "#{if selected then 'fas fa-check-circle' else 'fal fa-circle'}"))})
          )
        )
      )
    )

export Multi =
  ({options, value = [], onChange, question, className, disabled = false}) =>
    React.createElement("div", {"className": (cx("Form__field", className, {disabled}))},
      (options.map (o, i) ->
        selected = value.indexOf(o.value) > -1
        select = (v) =>
          if(selected)
            onChange(_.without([v], value))
          else onChange([...value, v])
        React.createElement("label", { \
          "key": (i),  \
          "className": (cx("Form__option radio", selected and "selected"))
        },
          React.createElement("input", { \
            "type": "checkbox",  \
            "checked": (selected),  \
            "onChange": (-> select(o.value)),  \
            "id": "Form__option#{o.value}"
          }),
          React.createElement("div", null,
            React.createElement("p", {"className": "Form__radioLabel"},
              (o.label)
            ),
            React.createElement("i", {"className": (cx("Form__radioMark", "#{if selected then 'fas fa-check-square' else 'fal fa-square'}"))})
          )
        )
      )
    )
