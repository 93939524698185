import React from 'react'
import cx from 'classnames'


export default Ul = ({children, className, size}) ->
  React.createElement("ul", { \
    "className": (cx "TeamReport__ul", className,
      size and "TeamReport__ul_#{size}"
    )
  }, (children))
