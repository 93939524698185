import React, {useEffect, useState} from 'react';
import {getUser} from 'api';
import cx from 'classnames';

import './DeprecatedSite.sass';

const logger = window.DD_LOGS && window.DD_LOGS.createLogger('Admin page access', {level: 'info'});

const DeprecatedSite = (props) => {
  const user = getUser() || {};
  const [hidden, hide] = useState(false);

  useEffect(() => {
    logger && logger.info('Admin page access', {user_id: user.id})
  }, []);

  return (
    <>
      {props.children}
      {user.isSuperAdmin &&
        <div className={cx(['DeprecatedSite', {hidden}])} onClick={() => hide(true)}>
          <i className="fal fa-exclamation-triangle" />
          Please use the new admin tools. This section of the application has been retired and will no longer be actively maintained.
        </div>
      }
    </>
  )
}

export default DeprecatedSite;
