import api, {getUser, apiWholeRequest} from 'api'
import * as _ from 'ramda'
import moment from 'moment'
import * as errors from './errors'
import {translations as t} from 'translations'

BothWriteAnswerOneByOne = 'BothWriteAnswerOneByOne'
Action = 'Action'
mapTo = ['FirstWriteAnswer', 'SecondWriteAnswer']


mapWithDefinitions = (conversation, resolve, reject) ->
  [programLabel, setLabel, convLabel] = _.split '/', conversation?.label ? ''
  getConversationsDefinitions()
  .then (definitions) ->
    program = definitions.find (d) -> d.label is programLabel
    set = program?.sets.find (s) -> s.label is setLabel
    conv = set?.definitions.find (c) -> c.label is convLabel
    questions = conv?.questions.reduce(
      (arr, q, i) ->
        if q.answerType isnt BothWriteAnswerOneByOne and q.answerType isnt Action
          _.append _.mergeRight(q, _i: i), arr
        else
          _.concat arr, mapTo.map (answerType) ->
            _.mergeRight q, {answerType, oneByOne: true, _i: i, action: q.answerType is Action}
      []
    )

    orderI = set?.definitions?.findIndex (c) -> c.label is convLabel
    order = if orderI isnt -1 then "#{orderI + 1} of #{set?.definitions?.length}"


    resolve _.mergeAll [conv, questions: questions, conversation, {order}, orderIndex: orderI + 1]
  , reject

export getParticipants = (memberSlugs = []) ->
  Promise.all memberSlugs.map (m) ->
    api.get "/colleagues/#{m}"

export getTwilioToken = (user) ->
  api.get '/twilio'
  .then ({value}) -> value

export getConversations = (organizationCode, params = {}) ->
  api.get '/peerconversations/cplquery', params: _.merge params, organizationCode: organizationCode

export getUserConversations = ->
  api.get '/peerconversations'

export getMatchingGroups = (workshop) ->
  api.get "/peerconversationmatcher/workshop/#{workshop.id}"

export getWorkshops = (organizationCode) ->
  api.get "/organizations/#{organizationCode}/workshops"

export getAllWorkshops = (organizationCode) ->
  api.get "/organizations/#{organizationCode}/workshops", params: {includeArchived: true}

export getConversationsDefinitions = ->
  api.get '/peerconversationdefinition/list'

export deleteConversation = (conversation) ->
  api.delete "/peerconversations/#{conversation.id}"

export getConversation = (conversationId) ->
  new Promise (resolve, reject) ->
    api.get "/peerconversations?id=#{conversationId}"
    .then _.head
    .then (conversation) ->
      mapWithDefinitions conversation, resolve, reject
    , reject

export getAnswers = (peerConversationId) ->
  api.get "/peerconversations/#{peerConversationId}/answer"

export sendAnswer = (peerConversationId, questionId, answer, answerMemberId) ->
  api.post "/peerconversations/#{peerConversationId}/answer", {questionId, answer, answerMemberId}

export updateAnswer = (peerConversationId, answerId, answer) ->
  api.patch "/peerconversations/#{peerConversationId}/answer/#{answerId}/edit", {answer}

export getMembers = (query, params = {}) ->
  user = getUser()
  params = _.merge {query, page: 0, size: 10, assessed: true, sort: 'slug', role: ['Employee', 'FriendOrFamily']}, params

  api.get "organizations/#{user.org.code}/members", {params}
  .then ({results}) ->
    results

export addPartner = (conversation, member) ->
  api.post "/peerconversations/#{conversation.id}/partners/#{member.id}"

export updateConversation = (conversation, data) ->
  new Promise (resolve, reject) ->
    api.post "/peerconversations/#{conversation.id}", data
    .then (data) ->
      if data?.forwarded?
        resolve conversation
      else
        mapWithDefinitions data, resolve, reject
    , (error) ->
      switch error.status
        when 400
          reject errors.updateConversation[error.data.message]
        else reject error

export updateUserTimezone = (user, timeZone) ->
  api.patch "/members/me", {timeZone}

export getSetFeedback = (conversation, user) ->
  return Promise.reject unless conversation? or user?
  [programLabel, setLabel, convLabel] = _.split '/', conversation.label
  partner = conversation.participant.find (p) -> p.member.memberId isnt user.id

  api.get "/peerconversationset/feedback", params:
    partnerMemberId: partner?.member.memberId
    memberId: user.id
    setLabel: setLabel
    programLabel: programLabel

export sendFeedback = (userId, partnerId, program, set, option, note) ->
  unless userId or partnerId or program or set or option
    return Promise.reject 'not enough data'
  api.post "/peerconversationset/feedback",
    memberId: userId
    partnerMemberId: partnerId
    peerConversationDefinitionProgramLabel: program
    peerConversationDefinitionSetLabel: set
    partnerFeedbackLabel: option
    partnerFeedbackNote: note

export answerAvailable = (conversation, after = 0, cancelToken) ->
  apiWholeRequest.get "/peerconversations/#{conversation.id}/answer/available",
    cancelToken: cancelToken
    params:
      after: after + 1
  .then ({status}) ->
    if status is 204
      Promise.reject("No new answers")
    else
      Promise.resolve()

export syncConversation = (conversationId, dto = {}, cancelToken) ->
  api.post "/peerconversations/#{conversationId}/sync", {isTyping: false, ...dto}, {cancelToken}

export getJobHack = (params) ->
  api.get "/jobhack", params: params
  .then (data) ->data.jobHacks[0]

export updateJobHack = (id, params) ->
  api.post "/jobhack/#{id}", params

export checkPartnerStatus = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/sync"

export getOrganizations = ->
 api.get "/organizations"

export getActionAreas = () ->
  api.get "/peerconversations/actions/commitment/areas"
  .then (areas) -> areas.sort (a, b) -> a.id - b.id

export getSubInvestmentAreas = () ->
  api.get "/peerconversations/actions/commitment/sub-investment-areas"

export sendActionCommitment = (conversationId, dto) ->
  api.post "/peerconversations/#{conversationId}/actions/commitment", dto

export syncActionCommitment = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/actions/commitment/sync"

export getActionCommitment = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/actions/commitment"

export updateActionCommitment = (conversationId, commitmentId, dto) ->
  api.patch "/peerconversations/#{conversationId}/actions/commitment/#{commitmentId}", dto

export getPreviousCommitment = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/actions/previous-commitments"

export sendActionConfirmation = (conversationId, dto) ->
  api.post "/peerconversations/#{conversationId}/actions/confirmation", dto

export updateActionConfirmation = (conversationId, confirmationId, dto) ->
  api.patch "/peerconversations/#{conversationId}/actions/confirmation/#{confirmationId}", dto

export getActionConfirmation = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/actions/confirmation"

export syncActionConfirmation = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/actions/confirmation/sync"

export checkIfRigIsEnabled = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/is-rig-pulse-enabled"

export sendConversationRIGScore = (conversationId, score) ->
  api.post("/rig/peerconversations/#{conversationId}/score", score)

export getConversationRIGMetadata = (conversationId) ->
  api.get("/rig/peerconversations/#{conversationId}/metadata")

export getConversationRIGScore = (conversationId) ->
  api.get("/rig/peerconversations/#{conversationId}/scores")

export createOneWord = (conversationId, dto) ->
  api.post "/peerconversations/#{conversationId}/one-word-check-ins", dto

export updateOneWord = (conversationId, checkinId, dto) ->
  api.patch "/peerconversations/#{conversationId}/one-word-check-ins/#{checkinId}/edit", dto

export syncOneWord = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/one-word-check-ins/sync"

export getOneWords = (params = {}) ->
  api.get "/peerconversations/one-word-check-ins", params

export getRIGScores = () =>
  api.get("/rig/peerconversations/scores")

export getOneWord = (conversationId) ->
  api.get "/peerconversations/#{conversationId}/one-word-check-ins"

export sendJoinNotification = (conversationId) ->
  api.post "/peerconversations/#{conversationId}/join"

export inviteColleagues = (dto) ->
  api.post "/colleagues/invite", dto

export getConversationQuestionsAnswers = (pcId) ->
  api.get("/conversation-questions", {params: {pcId}})

export getConversationQuestionsDefinitions = () ->
  api.get("/conversation-questions/definitions")

export sendConversationQuestionsAnswer = (conversationId, dto) ->
  api.post("/conversation-questions/#{conversationId}/answer", dto)
