export const ordinal = (count) => {
  if(count == undefined) return '';
  const mod10 = count % 10;
  const mod100 = count % 100;

  if (mod10 == 1 && mod100 != 11) {
      return count + "st";
  }
  if (mod10 == 2 && mod100 != 12) {
      return count + "nd";
  }
  if (mod10 == 3 && mod100 != 13) {
      return count + "rd";
  }
  return count + "th";
}

export const articleFor = (text) => {
    const vowels = ['a', 'e', 'i', 'o', 'u', 'y'];
    const firstLetter = text.slice(0, 1).toLowerCase()
    return vowels.indexOf(firstLetter) == -1 ? 'a' : 'an';
}
