
import * as states from 'utils/states';

export default function computeProps(conversation, definitions, conversations, user) {
  if(!conversation || !definitions || !conversations || !user)
    return {};
  const {program: programLabel, set: setLabel, convLabel} = conversation;
  const program = definitions.find((d) => d.label == programLabel);
  const set = program.sets.find((d) => d.label == setLabel);

  const startedConversations = conversations.filter((c) => {
    const me = c.participant.find((m) => m.member.memberId == user.id)
    return states.isAfter(me.state, states.Scheduled);
  });

  const finishedConversations = conversations.filter((c) => {
    const [pL, sL, cLabel] = c.label.split('/');
    const me = c.participant.find((m) => m.member.memberId == user.id);
    return (cLabel.indexOf('intro_peer_coaching') == -1 &&
        [states.PostActionsFilled, states.Finished].indexOf(me.state) > -1);
  });

  const conversationIndexInSet = set.definitions.findIndex((c) => c.label == convLabel);
  const nextConversationsDefinitions = set.definitions.slice(conversationIndexInSet + 1).map((d) => d.label);
  const prevConversationsDefinitions = set.definitions.slice(0, conversationIndexInSet).map((d) => d.label);

  const convsWithSamePartner = conversations.filter((conv) => {
    const [programL, setL, label] = (conv.label || '').split('/');
    return (
      conv.state != states.Rejected &&
      programL == programLabel && setL == setLabel &&
      conv.participant
      .find((p) => p.member.memberId == conversation.partner.member.memberId)
    );
  })
  .sort((conv1, conv2) => {
    const conv1I = set.definitions.findIndex((d) => `${programLabel}/${setLabel}/${d.label}` == conv1.label)
    const conv2I = set.definitions.findIndex((d) => `${programLabel}/${setLabel}/${d.label}` == conv2.label)
    return conv1I - conv2I
  })
  .map((c) => {
    const [programL, setL, label] = (c.label || '').split('/');
    return {...c, label}
  });

  const nextConvsWithPartner =
    convsWithSamePartner.filter((conv) => {
      const me = conv.participant.find((p) => p.member.memberId == user.id);
      return me && [states.Created, states.Accepted, states.Scheduled].indexOf(me.state) > -1 &&
      (nextConversationsDefinitions || []).indexOf(conv.label) > -1;
    });

  const convsToFinishBeforeEndDate = convsWithSamePartner.filter((conv) => {
    const me = conv.participant.find((p) => p.member.memberId == user.id);
    return me && [states.Created, states.Accepted, states.Scheduled, states.Started, states.Conducted].indexOf(me.state) > -1 &&
    (nextConversationsDefinitions || []).indexOf(conv.label) > -1;
  });

  const prevConvsWithPartner =
    convsWithSamePartner.filter((conv) => {
      const me = conv.participant.find((p) => p.member.memberId == user.id);
      return me && me.state && states.isAfter(me.state, states.Scheduled) &&
      (prevConversationsDefinitions || []).indexOf(conv.label) > -1;
    }).map(conv => ({...conv, convIInSet: set.definitions.findIndex((c) => c.label == conv.label)}));

  const prevConvs = convsWithSamePartner.filter((conv) => {
    return (prevConversationsDefinitions || []).indexOf(conv.label) > -1;
  })
  const allPrevConvsFinished = prevConvs.length == 0 ||
    prevConvs.every(conv => {
      const me = conv.participant.find((p) => p.member.memberId == user.id);
      return me && me.state && me.state == states.Finished;
    });

  const nextConversation = nextConvsWithPartner.length > 0 && nextConvsWithPartner[0];
  const prevConversation = prevConvsWithPartner.length > 0 && prevConvsWithPartner[prevConvsWithPartner.length - 1];

  const datePaired = convsWithSamePartner.reduce((result, conv) => {
    const me = conv.participant.find((p) => p.member.memberId == user.id);
    const date = me.createdAt;
    if(!result) return date;

    const resultMe = result.participant && result.participant.find((p) => p.member.memberId == user.id);
    const firstDate = resultMe ? resultMe.createdAt : result;
    return firstDate > date ? date : firstDate;
  }, null);

  return {
    nextConversation,
    prevConversation,
    allPrevConvsFinished,
    convsToFinishBeforeEndDate,
    firstConversation: finishedConversations.length == 0,
    conversationIndexInSet,
    onboardingVisible: startedConversations.length == 0,
    datePaired,
    nextConversations: nextConvsWithPartner,
    prevConvsWithPartner
  }
}
