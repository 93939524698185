import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as _ from 'ramda'
import {email as validateEmail} from 'validate'
import {withHandlers, withProps, withState} from 'recompose'
import cx from 'classnames'

import 'theme/scaffolding/scaffolding-styles.sass'
import './Multiinput.sass'

SEPARATOR = /\s*[,;\s]\s*/


export default composeComponent 'Invite.Multiinput',
  withState 'focused', 'setFocused', false
  withState 'inputRef', 'setInputRef', null
  withState 'value', 'setValue', ''
  withHandlers
    addItem: ({setValue, onChange, items}) -> (item) ->
      onChange [].concat items, item
      setValue ''
    removeItem: ({setValue, onChange, items}) -> (item) ->
      onChange _.without [item], items
      setValue ''
    focus: ({inputRef, setFocused}) -> ->
      inputRef && inputRef.focus()
      setFocused(true)
    blur: ({inputRef, setFocused}) -> ->
      inputRef && inputRef.blur()
      setFocused(false)
  withHandlers
    onChange: ({setValue, onChange, pasting, setPaste, items}) -> (e) ->
      value = e.target?.value ? e
      parts = value.split SEPARATOR
      if(parts.length > 1)
        newItems = _.uniq [].concat items, parts.filter((p) -> p.length)
        onChange newItems
        setValue ''
      else
        setValue value

  withHandlers
    onKeyDown: ({addItem, removeItem, value, items}) -> (e) ->
      switch e.key
        when 'Enter', 'Escape', 'Tab'
          addItem(value)
        when 'Backspace'
          if(value.length == 0 && items.length)
            removeItem(items[items.length - 1])

  withProps ({items}) ->
    errors = items.filter (item) -> validateEmail('invalid')(item)
    itemsWithErrors = items.map((i) =>
      error = errors.find((e) => e == i)
      {errored: error?, email: i}
    )

    {errors, items: itemsWithErrors}

  ({
    items
    addItem
    removeItem
    value
    onChange
    errors
    onKeyDown
    focus
    blur
    focused
    setInputRef
    placeholder
    inputRef
  }) ->

    React.createElement("div", {"className": "Multiinput"},
      (if errors?.length
        React.createElement("div", {"className": "Multiinput__error"},
          (errors.length), " email", (if errors.length > 1 then 's' else ''), " ", (if errors.length > 1 then 'are' else 'is'), """ invalid. Double check the spelling and formatting.
""")
      ),
      React.createElement("div", { \
        "className": (cx "Multiinput__input", {focused}),  \
        "onClick": (-> if focused then blur() else focus())
      },
        (items.map (item, i) ->
          React.createElement("span", { \
            "key": (i),  \
            "className": (cx "Multiinput__item", {error: item.errored}),  \
            "onClick": ((e) ->
              removeItem(item.email)
              e.stopPropagation()
            )
          },
            (item.email),
            React.createElement("span", {"className": "fal fa-times"})
          )
        ),
        React.createElement("input", { \
          "ref": (setInputRef),  \
          "value": (value),  \
          "placeholder": (if !items.length then placeholder ? 'Enter email addresses each separated by a comma or a semicolon' else ''),  \
          "onChange": (onChange),  \
          "onKeyDown": (onKeyDown),  \
          "onFocus": (focus),  \
          "onBlur": (blur),  \
          "onClick": ((e) -> e.stopPropagation()),  \
          "aria-label": "members"
        })
      )
    )
