import React from 'react'
import * as _ from 'ramda'
import moment from 'moment'
import Page from 'reports/PrintPage'
import Logo from './Logo'
import Heading from './Heading'
import P from './P'
import {
  MeAndTeamOverviewPage
  MeAndTeamWhoDriverPage
  MeAndTeamWhyDriverPage
  MeAndTeamHowDriverPage
} from './MeAndTeam'


export default ({report, teamMember}) ->
  teamMemberDrivers = _.pluck 'driver', teamMember?.assessmentResult.drivers ? []
  teamDrivers = _.pluck 'driver', report.drivers ? []

  name =  "#{teamMember?.firstName} #{teamMember?.lastName}"
  number = 2 # page numbering start
  date = moment().format 'MMM Do, YYYY'
  footer = "#{name} + #{report.name} Team Report \u00b7 #{date} \u00b7 \u00a9 Imperative"

  React.createElement("section", {"className": "TeamReport__content"},
    React.createElement(Page, {"key": (number), "background": (React.createElement("div", {"className": "TeamReport__coverBg"}))},
      React.createElement("div", {"className": "TeamReport__coverTextBlock"},
        React.createElement(Heading, null,
          (name), " + ", (report.name),
          React.createElement("br", null), """
          Team Report
"""),
        React.createElement(P, null, """
          Prepared for """, (name), " on ", (date), """.
""")
      ),
      React.createElement(Logo, {"className": "TeamReport__coverLogo"})
    ),

    React.createElement(MeAndTeamOverviewPage, { \
      "number": (number++),  \
      "footer": (footer),  \
      "me": (teamMemberDrivers),  \
      "team": (teamDrivers),  \
      "key": (number)
    }),

    React.createElement(MeAndTeamWhoDriverPage, { \
      "number": (number++),  \
      "footer": (footer),  \
      "myName": (name),  \
      "teamName": (report.name),  \
      "me": (teamMemberDrivers.who),  \
      "team": (teamDrivers.who),  \
      "key": (number)
    }),

    React.createElement(MeAndTeamWhyDriverPage, { \
      "number": (number++),  \
      "footer": (footer),  \
      "myName": (name),  \
      "teamName": (report.name),  \
      "me": (teamMemberDrivers.why),  \
      "team": (teamDrivers.why),  \
      "key": (number)
    }),

    React.createElement(MeAndTeamHowDriverPage, { \
      "number": (number++),  \
      "footer": (footer),  \
      "myName": (name),  \
      "teamName": (report.name),  \
      "me": (teamMemberDrivers.how),  \
      "team": (teamDrivers.how),  \
      "key": (number)
    })
  )
