import React from 'react'
import PT from 'prop-types'
import * as _ from 'ramda'
import cx from 'classnames'
import {URL_DRIVERS} from 'theme/assets/assets'

DIMENSIONS =
  who: 'impact'
  why: 'values'
  how: 'craft'


export default ({driver, dimension}) ->

  dominant = driver.dominant

  React.createElement("div", {"className": "LongReport__driverPage"},
    (if dominant
      React.createElement("h2", {"className": "LongReport__driverPageDominant second"}, """
        DOMINANT
""")
    ),
    React.createElement("div", {"className": (cx \
      "LongReport__driverPageHeader LongReport__driverPageHeader_small"
      , not dominant and dimension
      , LongReport__driverPageHeader_bordered: dominant
    )},
      React.createElement("img", { \
        "src": "#{URL_DRIVERS}/#{dimension}#{driver.title}.png",  \
        "className": "LongReport__driverPageHeaderIcon LongReport__driverPageHeaderIcon_small",  \
        "alt": (driver.title)
      }),
      React.createElement("div", {"className": "LongReport__driverPageHeaderContent LongReport__driverPageHeaderContent_small"},
        React.createElement("h2", {"className": (cx \
          "LongReport__subtitle LongReport__subtitle_bold LongReport__subtitle_black"
          , not dominant and dimension
        )}, """
          Optimizing Your """, (_.concat _.head(DIMENSIONS[dimension].toUpperCase()), _.tail DIMENSIONS[dimension]), " Driver - ", (driver.title)
        )
      )
    ),

    React.createElement("div", {"className": (cx \
      "LongReport__driverPageContent"
      , not dominant and dimension
      , dominant: dominant
    )},
      React.createElement("div", {"className": (cx "LongReport__powerUp", not dominant and dimension)},
        React.createElement("div", {"className": (cx "LongReport__powerUpTitle", not dominant and dimension)},
          React.createElement("div", {"className": "LongReport__powerUpTitleImage"})
        ),
        React.createElement("div", {"className": "LongReport__powerUpContent"},
          React.createElement("p", {"className": "LongReport__text"}, """
            Everybody has superpowers, but not everybody puts them to work. Building
            core skills around this driver will amplify fulfillment, productivity, and help you
            bring out your inner superhero!
"""),
          React.createElement("div", {"className": "LongReport__text"}, """
            Read through the following competencies that are core to this driver, and score
            yourself on a scale from 1 to 3. Use this scale to help you prioritize development.
""", React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_number"},
              React.createElement("span", {"className": "LongReport__listItemContent"}, "I could greatly benefit from developing this.")
            ),
            React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_number"},
              React.createElement("span", {"className": "LongReport__listItemContent"}, "I’m alright at this but could be better.")
            ),
            React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_number"},
              React.createElement("span", {"className": "LongReport__listItemContent"}, "I have already mastered this.")
            )
          ),
          React.createElement("div", {"className": "LongReport__text"},
            (driver.powerUpBullets.map (bullet, i) ->
              React.createElement("span", {"key": (i), "className": "LongReport__listItem LongReport__listItem_dash"},
                React.createElement("span", {"className": "LongReport__listItemContent"},
                  (bullet)
                )
              )
            )
          )
        )
      ),
      React.createElement("h3", {"className": (cx \
      "LongReport__subtitle LongReport__subtitle_small LongReport__subtitle_black"
      , not dominant and dimension
      )}, """
        What else could help you power-up this driver?
""")

    )
  )
