import React from 'react'
import {withRouter, Route, Switch} from 'react-router-dom'
import {composeComponent} from 'utils/react-tools'
import Request from './Request'
import Prompt from './Prompt'


export default composeComponent 'AuthPages.Confirm',
  ({success, submit}) ->
    React.createElement(Switch, null,
      React.createElement(Route, {"exact": true, "path": "/confirm/:token", "component": (Prompt)}),
      React.createElement(Route, {"exact": true, "path": "/confirm", "component": (Request)})
    )
