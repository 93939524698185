import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withRouter, Link} from 'react-router-dom'
import Datepicker from 'react-datepicker'
import {fetchUser} from 'auth/requests'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, mapProps, withProps, withState} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import {getUser} from 'api'
import * as ME from 'api/metrics'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Subnav from 'theme/Subnav'
import Modal from 'theme/Modal'
import Toast from 'theme/Toast'
import Avatar from 'theme/Avatar'
import Toggler from 'theme/Toggler'
import Checkbox from 'theme/Checkbox'
import RSelect from 'react-select'
import {getAdminConversationsDefinitions, deleteMatches, getWorkshop, getWorkshops, getMatchingGroup, updateMatchingGroup, getMatches, matchMembers, startConversations, getRigScores, smartMatch} from 'matchingGroups/requests'
import {getConversationsDefinitions} from 'conversations/requests'
import Search from 'Search'

import './MatchingGroup.sass'

MAX_COLORS = 5

STATE_MAP = [
  'Select partner'
  'Schedule conversation'
  'Conduct conversation'
  'Capture your insights'
  'Rate and mark as completed'
  'Finished'
]
MESSAGES =
  error:
    loading: 'Cannot load data. Please try again later or contact the administrator.'
    match: 'Something went wrong while matching members.'
    matchRIG: 'Something went wrong while matching members.'
    removeMatch: 'Something went wrong while removing this match.'
    startConversation: 'Something went wrong while starting the conversation.'
  success:
    match: 'Successfully matched members.'
    removeMatch: 'Successfully deleted match.'
    startConversation: 'Successfully started conversation.'

  mixed:
    smartMatch: {
      duplicates: (matches, members, workshops) =>
        moreThanOne = matches.length > 1
        React.createElement("div", {"className": "MatchingGroup__mixedToastError"},
          React.createElement("div", {"className": "MatchingGroup__mixedToastTitle"}, """
            Tried to match """, (matches.length), " pair", (if matches.length > 1 then 's' else ''), """ with no success. These pairs were created in different cohorts:
"""), """
          (click on members to see the set they""", ("'"), """re paired in)
""", (matches.map (d) ->
            match = d.existingMatch
            workshopId = d.workshopId
            workshop = workshops.find (w) -> w.id == workshopId
            React.createElement("div", {"key": (match.id)},
              React.createElement(Link, {"className": "MatchingGroup__mixedToastPartners", "to": ("/peer-coaching/manage/#{workshopId}/group/#{match.matchGroupId}/match")},
                (match.partners.map (pId) ->
                  partner = members.find (m) -> m.id == pId
                  name =
                    if partner?.firstName
                      "#{partner?.firstName} #{partner?.lastName}"
                    else "#{partner.email}"
                  React.createElement("span", {"className": "MatchingGroup__mixedToastPartner", "key": (pId)},
                    React.createElement(Avatar, {"for": (partner), "size": "xs"}),
                    (name)
                  )
                ), """
                paired in """, (workshop?.name)
              )
            )
          )
        )
      matched: (matches) =>
        React.createElement("div", {"className": "MatchingGroup__mixedToastSuccess"},
          React.createElement("div", {"className": "MatchingGroup__mixedToastTitle"}, """
            Successfully matched """, (matches.length), " pair", (if matches.length > 1 then 's' else '')
          )
        )
    },
    match: {
      duplicates: (matches, members, workshops) =>
        moreThanOne = matches.length > 1
        React.createElement("div", {"className": "MatchingGroup__mixedToastError"},
          React.createElement("div", {"className": "MatchingGroup__mixedToastTitle"}, """
            Tried to match """, (matches.length), " pair", (if matches.length > 1 then 's' else ''), """ with no success. These pairs were created in different cohorts:
"""), """
          (click on members to see the set they""", ("'"), """re paired in)
""", (matches.map (d, i) ->
            match = d.existingMatch
            workshopId = d.workshopId
            workshop = workshops.find (w) -> w.id == workshopId
            React.createElement("div", {"key": (i)},
              React.createElement(Link, {"className": "MatchingGroup__mixedToastPartners", "to": ("/peer-coaching/manage/#{workshopId}/group/#{match.matchGroupId}/match")},
                (match.partners.map (pId) ->
                  partner = members.find (m) -> m.id == pId
                  name =
                    if partner?.firstName
                      "#{partner?.firstName} #{partner?.lastName}"
                    else "#{partner.email}"
                  React.createElement("span", {"className": "MatchingGroup__mixedToastPartner", "key": (pId)},
                    React.createElement(Avatar, {"for": (partner), "size": "xs"}),
                    (name)
                  )
                ), """
                paired in """, (workshop?.name)
              )
            )
          )
        )
    }
ADD_TO_ALL_CONV_OPTIONS =
  value: null
  label: 'Match in all conversations'

mapMatchError = (error, match) =>
  {cohorts: error.data.ids || [], match: match.members || []}

memberName = (member = {}) =>
  if(member.firstName)
    "#{member.firstName} #{member.lastName}"
  else member.email


export default composeComponent 'MatchingGroup',
  withState 'definitions', 'setDefinitions', []
  withState 'activeConversation', 'changeConversation', ADD_TO_ALL_CONV_OPTIONS
  withState 'matchRandomModal', 'showMatchRandomModal', false
  withState 'matchByRigModal', 'showMatchByRigModal', false
  withState 'updating', 'setUpdating', false
  withState 'workshops', 'setWorkshops', []
  withRouter

  withStateHandlers
    user: null
    loading: false
    status: null
    matchingGroup: []
    workshop: null
    chosenConversationPath: null
    pickedMembers: []
    matches: []
    selectedGroups: []
    sortedMembers: null
    sortDirection: 'asc'
    orphanedMatchesSelected: false
    query:''
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setMatchingGroup: -> (matchingGroup) -> {matchingGroup}
    setWorkshop: -> (workshop) -> {workshop}
    setMatches: -> (matches) -> {matches}
    pickMember: ({pickedMembers}) -> (member) ->
      if member.id in _.pluck 'id', pickedMembers
        pickedMembers: pickedMembers.filter ({id}) -> id isnt member.id
      else if pickedMembers.length < 2
        pickedMembers: _.append member, pickedMembers
    selectGroup: ({selectedGroups}) -> (group) ->
      groupId = group.id
      if groupId in selectedGroups
        selectedGroups: _.without [groupId], selectedGroups
      else
        selectedGroups: _.append groupId, selectedGroups
    deselectAllGroups: -> -> selectedGroups: []
    assignGroups: () -> (groups) ->
      selectedGroups: groups
    clearPicked: -> -> pickedMembers: []
    setStatus: -> (type, reason, error, data) ->
      status: {type, reason, error, data}
    clearStatus: -> -> status: null
    setSortedMembers: -> (sortedMembers) -> {sortedMembers}
    setSortDirection: -> (sortDirection) -> {sortDirection}
    setOrphanedMatchesSelected: -> (orphanedMatchesSelected) -> {orphanedMatchesSelected}
    setQuery: -> (query) -> {query}

  withHandlers
    setError: ({setStatus}) -> (reason, details = undefined) -> (error) ->
      setStatus 'error', reason, error, details
      ME.reportIntermediateError "CPL PeerCoaching/Conversations/MatchGroup: #{reason} error", error, details
    setSuccess: ({setStatus}) -> (reason) -> (additionalData) ->
      setStatus 'success', reason, null, additionalData
    changeConversation: ({changeConversation}) -> (activeConversation) ->
      changeConversation activeConversation
      activeConversation

  withProps (props) ->
    [programLabel, setLabel] = _.split '/', props.matchingGroup?.peerConversationSetLabel ? ''
    program = props.definitions?.find (d) -> d.label is programLabel
    set = program?.sets?.find (s) -> s.label is setLabel

    program: program
    set: set
    conversationsOptions: _.prepend ADD_TO_ALL_CONV_OPTIONS, set?.definitions?.map (d) ->
      label: props.t.find "peerconversation.conversation.#{d.label}.title"
      value: d.label

    workshop: _.evolve
        members: _.pipe(
          _.filter (m) -> m.assessmentResult?
          _.map (m) -> _.merge m,
            dominantDim: m.assessmentResult && Object.keys(m.assessmentResult?.drivers).find (dim) -> m.assessmentResult?.drivers[dim].dominant
            dominant: m.assessmentResult && do ->
              dim = Object.keys(m.assessmentResult?.drivers).find (dim) -> m.assessmentResult?.drivers[dim].dominant
              m.assessmentResult?.drivers[dim].driver
          (members)->
            {sortDirection} = props
            sortedMembers =
              if sortDirection is 'asc'
                _.sort (_.ascend _.compose _.toLower, (m) -> m.firstName || ""), members
              else if sortDirection is 'desc'
                _.sort (_.descend _.compose _.toLower, (m) -> m.firstName || ""), members
              else
                members
            sortedMembers
        )
      , props.workshop

  withHandlers
    loadData: ({
      setError, user, setUser, startLoading, endLoading, setWorkshop, setWorkshops,
      setMatchingGroup, setMatches, match: {params}, setDefinitions, setQuery
    }) -> ->
      ME.reportStateChange "CPL PeerCoaching/Conversations/MatchGroup: data loading", {matchGroupId: params.matchingGroupId, workshopId: params.id}
      startLoading()
      setQuery ''

      a = new Promise (resolve, reject) ->
        fetchUser()
        .then (user) ->
          setUser user
          getWorkshop params.id
          .then (workshop) ->
            setWorkshop workshop
            getAdminConversationsDefinitions(workshop.organizationCode)
            .then setDefinitions
            .then resolve

            getWorkshops(workshop.organizationCode)
            .then setWorkshops
        .catch reject

      b = getMatchingGroup params.id, params.matchingGroupId
      .then setMatchingGroup
      .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations/MatchGroup: matching group loading error", err, {matchGroupId: params.matchingGroupId, workshopId: params.id}

      c = getMatches params.id, params.matchingGroupId
      .then setMatches
      .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations/MatchGroup: matches loading error", err, {matchGroupId: params.matchingGroupId, workshopId: params.id}

      Promise.all [a, b, c]
      .then _, setError 'loading'
      .finally endLoading

    isMemberPicked: ({pickedMembers}) -> (member) ->
      member.id in _.pluck 'id', pickedMembers

    isMemberMatched: ({matches, set}) -> (member) ->
      set?.definitions.every (conv) ->
        matches?.find (m) ->
          m.peerConversationLabel.endsWith("/#{conv.label}") and member.id in m.partners

    memberMatches: ({matches, set}) -> (member) ->
      set?.definitions.reduce(
        (acc, conv, i) ->
          foundMatch = matches?.find (m) ->
            m.peerConversationLabel.endsWith("/#{conv.label}") and member.id in m.partners
          _.mergeRight acc, "#{conv.label}": if foundMatch then (i % MAX_COLORS) else null
        {}
      )

    matchManually: ({match: {params}, pickedMembers, matches, setMatches, clearPicked, setSuccess, setError, setStatus, activeConversation, program, set}) -> ->
      ME.reportStateChange "CPL PeerCoaching/Conversations/MatchGroup: match(es) manually starting"
      newMatch =
        members: _.pluck('id', pickedMembers)
        peerConversationLabel: if activeConversation.value then "#{program.label}/#{set.label}/#{activeConversation.value}"

      matchMembers params.id, params.matchingGroupId, [newMatch]
      .then setMatches
      .then clearPicked
      .then setSuccess('match')
      , (err) ->
        if err.status is 409
          setStatus('mixed', 'match', null, {duplicates: err.data?.matches?.matches || []});
        else
          setError('match', {matchGroupId: params.matchingGroupId, workshopId: params.id}) err

    matchByRig: ({match: {params}, workshop, setMatches, setSuccess, setError, setStatus, showMatchByRigModal}) -> ->
      ME.reportStateChange "CPL PeerCoaching/Conversations/MatchGroup: match(es) by RIG starting"

      smartMatch(params.id, params.matchingGroupId)
      .then (result) ->
        setStatus('mixed', 'smartMatch', null, {matched: result.matched, duplicates: result.duplicates?.matches || []});
      .then ->
        getMatches(params.id, params.matchingGroupId)
        .then setMatches
        .then -> showMatchByRigModal(false)
        .catch (err) ->
          console.log(err)
          setError('loading')(err)
          ME.reportIntermediateError "CPL PeerCoaching/Conversations/MatchGroup: matches loading error", err, {matchGroupId: params.matchingGroupId, workshopId: params.id}

  withHandlers
    startConversations: ({match: {params}, loadData, setSuccess, setError, deselectAllGroups, user}) -> (selectedGroups, date) ->
      dto = {matchesIds: selectedGroups}
      ME.reportStateChange "CPL PeerCoaching/Conversations/MatchGroup: conversations starting"
      startConversations params.id, params.matchingGroupId,
        if date then _.assoc('scheduledAt', moment(date.valueOf()).tz(user.timeZone, true).valueOf(), dto) else dto
      .then loadData
      .then deselectAllGroups
      .then setSuccess('startConversation'), setError 'startConversation'

    removeMatches: ({setUpdating, matches, match: {params}, setMatches, setSuccess, setError, deselectAllGroups}) -> (selectedGroups) ->
      setUpdating(true)
      ME.reportStateChange "CPL PeerCoaching/Conversations/MatchGroup: match(es) removing"

      deleteMatches(params.id, params.matchingGroupId, selectedGroups)
      .then(
        () ->
          ME.reportStateChange "CPL PeerCoaching/Conversations/MatchGroup: match removed", {matches: selectedGroups}
          selectedGroups.map (matchId) -> {matchId, status: 'success'}
        (error) ->
          ME.reportIntermediateError "CPL PeerCoaching/Conversations/MatchGroup: match removing error", error, {matches: selectedGroups}
          selectedGroups.map (matchId) -> {matchId, status: 'error', error}
      )
      .then (deleteData) ->
        deselectAllGroups()
        getMatches params.id, params.matchingGroupId
        .then(
          (matches) ->
            setMatches(matches)
            if(deleteData.find((match) -> match.status == 'error'))
              setError('removeMatches')(deleteData)
            else
              setSuccess('removeMatches')(deleteData)
            setUpdating(false)
          (error) ->
            setError('removeMatch')(error)
            ME.reportIntermediateError "CPL PeerCoaching/Conversations/MatchGroup: error loading matches", error, {workshopId: params.id, matchingGroupId: params.matchingGroupId}
            setUpdating(false)
        )

    sortMembers: ({workshop, sortDirection, setSortedMembers, setSortDirection}) -> ->
      if sortDirection is 'asc'
        setSortDirection 'desc'
      else if sortDirection is 'desc'
        setSortDirection 'asc'

    setAutoAssign: ({matchingGroup, setUpdating, workshop, setMatchingGroup}) -> (autoAssign) ->
      ME.reportStateChange "CPL PeerCoaching/Conversations/MatchGroup: set auto assign"
      setUpdating true
      updateMatchingGroup matchingGroup.workshopId, matchingGroup.id,
        {description: matchingGroup.description, autoAssign}
      .then setMatchingGroup
      .finally -> setUpdating false

  withHandlers
    _searchUser: ({setQuery}) -> (user) -> (query) ->
      setQuery query

    boldString: -> (s, b) ->
      if(!s || !b)
        return React.createElement("span", null, (s || ""))

      groupedString = s.split(RegExp("(#{b})", 'i', 'g'))

      React.createElement("span", null,
        (groupedString.map((fragment, i) =>
          if(fragment.toLowerCase() == b.toLowerCase())
            React.createElement("b", {"key": (i)}, (fragment))
          else
            React.createElement("span", {"key": (i)}, (fragment))
        ))
      )

  lifecycle
    componentDidMount: ->
      @props.loadData()

    componentDidUpdate: (prevProps) ->
      if(prevProps.match.params.id != @props.match.params.id ||
      prevProps.match.params.matchingGroupId != @props.match.params.matchingGroupId)
        @props.loadData()
        @props.clearStatus()
        @props.deselectAllGroups()
        @props.clearPicked()

  withProps (props) ->
    matches:
      (props.matches
      .map _.evolve partners: _.map (partnerId) ->
        props.workshop?.members?.find (m) -> m.id is partnerId
      )
      .sort (a, b) ->
        a = "#{a.partners[0]?.firstName} #{a.partners[0]?.lastName}"
        b = "#{b.partners[0]?.firstName} #{b.partners[0]?.lastName}"
        if a < b then -1 else (if a > b then 1 else 0)

  withHandlers
    filterByQuery: ({matches, query}) -> (conv) ->
      mpcFilter = matches?.filter (match) -> match.peerConversationLabel.endsWith "/#{conv.label}"
      if (query? and query.length > 0)
        filteredConv = mpcFilter?.filter((a) ->
          (a.partners?.some ((x) ->
            (x?.firstName?.toLowerCase().indexOf(query?.toLowerCase()) > -1) or
            (x?.lastName?.toLowerCase().indexOf(query?.toLowerCase()) > -1))))
      else
        filteredConv = mpcFilter
      filteredConv
    filterMembersByQuery: ({workshop}) -> (query) ->
      filteredMembers: []
      if (query? and query.length > 0)
        filteredMembers = workshop.members?.filter((x) ->
          ((x?.firstName?.toLowerCase().indexOf(query?.toLowerCase()) > -1) or
          (x?.lastName?.toLowerCase().indexOf(query?.toLowerCase()) > -1)))
      else
        filteredMembers = workshop?.members
      filteredMembers

  withHandlers
    selectAllOrphanedConversations: ({assignGroups, setOrphanedMatchesSelected, matches}) -> ->
      mpc = matches?.filter (m) ->
        (!m?.partners?.every (p) -> p?)
      setOrphanedMatchesSelected true
      assignGroups(_.pluck 'id', mpc)
    deSelectAllOrphanedConversations: ({assignGroups, setOrphanedMatchesSelected, matches, selectedGroups}) -> ->
      mpc = matches?.filter (m) ->
        (!m?.partners?.every (p) -> p?)
      setOrphanedMatchesSelected false
      assignGroups(_.without _.pluck('id', mpc), selectedGroups)
    selectAllNonNotifiedGroupsInConv: ({assignGroups, matches, selectedGroups, setAllNonNotifiedSelected, filterByQuery, query}) -> (conv) ->
      filteredConv = filterByQuery conv
      mpc = filteredConv?.filter (m) ->
        ((!m.peerConversationId) and (m?.partners?.every (p) -> p?))
      assignGroups(_.union selectedGroups, _.pluck 'id', mpc)
    deselectAllNonNotifiedGroupsInConv: ({assignGroups, matches, selectedGroups, setAllNonNotifiedSelected, filterByQuery, query}) -> (conv) ->
      filteredConv = filterByQuery conv
      mpc = filteredConv?.filter (m) ->
        ((!m.peerConversationId) and (m?.partners?.every (p) -> p?))
      assignGroups(_.without _.pluck('id', mpc), selectedGroups)
    selectAllGroupsInConv: ({assignGroups, selectedGroups, matches, filterByQuery, query}) -> (conv) ->
      filteredConv = filterByQuery conv
      assignGroups(_.union selectedGroups, _.pluck 'id', filteredConv)
    deselectAllGroupsInConv: ({assignGroups, selectedGroups, matches, filterByQuery, query}) -> (conv) ->
      filteredConv = filterByQuery conv
      assignGroups(_.without _.pluck('id', filteredConv), selectedGroups)

  ({
    loading, matchingGroup, workshop
    isMemberPicked, isMemberMatched, pickMember, pickedMembers
    matches, startConversations, setAutoAssign, updating,
    removeMatches, matchManually, matchByRig
    user, clearStatus, status, set, t
    changeConversation, activeConversation, conversationsOptions
    matchByRigModal, showMatchByRigModal
    selectedGroups, selectGroup, memberMatches,
    selectAllGroupsInConv, deselectAllGroupsInConv,
    sortMembers, sortedMembers, sortDirection,
    selectAllOrphanedConversations, deSelectAllOrphanedConversations, orphanedMatchesSelected,
    selectAllNonNotifiedGroupsInConv, deselectAllNonNotifiedGroupsInConv,
    _searchUser, query, filterByQuery, boldString, filterMembersByQuery, workshops
  }) ->

    filteredConv: []
    React.createElement("div", {"className": "MatchingGroup page"},
      React.createElement(Header, {"user": (user), "cpl": (true)}),

      React.createElement("div", {"className": "container", "id": "main"},

        React.createElement("div", {"className": "MatchingGroup__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "MatchingGroup__title"}, """
              Set: """, (t.find "peerconversation.set.#{set?.label}.title")
            ),
            React.createElement("p", null, "Manage matched members and their conversations. Select matched groups to start their conversations.")
          ),
          (if false
            React.createElement("div", {"className": "MatchingGroup__toggler"},
              React.createElement("h3", null, "Auto Assign"),
              React.createElement("div", {"className": "MatchingGroup__togglerRow"}, """
                Off
""", React.createElement(Toggler, {"checked": (matchingGroup?.autoAssign), "onChange": (setAutoAssign), "disabled": (updating)}), """
                On
""")
            )
          )
        ),

        React.createElement("div", {"className": "MatchingGroup__row"},
          React.createElement("div", {"className": "MatchingGroup__matching"},
            React.createElement("div", {"className": "MatchingGroup__card grey"},
              React.createElement("h3", null, "Matches"),
              (if !orphanedMatchesSelected
                  React.createElement("button", {"className": "btn btn_compact btn_outlined_red", "onClick": (-> selectAllOrphanedConversations matches)}, """
                    Select All Orphaned Conversations
""")
              else
                React.createElement("button", {"className": "btn btn_compact btn_outlined_red", "onClick": (-> deSelectAllOrphanedConversations matches)}, """
                  Deselect All Orphaned Conversations
""")
              ),
              React.createElement("span", {"className": "MatchingGroup__matchingInfo"}, "Matched partners in all conversations in set")
            ),
            (set?.definitions?.map (conv, i) ->
              filteredConv = filterByQuery conv
              color = i % MAX_COLORS
              React.createElement("div", {"key": (i), "className": "MatchingGroup__ConvGroup MatchingGroup__Box-color#{color ? ''}"},
                React.createElement("div", {"className": "MatchingGroup__BoxHeader MatchingGroup__BoxHeaderColor-color#{color ? ''}"},
                  React.createElement("h4", null,
                    (t.find "peerconversation.conversation.#{conv.label}.title"),
                    React.createElement("span", {"className": "MatchingGroup__colors_item color#{color ? ''}"}),
                    (if filteredConv?.length
                      React.createElement("span", {"className": "MatchingGroup__cardHeader-grey"}, (filteredConv?.length), " match(es)")
                    )
                  )
                ),
                React.createElement("div", {"className": "MatchingGroup__card", "key": (conv.label)},
                  React.createElement("div", {"className": "MatchingGroup__cardHeader"},
                    (if filteredConv?.length
                      if filteredConv.every (m) -> m.id in selectedGroups
                        React.createElement("button", {"className": "btn btn_compact btn_outlined_bluePurple", "onClick": (-> deselectAllGroupsInConv conv)}, """
                          Deselect All
""")
                      else
                        React.createElement("button", {"className": "btn btn_compact btn_outlined_bluePurple", "onClick": (-> selectAllGroupsInConv conv)}, """
                          Select All
""")
                    ),
                    (if filteredConv?.length
                      if !(filteredConv.every (m) -> m.peerConversationId) #don't show this if every conversation has been notified
                        unNotifiedConvs = (filteredConv.filter ((m) -> !m.peerConversationId))
                        allUnNotSelected = ((unNotifiedConvs.every (s) -> s.id in selectedGroups) or (unNotifiedConvs.some (s) -> s.id in selectedGroups))
                        if ((unNotifiedConvs.every (s) -> s.id in selectedGroups) or (unNotifiedConvs.some (s) -> s.id in selectedGroups))
                          React.createElement("button", {"className": "btn btn_compact btn_outlined_bluePurple", "onClick": (-> deselectAllNonNotifiedGroupsInConv conv)}, """
                            Deselect Un-notified
""")
                        else
                          React.createElement("button", {"className": "btn btn_compact btn_outlined_bluePurple", "onClick": (-> selectAllNonNotifiedGroupsInConv conv)}, """
                            Select Un-notified
""")
                    )
                  ),
                  (unless filteredConv?.length
                    React.createElement("div", {"className": "font-primary font-w-light"}, "No matches")
                  ),
                  React.createElement("div", {"className": "MatchingGroup__matchList"},
                    (filteredConv?.map (match) ->
                      React.createElement("div", {"key": (match.id), "className": (cx "MatchingGroup__matchRow", active: match.id in selectedGroups)},
                        React.createElement(Checkbox, { \
                          "checked": (match.id in selectedGroups),  \
                          "onChange": (-> selectGroup match)
                        }),
                        (match.partners?.map (m, i) ->
                          return null unless m
                          [
                            if i isnt 0
                              React.createElement("span", {"className": "and", "key": (m.id)}, "\&")
                            React.createElement("div", {"key": (m.id + 1), "className": "MatchingGroup__match"},
                              React.createElement(Avatar, {"for": (m), "size": "xs", "className": "MatchingGroup__avatar", "withDominant": true}),
                              React.createElement("span", {"className": "MatchingGroup__matchingName"},
                                (boldString m.firstName, query), " ", (boldString m.lastName, query)
                              )
                            )
                          ]
                        ),
                        React.createElement("div", {"className": "MatchingGroup__match_started"},
                          (if match.peerConversationId then 'Notified')
                        )
                      )
                    )
                  )
                )
              )
            )
          ),

          (
            filteredMems = filterMembersByQuery query
            React.createElement("div", {"className": "MatchingGroup__matching MatchingGroup__matchingMembers MatchingGroup__card"},
              React.createElement("div", {"className": "MatchingGroup__card grey"},
              React.createElement("h3", null,
                (workshop?.name), """ Members
""", React.createElement("span", {"className": "MatchingGroup__cardHeader-grey"}, "(", (filteredMems?.length), ")")
              ),
              React.createElement(Search, { \
                "className": "MatchingGroup__search",  \
                "search": (_searchUser user),  \
                "placeholder": "Search for members",  \
                "name": "searchBox",  \
                "value": (query)
              })
              ),

              React.createElement("span", {"className": "MatchingGroup__matchingInfo"}, """
                Pick people to assign them as partners to conversation. Greyed ones have been already matched across all conversations.
                Dots symbolize member matched to specific conversation.
"""),
              React.createElement("div", null,
                React.createElement("span", {"className": "MatchingGroup__matchSort", "onClick": (-> sortMembers filteredMems)}, """
                  Name
""", (if sortDirection is 'asc'
                      React.createElement("i", {"className": "fas fa-arrow-up"})
                    else if sortDirection is 'desc'
                      React.createElement("i", {"className": "fas fa-arrow-down"})
                    )
                )
              ),
              React.createElement("div", {"className": "MatchingGroup__matchList"},
                (filteredMems?.map (m) ->
                  React.createElement("div", { \
                    "key": (m.id),  \
                    "className": (cx "MatchingGroup__matchingRow",
                      active: isMemberPicked m
                      matched: isMemberMatched m
                    ),  \
                    "onClick": (-> pickMember m)
                  },
                    React.createElement(Avatar, {"for": (m), "size": "xs", "key": (m.id), "className": "MatchingGroup__avatar", "withDominant": true}),
                    React.createElement("span", {"className": "MatchingGroup__matchingName"},
                      (boldString m.firstName, query), " ", (boldString m.lastName, query)
                    ),
                    React.createElement("span", {"className": "MatchingGroup__colors"},
                      (for conv, color of memberMatches(m)
                        React.createElement("span", { \
                          "key": (conv),  \
                          "className": "MatchingGroup__colors_item color#{color ? ''}"
                        })
                      )
                    )
                  )
                )
              ),
              React.createElement("div", {"className": "MatchingGroup__matchingBtns"},
                React.createElement("button", { \
                  "className": "btn btn_secondary btn_solid_bluePurple",  \
                  "disabled": (pickedMembers.length != 2),  \
                  "onClick": (matchManually)
                }, """
                  Match
"""),
                React.createElement("button", { \
                  "className": "btn btn_secondary btn_solid_bluePurple",  \
                  "onClick": (-> if matches?.length then showMatchByRigModal(true) else matchByRig())
                }, """
                  Imperative Smart Match
""")
              ),
              React.createElement(RSelect, { \
                "className": "MatchingGroup__select",  \
                "classNamePrefix": "MatchingGroup__select",  \
                "placeholder": "Match in all conversations",  \
                "options": (conversationsOptions),  \
                "value": (activeConversation),  \
                "onChange": (changeConversation),  \
                "menuPlacement": "top"
              })
            )
          )
        ),

        React.createElement(Loader, {"loading": (loading)})

      ),

      React.createElement(Footer, null),

      React.createElement(Toast, { \
        "visible": (status?),  \
        "type": (status?.type),  \
        "message": (status?.type != 'mixed' && status?.reason != 'removeMatches' && MESSAGES[status?.type]?[status?.reason]),  \
        "close": (clearStatus),  \
        "autoClose": (false),  \
        "noPadding": (status?.type == 'mixed')
      },
        (if status?.type == 'mixed'
          messages = MESSAGES[status?.type]?[status.reason]
          React.createElement("div", {"className": "MatchingGroup__mixedToast"},
            (Object.keys(status?.data).map (key) ->
              if status?.data[key]?.length
                React.createElement("div", {"key": (key)},
                  (messages[key]?(status?.data[key], workshop?.members || [], workshops))
                )
            )
          )
        ),
        (if status?.reason == 'removeMatches'
          success = status?.data?.filter((match) -> match.status == 'success')
          error = status?.data?.filter((match) -> match.status == 'error')

          React.createElement("span", null,
            (if success?.length > 0
              "#{success?.length} matches deleted"
            ),
            (if error?.length > 0
              "An error occured while deleting #{error.length} matches"
            )
          )
        )
      ),

      React.createElement(Toast, { \
        "visible": (selectedGroups.length),  \
        "type": "info",  \
        "opacity": "1",  \
        "title": (false),  \
        "className": "MatchingGroup__toast"
      },
        React.createElement("span", null,
          React.createElement("span", {"className": "Toast__title"},
            (selectedGroups.length), """ group(s) selected
"""), """
          You can perform one of these actions
"""),
        React.createElement("div", {"className": "MatchingGroup__toastActions"},
          React.createElement("button", { \
            "onClick": (-> removeMatches selectedGroups),  \
            "className": "btn btn_compact btn_outlined_white"
          },
            (if selectedGroups.length > 1 then 'Remove Matches' else 'Remove Match')
          ),
          React.createElement("button", { \
            "onClick": (-> startConversations selectedGroups),  \
            "disabled": (orphanedMatchesSelected or (selectedGroups.every (g) -> matches.find(({id}) -> id is g)?.peerConversationId?)),  \
            "className": "btn btn_compact btn_white_blue"
          }, """
            Activate and Notify
""")
        )
      ),

      React.createElement(Modal, {"isOpen": (matchByRigModal), "className": "MatchingGroup__modal", "close": (-> showMatchByRigModal false)},
        React.createElement("h2", null, "Warning"),
        React.createElement("p", null, "This action will remove all existing matches in set and create new matches by Imperative algorithm. Do you want to continue?"),
        React.createElement("div", {"className": "text-center margin-top-3"},
          React.createElement("button", {"className": "btn btn_secondary btn_outlined_bluePurple", "onClick": (-> showMatchByRigModal false)}, """
            Cancel
"""),
          React.createElement("button", {"className": "btn btn_secondary btn_solid_bluePurple", "onClick": (matchByRig)}, """
            Remove matches \& match imperative
""")
        )
      )

    )
