import api from 'api';

export const getAssessmentData = () => {
  return api.get(`${api.surveys}/purpose-assessment`);
}
export const getScoreBaseline = (user = {}) => {
  return api.get(`/members/${user.slug}/rig/scores/baseline`);
}
export const getJobFunctions = () => {
  return api.get("/dictionaries/JobFunction");
}
export const getIndustries = () => {
  return api.get("/dictionaries/Industry");
}
export const getRigQuestions = () => {
  return api.get("/rig/questions/random");
}
export const updateScoreBaseline = (user = {}, score) => {
  return api.put(`/members/${user.slug}/rig/scores/baseline`, score)
}
export const sendAnswer = (dto) => {
  return api.post(`${api.surveys}/purpose-assessment/answer`, dto)
}
export const finishAssessment = () => {
  return api.post("/surveys/purpose-assessment/iamready");
}
export const getRoleStats = () => {
  return api.get(api.roleStatistics);
}
export const updateDirectReportsCount = (user = {}, directReports) => {
  return api.patch(`/members/${user.slug}`, {directReportsCount: directReports});
}
export const updateDemographicData = (answers = []) => {
  return api.post("/me/demographic-questions", {answers});
}
