import React from 'react';

export const FollowUpUse = ({close}) => {
  return (
    <div className="Questions__tooltip">
      Coaching Tip: Use the follow-up question to help your partner dig deeper.
      <button className="btn-icon" onClick={close}>
        <span className="fal fa-times" />
      </button>
    </div>
)}

export const UseOfNotes = ({close}) => {
  return (
    <div className="Questions__tooltip">
      Your partner is taking notes for you so you can reference them later.
      <button className="btn-icon" onClick={close}>
        <span className="fal fa-times" />
      </button>
    </div>
)}

export const GoodNotes = ({close}) => {
  return (
    <div className="Questions__tooltip">
      Coaching Tip: Everyone writes notes differently. Experiment to find what works for you.
      <button className="btn-icon" onClick={close}>
        <span className="fal fa-times" />
      </button>
    </div>
  )
}


export const ReadNotes = ({close}) => {
  return (
    <div className="Questions__tooltip">
      Coaching Tip: It is helpful to share back what you heard your partner say before pressing “Next”.
      <button className="btn-icon" onClick={close}>
        <span className="fal fa-times" />
      </button>
    </div>
  )
}

export const YouAreAsking = ({close}) => {
  return (
    <div className="Questions__tooltip">
      It’s your turn to ask the first question.
      <button className="btn-icon" onClick={close}>
        <span className="fal fa-times" />
      </button>
    </div>
  )
}

export const YouAreAnswering = ({close}) => {
  return (
    <div className="Questions__tooltip">
      It’s your turn to answer the first question.
      <button className="btn-icon" onClick={close}>
        <span className="fal fa-times" />
      </button>
    </div>
  )
}
