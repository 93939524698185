import React from 'react'
import * as _ from 'ramda'
import cx from 'classnames'
import Dropdown, {composeComponent} from 'react-select'

import './LanguageDropdown.sass'


LanguageDropdown = ({options, value, label, onChange, settings, id, className, placeholder, loading, underlined}) ->
  DropdownIndicator = (props) ->
    React.createElement(components.DropdownIndicator, Object.assign({},  props),
      (if loading
        React.createElement("span", {"className": "far fa-spinner-third fa-spin"})
      )
    )

  React.createElement(Dropdown, { \
    "id": (id),  \
    "value": (options?.find((o) => o.value == value)),  \
    "className": (cx("LanguageDropdown", className, {underlined})),  \
    "classNamePrefix": "LanguageDropdown",  \
    "options": (options),  \
    "onChange": ((o) => onChange o.value),  \
    "placeholder": (placeholder),  \
    "disabled": (loading),  \
    "components": (DropdownIndicator)
  })

export default LanguageDropdown
