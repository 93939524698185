import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withStateHandlers} from 'recompose'
import cx from 'classnames'
import * as _ from 'ramda'

import './WorkshopForm.sass'

export default composeComponent 'WorkshopForm',
  withStateHandlers
    value: ''
    loading: false
  ,
    onChange: -> (e) -> value: e.target.value
    setLoading: -> (loading) -> {loading}

  ({value, onSubmit, onChange, cancel, className}) ->
    React.createElement("div", {"className": (cx "WorkshopForm", className)},
      React.createElement("input", {"onChange": (onChange), "value": (value), "placeholder": "Enter cohort name...", "aria-label": "cohort name"}),
      React.createElement("button", { \
        "className": "btn btn_secondary btn_solid_bluePurple",  \
        "onClick": (-> onSubmit value),  \
        "disabled": (_.isEmpty value)
      }, """
        Save
"""),
      (if cancel?
        React.createElement("button", { \
          "className": "btn btn_secondary btn_outlined_bluePurple",  \
          "onClick": (cancel)
        }, """
          Cancel
""")
      )
    )