import r from 'r-dom'
import T from 'tcomb'
import cx from 'classnames'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import { Link } from 'react-router-dom'
import {withProps} from 'recompose'

import './Nav.sass'

export default composeComponent 'Assessment.Nav',
  setPropTypes
    next: T.Function
    prev: T.maybe T.Function
    className: T.maybe T.String
    nextText: T.maybe T.String
    prevText: T.maybe T.String
    disabled: T.maybe T.Boolean
    lastQuestion: T.maybe T.Boolean

  withProps ({value}) ->
    progress: Math.min value, 100

  ({t, next, className, prev, nextText, prevText, submitting, error, prevDisabled, lastQuestion, completeWholeAssessment}) ->

    r.div className: cx("Nav__nav", className),
      r.div className: cx("Nav__navContent", singleBtn: not prev), [
        if prev
          r.button
            className: 'btn btn_primary btn_outlined_bluePurple'
            id: 'Assessment__prevBtn'
            onClick: prev
            disabled: submitting || prevDisabled
            prevText ? t.find 'assessment.general.button.previous'

        if next or completeWholeAssessment
          if completeWholeAssessment
            r.button
              className: cx 'btn btn_primary btn_solid_bluePurple', {disabled: submitting or error}
              id: 'Assessment__readyBtn'
              onClick: completeWholeAssessment
              disabled: submitting or error
              nextText ? 'Finish'
          else if next
            r.button
              className: 'btn btn_primary btn_solid_bluePurple'
              id: 'Assessment__nextBtn'
              onClick: next
              disabled: submitting or error
              nextText ? t.find 'assessment.general.button.next'
      ]
      