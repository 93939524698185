import React, { useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import TranslationsContext from 'translations';
import Loader from 'theme/Loader'
import {URL_HEROES} from 'theme/assets/assets'

const POSTER_URL = `${URL_HEROES}/posters`;
const PREVIEW_URL = `${URL_HEROES}/previews`;

const ANIMALS = {
  crock: 'Alligator',
  bear: 'Bear',
  dolphin: 'Dolphin',
  duck: 'Duck',
  chick: 'Eagle',
  elephant: 'Elephant',
  beaver: 'Ferret',
  commonfox: 'Fox',
  giraffe: 'Giraffe',
  lion: 'Lion',
  lizard: 'Lizard',
  sheep: 'Moose',
  octopus: 'Octopus',
  robin: 'Owl',
  panda: 'Panda',
  penguin: 'Penguin',
  pig: 'Pig',
  bunny: 'Rabbit',
  rhino: 'Rhino',
  seal: 'Seal',
  sloth: 'Sloth',
  tiger: 'Tiger',
  fox: 'Wolf',
  zebra: 'Zebra',
};

const HeroPoster = ({user, updateHero}) => {
  const t = useContext(TranslationsContext);
  const [loading, setLoading] = useState(false);

  const selectHero = (hero) => {
    setLoading(true);

    updateHero(hero)
    .then(
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      })
  };

  const patternString = useMemo(() => {
    if(!user) return '';
    return `${user.pattern.who}${user.pattern.why}${user.pattern.how}`
  }, [user]);

  const archetype = t.exists(`pattern.archetypes.${patternString}.title`) && t.find(`pattern.archetypes.${patternString}.title`);

  const animal = useMemo(() => {
    return (user && user.animalHero) || Object.keys(ANIMALS)[0];
  }, [user]);

  const url = archetype ? `${PREVIEW_URL}/${animal}.${t.find(archetype).toLowerCase()}.jpg` : undefined;

  return (
    <div>
      <h3 className="section-head margin-top-5">What’s your superpower?</h3>
      <p>
        When you were a kid, you knew that you were a superhero.
        Do you feel like you’ve lost faith in yourself?
        Reignite your fire with a custom comic book cover featuring your favorite animal.
      </p>
      <div className="PurposeProfile__row col-1-3 PurposeProfile__hero">
        <div>
          <p>Select an animal to personalize your superhero poster</p>
          <div className="PurposeProfile__heroList">
            {Object.keys(ANIMALS).map(a =>
              <button
                key={a}
                className={cx("btn-radio", {active: animal == a})}
                onClick={() => selectHero(a)}
              >
                <span>{ANIMALS[a]}</span>
              </button>
            )}
          </div>
          <a
            href={archetype ? `${POSTER_URL}/${animal}.${t.find(archetype).toLowerCase()}.pdf` : undefined}
            className="btn-secondary blue margin-top-3"
            target="_blank"
            download
          >Download poster</a>

        </div>
        <div className="relative">
          <Loader loading={loading} color="white" />
          <img src={url} alt={animal} />
        </div>
      </div>
    </div>
  )
}

export default HeroPoster;
