import * as ME from 'api/metrics';
import cx from 'classnames';
import { useStore } from 'stores/stores';
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import TranslationsContext from 'translations';
import Results from './Results';
import HeroPoster from './HeroPoster';
import HowItWorks from './HowItWorks';
import { observer } from 'mobx-react';


const sections = {
  "results": 'Results',
  "superhero-poster": 'Superhero poster',
  "how-it-works": 'How it works'
};

const PurposeReport = () => {
  const {userStore} = useStore();
  const {user} = userStore;

  const t = useContext(TranslationsContext);
  const history = useHistory();
  const match = useRouteMatch("/purpose-profile/report/:section");

  const updateHero = useCallback((animalHero) => {
    ME.reportStateChange('Purpose Profile: updating hero poster', {animalHero});
    return userStore.updateUser({animalHero})
    .catch((err) => ME.reportIntermediateError('Purpose Profile: updating hero poster error', err))
  })

  const section = useMemo(() => {
    return match && match.params.section;
  }, [match]);

  useEffect(() => {
    if(!match || !match.params.section) {
      history.replace(`/purpose-profile/report/${Object.keys(sections)[0]}`);
    }
  }, [match]);

  return (
    <>
      <div className="PurposeProfile__page">
        <div className="PurposeProfile__heading">
          <Link to="/purpose-profile" className="PurposeProfile__headingLink">
            <h1 className="page-title">
              <i className="far fa-chevron-left" />
              Purpose report
            </h1>
            <p>Your guide to owning your fulfillment and unleashing your potential</p>
          </Link>
          {user &&
            <a
              href={`/m/${user.slug}/print`}
              target="_blank"
              className="btn-primary"
            >Download Purpose Report</a>
          }
        </div>
        <div className="PurposeProfile__nav">
          {Object.keys(sections).map(key => {
            const title = sections[key];
            return (
              <Link
                key={key}
                title={title}
                to={`/purpose-profile/report/${key}`}
                className={cx("PurposeProfile__navItem", {active: section == key})}
                >
                {title}
              </Link>
            )
          })}
        </div>
      </div>
      {section == 'results' && user &&
        <div className="PurposeProfile__page">
          <Results user={user} />
        </div>
      }
      {section == 'superhero-poster' && user &&
        <div className="PurposeProfile__page">
          <HeroPoster user={user} updateHero={updateHero} />
        </div>
      }
      {section == 'how-it-works' &&
        <HowItWorks />
      }
    </>
  )
}

export default observer(PurposeReport);
