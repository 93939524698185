import React from 'react';
import * as emojis from 'theme/emojis'

const Opening = ({t, rigAvg, setFocusRef}) => {
  const level = rigAvg > 3 ? 'Pumped Up' :
    rigAvg > 2 ? 'Happy' :
    rigAvg > 1 ? 'Meh' :
    'Unhappy';

  const title1 = t.find(`assessment.page.5.title1.${level.split(' ')[0].toLowerCase()}`);
  const title3 = <>
    <strong>{t.find("assessment.page.5.title2") + " "}</strong>
    {t.find(`assessment.page.5.title3`)}
  </>;

  return (
    <section className="Assessment__opening">
      <img src={emojis[level === 'Pumped Up' ? 'PumpedUp' : level]} alt={level}/>
      <div className="Assessment__openingContent" tabIndex="-1" ref={setFocusRef}>
        <div>
          <p className='margin-top-5'>{title1}</p>
          <p className='margin-top-5'>{title3}</p>
        </div>
      </div>
    </section>
  )
};

export default Opening;
