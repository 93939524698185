import r from 'r-dom'
import T from 'tcomb'
import * as _ from 'ramda'
import cx from 'classnames'
import {Link} from 'react-router-dom'
import { observer } from 'mobx-react'
import {composeComponent, setPropTypes, addStatesWithHandlers} from 'utils/react-tools'
import {withHandlers, withStateHandlers, withProps, withState, lifecycle} from 'recompose'
import {CSSTransition as Transition, TransitionGroup} from 'react-transition-group'
import Modal from 'theme/Modal'
import SkipLink from 'theme/SkipLink'
import LanguageDropdown from 'theme/LanguageDropdown'
import {fetchUser} from 'auth/requests'
import {updateUser} from 'user/requests'
import Progress from 'theme/Progress'
import {useStore} from 'stores'
import Question from './Question'
import Nav from './Nav'
import Result from './Result/Result'
import Chart from './PercentChart'
import * as emojis from 'theme/emojis'
import {IMG_AARON} from 'theme/assets/assets'
import api, {setUser, getUser} from 'api'
import axios from 'axios'
import moment from 'moment'
import {getLanguage, getAvailableLanguages, setLanguage} from 'translations'
import Loader from 'theme/Loader'
import {withRouter} from 'react-router-dom'
import customGriffith from 'translations/custom-griffith'
import {
  getAssessmentData,
  getScoreBaseline,
  getJobFunctions,
  getRigQuestions,
  getIndustries,
  sendAnswer,
  getRoleStats,
  finishAssessment,
  updateScoreBaseline} from 'assessment/requests'
import * as ME from 'api/metrics'
import Intro from './Intro'
import Opening from './Opening'
import IntersitialRIG from './IntersitialRIG'
import IntersitialDriver from './IntersitialDriver'
import IntersitialDemographic from './IntersitialDemographic'
import IntersitialNetwork from './IntersitialNetwork'
import RoleStats from './RoleStats'
import Summary from './Summary'

import './Assessment.sass'

Assessment = composeComponent 'Assessment.Content',
  withRouter
  withStateHandlers ({backstop, suggestedIndustry, questions = [], user, rigScores}) ->
    answers = questions.reduce(
      (obj, a, i) ->
        if not a.section? or a.section is 'driver'
          _.merge obj, "#{i}": a.answer
        else if a.section is 'rig' and !_.isEmpty rigScores
          _.merge obj, "#{i}":
            if a.id == 'relationshipAreas'
              (rigScores.relationshipAreas?.areas || []).map((a) => a.area)
            else rigScores.scores[a.id].value
        else if a.section is 'demographic' and a.structure == 'option-radio-custom'
          hasTextField = a.options.find((o) => o.id == a.answer)?.hasTextField
          _.merge obj, "#{i}": {answer: a.answer, answerText: a.answerText, hasTextField: Boolean(hasTextField)}
        else if a.section is 'demographic'
          _.merge obj, "#{i}": a.answer
        else obj
      {}
    )

    currentQ: backstop + 1
    # state: intro | opening | intersitialRIG | intersitialDemographic | intersitialDriver | question | summary
    state:
      if user.assessmentCompleted and user.hasPattern()
        'demographicResults'
      else if questions[backstop + 1]?.section is 'demographicResults'
        'demographicResults'
      else if questions[backstop + 1]?.section is 'results'
        'results'
      else if questions[backstop + 1]?.section is 'demographic' and (questions[backstop]?.section is 'results')
        'intersitialDemographic'
      else if backstop > -1
        'question'
      else 'intro'
    errors: {}
    submitting: {}
    changingLanguage: false
    answers: _.merge answers,
      industry: suggestedIndustry
    answering: false
    driverQuestionSeen: false
    exitModalVisible: false
    intersitialDriverVisible: false
    rigAnswers: {}
    userRole: null
    focusRef: null
  ,
    results: -> -> state: 'results'
    demographicResults: -> -> state: 'demographicResults'
    start: -> -> state: 'question'
    intro: -> -> state: 'intro'
    opening: -> -> state: 'opening'
    roleItersitial: -> -> state: 'roleItersitial'
    intersitialRIG: -> -> state: 'intersitialRIG'
    setRigAnswers: -> (rigAnswers) -> {rigAnswers}
    intersitialDriver: -> -> intersitialDriverVisible: true
    intersitialDriverOff: -> -> intersitialDriverVisible: false
    intersitialDemographic: -> -> state: 'intersitialDemographic'
    intersitialNetwork: -> -> state: 'intersitialNetwork'
    finish: -> -> state: 'summary'
    showExitModal: -> -> exitModalVisible: true
    closeExitModal: -> -> exitModalVisible: false
    setChangingLanguage: -> (changingLanguage) -> {changingLanguage}
    prevQ: ({currentQ}) -> ->
      currentQ: currentQ - 1
    nextQ: ({currentQ}) -> ->
      currentQ: currentQ + 1
    skipPage: -> -> driverQuestionSeen: true
    addAnswer: ({answers}) -> (idx, answer) ->
      answers: _.assoc idx, answer, answers
    setError: ({errors}) -> (idx, err) ->
      errors: _.assoc idx, err, errors
    setSubmitting: ({submitting}) -> (idx, state) ->
      submitting: _.assoc idx, state, submitting
    setAnswering: ({answerings}) -> (step) ->
      answering: step
    setUserRole: ({roles}) -> (role) ->
      userRole: role
    setFocusRef: -> (ref) ->
      focusRef: ref

  withProps ({
    questions
    answers
    errors
    submitting
    currentQ
    backstop
    user
    state
  }) ->
    rigQuestions = questions.filter (q) => q.section is 'rig' && q.id != 'relationshipAreas'
    sum = rigQuestions.reduce ((sum, q) =>
      i = questions.findIndex((question) => question.id == q.id)
      sum + (answers[i] ? 0)
    ), 0
    question = questions[currentQ]

    question: question
    answer: answers[currentQ]
    error: errors[currentQ]
    submitting: submitting[currentQ] ? false
    progress:
      if questions.length
        Math.floor currentQ * 100 / questions.length
      else 0
    rigAvg: sum / rigQuestions.length
    lastScreen: state == 'demographicResults'

  withHandlers
    _changeLanguage: ({user, setCurrentLanguage, t}) -> (language) ->
      setLanguage user.slug, language
      .then ->
        t.changeLanguage language, user
        .then ->
          t.useUserValues(true)
          setCurrentLanguage language
    showVideo: ({user}) -> ->
      ME.reportStateChange "Assessment: sending video"
    completeWholeAssessment: ({user, history, t, setSubmitting, currentQ}) -> ->
      setSubmitting currentQ, true
      updateUser {assessmentCompleted: true}
      .then(
        (u) ->
          ME.reportStateChange "Assessment: video seen"
          ME.reportStateChange "Assessment: finishing assessment"
          finishAssessment()
          .then () ->
            ME.reportStateChange "Assessment: assessment finished"
            fetchUser()
            .then setUser
            .then ->
              t.useUserValues(false)
              history.push '/'
        (err) ->
          ME.reportIntermediateError "Assessment: completing assessment error", err
          setSubmitting currentQ, false
      )
    _postAnswer: ({user, setRoleStats, setRigAnswers, rigAnswers, setUser}) -> (q, answer, modified = moment().valueOf()) ->
      switch q.id
        when 'relationship', 'impact', 'growth', 'relationshipAreas'
          if(q.id == 'relationshipAreas')
            ans = {...rigAnswers, relationshipAreas: {areas: (answer || []).map((a) => ({area: a}))}}
          else
            ans = _.assoc q.id, {questionId: q.questionId, value: answer}, rigAnswers
          setRigAnswers ans
          if ans.relationship? and ans.impact? and ans.growth? and ans.relationshipAreas?
            updateScoreBaseline user, ans
          else Promise.resolve()

        else
          data =
            value: answer.answer ? answer
            answerText: answer.answerText
            questionId: q.id
            modified: modified

          sendAnswer(data)

  withHandlers
    changeLanguage: ({_changeLanguage, setChangingLanguage}) -> (language) ->
      setChangingLanguage true
      _changeLanguage language
      .finally -> setChangingLanguage false
    postAnswer: ({user, _postAnswer, correlationId, questions}) -> (idx, answer) ->
      q = questions[idx]
      ME.reportStateChange 'Assessment: sending answer', {question: q}
      _postAnswer q, answer
      .then (d) ->
        ME.reportStateChange 'Assessment: answer sent'
        Promise.resolve(d)
      , (err) ->
        ME.reportIntermediateError 'Assessment: sending answer error', err
        Promise.reject(err)


  withHandlers
    prevQ: ({currentQ, prevQ, intro, start, state, questions, results, intersitialDemographic, demographicResults, intersitialNetwork, roleItersitial}) -> ->
      if questions[currentQ]?.section == 'demographic' && questions[currentQ - 1]?.section is 'results'
        intersitialDemographic()
      else if questions[currentQ - 1]?.section is 'results'
        results()
      else if questions[currentQ - 1]?.section is 'demographicResults'
        demographicResults()
      else if state isnt 'question'
        start()
      else if state is 'results' and questions[currentQ - 1]?.section isnt 'results'
        return
      else if questions[currentQ].ignoreArv and !questions[currentQ - 1].ignoreArv
        intersitialDemographic()
      if currentQ > 0
        prevQ()
      else
        intro()
    nextStep: ({t, setUserRole, currentQ, functions, intersitialRIG, intersitialDriver, intersitialDemographic, intersitialNetwork, nextQ, start, driverQuestionSeen, skipPage, state, opening, question, questions, roleItersitial, demographicResults, results, showVideo, user, refreshUser}) -> ->
      nextQuestion = questions[currentQ + 1]

      goToIntersitialNetwork = () =>
        getAssessmentData()
        .then (data) ->
          roleQuestion = data.questions.find (q) -> q.questionType == 'role'
          setUserRole id: roleQuestion.answer, label: roleQuestion.answerText || t.find "surveys.purpose-assessment.questions.#{roleQuestion.id}.options.#{roleQuestion.answer}"
          intersitialNetwork()

      nextStepIsDemographicResultsAction = () => 
        if question.section isnt 'demographicResults'
          if !user?.jobInformation?.jobFunctionText && functions.find (f) -> f == user?.jobInformation?.jobFunction
            roleItersitial()
          else
            goToIntersitialNetwork()

      if state is 'intro'
        if question?.section is 'rig'
          intersitialRIG()
        else
          start()
        return
      if state is 'intersitialRIG'
        start()
        return
      if nextQuestion?.section is 'driver'
        nextQ()
        opening()
        return
      if state is 'opening'
        start()
        return
      if question?.id is 3 and not driverQuestionSeen
        intersitialDriver()
        skipPage()
        start()
        nextQ()
        return
      if question?.section is 'driver' and driverQuestionSeen
        nextQ()
        start()
        return
      if questions[currentQ + 1]?.section is 'results'
        results()
        req =
          if questions[currentQ]?.section is 'results'
            Promise.resolve()
          else refreshUser()
        req.then nextQ
        return
      if state is 'results'
        refreshUser()
        if questions[currentQ + 1]?.section is 'demographic'
          intersitialDemographic()
        else if nextQuestion?.section is 'demographicResults'
          nextStepIsDemographicResultsAction()
          return
        else
          nextQ()
        return
      if state is 'intersitialDemographic'
        start()
        nextQ()
        return
      if state is 'intersitialNetwork'
        demographicResults()
        nextQ()
        return

      if state is 'roleItersitial'
        goToIntersitialNetwork()
        return
      if nextQuestion?.section is 'demographicResults'
        nextStepIsDemographicResultsAction()
        return
      start()
      nextQ()

  withHandlers
    backToDriver: ({intersitialDriverOff, prevQ}) -> ->
      intersitialDriverOff()
      prevQ()

    _getPattern: ({user, correlationId}) -> (answers, modified = moment().valueOf()) ->
      ME.reportStateChange 'Assessment: sending all answers'
      api.post "#{api.surveys}/purpose-assessment/answers", {answers: answers.map (a) -> _.merge modified: moment().valueOf(), a}
      .catch (err) ->
        ME.reportIntermediateError 'Assessment: sending all answers error', err
        err

  withHandlers
    getPattern: ({_getPattern, user}) -> (answers) ->
      ME.reportStateChange 'Assessment: pattern collecting'
      _getPattern answers
      .then () ->
        ME.reportStateChange 'Assessment: pattern collected'
      .catch (err) ->
        ME.reportIntermediateError 'Assessment: pattern collected error', err

  withHandlers
    onSubmit: ({
      history
      questions
      answer
      submitting
      error
      currentQ
      nextStep
      postAnswer
      setError
      setSubmitting
      finish
      answering
      setAnswering
      getPattern
      answers
      dataQuestions
      refreshUser
      demographicQuestions
      userStore
    }) -> ->
      resolve = () ->
        nextStep()
        setSubmitting currentQ, false
        setAnswering false

      if answering
        return null

      u = getUser()
      if(u.assessmentCompleted)
        history.push('/')
        return

      setAnswering true
      q = questions[currentQ]
      answerEmpty =
        if q.structure == 'option-radio-custom'
          !answer?.answer? || (answer.hasTextField && !answer.answerText)
        else if q.structure != 'option-radio-multi'
          !answer?
      if answerEmpty
        setError currentQ, 'required'
        setAnswering false
        return

      if submitting or error
        setAnswering false
        return

      setSubmitting currentQ, true
      postAnswer currentQ, answer
      .then ->
        last = _.last dataQuestions
        isLast = last.id is questions[currentQ]?.id

        lastDemographic = _.last demographicQuestions
        isLastDemographic = lastDemographic && (lastDemographic.id is questions[currentQ]?.id)

        if isLast
          questionsAnswers = questions
          .map (q, i) -> _.merge q, {i}
          .filter (q) -> q.id in _.pluck 'id', dataQuestions
          .map (q) ->
            value: answers[q.i]
            questionId: q.id

          getPattern questionsAnswers
          .then (u) ->
            userStore.getUser()
            refreshUser()
          .then resolve
        else if isLastDemographic
          refreshUser()
          .then resolve
        else resolve()
      , (e) ->
          console.log 'unknown error', e
          #TODO: handle error
          setSubmitting currentQ, false
          setAnswering false
          setError currentQ, 'unknown'

    onChange: ({currentQ, addAnswer, setError}) -> (answer) ->
      setError currentQ, null
      addAnswer currentQ, answer

  lifecycle
    componentDidMount: ->
      allAnswered = @props.dataQuestions.every((q) -> q.answer?)
      if allAnswered
        questionsAnswers = @props.dataQuestions
        .map (q) =>
          value: q.answer
          questionId: q.id

        @props.getPattern questionsAnswers
        .then (u) =>
          @props.refreshUser()
        , (e) ->
            console.log 'unknown error', e
            #TODO: handle error

    componentDidUpdate: (prevProps) ->
      if(prevProps.focusRef != @props.focusRef)
        @props.focusRef && @props.focusRef.focus()

  ({
    question
    questions
    answer
    rigAvg
    error
    submitting
    state
    start
    opening
    nextStep
    prevQ
    currentQ
    onSubmit
    onChange
    progress
    user
    backstop
    backToDriver
    finish
    roleStats
    functions
    showExitModal
    closeExitModal
    exitModalVisible
    intersitialDriverVisible
    intersitialDriverOff
    changeLanguage
    currentLanguage
    languageOptions
    changingLanguage
    completeWholeAssessment
    lastScreen
    userRole
    setFocusRef
    t
  }) ->
    lastQuestion = currentQ == questions?.length - 1
    visibleSection = switch state
      when 'intro'
        r Intro, {t, setFocusRef, start, changeLanguage, currentLanguage, languageOptions, changingLanguage, user}
      when 'opening'
        r Opening, {t, rigAvg, setFocusRef}
      when 'intersitialRIG'
        r IntersitialRIG, {t, currentQ, setFocusRef}
      when 'intersitialDemographic'
        r IntersitialDemographic, {t, setFocusRef}
      when 'intersitialNetwork'
        r IntersitialNetwork, {t, user, userRole, setFocusRef}
      when 'roleItersitial'
        r RoleStats, {t, user, roleStats, functions, setFocusRef}
      when 'summary'
        r Summary, {t, setFocusRef}
      when 'results', 'demographicResults'
        r Result, {t, question, user, setFocusRef}
      else
        if question
          r Question, {
            key: "q#{currentQ}"
            question
            answer
            onChange
            error
            t
            setFocusRef: if !intersitialDriverVisible then setFocusRef
          }

    r.main className: "Assessment page", [
      r SkipLink
      r Progress, value: progress, visible: state isnt 'intro', exit: showExitModal, t: t
      r.div className: 'Assessment__container container', [
        r.div className: 'Assessment__content', [
          r.div id: "main", className: "Assessment__itemPlaceholder", visibleSection
          r TransitionGroup,
            component: null
            r Transition,
              key: "#{state}:#{currentQ}"
              timeout: 300
              className: "Assessment__animationContainer"
              classNames: "Assessment__animationContainer"
              r.div
                className: cx "Assessment__itemContainer", if question?.structure is 'option-radio' then "Assessment__itemRadio" else if question?.structure is 'option-icons' then "Assessment__itemIcon"
                visibleSection
        ]

        r Nav,
          t: t
          next: if state is 'question' then onSubmit else nextStep
          completeWholeAssessment: if lastScreen then completeWholeAssessment
          prev:
            if state isnt 'intro' and !lastScreen
              prevQ
          submitting: submitting
          prevDisabled: state is 'results' and questions[currentQ - 1]?.section isnt 'results'
          error: if state is 'demographicResults' then false else error
          nextText:
            if state is 'intro'
              t.find 'assessment.general.start.title'
            else if lastScreen
              t.find 'assessment.page.video.ready'
          prevText:
            if state is 'intersitialDriver' then t.find 'assessment.general.exit.back'
      ]
      r Modal,
        isOpen: intersitialDriverVisible
        close: intersitialDriverOff
        overlayClassName: "Assessment__intersitialDriverModal"
        className: "Assessment__intersitialDriverModalContent"
        r IntersitialDriver,
          back: backToDriver
          next: intersitialDriverOff
          t: t
          setFocusRef: setFocusRef
      r Modal,
        isOpen: exitModalVisible
        onClose: closeExitModal
        className: "Assessment__modal"
        [
          r.p {}, t.find 'assessment.general.exit.title'
          r.div className: "Assessment__modalBtns", [
            r.button
              className: 'btn btn_secondary btn_outlined_bluePurple',
              id: 'Assessment__exitModal_close'
              onClick: closeExitModal
              t.find 'assessment.general.exit.back'
            r Link,
              to: '/logout'
              id: 'Assessment__exitModal_logout'
              className: 'btn btn_secondary btn_solid_bluePurple', t.find 'assessment.general.exit.exit'
          ]
        ]
    ]


export default composeComponent 'Assessment',
  observer
  addStatesWithHandlers
    loading: false
    user: null
    currentLanguage: null
    languageOptions: []
    data: null
    rigScores: []
    functions: []
    industries: []
    roleStats: []
    rigQuestions: []
  withProps ({user, nextEmptyQuestion, questions, data, rigScores, industries, functions, rigQuestions, t}) ->
    includeRig = -> user.isEmployee and user?.organizationSettings.rigSettings?.isEnabled
    rigLabels = [
      'Frustrated'
      'Unhappy'
      'Meh'
      'Happy'
      'Pumped Up'
    ]
    relationshipAreasOptions = [
      'problem_solve',
      'collaborate',
      'energize',
      'diversify',
      'purpose',
      'none'
    ]
    results = [
      'purposeType'
      'who'
      'why'
      'how'
      'purposeStatement'
    ]
    demographicResults = [
      'purposeOrg'
    ]

    [ignoreArvQuestions, standardQuestions] =
      if data
        _.partition(
          (q) -> q.ignoreArv
          data.questions
        )
      else [[], []]

    demographicQuestions = if ignoreArvQuestions.every((q) => q.answer?) then [] else ignoreArvQuestions

    rigQ = ['relationship', 'impact', 'growth'].map (name) ->
      q = rigQuestions[name]

      id: name
      questionId: q?.id
      section: 'rig'
      structure: 'option-icons'
      description: q?.questions?[0]
      message: "How do you feel about your #{name.toUpperCase()} at work?"
      options: rigLabels.map (l, i) -> value: i, label: l
      labels: [
        'Frustrated'
        'Unhappy'
        'Meh'
        'Happy'
        'Pumped Up'
      ]
      min: 0
      defaultValue: 2

    relationshipQ =
      id: 'relationshipAreas'
      section: 'rig'
      structure: 'option-radio-multi'
      message: t.find("surveys.purpose-assessment.questions.relationshipAreas")
      options: relationshipAreasOptions.map((l) => value: l, label: t.find("surveys.purpose-assessment.questions.relationshipAreas.options.#{l}"))

    questions = if data
      _.concat(
        _.pipe(
          _.map (q) ->
            switch q.structure
              when 'option-radio'
                _.merge {section: if q.order is 1 then 'driver'}, _.evolve
                  options: _.map (o) -> value: o.id
                  q
              when 'option-slider'
                _.merge q, section: if q.order is 1 then 'driver'
              else q
          _.concat _.__, results.map (r) ->
            id: r
            section: 'results'
          _.concat _.__, _.map(
            (q) -> _.merge q, {
              section: 'demographic'
              options: q.options.map (o) ->
                option = t.find "surveys.purpose-assessment.questions.#{q.id}.options.#{o.id}"
                {
                  ...o,
                  value: o.id,
                  label:
                    if t.exists("dictionaries.#{q.questionType?.replace('-', '')}.#{option}.name")
                      t.find("dictionaries.#{q.questionType?.replace('-', '')}.#{option}.name")
                    else option
                }
              placeholder: switch q.questionType
                when "job-function" then 'assessment.page.role.placeholder'
                when "industry" then 'assessment.page.industry.placeholder'
                else "Select option"
            }
            demographicQuestions
          )
          _.when includeRig, _.concat rigQ.slice(0, 1).concat(relationshipQ).concat(rigQ.slice(1))
          , _.__
        ) standardQuestions
      ) demographicResults.map (r) ->
        id: r
        section: 'demographicResults'
    nextEmptyQuestion = data?.questions.findIndex (q) -> not q.answer?
    backstop = do ->
      {isCompleted} = data ? {}
      if !user or !questions
        -1
      else if user.hasPattern() and nextEmptyQuestion == -1
        _.findLastIndex(((q) -> q.answer?), questions)
      else if nextEmptyQuestion == -1
        _.findLastIndex(((q) -> !q.section), questions) - 1
      else if nextEmptyQuestion
        c = _.findIndex _.propEq('id', data?.questions[nextEmptyQuestion - 1]?.id), questions
        c
      else if !_.isEmpty rigScores
        2
      else -1
    suggestedIndustry = if user?.org? then industries.find _.propEq 'slug', user?.organization.defaultIndustry
    dataQuestions = standardQuestions

    {
      questions
      dataQuestions
      suggestedIndustry
      backstop
      nextEmptyQuestion
      demographicQuestions
    }

  withHandlers
    loadData: ({setLoading, setUser, changeLanguage, t, setData, setRigScores, setIndustries, setFunctions, setFocus, setRigQuestions, setRoleStats, setLanguageOptions, setCurrentLanguage}) -> ->
      setLoading true
      t.useUserValues(true)

      new Promise((resolve, reject) =>
        fetchUser()
        .then (user) ->
          setUser user
          isGriffith = user.organization.code.includes 'griffith'

          trans =
            t.getTranslation(user)
            .then ->
              getLanguage user.slug
              .then setCurrentLanguage
              .catch ->
                changeLanguage('English')
                Promise.resolve null

          availableLangs = getAvailableLanguages()
          .then (data) ->
            data.map (d) -> _.merge d, {label: d.name, value: d.name}
            .filter (d) ->
              return true unless isGriffith
              customGriffith.languages.includes d.name
          .then(setLanguageOptions)

          rigScores =
            if user.organizationSettings.rigSettings.isEnabled
              getScoreBaseline user
              .then setRigScores
              .catch (err) ->
                ME.reportIntermediateError 'Assessment: rig scores error', err
                []
            else Promise.resolve([])

          data = getAssessmentData()
          .then setData
          .catch (err) ->
            ME.reportIntermediateError 'Assessment: questions error', err
            {}

          functions = getJobFunctions()
          .then setFunctions
          .catch (err) ->
            ME.reportIntermediateError "Assessment: job functions error", err
            []

          industries = getIndustries()
          .then setIndustries
          .catch (err) ->
            ME.reportIntermediateError "Assessment: industries error", err
            []

          stats = getRoleStats()
          .then setRoleStats
          .catch (err) -> ME.reportIntermediateError 'Assessment: role stats error', err

          rigQuestions =
            if user.isApplicant or not user.organizationSettings.rigSettings?.isEnabled
              setRigQuestions []
              Promise.resolve([])
            else
              getRigQuestions()
              .then setRigQuestions
              .catch (err) ->
                ME.reportIntermediateError "Assessment: rig questions error", err
                []

          Promise.all([trans, availableLangs, rigScores, data, stats, functions, industries, rigQuestions])
          .then resolve, reject

          user
      )
      .finally -> setLoading false

  lifecycle
    componentDidMount: ->
      @props.loadData()
      window.Assessment = @

  withHandlers
    refreshUser: ({setUser}) -> ->
      fetchUser()
      .then setUser

  (props) ->
    stores = useStore()
    {userStore} = stores

    if props.loading || !props.user
      return r Loader, {loading: true}

    r Assessment, {...props, userStore}
