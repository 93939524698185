import React from 'react'
import PT from 'prop-types'
import * as _ from 'ramda'
import {withRouter} from 'react-router'
import {composeComponent, addStatesWithHandlers} from 'utils/react-tools'
import * as ME from 'api/metrics'
import {lifecycle, withProps} from 'recompose'
import {fetchUser} from 'auth/requests'
import Loader from 'theme/Loader'
import DownloadButton from 'reports/DownloadReportButton'
import {fetchTeamReport} from 'reports/requests'

import TeamReportContent from './TeamReportContent'

import './TeamPrintReport.sass'


export default composeComponent 'TeamReport',
  withRouter
  addStatesWithHandlers
    loading: false
    user: null
    report: null

  lifecycle
    componentDidMount: ->
      {match: {params}, setUser, t, setLoading, setReport} = @props

      setLoading(true);
      ME.reportStateChange('Team Report: loading', {teamSlug: params.slug});

      Promise.all([
        fetchUser(),
        fetchTeamReport(params.slug),
      ]).then(
        ([user, report]) ->
          setUser(user);
          setReport(report);
          ME.reportStateChange('Team Report: loaded', {teamSlug: params.slug});
          setLoading(false);
        , (error) ->
          ME.reportIntermediateError('Team Report: loading error', error, {teamSlug: params.slug});
          setLoading(false);
      )
  withProps ({report, match: {params}, user}) ->
    member:
      if params.member and report?
        report.members.find (m) -> m.slug is params.member
      else
        user

  ({report, user, loading, member}) ->
    unless report
      return null

    teamMember = if member
      report.members.find _.propEq 'slug', member.slug

    React.createElement("section", {"className": "TeamReport"},
      React.createElement(Loader, {"loading": (loading), "color": "bg"}),
      React.createElement(DownloadButton, {"name": "TeamReport_#{user?.firstName}_#{user?.lastName}"}),

      React.createElement("div", {"id": "ReportContent"},
        React.createElement(TeamReportContent, { \
          "teamMember": (teamMember),  \
          "report": (report)
        })
      )
    )
