import React from 'react'
import {composeComponent} from 'utils/react-tools'
import cx from 'classnames'
import {withHandlers, withStateHandlers, lifecycle, withState, withProps} from 'recompose'

RATING_SIZE = 5

export default composeComponent 'Competencies.Rating',
  withState 'focused', 'focus', false
  withState 'screenReaderMessage', 'setScreenReaderMessage', ''
  withHandlers
    handleKeyDown: ({focused, onChange, value}) -> (e) ->
      if !focused
        return
      v = value ? 0
      switch e.key
        when 'ArrowRight'
          if v + 1 <= RATING_SIZE
            onChange? v + 1
        when 'ArrowLeft'
          if v - 1 >= 0
            onChange? v - 1

    handleClick: ({onChange, setScreenReaderMessage}) -> (index) ->
      stars = index is 0 ? 'star' : 'stars'
      onChange? index + 1

      if index is 0
        setScreenReaderMessage 'Rating changed to 1 star'
      else
        setScreenReaderMessage 'Rating changed to ' + (index + 1) + ' stars'

  ({
    value
    onChange
    size = 'normal' #normal, xs
    focused
    focus
    handleKeyDown
    handleClick
    screenReaderMessage
  }) ->
    readonly = not onChange?

    React.createElement("div", { \
      "className": "Competencies__rating",  \
      "tabIndex": "0",  \
      "onFocus": (-> focus true),  \
      "onBlur": (-> focus false),  \
      "onKeyDown": (handleKeyDown)
    },
      ([0...RATING_SIZE].map (i) ->
        selected = value >= i + 1
        React.createElement("span", { \
          "className": (cx "Competencies__rating-item", size,
            readonly: readonly
            'fas fa-star': selected
            'fal fa-star': !selected
          ),  \
          "onClick": (-> handleClick i),  \
          "key": (i)
        })
      ),
      React.createElement("div", {"aria-live": "assertive", "aria-atomic": "true", "className": "Competencies__screenReaderMessage"},
        (screenReaderMessage && React.createElement("span", null, (screenReaderMessage)))
      )
    )
