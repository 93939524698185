import api from 'api';

export const fetchUser = () => {
  return api.get('/me');
}

export const getIndividualReport = (slug) => {
  return api.get(`/members/${slug}/report`);
}

export const updateUser = (dto) => {
  return api.patch(`/members/me`, dto);
}

export const getMember = (slug) => {
  return api.get(`/members/${slug}`);
}

export const getNetworkStatistics = (slug) => {
  return api.get(`/${slug}/network/statistics`);
}

export const uploadAvatar = (fd) => {
  return api.post("/members/me/avatar", fd);
}
