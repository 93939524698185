import React from 'react'
import {withRouter} from 'react-router-dom'
import {composeComponent} from 'utils/react-tools'
import {lifecycle, withState, withProps} from 'recompose'
import {reduxForm, Field} from 'redux-form'
import {confirmEmail} from 'auth/requests'
import Layout, {Result, Link} from 'auth/Layout'


export default composeComponent 'AuthPages.Confirm',
  withRouter
  withState 'state', 'setState', null
  lifecycle
    componentDidMount: ->
      {match} = @props
      confirmEmail match.params.token
      .then @props.setState, @props.setState
  ({state}) ->
    React.createElement(Layout, {"headline": "Request email confirmation"},
      (switch state
        when 'success'
          React.createElement(Result, null, """
            Your email has been confirmed.
""", React.createElement("br", null),
            ('Go to the '),
            React.createElement(Link, { \
              "style": "pink",  \
              "to": "/"
            }, """
              home page
"""), """
            \'.\'
""")
        when 'badToken'
          React.createElement(Result, null, """
            We were unable to confirm your email, sorry.
""")
        when 'error confirming email'
          React.createElement(Result, null, """
            Unexpected error.
""", React.createElement("br", null),
            ('Please, try requesting '),
            React.createElement(Link, { \
              "style": "pink",  \
              "to": "/confirm"
            }, """
              email confirmation
"""),
            (' again.')
          )
        else
          'Please wait a second while we confirm your email...'
      )
    )
