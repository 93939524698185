import React from 'react'
import * as _ from 'ramda'
import {withRouter} from 'react-router'
import {composeComponent} from 'utils/react-tools'
import {mapProps} from 'recompose'
import Page from 'reports/PrintPage'
import Markdown from 'theme/Markdown'
import {IMG_LOGO_BLUE} from 'theme/assets/assets'

import DriverPage from './DriverPage'


drivers =
  who: ['Individual', 'Organization', 'Society']
  why: ['Harmony', 'Karma']
  how: ['Community', 'Human', 'Knowledge', 'Structure']


memberDisplayName = (member) ->
  "#{member?.firstName} #{member?.lastName}"

Field = ({question, t}) ->
  switch question.questionType
    when 'Radio'
      React.createElement("div", {"className": "PrintableConversation__field"},
        ([0...question.optionsCount].map (i) ->
          React.createElement("div", {"key": (i), "className": "PrintableConversation__fieldOption"},
            React.createElement("span", {"className": "fal fa-square"}),
            (t.find "peerconversation.question.#{question.label}.option.#{i}")
          )
        )
      )
    when 'Multi'
      React.createElement("div", {"className": "PrintableConversation__field"},
        React.createElement("span", null,
          React.createElement("span", {"className": "fal fa-info-circle"}), """
          You can pick more than one answer.
"""),
        ([0...question.optionsCount].map (i) ->
          React.createElement("div", {"key": (i), "className": "PrintableConversation__fieldOption"},
            React.createElement("span", {"className": "fal fa-square"}),
            (t.find "peerconversation.question.#{question.label}.option.#{i}")
          )
        )
      )
    when 'Slider'
      React.createElement("div", {"className": "PrintableConversation__field"},
        ([0...question.optionsCount].map (i) ->
          React.createElement("div", {"key": (i), "className": "PrintableConversation__fieldOption"},
            React.createElement("span", {"className": "fal fa-square"}),
            (t.find "peerconversation.question.#{question.label}.option.#{i}")
          )
        )
      )
    else
      React.createElement("span", {"className": "PrintableConversation__questionsSpacer"})



export default composeComponent 'PrintableConversationPage',
  mapProps (props) ->
    _.evolve(
      conversation: (conversation) ->
        user = props.user
        t = props.t

        [program, set, convLabel] = _.split '/', conversation?.label ? ''
        participant = conversation?.participant?.find (p) -> p.member.slug is user?.slug
        partners = conversation?.participant?.filter (p) -> p.member.slug isnt user?.slug
        partner = partners?[0]
        imFirst = participant?.member.memberId < partner?.member.memberId

        _.merge conversation,
          program: program
          set: set
          convLabel: convLabel
          state: participant?.state
          image: if convLabel then t.find "peerconversation.conversation.#{convLabel}.image"
          me: participant
          partners: partners
          partner: partner
          goals: t.getSplitted "peerconversation.conversation.#{convLabel}.goals"
          questions: conversation?.questions
          .map (q) ->
            _.merge q,
              meWrite: q.answerType isnt 'Prework' and
                (q.answerType is 'BothWriteAnswer') or
                (q.answerType is 'FirstWriteAnswer' and imFirst) or
                (q.answerType is 'SecondWriteAnswer' and not imFirst)
    ,
      props
    )

  ({user, conversation, answers, loading, t}) ->

    questionsToShow = conversation?.questions?.filter (q) -> q.meWrite
    questionsCounter = Math.ceil questionsToShow?.length / 2

    number = 1 # page numbering start

    type = 'discover'
    driver = 'who'

    goals = ['Take turns answering each question', 'Take notes for your partner; return them when you’re done', 'Complete the two prompts on the last page']

    title = t.find "peerconversation.conversation.#{conversation.convLabel}.title"
    heading = t.find("peerconversation.program.#{conversation.program}.title") + " / " + t.find "peerconversation.set.#{conversation.set}.title"
    description = t.find "peerconversation.conversation.#{conversation.convLabel}.description"

    footer =
      React.createElement("div", {"className": "PrintableConversation__footer"},
        React.createElement("span", {"className": "PrintableConversation__footer_left"}, (t.find 'colleagues.guide.copyright')),
        React.createElement("img", {"src": (IMG_LOGO_BLUE), "alt": "imperative logo"})
      )

    React.createElement("div", {"id": "ReportContent", "className": "PrintableConversation__content"},
      ([0..questionsCounter - 1].map (c) ->
        React.createElement(Page, {"footer": (footer), "marginSize": "sm", "key": (c)},
          React.createElement("h2", {"className": "PrintableConversation__coachee"}, (t.find 'colleagues.guide.coachee')),
          React.createElement("h1", {"className": "PrintableConversation__text-color #{driver}"},
            (title),
            React.createElement("span", {"className": "PrintableConversation__icons"},
              React.createElement("span", {"className": "fas fa-feather-alt"}),
              React.createElement("span", {"className": "fas fa-comments"})
            )
          ),
          React.createElement("h2", {"className": "PrintableConversation__heading"},
            (heading)
          ),
          React.createElement("p", {"className": "PrintableConversation__description"},
            (description)
          ),

          React.createElement("div", {"className": "PrintableConversation__goals #{driver}"},
            React.createElement("div", {"className": "PrintableConversation__goals_title"}, "STEPS"),
            React.createElement("div", {"className": "PrintableConversation__goals_itemContentBackground"},
            (goals.map (g, i) ->
              React.createElement("div", {"key": (i), "className": "PrintableConversation__goals_item PrintableConversation__goals_item#{i} #{driver}"},
                React.createElement("span", {"className": "PrintableConversation__goals_itemContent"}, (g))
              )
            )
            )
          ),

          React.createElement("table", {"className": "PrintableConversation__questions #{driver}"},
            React.createElement("thead", null,
              React.createElement("tr", null,
                React.createElement("th", {"className": "#{driver}"}, "DISCUSSION QUESTIONS"),
                React.createElement("th", {"className": "#{driver}"}, "TIPS")
              )
            ),
            React.createElement("tbody", null,
              (questionsToShow[(0+c*2)..(1+c*2)].map (q, i) ->
                if q.action
                  React.createElement("tr", {"key": (i)},
                    React.createElement("td", {"className": "#{driver}"},
                      React.createElement(Markdown, { \
                        "className": "PrintableConversation__questions",  \
                        "source": (t.find "peerconversation.question.#{q?.label}.title", user)
                      }),
                      React.createElement("ul", {"className": "PrintableConversation__questionsGoals"},
                        (conversation.goals.map (goal, i) ->
                          React.createElement("li", {"key": (i)}, (goal))
                        )
                      ),
                      React.createElement(Markdown, { \
                        "className": "PrintableConversation__questions",  \
                        "source": (t.find "peerconversation.question.#{q?.label}.action", user)
                      })
                    ),
                    React.createElement("td", {"className": "#{driver}"},
                      React.createElement(Markdown, {"source": (t.find "peerconversation.question.#{q.label}.description")})
                    )
                  )
                else
                  React.createElement("tr", {"key": (i)},
                    React.createElement("td", {"className": "#{driver}"},
                      React.createElement(Markdown, {"className": "PrintableConversation__questions", "source": (t.find "peerconversation.question.#{q.label}.title")}),
                      React.createElement(Field, { \
                        "question": (q),  \
                        "t": (t)
                      })
                    ),
                    React.createElement("td", {"className": "#{driver}"},
                      React.createElement(Markdown, {"source": (t.find "peerconversation.question.#{q.label}.description")})
                    )
                  )
              )
            )
          )
        )
      ),

      React.createElement(Page, null,
        React.createElement("h2", {"className": "PrintableConversation__coachee"}, (t.find 'colleagues.guide.coachee')),
        React.createElement("h1", {"className": "PrintableConversation__text-color #{driver}"},
          (title),
          React.createElement("span", {"className": "PrintableConversation__icons"},
            React.createElement("span", {"className": "fas fa-feather-alt"}),
            React.createElement("span", {"className": "fas fa-comments"})
          )
        ),
        React.createElement("h2", {"className": "PrintableConversation__heading"}, (heading)),
        React.createElement("p", {"className": "PrintableConversation__description"},
          (description)
        ),

        React.createElement("table", {"className": "PrintableConversation__action"},
          React.createElement("tbody", null,
            React.createElement("tr", null,
              React.createElement("td", {"className": "#{driver}"},
                React.createElement("span", null, (t.find 'colleagues.guide.takeaway'))
              ),
              React.createElement("td", {"className": "#{driver}"},
                React.createElement("span", null)
              )
            ),
            React.createElement("tr", null,
              React.createElement("td", {"className": "#{driver}"},
                React.createElement("span", null, (t.find 'colleagues.guide.action'))
              ),
              React.createElement("td", {"className": "#{driver}"},
                React.createElement("span", null)
              )
            )
          )
        )
      ),
      (for key, value of drivers
        React.createElement("div", null)
        value.map (k) ->
          React.createElement(DriverPage, {"dimension": (key), "driver": (k), "key": (k), "t": (t)})
      )
    )
