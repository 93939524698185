import React from 'react'
import {setToken, getRedirUrl} from 'api'
import moment from 'moment'
import cx from 'classnames'
import {withRouter} from 'react-router'
import {withHandlers, withState, lifecycle, withProps} from 'recompose'
import qs from 'qs'
import {composeComponent} from 'utils/react-tools'
import {
  SSOAuthFailed
  SSOMergeFailed
  SSONoAccount
  SubscriptionExpired
  TokenRequired
} from 'auth/errors'
import {Cancelled as SSOCancelled} from 'auth/configure'
import Layout, {Link, Hint, Subheading} from 'auth/Layout'
import {ssoAuthenticateAndLogin, getAuthBySocial, initUser} from 'auth/requests'
import {saveAuth, saveConsents, setUser} from 'api'

import s from './SSO.sass'

config = window.config

samlUrl = config?.sso?.samlUrl
samlPwcUrl = config?.sso?.samlPwcUrl
samlHasbroUrl = config?.sso?.samlHasbroUrl
samlEquitableUrl = config?.sso?.samlEquitableUrl
samlIcfUrl = config?.sso?.samlIcfUrl
samlIntuitiveUrl = config?.sso?.samlIntuitiveUrl
samlZillowUrl = config?.sso?.samlZillowUrl
samlOswaldCompaniesUrl = config?.sso?.samlOswaldCompaniesUrl
samlBostonScientificUrl = config?.sso?.samlBostonScientificUrl
samlSiteImproveUrl = config?.sso?.samlSiteImproveUrl
samlAirbnbUrl = config?.sso?.samlAirbnbUrl
samlSeattleCityLightUrl = config?.sso?.samlSeattleCityLightUrl

authNMethods = ['hasbro', 'equitable', 'oswald-companies', 'boston-scientific', 'site-improve', 'seattle-city-light', 'icf']

SAMLErrors = ['ProviderAuthenticationFailed', 'SAMLEmailAddressNotFound', 'SAMLRequestAlreadyUsed', 'SAMLRequestExpired', 'SAMLRequestIdNotFound', 'SAMLUniqueIdentifierNotFound']
SAMLErrorMessage = 'An error has occurred logging you in with SSO. Please try again or contact our support team.'
OtherErrorMessage = 'An error has occurred. Please try again or contact our support team.'

export default composeComponent 'AuthPages.SamlSignin',
  withRouter
  withProps ->
    {www: {address: www}} = window.config
    AuthLinks:
      program: "#{www}/purpose-program"

  withState 'state', 'setState', 'ready' # 'ready', 'busy', 'error
  withState 'error', 'setError', null

  withHandlers
    authenticate: -> ssoAuthenticateAndLogin
    errorToMessage: -> (err) ->
      if err in SAMLErrors
        SAMLErrorMessage
      else
        OtherErrorMessage

  withHandlers
    auth: ({state, setState, setError, authenticate}) ->
      (provider) -> (e) ->
        e.preventDefault()
        return if state is 'busy'

        setState 'busy'
        setError null

        authenticate provider
        .catch (e) ->
          setState 'error'

    redirectToSSO: ({method, setState}) -> ->
      setState('busy')

      url = switch method
        when 'pwc' then samlPwcUrl
        when 'hasbro' then samlHasbroUrl
        when 'equitable' then samlEquitableUrl
        when 'icf' then samlIcfUrl
        when 'intuitive' then samlIntuitiveUrl
        when 'zillow' then samlZillowUrl
        when 'airbnb' then samlAirbnbUrl
        when 'oswald-companies' then samlOswaldCompaniesUrl
        when 'boston-scientific' then samlBostonScientificUrl
        when 'site-improve' then samlSiteImproveUrl
        when 'seattle-city-light' then samlSeattleCityLightUrl
        else samlUrl

      samlMethod = switch method
        when 'oswald-companies' then 'oswaldCompaniesSaml'
        when 'boston-scientific' then 'bostonScientificSaml'
        when 'site-improve' then 'siteImproveSaml'
        when 'seattle-city-light' then 'seattleCityLightSaml'
        else method + 'Saml'

      if (authNMethods.indexOf(method) > -1)
        getAuthBySocial({samlMethod: samlMethod})
        .then(
          (socialUrl) => location.href = url + '?SAMLRequest=' + encodeURIComponent(socialUrl)
          (error) => setState('error')
        )
      else
        location.href = url

  lifecycle
    componentDidMount: ->
      params = qs.parse location.search, ignoreQueryPrefix: true
      redirUrl = @props.redirUrl

      if params.jwt
        @props.setState('busy')
        initUser({jwt: params.jwt}, 'saml')
        .then(
          =>
            if(redirUrl)
              if (redirUrl.indexOf('://') > 0 || redirUrl.indexOf('//') == 0)
                url = new URL(redirUrl)
                url.searchParams.set('session_token', params.jwt)
                window.location.replace(url)
              else @props.history.push(redirUrl)
            else @props.history.push('/')
          (error) =>
            @props.setState('error')
            @props.setError(error)
        )
      else if params.error
        @props.setError params.error

  ({user, state, auth, loginError, error, method, redirectToSSO, errorToMessage}) ->
    provider = switch method
      when 'pwc' then 'samlPwc'
      when 'hasbro' then 'samlHasbro'
      when 'equitable' then 'samlEquitable'
      when 'icf' then 'samlIcf'
      when 'intuitive' then 'samlIntuitive'
      when 'zillow' then 'samlZillow'
      when 'oswald-companies' then 'samlOswaldCompanies'
      when 'boston-scientific' then 'samlBostonScientific'
      when 'site-improve' then 'samlSiteImprove'
      when 'airbnb' then 'samlAirbnb'
      when 'seattle-city-light' then 'samlSeattleCityLight'
      else 'saml'

    logo = switch method
      when 'pwc' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/PwCLogo.png"
      when 'hasbro' then "https://res.cloudinary.com/imperativedev/image/upload/v1610051713/kanfkf7zshon4djbqwdq.png"
      when 'icf' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/icf_logo_big.png"
      when 'intuitive' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/intuitive_logo_big.png"
      when 'zillow' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/zillow_logo.png"
      when 'equitable' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/equitable_logo.png"
      when 'oswald-companies' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/oswaldcompanies_logo.png"
      when 'boston-scientific' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/bostonscientific_logo.png"
      when 'site-improve' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/siteimprove_logo23.png"
      when 'airbnb' then "https://assets.imperative.com/static.imperative.com/images/logos/clients/airbnb_logo.png"
      when 'seattle-city-light' then "https://res.cloudinary.com/imperativedev/image/upload/v1644271172/xzi4r4n67qyg4m7hbgxl.png"

    React.createElement(Layout, {"headline": "Sign In"},
      (if !samlPwcUrl or !samlUrl or !samlIcfUrl or !samlIntuitiveUrl or !samlZillowUrl or !samlHasbroUrl or !samlEquitableUrl or !samlOswaldCompaniesUrl or !samlBostonScientificUrl or !samlSiteImproveUrl or !samlAirbnbUrl or !samlSeattleCityLightUrl
        React.createElement("p", null, "Missing SSO Configuration")
      else
        React.createElement("div", {"className": "SSO"},
          (logo &&
            React.createElement("img", {"src": (logo), "className": (if method == 'equitable' then 'bigger' else ''), "alt": (method)})
          ),
          React.createElement("p", {"className": "SSO__info"}, """
            Imperative is an employee engagement and networking platform where you’ll find a safe space to thoughtfully and courageously discuss your job, your life, and what drives your sense of purpose.
            Imperative enables you to own your fulfillment at work with the support and accountability that one-on-one coaching with a peer in your network provides.
"""),
          React.createElement("div", {"className": "SSO__inner"},
            (if state is 'busy'
              React.createElement("p", {"className": "SSO__status"}, """
                Signing in...
""")
            else
              React.createElement("div", {"className": "SSO__providers"},
                React.createElement("div", {"className": "SSO__privacy"}, """
                  By continuing, you agree to our """, React.createElement("a", {"href": "https://www.imperative.com/terms-of-service", "target": "_blank"}, "Terms of Service")
                ),
                React.createElement("button", { \
                  "className": (cx "SSO__provider saml", state is 'busy' and "SSO__disabled"),  \
                  "onClick": (redirectToSSO)
                },
                  React.createElement("span", {"className": "SSO__providerSaml"}, "Sign in with SSO")
                ),
                React.createElement("div", {"className": "SSO__privacy"},
                  React.createElement("a", {"href": "https://www.imperative.com/privacy-policy", "target": "_blank"}, "Privacy Policy")
                )
              )
            ),
            (if loginError || error
              React.createElement("p", {"className": "SSO__error"}, (loginError || errorToMessage error))
            )
          ),

          React.createElement(Hint, null,
            React.createElement(Link, {"style": "dark", "to": "/signin"}, """
              Not your organization? Sign-in here
""")
          )
        )
      )
    )
