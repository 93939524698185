import store from 'store';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import CookieConsent from 'CookieConsent';
import 'theme/grid/grid-styles.sass';
import './app.sass';
import Routes from './routes';

const App = () => (
  <Provider store={store}>
    <div className="App">
      <div className="App__content">
        <Routes />
      </div>
      <CookieConsent />
    </div>
  </Provider>
);

export default hot(module)(App);
