import React from 'react';
import {IMG_AARON} from 'theme/assets/assets'

const IntersitialDriver = ({t, back, next, setFocusRef}) => (
  <section className="Assessment__intersitialDriver">
    <img src={IMG_AARON} alt=""/>
    <div className="Assessment__text" tabIndex="-1" ref={setFocusRef}>
      <p className="p margin-top-3">{t.find('assessment.general.arrons.tip1')}</p>
      <p className='p margin-top-3'>{t.find('assessment.general.arrons.tip2')}</p>
    </div>
    <div className="Assessment__intersitialDriverModalButtons">
      <button
        className="btn btn_primary btn_outlined_bluePurple"
        id="Assessment__intersitialDriver_back"
        onClick={back}
      >
        {t.find('assessment.general.arrons.back')}
      </button>
      <button
        className="btn btn_primary btn_solid_bluePurple"
        id="Assessment__intersitialDriver_next"
        onClick={next}
      >
        {t.find('assessment.general.arrons.next')}
      </button>
    </div>
  </section>
);

export default IntersitialDriver;
