import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as states from 'utils/states'
import {withRouter} from 'react-router-dom'
import Loader from 'theme/Loader'
import cx from 'classnames'
import moment from 'moment'
import {withHandlers, withState, lifecycle, withProps} from 'recompose'
import {getConversationsDefinitions, updateConversation} from 'conversations/requests'
import * as _ from 'ramda'
import Goals from 'conversations/Goals'


export default composeComponent 'Conversation.Agenda',
  withRouter
  withState 'popupVisible', 'showPopup', false
  withHandlers
    questionAnswered: ({user, conversation, questions, isQuestionAnswered}) -> (q) ->
      return false unless q
      convQuestions = questions.filter((qq) -> q.label == qq.label);
      allAnswered = convQuestions.every(isQuestionAnswered)

      allAnswered

    handleEscape: ({popupVisible, showPopup}) => (event) =>
      if event.key == "Escape" && popupVisible
        showPopup(false)

  withProps ({programsList, conversation}) ->
    program = programsList?.find (p) -> p.label is conversation?.program
    set = program?.sets?.find (s) -> s.label is conversation?.set
    conversations = set?.definitions

    conversationsList: conversations

  lifecycle
    componentDidMount: ->
      document.addEventListener('keydown', @props.handleEscape)
    componentWillUnmount: ->
      document.removeEventListener('keydown', @props.handleEscape)

  ({children, loading, t, conversation, questions, answers, user, startQuestions, wrapUpQuestions, conversationsList, prework, showPopup, popupVisible, modalLoading, questionAnswered, datePaired}) ->
    convQuestions = questions.filter((q) -> q.imAsking && !q.notInQuestionCount)
    actionQuestions = questions.filter (q) -> q.action
    confirmationQs = questions.filter (q) -> q.actionConfirmation

    myAnswers = answers?.filter (ans) -> ans.memberId is user.id

    confirmationAnswers = answers?.filter((a) -> a.questionId == 'actionConfirmation')
    confirmationFinished = !confirmationQs || confirmationQs?.length == confirmationAnswers?.length
    getStartedFinished = startQuestions.every(questionAnswered)
    wrapUpFinished = wrapUpQuestions.every(questionAnswered)
    allAnswered = convQuestions?.every questionAnswered

    React.createElement("div", {"className": "Questions__header"},
      React.createElement("div", {"className": "container Questions__headerContainer"},
        React.createElement("div", {"className": "Questions__headerTitle"},
          React.createElement("button", {"className": "Questions__headerTitleText", "onClick": (-> showPopup(!popupVisible)), "aria-label": (if popupVisible then "Close conversation goals" else "Show conversation goals")},
            (conversation?.me?.member?.firstName), " \& ", (conversation?.partner?.member?.firstName)
          ),
          (if popupVisible
            React.createElement("div", {"className": "Questions__headerOverlay", "onClick": (-> showPopup(false))})
          ),
          React.createElement(Goals, { \
            "conversation": (conversation),  \
            "className": (cx "Questions__headerPopup", visible: popupVisible),  \
            "conversations": (conversationsList),  \
            "datePaired": (datePaired)
          })
        ),
        React.createElement("div", {"className": "Questions__agenda"},
          React.createElement("div", {"className": "Questions__agendaItem"},
            React.createElement("span", {"className": "Questions__agendaItemLabel"}, "Start"),
            React.createElement("div", {"className": "Questions__agendaItemIcons"},
              React.createElement("span", {"className": (cx "Questions__agendaItemIcon first", active: !getStartedFinished, finished: getStartedFinished)},
                (if !getStartedFinished
                  React.createElement("span", {"className": "fas fa-circle"})
                else
                  React.createElement("span", {"className": "fal fa-check"})
                ),
                React.createElement("span", {"className": "Questions__agendaItemIconTooltip"}, """
                  Get Started
""")
              )
            )
          ),
          React.createElement("div", {"className": "Questions__agendaItem"},
            React.createElement("span", {"className": "Questions__agendaItemLabel"}, "Conversation Questions"),
            React.createElement("div", {"className": "Questions__agendaItemIcons"},
              (convQuestions.map (q, i) ->
                finished = questionAnswered(q)
                active = !finished and ((i is 0 and getStartedFinished) or questionAnswered(convQuestions[i - 1]))
                React.createElement("span", { \
                  "key": (i),  \
                  "className": (cx "Questions__agendaItemIcon second", {active, finished})
                },
                  (if finished
                    React.createElement("span", {"className": "fal fa-check"})
                  else if active
                    React.createElement("span", {"className": "fas fa-circle"})
                  ),
                  React.createElement("span", {"className": "Questions__agendaItemIconTooltip"}, """
                    Question """, (i+1)
                  )
                )
              )
            )
          ),
          React.createElement("div", {"className": "Questions__agendaItem"},
            React.createElement("span", {"className": "Questions__agendaItemLabel"}, "Wrap"),
            React.createElement("div", {"className": "Questions__agendaItemIcons"},
              React.createElement("span", {"className": (cx "Questions__agendaItemIcon third", {active: allAnswered && !wrapUpFinished, finished: wrapUpFinished})},
                (if wrapUpFinished
                  React.createElement("span", {"className": "fal fa-check"})
                else if allAnswered
                  React.createElement("span", {"className": "fas fa-circle"})
                ),
                React.createElement("span", {"className": "Questions__agendaItemIconTooltip"}, """
                  Wrap Up
""")
              )
            )
          )
        )
      )
    )
