import r from 'r-dom'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import qs from 'qs'
import {withProps} from 'recompose'
import {replace} from 'ramda'
import {composeComponent} from 'utils/react-tools'
import cx from 'classnames'

import './Subnav.sass'

LINK_PREFIX = '/'


LINKS = [
    name: 'My conversations'
    to: ''
    matchFunc: (url) ->
      ['/explore', '/manage', '/conversations', '/user-groups'].every (u) ->
        url.indexOf(u) isnt 0
  ,
    name: 'Explore'
    to: '/peer-coaching/explore'
  ,
    name: 'Conversations'
    to: '/peer-coaching/manage'
    cpl: true
  ,
    name: 'Conversation Status'
    to: '/peer-coaching/conversations'
    cpl: true
  ,
    name: 'Cohorts'
    to: '/peer-coaching/user-groups'
    cpl: true
  ,
    name: 'Analytics'
    to: '/peer-coaching/analytics'
    cpl: true
]


export default composeComponent 'Subnav',
  withRouter
  ({links = LINKS, match, user, cpl, queryParams, location}) ->
    r.div className: 'Subnav__nav',
      r.div className: 'Subnav__navItems container-fluid', [
        links.map (link, i) ->
          active =
            if link.strict
              location.pathname is link.to || location.pathname == "#{link.to}/"
            else location.pathname.indexOf(link.to) is 0

          wereOnCPLPage = cpl
          linkIsCplLink = link.cpl

          if (wereOnCPLPage && !linkIsCplLink) || (!wereOnCPLPage && linkIsCplLink)
            null
          else
            r Link,
              key: i
              className: cx 'Subnav__navItem', {active}
              to:
                pathname: link.to
                search: if queryParams then qs.stringify queryParams, addQueryPrefix: true
              link.name
      ]
