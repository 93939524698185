import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, withProps, mapProps, withState} from 'recompose'
import moment from 'moment-timezone'
import cx from 'classnames'
import Avatar from 'theme/Avatar'
import RSelect from 'react-select'
import { CSSTransition as Transition, TransitionGroup } from 'react-transition-group'

import 'theme/responsive/responsive-styles.sass'
import './Calendar.sass'

ADD_TIME_UNIT = 30
START_HOUR = 8
END_HOUR = 20
DAYS_IN_WEEK = moment.duration(1, 'weeks').asDays()
DAYS_IN_WEEK_MOBILE = moment.duration(3, 'days').asDays()
TIMES_PER_DAY = (20 - 8) * 2 # half hours

DEFAULT_TIMEZONES = [
  "CET"
  "CST6CDT"
  "EET"
  "EST"
  "EST5EDT"
  "GB"
  "GMT"
  "GMT0"
  "HST"
  "MET"
  "MST"
  "MST7MDT"
  "NZ"
  "PRC"
  "PST8PDT"
  "ROC"
  "ROK"
  "UCT"
  "UTC"
  "WET"
]

TIMEZONES = moment.tz.names()

guessedTimezone =
  label: moment.tz.guess()
  value: moment.tz.guess()


export default composeComponent 'Dashboard.ConversationCalendar',
  withState 'loading', 'setLoading', false
  withProps ({suggesting}) ->
    suggesting: suggesting ? true
  withStateHandlers ({suggesting, dates = []}) ->
    firstDate = _.reduce _.min, Infinity, dates.filter (d) -> moment(d).isAfter moment()

    startDate:
      if suggesting
        moment().startOf 'isoWeek'
      else if firstDate
        moment(firstDate).clone().startOf 'isoWeek'
      else moment().startOf 'isoWeek'
    mobileStartDate:
      if suggesting
        moment().startOf 'day'
      else if firstDate
        moment(firstDate).clone().startOf 'day'
      else moment().startOf 'day'
    activeDate:
      if suggesting
        moment().startOf 'day'
      else moment(firstDate).startOf 'day'
    pickedTimezone: null
    timeZones: DEFAULT_TIMEZONES
    lastDir: 'fw'
  ,
    nextWeek: ({startDate, activeDate, lastDir}) -> ->
      startDate: startDate.clone().add(1, 'weeks').startOf 'day'
      activeDate: activeDate.clone().add(1, 'weeks').startOf 'day'
      lastDir: 'fw'
    prevWeek: ({startDate, activeDate}) -> ->
      if moment().startOf('isoWeek').isSame startDate, 'day'
        return
      active = activeDate.clone().subtract 1, 'weeks'
      startDate: startDate.clone().subtract 1, 'weeks'
      activeDate: if active.isBefore(moment()) then moment().startOf 'day' else active.startOf 'day'
      lastDir: 'bw'
    nextWeekMobile: ({mobileStartDate, activeDate}) -> ->
      mobileStartDate: mobileStartDate.clone().add(3, 'days').startOf 'day'
      activeDate: activeDate.clone().add(3, 'days').startOf 'day'
      lastDir: 'fw'
    prevWeekMobile: ({mobileStartDate, activeDate}) -> ->
      if moment().startOf('day').isSame mobileStartDate, 'day'
        return
      active = activeDate.clone().subtract 3, 'days'
      mobileStartDate: mobileStartDate.clone().subtract 3, 'days'
      activeDate: if active.isBefore(moment()) then moment().startOf 'day' else active.startOf 'day'
      lastDir: 'bw'
    setActiveDate: -> (date) ->
      activeDate: moment(date).startOf 'day'
    selectTimezone: -> (pickedTimezone) ->
      {pickedTimezone}
    defaultTimezones: -> ->
      timeZones: DEFAULT_TIMEZONES
    timeZoneSearch: -> (value, {action}) ->
      if action is 'input-change'
        timeZones:
          if value
            TIMEZONES
            .filter (t) ->
              t.toLowerCase().indexOf(value.toLowerCase()) isnt -1
            .slice 0, 20
          else DEFAULT_TIMEZONES

  mapProps _.evolve
    dates: _.map (d) -> moment d
    timeZones: _.map (t) -> label: t, value: t
    user:
      timeZone: (tz) ->
        label: tz
        value: tz

  withHandlers
    selectDate: ({dates, setDates, suggesting, openModal, schedule, withoutAsking}) -> (datetime) ->
      isSelected = dates.find (d) -> d.isSame datetime, 'm'
      unless suggesting
        if isSelected
          schedule? datetime
        else return
      if withoutAsking
        schedule? datetime
        return

      newDates =
        if isSelected
          dates.filter (d) -> not d.isSame datetime, 'm'
        else _.append datetime, dates
      setDates? newDates.map (d) -> d.format()
    isDatetimeActive: ({dates, proposals = []}) -> (datetime, proposed = false) ->
      found = (if proposed then proposals else dates).find (d) -> moment(d).isSame datetime, 'm'
      found?
    timesCountInDate: ({dates}) -> (date) ->
      sameDay = dates.filter (d) -> d.isSame date, 'd'
      sameDay.length

    changeTimezone: ({selectTimezone}) -> (option) ->
      selectTimezone option

  withProps ({startDate, mobileStartDate}) ->
    isPrevDisabled: startDate.clone().subtract(1, 'days').isBefore moment().startOf('isoWeek'), 'd'
    mobilePrevDisabled: mobileStartDate.clone().subtract(1, 'days').isBefore moment().startOf('day'), 'd'

  ({
    user
    dates
    conversation
    startDate
    endDate
    activeDate
    setActiveDate
    selectDate
    isDatetimeActive
    timesCountInDate
    nextWeek
    prevWeek
    nextWeekMobile
    prevWeekMobile
    isPrevDisabled
    mobileStartDate
    mobilePrevDisabled
    suggesting
    changeTimezone
    pickedTimezone
    timeZones
    timeZoneSearch
    defaultTimezones
    lastDir
    proposals
  }) ->
    transitionTimeout = 500
    desktopWeek =
      React.createElement("div", null,
        React.createElement("div", {"className": "Conversation__calendarWeek hidden-xs"},
          React.createElement("button", { \
            "className": "Conversation__calendarBtn",  \
            "onClick": (prevWeek),  \
            "disabled": (isPrevDisabled)
          },
            React.createElement("span", {"className": "far fa-chevron-left"})
          ),
          ([0...DAYS_IN_WEEK].map (i) ->
            date = startDate.clone().add(i, 'days')
            selectedCount = timesCountInDate date
            React.createElement("div", { \
              "className": (cx "Conversation__calendarDay",
                active: activeDate.isSame date
                disabled: date.isBefore moment(), 'd'
              ),  \
              "onClick": (-> setActiveDate date),  \
              "key": (i)
            },
              React.createElement("div", {"className": "Conversation__calendarWeekday"},
                (date.format 'ddd')
              ),
              React.createElement("div", {"className": "Conversation__calendarDate"},
                React.createElement("div", null,
                  (date.format 'MMM DD')
                ),
                (if selectedCount
                  React.createElement("span", {"className": "Conversation__calendarCount"},
                    (selectedCount), " ", React.createElement("span", {"className": "hidden-sm-minus"}, " selection", (if selectedCount > 1 then 's' else ''))
                  )
                )
              )
            )
          ),
          React.createElement("button", {"className": "Conversation__calendarBtn", "onClick": (nextWeek)},
            React.createElement("span", {"className": "far fa-chevron-right"})
          )
        )
      )

    mobileWeek =
      React.createElement("div", null,
        React.createElement("div", {"className": "Conversation__calendarWeek visible-xs-flex hidden-sm-plus"},
          React.createElement("button", { \
            "className": "Conversation__calendarBtn",  \
            "onClick": (prevWeekMobile),  \
            "disabled": (mobilePrevDisabled)
          },
            React.createElement("span", {"className": "far fa-chevron-left"})
          ),
          ([0...DAYS_IN_WEEK_MOBILE].map (i) ->
            date = mobileStartDate.clone().add(i, 'days')
            selectedCount = timesCountInDate date
            React.createElement("div", { \
              "className": (cx "Conversation__calendarDay",
                active: activeDate.isSame date
                disabled: date.isBefore moment(), 'd'
              ),  \
              "onClick": (-> setActiveDate date),  \
              "key": (i)
            },
              React.createElement("div", {"className": "Conversation__calendarWeekday"},
                (date.format 'ddd')
              ),
              React.createElement("div", {"className": "Conversation__calendarDate"},
                React.createElement("div", null,
                  (date.format 'MMM')
                ),
                React.createElement("div", null,
                  (date.format 'DD')
                )
              ),
              (if selectedCount
                React.createElement("span", {"className": "Conversation__calendarCount"},
                  (selectedCount)
                )
              )
            )
          ),
          React.createElement("button", {"className": "Conversation__calendarBtn", "onClick": (nextWeekMobile)},
            React.createElement("span", {"className": "far fa-chevron-right"})
          )
        )
      )

    React.createElement("div", {"className": "Conversation__calendar"},
      React.createElement("div", {"className": "Conversation__calendarWeek_placeholder hidden-xs", "style": ({visibility: 'hidden'})},
        (desktopWeek)
      ),
      React.createElement(TransitionGroup, null,
        React.createElement(Transition, { \
          "key": (startDate.format 'LLL'),  \
          "timeout": ({enter: transitionTimeout, exit: transitionTimeout}),  \
          "className": "Conversation__calendarTransition hidden-xs",  \
          "classNames": (lastDir)
        },
          (desktopWeek)
        )
      ),

      React.createElement("div", {"className": "Conversation__calendarWeek_placeholder visible-xs-flex hidden-sm-plus", "style": ({visibility: 'hidden'})},
        (mobileWeek)
      ),
      React.createElement(TransitionGroup, null,
        React.createElement(Transition, { \
          "key": (mobileStartDate.format 'LLL'),  \
          "timeout": ({enter: transitionTimeout, exit: transitionTimeout}),  \
          "className": "Conversation__calendarTransition visible-xs-flex hidden-sm-plus",  \
          "classNames": (lastDir)
        },
        (mobileWeek)
        )
      ),


      (if activeDate
        zone = pickedTimezone ? user?.timeZone ? guessedTimezone
        React.createElement("div", null,
          React.createElement("div", {"className": "Conversation__calendarTimezones"},
            React.createElement("span", {"className": "Conversation__calendarTimezones_item"},
              (conversation?.me?.member.firstName), " ", (conversation?.me?.member.lastName)
            ),
            React.createElement("span", {"className": "Conversation__calendarTimezones_item"},
              (conversation?.partners?[0]?.member.firstName), " ", (conversation?.partners?[0]?.member.lastName)
            )
          ),
          React.createElement("div", {"className": "Conversation__calendarTimezones"},
            React.createElement("span", {"className": "Conversation__calendarTimezones_item"},
              React.createElement(RSelect, { \
                "className": "Conversation__calendarSelect",  \
                "classNamePrefix": "Conversation__calendarSelect",  \
                "value": (zone),  \
                "onChange": (changeTimezone),  \
                "onInputChange": (timeZoneSearch),  \
                "options": (timeZones),  \
                "onBlur": (defaultTimezones)
              })
            ),
            React.createElement("span", {"className": "Conversation__calendarTimezones_item small"},
                (conversation?.partners?[0]?.member?.timeZone)
            )
          ),
          React.createElement("div", {"className": "Conversation__calendarTimes"},
            ([0...12].map (i) ->
              time = activeDate.clone().add(START_HOUR, 'hours').add(i, 'hours')
              myTime = moment.tz(time, zone?.value ? zone)
              parterTime = moment.tz(time, conversation?.partners?[0]?.member?.timeZone)
              active = isDatetimeActive time
              React.createElement("div", {"key": (i)},
                React.createElement("div", { \
                  "onClick": (-> selectDate time),  \
                  "className": (cx "Conversation__calendarTime",
                    greyed: not suggesting and not active
                    disabled: time.isBefore moment()
                    active: active
                  )
                },
                  (myTime.format 'h.mm a'),
                  (if active
                    React.createElement("span", {"className": "far fa-check"})
                  )
                ),

                React.createElement("div", { \
                  "onClick": (-> selectDate time),  \
                  "className": (cx "Conversation__calendarTime",
                    greyed: not suggesting and not active
                    disabled: time.isBefore moment()
                    active: isDatetimeActive time, true
                  )
                },
                  (parterTime.format 'h.mm a'),
                  (if isDatetimeActive time, true
                    React.createElement("span", {"className": "far fa-check"})
                  )
                )

              )
            )
          )
        )
      )
    )
