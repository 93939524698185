import React, {useEffect, useState, useContext, useMemo, useCallback} from 'react';
import * as _ from 'ramda';
import cx from 'classnames';
import TranslationsContext from 'translations';
import * as emojis   from 'theme/emojis';
import Modal, * as M from 'theme/Modal';
import {IMG_AARON} from 'theme/assets/assets';

const min = 0;
const max = 4;

const RIG = ['relationship', 'impact', 'growth'];

const RIG_PART = {
  intro: 'intro',
  questions: 'questions',
  closed: 'closed'
}

const RIG_QUESTION_MAP = {
  relationship: "How do you feel about your RELATIONSHIPS at work?",
  impact: "How do you feel about the IMPACT you are making at work?",
  growth: "How do you feel about your personal and professional GROWTH at work?",
}

const RIG_OPTIONS = [
  'Frustrated',
  'Unhappy',
  'Meh',
  'Happy',
  'Pumped Up',
]

const RIG_LABELS_MAP = {
  'Frustrated': 'assessment.rig.answer1',
  'Unhappy': 'assessment.rig.answer2',
  'Meh': 'assessment.rig.answer3',
  'Happy': 'assessment.rig.answer4',
  'Pumped Up': 'assessment.rig.answer5',
}


const ModalRIG = (props) => {
  const {opened, save} = props;

  const t = useContext(TranslationsContext);
  const [modalRIGpart, setModalRIGpart] = useState(RIG_PART.intro);
  const [answers, setAnswers] = useState(RIG.reduce((obj, key, i) => ({...obj, [key]: {value: Math.ceil((min + max) / 2), questionId: i + 1}}), {}));
  const [focused, focus] = useState(false);

  const buttonRIGDisabled = useMemo(() =>
    !(answers.relationship && answers.relationship.value + 1 &&
      answers.impact && answers.impact.value + 1 &&
      answers.growth && answers.growth.value + 1)
  , [answers]);

  const setRigAnswers = useCallback((rigPart, option) => {
    const newAnswers = {
      ...answers,
      [rigPart]: {value: option, questionId: (RIG.indexOf(rigPart) + 1)}
    };
    setAnswers(newAnswers);
  }, [answers]);

  const handleKeyDown = useCallback((e, rig) => {
    if(!focused)
      return;

    const v = answers[rig] && answers[rig].value;
    switch (e.key) {
      case 'ArrowLeft':
        if (v - 1 >= min)
          setRigAnswers(rig, v - 1)
        break;
      case 'ArrowRight':
        if (v + 1 <= max)
          setRigAnswers(rig, v + 1)
        break;
      default: break;
    }
  }, [answers, focused]);

  return (
    <Modal
      isOpen={opened}
      className="Questions__modalRIG no-padding"
    >
      <M.Header className="Questions__modalRIG-title title">
        {'Pre-Conversation Check-In'}
      </M.Header>
      <M.Content className="Questions__modalRIG-content">
        {modalRIGpart == RIG_PART.intro &&
          <div className="Questions__modalRIG-first">
            <div className="Questions__modalRIG-copy">
              <img src={IMG_AARON} alt="" />
              <div>
                <span>
                  {'Before we get started, take a moment to check-in and reflect on how you are doing at work this week. It will help you be present and get the most out of your conversation.'}
                </span>
              </div>
            </div>
            <div className="Questions__btns">
              <button onClick={() => setModalRIGpart(RIG_PART.questions)} className="btn btn_primary btn_solid_bluePurple">
                Let{"'"}s Check-In
              </button>
            </div>
          </div>
        }
        {modalRIGpart == RIG_PART.questions &&
          <div className="Questions__modalRIG-second">
            <div>
              {RIG.map((rig, i) =>
                <div className="RIGQuestion__question" key={`question-${rig}`}>
                  <div id={i} className="RIGQuestion__title">{RIG_QUESTION_MAP[rig]}</div>
                  <ul
                    className="RIGQuestion__answers"
                    tabIndex="0"
                    onFocus={() => focus(true)}
                    onBlur={() => focus(false)}
                    onKeyDown={(e) => handleKeyDown(e, rig)}
                    aria-describedby={i}
                  >
                    {RIG_OPTIONS.map((o, o_id) => {
                      const emoji_alt = o;
                      const emoji_src_gray = emojis[o.replace(' ', '') + '_gray'];
                      const emoji_src = emojis[o.replace(' ', '')];
                      return (
                        <li
                          className={cx("RIGQuestion__answer", {"active": answers[rig].value == o_id})}
                          onClick={() => setRigAnswers(rig, o_id)}
                          key={`option-${o}-${rig}`}
                        >
                          <img src={emoji_src_gray} alt="" aria-hidden />
                          <img src={emoji_src} alt="" className="rig-img-color" aria-hidden />
                          <span>{t.find(RIG_LABELS_MAP[o])}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </div>
            <div className="Questions__btns">
              <button
                onClick={() => save(answers)}
                className="btn btn_primary btn_solid_bluePurple"
                disabled={buttonRIGDisabled}
              >
                I{"'"}m Ready to Start
              </button>
            </div>
          </div>
        }
      </M.Content>
    </Modal>
  )
}

export default ModalRIG;
