import { makeAutoObservable, observable } from 'mobx';
import React, { createContext, useContext } from 'react'
import UserStore from 'stores/User';

class Store {
  @observable userStore = null;

  constructor() {
    this.userStore = new UserStore(this);
    makeAutoObservable(this);
  }
}

const store = new Store();

const StoreContext = createContext(store);

const StoreProvider = ({children}) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  )
}

const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }
  return context;
}

export default StoreContext;

export {
  Store,
  store,
  StoreContext,
  useStore,
  StoreProvider
}