import r from 'r-dom'
import T from 'tcomb'
import cx from 'classnames'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import {withProps, defaultProps} from 'recompose'

import './Progress.sass'


export default composeComponent 'Assessment.Progress',
  setPropTypes
    value: T.Number
    className: T.maybe T.String
    progressClassName: T.maybe T.String
    visible: T.Boolean
    exit: T.Function

  defaultProps
    visible: true

  withProps ({value}) ->
    progress: Math.min value, 100

  ({progress, visible, exit, t}) ->
    r.div className: "Progress__progress",
      r.div className: "container-fluid Progress__progressContent", [
        r.div
          className: "Progress__logoContainer"
          r.div className: "Progress__logo"

        if visible
          r.div className: "Progress__container", [
            r.span {}, t.find 'assessment.general.progress.start'
            r.div className: "Progress__progressContainer",
              r.div
                className: "Progress__progressValue"
                style: width: "#{progress}%"
            r.span {}, t.find 'assessment.general.progress.finish'
          ]
        else r.div className: "Progress__container"

        r.div className: "Progress__exit",
          r.button onClick: exit, id: 'Assessment__exit', [
            t.find 'assessment.general.exit.button.title'
            r.span className: 'fal fa-times-circle'
          ]
      ]
