import React from 'react'
import * as _ from 'ramda'
import cx from 'classnames'
import {withRouter} from 'react-router'
import moment from 'moment'
import {composeComponent, addStatesWithHandlers} from 'utils/react-tools'
import * as ME from 'api/metrics'
import {lifecycle, withProps} from 'recompose'
import {fetchUser} from 'auth/requests'
import Loader from 'theme/Loader'
import {URL_ARCHETYPES, URL_DRIVERS, IMG_LOGO_BLUE} from 'theme/assets/assets'
import Page from 'reports/PrintPage'
import convertData from 'reports/convertData'
import DownloadButton from 'reports/DownloadReportButton'
import {fetchFeedbacks, fetchPowerUps} from 'reports/requests'
import Avatar from './Avatar'

import './SelfAssessmentReport.sass'


SCORES = [
  'Novice'
  'Advanced Beginner'
  'Competent'
  'Proficient'
  'Expert'
  'Mastery'
]

DIMENSIONS = ['who', 'why', 'how']

getArticleFor = (text) ->
  vowels = ['a', 'e', 'i', 'o', 'u', 'y']
  firstLetter = text?.slice(0, 1).toLowerCase()
  if vowels.indexOf(firstLetter) == -1 then 'a' else 'an'

export default composeComponent 'SelfAssessmentReport',
  withRouter
  addStatesWithHandlers
    loading: false
    user: null
    powerUps: {}
    feedback: []
  lifecycle
    componentDidMount: ->
      {match: {params}, setUser, t, setLoading, setPowerUps, setFeedback} = @props

      setLoading(true);
      ME.reportStateChange('Self Assessment Report: loading');

      Promise.all([
        fetchUser(),
        fetchFeedbacks(),
        fetchPowerUps(),
      ]).then(
        ([user, feedback, powerUps]) ->
          setUser(user);
          setFeedback(feedback);
          setPowerUps(powerUps);
          ME.reportStateChange('Self Assessment Report: loaded');
          setLoading(false);
        , (error) ->
          ME.reportIntermediateError('Self Assessment Report: loading error', error);
          setLoading(false);
      )
  withProps ({powerUps, feedback}) ->
    report: Object.keys(powerUps).reduce(
      (result, dimension) ->
        Object.assign {}, result, "#{dimension}": powerUps[dimension]?.map (powerUp) ->
          foundMembers = feedback.filter ({selectedPowerUps}) -> powerUp.id in selectedPowerUps
          Object.assign {}, powerUp, members: foundMembers
      {}
    )

  ({report, user: u, loading, t}) ->
    unless u
      return null

    {who, why, how} = report
    user = convertData(t).User(u)

    dominant = user?.pattern?.dominant?.toLowerCase()

    number = 1 # page numbering start

    org = user?.organization

    name = "#{user?.firstName} #{user?.lastName}"
    date = moment().format 'MMMM Do, YYYY'
    coverDate = moment().format 'MMMM D, YYYY'

    footer = (number, visible) ->
      if visible
        React.createElement("span", {"className": (cx \
        "SelfAssessmentReport__footer"
        , right: number %% 2
        )},
          (unless number %% 2
            React.createElement("span", {"className": "SelfAssessmentReport__footerNumber"}, (number))
          ), """
          Development Plan for """, (name), " • ", (date), """ • Ⓒ Imperative
""", (if number %% 2
            React.createElement("span", {"className": "SelfAssessmentReport__footerNumber"}, (number))
          )
        )

    React.createElement("section", {"className": "SelfAssessmentReport"},
      React.createElement(Loader, {"loading": (loading), "color": "bg"}),
      React.createElement(DownloadButton, {"name": "#{user?.firstName}#{user?.lastName}DevelopmentPlan"}),

      React.createElement("div", {"id": "ReportContent"},
        React.createElement(Page, { \
          "background": (React.createElement("div", {"className": "SelfAssessmentReport__coverBg"})),  \
          "className": "SelfAssessmentReport__cover"
        },
          React.createElement("div", { \
            "className": "SelfAssessmentReport__archetypeTitle",  \
            "style": (backgroundImage: "url(#{URL_ARCHETYPES}/#{user?.archetype.title}.png)")
          }),
          React.createElement("div", {"className": "SelfAssessmentReport__page SelfAssessmentReport__page_bottom"},
            React.createElement("h1", {"className": "SelfAssessmentReport__coverTitle"},
              React.createElement("span", null, (user?.archetype.title), " Development Plan")
            ),
            React.createElement("h2", {"className": "SelfAssessmentReport__coverName"}, (name)),
            React.createElement("h3", {"className": "SelfAssessmentReport__coverText"}, """
              Master your purpose to realize your potential.""", React.createElement("br", null), """
              Prepared on """, (coverDate)
            ),
            React.createElement("img", {"src": (IMG_LOGO_BLUE), "className": "SelfAssessmentReport__coverLogo", "alt": "Imperative"})
          )
        ),

        React.createElement(Page, {"footer": (footer number+=1, true), "className": "SelfAssessmentReport__page_pink"},
          React.createElement("section", {"className": "SelfAssessmentReport__plan"},
            React.createElement("div", {"className": "SelfAssessmentReport__row"},
              React.createElement("h2", {"className": "SelfAssessmentReport__title"}, """
                Development Plan
"""),
              React.createElement("p", {"className": "SelfAssessmentReport__text SelfAssessmentReport__text_noMargin"}, """
                Identify where you want to lead and grow based on your self-assessed talents as """, (getArticleFor user?.archetype.title), " ", (user?.archetype.title), """ outlined on the following pages.
                We recommend reviewing it with your manager, coach, mentor, and \x2F or sidekick.
""")
            ),
            React.createElement("div", {"className": "SelfAssessmentReport__row margin-top-25"},
              React.createElement("h2", {"className": "SelfAssessmentReport__subtitle"}, """
                Areas to lead
"""),
              React.createElement("p", {"className": "SelfAssessmentReport__text margin-top-5"}, """
                What are the three talents where you can lead in your work today and mentor others?
                Who might need more support from you in these areas?
"""),
              React.createElement("div", {"className": "SelfAssessmentReport__text margin-top-5 SelfAssessmentReport__text_borderedPink"},
                React.createElement("ol", null,
                  React.createElement("li", null),
                  React.createElement("li", null),
                  React.createElement("li", null)
                )
              )
            ),
            React.createElement("div", {"className": "SelfAssessmentReport__row margin-top-25"},
              React.createElement("h2", {"className": "SelfAssessmentReport__subtitle"}, """
                Areas to develop
"""),
              React.createElement("p", {"className": "SelfAssessmentReport__text margin-top-5 "}, """
                What are the three talents that you would like to hone this year?
                Who might be able to mentor you in these areas?
"""),
              React.createElement("div", {"className": "SelfAssessmentReport__text SelfAssessmentReport__text_noMargin  SelfAssessmentReport__text_borderedPink"},
                React.createElement("ol", null,
                  React.createElement("li", null),
                  React.createElement("li", null),
                  React.createElement("li", null)
                )
              )
            )
          )
        ),

        React.createElement(Page, {"footer": (footer number+=1, true)},
          React.createElement("h2", {"className": "SelfAssessmentReport__title"}, """
            Your Current """, (user?.archetype.title), """ Mastery
"""),
          React.createElement("div", {"className": "SelfAssessmentReport__driverContainer"},
            ([dominant].map (driver, i) ->
              React.createElement("div", {"className": "SelfAssessmentReport__driver SelfAssessmentReport__driver_#{driver}", "key": (i)},
                React.createElement("div", {"className": "SelfAssessmentReport__row SelfAssessmentReport__driverHeading"},
                  React.createElement("img", { \
                    "className": "SelfAssessmentReport__column SelfAssessmentReport__driverIcon SelfAssessmentReport__driverIcon_medium",  \
                    "src": "#{URL_DRIVERS}/#{driver}#{user?.pattern[driver]}.png",  \
                    "alt": (user?.pattern[driver])
                  }),
                  React.createElement("h2", {"className": "SelfAssessmentReport__column SelfAssessmentReport__subtitle #{driver}"},
                    (user?.pattern?[driver]),
                    React.createElement("p", null, (user?.drivers?[driver]?.description))
                  )
                ),
                (report?[driver]?.map (powerUp) ->
                  [visibleMembers, restMembers] = _.splitAt 5, powerUp.members
                  React.createElement("div", {"className": "SelfAssessmentReport__row SelfAssessmentReport__powerUp", "key": (powerUp.id)},
                    React.createElement("div", {"className": "SelfAssessmentReport__column"},
                      React.createElement("p", {"className": "SelfAssessmentReport__text"},
                        (powerUp?.content)
                      ),
                      (visibleMembers.map (member) ->
                        React.createElement(Avatar, {"user": (member), "key": (member.slug)})
                      ),
                      (if restMembers?.length > 0
                        React.createElement("span", {"className": "SelfAssessmentReport__powerUp_more"}, """
                          and """, (restMembers?.length), """ member verified your skill
""")
                      )
                    ),
                    React.createElement("span", {"className": "SelfAssessmentReport__powerUp_score #{driver}"},
                      (SCORES[powerUp?.score])
                    )
                  )
                )
              )
            )
          )
        ),

        React.createElement(Page, {"footer": (footer number+=1, true)},
          React.createElement("div", {"className": "SelfAssessmentReport__driverContainer"},
            (DIMENSIONS.filter((d) -> d isnt dominant).map (driver) ->
              React.createElement("div", {"className": "SelfAssessmentReport__driver SelfAssessmentReport__driver_#{driver}", "key": (driver)},
                React.createElement("div", {"className": "SelfAssessmentReport__row"},
                  React.createElement("img", { \
                    "className": "SelfAssessmentReport__column SelfAssessmentReport__driverIcon SelfAssessmentReport__driverIcon_medium",  \
                    "src": "#{URL_DRIVERS}/#{driver}#{user?.pattern[driver]}.png",  \
                    "alt": (user?.pattern[driver])
                  }),
                  React.createElement("h2", {"className": "SelfAssessmentReport__column SelfAssessmentReport__subtitle #{driver}"},
                    (user?.pattern?[driver]),
                    React.createElement("p", null, (user?.drivers?[driver]?.description))
                  )
                ),
                (report?[driver]?.map (powerUp) ->
                  React.createElement("div", {"className": "SelfAssessmentReport__row SelfAssessmentReport__powerUp", "key": (powerUp.id)},
                    React.createElement("p", {"className": "SelfAssessmentReport__text SelfAssessmentReport__column"},
                      (powerUp?.content)
                    ),
                    React.createElement("span", {"className": "SelfAssessmentReport__powerUp_score #{driver} SelfAssessmentReport__column"},
                      (SCORES[powerUp?.score])
                    )
                  )
                )
              )
            )
          )
        )
      )

    )
