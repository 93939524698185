const STATIC_URL = 'https://assets.imperative.com/static.imperative.com/images';

const IMG_AARON = `${STATIC_URL}/aaron.png`;
const IMG_AARON2 = `${STATIC_URL}/aaron2.png`;
const IMG_CATEGORY = `${STATIC_URL}/category.jpg`;
const IMG_DESK = `${STATIC_URL}/illustatrion_desk.png`;
const IMG_DNA_GRAY = `${STATIC_URL}/dna_gray.svg`;
const IMG_DNA_WHITE = `${STATIC_URL}/dna_white.svg`;
const IMG_HAPPY_GHOST = `${STATIC_URL}/happy_ghost.svg`;
const IMG_QUOTE = `${STATIC_URL}/quote_icon.svg`;
const IMG_TEAM_SAMPLE = `${STATIC_URL}/team_sample.jpg`;
const IMG_TIP = `${STATIC_URL}/Tip.svg`;

const IMG_LOGO_BLUE = `${STATIC_URL}/logos/logo_blue.png`;
const IMG_LOGO_BLUE_MOBILE = `${STATIC_URL}/logos/logo_blueMobile.png`;
const IMG_LOGO_B_CORP = `${STATIC_URL}/logos/logo__bCorp.png`;

const URL_ANIMALS = `${STATIC_URL}/animals`;
const URL_ARCHETYPES = `${STATIC_URL}/archetypes-titles`;
const URL_DOMINANT = `${STATIC_URL}/dominant`;
const URL_DRIVERS = `${STATIC_URL}/drivers`;
const URL_EMOJIS = `${STATIC_URL}/emojis`;
const URL_FRAMEWORKS = `${STATIC_URL}/frameworks`;
const URL_HEROES = `${STATIC_URL}/heroes`;
const URL_JOB_HACKS = `${STATIC_URL}/job-hacks`;
const URL_PEER_CONVS = `${STATIC_URL}/peer-conversations`;
const URL_PURPOSE_PROFILE_DRIVERS = `${STATIC_URL}/purpose_profile_drivers`;


export {
  IMG_AARON,
  IMG_AARON2,
  IMG_CATEGORY,
  IMG_DESK,
  IMG_DNA_GRAY,
  IMG_DNA_WHITE,
  IMG_HAPPY_GHOST,
  IMG_LOGO_B_CORP,
  IMG_LOGO_BLUE_MOBILE,
  IMG_LOGO_BLUE,
  IMG_QUOTE,
  IMG_TEAM_SAMPLE,
  IMG_TIP,
  URL_ANIMALS,
  URL_ARCHETYPES,
  URL_DOMINANT,
  URL_DRIVERS,
  URL_EMOJIS,
  URL_FRAMEWORKS,
  URL_HEROES,
  URL_JOB_HACKS,
  URL_PEER_CONVS,
  URL_PURPOSE_PROFILE_DRIVERS,
}
