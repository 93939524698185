import api from 'api';

export const getJobFunctions = () => {
  return api.get("/dictionaries/JobFunction");
}
export const getIndustries = () => {
  return api.get("/dictionaries/Industry");
}
export const updateUser = (dto) => {
  return api.patch("/members/me", dto);
}
export const uploadAvatar = (fd) => {
  return api.post("/members/me/avatar", fd);
}
export const deleteMember = (slug) => {
  return api.delete(`/members/${slug}`);
}
export const getConsents = (slug) => {
  return api.get(`/members/${slug}/consents`);
}
export const updateConsents = (slug, data) => {
  return api.patch(`/members/${slug}/consents`, data)
}
export const getAssessmentData = () => {
  return api.get(`${api.surveys}/purpose-assessment`);
}
export const sendAnswer = (dto) => {
  return api.post(`${api.surveys}/purpose-assessment/answer`, dto);
}
export const updateDemographicData = (answers = []) => {
  return api.post("/me/demographic-questions", {answers});
}
export const assessmentQuestionNames = {
  107: "tenure",
  108: "gender",
  109: "race",
  110: "jobFunction",
  111: "industry",
  112: "role",
}
