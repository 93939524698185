import moment from 'moment-timezone';
import { mapObjIndexed } from 'ramda';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { loadTimezones } from 'utils/react-tools';
import TranslationsContext from 'translations';
import { getAllowedTimezones } from '../../utils/timezones';
import Scheduler, { suggestedDate } from './Scheduler';

const TIMEZONES = getAllowedTimezones(loadTimezones());
const mappedTimezones = TIMEZONES.map((o) => ({
  ...o,
  label: o.description,
  value: o.name,
  offset: o.currentTimeOffsetInMinutes / 60,
  currentTimeOffsetInMinutes: o.currentTimeOffsetInMinutes,
}));

const ScheduleNextConversations = (props) => {
  const {
    conversations = [],
    user,
    initDates,
    nextConversationsDates = {},
    onChange,
    cancelConversation,
    partner,
  } = props;
  const t = useContext(TranslationsContext);
  const [openedSchedulers, openScheduler] = useState({});
  const [openedTimezone, openTimezone] = useState(false);
  const checkedConversations = useMemo(
    () =>
      Object.keys(nextConversationsDates).filter(
        (id) => nextConversationsDates[id].scheduleDate && nextConversationsDates[id].scheduleTimezone
      ),
    [nextConversationsDates]
  );

  const timezone = useMemo(() => {
    const firstConv = conversations[0];
    return (
      (nextConversationsDates[firstConv.id] && nextConversationsDates[firstConv.id].scheduleTimezone) ||
      (conversations[0] && conversations[0].scheduleDateTimeZone) ||
      moment.tz.guess()
    );
  }, [nextConversationsDates]);

  const changeDate = useCallback(
    (id, date, timezone) => {
      const formatted = moment.tz(date, timezone).format('YYYY-MM-DD HH:mm');
      const mapped = moment.tz(formatted, timezone).valueOf();
      const convI = conversations.findIndex((c) => c.id == id);
      const convsToChange = conversations
        .reduce((dates, c, i) => {
          if (i < convI)
            return dates.concat(
              nextConversationsDates[c.id] ? nextConversationsDates[c.id].scheduleDate : suggestedDates[c.id]
            );
          if (i == convI) return dates.concat(mapped);

          const d = nextConversationsDates[c.id] ? nextConversationsDates[c.id].scheduleDate : suggestedDates[c.id];
          const prev = dates[dates.length - 1];
          if (moment(d).isAfter(moment(prev))) return dates.concat(d);

          const newDate = suggestedDate(prev, c.convIInSet == 0, !prev);
          return dates.concat(moment.tz(newDate, timezone).valueOf());
        }, [])
        .reduce((res, date, i) => {
          if (isConversationChecked(conversations[i]) || i == convI) {
            return {
              ...res,
              [conversations[i].id]: {
                scheduleDate: date,
                scheduleTimezone: timezone,
              },
            };
          }
          return res;
        }, {});

      const schedulers = mapObjIndexed((opened, convId) => {
        if (
          convId != id &&
          opened &&
          nextConversationsDates[convId] &&
          convsToChange[convId] &&
          nextConversationsDates[convId].scheduleDate != convsToChange[convId].scheduleDate
        ) {
          return opened + 1;
        }
        return opened;
      }, openedSchedulers);
      openScheduler(schedulers);

      initDates(convsToChange);
    },
    [nextConversationsDates, conversations, openedSchedulers]
  );

  const changeTimezone = useCallback(
    (t) => {
      const convsToChange = conversations.reduce((res, c) => {
        if (!isConversationChecked(c)) return res;
        const date = nextConversationsDates[c.id] && nextConversationsDates[c.id].scheduleDate;
        const formatted = moment.tz(date, timezone).format('YYYY-MM-DD HH:mm');
        const a = {
          scheduleDate: moment.tz(formatted, t).valueOf(),
          scheduleTimezone: t,
        };
        return { ...res, [c.id]: a };
      }, {});
      initDates(convsToChange);
    },
    [nextConversationsDates, checkedConversations, conversations, timezone]
  );

  const suggestedDates = useMemo(() => {
    return conversations
      .reduce((suggested, conv) => {
        const prev = suggested[suggested.length - 1];
        return suggested.concat(moment.tz(suggestedDate(prev, conv.convIInSet == 0, !prev), timezone).valueOf());
      }, [])
      .reduce((res, date, i) => {
        return { ...res, [conversations[i].id]: date };
      }, {});
  }, [conversations, timezone]);

  useEffect(() => {
    if (!checkedConversations.length) {
      const initData = conversations.reduce((res, c) => {
        const date = c.scheduleDate || suggestedDates[c.id];
        return {
          ...res,
          [c.id]: {
            scheduleDate: moment(date).valueOf(),
            scheduleTimezone: timezone,
          },
        };
      }, {});
      initDates(initData);
    }
  }, [suggestedDates, timezone, checkedConversations]);

  const checkConversation = useCallback(
    (i) => {
      const previousConversation = conversations[i - 1];
      const previousConversationDate =
        previousConversation &&
        nextConversationsDates[previousConversation.id] &&
        nextConversationsDates[previousConversation.id].scheduleDate;
      const previousConversationChecked = i == 0 || isConversationChecked(previousConversation);
      const convChecked = isConversationChecked(conversations[i]);
      if (!convChecked && previousConversationChecked) {
        changeDate(
          conversations[i].id,
          suggestedDate(previousConversationDate, conversations[i].convIInSet == 0, !previousConversation),
          timezone
        );
      } else if (convChecked) {
        const convsToUncheck = conversations.slice(i).map((c) => c.id);
        convsToUncheck.map((id) => onChange(id, null, null));
        openScheduler((s) => ({
          ...s,
          ...convsToUncheck.reduce((obj, id) => ({ ...obj, [id]: false }), {}),
        }));
      }
    },
    [conversations, checkedConversations, timezone, nextConversationsDates]
  );

  const isConversationChecked = useCallback(
    (conversation) => {
      return conversation && checkedConversations.indexOf(conversation.id) > -1;
    },
    [checkedConversations]
  );

  const timezoneObj = useMemo(() => {
    const userTimezone = timezone || moment.tz.guess();
    const selectedTimezone =
      mappedTimezones.find((t) => t.value === userTimezone) ||
      mappedTimezones.find((t) => t.currentTimeOffsetInMinutes == moment().tz(userTimezone).utcOffset());

    return selectedTimezone;
  }, [timezone]);

  return (
    <div className="Questions__scheduleNext">
      {conversations.length == 1 && (
        <Scheduler
          user={user}
          scheduleDate={
            (nextConversationsDates[conversations[0].id] && nextConversationsDates[conversations[0].id].scheduleDate) ||
            conversations[0].scheduleDate
          }
          scheduleTimezone={timezone}
          onChange={(date, t) => onChange(conversations[0].id, date, t)}
        />
      )}
      {conversations.length > 1 && (
        <>
          <div className="Questions__scheduleNextHeader">
            <h2 className="section-head">Upcoming conversations</h2>
            <p className="p-new">
              Select the conversations you and {partner.firstName} wish to schedule today. You can change the suggested
              date and time by clicking the pencil icon.
            </p>
          </div>
          <div className="Questions__scheduleNextTitle timezone">
            <h2 id="my-timezone" className="p-new bold">
              Scheduled timezone
            </h2>
            {openedTimezone ? (
              <Scheduler
                aria-labelledby="my-timezone"
                user={user}
                scheduleTimezone={timezone}
                onChange={(_, t) => changeTimezone(t)}
                showDatepicker={false}
              />
            ) : (
              <div className="Questions__scheduleNextDate p-new">
                {(timezoneObj && timezoneObj.label) || timezone}
                <button className="btn-icon" onClick={() => openTimezone(true)}>
                  <i className="material-icons-outlined" aria-hidden>
                    edit
                  </i>
                  <span className="p-new">Change</span>
                </button>
              </div>
            )}
          </div>
          {conversations.map((c, i) => {
            const suggested = c.scheduleDate || suggestedDates[c.id];
            const date = nextConversationsDates[c.id] && nextConversationsDates[c.id].scheduleDate;
            const previousConversationChecked = i == 0 || isConversationChecked(conversations[i - 1]);
            const checked = isConversationChecked(c);
            const previousConv = conversations[i - 1];
            const previousDate =
              previousConv &&
              ((nextConversationsDates[previousConv.id] && nextConversationsDates[previousConv.id].scheduleDate) ||
                suggestedDates[previousConv.id]);
            const minDate = previousDate && moment(previousDate).add(45, 'm').valueOf();

            return (
              <div className={cx('Questions__scheduleNextConv', { checked })} key={c.id}>
                <div className="Questions__scheduleNextTitle">
                  <button
                    className={cx('btn-icon check', { readonly: i == 0 || !previousConversationChecked, checked })}
                    onClick={() => i > 0 && checkConversation(i)}
                  >
                    {checked && <i className="ci-check" />}
                  </button>
                  <h2 className="p-new bold">Conversation {c.orderIndex}</h2>
                  {suggested && checked && !openedSchedulers[c.id] && (
                    <div className="Questions__scheduleNextDate p-new">
                      <span className="p-new bold">
                        {moment.tz(date || suggested, timezone).format('MMMM D')},{' '}
                        {moment.tz(date || suggested, timezone).format('hh:mm A')} (
                        {moment.tz(date || suggested, timezone).zoneAbbr()})
                      </span>
                      <button
                        className="btn-icon"
                        onClick={() => !openedSchedulers[c.id] && openScheduler((s) => ({ ...s, [c.id]: 1 }))}
                      >
                        <i className="material-icons-outlined">edit</i>
                        <span className="p-new">Change</span>
                      </button>
                    </div>
                  )}
                  {openedSchedulers[c.id] && (
                    <div className="relative Questions__scheduleNextScheduler">
                      <Scheduler
                        key={openedSchedulers[c.id]}
                        user={user}
                        scheduleDate={date || suggested}
                        scheduleTimezone={timezone}
                        showTimezonePicker={false}
                        onChange={(date) => changeDate(c.id, date, timezone)}
                        minDate={minDate}
                      />
                      {cancelConversation && c.scheduleDate && (
                        <button
                          className="btnLink btnLink_secondary btnLink_bluePurple"
                          onClick={() => cancelConversation(c)}
                        >
                          Cancel Conversation
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ScheduleNextConversations;
