import React from 'react'
import {composeComponent} from 'utils/react-tools'
import Dropdown from 'react-select'


export default composeComponent 'Assessment.RoleDropdown',
  ({options, value, onChange, t, id, placeholder}) ->
    React.createElement(Dropdown, { \
      "id": (id),  \
      "value": (options?.find((o) => o.value == value)),  \
      "className": "Assessment__dropdown",  \
      "classNamePrefix": "Assessment__dropdown",  \
      "options": (options),  \
      "onChange": ((o) => onChange o.value),  \
      "placeholder": (t.find placeholder),  \
      "getOptionLabel": ((o) => t.find o.label)
    })
