import React, {useState} from 'react'
import * as _ from 'ramda'
import {reduxForm, Field} from 'redux-form'
import validate, * as V from 'validate'
import {requestPasswordReset} from 'auth/requests'
import Layout, {Result} from 'auth/Layout'
import {TextField, BtnContainer} from 'auth/Form'

const Form = ({handleSubmit, onSubmit, disabled, submitting, invalid, submitFailed}) => {
  const [errorState, setErrorState] = useState(null);
  const submit = (onSubmit, values) => {
    setErrorState(null);
    onSubmit(values)
    .then(
      _.identity,
      ({data: {message}}) => {
        setErrorState(message);
      }
    );
  }

  return (
    <form onSubmit={handleSubmit(values => submit(onSubmit, values))}>
      <Field
        name="email"
        placeholder="Email address"
        component={TextField}
        className="margin-bottom-2"
      />

      {errorState &&
        <div className="Signup__error">
          {errorState}
        </div>
      }

      <BtnContainer>
        <button
          className="btn btn_primary btn_solid_pink margin-top-3"
          disabled={disabled || submitting || invalid && submitFailed}
        >
          Reset your password
        </button>
      </BtnContainer>
    </form>
  )
};

const ResetForm = reduxForm({
  form: 'reset',
  validate: validate({
    email: [
      V.required('Enter your email please'),
      V.email('Enter a valid email please')
    ]
  })
})(Form);

const ResetRequest = () => {
  const [success, setSuccess] = useState(false);

  const submit = (data) => {
    return requestPasswordReset(data)
      .then(() => {
        setSuccess(true);
      })
  };

  return (
    <Layout headline="Request a password reset">
      {success &&
        <Result>
          You have requested a password reset.
          <br />
          Check your email for further instructions.
        </Result>
      }
      {!success &&
        <ResetForm onSubmit={submit} />
      }
    </Layout>
  );
};

export default ResetRequest;
