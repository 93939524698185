import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

rootReducer = combineReducers(
  form: formReducer
)

store = createStore rootReducer

export default store
