import React from 'react'
import PT from 'prop-types'
import * as _ from 'ramda'
import cx from 'classnames'
import {URL_DRIVERS} from 'theme/assets/assets'

DIMENSIONS =
  who: 'impact'
  why: 'values'
  how: 'craft'

export default ({driver, dimension}) ->

  dominant = driver.dominant

  React.createElement("div", {"className": "LongReport__driverPage"},
    (if dominant
      React.createElement("h2", {"className": "LongReport__driverPageDominant first"}, """
        DOMINANT
""")
    ),
    React.createElement("div", {"className": (cx \
      "LongReport__driverPageHeader LongReport__driverPageHeader_bordered"
      , not dominant and dimension
    )},
      React.createElement("img", { \
        "src": "#{URL_DRIVERS}/#{dimension}#{driver.title}.png",  \
        "className": "LongReport__driverPageHeaderIcon",  \
        "alt": (driver.title)
      }),
      React.createElement("div", {"className": "LongReport__driverPageHeaderContent"},
        React.createElement("h1", {"className": "LongReport__driverPageTitle"}, """
          Your """, React.createElement("span", {"className": "LongReport__driverPageTitle"}, (_.concat _.head(DIMENSIONS[dimension].toUpperCase()), _.tail DIMENSIONS[dimension]), " "), """
          Driver - """, (driver.title)
        ),
        React.createElement("span", {"className": "LongReport__driverPageQuote"}, """
          “""", (driver.quote.value), "” —", (driver.quote.author)
        )
      )
    ),

    React.createElement("div", {"className": (cx \
      "LongReport__driverPageContent"
      , not dominant and dimension
    )},
      (if dominant
        React.createElement("p", {"className": "LongReport__text LongReport__text_bold LongReport__text_firstBig"}, """
          This driver is your most defining motivator. Actively engaging this driver keeps
          you in top form and full of motivation. This driver can be the source of your
          greatest potential biases and your deepest frustrations, or it can be the source
          of your biggest breakthroughs at work. What makes the difference? Awareness
          and active engagement!
""")
      ),
      React.createElement("p", {"className": "LongReport__text LongReport__text_firstBig"},
        (driver.explication)
      ),

      React.createElement("p", {"className": "LongReport__text"},
        React.createElement("span", {"className": (cx \
          "LongReport__subtitle LongReport__subtitle_smaller LongReport__subtitle_black"
          , not dominant and dimension
        )}, """
          You Find Purpose When You
"""),
        (driver.moments.map (moment, j) ->
          React.createElement("span", {"className": "LongReport__listItem LongReport__listItem_bullet", "key": (j)},
            React.createElement("span", {"className": "LongReport__listItemContent"},
              (moment)
            )
          )
        )
      ),

      React.createElement("p", {"className": (cx "LongReport__driverPageKeywords", not dominant and dimension)},
        (driver.keywords.map (keyword, j) ->
          React.createElement("span", {"className": "LongReport__driverPageKeyword", "key": (j)},
            (keyword)
          )
        )
      )
    )
  )
