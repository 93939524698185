`/** @jsx R.DOM */`
import R from 'react'
import PT from 'prop-types'
import {URL_DRIVERS} from 'theme/assets/assets'
import P from './P'
import Ul from './Ul'
import Li from './Li'


DRIVER_DATA =
  who:
    Individual:
      description:
        'We feel most fulfilled when we see our work impact individuals and groups of individuals.
        We believe that our greatest contribution is helping people.'
      fulfillments: [
        'We clearly describe how our collective efforts as a team will impact individuals. This will define what success looks like in a way that is relatable for us.'
        'We orient our team goals and incentives toward making an impact on individuals.'
        'We make space as a team to regularly connect our work to the impact on individuals.'
        'Our celebrations describe/cite the positive impact that our work has on individuals.'
      ]
      considerations: [
        'Further deepen our impact on individuals.'
        'Reach an even larger group of individuals.'
        'Aim for individuals with more complex needs.'
        'Aim to make the impact longer lasting and more sustainable.'
      ]
      goals:
        'When we formulate team goals that make an even bigger impact on individuals, we amplify our team purpose and will generate even greater levels of fulfillment.'
      groupThink:
        'We can avoid groupthink by seeking perspectives from those with Organization or Society drivers.'

    Organization:
      description:
        'We feel most fulfilled when we see our work impact teams and organizations. We believe our greatest
        contribution is helping teams and organizations to achieve results that impact the lives of many.'
      fulfillments: [
        'We clearly describe how our collective efforts as a team will impact organizations and teams. This will define what success looks like in a way that is relatable for us.'
        'We orient our team goals and incentives toward making an impact on organizations (including our organization)'
        'We make space as a team to regularly connect our work to the impact on organizations and teams'
        'Our celebrations describe/cite the positive impact that our work has on organizations and teams.'
      ]
      considerations: [
        'Further deepen our impact on teams and organizations'
        'Reach even more teams or organizations'
        'Aim to impact teams or organizations with more complex needs'
        'Aim to make an impact that is longer lasting and more sustainable.'
      ]
      goals:
        'When we formulate team goals that make an even bigger impact on organizations and teams, we amplify our team purpose and will generate even greater levels of fulfillment.'
      groupThink:
        'We can avoid groupthink by seeking perspectives from those with Individual or Society drivers.'

    Society:
      description:
        'We feel most fulfilled when we see our work have a broader impact on society.
        We believe our greatest contribution is when we create innovations that can
        benefit society at large or we adopt trends and innovations that can keep us societally relevant.'
      fulfillments: [
        'We clearly describe how our collective efforts as a team impact society, or we clarify which parts of society we would like to impact. This will define what success looks like in a way that is relatable for us.'
        'We orient our team goals and incentives toward our impact on society and broader trends.'
        'We make space as a team to regularly connect our work to the impact on society.'
        'Our celebrations describe and cite the positive impact that our work has on society.'
      ]
      considerations: [
        'Further deepen our impact on society'
        'Help our organization become more relevant to society (e.g., bring outside innovations and societal trends into the organization, or help the organization more deeply adopt external innovations)'
        'Share our innovations and best practices with the parts of society that can benefit from them'
        'Aim to impact a part of society with more complex challenges or critical issues'
        'Aim to make our impact longer lasting and more sustainable.'
      ]
      goals:
        'When we formulate team goals to make a bigger impact on society, we amplify our team purpose and will generate even greater levels of fulfillment.'
      groupThink:
        'We can avoid groupthink by seeking perspectives from those with Individual or Organization drivers.'

  why:
    Karma:
      description:
        'We believe success is a product of the constant push for excellence and the challenge
        to everyone to be their best. We work to constantly deliver remarkable results
        and to raise the bar for the organization and the market.'
      alignments: [
        'Create KPIs and incentives that will encourage the hard work needed to get good results'
        'Make sure our goals inspire the team to up their game'
        'Identify team norms that challenge and inspire us to do our best work and to prevent mediocrity'
        'Foster a culture of healthy competition toward the goals'
        'Provide solutions and incentives to customers that inspire excellence and breakthrough results'
        'Engage Harmony members of the team to push our thinking and to help us avoid being myopic and insensitive.'
      ]
      considerations: (who) -> [
        'Design goals that feel like a stretch'
        'Set the bar high for how we define excellence'
        'Find ways we can drive progress for (Dynamic content based on dominant impact driver: “organization,” “individuals” or “society”).'
        'Consider how we can incentivize others to accelerate change.'
      ]
      goals:
        'Our team will have a high sense of meaning and impact when we set ambitious goals that are truly groundbreaking and market-leading and that set new standards for excellence. We can use this to define our “moonshot” goal!'

    Harmony:
      description:
        'We believe that we must level the playing field and ensure that everyone
        has equal opportunity to thrive. We work to increase accessibility and to
        give a voice to the disenfranchised so they can realize their potential.'
      alignments: [
        'Make sure our team goals state what inequities are being addressed'
        'Create KPIs and incentives that will encourage a level playing field needed within our team to get good results'
        'Foster a culture of disciplined collaboration toward the goals, ensuring that all voices are heard'
        'Provide inclusive solutions for customers'
        'Engage Karma members of the team to push our thinking and to help us avoid being myopic and too consensus-oriented.'
      ]
      considerations: [
        'Create a more sustained impact on equality and fairness'
        'Enable equal access to meet more fundamental human needs'
        'Level the playing field for more underserved groups'
        'Build awareness of the importance of equality, balance, justice, and accountability within the organization.'
      ]
      goals:
        'Our team will have a high sense of meaning and impact when we set goals that advance equal opportunity, diversity, and lasting equality. We can use this to define our “moonshot” goal!'

  how:
    Knowledge:
      description:
        'Our core contribution is asking the right questions and uncovering the insights and information needed to unlock the right path forward.'
      competenciesParagraph:
        'Solving problems through knowledge is a needed competency in any organization. Your team is increasingly effective at this when you:'
      competencies: [
        'Consciously make use of this dominant driver to solve challenges inherent in the team goals.'
        'Find approaches to problem-solving that might be best in certain tasks, making use of the other HOW drivers on the team. Perhaps there is a person who loves to do what another hates. This is diversity and inclusion in action!'
        'When celebrating or recognizing the team as a whole, share any breakthrough methods that were used.'
        'Identify needed competencies to achieve the team goals, and update the personal development plans and goals.'
      ]
      considerations: [
        'Identify the biggest challenges the organization faces that can be solved with knowledge and insights.'
        'Use team goals to incentivize better insights or to solve deeper problems.'
        'Create more sustainable insights or insights that can be used multiple times.'
        'Define the competence needed to better use knowledge to provide insights.'
      ]

    Structure:
      description:
        'Our core contribution is designing and implementing systems, tools, and structures to support the delivery of scalable services and products.'
      competenciesParagraph:
        'Solving problems through structure is a needed competency in any organization. Your team is increasingly effective at this when you:'
      competencies: [
        'Consciously make use of this dominant driver to solve challenges inherent in the team goals.'
        'Find approaches to problem-solving that might be best in certain tasks, making use of the other HOW drivers on the team. Perhaps there is a person who loves to do what another hates. This is diversity and inclusion in action!'
        'When celebrating or recognizing the team as a whole, share any breakthrough methods that were used.'
        'Identify needed competencies to achieve the team goals, and update the personal development plans and goals.'
      ]
      considerations: [
        'Identify the biggest challenges the organization faces that can be solved with structure. Reflect on how the team can contribute.'
        'Identify ways to incentivize solving problems using structure.'
        'Create more sustainable structures or structures that can be used multiple times.'
        'Develop needed competencies to strengthen the use of structure to solve problems.'
      ]

    Community:
      description:
        'Our core contribution is equipping others to take ownership of their challenges and opportunities so they can create the future that they want for themselves and the community.'
      competenciesParagraph:
        'Bringing together and empowering communities is a needed competency in any organization. Your team is increasingly effective at this when you:'
      competencies: [
        'Consciously make use of this dominant driver to solve challenges inherent in the team goals.'
        'Find approaches to problem-solving that might be best in certain tasks, making use of the other HOW drivers on the team. Perhaps there is a person who loves to do what another hates. This is diversity and inclusion in action!'
        'Celebrate or recognize the team as a whole by sharing any breakthrough methods that were used.'
        'Identify needed competencies to achieve the team goals, and update the personal development plans and goals.'
      ]
      considerations: [
        'Identify the biggest challenges the organization faces that can be solved with community. Reflect on how the team can contribute.'
        'Identify opportunities to empower even more people to have a greater impact.'
        'Explore where the team can make a more sustained impact.'
        'Improve the perceived value of community-based problem-solving within the organization.'
        'Define the competence needed to develop the strength and abilities to build community.'
        'Define team goals that can help the team increase ownership among stakeholders.'
      ]

    Human:
      description:
        'Our core contribution is delivering authentic solutions based on the real needs of people in the context of their unique environments.'
      competenciesParagraph:
        'The team naturally prioritizes the needs of real people and their unique contexts. Your team is increasingly effective at this when you:'
      competencies: [
        'Consciously make use of this dominant driver to solve challenges inherent in the team goals.'
        'Find approaches to problem-solving that might be best in certain tasks, making use of the other HOW drivers on the team. Perhaps there is a person who loves to do what another hates. This is diversity and inclusion in action!'
        'When celebrating an achievement, recognize the team as a whole, share any breakthrough methods that were used.'
        'Identify needed competencies to achieve the team goals, and update personal development plans and goals.'
      ]
      considerations: [
        'Spot the biggest challenges the organization faces that can be solved with human-centered design or that can be better defined by a true understanding of the needs of people.'
        'Explore ways to use empathy to solve needs for larger and/or more diverse populations.'
        'Solve more critical needs.'
        'Gain and provide a deeper understanding of the contexts and authentic needs of people.'
        'Increase the perceived value of human-based problem-solving.'
        'Become a mentor to the next generation of those who create value by connecting with authentic needs.'
        'Define the competence needed to be the best at human-centered design.'
      ]

DIMENSIONS_MAP =
 who: 'impact'
 why: 'values'
 how: 'craft'

TeamDriver = ({dimension, driver, description, children})  ->
  driverIcon = "#{URL_DRIVERS}/#{dimension}#{driver}.png"

  return \
  R.createElement("article", {"className": "TeamReport__teamDriver TeamReport__teamDriver_#{dimension}"},
    R.createElement("header", {"className": "TeamReport__teamDriverHeader TeamReport__teamDriverHeader_#{dimension}"},
      R.createElement("div", {"className": "TeamReport__teamDriverHeaderLeft"},
        R.createElement("img", { \
          "className": "TeamReport__teamDriverHeaderIcon",  \
          "src": (driverIcon),  \
          "alt": (driver)
        })
      ),
      R.createElement("div", {"className": "TeamReport__teamDriverHeaderRight"},
        R.createElement("h1", {"className": "TeamReport__teamDriverHeaderHeading TeamReport__teamDriverHeaderHeading_#{dimension}"},
          R.createElement("span", {"className": "TeamReport__teamDriverHeaderHeadingDimension"},
            (DIMENSIONS_MAP[dimension].toUpperCase()), """:
"""),
          R.createElement("span", {"className": "TeamReport__teamDriverHeaderHeadingDriver"},
            (driver)
          )
        ),
        R.createElement(P, {"size": "sm", "className": "TeamReport__teamDriverHeaderDescription"}, (description))
      )
    ),
    R.createElement("section", {"className": "TeamReport__teamDriverSection"},
      (children)
    )
  )


WhoTeamDriver = ({driver}) ->
  {
    description
    goals
    fulfillments
    considerations
    groupThink
  } = DRIVER_DATA.who[driver]

  return \
  R.createElement(TeamDriver, { \
    "dimension": "who",  \
    "driver": (driver),  \
    "description": (description)
  },
    R.createElement("div", {"className": "TeamReport__teamDriverSectionLeft"},
      R.createElement("h1", {"className": "TeamReport__teamDriverSectionHeading"}, "Own Your Team’s Impact"),
      R.createElement(P, {"size": "sm"}, "Our team will be more effective and fulfilled in our daily work when:"),
      R.createElement(Ul, {"size": "sm"},
        (for fulfillment, i in fulfillments
          R.createElement(Li, {"key": (i)}, (fulfillment))
        )
      )
    ),
    R.createElement("div", {"className": "TeamReport__teamDriverSectionRight"},
      R.createElement(P, {"size": "sm"}, (goals)),
      R.createElement(P, {"size": "sm"}, "Consider how we can:"),
      R.createElement(Ul, {"size": "sm"},
        (for consideration, i in considerations
          R.createElement(Li, {"key": (i)}, (consideration))
        )
      ),
      R.createElement(P, {"size": "sm"}, (groupThink))
    )
  )


WhyTeamDriver = ({driver, who}) ->
  inflectedWho = switch who
    when 'Society' then who.toLowerCase()
    when 'Individual', 'Organization' then "#{who.toLowerCase()}s"

  {
    description
    alignments
    fulfillments
    considerations
    goals
  } = DRIVER_DATA.why[driver]

  if typeof considerations is 'function'
    considerations = considerations inflectedWho

  return \
  R.createElement(TeamDriver, { \
    "dimension": "why",  \
    "driver": (driver),  \
    "description": (description)
  },
    R.createElement("div", {"className": "TeamReport__teamDriverSectionLeft"},
      R.createElement("h1", {"className": "TeamReport__teamDriverSectionHeading"}, "Live Your Team’s Values"),
      R.createElement(P, {"size": "sm"}, "We are deeply aligned as a team and have a strong sense of shared values when we:"),
      R.createElement(Ul, {"size": "sm"},
        (for alignment, i in alignments
          R.createElement(Li, {"key": (i)}, (alignment))
        )
      )
    ),
    R.createElement("div", {"className": "TeamReport__teamDriverSectionRight"},
      R.createElement(P, {"size": "sm"}, (goals)),
      R.createElement(P, {"size": "sm"}, "We can use this to define our “moonshot” goal!"),
      R.createElement(Ul, {"size": "sm"},
        (for consideration, i in considerations
          R.createElement(Li, {"key": (i)}, (consideration))
        )
      )
    )
  )


HowTeamDriver = ({driver}) ->
  {
    description
    considerations
    competencies
    competenciesParagraph
  } = DRIVER_DATA.how[driver]

  return \
  R.createElement(TeamDriver, { \
    "dimension": "how",  \
    "driver": (driver),  \
    "description": (description)
  },
    R.createElement("div", {"className": "TeamReport__teamDriverSectionLeft"},
      R.createElement("h1", {"className": "TeamReport__teamDriverSectionHeading"}, "Harness Your Team’s Gifts"),
      R.createElement(P, {"size": "sm"}, (competenciesParagraph)),
      R.createElement(Ul, {"size": "sm"},
        (for competency, i in competencies
          R.createElement(Li, {"key": (i)}, (competency))
        )
      )
    ),
    R.createElement("div", {"className": "TeamReport__teamDriverSectionRight"},
      R.createElement(P, {"size": "sm"}, "To increase the team’s impact in the long-term:"),
      R.createElement(Ul, {"size": "sm"},
        (for consideration, i in considerations
          R.createElement(Li, {"key": (i)}, (consideration))
        )
      )
    )
  )


export {WhoTeamDriver, WhyTeamDriver, HowTeamDriver}
