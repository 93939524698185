import React, {useCallback, useMemo, useState, useContext, forwardRef, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import {IMG_AARON} from 'theme/assets/assets';
import Modal, * as M from 'theme/Modal';
import TranslationContext from 'translations';
import Datepicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';


const CustomInput = forwardRef(({onClick, ...props}, ref) => {
  return (
    <div className="Settings__pauseDatepickerInput" onClick={onClick}>
      <input {...props} ref={ref}/>
      <i className="far fa-calendar-day" />
    </div>
  )
})

const PauseModal = (props) => {
  const {user, pause, opened = false, open, groupedConversations = {}, inline, cancel} = props;
  const history = useHistory();
  const t = useContext(TranslationContext);
  const [reason, setReason] = useState();
  const [startDate, setStartDate] = useState(() => {
    const date = user && user.pauseInformation &&
      (user.isPaused ? user.pauseInformation.pauseStartDate : user.pauseInformation.pauseScheduleStartDate);
    return date
      ? moment(date).toDate()
      : moment().startOf('day').toDate()
  });
  const [endDate, setEndDate] = useState(() => {
    const date = user && user.pauseInformation &&
      (user.isPaused ? user.pauseInformation.pauseEndDate : user.pauseInformation.pauseScheduleEndDate)
    return date
      ? moment(date).toDate()
      : moment(startDate).add(2, 'months').endOf('day').toDate()
  });
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmationVisible, showConfirmation] = useState(false);

  const memberPauseEnabled = useMemo(() => {
    if(!user) return false;
    return user.organizationSettings.pauseSettings.isMemberPauseEnabled;
  }, [user]);

  const memberPaused = useMemo(() => {
    if(!user) return false;
    return user.isPaused;
  }, [user]);

  const pauseOrShowConfirmation = useCallback(() => {
    const activeConv = Object.keys(groupedConversations).find(peerId => {
      const sets = groupedConversations[peerId].sets;
      return sets.find(s => s.dateCompleteBy && moment(startDate).isBefore(moment(s.dateCompleteBy), 'day'));
    });
    if(activeConv && !confirmationVisible) {
      showConfirmation(true);
    }
    else pauseMember();
  }, [startDate, endDate, groupedConversations, confirmationVisible, reason]);

  const pauseMember = useCallback(() => {
    setLoading(true);
    return pause({reason, startDate: moment(startDate).toISOString(), endDate: moment(endDate).toISOString()})
    .then(
      () => {
        history.push('/?pauseConfirmation=true')
      },
      () => {
        setError('Cannot pause peer coaching. Please try again later or contact Imperative Team.');
        setLoading(false);
      }
    )
  }, [reason, startDate, endDate]);

  const changeStartDate = useCallback((d) => {
    const start = moment(d).startOf('day'); 
    const end = moment(start).add(2, 'months').endOf('day').toDate();
    setStartDate(start.toDate());
    setEndDate(end);
  });

  const pauseReasons = useMemo(() => {
    return t.getSplitted("settings.pause.reasons").map(r => ({label: r, value: r}));
  }, [t]);

  const pauseBtnDisabled = useMemo(() => {
    if(inline) {
      return loading || !startDate || !endDate ||
        (moment(startDate).isSame(moment(user.pauseScheduleStartDate), 'day') && moment(endDate).isSame(moment(user.pauseScheduleEndDate), 'day'));
    }
    return !reason || !startDate || !endDate || loading;
  }, [user, reason, inline, startDate, endDate]);

  if(!memberPauseEnabled) return <span />

  const PauseForm = () =>
    <>
      <div className={cx("Settings__pauseForm", {inline})}>
        <div className='Settings__pauseFormItem'>
          <span className='p-new'>{inline ? 'Begin pause' : t.find("settings.pause.startDate.label")}</span>
          <Datepicker
            selected={startDate}
            onChange={changeStartDate}
            dateFormat="dd MMM yyyy"
            className={cx("Settings__pauseDatepicker", {hasValue: startDate})}
            minDate={new Date()}
            placeholderText="Select a date"
            customInput={<CustomInput />}
          />
        </div>
        <div className='Settings__pauseFormItem'>
          <span className='p-new'>{inline ? 'End pause' : t.find("settings.pause.endDate.label")}</span>
          <Datepicker
            selected={endDate}
            onChange={(o) => setEndDate(moment(o).endOf('day').toDate())}
            dateFormat="dd MMM yyyy"
            className={cx("Settings__pauseDatepicker", {hasValue: endDate})}
            minDate={startDate}
            placeholderText="Select a date"
            customInput={<CustomInput />}
          />
        </div>
        {!inline &&
          <div className='Settings__pauseFormItem Settings__pauseFormItem--stretch'>
            <span className='p-new'>{t.find("settings.pause.reason.label")}</span>
            <Select
              className={cx("react-select Settings__select", {hasValue: reason !== null})}
              classNamePrefix="Settings__select react-select"
              placeholder={t.find("settings.pause.reason.placeholder")}
              value={pauseReasons.find(r => r.value == reason)}
              onChange={o => setReason(o.value)}
              options={pauseReasons}
              components={{ IndicatorSeparator:() => null }}
            />
          </div>
        }
      </div>
      {error &&
        <div className="Settings__pauseError">
          {error}
        </div>
      }
      <div className={cx("Settings__pauseBtns", {inline})}>
        <button
          className="btn-secondary large"
          onClick={cancel || (() => open(false))}
        >{t.find("settings.pause.cancel")}</button>
        <button
          className="btn-primary large"
          onClick={pauseOrShowConfirmation}
          disabled={pauseBtnDisabled}
        >
          {loading
            ? <i className="far fa-circle-notch fa-spin" />
            : t.find("settings.pause.save")
          }
        </button>
      </div>
    </>

  return (
    <>
      {inline && <PauseForm  />}
      {!inline &&
        <Modal
          isOpen={opened && !memberPaused && !confirmationVisible}
          close={() => open(false)}
          className="no-padding"
        >
          <M.Header>
            {t.find("settings.pause.title")}
          </M.Header>
          <M.Content className="Settings__pause">
            <div className="Settings__pauseContent">
              <img src={IMG_AARON} alt="" />
              <PauseForm />
            </div>
          </M.Content>
        </Modal>
      }

      <Modal
        isOpen={!memberPaused && confirmationVisible}
        close={() => showConfirmation(false)}
        className="no-padding"
      >
        <M.Header>
          {t.find("settings.pause.confirmation.title")}
        </M.Header>
        <M.Content className="Settings__pause">
          <div className="Settings__pauseContent">
            <img src={IMG_AARON} alt="" />
            <div className="Settings__pauseContentText">
              {t.getSplitted("settings.pause.confirmation.description").map((c, i) =>
                <p key={i}>
                  {c}
                </p>
              )}
            </div>
          </div>
          <div className="Settings__pauseBtns">
            <button
              className="btn-secondary large"
              onClick={() => showConfirmation(false)}
            >{t.find("settings.pause.confirmation.back")}</button>
            <button
              className="btn-primary large"
              onClick={pauseOrShowConfirmation}
              disabled={pauseBtnDisabled}
            >
              {loading
                ? <i className="far fa-circle-notch fa-spin" />
                : t.find("settings.pause.confirmation.confirm")
              }
            </button>
          </div>
        </M.Content>
      </Modal>
    </>
  )
}

export default PauseModal;
