import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as ME from 'api/metrics';
import { getDomain } from 'api';
import { parse } from 'qs';
import Layout from 'auth/Layout';
import { signinWithWorkOS } from 'auth/requests';
import { getRedirUrl } from 'api';

import './SSO.sass';

const SSOCallback = () => {
  const location = useLocation();
  const history = useHistory();

  const code = useMemo(() => {
    const search = parse(location.search, {ignoreQueryPrefix: true});
    return search.code;
  }, [location]);

  useEffect(() => {
    if(code) {
      const domain = getDomain();
      if(!domain) {
        ME.reportIntermediateError('SSO Callback', 'No domain saved in storage');;
        history.push(`/signin/sso?error=workos`);
      } else {
        signinWithWorkOS(domain, code)
        .then((jwt) => {
          window.location.href = `${getRedirUrl()}&session_token=${jwt}`;
        })
        .catch((err) => {
          ME.reportIntermediateError('SSO Callback', err.data);
          history.push(`/signin/sso?error=workos`)
        })
      }
    }
  }, [code]);

  return (
    <Layout headline="Sign In">
      <div className="SSO__inner">
        <p className="SSO__status">Signing in...</p>
      </div>
    </Layout>
  )
}

export default SSOCallback;
