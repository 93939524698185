import React from 'react';
import LanguageDropdown from 'theme/LanguageDropdown';
import cx from 'classnames';

const Intro = ({t, setFocusRef, languageOptions, changeLanguage, currentLanguage, changingLanguage, user}) => (
  <section className='margin-bottom-8'>
    <div className={cx("Assessment__loadingOverlay", {visible: changingLanguage})}>
      <span className='far fa-spinner-third fa-spin fa-3x'/>
    </div>
    {languageOptions && languageOptions.length > 1 &&
      <label className="Assessment__languageDropdown_label">
        <span>Your language</span>
        <LanguageDropdown
          className="Assessment__languageDropdown"
          options={languageOptions}
          onChange={changeLanguage}
          placeholder={`Change language - ${!!currentLanguage ? currentLanguage : 'English'}`}
          id='Assessment__languageDropdown'
        />
      </label>
    }
    <header tabIndex="-1" className="Assessment__title" ref={setFocusRef}>{t.find('assessment.page.welcome.title')}{user ? ", " + user.firstName : ""}</header>
    <div className="Assessment__intro_content_layout">
      <div className="Assessment__img Assessment__img_aaron_intro"/>
      <div>
        <p className="Assessment__text">
          {t.find('assessment.page.welcome.description1')}
        </p>
        <p className="Assessment__text">
          {t.find('assessment.page.welcome.description2')}
        </p>
        <p className="Assessment__text">
            <span className="Assessment__text_bold">{t.find('assessment.page.welcome.description3.bold')}</span>
            <br/>
            {t.find('assessment.page.welcome.description4')}
        </p>
      </div>
    </div>
  </section>
);

export default Intro;
