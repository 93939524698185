import React, { useState } from 'react';
import cx from 'classnames';
import './Toggler.sass';

const DEFAULT_ARIA_LABELS = {
  on: 'on',
  off: 'off',
};

const Toggler = ({ checked, onChange, disabled, className, ariaLabels = DEFAULT_ARIA_LABELS }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = (e) => {
    if (!disabled && (e.key === ' ' || e.key === 'Enter')) onChange(!checked);
  };

  return (
    <button
      className={cx('Toggler', className, { on: checked, disabled })}
      onClick={() => !disabled && onChange(!checked)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      aria-pressed={checked}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      aria-label={checked ? ariaLabels.on : ariaLabels.off}
    >
      {checked && <span className="Toggler__icon far fa-check" />}
      <span className="Toggler__dot" />
      {!checked && <span className="Toggler__icon far fa-times" />}
    </button>
  );
};

export default Toggler;
