import {composeComponent, withData} from 'utils/react-tools'
import {withHandlers, withStateHandlers, withState, lifecycle, withProps} from 'recompose'
import {Link, withRouter} from 'react-router-dom'
import axios from 'axios'
import cx from 'classnames'
import Modal from 'theme/Modal'
import r from 'r-dom'
import Markdown from 'theme/Markdown'
import * as ME from 'api/metrics'
import {getImage} from 'microLessons/images'
import MicroLessonCard from './MicroLessonCard'
import {updateJobHack} from 'microLessons/requests'

import './MicroLesson.sass'

export default composeComponent 'MicroLesson',
  withRouter
  withState 'loading', 'setLoading', false
  withState 'rate', 'setRate', 0
  withState 'video', 'setVideo', null
  withStateHandlers ({initiallyOpened}) ->
    ratingModalVisible: false
    modalVisible: initiallyOpened ? false
  ,
    showRatingModal: -> (ratingModalVisible) -> {ratingModalVisible}
    showModal: -> (modalVisible) -> {modalVisible}

  withHandlers
    updateMicroLesson: ({user, defaultSearchParams, getJobHacks, setMicroLessons}) -> (id, params) ->
      if params?.completed
        ME.reportStateChange 'Purpose Academy: completing job hack'
      else
        ME.reportStateChange 'Purpose Academy: updating job hack', {jobHackId: id, ...params}
      updateJobHack id, params
      .then (microLesson) ->
        if params?.completed
          ME.reportStateChange 'Purpose Academy: job hack completed'
        else
          ME.reportStateChange 'Purpose Academy: job hack updated'
      .catch (err) ->
        if params?.completed
          ME.reportIntermediateError 'Purpose Academy: job hack completing error', err
        else
          ME.reportIntermediateError 'Purpose Academy: job hack updating error', err


  withHandlers
    updateJH: ({microLesson, updateMicroLesson, setLoading, microLessonsPage, showRatingModal, showModal}) ->
      (optionToChange) ->
        changedOption = {"#{optionToChange}": !microLesson[optionToChange]}
        updateMicroLesson microLesson.jobHack.id, changedOption
        .then ->
          showModal false
        .then ->
          if changedOption?.completed
            showRatingModal true

    rateMicroLesson: ({microLesson, showRatingModal, setRate, rate, updateMicroLesson, microLessonUpdated}) -> ->
      id = microLesson?.jobHack.id
      ME.reportStateChange 'Purpose Academy: job hack rating', {jobHackId: microLesson?.jobHack.id, rate}
      updateMicroLesson id, {rate}
      .then ->
        setRate 0
        showRatingModal false
        ME.reportStateChange 'Purpose Academy: job hack rated', {jobHackId: microLesson?.jobHack.id, rate}
        microLessonUpdated? microLesson
      .catch (err) -> ME.reportIntermediateError 'Purpose Academy: job hack rating error', err, {jobHackId: microLesson?.jobHack.id}

    getVideo: ({microLesson, setVideo, setLoading, user, t, videoId}) -> ->
      setLoading true

      unless microLesson
        return Promise.resolve null

      {drtc, label} = microLesson.jobHack

      videoId =
        if drtc != 'Watch'
          null
        else if !t.exists "jobHack.#{label}.video"
          null
        else t.find "jobHack.#{label}.video"


      if !videoId
        setLoading false
        setVideo({})
        return Promise.resolve null

      ME.reportStateChange 'Purpose Academy: loading vimeo video', {jobHackId: microLesson?.jobHack.id, videoId}

      axios.get "https://vimeo.com/api/v2/video/#{videoId}.json"
      .then ({data}) ->
        setVideo({
          id: data[0].id
          image: data[0].thumbnail_large
          title: data[0].title
          duration: data[0].duration
        })
        ME.reportStateChange 'Purpose Academy: vimeo video loaded', {jobHackId: microLesson?.jobHack.id, videoId}
        setLoading false
      , (err) ->
        ME.reportIntermediateError 'Purpose Academy: vimeo video loading error', err,  {jobHackId: microLesson?.jobHack.id, videoId}
        setLoading false
        setVideo({})

  lifecycle
    componentDidUpdate: ->
      {microLesson, t, loading, video} = @props
      {label} = microLesson.jobHack
      exists = t.exists "jobHack.#{label}.video"

      if(microLesson?.jobHack.drtc == 'Watch')
        if(!loading && !video && exists)
          @props.getVideo()

  ({microLesson, updateMicroLesson, showModal, modalState, video, loading,
    user, close, from, history, tutorial, showRatingModal, inDashboard, modalVisible,
    ratingModalVisible, setRate, rate, rateMicroLesson, updateJH, t}) ->

    r.div className: "MicroLesson", [
      r MicroLessonCard,
        user: user
        microLesson: microLesson
        inDashboard: inDashboard
        inModal: false
        video: video
        loadingVideo: loading
        updateMicroLesson: updateJH
        t: t
        onClick: -> showModal true
        close: -> showModal false

      r Modal,
        isOpen: modalVisible
        className: "MicroLesson__modal"
        r MicroLessonCard,
          user: user
          microLesson: microLesson
          inDashboard: inDashboard
          video: video
          loadingVideo: loading || (microLesson?.jobHack.drtc == 'Watch' && !video)
          inModal: true
          t: t
          updateMicroLesson: updateJH
          close: -> showModal false

      r Modal,
        isOpen: ratingModalVisible
        title: 'Micro-Lesson'
        className: "MicroLesson__ratingModal"
        r.div className: "MicroLesson__rate", [
          r.p className: "MicroLesson__rateTitle", t.find "jobHack.#{microLesson?.jobHack?.label}.title"
          r.p className: "MicroLesson__rateContent", "Was this helpful?"
          r.div className: "MicroLesson__rateStars",
            [1..5].map (i) ->
              r.i
                key: i,
                className: if rate >= i then 'fas fa-star' else 'far fa-star'
                onClick: -> setRate i
          r.div {},
            r.button
              className: 'btn btn_secondary btn_solid_bluePurple'
              onClick: rateMicroLesson
              'Continue'
        ]
    ]
