import React from 'react'
import PT from 'prop-types'
import ReactMarkdown from 'react-markdown'
import cx from 'classnames'


export default ({source, className}) ->
  React.createElement(ReactMarkdown, {"className": (cx if className then className else "LongReport__markdown")},
    (source)
  )
