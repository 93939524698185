import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import {TextField} from './Form';

const TextFieldWithValidationBullets = ({errors = [], meta, ...props}) => {
  const [bulletsVisible, showBullets] = useState(false);
  const {error, ...rest} = meta;
  const value = props.input.value;

  const validationBullets = errors
  .filter(e => e.inBullets)
  .map(e => {
    return {...e, conditionMet: e.value == undefined && props.input.value.length > 0}
  });

  const err = errors.find(e => e.value != undefined && !e.inBullets);

  useEffect(() => {
    if(value) {
      if(meta.valid && !meta.active) {
        showBullets(false);
      }
      else {
        showBullets(true);
      }
    }
  }, [meta, value]);

  return (
    <>
      <TextField {...props} meta={{...rest, error: err && err.message}}/>
      <div className="AuthForm__validationBullets">
        {bulletsVisible && validationBullets.map((e, i) => {
          return (
            <div
              key={i}
              className={cx("AuthForm__validationBullets-item", {active: e.conditionMet})}
            >
              <i className={e.conditionMet ? 'fas fa-check-circle' : 'fas fa-exclamation-circle'} />
              {e.message}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default TextFieldWithValidationBullets;
