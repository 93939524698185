import React, { useEffect, useRef, useMemo, useContext } from "react";
import * as d3 from "d3";
import { useHistory } from "react-router-dom";
import TranslationsContext from "translations";
import { uniqBy } from "ramda";

import "./NetworkGraph.sass";

const MAX_DISTANCE_COUNT = 4;

const initials = ({ firstName, lastName, slug } = {}) => {
  if (firstName && lastName)
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  if (slug) return slug.slice(0, 2);
};

const NetworkGraph = ({ member } = { member: { connections: [] } }) => {
  const graphRef = useRef(null);
  const history = useHistory();
  const tooltip = useRef();
  const t = useContext(TranslationsContext);

  const createAvatarPattern = (avatar, memberId) => {
    return (
      <pattern
        key={memberId}
        id={memberId}
        width="100%"
        height="100%"
        patternContentUnits="objectBoundingBox"
        viewBox="0 0 1 1"
        preserveAspectRatio="xMidYMid slice"
      >
        <image
          x="0"
          y="0"
          width="1"
          height="1"
          xlinkHref={avatar}
          preserveAspectRatio="xMidYMid slice"
        />
      </pattern>
    );
  };

  const connections = useMemo(() => member.connections, [member.memberId]);

  const avatarsDefinitions = useMemo(() => {
    const uniqMembers = uniqBy(
      (m) => m.memberId,
      [member].concat(connections)
    ).filter((m) => m.avatar);
    return uniqMembers.map((c) => createAvatarPattern(c.avatar, c.memberId));
  }, [connections]);

  useEffect(() => {
    const memberIds = [member].concat(connections);

    const nodeRadius = 30,
      centerNodeRadius = Math.floor(nodeRadius * 1.5),
      distanceOffset = nodeRadius * 2,
      distanceVariations =
        connections.length < MAX_DISTANCE_COUNT
          ? connections.length
          : MAX_DISTANCE_COUNT,
      distance = (_, i) => {
        return (
          centerNodeRadius +
          distanceOffset +
          (i % distanceVariations) * 2 * nodeRadius
        );
      },
      maxDistance = distance(null, distanceVariations - 1),
      height = (maxDistance + nodeRadius) * 2 + 20,
      width = height;

    const nodes = memberIds.map(
      ({
        memberId,
        jobFunction,
        jobFunctionText,
        firstName,
        lastName,
        avatar,
        pattern: { dominant } = {},
      }) => {
        const me = memberId == member.memberId;
        return {
          id: memberId,
          firstName,
          lastName,
          avatar,
          dominant,
          me,
          jobFunction:
            jobFunctionText ||
            (jobFunction &&
              t.find(`dictionaries.jobFunction.${jobFunction}.name`)),
          radius: me ? centerNodeRadius : nodeRadius,
          ...(!me || connections.length === 1
            ? {}
            : {
                fx: width / 2,
                fy: height / 2,
              }),
        };
      }
    );
    const links = memberIds
      .slice(1)
      .map(({ memberId }) => ({ source: member.memberId, target: memberId }));

    function focusEventHandler(event, d) {
      const graphSize = graphRef.current.getBoundingClientRect();
      if (!tooltip.current || d.me) return;

      const x = (d.x * graphSize.width) / width;
      const y = (d.y * graphSize.height) / height;
      const radius = (d.radius * graphSize.width) / width;

      d3.select(tooltip.current)
        .html(
          !!d.jobFunction
            ? `<span>${d.firstName} ${d.lastName}</span><span>${d.jobFunction}</span>`
            : `<span>${d.firstName} ${d.lastName}</span>`
        )
        .style("top", y - radius * 1.5 + "px")
        .style("left", x + "px")
        .style("transform", `translate(-50%, -100%)`);

      d3.select(tooltip.current)
        .transition()
        .duration(200)
        .style("visibility", "visible")
        .style("opacity", 1);
    }

    function blurEventHandler() {
      if (!tooltip.current) return;
      d3.select(tooltip.current)
        .transition()
        .duration(200)
        .style("visibility", "hidden")
        .style("opacity", 0);
    }

    const forceNode = d3.forceManyBody().strength(0);

    const forceLink = d3
      .forceLink(links)
      .distance(distance)
      .id(({ index: i }) => memberIds[i].memberId);

    const simulation = d3
      .forceSimulation(nodes)
      .force("link", forceLink)
      .force("charge", forceNode)
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force(
        "collide",
        d3
          .forceCollide()
          .radius((d) => d.radius)
          .iterations(3)
      )
      .on("tick", ticked);

    const svg = d3
      .select(graphRef.current)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", `0 0 ${width} ${height}`);

    const children = svg.selectAll("g").remove();

    const linesElem = svg
      .append("g")
      .selectAll("line")
      .data(links)
      .join("line")
      .classed("NetworkGraph__connection", true);

    const nodesElem = svg
      .append("g")
      .selectAll("g")
      .data(nodes)
      .join("g")
      .attr("aria-label", (d) =>
        d.me
          ? undefined
          : !!d.jobFunction
          ? `Go to ${d.firstName} ${d.lastName} - ${d.jobFunction} Profile`
          : `Go to ${d.firstName} ${d.lastName} Profile`
      )
      .attr("role", "link")
      .attr("class", (d) => {
        const me = d.id == member.memberId;
        const isMeClass = me ? "me" : "partner";
        const dominantClass = (d.dominant || "").toLowerCase();
        const hasAvatarClass = !!d.avatar && "hasAvatar";
        return [isMeClass, hasAvatarClass, dominantClass].join(" ");
      })
      .classed("NetworkGraph__avatar", true)
      .attr("role", (d) => (d.me ? undefined : "button"))
      .attr("tabindex", (d) => (d.me ? undefined : 0))
      .on("click", (_, d) => {
        d.me ? undefined : history.push(`/community/members/${d.id}`);
      })
      .on("keypress", function (event, d) {
        if (!d.me && event.key == "Enter") {
          history.push(`/community/members/${d.id}`);
        }
      })
      .call(drag(simulation))
      .on("mouseover", focusEventHandler)
      .on("mouseout", blurEventHandler)
      .on("focus", focusEventHandler)
      .on("blur", blurEventHandler);

    nodesElem
      .append("circle")
      .classed("NetworkGraph__avatarCircle", true)
      .attr("fill", (d) => (d.avatar ? `url(#${d.id})` : "white"))
      .attr("r", (d) => (d.me ? centerNodeRadius : nodeRadius));

    nodesElem
      .append("circle")
      .attr("fill", "none")
      .attr("hidden", (d) => (d.me ? true : null))
      .classed("NetworkGraph__avatarOutline", true)
      .attr("r", (d) =>
        d.me ? centerNodeRadius : nodeRadius + (d.avatar ? 0 : 2)
      );

    nodesElem
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", (d) => (d.me ? 10 : 7))
      .attr("text-anchor", "middle")
      .classed("NetworkGraph__avatarInitials", true)
      .text((d) => (!d.avatar ? initials(d) : ""));

    function ticked() {
      linesElem
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      nodesElem.attr("transform", (d) => `translate(${d.x}, ${d.y})`);
    }

    function drag(simulation) {
      const dragstarted = (event, d) => {
        if (d.me) return;
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      };

      const dragged = (event, d) => {
        if (d.me) return;
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      };

      const dragended = (event, d) => {
        if (d.me) return;
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      };

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    }
  }, [member, connections, tooltip]);

  return (
    <div className="NetworkGraph">
      <div className="NetworkGraph__tooltip" ref={tooltip} />
      <svg
        className="NetworkGraph"
        ref={graphRef}
        role="group"
        aria-label={`${member && member.firstName}'${
          member && member.firstName.slice(-1) == "s" ? "" : "s"
        } connections`}
      >
        <defs>{avatarsDefinitions}</defs>
      </svg>
    </div>
  );
};

export default NetworkGraph;
