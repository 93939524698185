import React, {useState, useEffect, useContext, useMemo, useCallback} from 'react';
// import {composeComponent, withData} from 'utils/react-tools'
// import {withHandlers, withStateHandlers} from 'recompose'
import {Link, withRouter} from 'react-router-dom'
import TranslationsContext from 'translations';
import cx from 'classnames'
import Loader from 'theme/Loader'
import r from 'r-dom'
import Markdown from 'theme/Markdown'
import {URL_ARCHETYPES, URL_JOB_HACKS} from 'theme/assets/assets'
import {getImage} from 'microLessons/images'

import './MicroLesson.sass'

const MID_TEXTS = {
  'ArchetypeName': (u) =>
    u.archetype.title
}

const ONBOARDING_ML = [
  'article-3',
  'onboarding-video-1',
  'onboarding-video-2',
]

const ONBOARDING_TIPS = [
  'article-2'
]

const MicroLessonCard = (props) => {
  const {microLesson, updateMicroLesson, inModal, user, close, from, tutorial, onClick, inDashboard, className, video, loadingVideo} = props;
  const [copyInfoVisible, setCopyInfoVisibility] = useState(false);
  const t = useContext(TranslationsContext);

  const copyToClipboard = useCallback((id, label) => {
    if (typeof navigator.share != 'undefined') {
      navigator.share({title: `Micro-Lesson: ${t.find(`jobHack.${label}.title`)}`, url: `${location.origin}/purpose-profile/purpose-academy?microlesson=${id || label}&${id ? 'id' : 'label'}`})
    } else {
        textField = document.createElement('textarea');
        textField.innerText = `${location.origin}/purpose-profile/purpose-academy?microlesson=${id || label}&${id ? 'id' : 'label'}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopyInfoVisibility(true);
        setTimeout(() => setCopyInfoVisibility(false), 1500);
    }
  }, []);

  const {textA = '', textB = '', textMid = ''} = useMemo(() => {
    if(!microLesson) return {};

    const textToTransform = t.find(`jobHack.${microLesson.jobHack.label}.content`);
    const regBegin = new RegExp(/\[\[|\{\{/g);
    const regEnd = new RegExp(/\]\]|\}\}/g);
    let textA = '';
    let textB = '';
    let textMid = '';

    if (regBegin.test(textToTransform)) {
      regEnd.test(textToTransform);
      const notLink = new RegExp(/\{\{/g).test(textToTransform);

      textA = textToTransform.slice(0, regBegin.lastIndex - 2);
      textB = textToTransform.slice(regEnd.lastIndex);
      if(notLink) {
        const dictionaryIndicator = textToTransform.slice(regBegin.lastIndex, regEnd.lastIndex - 2);
        textMid = MID_TEXTS[dictionaryIndicator](user);
      }
      else {
        const temp = textToTransform.slice(regBegin.lastIndex, regEnd.lastIndex - 2).split(new RegExp(/,|=/g))
        const text = temp[1].replace(/"/g, '');
        const link = temp[3].replace(/"/g, '');
        textMid = <a href={link} target='_blank' className="MicroLesson__link">text</a>
      }
    }

    return {textA, textB, textMid};
  }, [microLesson]);

  const text = ''.concat(textA, textMid, textB);

  const onboardingCard = microLesson && microLesson.jobHack && ONBOARDING_ML.some((label) => microLesson.jobHack.label == label);
  const onboardingCardPink = microLesson && microLesson.jobHack && ONBOARDING_TIPS.some((label) => microLesson.jobHack.label == label);
  const PA = location.pathname.indexOf('purpose-academy') == 0;

  const archetype = useMemo(() => {
    if(!user || !user.pattern) return;
    if(t.exists(`pattern.archetypes.${user.pattern.who}${user.pattern.why}${user.pattern.how}.title`))
      return t.find(`pattern.archetypes.${user.pattern.who}${user.pattern.why}${user.pattern.how}.title`)
  }, [user]);

  const image = useMemo(() => {
    if (user) {
      if (microLesson.jobHack.label == 'article-3' && archetype)
        return `${URL_ARCHETYPES}/${archetype}.png`
      else if (microLesson.jobHack.label == 'article-1')
        return `${URL_JOB_HACKS}/job_hack_connect_1.jpg`
      else if (microLesson.jobHack.label == 'article-2')
        return `${URL_JOB_HACKS}/job_hack_connect_7.jpg`
      else
        return getImage(microLesson)
    }
  }, [user, microLesson])

  if(!microLesson) return null;

  const Wrapper = (p) => inModal ? <div {...p} /> : <button {...p} />

  return (
    <Wrapper
      className={cx("MicroLesson__card", className, {inDashboard, inModal})}
      onClick={onClick}
    >
      <div className={cx("MicroLesson__header", {
        "MicroLesson__headerOnboarding": onboardingCard || onboardingCardPink,
        "pink": onboardingCardPink
      })}>
        <div className="MicroLesson__title">
          {onboardingCard &&
            <span className="material-icons" aria-hidden>
              people_outline
            </span>
          }
          {onboardingCardPink &&
            <span className="material-icons" aria-hidden>
              playlist_add_check
            </span>
          }
          {onboardingCard && 'Conversation basics'}
          {onboardingCardPink && 'Tips & tricks'}
          {!onboardingCardPink && !onboardingCard && t.find(`jobHack.${microLesson.jobHack.label}.title`)}
        </div>
        {(close && inModal) &&
          <button
            className="MicroLesson__modalExit btn"
            onClick={close}
            aria-label="Close"
          >
            <span className='fal fa-times' />
          </button>
        }
      </div>
      <div className="MicroLesson__content">
        {!inModal && inDashboard &&
          <p className="p-2">{t.find(`jobHack.${microLesson.jobHack.label}.title`)}</p>
        }
        {microLesson.jobHack.contentType == 'Video' &&
          <>
            {inModal &&
              <div className="MicroLesson__videoContainer inModal">
                <Loader loading={loadingVideo} className="MicroLesson__videoThumbnailLoading" color="white" />
                {video &&
                  <iframe
                    id='vimeoVideo'
                    className="MicroLesson__video"
                    src={`https://player.vimeo.com/video/${video.id}?transparent=0`}
                    allowFullScreen
                    frameBorder={0}
                    tabIndex="0"
                  />
                }
              </div>
            }
            {!inModal &&
              <div className="MicroLesson__videoThumbnail MicroLesson__videoContainer">
                <Loader loading={loadingVideo} className="MicroLesson__videoThumbnailLoading" color="white" />
                <i className="MicroLesson__videoArrow fas fa-caret-right" />
                {/* <div className="MicroLesson__videoMask" /> */}
                {video && video.image &&
                  <div
                    className="MicroLesson__imager"
                    style={{backgroundImage: `url(${video.image})`}}
                  />
                }
              </div>
            }
          </>
        }
        {microLesson.jobHack.contentType != 'Video' && image &&
          <div className="MicroLesson__imageContainer">
            <div
              className={cx("MicroLesson__image", {"MicroLesson__imageModal": inModal})}
              style={{backgroundImage: `url(${image})`}}
            />
          </div>
        }
        {inModal && inDashboard &&
          <h3 className="margin-top-5 section-head">{t.find(`jobHack.${microLesson.jobHack.label}.title`)}</h3>
        }
        {(inModal && microLesson.jobHack.contentType == 'Article')
        ?
          <div className="MicroLesson__contentArticle">
            <Markdown source={t.find(`jobHack.${microLesson.jobHack.label}.content.markdown`)} />
          </div>
        : inModal &&
          <div>
            {!textA &&
              <Markdown source={t.find(`jobHack.${microLesson.jobHack.label}.content`)} />
            }
            {textA &&
              <Markdown source={text} />
            }
          </div>
        }
        {!inDashboard &&
          <div className="MicroLesson__buttons">
            {microLesson.jobHack.label == 'article-3'
            ?
              <a
                onClick={(e) => e.stopPropagation()}
                target='_blank'
                href={`/m/${user.slug}/print`}
                className="MicroLesson__button btn btn_secondary btn_solid_bluePurple inDashboard"
              >Download My Report</a>
            :
              <>
                {(inDashboard && !inModal)
                ?
                  <button className="MicroLesson__button btn btn_secondary btn_solid_bluePurple inDashboard">
                    <span>Watch Video</span>
                  </button>
                : !inDashboard &&
                  <div className="MicroLesson__copy">
                    <button
                      className="MicroLesson__button btnLink btnLink_compact"
                      onClick={(e) => {
                        copyToClipboard(microLesson.jobHack.id, microLesson.jobHack.label);
                        e.stopPropagation();
                      }}>
                        <i className='fas fa-share btn__icon' />
                        Share
                      </button>
                    <div className={cx("MicroLesson__copied", {"visible": copyInfoVisible})}>
                      Copied to clipboard!
                    </div>
                  </div>
                }
              </>
            }
            {microLesson.completed &&
              <div className="MicroLesson__button btnLink btnLink_secondary btnLink_bluePurple MicroLesson__completed">
                <i className='far fa-check' />
                <span>Completed</span>
              </div>
            }
            {!microLesson.completed && !onboardingCard &&
              <button
                className="MicroLesson__button btn btn_secondary btn_solid_bluePurple"
                onClick={(e) => {
                  e.stopPropagation();
                  updateMicroLesson('completed');
                }}
              >
                <span>Mark complete</span>
              </button>
            }
          </div>
        }
      </div>
    </Wrapper>
  )
}

export default MicroLessonCard;
