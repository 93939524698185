import React from 'react'
import cx from 'classnames'


export default P = ({children, className, size, color}) ->
  React.createElement("p", { \
    "className": (cx "TeamReport__p", className,
      size and "TeamReport__p_#{size}"
      color and "TeamReport__p_#{color}"
    )
  }, (children))
