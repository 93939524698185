
const BothWriteAnswer = 'BothWriteAnswer';
const FirstWriteAnswer = 'FirstWriteAnswer';
const SecondWriteAnswer = 'SecondWriteAnswer';
const Prework = 'Prework';

export default function(conversation, previousCommitments, user, oneWords) {
  if(!conversation || !user)
    return {
      questions: [],
      actions: [],
      actionConfirmations: [],
      oneWordBeforeQuestions: [],
      oneWordAfterQuestions: []
    };
  const meFirst = conversation.me && conversation.me.meFirst;

  const questions = (conversation.questions || [])
  .filter((q) => q.answerType != Prework && !q.action)
  .map((question) => {
    const imAsking =
      (question.answerType == BothWriteAnswer) ||
      (meFirst && question.answerType == FirstWriteAnswer) ||
      (!meFirst && question.answerType == SecondWriteAnswer);
    return {
      ...question,
      memberId: imAsking ? user.id : conversation.partner.member.memberId,
      imAsking
    }
  });

  const actions = (conversation.questions || [])
  .filter((q) => q.answerType != Prework && q.action)
  .map((question) => {
    const imAsking =
      (question.answerType == BothWriteAnswer) ||
      (meFirst && question.answerType == FirstWriteAnswer) ||
      (!meFirst && question.answerType == SecondWriteAnswer);
    return {
      ...question,
      label: 'action',
      memberId: imAsking ? user.id : conversation.partner.member.memberId,
      imAsking,
      notInQuestionCount: true
    }
  });

  const actionConfirmations = (previousCommitments || []).map((a, i) => {
    const answerType =
      (meFirst && a.byMemberId == user.id) || (!meFirst && a.byMemberId != user.id)
      ? FirstWriteAnswer
      : SecondWriteAnswer;
    const oldConfirmation = a.areaId == null;

    return {
      ...a,
      oldConfirmation,
      oneByOne: true,
      actionConfirmation: true,
      notInQuestionCount: true,
      answerType,
      imAsking: (meFirst && answerType == FirstWriteAnswer) || (!meFirst && answerType == SecondWriteAnswer),
      oneByOne: true,
      label: 'actionConfirmation',
      content: oldConfirmation ? a.what : {...a},
      modifiedAt: a.modifiedAt,
      memberId: a.byMemberId,
      member: conversation.participant.find((p) => p.member.memberId == a.byMemberId)
    }
  }).sort((a) => a.answerType == FirstWriteAnswer ? -1 : 1);

  const oneWordBeforeQuestions = conversation.participant.map((p) => {
    const answerType =
      (meFirst && p.member.memberId == user.id) || (!meFirst && p.member.memberId != user.id)
      ? FirstWriteAnswer
      : SecondWriteAnswer;
      return {
        oneByOne: true,
        oneWordBefore: true,
        notInQuestionCount: true,
        answerType,
        imAsking: (meFirst && answerType == FirstWriteAnswer) || (!meFirst && answerType == SecondWriteAnswer),
        oneByOne: true,
        label: 'oneWordBefore',
        memberId: p.member.memberId,
        member: p
      }
    }).sort((a) => a.answerType == FirstWriteAnswer ? -1 : 1);

  const oneWordAfterQuestions = conversation.participant.map((p) => {
    const answerType =
      (meFirst && p.member.memberId == user.id) || (!meFirst && p.member.memberId != user.id)
      ? FirstWriteAnswer
      : SecondWriteAnswer;
      const oneWordAnswer = oneWords.find((w) => w.byMemberId == p.member.memberId);
      return {
        ...oneWordAnswer,
        oneByOne: true,
        oneWordAfter: true,
        notInQuestionCount: true,
        answerType,
        imAsking: (meFirst && answerType == FirstWriteAnswer) || (!meFirst && answerType == SecondWriteAnswer),
        oneByOne: true,
        label: 'oneWordAfter',
        memberId: p.member.memberId,
        member: p
      }
    }).sort((a) => a.answerType == FirstWriteAnswer ? -1 : 1);

    return {
      questions,
      actions,
      actionConfirmations,
      oneWordBeforeQuestions,
      oneWordAfterQuestions
    };
  }
