import React from 'react'
import * as _ from 'ramda'
import {withRouter} from 'react-router'
import {composeComponent} from 'utils/react-tools'
import {fetchUser} from 'auth/requests'
import {mapProps, lifecycle, withStateHandlers, withHandlers} from 'recompose'
import Page from 'reports/PrintPage'
import DownloadButton from 'reports/DownloadReportButton'
import Loader from 'theme/Loader'
import {fetchConversation, fetchConversationsInWorkshop} from 'reports/requests'

import ReportContent from './PrintableConversationPage'

import './PrintableConversation.sass'

drivers =
  who: ['Individual', 'Organization', 'Society']
  why: ['Harmony', 'Karma']
  how: ['Community', 'Human', 'Knowledge', 'Structure']


export default composeComponent 'PrintableConversation',
  withRouter
  withStateHandlers
    user: null
    loading: false
    report: null
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setReport: -> (report) -> {report}

  withHandlers
    loadData: ({user, setUser, startLoading, endLoading, setReport, match: {params}}) -> ->
      startLoading()

      u = fetchUser()
      .then (user) ->
        setUser user
        b = if params.workshopId
            fetchConversationsInWorkshop params.workshopId, user?.org.code
          else if params.id
            fetchConversation params.id
        b.then setReport
      .finally endLoading

  lifecycle
    componentDidMount: ->
      @props.loadData()

  ({loading, report, match: {params}, t, user}) ->
    React.createElement("section", {"className": "PrintableConversation"},
      React.createElement(Loader, {"loading": (loading), "color": "bg"}),
      React.createElement(DownloadButton, {"name": "ConversationGuide"}),

      (if params.workshopId
        report?.map (conversation) ->
          conversation.participant.map ({member}) ->
            React.createElement(ReportContent, { \
              "conversation": (conversation),  \
              "user": (member),  \
              "key": (conversation.id + member.slug),  \
              "t": (t)
            })
      else if params.id
        React.createElement(ReportContent, { \
          "conversation": (report),  \
          "user": (user),  \
          "t": (t)
        })
      )

    )
