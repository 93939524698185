import React from 'react'
import { observer } from 'mobx-react';
import {Route, Switch} from 'react-router-dom'
import { useStore } from 'stores/stores';
import Loader from 'theme/Loader'
import Header from 'theme/Header'
import Footer from 'theme/Footer'
import Landing from './Landing'
import PurposeReport from './PurposeReport'

import "./PurposeProfile.sass"

const PurposeProfile = () => {
  const {userStore} = useStore();
  const {loading} = userStore;

  return (
    <div className='PurposeProfile page'>
      <Header />
      <main className='container' id="main">
        <Loader loading={loading} />
        <Switch>
          <Route exact path="/purpose-profile/report/:section">
            <PurposeReport />
          </Route>
          <Route exact path="/purpose-profile/report">
            <PurposeReport />
          </Route>
          <Route>
            <Landing />
          </Route>
        </Switch>
      </main>
      <Footer maxWidth="1040" />
    </div>
  )
}

export default observer(PurposeProfile);
