import {flatten} from 'ramda';
import {URL_DRIVERS} from 'theme/assets/assets'

Community = "#{URL_DRIVERS}/howCommunity.png"
Human = "#{URL_DRIVERS}/howHuman.png"
Knowledge = "#{URL_DRIVERS}/howKnowledge.png"
Structure = "#{URL_DRIVERS}/howStructure.png"
Individual = "#{URL_DRIVERS}/whoIndividual.png"
Organization = "#{URL_DRIVERS}/whoOrganization.png"
Society = "#{URL_DRIVERS}/whoSociety.png"
Harmony = "#{URL_DRIVERS}/whyHarmony.png"
Karma = "#{URL_DRIVERS}/whyKarma.png"


who = {
  Individual
  Organization
  Society
}

why = {
  Harmony
  Karma
}

how = {
  Community
  Human
  Knowledge
  Structure
}

archetypes = flatten(
  Object.keys(who).map((who) =>
    Object.keys(why).map((why) =>
      Object.keys(how).map((how) =>
        "#{who}#{why}#{how}"
      )
    )
  )
)

export {
  Individual
  Organization
  Society
  Harmony
  Karma
  Community
  Human
  Knowledge
  Structure
  who
  why
  how
  archetypes
}