import React from 'react';

const content = {
  notOpen: (
    <>
      <p>You have indicated you are unavailable for matching.</p>
      <p>When you're ready, you can change your match status by selecting the “Update Status” button above.</p>
    </>
  ),
  open: (
    <>
      <p>We're currently looking through your community to connect you with your next match.</p>
      <p>Once matched, you'll be introduced to your new connection via email.</p>
    </>
  ),
  undeclared: (
    <>
      <p>Are you ready to meet a new connection?</p>
      <p>Select the “Update Status” button above to indicate your availability.</p>
    </>
  ),
  networkTip: (
    <>
      You will see your personal network growing on Imperative here. Each time you are matched with a partner, they are
      added to your personal network.
    </>
  ),
  matchStatusTip: (
    <>
      Your match status reflects whether you are open to new matches or unavailable. Be sure to update your status when
      your availability changes.
    </>
  ),
};

export default content;
