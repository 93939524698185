import {URL_EMOJIS} from 'theme/assets/assets'

Frustrated = "#{URL_EMOJIS}/emoji_frustrated.png"
Unhappy = "#{URL_EMOJIS}/emoji_unhappy.png"
Meh = "#{URL_EMOJIS}/emoji_meh.png"
Happy = "#{URL_EMOJIS}/emoji_happy.png"
PumpedUp = "#{URL_EMOJIS}/emoji_pumped.png"

Frustrated_gray = "#{URL_EMOJIS}/grayscale/emoji_frustrated_gray.png"
Unhappy_gray = "#{URL_EMOJIS}/grayscale/emoji_unhappy_gray.png"
Meh_gray = "#{URL_EMOJIS}/grayscale/emoji_meh_gray.png"
Happy_gray = "#{URL_EMOJIS}/grayscale/emoji_happy_gray.png"
PumpedUp_gray = "#{URL_EMOJIS}/grayscale/emoji_pumped_gray.png"

Frustrated_blue = "#{URL_EMOJIS}/blue/emoji_frustrated_blue.png"
Unhappy_blue = "#{URL_EMOJIS}/blue/emoji_unhappy_blue.png"
Meh_blue = "#{URL_EMOJIS}/blue/emoji_meh_blue.png"
Happy_blue = "#{URL_EMOJIS}/blue/emoji_happy_blue.png"
PumpedUp_blue = "#{URL_EMOJIS}/blue/emoji_pumped_blue.png"

calculateLevel = (score) ->
  if score > 3 then 'PumpedUp'
  else if score > 2 then 'Happy'
  else if score > 1 then 'Meh'
  else 'Unhappy'

emojiForScore = (score) =>
  emojiMap = [
    Frustrated,
    Unhappy,
    Meh,
    Happy,
    PumpedUp
  ]
  emojiMap[score]


export {
  Frustrated, Unhappy, Meh, Happy, PumpedUp,
  Frustrated_gray, Unhappy_gray, Meh_gray, Happy_gray, PumpedUp_gray,
  Frustrated_blue, Unhappy_blue, Meh_blue, Happy_blue, PumpedUp_blue,
  calculateLevel,
  emojiForScore
}
