import React, {useState, useEffect, useMemo} from 'react';
import cx from 'classnames';
import * as ME from 'api/metrics';
import Checkbox from 'theme/Checkbox';
import Loader from 'theme/Loader';
import RSelect, {components} from 'react-select';

import './DeviceSelector.sass';

const SingleValue = (icon) => (props) => {
  return (
    <components.SingleValue {...props}>
      <div className="DeviceSelector__selectValue">
        <span className={icon} />
        <span>{props.data.label}</span>
      </div>
    </components.SingleValue>
  )
}

const Select = (props) => {
  return (
    <>
      <div className={cx(props.className, "native hidden-sm-plus")}>
        <select onChange={(e) => props.onChange(props.options.find(o => o.deviceId == e.target.value))}>
          {props.options.map((o, i) =>
            <option
              key={i}
              value={o.deviceId}
              selected={props.value && props.value.deviceId == o.deviceId}
            >
              {o.label}
            </option>
          )}
        </select>
      </div>
      <RSelect {...props} className={cx(props.className, "hidden-xs")} />
    </>
  )
}

function handleError(error) {
  console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  ME.reportIntermediateError('DeviceSelectorError: navigator.MediaDevices.getUserMedia error: ', error.message, {name: error.name});
}


const DeviceSelector = (props) => {
  const {onClose, visible = true, updateVideoDevice, updateAudioDevice, remoteAudioElement, blurBackground} = props;

  const [devices, setDevices] = useState([]);
  const [audioOutput, setAudioOutput] = useState(null);
  const [audioInput, setAudioInput] = useState(null);
  const [videoInput, setVideoInput] = useState(null);
  const [blurred, setBlurred] = useState(false);
  const [isLoadingBlurModel, setLoadingBlurModel] = useState(false);

  const [audioOutputDevices = [], audioInputDevices = [], videoInputDevices = []] = useMemo(() => {
    const inputs = [];
    const outputs = [];
    const videos = [];
    devices.forEach((device) => {
      switch (device.kind) {
        case 'audioinput':
          inputs.push({deviceId: device.deviceId, label: device.label || `Microphone ${inputs.length + 1}`});
          break;
        case 'audiooutput':
          outputs.push({deviceId: device.deviceId, label: device.label || `Speaker ${outputs.length + 1}`});
          break;
        case 'videoinput':
          videos.push({deviceId: device.deviceId, label: device.label || `Camera ${videos.length + 1}`});
      }
    });
    return [outputs, inputs, videos];
  }, [devices]);

  const gotDevices = (devices = []) => {
    setDevices(devices);
    return devices;
  }

  const start = () => {
    navigator.mediaDevices.enumerateDevices()
    .then(gotDevices)
    .catch(handleError);
  }

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
      ME.reportIntermediateError('DeviceSelectorError: enumerateDevices() not supported.');
      return;
    }

    start();
  }, []);

  useEffect(() => {
    if(!audioInput) {
      setAudioInput(audioInputDevices.find((d) => d.deviceId == 'default') || audioInputDevices[0]);
    }
    if(!audioOutput) {
      setAudioOutput(audioOutputDevices.find((d) => d.deviceId == 'default') || audioOutputDevices[0]);
    }
    if(!videoInput) {
      setVideoInput(videoInputDevices.find((d) => d.deviceId == 'default') || videoInputDevices[0]);
    }
  }, [audioOutputDevices, audioInputDevices, videoInputDevices, audioInput, audioOutput, videoInput]);


  const changeAudioInput = (option) => {
    if(!option || !option.deviceId || (audioInput && option.deviceId == audioInput.deviceId))
      return;
    updateAudioDevice(option.deviceId)
    .then(() => {
      setAudioInput(option);
      start();
    })
  }

  const changeVideoInput = (option) => {
    if(!option || !option.deviceId || (videoInput && option.deviceId == videoInput.deviceId))
      return;
    updateVideoDevice(option.deviceId)
    .then(() => {
      setVideoInput(option);
      start();
    })
  }

  const changeAudioOutput = (option) => {
    if(!option || !option.deviceId || (audioOutput && option.deviceId == audioOutput.deviceId))
      return;
    const element = remoteAudioElement;
    if(!element)
      return;
    if (typeof element.sinkId !== 'undefined') {
      const sinkId = option.deviceId;
      element.setSinkId(sinkId)
        .then(() => {
          setAudioOutput(option);
        })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
        });
    } else {
      console.warn('Browser does not support output device selection.');
    }
  }

  const toggleBlurred = (b) => {
    if(!blurBackground) return;
    setLoadingBlurModel(true);
    blurBackground(b)
    .then(() => {
      setBlurred(b);
      setLoadingBlurModel(false);
    }, () => {
      setLoadingBlurModel(false);
    })
  }

  return (
    <div className={cx("DeviceSelector__container", {visible})} onClick={onClose}>
      <div id="deviceSelector" className="DeviceSelector" onClick={(e) => e.stopPropagation()}>
        <button type="button" className="btn btn-icon DeviceSelector__close" onClick={onClose} aria-label="close settings" aria-expanded={visible}>
          <i className="far fa-times" />
        </button>
        <label>Audio Settings</label>
        <Select
          className="DeviceSelector__select"
          classNamePrefix="DeviceSelector__select"
          options={audioInputDevices}
          onChange={changeAudioInput}
          value={audioInput}
          getOptionValue={(option) => option.deviceId}
          components={{SingleValue: SingleValue("far fa-microphone")}}
          aria-hidden={!visible}
          aria-label="Select microphone"
          tabIndex={!visible && -1}
        />
        {remoteAudioElement &&
          <Select
            className="DeviceSelector__select"
            classNamePrefix="DeviceSelector__select"
            options={audioOutputDevices}
            onChange={changeAudioOutput}
            value={audioOutput}
            getOptionValue={(option) => option.deviceId}
            components={{SingleValue: SingleValue("far fa-volume")}}
            aria-hidden={!visible}
            aria-label="Select speakers"
            tabIndex={!visible && -1}
          />
        }
        <label>Video Settings</label>
        <Select
          className="DeviceSelector__select"
          classNamePrefix="DeviceSelector__select"
          options={videoInputDevices}
          onChange={changeVideoInput}
          value={videoInput}
          getOptionValue={(option) => option.deviceId}
          components={{SingleValue: SingleValue("far fa-video")}}
          aria-hidden={!visible}
          aria-label="Select camera"
          tabIndex={!visible && -1}
        />
        {blurBackground &&
          <div
            className="DeviceSelector__blur"
            onClick={() => !isLoadingBlurModel && toggleBlurred(!blurred)}
            aria-hidden={!visible}
          >
            <Loader loading={isLoadingBlurModel} size="1" />
            <label>
              <Checkbox checked={blurred} onChange={() => {}} />
              Blur background
            </label>
          </div>
        }
      </div>
    </div>
  )
}

export default DeviceSelector;
