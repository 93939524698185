import React from 'react'
import cx from 'classnames'
import {getReport} from 'api'
import {withState, withHandlers} from 'recompose'
import {composeComponent} from 'utils/react-tools'


import 'theme/btn/btn-styles.sass'
import 'theme/scaffolding/scaffolding-styles.sass'
import './DownloadReportButton.sass'

export default composeComponent 'DownloadReportButton',
  withState 'loading', 'setLoading', false
  withState 'error', 'setError', null

  withHandlers
    download: ({setLoading, name}) -> ->
      setLoading true
      getReport name
      .then(
        ->
          setLoading false
        (err) ->
          console.error err
          setLoading false
      )

  ({download, loading, error, name, onClick}) ->
    React.createElement("div", {"className": "DownloadReportButton text-center"},
      React.createElement("button", { \
        "className": (cx loading: loading, disabled: loading),  \
        "disabled": (loading),  \
        "onClick": (onClick || download)
      },
        (if loading
          React.createElement("span", null,
            React.createElement("span", {"className": "far fa-spinner-third fa-spin"}), """
            Preparing PDF
""")
        else
          'Save as PDF'
        )
      )
    )
