import React, { useContext, useMemo } from 'react'
import { observer } from 'mobx-react';
import { useStore } from 'stores/stores';
import {Link} from 'react-router-dom'
import cx from 'classnames';
import TranslationsContext from 'translations';
import {URL_ARCHETYPES} from 'theme/assets/assets'
import * as DRIVER_ICONS from 'theme/drivers'
import Loader from 'theme/Loader'
import NameCard from 'user/NameCard';

const tenureYears = {
  158: 0,
	159: 1,
	160: 2,
	161: 3,
	162: 4,
	163: 5,
	164: 6,
	165: 7,
}


const getArticleFor = (text) => {
  const vowels = ['a', 'e', 'i', 'o', 'u', 'y'];
  const firstLetter = text.slice(0, 1).toLowerCase()
  return vowels.indexOf(firstLetter) == -1 ? 'a' : 'an';
}

const Landing = () => {
  const {userStore} = useStore();
  const {user, loading} = userStore;

  const t = useContext(TranslationsContext);

  const patternString = useMemo(() => {
    if(!user) return '';
    return `${user.pattern.who}${user.pattern.why}${user.pattern.how}`
  }, [user]);

  const imperative = useMemo(() => {
    return {
      prefix: {
        my: t.find(`pattern.imperative-prefix.my`),
        your: t.find(`pattern.imperative-prefix.your`),
      },
      who: t.find(`pattern.archetypes.${patternString}.imperative.who`),
      why: t.find(`pattern.archetypes.${patternString}.imperative.why`),
      how: t.find(`pattern.archetypes.${patternString}.imperative.how`),
    }
  }, [t, patternString]);

  const archetype = useMemo(() => {
    return t.find(`pattern.archetypes.${patternString}.title`);
  }, [patternString, t]);

  const dominantDimension = ((user && user.pattern && user.pattern.dominant) || '').toLowerCase();
  const dominant = (user && user.pattern && user.pattern[dominantDimension]);
  const leadershipStyle = t.find(`pattern.leadershipStyle.dominantDriver.${dominantDimension}.name`).toLowerCase();

  return (
    <div className="PurposeProfile__page">
      <Loader loading={loading} color="white" />
      <h1 className="page-title blue" aria-label={`${imperative.prefix.your} ${imperative.who} ${imperative.why} ${imperative.how}.`}>{imperative.prefix.your} {imperative.who} {imperative.why} {imperative.how}.</h1>
      <div className="PurposeProfile__row col-landing">
        <NameCard for={user} />
        {user &&
          <div className="PurposeProfile__content">
            <div className="card-shadow">
              <img className="PurposeProfile__archetype" src={`${URL_ARCHETYPES}/${archetype}.png`} alt={archetype}/>
              <p>{t.find(`pattern.archetypes.${patternString}.description.second`)}</p>
              <Link
                to="/purpose-profile/report"
                className="btn-primary"
              >View Purpose Report</Link>
            </div>

            <div className="section-head margin-top-5">
              You are {getArticleFor(leadershipStyle)} {leadershipStyle}-driven leader
            </div>
            <div className="PurposeProfile__row col-1-1-1 margin-top-3">
              {['who', 'why', 'how'].map(dim => {
                return (
                  <div key={dim}>
                    <div className="caption-caps">
                      {t.find(`pattern.leadershipStyle.dominantDriver.${dim}.name`)}
                    </div>
                    <div className={cx("PurposeProfile__driver", dim)}>
                      <img src={DRIVER_ICONS[user.pattern[dim]]} alt={user.pattern[dim]}/>
                    </div>
                    <p>
                      <b>{t.find(`assessment.page.driver.title.${user.pattern[dim]}`)}. </b>
                      {t.find(`assessment.page.driver.result.${user.pattern[dim]}`)}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default observer(Landing);
