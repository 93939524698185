import React, {useState, useEffect, useMemo} from 'react';
import cx from 'classnames';
import {Link, Redirect, Route, Switch, useLocation} from 'react-router-dom'
import { observer } from 'mobx-react';
import {getNetworkStatistics} from 'user/requests';
import Loader from 'theme/Loader'
import Header from 'theme/Header'
import Footer from 'theme/Footer'
import { useStore } from 'stores/stores';
import Activity from './Activity'
import Invite from './Invite'
import Members from './Members';
import MemberProfile from './MemberProfile';

const Community = () => {
  const [error, setError] = useState();
  const location = useLocation();
  const [communityCount, setCommunityCount] = useState(0);

  const {userStore} = useStore();
  const {user, loading} = userStore;

  const inviteColleagueEnabled = useMemo(() => {
    return !user || (
      !!user.organizationSettings && !!user.organizationSettings.partnerConversationsSettings && !!user.organizationSettings.partnerConversationsSettings.colleagueInviteEnabled
    );
  }, [user]);

  const loadNetworkStats = (user) => {
    if(!user) return;
    return getNetworkStatistics(user.slug)
    .then((stats) => setCommunityCount(stats.communityCount), setError);
  }

  useEffect(() => {
    loadNetworkStats(user)
  }, [user]);

  const navLinks = [
    {
      title:
        <>
          Recognition
          <span className='Community__navTitleLabel'>Beta</span>
        </>,
      icon: <i className="material-icons-outlined">feed</i>,
      url: '/community/activity',
    },
    // {
    //   title: 'Dashboard',
    //   icon: <i className="material-icons-outlined">dashboard</i>,
    //   url: '/community/dashboard',
    // },
    {
      title: 'Members' + (communityCount ? ` (${communityCount})` : ''),
      icon: <i className="material-icons-outlined">people</i>,
      url: '/community/members',
    },
    inviteColleagueEnabled && {
      title: 'Invite',
      icon: <i className="material-icons-outlined">person_add</i>,
      url: '/community/invite',
    },
  ].filter(Boolean);

  return (
    <div className='Community page'>
      <Header user={user} />
      <div className="container">
        <nav className="Community__nav">
          {navLinks.map((l, i) =>
            <Link
              key={i}
              to={l.url}
              className={cx("navItem", {active:
                l.exact ? location.pathname == l.url : location.pathname.indexOf(l.url) == 0})
              }
            >
              {l.icon}
              <span className='Community__navTitle'>{l.title}</span>
            </Link>
          )}
        </nav>
        <main className="container" id="main">
          <Loader loading={loading} />
          <div className="Community__page">
            <Switch>
              <Route
                path="/community/activity"
                component={Activity}
              />
              <Route
                exact
                path="/community/dashboard"
                component={Activity}
              />
              <Route
                exact
                path="/community/members"
                component={Members}
              />
              <Route
                exact
                path="/community/members/:memberId"
                component={MemberProfile}
              />
              {inviteColleagueEnabled &&
                <Route
                  exact
                  path="/community/invite"
                  component={Invite}
                />
              }
              <Route>
                <Redirect to="/community/activity" />
              </Route>
            </Switch>
          </div>
          <Footer position="static" />
        </main>
      </div>
    </div>
  )
}

export default observer(Community);
