import React, {useState, useEffect, useMemo, useContext} from 'react'
import Tippy from '@tippyjs/react'
import cx from 'classnames';
import Loader from 'theme/Loader';
import Avatar from 'theme/Avatar';

const REACTION_LIST = [
  {
    name: 'Like',
    icon: 'twa twa-thumbs-up',
  },
  {
    name: 'Welcome',
    icon: 'twa twa-waving-hand',
  },
  {
    name: 'Celebrate',
    icon: 'twa twa-party-popper',
  }
]

const reactionsAllowedForActivity = {
  "UserCommunityJoin": ['Welcome'],
  "ConnectionsMilestone": ['Like', 'Celebrate'],
  "CompletedConversationsMilestone": ['Like', 'Celebrate'],
  "PeerConversationBreakthrough": ['Like', 'Celebrate']
}


const Reactions = (props) => {
  const {user, reactions = [], activity = {}, sendReaction, removeReaction, changeReaction} = props;
  const [loading, setLoading] = useState(false);
  const [allReactionsVisible, showAllReactions] = useState(reactions.length <= 5);

  const myReactions = reactions.filter(r => r.memberId == user.id).map(r => r.reaction);
  const allowedReactions = REACTION_LIST.filter(r => reactionsAllowedForActivity[activity.activityType].indexOf(r.name) > -1);

  const clickReaction = (reaction) => {
    setLoading(true);
    const req =
      (myReactions.length && myReactions.indexOf(reaction.name) > -1)
      ? removeReaction(activity.id, reaction.name)
      : sendReaction(activity.id, reaction.name);
    req.then(
      () => setLoading(false),
      () => setLoading(false)
    )
  }

  return (
    <div className='Community__reactions'>
      {reactions.length > 0 &&
        <div className='Community__reactionsMembers'>
          {reactions.slice(0, allReactionsVisible ? undefined : 7).map((r, i) => {
            const reactionObj = REACTION_LIST.find(l => l.name == r.reaction);
            if(reactionObj)
              return (
                <Tippy
                  key={i}
                  content={`${r.firstName} ${r.lastName}`}
                  placement="bottom"
                  className='Community__tip'
                >
                  <div className='Community__reactionsAvatar' aria-label={`${r.firstName} ${r.lastName} reacted with ${r.reaction}`}>
                    <Avatar
                      size="36"
                      for={{...r, avatars: {normal: r.avatars}}}
                      />
                    <div className={cx('Community__reactionsAvatarIcon', r.reaction)}>
                      <span className={reactionObj.icon} />
                    </div>
                  </div>
                </Tippy>
              )
          })}
          {!allReactionsVisible &&
            <div className='Community__reactionsAvatar'>
              <button
                className='Community__reactionsEllipsis btn-icon'
                onClick={() => showAllReactions(true)}
              >
                <span className='fas fa-ellipsis-h' />
              </button>
            </div>
          }
        </div>
      }
      <div className='Community__reactionsButtons'>
        <Loader loading={loading} size="xs" color="white" />
        {allowedReactions.map(r => {
          return (
            <Tippy
              key={r.name}
              disabled={loading || myReactions.indexOf(r.name) == -1}
              content="Remove reaction"
              className='Community__tip'
              placement="bottom"
            >
              <button
                className={cx("btn-tertiary-new", {active: myReactions.indexOf(r.name) > -1, blurred: loading})}
                onClick={() => clickReaction(r)}
              >
                <span className={r.icon} />
                {r.name}
              </button>
            </Tippy>
          )
        })}
      </div>
    </div>
  )
}

export default Reactions;
