import React, {useContext, useEffect, useState, useMemo, useRef} from 'react';
import TranslationsContext from 'translations';
import Tippy from '@tippyjs/react'
import Markdown from 'theme/Markdown';
import {IMG_AARON} from 'theme/assets/assets';
import * as Fields from './Fields'
import * as Toasts from './Toasts';
import Carousel from './Carousel';
import SaveAndExitBtn from './SaveAndExitBtn';
import Action from './Action';
import ActionConfirmation from './ActionConfirmation';


const NextBtn = ({disabled, children, onClick, answering}) => {
  return (
    <button
      className="btn btn_secondary btn_solid_bluePurple"
      disabled={disabled}
      onClick={onClick}
    >
      {children ||
        (answering ? <i className="fas fa-circle-notch fa-spin" /> : 'Next')
      }
    </button>
  )
};

const Question = (props) => {
  const {
    question,
    questionId,
    conversation,
    rigScore,
    user,
    saveOneWord,
    saveActionConfirmation,
    saveAnswer,
    conversationIndexInSet,
    saveActionCommitment,
    convsWithScores = []
   } = props;

  const questionRef = useRef(null);
  const t = useContext(TranslationsContext);
  const [answer, setAnswer] = useState({});
  const [answering, setAnswering] = useState(false);
  const [toasts, setToasts] = useState({});
  const isMobile = window.innerWidth < 813;

  const imAsking = useMemo(() => question && question.imAsking, [question]);
  const isActionConfirmation = question && question.actionConfirmation;
  const isOneWordBefore = question && question.oneWordBefore;
  const isOneWordAfter = question && question.oneWordAfter;
  const isAction = question && question.action;
  const isQuestion = question && !isActionConfirmation && !isOneWordBefore && !isOneWordAfter && !isAction;

  const Field = question && question.questionType && Fields[question.questionType];

  const hideToast = (toastName) => {
    setToasts({
      ...toasts,
      [toastName]: 'shown'
    })
  };

  const showToast = (toastName) => {
    setToasts({
      ...toasts,
      [toastName]: setInterval((() => hideToast(toastName)), 12000)
    });
  };

  useEffect(() => {
    setAnswer({});
  }, [questionId]);


  useEffect(() => {
    if (conversationIndexInSet == 0 && question) {
      const ans = answer ? (answer.value || '') : '';
      if (question._i == 0 && imAsking && ans.length >= 1 && !toasts.FollowUpUse)
        showToast('FollowUpUse');
      if (question._i == 0 && !imAsking && !toasts.UseOfNotes)
        showToast('UseOfNotes');
      if (question._i == 1 && imAsking && ans.length >= 1 && !toasts.GoodNotes)
        showToast('GoodNotes');
      if (question._i == 2 && imAsking && ans.length >= 1 && !toasts.ReadNotes)
        showToast('ReadNotes');
    }

    return () => {
      Object.keys(toasts).map((key) => clearInterval(toasts[key]));
    }
  }, [conversationIndexInSet, question, toasts, answer]);


  const setActionConfirmation = (args) => {
    setAnswer((answer) => ({...answer, ...args}));
  }

  const mapOneWord = ({preConvWord, postConvWord}) => {
    setAnswer({
      preConvWord,
      postConvWord
    })
  }

  const next = () => {
    setAnswering(true)
    if(isOneWordBefore || isOneWordAfter) {
      saveOneWord({...answer, forMemberId: conversation.partner.member.memberId}, question, conversation.partner.member.memberId)
      .then(() => setAnswering(false), () => setAnswering(false));
    }
    else if(isActionConfirmation) {
      saveActionConfirmation({
        performed: answer.performed,
        answer: answer.answer,
        impactedAreasIds: answer.impactedAreasIds,
        memberId: conversation.partner.member.memberId,
        sourcePeerConversationId: question.peerConversationId
      }, question, conversation.partner.member.memberId)
      .then(() => setAnswering(false), () => setAnswering(false));
    }
    else if(isAction) {
      saveActionCommitment(answer, question, conversation.partner.member.memberId)
      .then(() => setAnswering(false), () => setAnswering(false));
    }
    else if(isQuestion) {
      saveAnswer(answer.value, question, conversation.partner.member.memberId)
      .then(() => setAnswering(false), () => setAnswering(false));
    }
  }

  const answeringMember = useMemo(() =>
    imAsking ? conversation.partner.member : conversation.me.member
  , [imAsking, conversation]);

  const waitingForPartnerInfo = useMemo(() => {
    return (
      <div className="Questions__convoSheetAnswer">
        <div className="Questions__convoSheetOptionDivider" />
        <div className="Questions__convoSheetAnswerTip">(Partner will advance the conversation once done taking notes.)</div>
      </div>
    )
  }, []);

  useEffect(() => {
    questionRef.current && questionRef.current.focus();
  }, [questionId]);

  if(!conversation || !conversation.partner || !conversation.me)
    return <div />

  return (
    <div className="Questions__convoRef" ref={questionRef} tabIndex="-1">
      {isOneWordBefore &&
        <>
          <div className="Questions__convoSheetOption">
            <div className="Questions__row">
              <img src={IMG_AARON} alt="" className="Questions__rowIcon" />
              <div className="Questions__column max">
                <div className="Questions__questionOneWord">
                  {t.find("In a Word")}
                  <span className="font-s-xs">&trade;</span>
                </div>
                <Markdown
                  className="margin-bottom-3"
                  source={t.find("Before we start, take a minute to check in with each other.")}
                />
                <Markdown
                  className="Questions__questionDescription"
                  source={t.find("{{member.firstname}}, what is one word that describes how you are doing today?", answeringMember)}
                  />
                {imAsking &&
                  <Fields.Text
                    className="margin-top-2 Questions__oneWordInput"
                    value={answer.preConvWord || ''}
                    onChange={(preConvWord) => mapOneWord({preConvWord})}
                    rich={false}
                    placeholder="Type partner's one word..."
                    maxLength="20"
                  />
                }
              </div>
            </div>
            {!imAsking && waitingForPartnerInfo}
          </div>
          <div className="Questions__convoSheetBtns">
            <SaveAndExitBtn />
            {imAsking &&
              <NextBtn
                answering={answering}
                onClick={next}
                disabled={answering || !answer || !answer.preConvWord}
               />
            }
          </div>
        </>
      }
      {isOneWordAfter &&
        <>
          <div className="Questions__convoSheetOption">
            <div className="Questions__row">
              <img src={IMG_AARON} alt="" className="Questions__rowIcon"/>
              <div className="Questions__column max">
                <div className="Questions__questionOneWord">
                  {t.find("In a Word")}
                  <span className="font-s-xs">&trade;</span>
                </div>
                <Markdown
                  className="Questions__questionDescription"
                  source={t.find("{{member.firstname}}, how are you feeling as we end the conversation today?", answeringMember)}
                  />
                {imAsking &&
                  <Fields.Text
                    className="margin-top-2 Questions__oneWordInput"
                    value={answer.postConvWord || ''}
                    onChange={(postConvWord) => mapOneWord({postConvWord})}
                    rich={false}
                    placeholder="Type partner's one word..."
                    maxLength="20"
                  />
                }
              </div>
            </div>
            {!imAsking && waitingForPartnerInfo}
          </div>
          <div className="Questions__convoSheetBtns">
            <SaveAndExitBtn contentType='inAWord'/>
            {imAsking &&
              <NextBtn
                answering={answering}
                onClick={next}
                disabled={answering || !answer || !answer.postConvWord}
               />
            }
          </div>
        </>
      }
      {isActionConfirmation &&
        <>
          <div className="Questions__convoSheetOption relative">
            <ActionConfirmation
              user={user}
              answer={answer}
              setAnswer={setAnswer}
              answering={answering}
              conversation={conversation}
              rigScore={rigScore}
              imAsking={imAsking}
              t={t}
              previousCommitment={question}
            />
            {!imAsking && waitingForPartnerInfo}
          </div>
          <div className="Questions__convoSheetBtns">
            <SaveAndExitBtn />
            {imAsking &&
              <NextBtn
                answering={answering}
                onClick={next}
                disabled={answering || answer.performed == null}
              />
            }
          </div>
        </>
      }
      {isAction &&
        <Action
          user={user}
          answer={answer}
          setAnswer={setAnswer}
          answering={answering}
          conversation={conversation}
          rigScore={rigScore}
          imAsking={imAsking}
          convsWithScores={convsWithScores}
          t={t}
        >
          {({disabled}) => (
            <div className="Questions__convoSheetBtns">
              <SaveAndExitBtn />
              {imAsking &&
                <NextBtn
                  answering={answering}
                  onClick={next}
                  disabled={answering || disabled}
                />
              }
            </div>
          )}
        </Action>
      }
      {isQuestion &&
        <>
          <div className="Questions__convoSheetOption">
            <div className="Questions__convoSheetOptionQuestion">
              <Carousel
                imAsking={imAsking}
                conversation={conversation}
                question={question}
                activeColor={question.notInQuestionCount ? 'green' : 'pink'}
                t={t}
                rigScore={rigScore}
                user={user}
                convsWithScores={convsWithScores}
                toasts={{
                  followUp: {
                    visible:
                      question._i == 0 && toasts.FollowUpUse != null && toasts.FollowUpUse != 'shown' && (answer ? (answer.value || '') : '').length,
                    content: <Toasts.FollowUpUse close={() => hideToast('FollowUpUse')} />
                  }
                }}
              />
            </div>
            {imAsking &&
              <div>
                <div className="Questions__convoSheetAsking">
                  <Tippy
                    className="Questions__tip"
                    content={<Toasts.GoodNotes close={() => hideToast('GoodNotes')} />}
                    arrow
                    visible={question._i == 1 && toasts.GoodNotes != null && toasts.GoodNotes != 'shown'}
                    placement={isMobile ? 'top' : 'left'}
                  >
                    <Tippy
                      className="Questions__tip"
                      content={<Toasts.ReadNotes close={() => hideToast('ReadNotes')} />}
                      arrow
                      visible={question._i == 2 && toasts.ReadNotes != null && toasts.ReadNotes != 'shown'}
                      placement={isMobile ? 'top' : 'left'}
                    >
                      <div>
                        {Field &&
                          <Field
                            key={question.label}
                            onChange={(value) => setAnswer((answer) => ({...answer, value}))}
                            value={answer.value}
                            label={question.label}
                            max={question.optionsCount}
                            optionsCount={question.optionsCount}
                            loading={answering}
                            placeholder={`Type the 3-5 main points you hear to help ${conversation.partner.member.firstName} reflect on the conversation later...`}
                          />
                        }
                      </div>
                    </Tippy>
                  </Tippy>
                </div>
              </div>
            }
            {!imAsking && waitingForPartnerInfo}
          </div>
          <div className="Questions__convoSheetBtns">
            <SaveAndExitBtn />
            {imAsking &&
              <NextBtn
                answering={answering}
                onClick={next}
                disabled={answering || (answer && (!answer.value || answer.value == ''))}
              />
            }
          </div>
        </>
      }
    </div>
  )
};

export default Question;
