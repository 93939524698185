import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import {URL_FRAMEWORKS} from 'theme/assets/assets'

IMG_FRAMEWORK_WHO1 = "#{URL_FRAMEWORKS}/frameworks__who1.png"
IMG_FRAMEWORK_WHO2 = "#{URL_FRAMEWORKS}/frameworks__who2.png"
IMG_FRAMEWORK_WHY = "#{URL_FRAMEWORKS}/frameworks__why.png"
IMG_FRAMEWORK_HOW = "#{URL_FRAMEWORKS}/frameworks__how.png"

export default ({driver, dimension, t}) ->
  switch dimension
    when 'who'
      React.createElement("div", {"className": "LongReport__driverOverview"},
        React.createElement("h1", {"className": "LongReport__title who"}, "Impact Driver Overview"),
        React.createElement("p", {"className": "LongReport__text LongReport__text_firstBig"}, """
          Boost your awareness of who you want to impact compared to others by thinking about
          how you can help each person work with what drives them? (ex: recognize, incentivize
          and inspire them through what drives them)
"""),
        React.createElement("img", {"src": (IMG_FRAMEWORK_WHO1), "className": "LongReport__framework LongReport__frameworkWho1", "alt": "who 1"}),
        React.createElement("img", {"src": (IMG_FRAMEWORK_WHO2), "className": "LongReport__framework LongReport__frameworkWho2", "alt": "who 2"}),
        React.createElement("div", {"className": "LongReport__driverOverviewWho"},
          React.createElement("p", {"className": "LongReport__driverOverviewGroup who"}, """
            Change begins with the individual. I can improve
            people’s lives by meeting their needs and finding
            solutions that overcome their challenges. I like to see
            a person’s eyes light up.
"""),
          React.createElement("p", {"className": "LongReport__driverOverviewGroup who"}, """
            By enabling an organization or team to succeed, I
            am able to touch the lives of many. I like to see goals
            and metrics that show the impact at the team or
            organizational level.
"""),
          React.createElement("p", {"className": "LongReport__driverOverviewGroup who"}, """
            A healthy society will result in healthy individuals
            and healthy organizations. Therefore, my focus is on
            how work can impact Society at large. Even small
            changes, when clever, can have massive impact.
"""),
          React.createElement("span", {"className": (cx "LongReport__driverOverviewYouWho", driver)}, """
            YOU
""")
        )
      )
    when 'why'
      React.createElement("div", {"className": "LongReport__driverOverview"},
        React.createElement("h1", {"className": "LongReport__title why"}, "Values Driver Overview"),
        React.createElement("p", {"className": "LongReport__text LongReport__text_firstBig"}, """
          By relating to people based on why they do what they do, you begin to understand
          the moral foundation of their purpose which informs how they operate in the world.
          This awareness allows you to compromise even when your points of view seem far apart.
"""),
        React.createElement("img", {"src": (IMG_FRAMEWORK_WHY), "className": "LongReport__framework LongReport__frameworkWhy", "alt": "why"}),
        React.createElement("div", {"className": "LongReport__driverOverviewWhy"},
          React.createElement("div", {"className": "LongReport__driverOverviewGroup why"},
            React.createElement("div", {"className": "LongReport__driverOverviewText"}, """
              Individualism""", React.createElement("br", null), """
              =""", React.createElement("br", null), """
              Individual interests and desires trump collective ones
"""),
            React.createElement("div", {"className": "LongReport__driverOverviewText bold"}, """
              THEY VIEW THE WORLD THROUGH THE LENS OF
"""),
            React.createElement("div", {"className": "LongReport__driverOverviewText"}, """
              Collectivism""", React.createElement("br", null), """
              =""", React.createElement("br", null), """
              Shared interest and the common good eclipse individual preferences
""")
          ),
          React.createElement("div", {"className": "LongReport__driverOverviewGroup why"},
            React.createElement("div", {"className": "LongReport__driverOverviewText"}, """
              Struggle, hard work, resourcefulness
"""),
            React.createElement("div", {"className": "LongReport__driverOverviewText bold"}, """
              THEY BELIEVE THAT PROGRESS""", React.createElement("br", null), """IS ACHIEVED THROUGH
"""),
            React.createElement("div", {"className": "LongReport__driverOverviewText"}, """
              Equality, fairness, equal access
""")
          ),
          React.createElement("div", {"className": "LongReport__driverOverviewGroup why"},
            React.createElement("div", {"className": "LongReport__driverOverviewText"}, """
              Challenging, driven environments that reward
              bold ideas, make them stretch and involve quick action
"""),
            React.createElement("div", {"className": "LongReport__driverOverviewText bold"}, """
              THEY WORK""", React.createElement("br", null), """BEST IN
"""),
            React.createElement("div", {"className": "LongReport__driverOverviewText"}, """
              Safe, inclusive environments that reward bold ideas
              and action and encourage everyone’s participation
""")
          ),
          React.createElement("span", {"className": (cx "LongReport__driverOverviewYouWhy", driver)}, """
            YOU
""")
        )
      )
    when 'how'
      React.createElement("div", {"className": "LongReport__driverOverview"},
        React.createElement("h1", {"className": "LongReport__title how"}, "Craft Driver Overview"),
        React.createElement("p", {"className": "LongReport__text LongReport__text_firstBig"}, """
          We all solve problems in one of four different ways. By understanding other
          approaches, you begin to realize the benefits derived from diverse thinking when
          solving a difficult problem. Integrating all four problem-solving techniques generates
          greater impact. Recognize what you bring to the table and find who you need to partner
          with to achieve progress.
"""),
        React.createElement("img", {"src": (IMG_FRAMEWORK_HOW), "className": "LongReport__framework LongReport__frameworkHow", "alt": "how"}),
        React.createElement("div", {"className": "LongReport__driverOverviewHow"},
          React.createElement("p", {"className": "LongReport__driverOverviewGroup how right"}, """
            Engaging""", React.createElement("br", null), "with people and", React.createElement("br", null), "exploring contexts", React.createElement("br", null), "to determine needs", React.createElement("br", null), """
            and provide authentic""", React.createElement("br", null), "solutions.", React.createElement("br", null), React.createElement("br", null), """
            Their problem-solving""", React.createElement("br", null), """approach is intuitive.
"""),
          React.createElement("p", {"className": "LongReport__driverOverviewGroup how left"}, """
            Investigating""", React.createElement("br", null), "new theories by", React.createElement("br", null), "exploring possible", React.createElement("br", null), """
            solutions through""", React.createElement("br", null), "rigorous research and", React.createElement("br", null), "analysis.", React.createElement("br", null), React.createElement("br", null), """
            Their problem-solving""", React.createElement("br", null), "approach is research", React.createElement("br", null), """based.
"""),
          React.createElement("p", {"className": "LongReport__driverOverviewGroup how right"}, """
            Empowering others""", React.createElement("br", null), "to work together by", React.createElement("br", null), "leveraging collective", React.createElement("br", null), """
            capabilities to tackle""", React.createElement("br", null), "obstacles.", React.createElement("br", null), React.createElement("br", null), """
            Their problem-""", React.createElement("br", null), "solving approach is", React.createElement("br", null), """collaborative.
"""),
          React.createElement("p", {"className": "LongReport__driverOverviewGroup how left"}, """
            Designing and""", React.createElement("br", null), "implementing the tools", React.createElement("br", null), "and systems necessary", React.createElement("br", null), """
            to take on a challenge.""", React.createElement("br", null), React.createElement("br", null), """
            Their problem-solving""", React.createElement("br", null), "approach is process", React.createElement("br", null), """based.
"""),
          React.createElement("span", {"className": (cx "LongReport__driverOverviewYouHow", driver)}, """
            YOU
""")
        )
      )
