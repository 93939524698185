import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'theme/Modal';
import "./SaveAndExitBtn.scss";

const ModalContentInAWord = () => (
  <p className="margin-bottom-2">
    There’s a little bit more to do - check in with your partner <span className="no-wrap">In a Word&#8482;</span> before exiting to complete your time together.
  </p>
);

const ModalContentDefault = () => (
  <>
    <p>
      Are you sure you want to exit this conversation?
    </p>
    <p className="text-center">
      You can always come back to it later.
    </p>
  </>
);

const ExitButton = ({text, solid}) => (
  <Link to="/" className={`btn btn_secondary ${solid ? 'btn_solid_bluePurple' : 'btn_outlined_bluePurple'}`}>
    {text}
  </Link>
);

const RemainButton = ({text, solid, onClick}) => (
  <button onClick={onClick} className={`btn btn_secondary ${solid ? 'btn_solid_bluePurple' : 'btn_outlined_bluePurple'}`}>
    {text}
  </button>
);

const modalContents = (onRemainClick) => ({
  default: {
    title: 'Warning',
    content: ModalContentDefault(),
    leftButton: <RemainButton text='No' onClick={onRemainClick}/>,
    rightButton: <ExitButton text='Yes' solid/>
  },
  inAWord: {
    title: 'Leaving so soon?',
    content: ModalContentInAWord(),
    leftButton: <ExitButton text='EXIT CONVERSATION'/>,
    rightButton: <RemainButton text='RETURN TO CONVERSATION' solid onClick={onRemainClick}/>
  }
});

const SaveAndExitBtn = ({contentType='default'}) => {
  const [exitModal, showExitModal] = useState(false);

  const handleRemainClick = () => showExitModal(false)
  const modalContent = modalContents(handleRemainClick)[contentType];

  return (
    <>
      <button
        className="btnLink btnLink_secondary btnLink_bluePurple"
        onClick={() => showExitModal(true)}
      >
        Save and exit conversation
      </button>
      <Modal
        isOpen={exitModal}
        close={handleRemainClick}
        className="confirm-modal SaveAndExit__Modal"
      >
        <h2 className="title">
          {modalContent.title}
        </h2>
        {modalContent.content}
        <div className="btns">
          {modalContent.leftButton}
          {modalContent.rightButton}
        </div>
      </Modal>
    </>
  )
};

export default SaveAndExitBtn;
