import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withState} from 'recompose'
import cx from 'classnames'

import './Video.sass'


export default composeComponent 'Notification',
  withState 'visible', 'setVisible', true

  ({children, type, visible, setVisible, sticky, closeButton, className}) ->
    unless visible
      return null

    React.createElement("div", {"className": (cx "Video__notification", type, sticky: sticky, className)},
      (children),

      (if closeButton?
        React.createElement("div", {"className": "Video__notificationClose"},
          (React.cloneElement(closeButton))
        )
      )
    )
