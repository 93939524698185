import moment from 'moment-timezone';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import RSelect from 'react-select';
import * as ME from 'api/metrics';
import ScheduleNextConversations from 'conversations/Questions/ScheduleNextConversations';
import { updateConversation } from 'conversations/requests';
import Loader from 'theme/Loader';
import Modal, * as M from 'theme/Modal';
import Toast from 'theme/Toast';
import { IMG_AARON } from 'theme/assets/assets';
import * as states from 'utils/states';
import TranslationsContext from 'translations';
import './SetCard.sass';

const REASONS = [
  { label: 'Work Conflict', value: 'Work Conflict' },
  { label: 'Personal Scheduling Conflict', value: 'Personal Scheduling Conflict' },
  { label: 'Peer Coach Unavailable', value: 'Peer Coach Unavailable' },
  { label: 'Need to Pause Peer Coaching', value: 'Need to Pause Peer Coaching' },
  { label: 'Other', value: 'Other' },
];

const stopReschedulingState = states.Finished;

const DashboardScheduler = ({ groupedSetsByPeer = [], user, scheduleUpdated }) => {
  const t = useContext(TranslationsContext);
  const history = useHistory();
  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);
  const actionName = useMemo(() => query.get('action'), [query]);
  const conversationIdToModify = useMemo(() => query.get('id'), [query]);

  const [updating, setUpdating] = useState(false);
  const [conversationToCancel, showCancellation] = useState(null);
  const [cancellationReason, setCancellationReason] = useState(null);
  const [toastObj, showToast] = useState({ visible: false });
  const [error, setError] = useState();
  const [nextConversationsDates, setNextConversationsDates] = useState({});

  const changeNextConvDate = (conversationId, scheduleDate, scheduleTimezone) => {
    setNextConversationsDates((prevState) => ({ ...prevState, [conversationId]: { scheduleDate, scheduleTimezone } }));
  };

  const availableSets = useMemo(() => {
    return groupedSetsByPeer.reduce((arr, { sets }) => [].concat(arr, sets), []);
  }, [groupedSetsByPeer]);

  const reschedulingSet = useMemo(() => {
    if (actionName == 'reschedule' && conversationIdToModify) {
      const set = availableSets.find((s) => s.convs.find((c) => c.id == conversationIdToModify));
      return set;
    } else return null;
  }, [actionName, conversationIdToModify, availableSets]);

  const conversationsToSchedule = useMemo(() => {
    if (!reschedulingSet || !reschedulingSet.convs) return [];
    if (actionName == 'reschedule' && conversationIdToModify) {
      return reschedulingSet.convs
        .filter((c) => states.isBefore(c.state, stopReschedulingState))
        .map((c) => ({ ...c, orderIndex: c.order ?? c.convIInSet + 1, label: c.convLabel }));
    }
  }, [reschedulingSet, actionName, conversationIdToModify]);

  const scheduleConversations = useCallback(() => {
    ME.reportStateChange('Dashboard: scheduling conversations');
    setUpdating('reschedule');
    setError(null);

    const nextConvsUpdated = Object.keys(nextConversationsDates)
      .filter((k) => schedulePicked(nextConversationsDates[k]))
      .map((id) => {
        const convObj = conversationsToSchedule.find((c) => c.id == id);
        const dto = {
          scheduleTo: nextConversationsDates[id].scheduleDate,
          timezone: nextConversationsDates[id].scheduleTimezone,
          reschedule: Boolean(
            convObj && convObj.scheduleDate && convObj.scheduleDate != nextConversationsDates[id].scheduleDate
          ),
        };
        ME.reportStateChange('Dashboard: scheduling conversation', { conversationId: id });
        return updateConversation({ id }, dto).then(
          () => {
            ME.reportStateChange('Dashboard: conversation scheduled', { conversationId: id });
            return {
              ...convObj,
              ...dto,
              scheduleDate: dto.scheduleTo,
            };
          },
          (error) => {
            ME.reportIntermediateError('Dashboard: conversation scheduling error', error, { conversationId: id });
            return Promise.reject(error);
          }
        );
      });

    return Promise.all(nextConvsUpdated).then(
      (convsUpdated) => {
        scheduleUpdated().then(() => {
          setUpdating(false);
          showToast({
            visible: true,
            name: 'schedule',
            conversations: convsUpdated,
          });
          closeScheduler();
        });
      },
      (error) => {
        setUpdating(false);
        setError(error);
      }
    );
  }, [nextConversationsDates, conversationsToSchedule]);

  const cancelConversation = useCallback(
    (conversation) => {
      if (!cancellationReason) return;
      setUpdating('cancel');
      return updateConversation(conversation, { cancellationReason: cancellationReason.value }).then(
        () => {
          scheduleUpdated().then(() => {
            setUpdating(false);
            showToast({ visible: true, name: 'cancel', conversation });
            closeScheduler();
            showCancellation(null);
            setCancellationReason(null);
          });
        },
        (error) => {
          setError(error);
          setUpdating(false);
        }
      );
    },
    [cancellationReason]
  );

  const closeScheduler = () => {
    history.push(location.pathname);
    setNextConversationsDates({});
  };

  const schedulePicked = (obj) => {
    return obj && obj.scheduleDate && obj.scheduleTimezone;
  };

  const convsScheduledCount = useMemo(() => {
    return Object.keys(nextConversationsDates).filter((k) => schedulePicked(nextConversationsDates[k])).length;
  }, [nextConversationsDates]);

  const partner = useMemo(() => {
    return (
      conversationsToSchedule[0] && conversationsToSchedule[0].partner && conversationsToSchedule[0].partner.member
    );
  }, [conversationsToSchedule]);

  if (!groupedSetsByPeer || !user) return null;

  return (
    <>
      <Modal
        onClose={() => closeScheduler()}
        isOpen={Boolean(reschedulingSet && actionName && conversationIdToModify)}
        className="SetCardNew__scheduler"
      >
        <Loader loading={updating == 'reschedule'} />
        {conversationsToSchedule.length == 0 && (
          <>
            <div className="SetCardNew__schedulerDescription">
              <img src={IMG_AARON} alt="" className="SetCardNew__schedulerDescription-img" />
              <div className="SetCardNew__schedulerDescription-text">
                <h3>Scheduling is not available for this conversation</h3>
                <p>The conversation has been archived.</p>
              </div>
            </div>
            <div className="SetCardNew__schedulerBtns">
              <span />
              <div>
                <button onClick={() => closeScheduler()} className="btn btn_secondary btn_solid_bluePurple">
                  Cancel
                </button>
              </div>
            </div>
          </>
        )}
        {conversationsToSchedule.length > 0 && (
          <>
            <div className="SetCardNew__schedulerDescription">
              <img src={IMG_AARON} alt="" className="SetCardNew__schedulerDescription-img" />
              <div className="SetCardNew__schedulerDescription-text">
                <h3>Conversation Scheduler</h3>
                <p>
                  Awesome! Let's make sure your conversations are scheduled with {partner.firstName}. We recommend
                  bi-weekly conversations. Once you review and press schedule, you and your partner will receive
                  calendar invitations.
                </p>
              </div>
            </div>
            {Boolean(reschedulingSet && actionName && conversationIdToModify) && (
              <ScheduleNextConversations
                user={user}
                partner={partner}
                conversations={conversationsToSchedule}
                nextConversationsDates={nextConversationsDates}
                onChange={changeNextConvDate}
                initDates={setNextConversationsDates}
                cancelConversation={showCancellation}
              />
            )}
            <div className="SetCardNew__schedulerBtns">
              {conversationsToSchedule.length == 1 && conversationsToSchedule[0].scheduleDate ? (
                <button
                  className="btnLink btnLink_secondary btnLink_bluePurple"
                  onClick={() => showCancellation(conversationsToSchedule[0])}
                >
                  Cancel Conversation
                </button>
              ) : (
                <span />
              )}
              <div>
                <button onClick={() => closeScheduler()} className="btn btn_secondary btn_solid_bluePurple">
                  Exit
                </button>
                <button
                  className="btn btn_secondary btn_solid_bluePurple"
                  disabled={updating == 'reschedule' || !convsScheduledCount}
                  onClick={scheduleConversations}
                >
                  {updating == 'reschedule' ? (
                    <i className="fas fa-circle-notch fa-spin" />
                  ) : (
                    <>Schedule {convsScheduledCount > 1 ? `(${convsScheduledCount}) conversations` : ''}</>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
      {reschedulingSet && actionName && conversationIdToModify && (
        <Modal
          close={() => showCancellation(false)}
          isOpen={Boolean(conversationToCancel)}
          className="SetCardNew__scheduler"
        >
          <Loader loading={updating == 'cancel'} />
          <div className="SetCardNew__schedulerDescription">
            <div className="SetCardNew__schedulerDescription-text">
              <h3 className="SetCardNew__schedulerCancellation">
                Discontinue peer coaching with {conversationToCancel && conversationToCancel.partner.member.firstName}?
              </h3>
              <p>
                Cancelling your conversation with your peer coach indicates that you need to discontinue peer coaching
                for the foreseeable future. If that is the case, please provide your reason for discontinuing below. An
                updated calendar event will be sent to you and your partner indicating that the conversation has been
                cancelled.
              </p>
              <p>
                Remember that you can always reschedule instead. It’s best to communicate directly with your partner
                about your timing if you’ll be out of the office on vacation or extended leave.
              </p>
            </div>
          </div>
          <div>
            <span className="label">Reason for cancellation</span>
            <RSelect
              className="Form__select"
              classNamePrefix="Form__select"
              options={REASONS}
              onChange={(o) => setCancellationReason(o)}
              value={cancellationReason}
              placeholder="Select Reason"
            />
          </div>
          <div className="text-center margin-top-3">
            <button
              className="btn btn_secondary btn_solid_bluePurple"
              disabled={!cancellationReason}
              onClick={() => cancelConversation(conversationToCancel)}
            >
              Confirm Cancellation
            </button>
          </div>
        </Modal>
      )}
      {toastObj && (
        <Toast
          className="SetCardNew__toast"
          type="info"
          visible={toastObj.visible}
          close={() => showToast((t) => ({ ...t, visible: false }))}
          timeout={5000}
          title={false}
          position="top"
          autoClose={false}
        >
          {toastObj.name == 'cancel' ? (
            <i className="fal fa-calendar-times" title="Info" />
          ) : (
            <i className="fal fa-calendar" title="Info" />
          )}
          <div className="SetCardNew__toastContent">
            {toastObj.name == 'schedule' && (
              <>
                {toastObj.conversations.length > 1 ? (
                  <>
                    You have scheduled {toastObj.conversations.length} conversations with{' '}
                    {toastObj.conversations[0] && toastObj.conversations[0].partner.member.firstName}. Calendar invites
                    have been sent to both of you.
                  </>
                ) : (
                  <>
                    You have scheduled{' '}
                    {toastObj.conversations[0] &&
                      t.find(`peerconversation.conversation.${toastObj.conversations[0].convLabel}.title`)}{' '}
                    with {toastObj.conversations[0] && toastObj.conversations[0].partner.member.firstName} for{' '}
                    {moment(toastObj.conversations[0].scheduleDate).format('MMMM D, hh:mm A')}. A calendar invite has
                    been sent to both of you.
                  </>
                )}
              </>
            )}
            {toastObj.name == 'reschedule' && <>An updated calendar invite has been sent to both of you.</>}
            {toastObj.name == 'cancel' && (
              <>
                You have cancelled{' '}
                {toastObj.conversation &&
                  t.find(`peerconversation.conversation.${toastObj.conversation.convLabel}.title`)}{' '}
                with {toastObj.conversation && toastObj.conversation.partner.member.firstName}. An updated calendar
                invite has been sent to both of you.
              </>
            )}
          </div>
        </Toast>
      )}
    </>
  );
};

export default DashboardScheduler;
