import 'vanilla-cookieconsent';
import { useEffect } from 'react';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import config from './config';

export default function CookieConsent() {
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CC = window.initCookieConsent();
      window.CC.run(config);
    }
  }, []);

  return null;
}
