import React, {useState} from 'react';
import cx from 'classnames';
import {Collapse} from 'react-collapse';

const Card = (props) => {
  const [expanded, expand] = useState(() => props.expanded);

  return (
    <div className={cx("Conversation__card", props.className, {expanded})}>
      <div className="Conversation__cardHeading flex">
        <div className="Conversation__cardHeading-title">
          {props.title}
        </div>
        {expanded &&
          <div className="Conversation__cardHeading-buttons">
            {props.buttons}
          </div>
        }
        <button
          className="btn-icon Conversation__cardHeading-expand"
          onClick={() => expand(!expanded)}
        >
          <i className={cx("fal", expanded ? "fa-chevron-up" : "fa-chevron-down")} />
        </button>
      </div>
      <Collapse
        className={cx("Conversation__cardBody", {expanded})}
        isOpened={expanded}
      >
        {props.children}
      </Collapse>
    </div>
  )
}

export default Card;
