import React, {useState, useEffect, useMemo, useContext, useRef} from 'react'
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import {uploadAvatar} from 'settings/requests';
import * as ME from 'api/metrics'
import {fetchUser} from 'auth/requests'
import Loader from 'theme/Loader';
import TranslationsContext from 'translations';
import { ordinal, articleFor } from 'utils/utils';
import * as DRIVER_ICONS from 'theme/drivers'
import { URL_ARCHETYPES } from 'theme/assets/assets';
import NameCard from 'user/NameCard';
import NetworkGraph from './NetworkGraph';
import { fetchMember } from './requests';

import './Community.sass';

const formatDate = (date) => {
  if(!date) return '';
  return dayjs(date).format('MM/DD/YY');
}

const milestones = {
  "UserCommunityJoin": () => "Joined Imperative",
  "CompletedConversationsMilestone": (e) => {
    if(e.data.conversationCount == 1) {
      return (
        <>
          Completed First Conversation
        </>
      )
    } else if (e.data.conversationCount > 1) {
      return (
        <>
          Completed {ordinal(e.data.conversationCount)} Conversation
        </>
      )
    }
  },
  "PeerConversationBreakthrough": () => "Had a Breakthrough Conversation"
}


const MemberProfile = () => {
  const t = useContext(TranslationsContext);
  const {memberId} = useParams();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [member, setMember] = useState();
  const profileRef = useRef();

  const getUser = () => {
    return fetchUser()
    .then(
      (u) => {
        setUser(u);
        return u;
      },
      (error) => ME.reportIntermediateError("community-member-loading_user-error", error)
    );
  }

  useEffect(() => {
    setLoading(true);
    getUser()
    .then((u) => {
      fetchMember(memberId)
      .then(
        (data) => {
          setMember(data);
          setLoading(false);
          profileRef.current && profileRef.current.focus();
          window.scrollTo(0, 0);
        }, (err) => {
          setError(err);
          setLoading(false);
        }
      );
    });
  }, [memberId]);

  const patternString = useMemo(() => {
    if(member && member.pattern) {
      return `${member.pattern.who}${member.pattern.why}${member.pattern.how}`;
    }
  }, [member]);

  const dominantDimension = useMemo(() => {
    if(member && member.pattern)
      return (member.pattern.dominant || '').toLowerCase();
    return '';
  }, [member]);

  const leadershipStyle = useMemo(() => {
    return t.find(`pattern.leadershipStyle.dominantDriver.${dominantDimension}.name`).toLowerCase();
  }, [t, dominantDimension]);

  const archetype = useMemo(() => {
    if(patternString) {
      return t.find(`pattern.archetypes.${patternString}.title`);
    }
  }, [t, patternString]);

  const purpose = useMemo(() => {
    if(patternString) {
      return (
        t.find(`pattern.archetypes.${patternString}.imperative.who`) + " " +
        t.find(`pattern.archetypes.${patternString}.imperative.why`) + " " +
        t.find(`pattern.archetypes.${patternString}.imperative.how`)
      );
    }
  }, [patternString, t]);

  const jobFunction = useMemo(() => {
    if(member && member.jobFunctionText) return member.jobFunctionText;
    if(member && member.jobFunction && member.jobFunction.toLowerCase() !== 'other') {
      return t.find(`dictionaries.jobfunction.${member.jobFunction}.name`);
    }
  }, [member]);

  const isUser = useMemo(() => {
    if(user) {
      return memberId == user.id;
    }
  }, [memberId, user]);

  return (
    <div className="Community__memberProfile">
      <Loader loading={loading} color="white" />
        <Link to="/community/members" title='Back to the list' className='btn-creadcrumbs'>
          <i className="far fa-chevron-left" aria-hidden />
          back to the list
        </Link>
      {error &&
        <p className='margin-top-5 Community__memberProfileError error'>
          Something went wrong. Cannot load member profile.
        </p>
      }
      {member && user &&
        <div className="Community__memberProfileContent relative" aria-label={`${member.firstName} ${member.lastName} profile`} tabIndex="-1" ref={profileRef}>
          <NameCard
            for={member}
            flow="row"
            withoutButtons={user.id !== member.memberId}
            uploadAvatar={user.id == member.memberId && uploadAvatar}
          />
          {member && archetype &&
            <div className='Community__memberProfilePurpose'>
              <div className='Community__memberProfileArchetype'>
                <img src={`${URL_ARCHETYPES}/${archetype}.png`} alt={archetype} />
              </div>
              <div className='card'>
                {!member.companyTenure &&
                  `${member.firstName} is working at ${member.organizationName}`
                }
                {member.companyTenure &&
                  `${member.firstName} has worked at ${member.organizationName} for ${member.companyTenureText || member.companyTenure}`
                }
                {!jobFunction && '.'}
                {jobFunction &&
                  ` and is in ${jobFunction}.`
                }
                {purpose &&
                  <span>{" "}Their purpose is {purpose}.</span>
                }
              </div>
            </div>
          }
          <div className='Community__memberProfileSection'>
            {member.connections && member.connections.length > 0 &&
              <>
                <h3 className='section-head'>{member.firstName}'{member.firstName.slice(-1) == 's' ? "": "s"} connections</h3>
                <NetworkGraph member={member} />
              </>
            }
          </div>
          {(member.pattern || (member.activities && member.activities.length > 0)) &&
            <div className='Community__memberProfileSection'>
              {member.pattern &&
                <>
                  <div className="section-head">
                    {isUser ? 'You' : 'They'} are {articleFor(leadershipStyle)} {leadershipStyle}-driven leader
                  </div>
                  <div className="Community__memberProfileDrivers margin-top-3">
                    {['who', 'why', 'how'].map(dim => {
                      return (
                        <div key={dim}>
                          <div className="caption-caps">
                            {t.find(`pattern.leadershipStyle.dominantDriver.${dim}.name`)}
                          </div>
                          <div className={`Community__memberProfileDriver ${dim}`}>
                            <img src={DRIVER_ICONS[member.pattern[dim]]} alt="" />
                          </div>
                          <p className='margin-top-3'>
                            <b>{t.find(`assessment.page.driver.title.${member.pattern[dim]}`)}. </b>
                            {isUser
                              ? t.find(`assessment.page.driver.result.${member.pattern[dim]}`)
                              : t.find(`member.profile.driver.${member.pattern[dim]}.description`)
                            }
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </>
              }
              {member.activities && member.activities.length > 0 &&
                <>
                  <h3 className='section-head margin-top-3'>Milestones</h3>
                  <div className='Community__memberProfileMilestones'>
                    {member.activities.sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt))).map(a => milestones[a.activityType] &&
                      <React.Fragment key={a.id}>
                        <span className='Community__memberProfileMilestonesDate'>{formatDate(a.createdAt)}</span>
                        <span className='Community__memberProfileMilestonesName'>{milestones[a.activityType](a)}</span>
                      </React.Fragment>
                    )}
                  </div>
                </>
              }
            </div>
          }
        </div>
      }
    </div>
  )
}

export default MemberProfile;
