import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import cx from 'classnames';
import * as ME from 'api/metrics';
import { fetchUser } from 'auth/requests';
import { updateUser } from 'settings/requests';
import Loader from 'theme/Loader';
import { loadTimezones } from 'utils/react-tools';
import { getAllowedTimezones } from 'utils/timezones';
import CollapsibleSection from './CollapsibleSection';
import './Settings.sass';

const TIMEZONES = getAllowedTimezones(loadTimezones());

const Option = (props) => {
  const splittedDescription = props.data.label.split(')');
  return (
    <components.Option {...props} className={cx('Settings__selectOption', props.className)}>
      <span className="Settings__selectOffset">{splittedDescription[0]})</span>
      <div className="Settings__selectOptionName">{splittedDescription[1]}</div>
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  const splittedDescription = props.data.label.split(')');
  return (
    <components.SingleValue {...props} className={cx('Settings__selectOption', props.className)}>
      <span className="Settings__selectOffset">{splittedDescription[0]})</span>
      <div className="Settings__selectOptionName">{splittedDescription[1]}</div>
    </components.SingleValue>
  );
};

const Region = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [sectionOpened, openSection] = useState(false);
  const [timezone, setTimezone] = useState((user && user.timeZone) || moment.tz.guess());

  const getUser = () => {
    return fetchUser().then(setUser, (error) =>
      ME.reportIntermediateError('settings-region-loading_user-error', error)
    );
  };
  useEffect(() => {
    setLoading(true);
    getUser().then(
      () => setLoading(false),
      () => setLoading(false)
    );
  }, []);

  const save = useCallback(() => {
    setLoading(true);
    return updateUser({ timeZone: timezone })
      .then(getUser)
      .then(() => openSection(false))
      .then(
        () => setLoading(false),
        () => setLoading(false)
      );
  }, [timezone]);

  const pickedTimezone = TIMEZONES.find((t) => t.name === timezone);
  const mappedTimezones = TIMEZONES.map((o) => ({
    label: o.description,
    value: o.name,
    offset: o.currentTimeOffsetInMinutes / 60,
  }));
  const selectedTimezone = mappedTimezones.find((t) => pickedTimezone && t.value === pickedTimezone.name);

  const currentTimeZone = (user && user.timeZone) || moment.tz.guess();

  return (
    <div className="Settings__region">
      <div className="Settings__title">
        <h1 className="page-title">Region</h1>
      </div>

      <div className="relative">
        <Loader loading={loading} color="white" />
        {user && (
          <div className="card">
            <CollapsibleSection
              label="Time zone"
              opened={sectionOpened}
              open={() => openSection(true)}
              close={() => openSection(false)}
            >
              {!sectionOpened ? (
                <p>{user.timeZone || 'You have not set your time zone yet'}</p>
              ) : (
                <div>
                  <p>
                    Imperative uses your time zone to send notification emails, for matching you with peer coaches, for
                    times in your activity feeds, and for reminder nudges. Your current time zone is{' '}
                    <b>{currentTimeZone}</b>.
                  </p>
                  <Select
                    className={cx('react-select Settings__select Settings__regionSelect', {
                      hasValue: timezone !== null,
                    })}
                    classNamePrefix="Settings__select react-select"
                    value={selectedTimezone}
                    onChange={(o) => setTimezone(o.value)}
                    options={mappedTimezones}
                    placeholder="Select a timezone"
                    components={{ Option, SingleValue }}
                    aria-label="Time zone"
                  />
                  <button className="btn-primary" disabled={!user || timezone == user.timeZone} onClick={save}>
                    Save time zone
                  </button>
                </div>
              )}
            </CollapsibleSection>
          </div>
        )}
      </div>
    </div>
  );
};

export default Region;
