import React from 'react'
import PT from 'prop-types'
import * as _ from 'ramda'
import cx from 'classnames'
import t from 'translations'
import griffith from './custom-griffith'

import Markdown from './Markdown'
import Quote from './Quote'


export default ({org, t}) ->
  React.createElement("section", {"className": "ShortReport__overview"},
    React.createElement("h2", {"className": "ShortReport__subtitle"}, (t.find 'report.shortReport.overview.subtitle')),
    React.createElement("div", {"className": "ShortReport__row"},
      (if org.code.includes 'griffith'
        code = JSON.parse(sessionStorage.getItem('imperative-language-user-data'))?.code || JSON.parse(localStorage.getItem('imperative-language-data'))?.code || 'en'

        React.createElement("div", {"className": "ShortReport__columnOverview ShortReport__text_margin-top"},
          React.createElement("div", null,
            React.createElement("div", {"className": "ShortReport__quote"},
              React.createElement(Markdown, {"source": (griffith[code]?.quote), "className": "ShortReport__quoteText"}),
              (if griffith[code]?.author
                React.createElement(Markdown, {"source": (griffith[code]?.author), "className": "ShortReport__quoteAuthor"})
              )
            ),
            React.createElement(Markdown, {"className": "ShortReport__purposeOverview", "source": (griffith[code]?.overview)})
          ),
          React.createElement("div", { \
            "style": (backgroundImage: "url(#{org?.smallLogoUrl})"),  \
            "alt": "Organization logo",  \
            "className": "ShortReport__logo"
          })
        )
      else
        React.createElement("div", {"className": "ShortReport__columnOverview ShortReport__text_margin-top"},
          (if org?.purposeOverview?.overview?.length > 0
            React.createElement("div", null,
              (if org?.purposeOverview?.quote
                React.createElement("div", {"className": "ShortReport__quote"},
                  React.createElement(Markdown, {"source": (t.findCustomQuote "org.custom.purpose.quote.quote", org, 'quote'), "className": "ShortReport__quoteText"}),
                  React.createElement(Markdown, {"source": (t.findCustomQuote "org.custom.purpose.quote.author", org, 'author'), "className": "ShortReport__quoteAuthor"})
                )
              ),
              React.createElement(Markdown, {"className": "ShortReport__purposeOverview", "source": (t.findCustomQuote "org.custom.purpose.overview", org, 'overview')})
            )
          ),
          React.createElement("div", { \
            "style": (backgroundImage: "url(#{org?.smallLogoUrl})"),  \
            "alt": "Organization logo",  \
            "className": "ShortReport__logo"
          })
        )
      )
    )
  )
