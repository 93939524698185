import moment from 'moment';
import {getUser, logger} from 'api';

const reportStateChange = (newState, additionalData) => {
  const user = getUser();
  const previousState = localStorage.getItem('imperative-state-change-state');
  const newTime = moment();
  const nowTimeString = newTime.toISOString();
  const previousTimeString = localStorage.getItem('imperative-state-change-time');
  const previousTime = previousTimeString && moment(previousTimeString);
  const duration = previousTime && moment.duration(newTime.diff(previousTime)).asMilliseconds();

  logger && logger.debug(`Info: ${newState}`, {
    previous_state: previousState,
    new_state: newState,
    transition_duration: duration,
    user_id: user && (user.memberId || user.id),
    additional_data: additionalData
  })

  // UPDATING STATE
  localStorage.setItem('imperative-state-change-time', nowTimeString);
  localStorage.setItem('imperative-state-change-state', newState);
  localStorage.setItem('imperative-state-change-state-previous', previousState);

  return true;
}

const reportIntermediateError = (errorState, errorMessage, additionalData) => {
  const user = getUser();
  const duringState = localStorage.getItem('imperative-state-change-state');

  logger && logger.error(`Error: ${errorState}`, {
    user_id: user && (user.memberId || user.id),
    during_state: duringState,
    errorMessage: errorMessage && (errorMessage.message || errorMessage),
    additional_data: additionalData
  });

  return false;
}

export {reportStateChange, reportIntermediateError}
