import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'


export default ({author, children, className}) ->
  React.createElement("div", {"className": (cx "LongReport__quote", className)},
    React.createElement("p", {"className": "LongReport__quoteText"}, (children)),
    React.createElement("p", {"className": "LongReport__quoteAuthor"}, (author))
  )
