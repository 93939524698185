import React, { useState } from 'react';
import cx from 'classnames';
import './Radio.scss';

const Radio = ({
  onChange,
  className,
  disabled,
  id,
  value,
  options = [], // [{label, value}]
}) => {
  const [focusedIndex, setFocusedIndex] = useState(null);

  const handleKeyDown = (event, index) => {
    if (event.key === 'ArrowDown' && index < options.length - 1) {
      event.preventDefault();
      setFocusedIndex(index + 1);
    } else if (event.key === 'ArrowUp' && index > 0) {
      event.preventDefault();
      setFocusedIndex(index - 1);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      onChange(options[index].value);
    }
  };

  return (
    <div className={cx('Radio', className, { disabled })}>
      {options.map((option, i) => {
        const checked = value === option.value;

        return (
          <div className="Radio__option" key={i} tabIndex={0} onKeyDown={(e) => handleKeyDown(e, i)}>
            <i className={cx('Radio__optionIcon', 'fa-stack', { checked })} aria-hidden>
              <i className="fal fa-circle fa-stack-2x" />
              {checked && <i className="fas fa-circle fa-stack-1x" />}
            </i>
            <input
              type="radio"
              id={`${id}${i}`}
              name={id}
              value={option.value}
              checked={checked}
              onChange={() => onChange(option.value)}
              tabIndex={-1}
            />
            <label htmlFor={`${id}${i}`}>{option.label}</label>
          </div>
        );
      })}
    </div>
  );
};

export default Radio;
