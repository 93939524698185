import React from 'react'
import * as _ from 'ramda'
import {withRouter} from 'react-router'
import {composeComponent, addStatesWithHandlers} from 'utils/react-tools'
import * as ME from 'api/metrics'
import {lifecycle} from 'recompose'
import Loader from 'theme/Loader'
import DownloadButton from 'reports/DownloadReportButton'
import {fetchUser} from 'auth/requests'
import {fetchTeamReport} from 'reports/requests'

import TeamReportContent from './TeamReportContent'

import './TeamPrintReport.sass'

export default composeComponent 'AllInTeamReport',
  withRouter
  addStatesWithHandlers
    loading: false
    user: null
    report: null

  lifecycle
    componentDidMount: ->
      {match: {params}, setUser, t, setLoading, setReport} = @props

      setLoading(true);
      ME.reportStateChange('Team Report: loading', {teamSlug: params.slug});

      Promise.all([
        fetchUser(),
        fetchTeamReport(params.slug),
      ]).then(
        ([user, report]) ->
          setUser(user);
          setReport(report);
          ME.reportStateChange('Team Report: loaded', {teamSlug: params.slug});
          setLoading(false);
        , (error) ->
          ME.reportIntermediateError('Team Report: loading error', error, {teamSlug: params.slug});
          setLoading(false);
      )
  ({report, user, loading}) ->
    unless report
      return null

    React.createElement("section", {"className": "TeamReport"},
      React.createElement(Loader, {"loading": (loading), "color": "bg"}),
      React.createElement(DownloadButton, {"name": "AllInTeamReport_#{report.name}"}),

      React.createElement("div", {"id": "ReportContent"},
        (report.members.map (teamMember) ->
          React.createElement(TeamReportContent, { \
            "key": (teamMember.slug),  \
            "teamMember": (teamMember),  \
            "report": (report)
          })
        )
      )
    )
