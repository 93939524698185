import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import TranslationsContext from 'translations';
import * as DRIVER_ICONS from 'theme/drivers';
import * as PURPOSE_PROFILE_ICONS from 'theme/purpose-profile-drivers';

import './Purpose.sass';

const DIMENSIONS = ['who', 'why', 'how'];

const Purpose = ({ user }) => {
  const t = useContext(TranslationsContext);

  const patternString = useMemo(() => {
    if (!user) return '';
    return `${user.pattern.who}${user.pattern.why}${user.pattern.how}`;
  }, [user]);

  const imperative = useMemo(() => {
    return {
      prefix: {
        my: t.find(`pattern.imperative-prefix.my`),
        your: t.find(`pattern.imperative-prefix.your`),
      },
      who: t.find(`pattern.archetypes.${patternString}.imperative.who`),
      why: t.find(`pattern.archetypes.${patternString}.imperative.why`),
      how: t.find(`pattern.archetypes.${patternString}.imperative.how`),
    };
  }, [t, patternString]);

  const dominantDimension = ((user && user.pattern && user.pattern.dominant) || '').toLowerCase();
  const dominant = user && user.pattern && user.pattern[dominantDimension];
  const dominantDriverTitle = t.find(`pattern.drivers.${dominantDimension}.${dominant}.title`);

  if (!user) return null;

  return (
    <div className="Purpose">
      <h1 className="page-title blue bold">Discover your purpose</h1>
      <div className="Purpose__row">
        <div className="card Purpose__row Purpose__section-2">
          {user.avatars && (
            <div className="Purpose__section Purpose__avatar card card-color-grey-50 padding-16">
              <p className="section-intro color-primary-600">
                {imperative.prefix.your} {imperative.who} {imperative.why} {imperative.how}.
              </p>
            </div>
          )}
          <div className="Purpose__section Purpose__row gap-4">
            <div className="Purpose__leadership card card-color-grey-50">
              <img src={PURPOSE_PROFILE_ICONS[dominantDriverTitle]} alt={dominantDriverTitle} />
            </div>
            {DIMENSIONS.map((dim) => (
              <div className="Purpose__driver card card-color-grey-50" key={dim}>
                <img src={DRIVER_ICONS[user.pattern[dim]]} alt={user.pattern[dim]} />
              </div>
            ))}
            {!user.avatars && (
              <div className="Purpose__imperative card card-color-grey-50">
                <p className="caption-new color-primary-600">
                  {imperative.prefix.your} {imperative.who} {imperative.why} {imperative.how}.
                </p>
              </div>
            )}
          </div>
          <div className="Purpose__section">
            <p className="p-new">Purpose report</p>
            <p className="p-2">
              Identify what makes you come alive at work and investigate which activities give you the deepest sense of
              fulfillment.
            </p>
            <p className="p-2">
              Your custom purpose report provides insights from Imperative’s extensive research into the science of
              purpose.
            </p>
            <Link to="/purpose-profile/report/results" className="btn-primary pink">
              View purpose profile
            </Link>
          </div>
        </div>
        {!user.avatars && (
          <div className="card Purpose__avatar Purpose__section">
            <img
              src="https://assets.imperative.com/static.imperative.com/images/purpose_aaron.png"
              aria-hidden
              alt=""
            />
            <p className="section-intro">Your peers would like to get to know you better</p>
            <Link to="/settings/profile" className="btn-secondary">
              Upload profile photo
            </Link>
          </div>
        )}
      </div>
      <h3 className="section-head">What members on Imperative are saying about building connections</h3>
      <div className="Purpose__row">
        <div className="Purpose__section card text-center">
          <p className="page-title">
            89<span className="x">%</span>
          </p>
          <p className="p-2">built a meaningful connection</p>
        </div>
        <div className="Purpose__section card text-center">
          <p className="p-2">Imperative conversations increase positive emotions by</p>
          <p className="page-title">
            2.4<span className="x">x</span>
          </p>
        </div>
        <div className="Purpose__section card text-center">
          <p className="page-title">
            78<span className="x">%</span>
          </p>
          <p className="p-2">Imperative enables them to be more successful</p>
        </div>
      </div>
    </div>
  );
};

export default Purpose;
