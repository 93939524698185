import React from 'react'
import moment from 'moment'
import cx from 'classnames'
import {composeComponent} from 'utils/react-tools'
import {lifecycle} from 'recompose'
import * as ME from 'api/metrics'
import DownloadButton from 'reports/DownloadReportButton'
import Page from 'reports/PrintPage'
import {IMG_CATEGORY} from 'theme/assets/assets'
import DriverPage from './DriverPage'

import './DriversAtGlance.sass'

drivers =
  who: ['Individual', 'Organization', 'Society']
  why: ['Harmony', 'Karma']
  how: ['Community', 'Human', 'Knowledge', 'Structure']

dimensions = Object.keys drivers


export default composeComponent 'DriversAtGlance',
  lifecycle
    componentDidMount: ->
      ME.reportStateChange('Drivers At Glance: loaded');

  ({translations, loading, t}) ->
    number = 1 # page numbering start
    date = moment().format 'MMMM Do, YYYY'

    footer = (number, visible) ->
      if visible
        React.createElement("span", {"className": (cx \
        "DriversAtGlance__footer"
        , right: number %% 2
        )},
          (unless number %% 2
            React.createElement("span", {"className": "DriversAtGlance__footerNumber"}, (number))
          ), """
          Drivers At a Glance Report • Ⓒ Imperative
""", (if number %% 2
            React.createElement("span", {"className": "DriversAtGlance__footerNumber"}, (number))
          )
        )

    React.createElement("section", {"className": "DriversAtGlance"},
      React.createElement(DownloadButton, {"name": "DriversAtGlance"}),

      React.createElement("div", {"id": "ReportContent"},
        React.createElement(Page, {"footer": (footer number, true), "marginSize": "none"},
          React.createElement("div", {"className": "DriversAtGlance__header"},
            React.createElement("div", {"className": "DriversAtGlance__headerContainer"},
              React.createElement("p", {"className": "DriversAtGlance__headerContainerHeading"}, """
                Purpose Drivers at a Glance
"""),
              React.createElement("p", {"className": "DriversAtGlance__headerContainerSubheading"}, "An in-depth look at each purpose driver")
            )
          ),
          React.createElement("img", {"className": "DriversAtGlance__image", "src": (IMG_CATEGORY), "alt": "categories"})
        ),
        (for key, value of drivers
          value.map (k) ->
            React.createElement(DriverPage, {"dimension": (key), "driver": (k), "key": (k), "footer": (footer number+=1, true), "t": (t)})
        )
      )
    )
