import React from 'react';

const leaderRoleIds = [225, 226];
const peerRoleIds = [223];

const IntersitialNetwork = ({ t, user, userRole, setFocusRef }) => {
  const extendedUser = {
    ...user,
    selectedRole: leaderRoleIds.includes(userRole.id)
      ? 'leader'
      : peerRoleIds.includes(userRole.id)
      ? 'peer'
      : userRole.label.toLowerCase(),
  };

  return (
    <section>
      <p tabIndex="-1" ref={setFocusRef}>
        {t.find('assessment.page.network.description', extendedUser)}
      </p>
      <div className="Assessment__img Assessment__img_peer_network" />
    </section>
  );
};

export default IntersitialNetwork;
