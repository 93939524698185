import React, {useContext, useMemo, useState, useEffect, useCallback} from 'react';
import cx from "classnames";
import {adjust, countBy, identity, sum, values, map} from 'ramda';
import Avatar from 'theme/Avatar';
import moment from "moment-timezone";
import TranslationsContext from 'translations';
import * as ME from 'api/metrics';
import * as emojis   from 'theme/emojis';
import {getActionAreas, getSubInvestmentAreas, getRIGScores, getActionCommitments, getRigBaseline, getActionCommitmentStats, getActionConfirmations} from './requests';

import "./Fulfillment.sass";

const RIG = ['relationship', 'impact', 'growth'];
const RIG_MAP = {
  'relationship': 'relationships'
};
const RIG_LABELS_MAP = {
  'Frustrated': 'assessment.rig.answer1',
  'Unhappy': 'assessment.rig.answer2',
  'Meh': 'assessment.rig.answer3',
  'Happy': 'assessment.rig.answer4',
  'Pumped Up': 'assessment.rig.answer5',
}
const RIG_OPTIONS = [
  'Frustrated',
  'Unhappy',
  'Meh',
  'Happy',
  'Pumped Up',
]


const Fulfillment = ({user, conversations, networkStats}) => {
  const t = useContext(TranslationsContext);
  const [loading, setLoading] = useState(false);
  const [rigScores, setRigScores] = useState([]);
  const [actionCommitments, setActionCommitments] = useState([]);
  const [actionConfirmations, setConfirmations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [subInvestmentAreas, setSubInvestmentAreas] = useState([]);
  const [stats, setStats] = useState({});

  const isRigEnabled = useMemo(() => {
    return user && user.organizationSettings && user.organizationSettings.rigSettings &&
      user.organizationSettings.rigSettings.isEnabled;
  }, [user]);

  const isRigPulseEnabled = useMemo(() => {
    return user && user.organizationSettings && user.organizationSettings.rigSettings &&
      user.organizationSettings.rigSettings.rigPulseEnabled
  }, [user]);

  const loadRigScores = useCallback(() => {
    return new Promise((resolve, reject) => {
      getRIGScores()
      .then(scores => {
        if(!scores || scores.length == 0) {
          getRigBaseline(user.slug)
          .then(
            (resp) => resolve([resp]),
            reject
          )
        } else resolve(scores)
      }, reject)
    });
  }, [user]);

  useEffect(() => {
    if (networkStats && networkStats.communityCount >= 6) {
      getActionCommitmentStats()
      .then(stats => {
        setStats(stats);
      });
    }
  }, [networkStats]);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      ...[(isRigEnabled || isRigPulseEnabled) && loadRigScores()],
      getActionCommitments(),
      getActionConfirmations(),
      getActionAreas(),
      getSubInvestmentAreas()
    ]).then(([rig, actions, confirmations, areas, subAreas]) => {
      setRigScores(rig);
      setActionCommitments(actions);
      setConfirmations(confirmations);
      setAreas(areas);
      setSubInvestmentAreas(subAreas);
      setLoading(false);
    }).catch((error) => {
      ME.reportIntermediateError('Fulfillment: loading data', error);
    })
  }, []);

  if(!user) return null;

  const lastRigScore = useMemo(() => {
    return rigScores.reduce((last, score) => {
      if(!last) return score;
      if((last.scoredAt || last.dateScored) > (score.scoredAt || score.dateScored)) {
        return last;
      }
      return score;
    }, null);
  }, [rigScores]);

  const lastCommitment = useMemo(() => {
    if(actionCommitments.every(com =>
        actionConfirmations.find(c => c.sourcePeerConversationId == com.peerConversationId)
    )) {
      return null;
    }

    const lastC = actionCommitments.reduce((last, score, i) => {
      if(!last) return score;
      if(moment(last.modifiedAt).isAfter(moment(score.modifiedAt))) {
        return last;
      }
      return score;
    }, null);

    const conversation = lastC && conversations.find(c => c.id == lastC.peerConversationId);
    if(lastC && conversation) {
      return {
        ...lastC,
        conversation
      }
    }
  }, [actionCommitments, conversations, actionConfirmations]);

  const completions = useMemo(() => {
    const confirmationsAreas = actionConfirmations.reduce((array, confirmation) => {
      if(!confirmation.performed)
        return array;

      return array.concat(confirmation.impactedAreasIds);
    }, []);

    const count = countBy(identity)(confirmationsAreas);

    return {
      ...count,
      total: sum(values(count))
    }
  }, [actionConfirmations]);

  const areasMapped = useMemo(() => {
    return areas.reduce((obj, a) => ({...obj, [a.id]: a.areaName}), {});
  }, [areas]);

  const area = useMemo(() => {
    if(!lastCommitment || !areas) return;
    const obj = areas.find(a => a.id == lastCommitment.areaId);
    return obj && obj.areaName;
  }, [lastCommitment, areas]);

  const statsEmpty = useMemo(() => {
    return Object.keys(stats).every(key => !stats[key].subInvestmentAreasCount || stats[key].subInvestmentAreasCount.length == 0);
  }, [stats]);

  const customActionsEnabled = useMemo(() => {
    return user?.organizationSettings.customActionsActive;
  }, [user?.organizationSettings.customActionsActive]);

  const statsMapped = useMemo(() => {
    const counts = map(
      ({subInvestmentAreasCount}) => subInvestmentAreasCount.reduce((sum, a) => sum + a.count, 0),
      stats
    );
    const total = sum(values(counts));
    return {
      ...counts,
      total
    }
  }, [stats]);

  const subAreas = useMemo(() => {
    return subInvestmentAreas.reduce((array, {subInvestmentAreas}) => array.concat(subInvestmentAreas) ,[]);
  }, [subInvestmentAreas]);

  const statsWithOther = useMemo(() => {
    return map(
      ({subInvestmentAreasCount}) => {
        return subInvestmentAreasCount.reduce((result, obj) => {
          const isOtherArea = subAreas.indexOf(obj.subInvestmentArea) == -1;
          if(isOtherArea) {
            const foundI = result.findIndex(({subInvestmentArea}) => subInvestmentArea == 'Other');
            if(foundI > -1) {
              return adjust(foundI, ({count}) => ({subInvestmentArea: 'Other', count: count + obj.count}), result);
            }
            return result.concat({...obj, subInvestmentArea: 'Other'});
          }
          return result.concat(obj);
        }, [])
      }, stats
    )
  }, [stats, subAreas]);

  return (
    <div className="Fulfillment">
      <h3 className="page-title blue bold">Actionable conversations</h3>
      <p className="section-intro">
        With each conversation, you commit to a meaningful action to complete before your next conversation.
        Track your commitments here and see the growing impact.
      </p>
      <div className="Fulfillment__row">
        {(isRigEnabled && isRigPulseEnabled && lastRigScore) &&
          <div className="Fulfillment__section">
            <div className="card">
              <p className="p-new">Current fulfillment</p>
              <div className="Fulfillment__rig">
                {RIG.map(dim => {
                  const value = lastRigScore.scores[dim] && lastRigScore.scores[dim].value;
                  const emoji_src = RIG_OPTIONS[value] && emojis[`${RIG_OPTIONS[value].replace(' ', '')}_blue`];
                  return (
                    <div className="Fulfillment__rig-item" key={dim}>
                      <p className="p-2 bold">{t.find(`dictionaries.reflectionquestionname.${RIG_MAP[dim] || dim}.name`)}</p>
                      <div className="card card-color-grey-50">
                        <img src={emoji_src} alt={RIG_OPTIONS[value]} aria-hidden />
                        <p className="p-new">{t.find(RIG_LABELS_MAP[RIG_OPTIONS[value]])}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="card">
              <p className="p-new">Commitment completions</p>
              <div className="Fulfillment__rig commitment">
                <div className="Fulfillment__rig-item">
                  <div className="card">
                    <p className={cx("page-title", {"color-grey-30": completions.total == 0})}>
                      {completions.total}<span className="x">x</span>
                    </p>
                  </div>
                  <p className="p-2 bold">Total</p>
                </div>
                <div className="Fulfillment__completions">
                  {Object.keys(areasMapped).map(areaId => {
                    return (
                      <div key={areaId} className="Fulfillment__rig-item">
                        <div className="card card-color-grey-50">
                          <p className={cx("page-title", {"color-grey-30": !completions[areaId]})}>
                            {completions[areaId] || 0}<span className="x">x</span>
                          </p>
                        </div>
                        <p className="p-2 bold">{areasMapped[areaId]}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        }
        {!lastCommitment && !actionCommitments.length &&
          <div className="Fulfillment__commitment card-shadow padding-32">
            <img src="https://assets.imperative.com/static.imperative.com/images/own_fulfillment_commitment.png" alt="" />
            <p className="section-intro">Your first commitment will appear here</p>
            <p className="p-new">You’ll set a commitment with your peer coach during your first conversation. Which area of your fulfillment will you prioritize?</p>
          </div>
        }
        {!lastCommitment && actionCommitments.length > 0 &&
          <div className="Fulfillment__commitment card-shadow padding-32">
            <img src="https://assets.imperative.com/static.imperative.com/images/own_fulfillment_waiting.png" alt="" />
            <p className="section-intro">You’ve made great progress</p>
            <p className="p-new">What’s the next small step you can take? Which area of your fulfillment will you prioritize during your next conversation?</p>
          </div>
        }
        {lastCommitment &&
          <div className="card-shadow">
            <div className="card-heading">
              <p className="p-new">Current commitment</p>
              <div className="btn-small pink">
                Commited on {moment(lastCommitment.modifiedAt).format('D MMMM')}
              </div>
            </div>
            <div className="Fulfillment__commitment">
              <div className="Fulfillment__avatars">
                {lastCommitment.conversation && lastCommitment.conversation.participant.map(p =>
                  <Avatar key={p.member.memberId} for={p.member} size="48" bordered />
                )}
                {area &&
                  <h4 className="section-head">
                    {t.find(`dictionaries.reflectionquestionname.${area}.name`)} - {t.find(lastCommitment.subInvestmentArea)}
                  </h4>
                }
              </div>
              <p className="p-2 color-grey-60">Action</p>
              <p className="p-new">{lastCommitment.what}</p>
              <p className="p-2 color-grey-60">Impact</p>
              <p className="p-new">{lastCommitment.why}</p>
            </div>
          </div>
        }
      </div>
      {(!statsEmpty && !customActionsEnabled) &&
        <div className="Fulfillment__stats">
          <h4 className="section-head">Commitments made in your community</h4>
          <div className="Fulfillment__row">
            {RIG.map(dim => {
              const key = `${RIG_MAP[dim] || dim}Score`;
              const count = statsMapped[key];
              const total = statsMapped.total;
              const areasForDim = statsWithOther[key] || [];
              const percentage = Math.round(count / total * 100);
              return (
                <div className="card" key={dim}>
                  <p className="p-new">{t.find(`dictionaries.reflectionquestionname.${RIG_MAP[dim] || dim}.name`)}</p>
                  <p className={cx("page-title", {"color-grey-60": percentage == 0})}>
                    {percentage}<span className="x">%</span>
                  </p>
                  {areasForDim.length > 0 &&
                    <div className="divider horizontal" />
                  }
                  {areasForDim.sort((a, b) => b.count - a.count).map((area, i) => {
                    const percentage = Math.round(area.count / total * 100);
                    return (
                      <div className="card-heading p-2" key={i}>
                        <span>{t.find(area.subInvestmentArea)}</span>
                        <span>{percentage}%</span>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      }
    </div>
  )
}

export default Fulfillment;
