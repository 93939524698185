import React from 'react'
import {withRouter, Link} from 'react-router-dom'
import RSelect, {components} from 'react-select'
import {
  withHandlers,
  withStateHandlers,
  lifecycle,
  mapProps
} from 'recompose'
import * as _ from 'ramda'
import cx from 'classnames'
import {composeComponent, withModal} from 'utils/react-tools'
import {simplifyConvState, STATES, Finished} from 'utils/states'
import Avatar from 'theme/Avatar'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Modal  from 'theme/Modal'
import Subnav from 'theme/Subnav'
import {fetchUser} from 'auth/requests'
import {
  getConversations,
  getAllWorkshops,
  deleteConversation,
  updateConversation,
  getOrganizations
} from 'conversations/requests'

import './Conversations.sass'


export default composeComponent 'ConversationsAll',
  withRouter
  withModal

  withStateHandlers
    user: null
    loading: false
    status: null
    organization: null
    conversations: []
    orphanedConvs: []
    workshops: []
    organizations: []
    showAll: false
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setOrganization: -> (organization) -> {organization}
    setConversations: -> (conversations) -> {conversations}
    setOrphaned: -> (orphanedConvs) -> {orphanedConvs}
    setWorkshops: -> (workshops) -> {workshops}
    setOrganizations: -> (organizations) -> {organizations}
    setShowAll: -> (showAll) -> {showAll}

  withHandlers
    getConversations: ({setConversations, setWorkshops2}) -> (orgCode) ->
      getConversations(orgCode)
        .then (convs) ->
          convsWithNoWorkshops = convs.filter (c) -> !c.workshopId
          convsWithWorkshops = convs.filter (c) -> c.workshopId
          convsWithWorkshops.sort (a,b) -> a.workshopId - b.workshopId
          sorted = [...convsWithWorkshops, ...convsWithNoWorkshops]

          setConversations sorted

          sorted
        .catch (err) -> console.log "CPL Conversation status/ ALL: conversations loading error", err

    getWorkshops: ({setWorkshops}) -> (orgCode) ->
      getAllWorkshops(orgCode)
        .then (workshops) ->
          setWorkshops workshops.sort (a,b) -> a.id - b.id

          workshops
        .catch (err) -> console.log "CPL Conversation status/ ALL: workshops loading error", err

    getOrganizations: ({setOrganizations}) -> () ->
      getOrganizations()
        .then (orgs) ->
          sorted = [...orgs].sort (a,b) -> a.code.localeCompare(b.code)
          setOrganizations sorted

          sorted
        .catch (err) -> console.log "CPL Conversation status/ ALL: organizations loading error", err

  withHandlers
    getOrgData: ({organization, getConversations, getWorkshops, setOrphaned, startLoading, endLoading}) -> (org) ->
      code = org?.code || organization.code

      startLoading()
      a = getConversations(code)
      b = getWorkshops(code)

      Promise.all [a, b]
        .then(
          ([convs, workshops]) ->
            activeW = workshops.filter (w) -> !w.archivedAt
            orphaned = convs.filter (c) -> !(activeW.find (a) -> a.id is c.workshopId)

            setOrphaned orphaned

        , (err) -> console.log "CPL Conversation status/ ALL: org data loading error", err
        )
        .finally endLoading

  withHandlers
    loadData: ({user, setUser, setOrganization, startLoading, endLoading, getOrganizations, getOrgData}) -> ->
      startLoading()

      fetchUser()
        .then (user) ->
          org = user.organization
          setUser user
          setOrganization org

          a = if user.isSuperAdmin then getOrganizations() else Promise.resolve []
          b = getOrgData()

          Promise.all [a, b]
            .then _, (err) -> console.log "CPL Conversation status/ ALL: data loading error", err

        .catch (err) -> console.log "CPL Conversation status/ ALL: user fetching error", err
        .finally endLoading

    deleteConversation: ({organization, getOrgData, closeModal, startLoading, endLoading}) -> (conversation) ->
      startLoading()

      deleteConversation conversation
        .then () ->
          getOrgData(organization)
        .then closeModal
        .finally endLoading

    markAsComplete: ({organization, getOrgData, startLoading, endLoading}) -> ({conv}) ->
      startLoading()

      updateConversation conv, {state: Finished, forcedUpdate: true}
        .then () ->
          getOrgData(organization)
            .then () -> endLoading()
        .catch (err) ->
          console.log "CPL Conversation status/ ALL: mark as complete error", err
          endLoading()

    selectOrganization: ({setOrganization, getOrgData}) -> (org) ->
      setOrganization(org)
      getOrgData(org)


  mapProps (props) -> _.evolve(
    conversations: _.map((conv) ->
        {t} = props
        [program, set, convLabel] = _.split '/', conv.label
        programD = t.find "peerconversation.program.#{program}.title"
        setD = t.find "peerconversation.set.#{set}.title"
        convLabelD = t.find "peerconversation.conversation.#{convLabel}.title"
        stateD = simplifyConvState conv.state
        member1 = conv.participant?[0] &&
          member: conv.participant[0]?.member
          name: "#{conv.participant[0]?.member.firstName} #{conv.participant[0]?.member.lastName}"
          state: simplifyConvState conv.participant[0]?.state
        member2 = conv.participant?[1] &&
          member: conv.participant[1]?.member
          name: "#{conv.participant[1]?.member.firstName} #{conv.participant[1]?.member.lastName}"
          state: simplifyConvState conv.participant[1]?.state
        workshop = props.workshops.find (w) -> w.id is conv.workshopId

        _.merge conv, {program, programD, set, setD, convLabel, convLabelD, stateD, member1, member2, workshop}
    )
    orphanedConvs: _.map((conv) ->
        {t} = props
        [program, set, convLabel] = _.split '/', conv.label
        programD = t.find "peerconversation.program.#{program}.title"
        setD = t.find "peerconversation.set.#{set}.title"
        convLabelD = t.find "peerconversation.conversation.#{convLabel}.title"
        stateD = simplifyConvState conv.state
        member1 = conv.participant?[0] &&
          member: conv.participant[0]?.member
          name: "#{conv.participant[0]?.member.firstName} #{conv.participant[0]?.member.lastName}"
          state: simplifyConvState conv.participant[0]?.state
        member2 = conv.participant?[1] &&
          member: conv.participant[1]?.member
          name: "#{conv.participant[1]?.member.firstName} #{conv.participant[1]?.member.lastName}"
          state: simplifyConvState conv.participant[1]?.state
        workshop = props.workshops.find (w) -> w.id is conv.workshopId

        _.merge conv, {program, programD, set, setD, convLabel, convLabelD, stateD, member1, member2, workshop}
    )
    ,
    props
  )

  lifecycle
    componentDidMount: ->
      @props.loadData()

  ({
    user
    loading
    conversations
    orphanedConvs
    modal
    showModal
    closeModal
    deleteConversation
    markAsComplete
    organizations
    organization
    selectOrganization
    showAll
    setShowAll
  }) ->

    data = if showAll then conversations else orphanedConvs
    convsLength = conversations.length
    orphanedLength = orphanedConvs.length

    React.createElement("div", {"className": "Conversations page"},
      React.createElement(Header, {"user": (user), "cpl": (true)}),

      React.createElement("div", {"className": "container", "id": "main"},
        React.createElement(Loader, {"loading": (loading), "position": "top"}),

        React.createElement("div", {"className": "Conversations__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "Conversations__title"},
              React.createElement(Link, { \
                "className": "Conversations__title-btnBack",  \
                "to": "/peer-coaching/conversations"
              },
                React.createElement("i", {"className": "fas fa-chevron-left"})
              ), """
              Conversations
"""),
             React.createElement("div", {"className": "Conversations__headingFilter"},
              ( if user?.isSuperAdmin
                React.createElement("div", {"className": "Conversations__label"}, "Organization")
              ),
              ( if user?.isSuperAdmin
                React.createElement(RSelect, { \
                  "className": "Conversations__select",  \
                  "classNamePrefix": "Conversations__select",  \
                  "placeholder": "Select an organization",  \
                  "options": (organizations),  \
                  "getOptionValue": ((o) -> o),  \
                  "getOptionLabel": ((o) -> o.name),  \
                  "value": (organization),  \
                  "onChange": (selectOrganization),  \
                  "disabled": (loading)
                })
              )
            )
          ),
          React.createElement("div", {"className": "Conversations__options"},
            React.createElement("div", {"className": "Conversations__toggler"},
              React.createElement("span", {"className": (if showAll is false then 'active')}, "Orphaned (", (orphanedLength), ")"),
              React.createElement("div", {"className": "Conversations__toggler-bar", "onClick": (-> setShowAll !showAll)},
                React.createElement("div", {"className": (cx "Conversations__toggler-handle", right: showAll is true)})
              ),
              React.createElement("span", {"className": (if showAll is true then 'active')}, "All (", (convsLength), ")")
            )
          ),

          React.createElement("div", {"className": "relative"},
            React.createElement("div", {"className": "Conversations__table Conversations__all"},
              React.createElement("table", null,
                React.createElement("thead", null,
                  React.createElement("tr", null,
                    React.createElement("th", null, ('Cohort')),
                    React.createElement("th", null, ('Program')),
                    React.createElement("th", null, ('Set')),
                    React.createElement("th", null, ('Conversation')),
                    React.createElement("th", null, ('State')),
                    React.createElement("th", null, ('Member #1')),
                    React.createElement("th", null, ('#1 State')),
                    React.createElement("th", null, ('Member #2')),
                    React.createElement("th", null, ('#2 State')),
                    React.createElement("th", {"className": "invisible"}, ('Actions'))
                  )
                ),
                React.createElement("tbody", null,
                  (if data.length is 0
                    if showAll
                      React.createElement("tr", null,
                        React.createElement("td", {"colSpan": "10"}, ('No conversations'))
                      )
                    else
                      React.createElement("tr", null,
                        React.createElement("td", {"colSpan": "10"}, ('No orphaned conversations'))
                      )
                  else data.map (conv, i) ->
                    React.createElement("tr", {"key": ("conv-#{conv.id}")},
                      React.createElement("td", {"className": (cx("Conversations__cohort", unless conv.workshop?.name then "Conversations__cell-light"))},
                        (if conv.workshop?.archivedAt or (conv.workshopId and !conv.workshop?.name)
                          React.createElement("div", {"className": "Conversations__cohort-archived"}, "Archived")
                        ),
                        (if conv.workshopId and !conv.workshop?.name then React.createElement("span", null, "Cohort Id: ")),
                        (conv.workshop?.name || conv.workshopId || '—')
                      ),
                      React.createElement("td", null, (conv.programD)),
                      React.createElement("td", null, (conv.setD)),
                      React.createElement("td", null, (conv.convLabelD)),
                      React.createElement("td", null, (conv.stateD)),
                      React.createElement("td", null,
                        (if conv.member1?.name
                          React.createElement("span", null,
                            React.createElement(Avatar, {"for": (conv.member1?.member), "className": "Conversations__avatar"}),
                            React.createElement("span", null, (conv.member1?.name))
                          )
                        else
                          React.createElement("span", null,
                            React.createElement("span", {"className": "Conversations__avatar-warning Avatar _sz_xs"},
                              React.createElement("i", {"className": "fas fa-exclamation"})
                            ),
                            React.createElement("span", {"className": "Conversations__member-warning"}, ('no member'))
                          )
                        )
                      ),
                      React.createElement("td", null, (conv.member1?.state)),
                      React.createElement("td", null,
                        (if conv.member2?.name
                          React.createElement("span", null,
                            React.createElement(Avatar, {"for": (conv.member2?.member), "className": "Conversations__avatar"}),
                            React.createElement("span", null, (conv.member2?.name))
                          )
                        else
                          React.createElement("span", null,
                            React.createElement("span", {"className": "Conversations__avatar-warning Avatar _sz_xs"},
                              React.createElement("i", {"className": "fas fa-exclamation"})
                            ),
                            React.createElement("span", {"className": "Conversations__member-warning"}, ('no member'))
                          )
                        )
                      ),
                      React.createElement("td", null, (conv.member2?.state)),
                      React.createElement("td", null,
                        React.createElement("div", {"className": "Conversations__actions"},
                          (if (simplifyConvState conv.state) is STATES.inProgress
                            React.createElement("button", {"className": "btn-icon Conversations__action", "onClick": (-> markAsComplete {conv}), "aria-label": "check"},
                              React.createElement("i", {"className": "far fa-clipboard-check"}),
                              React.createElement("span", {"className": "Conversations__action-tooltip"}, ('Mark as complete'))
                            )
                          ),
                          React.createElement("button", {"className": "btn-icon Conversations__action", "onClick": (-> showModal {conv}), "aria-label": "trash"},
                            React.createElement("i", {"className": "far fa-trash-alt"}),
                            React.createElement("span", {"className": "Conversations__action-tooltip"}, ('Delete conversation'))
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      ),

      React.createElement(Footer, null),

      React.createElement(Modal, {"isOpen": (modal?.visible), "className": "confirm-modal", "close": (closeModal)},
        React.createElement("h2", {"className": "title"}, "Warning"),
        React.createElement("p", null, "Do you really want to delete this conversation?"),
        React.createElement("div", {"className": "btns"},
          React.createElement("button", {"className": "btn btn_secondary btn_outlined_bluePurple", "onClick": (closeModal)}, """
            Cancel
"""),
          React.createElement("button", {"className": "btn btn_secondary btn_solid_bluePurple", "onClick": (-> deleteConversation modal?.conv)}, """
            Delete
""")
        )
      )
    )
