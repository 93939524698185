import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'

export default ({className, title, items, agendaReset}) ->
  React.createElement("div", {"className": (cx "LongReport__agenda", className)},
    (if agendaReset
      React.createElement("div", {"className": "LongReport__agendaLogo"})
    ),
    React.createElement("div", {"className": "LongReport__agendaContent"},
      React.createElement("h1", {"className": "LongReport__agendaTitle"}, (title)),
      (items?.map (item, i) ->
        React.createElement("div", {"key": (i)},
          React.createElement("h2", {"className": "LongReport__agendaItem LongReport__agendaResetItem"}, (item.title), ":"),
          (item.subitems?.map (subitem, j) ->
            React.createElement("h3", {"key": (j), "className": "LongReport__agendaSubitem LongReport__agendaResetSubitem"},
              (subitem),
              (if j < item.subitems.length - 1
                React.createElement("span", {"className": "LongReport__agendaSubitemDot"})
              )
            )
          )
        )
      )
    )
  )
