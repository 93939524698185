import React, { useEffect, useState } from 'react';
import {
  Router,
  Route as ReactRoute,
  Redirect,
  Switch,
} from 'react-router-dom';
import { logout, getUser, isLoggedIn, getAuth, getJWTToken } from 'api';
import qs from 'qs';
import TranslationsContext, {TranslationsProvider} from 'translations';
import Loader from 'theme/Loader';
import history from 'api/history';
import Signin from 'auth/Signin';
import Signup from 'auth/Signup';
import Reset from 'auth/Reset';
import Confirm from 'auth/Confirm';
import SamlSignin from 'auth/SSO/SamlSignin';
import SSO from 'auth/SSO/SSO';
import Callback from 'auth/SSO/Callback';
import CompleteUserData from 'auth/CompleteUserData';
import ConsentsCheck from 'auth/ConsentsCheck';
import DemographicQuestionsCheck from 'assessment/DemographicQuestionsCheck';
import Assessment from 'assessment/Assessment';
import NetworkError from 'NetworkError';
import Dashboard from 'Dashboard';
import MatchStatus from 'opt-in/MatchStatus';
import PurposeProfile from 'purposeProfile/PurposeProfile';
import Settings from 'settings/Settings';
import Community from 'Community';
import MatchingGroups from 'matchingGroups/MatchingGroups';
import Conversations from 'conversations/Conversations';
import ConversationsAll from 'conversations/Conversations/ConversationsAll';
import Conversation from 'conversations/Conversation';
import Questions from 'conversations/Questions';
import Prework from 'conversations/Questions/Prework';
import MatchingGroup from 'matchingGroups/MatchingGroup';
import Workshop from 'workshops/Workshop';
import Workshops from 'workshops/Workshops';
import Analytics from 'Analytics';
import NotFound from 'NotFound';
import LeadershipTypeReport from 'reports/LeadershipTypeReport';
import DriversAtGlance from 'reports/DriversAtGlance';
import ColleagueGuide from 'reports/ColleagueGuide';
import SelfAssessment from 'reports/SelfAssessmentReport';
import PrintableConversation from 'reports/PrintableConversation';
import IndividualReport from 'reports/IndividualReport';
import TeamPrintReport from 'reports/TeamPrintReport';
import AllInTeamReport from 'reports/TeamPrintReport/AllInTeamReport';
import MeAndTeam from 'reports/TeamPrintReport/MeAndTeamReport';
import AllIndividualInTeamReport from 'reports/AllIndividualInTeamReport';
import PurposeSummarySample from 'reports/PurposeSummarySample';
import DeprecatedSite from 'theme/DeprecatedSite';
import TranslationError from 'TranslationError';
import TimeoutCountdown from 'theme/TimeoutCountdown/TimeoutCountdown';
import { StoreProvider } from 'stores/stores';
import PosthogTracker from 'api/posthog';
import { useStore } from 'stores';

const Route = ({component: Component, render, ...props}) => {
  return (
    <TranslationsContext.Consumer>
      {(t) =>
        <>
          <ReactRoute
            {...props}
            render={(p) => {
              if (t.error)
                return <NetworkError {...p} t={t} error={t.error} />
              else if (Component && !props.children)
                return (
                  <div style={{filter: t.loading && 'blur(5px)'}}>
                    {t.translatingError && <TranslationError />}
                    <Component {...p} t={t} />
                  </div>
                )
              else if (render)
                return render(p);
            }}
          />
        </>
      }
    </TranslationsContext.Consumer>
  );
}

const Logout = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const {userStore} = useStore();
  const {sessionExpired} = qs.parse(location.search, {ignoreQueryPrefix: true});
  const authPagesBase = config && config.onboarding && config.onboarding.base;
  const local = !!config && config.local; //show signin page when on localhost

  const redirectToOnboardingAuth = () => {
    const exp = sessionExpired !== undefined;
    window.location.replace(`${authPagesBase}/sign-out${exp ? '?session-timeout' : ''}`);
  }
  
  useEffect(() => {
    logout()
    .then(() => {
      setLoggedIn(false);
      userStore.logout();
    });
  }, []);

  if(loggedIn) return null;
    
  if(local) {
    return <Redirect to="/signin" />
  }

  redirectToOnboardingAuth();
  return null;
}

const AuthRoutes = (props) => {
  const loggedIn = isLoggedIn();

  if (loggedIn) {
    const path = props.location.pathname;
    const user = getUser();
    const assessmentCompleted = user && user.assessmentCompleted;
    const teamsEnabled = user && user.organizationSettings.teamsSettings.enabled;
    const enabledPeerCoaching = user && user.organizationSettings.partnerConversationsSettings.enabled;

    if (!user || !user.firstName || !user.lastName) {
      if (!path.includes('/complete-profile'))
        return <Redirect to='/complete-profile' />
      else return <Route {...props} />
    }

    if(user && (!assessmentCompleted || !user.pattern)) {
      if (props.location.pathname == '/assessment')
        return <Route {...props} />
      else return <Redirect to="/assessment" {...props} />
    } else {
      if (teamsEnabled != null && (!teamsEnabled && user && !user.isCPL) && path.includes('/team-coaching')) {
        return <Redirect to="/" />
      }

      if(!enabledPeerCoaching && path == '/') {
        return <Redirect to='/purpose-profile' />
      } else return <Route {...props} />;

    }

  } else {
    if (props.location.pathname.includes(['/', '/signin']))
      return <Redirect to="/signin" {...props} />
    else {
      return (
        <Redirect to="/logout" {...props} />
      )
    }
  }
}

const DemographicsModal = ({children}) => {
  const user = getUser();
  const assessmentCompleted = user && user.assessmentCompleted;

  if ((user && assessmentCompleted && user.pattern) || !user)
    return <DemographicQuestionsCheck user={user}>{children}</DemographicQuestionsCheck>
  else return children
}

const AdminRoute = (props) => {
  return (
    <DeprecatedSite>
      <Route {...props} />
    </DeprecatedSite>
  )
}

const SuperAdminRoute = (props) => {
  const user = getUser();

  if(!user) return <Redirect to="/" {...props} />;
  else if(user.isSuperAdmin || (
    user.roles && user.roles.findIndex(r => r.name == "super-admin") > -1
  )) {
    return (
      <Route {...props} />
    )
  }
  else return <Redirect to="/" {...props} />;
}

const LoginRoute = (props) => {
  if (isLoggedIn()) {
    return <Redirect to="/" />
  }
  else return <Route {...props} />
}

const CommunityEnabledRoute = (props) => {
  const user = getUser();
  if (user.organizationSettings && user.organizationSettings.communityPageSettings && user.organizationSettings.communityPageSettings.communityPageEnabled) {
    return <Route {...props}  />
  }
  return <Redirect to="/" />
}

const AssessmentRoute = (props) => {
  const user = getUser();
  const assessmentCompleted = user && user.assessmentCompleted;
  if(user && user.pattern && assessmentCompleted) {
    return <Redirect to="/" />
  }
  return <Route {...props} />
}

const PortalRedirect = (props) => {
  const config = window.config;
  const adminPortalUrl = config ? config.admin.portal : '';

  useEffect(() => {
    const auth = getAuth();
    getJWTToken(auth.jwt)
    .then((token) => {
      window.location.href = `${adminPortalUrl}/sign-in?session_token=${token}`
    }).catch(() => {
      history.push('/');
    })
  }, []);

  return <Loader loading />;
}

export default () =>
  <Router history={history}>
    <PosthogTracker>
      <StoreProvider>
        <TranslationsProvider>
          <ReactRoute path="/">
            <Switch>
              <LoginRoute exact path="/signin/pwc" render={(props) => <SamlSignin {...props} method="pwc" />} />
              <LoginRoute exact path="/signin/hasbro" render={(props) => <SamlSignin {...props} method="hasbro" />} />
              <LoginRoute exact path="/signin/equitable" render={(props) => <SamlSignin {...props} method="equitable" />} />
              <LoginRoute exact path="/signin/oswaldcompanies" render={(props) => <SamlSignin {...props} method="oswald-companies" />} />
              <LoginRoute exact path="/signin/bostonscientific" render={(props) => <SamlSignin {...props} method="boston-scientific" />} />
              <LoginRoute exact path="/signin/siteimprove" render={(props) => <SamlSignin {...props} method="site-improve" />} />
              <LoginRoute exact path="/signin/icf" render={(props) => <SamlSignin {...props} method="icf" />} />
              <LoginRoute exact path="/signin/intuitive" render={(props) => <SamlSignin {...props} method="intuitive" />} />
              <LoginRoute exact path="/signin/zillow" render={(props) => <SamlSignin {...props} method="zillow" />} />
              <LoginRoute exact path="/signin/airbnb" render={(props) => <SamlSignin {...props} method="airbnb" />} />
              <LoginRoute exact path="/signin/seattle-city-light" render={(props) => <SamlSignin {...props} method="seattle-city-light" />} />
              <LoginRoute exact path="/signin/seattlecitylight" render={(props) => <SamlSignin {...props} method="seattle-city-light" />} />
              <LoginRoute exact path="/signin/saml" component={SamlSignin} />
              <LoginRoute exact path="/signin/sso" component={SSO} />
              <LoginRoute exact path="/signin" component={Signin}/>
              <LoginRoute exact path="/signup" component={Signup} />
              <LoginRoute exact path="/sso-callback" component={Callback} />
              <LoginRoute exact path="/callback" component={Callback} />
              <LoginRoute path="/reset" component={Reset} />
              <LoginRoute path="/confirm" component={Confirm} />
              <ReactRoute exact path="/logout" component={Logout}/>
              <AuthRoutes path="/">
                <TimeoutCountdown />
                <ConsentsCheck>
                  <DemographicsModal>
                    <Switch>
                      <Route exact path="/complete-profile" component={CompleteUserData} />
                      <Route path="/purpose-profile" component={PurposeProfile} />
                      <AssessmentRoute exact path="/assessment" component={Assessment} />
                      <Route path="/settings" component={Settings} />
                      <CommunityEnabledRoute path="/community" component={Community} />
                      <AdminRoute exact path="/peer-coaching/analytics" component={Analytics} />
                      <AdminRoute exact path="/peer-coaching/manage" component={MatchingGroups} />
                      <AdminRoute exact path="/peer-coaching/manage/:id/group/:matchingGroupId/match" component={MatchingGroup} />
                      <AdminRoute exact path="/peer-coaching/user-groups" component={Workshops} />
                      <AdminRoute exact path="/peer-coaching/user-groups/:workshopId" component={Workshop} />
                      <AdminRoute exact path="/peer-coaching/conversations" component={Conversations} />
                      <AdminRoute exact path="/peer-coaching/conversations/all" component={ConversationsAll} />
                      <SuperAdminRoute exact path="/report-sample/:orgSlug" component={PurposeSummarySample} />
                      <Route exact path="/peer-coaching/:conversationId/conduct" component={Questions} />
                      <Route exact path="/peer-coaching/:conversationId/prework" component={Prework} />
                      <Route exact path="/peer-coaching/:conversationId" component={Conversation} />
                      <Route exact path="/peer-coaching/:id/print" component={PrintableConversation} />
                      <Route exact path="/peer-coaching/workshops/:workshopId/print" component={PrintableConversation} />
                      <Route exact path="/m/:slug/print" component={IndividualReport} />
                      <Route exact path="/teams/:slug/print/:member" component={TeamPrintReport} />
                      <Route exact path="/teams/:slug/print" component={TeamPrintReport} />
                      <Route exact path="/teams/:slug/print-all" component={AllInTeamReport} />
                      <Route exact path="/teams/:slug/print-all-individuals" component={AllIndividualInTeamReport} />
                      <Route exact path="/teams/:slug/members/:member/print" component={MeAndTeam} />
                      <Route exact path="/self-assessment/print" component={SelfAssessment} />
                      <Route exact path="/m/:slug/conversation-guide/:type/:driver" component={ColleagueGuide} />
                      <Route exact path="/drivers-at-glance" component={DriversAtGlance} />
                      <Route exact path="/leadershiptype-report/:who/:why/:how" component={LeadershipTypeReport} />
                      <Route exact path="/portal" component={PortalRedirect} />
                      <Route path="/" component={Dashboard} />
                      <Route component={NotFound} />
                    </Switch>
                  </DemographicsModal>
                </ConsentsCheck>
              </AuthRoutes>
              <Route component={NotFound} />
            </Switch>
          </ReactRoute>
        </TranslationsProvider>
      </StoreProvider>
    </PosthogTracker>
  </Router>
