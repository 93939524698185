import React from 'react';

const steps = [
  {
    disableBeacon: true,
    target: '#match-status',
    title: (
      <span className="section-head" style={{ margin: 0 }}>
        Welcome to Your Upgraded Dashboard
      </span>
    ),
    content: <p className="p-new">Your match status reflects whether you are open to new matches or unavailable.</p>,
  },
  {
    target: '#update-match-status-btn',
    content: <p className="p-new">Be sure to update your status here when your availability changes.</p>,
  },
  {
    target: '#partner-profile-section',
    content: (
      <>
        <p className="p-new">
          This section is dynamic. It will let you know where you are in the experience at a given moment.
        </p>
        <p className="p-new margin-top-2">
          Examples: Inviting you to learn more about your connection or reminding you to schedule your next
          conversation.
        </p>
      </>
    ),
  },
  {
    target: '#partners-list',
    content: (
      <p className="p-new">
        You can always find your current and past connections here. Click the partner icon to view upcoming
        conversations or revisit past notes.
      </p>
    ),
  },
  {
    target: '#launcher-frame',
    content: (
      <p className="p-new">
        As always, reach out to Team Imperative for any questions or to share feedback. We are listening and always
        working to deliver the best experience for you.
      </p>
    ),
  },
];

export default steps;
