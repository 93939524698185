import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import './Dropdown.sass'

const DropdownMenu = (props) => {
  const {children, visible, over, hideMenu, title} = props;
  const position = (props.position || '').split(' ').join('_');

  return (
    <>
      <div className={cx(
        "HeaderDropdown__menu",
        position,
        {visible, over},
      )}>
        {over &&
          <button
            className="HeaderDropdown__menuBack btnLink"
            onClick={hideMenu}
          >
            <i className="ci-chevron_left" title="Back" />
            {title}
          </button>
        }
        {children}
      </div>
    </>
  )
}

const Dropdown = props => {
  const {trigger, position = 'top left', title, children, className, over, withOverlay = true} = props;
  const [dropdownVisible, showDropdown] = useState(false);
  const [menuMounted, setMenuMounted] = useState(false);
  const unmountTimeout = useRef(null);
  const showTimeout = useRef(null);

  const showMenu = useCallback(() => {
    clearTimeout(unmountTimeout.current);
    setMenuMounted(true);
    showTimeout.current = setTimeout(() => showDropdown(true), 100);
  }, [unmountTimeout]);

  const hideMenu = useCallback(() => {
    clearTimeout(showTimeout.current);
    showDropdown(false);
    unmountTimeout.current = setTimeout(() => setMenuMounted(false), 200);
  }, [showTimeout]);

  const onClick = (e) => {
    showMenu();
    props.onClick && props.onClick(e);
  }

  useEffect(() => {
    return () => {
      clearTimeout(showTimeout.current);
      clearTimeout(unmountTimeout.current);
  }
  }, []);

  const handleKey = e => {
    if(dropdownVisible && e.key == 'Escape') {
      hideMenu();
    }
  }

  return (
    <div className={cx("HeaderDropdown", className, {over})} onKeyDown={handleKey}>
      {trigger && trigger({onClick, opened: dropdownVisible})}
      {menuMounted &&
        <DropdownMenu
          position={position}
          over={over}
          title={title}
          hideMenu={hideMenu}
          visible={dropdownVisible}
        >
          {children}
        </DropdownMenu>
      }
      {menuMounted && withOverlay &&
        <div
          className={cx("HeaderDropdown__overlay", {visible: dropdownVisible})}
          onClick={hideMenu}
        />
      }
    </div>
  )
}

export default Dropdown;
export {DropdownMenu};
