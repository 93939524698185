import api, {getUser} from 'api'
import * as _ from 'ramda'


export getConversations = (organizationCode, params = {}) ->
  api.get '/peerconversations/cplquery', params: _.merge params, organizationCode: organizationCode

export getMatchingGroups = (workshop) ->
  api.get "/peerconversationmatcher/workshop/#{workshop.id}"

export getMatchingGroup = (workshopId, groupId) ->
  api.get "/peerconversationmatcher/workshop/#{workshopId}", params: {groupId}
  .then ([group]) -> group

export deleteMatchingGroup = (workshopId, groupId, deleteNonEmpty = false) ->
  api.delete "/peerconversationmatcher/workshop/#{workshopId}/#{groupId}", params: {deleteNonEmpty}

export getMatches = (workshopId, groupId) ->
  api.get "/peerconversationmatcher/workshop/#{workshopId}/#{groupId}/matches"

export deleteMatch = (workshopId, groupId, matchId) ->
  api.delete "/peerconversationmatcher/workshop/#{workshopId}/#{groupId}/matches/#{matchId}"

export deleteMatches = (workshopId, groupId, matches) ->
  api.post "/peerconversationmatcher/workshop/#{workshopId}/#{groupId}/matches/delete", matches

export getRigScores = (workshopId) ->
  api.get "/rig/scores", params: {workshopId}

export matchMembers = (workshopId, groupId, matches) ->
  api.post "/peerconversationmatcher/workshop/#{workshopId}/#{groupId}/matches", {matches}

export smartMatch = (workshopId, groupId) =>
  api.post("/peerconversationmatcher/workshop/#{workshopId}/#{groupId}/smart-match", null, {params: {preDelete: true}})

export startConversations = (workshopId, groupId, dto) ->
  api.post "/peerconversationmatcher/workshop/#{workshopId}/#{groupId}/matches/start", dto

export getConversationsDefinitions = ->
  api.get '/peerconversationdefinition/list'

export getAdminConversationsDefinitions = (orgCode) ->
  api.get "/admin/peerconversationdefinition/list/#{orgCode}"

export addConversation = (workshop, data) ->
  api.post "/peerconversationmatcher/workshop/#{workshop.id}", data

export getWorkshops = (organizationCode) ->
  api.get "/organizations/#{organizationCode}/workshops"

export getWorkshop = (id) ->
  api.get "/workshops/byId/#{id}"

export updateMatchingGroup = (workshopId, groupId, dto) ->
  api.post "/peerconversationmatcher/workshop/#{workshopId}/#{groupId}", dto

export getOrganizations = ->
 api.get "/organizations"

export getMatchingPairs = (workshopId, pcmId, memberId) ->
  api.get "/peerconversationmatcher/workshop/#{workshopId}/matchingPairs", params: {pcmId, memberId}
