import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withRouter, Link} from 'react-router-dom'
import debounce from 'debounce-promise'
import qs from 'qs'
import AsyncSelect from 'react-select/async'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import Loader from 'theme/Loader'
import Input from 'theme/Multiinput'


import './Invite.sass'


DRIVERS = ['who', 'why', 'how']
MESSAGES =
  error:
    loading: 'Cannot load user group data. Please try again later or contact the administrator.'
    addMembers: 'Could not add member(s) to user group. Please try again later.'
  success:
    addMembers: 'Successfully added member(s).'


export default composeComponent 'Invite',
  withRouter
  withStateHandlers
    emails: []
    results: []
    loading: false
  ,
    setItems: -> (emails) -> {emails}
    setResults: -> (results) -> {results}
    setLoading: -> (loading) -> {loading}

  withHandlers
    invite: ({emails, setResults, setItems, setLoading, invite}) -> ->
      setLoading(true)
      invite emails
      .then setResults
      .then -> setItems []
      .finally -> setLoading(false)

  ({
    emails
    setItems
    invite
    results
    setResults
    loading
  }) ->

    React.createElement("div", {"className": "Invite grey-section"},
      React.createElement("div", {"className": "Invite__heading"},
        React.createElement("h2", null, "Invite Members"),
        (if emails.length && !loading
          React.createElement("div", {"className": "Invite__headingBtns"},
            React.createElement("button", { \
              "className": "btn btn_secondary btn_outlined_pink",  \
              "disabled": (emails.length == 0),  \
              "onClick": (-> setItems [])
            }, """
              Clear
"""),
            React.createElement("button", { \
              "className": "btn btn_secondary btn_solid_pink",  \
              "disabled": (emails.length == 0),  \
              "onClick": (invite)
            }, """
              Invite """, (emails.length), " member", (if emails.length > 1 then 's' else '')
            )
          )
        )
      ),
      React.createElement("div", {"className": "relative"},
        React.createElement(Loader, {"loading": (loading)}),
        (if results.length
          Created = results.filter((res) => res.status == 'Created')
          Added = results.filter((res) => res.status == 'Added')
          AlreadyExists = results.filter((res) => res.status == 'AlreadyExists')
          OrganizationSeatsLimitExceeded = results.filter((res) => res.status == 'OrganizationSeatsLimitExceeded')
          OrgConflict = results.filter((res) => res.status == 'OrgConflict')
          NotOrganizationEmployee = results.filter((res) => res.status == 'NotOrganizationEmployee')
          TargetNotExists = results.filter((res) => res.status == 'TargetNotExists')
          React.createElement("div", null,
            (if Added.length || AlreadyExists.length || Created.length
              React.createElement("div", {"className": "Invite__notification"},
                (if AlreadyExists.length
                  React.createElement("p", null,
                    React.createElement("span", {"className": "far fa-check-circle"}),
                    (AlreadyExists.length),
                    (if Added.length > 1
                      " members have "
                    else " member has "
                    ), """
                    been added earlier
""")
                ),
                (if Created.length
                  React.createElement("p", null,
                    React.createElement("span", {"className": "fal fa-check-circle"}),
                    (Created.length),
                    (if Created.length > 1
                      " invitations have "
                    else " invitation has "
                    ), """
                    been successfully sent
""")
                ),
                (if Added.length
                  React.createElement("p", null,
                    React.createElement("span", {"className": "fal fa-check-circle"}),
                    (Added.length), """ existing
""", (if Added.length > 1
                      " members have "
                    else " member has "
                    ), """
                    been added to cohort
""")
                )
              )
            ),
            (if OrganizationSeatsLimitExceeded.length || OrgConflict.length || NotOrganizationEmployee.length || TargetNotExists.length
              React.createElement("div", {"className": "Invite__notification error"},
                (if OrganizationSeatsLimitExceeded.length
                  React.createElement("p", null,
                    React.createElement("span", {"className": "far fa-exclamation-triangle"}),
                    (OrganizationSeatsLimitExceeded.length),
                    (if OrganizationSeatsLimitExceeded.length > 1
                      " members have "
                    else " member has "
                    ), """
                    not been invited because seats limit has been exceeded
""")
                ),
                (if OrgConflict.length
                  React.createElement("p", null,
                    React.createElement("span", {"className": "fal fa-exclamation-triangle"}),
                    (OrgConflict.length),
                    (if OrgConflict.length > 1
                      " members have "
                    else " member has "
                    ), """
                    not been invited because they are assigned to different organization
""")
                ),
                (if NotOrganizationEmployee.length
                  React.createElement("p", null,
                    React.createElement("span", {"className": "fal fa-exclamation-triangle"}),
                    (NotOrganizationEmployee.length),
                    (if NotOrganizationEmployee.length > 1
                      " members have "
                    else " member has "
                    ), """
                    not been invited because they are not employees
""")
                ),
                (if TargetNotExists.length
                  React.createElement("p", null,
                    React.createElement("span", {"className": "fal fa-exclamation-triangle"}),
                    (TargetNotExists.length),
                    (if TargetNotExists.length > 1
                      " members have "
                    else " member has "
                    ), """
                    not been invited because the cohort does not exist
""")
                )
              )
            ),
            React.createElement("div", {"className": "text-center margin-top-3"},
              React.createElement("button", {"className": "btn btn_secondary btn_outlined_bluePurple", "onClick": (-> setResults([]))}, """
                Invite more people
""")
            )
          )
        else
          React.createElement("div", null,
            React.createElement("div", {"className": "Invite__form"},
              React.createElement(Input, { \
                "items": (emails),  \
                "onChange": (setItems)
              })
            )
          )
        )
      )
    )
