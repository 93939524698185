import React, {useState, useEffect} from 'react';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import {getActionAreas, getSubInvestmentAreas} from 'conversations/requests';
import Loader from 'theme/Loader';
import * as Fields from './Fields';
import RIGScores from './RIGScores';


const Action = (props) => {
  const {children, answer, conversation, imAsking, rigScore, t, answering, setAnswer, user} = props;
  const [textFieldForOtherVisible, showTextFieldForOther] = useState(false);
  const [openFieldsVisible, showOpenFields] = useState(false);
  const [openFieldsTimeout, setOpenFieldsTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [areas, setAreas] = useState([]);
  const [subAreas, setSubAreas] = useState([]);

  const partner = (conversation.partner && conversation.partner.member) || {};

  const selectedArea = areas.find(a => a.id === answer.areaId);
  const subAreasForSelectedArea = (
    (selectedArea && subAreas.find(a => a.areaId == selectedArea.id)) || {subInvestmentAreas : []}
  ).subInvestmentAreas;

  const mappedSubAreas =
  (!subAreasForSelectedArea || subAreasForSelectedArea.length == 0)
    ? []
    : subAreasForSelectedArea.map(label => ({label})).concat({label: "Other", customOption: true});

  const allFieldsAnwered = answer.areaId !== null && answer.subInvestmentArea && answer.why && answer.what;

  const onChange = (keyValue = {}) => {
    setAnswer((a) => ({...a, ...keyValue}))
  }

  const selectArea = (area) => {
    onChange({subInvestmentArea: null});
    showTextFieldForOther(false);
    onChange({areaId: area.id});
  }

  const selectOption = (o) => {
    if(o.customOption) {
      onChange({subInvestmentArea: null});
      showTextFieldForOther(true);
      showOpenFields(false);
    }
    else {
      showTextFieldForOther(false);
      onChange({subInvestmentArea: o.label});
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getActionAreas(conversation.id).then(setAreas),
      getSubInvestmentAreas(conversation.id).then(setSubAreas)
    ]).then(
      () => setLoading(false),
      (reason) => {
        setLoading(false);
        setError({type: 'loading', reason});
      }
    );
  }, []);

  useEffect(() => {
    const subArea = answer && answer.subInvestmentArea;
    if(!subArea) return;
    const otherOption = !mappedSubAreas.find(a => a.label == subArea);
    if(otherOption) {
      setOpenFieldsTimeout(setTimeout(() => showOpenFields(true), 2000));
    } else {
      showOpenFields(true);
    }

    return () => {
      clearTimeout(openFieldsTimeout);
    }
  }, [answer]);

  return (
    <>
      <div className="Questions__convoSheetOption relative">
        <Loader loading={loading} />
        <div className="Questions__convoSheetOptionQuestion">
          <div className="Questions__questionTitle">
            {imAsking ? (`${partner.firstName}${partner.firstName[partner.firstName.length - 1] == 's' ? "'" : "'s"}`) : 'My'}
            <img alt="Imperative" className="Questions__questionTitle-img" src="https://assets.imperative.com/static.imperative.com/images/logos/logo_blue.png" />
          </div>

          {imAsking &&
            <div className="Questions__questionDynamicTitle black margin-top-2">
              Based on our conversation and your current fulfillment,
              what area feels like an <i>Imperative</i> to focus on before our next conversation?
            </div>
          }
          {!imAsking &&
            <>
              <div className="Questions__questionDynamicTitle black margin-top-2 light">
                Based on our conversation and your current fulfillment,
                let’s work together to design a specific <i>Imperative</i> commitment to focus on before our next conversation.
              </div>
              <div className="Questions__questionDynamicTitle black margin-top-2">
                Your coach will lead you through a series of questions to help you define your Imperative.
              </div>
            </>
          }
          {!imAsking &&
            <RIGScores
              conversation={conversation}
              t={t}
              rigScore={rigScore}
              activeColor='pink'
            />
          }
          {imAsking &&
            <div className="Questions__convoSheetAsking">
              <div className="Questions__actionSubareas">
                {areas.map((o) => {
                  return (
                    <button
                      key={o.id}
                      className={cx("Questions__actionSubareas-btn", {
                        active: o.id == answer.areaId
                      })}
                      onClick={() => selectArea(o)}
                    >
                      {"My " + o.areaName}
                    </button>
                  )
                })}
              </div>
              {!selectedArea &&
                <span className="Questions__carouselInfo">
                  {t.find("{{member.firstName}} is able to view and reflect on their response to their pre-conversation check-in today. It's a helpful reminder when brainstorming a meaningful action that will help their fulfillment and effectiveness.", partner)}
                </span>
              }
              {selectedArea &&
                <>
                  <div className="Questions__questionDynamicTitle black margin-top-2">
                    {selectedArea.areaName.toLowerCase() == 'relationships' && t.find("Which relationship(s)?")}
                    {selectedArea.areaName.toLowerCase() == 'impact' && t.find("Where do you want to make an impact? ")}
                    {selectedArea.areaName.toLowerCase() == 'growth' && t.find("In what area do you want to grow?")}
                  </div>
                  <div className="Questions__actionSubareas">
                    {mappedSubAreas.map((o) => {
                      return (
                        <button
                          key={o.label}
                          className={cx("Questions__actionSubareas-btn", {
                            active: o.label == answer.subInvestmentArea || (textFieldForOtherVisible && o.customOption)
                          })}
                          onClick={() => selectOption(o)}
                        >
                          {t.find(o.label)}
                        </button>
                      )
                    })}
                  </div>
                  {textFieldForOtherVisible &&
                    <Fields.Text
                      className="margin-top-2 small-input"
                      value={answer.subInvestmentArea || ''}
                      onChange={(subInvestmentArea) => onChange({subInvestmentArea})}
                      rich={false}
                      placeholder="Type your option..."
                      maxLength="50"
                    />
                  }
                  {!answer.subInvestmentArea && !textFieldForOtherVisible &&
                    <span className="Questions__carouselInfo">
                      Avoid sharing these options until your peer has answered in their own words
                    </span>
                  }
                </>
              }
              {answer.subInvestmentArea && openFieldsVisible &&
                <>
                  <div className="Questions__questionDynamicTitle black margin-top-2">
                    <span>What one action is an <i>Imperative</i> to do before our next conversation </span>
                    {selectedArea && selectedArea.areaName.toLowerCase() == 'relationships' &&
                      <span>to build your relationship with your </span>
                    }
                    {selectedArea && selectedArea.areaName.toLowerCase() == 'impact' &&
                      <span>to make an impact on </span>
                    }
                    {selectedArea && selectedArea.areaName.toLowerCase() == 'growth' &&
                      <span>to grow personally and professionally by working on your </span>
                    }
                    {t.find(answer.subInvestmentArea && answer.subInvestmentArea.toLowerCase())}
                    ?
                  </div>
                  <Fields.AutosizeInput
                    className="margin-top-2"
                    value={answer.what || ''}
                    onChange={(what) => onChange({what})}
                    placeholder={`Type ${partner.firstName}${partner.firstName[partner.firstName.length - 1] == 's' ? "'" : "'s"} answer...`}
                  />
                </>
              }
              {answer.subInvestmentArea && openFieldsVisible &&
                <>
                  <div className="Questions__questionDynamicTitle black margin-top-2">
                    Why is this an <i>Imperative</i> to do before our next conversation?
                  </div>
                  <Fields.AutosizeInput
                    className="margin-top-2"
                    value={answer.why || ''}
                    onChange={(why) => onChange({why})}
                    placeholder={`Type ${partner.firstName}${partner.firstName[partner.firstName.length - 1] == 's' ? "'" : "'s"} answer...`}
                  />
                </>
              }
            </div>
          }
        </div>
      </div>
      {children({
        ...props,
        disabled: !allFieldsAnwered
      })}
    </>
  )
}

export default Action;
