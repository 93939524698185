import React from 'react'
import cx from 'classnames'
import * as _ from 'ramda'
import {getIn, useField} from 'formik';
import RSelect, {components} from 'react-select'

import './Form.sass'

export const TextField = ({field, form, label, required, textarea, withoutError, className, info, ...props}) => {
  const touched = getIn(form.touched, field.name);
  const error = getIn(form.errors, field.name);
  const {disabled} = props;
  return (
    <div className={cx("Form__field", className, {disabled})}>
      <div className="Form__label">
        {label &&
          <label className={cx({required})} htmlFor={field.name}>
            {label}
          </label>
        }
        <div className="Form__labelInfos">
          {touched && error && !withoutError &&
            <span className={cx("Form__fieldError", {withLabel: label})}>{error}</span>
          }
          {!error && info &&
            <span className={cx("Form__fieldInfo", {withLabel: label})}>{info}</span>
          }
        </div>
      </div>
      <div className="Form__input">
        {textarea
        ? <textarea
            className={cx({
              'has-value': field.value && field.value != '',
              err: touched && error,
            })}
            {...field}
            {...props}
          />
        :  <input
            className={cx({
              'has-value': field.value && field.value != '',
              err: touched && error,
            })}
            {...field}
            {...props}
          />
        }
      </div>
    </div>
  )
}

export const Select = ({
  field: {name},
  form,
  label,
  required,
  options,
  selectClassName,
  optionClassName,
  isMulti,
  search,
  creatable,
  withoutError,
  className,
  selectComponents = {},
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const {setValue} = helpers;

  const touched = getIn(form.touched, field.name);
  const error = getIn(form.errors, field.name);
  const {disabled} = props;

  const Option = (optionProps) =>
    <div className={cx("Form__selectOption", optionClassName && optionClassName(optionProps.data))}>
      <components.Option {...optionProps} />
    </div>

  const selectedOption = options && (
    isMulti
      ? options.filter(o => field.value.indexOf(o.value) > -1)
      : options.find(o => field.value == o.value)
  )

  const handleChange = (option) => {
    const value = isMulti ? _.pluck('value', option) : option.value;
    setValue(value)
  }

  if(!field.name) return null;

  return (
    <div className={cx("Form__field", className, {disabled})}>
      <div className="Form__label">
        {label &&
          <label className={cx({required})} htmlFor={field.name}>
            {label}
          </label>
        }
        <div className="Form__labelInfos">
          {touched && error && !withoutError &&
            <span className={cx("Form__fieldError", {withLabel: label})}>{error}</span>
          }
        </div>
      </div>
      <div className="Form__input">
        <RSelect
          {...field}
          {...props}
          inputId={field.name}
          className={cx("Form__select", {err: touched && error}, selectClassName)}
          classNamePrefix={cx("Form__select", selectClassName)}
          options={options}
          onChange={handleChange}
          value={selectedOption}
          autoBlur
          isMulti={isMulti}
          components={{Option, ...selectComponents}}
        />
      </div>
    </div>
  )
}

const NewOptionsTooltip = () =>
  <div className='Form__tooltip'>
    <div className='Form__tooltipContent'>
      New options available, click to expand
    </div>
  </div>

export const SelectCustom = ({
  field: {name},
  form,
  label,
  required,
  options,
  selectClassName,
  optionClassName,
  search,
  creatable,
  withoutError,
  className,
  selectComponents = {},
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const {setValue} = helpers;
  
  const value = field.value && field.value.answer;
  const textValue = (field.value && field.value.answerText) || '';

  const touched = getIn(form.touched, field.name);
  const error = getIn(form.errors, field.name);
  const {disabled} = props;

  const Option = (optionProps) =>
    <div className={cx("Form__selectOption", optionClassName && optionClassName(optionProps.data))}>
      <components.Option {...optionProps} />
    </div>

  const selectedOption = options && value && options.find(o => value == o.id && (!o.answerText || o.answerText == textValue));

  const handleChange = (option) => {
    if(option.hasTextField) {
      setValue({...field.value, answer: option.id, answerText: ''});
    } else {
      setValue({...field.value, answer: option.id, answerText: option.answerText});
    }
  }

  const DropdownIndicator = (indicatorProps) =>
    <>
      <NewOptionsTooltip />
      <components.DropdownIndicator {...indicatorProps} />
    </>

  if(!field.name) return null;

  return (
    <div className={cx("Form__field", className, {disabled})}>
      <div className="Form__label">
        {label &&
          <label className={cx({required})} htmlFor={field.name}>
            {label}
          </label>
        }
        <div className="Form__labelInfos">
          {touched && error && !withoutError && !(selectedOption && selectedOption.hasTextField) &&
            <span className={cx("Form__fieldError", {withLabel: label})}>{error}</span>
          }
        </div>
      </div>
      <div className="Form__input">
        <RSelect
          {...field}
          {...props}
          inputId={field.name}
          className={cx("Form__select", {err: touched && error}, selectClassName)}
          classNamePrefix={cx("Form__select", selectClassName)}
          options={options}
          onChange={handleChange}
          value={selectedOption}
          autoBlur
          components={{Option, DropdownIndicator, ...selectComponents}}
        />
        {selectedOption && selectedOption.hasTextField &&
          <div key="openField">
            <input
              className={error && 'err'}
              type="text"
              maxLength="50"
              value={textValue}
              onChange={(e) => setValue({...field.value, answerText: e.target.value})}
            />
            <div className="Assessment__optionTextInfos">
              <p>Maximum 50 characters</p>
              {error &&
                <p className="Assessment__optionTextInfos_error">{error}</p>
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}
