import React, {useContext, useEffect, useState, useCallback, useMemo, useRef} from 'react';
import moment from 'moment-timezone';
import * as _ from 'ramda';
import {Link} from 'react-router-dom';
import TranslationsContext from 'translations';
import cx from 'classnames';
import Tippy from '@tippyjs/react'
import * as ME from 'api/metrics';
import {fetchUser} from 'auth/requests';
import Footer from 'theme/Footer';
import Toast from 'theme/Toast';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import {IMG_AARON} from 'theme/assets/assets';
import * as states from 'utils/states'
import computeProps from 'conversations/computeAdditionalProps';
import {
  updateConversation,
  getAnswers,
  sendAnswer,
  answerAvailable,
  getParticipants,
  getConversation,
  getUserConversations,
  getConversationsDefinitions,
  getPreviousCommitment,
  sendActionConfirmation,
  sendActionCommitment,
  syncActionCommitment,
  syncActionConfirmation,
  checkIfRigIsEnabled,
  sendConversationRIGScore,
  getConversationRIGMetadata,
  getConversationRIGScore,
  createOneWord,
  updateOneWord,
  syncOneWord,
  getOneWords,
  syncConversation,
  sendJoinNotification,
  getConversationQuestionsAnswers,
  getConversationQuestionsDefinitions,
  sendConversationQuestionsAnswer,
  getRIGScores
}          from 'conversations/requests'
import prepareQuestions from './prepareQuestions';
import Onboarding from './Onboarding';
import Agenda from './Agenda';
import Notification from './Notification';
import ModalRIG from './ModalRIG';
import Video from './Video';
import * as Toasts from './Toasts';
import SaveAndExitBtn from './SaveAndExitBtn';
import ScheduleNextConversations from './ScheduleNextConversations';


import './Questions.sass';
import Question from './Question';
import RelationshipModal from './RelationshipModal';

const BothWriteAnswer = 'BothWriteAnswer';

const questionsOrder = [
  'oneWordBeforeQuestions',
  'actionConfirmations',
  'questions',
  'actions',
  'oneWordAfterQuestions'
];


const MESSAGES = {
  error: {
    loading: 'Cannot load data. Please try again later or contact the administrator.',
    answer: 'Something went wrong while sending answer.',
    finish: 'Something went wrong while finishing conversation.',
    startConversation: 'Something went wrong while starting conversation.',
    schedule: 'Cannot schedule next conversation',
  }
}


const config = window.config;
const SYNC_INTERVAL = 2000;


const Questions = (props) => {
  const {match: {params}, history} = props;
  const conversationId = params.conversationId;
  const t = useContext(TranslationsContext);

  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [warningVisible, setWarningVisible] = useState(false);
  const [answering, setAnswering] = useState(false);
  const [toasts, setToasts] = useState({});
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState(null);
  const [definitions, setDefinitions] = useState([]);
  const [previousCommitments, setPreviousCommitments] = useState([]);
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [confirmations, setConfirmations] = useState([]);
  const [commitments, setCommitments] = useState([]);
  const [oneWords, setOneWords] = useState([]);
  const [allOneWords, setAllOneWords] = useState(null);
  const [rigScores, setRigScores] = useState([]);
  const [rigScore, setRigScore] = useState({});
  const [notMappedConversation, setConversation] = useState(null);
  const [rigModalVisible, showRigModal] = useState(null);
  const [finishModal, showFinishModal] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const syncInterval = useRef(null);
  const transitionAvailableInterval = useRef(null);
  const [partnerStatus, setPartnerStatus] = useState(null);
  const [notificationSent, setNotificationSent] = useState(false);
  const [nextConversationScheduled, setNextConversationScheduled] = useState(false);
  const [conversationQuestionsAnswers, setConversationQuestionsAnswers] = useState([]);
  const [conversationQuestionsDefinitions, setConversationQuestionsDefinitions]  = useState([]);
  const [firstRelationshipAnswer, setFirstRelationshipAnswer] = useState(null);

  const shouldSendNotification = useMemo(() => {
    return Boolean(partnerStatus && !partnerStatus.online);
  }, [partnerStatus]);

  const mapConversation = useCallback((conv) => {
    if(!conv || !user || !definitions.length || !partners.length)
      return null;
    const [program, set, convLabel] = conv.label.split('/');
    const me = conv.participant.find((p) => p.member.memberId == user.id) || {};
    const partner = conv.participant.find((p) => p.member.memberId != user.id) || {};
    const meData = me ? partners.find((p) => p.slug == user.slug) : {};
    const memberData = partner ? partners.find((p) => p.slug == partner.member.slug) : {};

    const c = {
      ...conv,
      program,
      set,
      convLabel,
      me: {...me, ...user, member: {...(me.member || {}), ...meData, organization: user.organization}},
      partner: {...partner, ...memberData, member: {...(partner.member || {}), ...memberData, organization: user.organization}},
      goals: t.getSplitted(`peerconversation.conversation.${convLabel}.goals`),
    }

    return c;
  }, [user, definitions, partners]);

  const conversation = useMemo(() => {
    return mapConversation(notMappedConversation);
  }, [user, notMappedConversation, definitions, partners]);


  const {
    nextConversation,
    nextConversations = [],
    conversationIndexInSet,
    datePaired,
    allPrevConvsFinished,
    prevConvsWithPartner,
    onboardingVisible} = useMemo(
      () => computeProps(conversation, definitions, conversations, user),
      [conversations, conversation, definitions, user]
    );

  useEffect(() => {
    if(prevConvsWithPartner != null && !allPrevConvsFinished) {
      history.replace('/');
    }
  }, [prevConvsWithPartner, allPrevConvsFinished]);

  useEffect(() => {
    if(conversation && !allOneWords) {
      const pcId = [].concat(prevConvsWithPartner, conversation).map(c => c.id)
      loadAllOneWords({pcId});
    }
  }, [prevConvsWithPartner, allOneWords, conversation]);

  const convsWithScores = useMemo(() => {
    if(!conversation) return [];
    return [].concat(prevConvsWithPartner, conversation).filter(Boolean).map(c => {
      const participant = c.participant.map(p => {
        const oneWord = (allOneWords || []).find(o => c && o.peerConversationId == c.id && p.member.memberId == o.forMemberId);
        const rig = rigScores.filter(r => c && r.peerConversationId == c.id && p.member.memberId == user.id);
        return {...p, oneWord, rig}
    })
      return {...c, participant};
    })
  }, [user, prevConvsWithPartner, allOneWords, conversation, rigScores]);

  const relationshipQuestion = useMemo(() => {
    const q = conversationQuestionsDefinitions.find(def => def.question && user && user.org &&
      (def.question.id == conversationIndexInSet + 1) && def.question.orgExclude.indexOf(user.org.id) == -1
      );
    return q;
  }, [user, conversationIndexInSet, conversationQuestionsDefinitions]);

  const firstConvoInSet = useMemo(() => {
    return prevConvsWithPartner && (prevConvsWithPartner.find(c => c.convIInSet == 0) || prevConvsWithPartner[0]);
  }, [prevConvsWithPartner]);

  useEffect(() => {
    if(firstConvoInSet) {
      getConversationQuestionsAnswers(firstConvoInSet.id)
      .then((res) => {
        const answers = res.answers || [];
        const recent = answers.reduce((r, a) => {
          if(a.questionId != 1) return r;
          if(!r) return a;
          if(moment(a).isAfter(moment(r))) {
            return a;
          }
          return r;
        }, null);
        setFirstRelationshipAnswer(recent);
      })
    }
  }, [firstConvoInSet]);

  const conversationQuestionsModalVisible = useMemo(() => {
    if(!conversationQuestionsDefinitions.length) return null;
    const questionAnswered = relationshipQuestion && conversationQuestionsAnswers.find(a => a.questionId == relationshipQuestion.question.id && a.answer != null);
    return !questionAnswered;
  }, [conversationQuestionsAnswers, relationshipQuestion, conversationQuestionsDefinitions]);

  const nextConversationsToSchedule = useMemo(() => {
    return nextConversations.map(c => {
      const program = conversation && definitions.find(p => p.label == conversation.program);
      const set = program && program.sets.find(s => s.label == conversation.set);
      const i = set && set.definitions.findIndex(d => d.label == c.label);
      return {...c, orderIndex: i + 1};
    }).sort((a, b) => a.orderIndex - b.orderIndex)
  }, [nextConversations, definitions, conversation]);

  const [nextConversationsDates, setNextConversationsDates] = useState({});

  const changeNextConvDate = useCallback((conversationId, scheduleDate, scheduleTimezone) => {
    setNextConversationsDates(prevState => ({...prevState, [conversationId]: {scheduleDate, scheduleTimezone}}));
  }, []);

  const meFirst = useMemo(() => {
    return conversation && conversation.me.meFirst;
  }, [conversation]);

  const answers = useMemo(() => {
    if(!conversation)
      return [];
    const oneWordsAnswers = oneWords.reduce((res, oneWord) => {
      return res.concat([
        ...(oneWord.preConvWord
          ? [{
            ...oneWord,
            memberId: oneWord.byMemberId,
            questionId: 'oneWordBefore',
            answer: oneWord.preConvWord
          }]
          : []
        ),
        ...(oneWord.postConvWord
          ? [{
            ...oneWord,
            memberId: oneWord.byMemberId,
            questionId: 'oneWordAfter',
            answer: oneWord.postConvWord
          }]
          : []
        )
      ])
    }, []);

    const confirmationsAnswers = confirmations.map((c) => {
      const byMember = conversation.participant.find((p) => p.member.memberId != c.memberId);
      return {
        ...c,
        questionId: 'actionConfirmation',
        forMemberId: c.memberId,
        memberId: byMember && byMember.member.memberId
      }
    });

    const actionsAnswers = commitments.map(c => {
      const forMember = conversation.participant.find((p) => p.member.memberId != c.memberId);
      return {
        ...c,
        questionId: 'action',
        memberId: c.memberId,
        forMemberId: forMember && forMember.member.memberId
      }
    });


    const questionAnswersMapped = questionAnswers.map((a) => {
      const forMemberId = a.answerMemberId;
      const memberId = a.memberId;
      return {
        ...a,
        forMemberId,
        memberId
      }
    })
    return [].concat(questionAnswersMapped, confirmationsAnswers, actionsAnswers, oneWordsAnswers);
  }, [questionAnswers, confirmations, oneWords, conversation, commitments]);

  const allQuestions = useMemo(() => {
    const qs = prepareQuestions(conversation, previousCommitments, user, oneWords);
    return qs;
  }, [conversation, user, previousCommitments, oneWords]);

  const {oneWordBeforeQuestions, actionConfirmations, actions = [], oneWordAfterQuestions} = allQuestions;

  const questionsArray = questionsOrder.reduce((res, key) => res.concat(allQuestions[key]), []);

  const isQuestionAnswered = useCallback((q) => {
    if(!q) return false;
    return answers.find((a) => a.memberId == q.memberId && q.label == a.questionId);
  }, [answers]);

  const activeQuestionI = useMemo(() => {
    const questionI = questionsArray.findIndex((q) => {
      return !isQuestionAnswered(q);
    })
    return questionI;
  }, [answers, questionsArray]);

  const question = useMemo(() => {
    return questionsArray[activeQuestionI];
  }, [questionsArray, activeQuestionI]);

  const imAsking = useMemo(() => question && question.imAsking, [question]);

  const getUser = useCallback(() => {
    return fetchUser()
      .then(
        setUser,
        (error) => {
          ME.reportIntermediateError('Conversation: loading user error', error);
          setStatus({type: 'error', error, reason: 'loading'});
          return error;
        }
      )
  }, []);

  const loadConversation = useCallback(() => {
    return getConversation(conversationId)
    .then(
      (conversation) => {
        setConversation(conversation);
        loadParticipants(conversation.participant.map(p => p.member.slug));
        return conversation;
      }
    )
    .catch((error) => {
      ME.reportIntermediateError('Conversation: loading conversation', error, {conversationId});
      setStatus({type: 'error', error, reason: 'loading'});
    })
  }, []);

  const loadParticipants = useCallback((ids) => {
    return getParticipants(ids)
    .then(
      setPartners,
      (error) => {
        ME.reportIntermediateError('Conversation: loading participants', error, {conversationId});
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadDefinitions = useCallback(() => {
    return getConversationsDefinitions()
    .then(
      setDefinitions,
      (error) => {
        ME.reportIntermediateError('Conversation: loading conversation definitions', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadPreviousActions = useCallback(() => {
    return getPreviousCommitment(conversationId)
    .then(
      setPreviousCommitments,
      (error) => {
        ME.reportIntermediateError('Conversation: loading previous actions', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadAnswers = useCallback(() => {
    return getAnswers(conversationId)
    .then(
      setQuestionAnswers,
      (error) => {
        ME.reportIntermediateError('Conversation: loading answers', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadConfirmations = useCallback(() => {
    return syncActionConfirmation(conversationId)
    .then(
      setConfirmations,
      (error) => {
        ME.reportIntermediateError('Conversation: loading actions confirmations', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadCommitments = useCallback(() => {
    return syncActionCommitment(conversationId)
    .then(
      setCommitments,
      (error) => {
        ME.reportIntermediateError('Conversation: loading actions confirmations', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadOneWords = useCallback(() => {
    return syncOneWord(conversationId)
    .then(
      setOneWords,
      (error) => {
        ME.reportIntermediateError('Conversation: loading one words checkins', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadAllOneWords = useCallback((params) => {
    return getOneWords(params)
    .then(
      setAllOneWords,
      (error) => {
        ME.reportIntermediateError('Conversation: loading all one words checkins', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadRIGScores = useCallback(() => {
    return getRIGScores()
    .then(
      setRigScores,
      (error) => {
        ME.reportIntermediateError('Conversation: loading rig scores', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  }, []);

  const loadConversations = useCallback(() => {
    return getUserConversations()
    .then(
      setConversations,
      (error) => {
        ME.reportIntermediateError('Conversation: loading conversations', error);
        setStatus({type: 'error', error, reason: 'loading'});
      }
    )
  });

  const loadRig = useCallback(() => {
    checkIfRigIsEnabled(conversationId)
    .then(({rigPulseEnabled}) => {
      if (rigPulseEnabled) {
        getConversationRIGScore(conversationId)
        .then(setRigScore);

        getConversationRIGMetadata(conversationId)
        .then((metas) => {
          const rigTimeFrame = (config && config.rig && config.rig.rigTimeFrame) || 86400000
          const isLessThanRigTimeFrame = metas.find((meta) => moment().diff(moment(meta.rigLastFinishedAt), 'milliseconds') < parseInt(rigTimeFrame)) != null;
          showRigModal(!isLessThanRigTimeFrame);
        })
      } else showRigModal(false)
    }).catch((error) => {
      ME.reportIntermediateError('Conversation: loading rig', error);
      setStatus({type: 'error', error, reason: 'loading'});
    })
  }, []);


  const loadConvQuestions = useCallback(() => {
    getConversationQuestionsAnswers(conversationId)
    .then((res) => setConversationQuestionsAnswers(res.answers || []));

    getConversationQuestionsDefinitions()
    .then((res) => setConversationQuestionsDefinitions(res.questions || []));
  }, [conversationId])

  const syncVideoStatus = (videoStatus, meFirst, nextConversationScheduled) => {
    const requestStart = moment();
    if(user) {
      const newStatus = videoStatus || (videoEnabled ? 'Connected' : 'CameraOff')
      ME.reportStateChange("Conversation: sync loading", {videoStatus: newStatus, scheduleDateConfirmed: meFirst && nextConversationScheduled})

      return syncConversation(conversationId, {videoStatus: newStatus, scheduleDateConfirmed: meFirst && nextConversationScheduled})
      .then((statuses) => {
        ME.reportStateChange("Conversation: sync loaded", {statuses});
        const partnerStatus = statuses.find((a) => a.memberId != user.id);
        const myStatus = statuses.find((a) => a.memberId == user.id);
        const now = moment();
        const online = partnerStatus && partnerStatus.lastSync && now.diff(moment(partnerStatus.lastSync), 'ms') < SYNC_INTERVAL * 70;
        setPartnerStatus({
          online,
          videoStatus:
          partnerStatus && !online
            ? 'Disconnected'
            : partnerStatus && partnerStatus.videoStatus
        });

        if(!meFirst && !nextConversationScheduled) {
          loadConversations()
          .then(() => {
            partnerStatus && setNextConversationScheduled(partnerStatus.scheduleDateConfirmed);
          })
        }

        if(now.diff(requestStart, 'ms') >= SYNC_INTERVAL * 5)
          setWarningVisible(true)
        else
          setWarningVisible(false)
      })
      .catch((error) =>
        ME.reportIntermediateError("Conversation: sync error", error)
      )
    }
    else return Promise.reject('no user')
  };

  const isTransitionAvailable = () => {
    if(question && question.answerType == BothWriteAnswer)
      return;
    if(conversation)
      if(question && question.actionConfirmation) {
        ME.reportStateChange("Conversation: Loading action confirmation", {conversationId});
        loadConfirmations()
      } else if (question && (question.oneWordAfter || question.oneWordBefore)) {
        ME.reportStateChange("Conversation: Loading one words", {conversationId});
        loadOneWords();
      } else if (question && question.action) {
        ME.reportStateChange("Conversation: Loading action commitment", {conversationId});
        loadCommitments();
      }
      else {
        const lastModified = _.reduce(_.max, 1, _.pluck('modifiedAt', questionAnswers))
        ME.reportStateChange("Conversation: Checking if transition available", {conversationId: conversationId, lastModified})
        const convReqs = conversation.me.state == 'Finished' && !meFirst && nextConversation && !nextConversation.scheduleDate
          ? loadConversations()
          : Promise.resolve();
        const convReq = conversation.me.state == 'Finished' && conversation.state != 'Finished'
          ? loadConversation()
          : Promise.resolve();
        Promise.all([
          answerAvailable(conversation, lastModified),
          convReqs,
          convReq
        ]).then(
          () => {
            ME.reportStateChange("Conversation: transition available", {conversationId: conversationId, lastModified})
            loadAnswers();
          },
          (err) => {
            ME.reportStateChange("Conversation: transition unavailable", {conversationId: conversationId})
            return null
          }
        )
      }
  }

  const saveOneWord = useCallback((answer, question, forMember) => {
    setStatus(null);
    ME.reportStateChange(`Conversation: Sending one word ${answer.postConvWord ? 'after' : 'before'}`, {
      sourcePeerConversationId: answer.peerConversationId,
      forMember,
    });
    const req = question.id && question.preConvWord
    ? updateOneWord(conversationId, question.id, {postConvWord: answer.postConvWord})
    : createOneWord(conversationId, answer);

    return req
      .then(loadOneWords)
      .then(() => {
        loadAllOneWords();
        ME.reportStateChange("Conversation: One word sent", {conversationId});
      }, (error) => {
        ME.reportIntermediateError("Conversation: One word sending error", error, {conversationId});
        setStatus({type: 'error', error: 'answer', reason: 'answer'});
      })
  }, []);

  const saveActionCommitment = useCallback((answer, action, forMember) => {
    setStatus(null);
    ME.reportStateChange("Conversation: Sending action commitment", {
      conversationId: conversationId,
      sourcePeerConversationId: answer.peerConversationId,
      forMember,
      action
    });
    return sendActionCommitment(conversationId, answer)
    .then(loadCommitments)
    .then(() => {
      ME.reportStateChange("Conversation: Action commitment sent", {conversationId});
    }, (error) => {
      ME.reportIntermediateError("Conversation: Action commitment sending error", error, {conversationId});
      setStatus({type: 'error', error: 'answer', reason: 'answer'});
    })
  }, []);

  const saveActionConfirmation = useCallback((answer, action, forMember) => {
    setStatus(null);
    ME.reportStateChange("Conversation: Sending action confirmation", {
      conversationId: conversationId,
      sourcePeerConversationId: answer.peerConversationId,
      forMember,
      action
    });
    return sendActionConfirmation(conversationId, answer)
    .then(loadConfirmations)
    .then(() => {
      ME.reportStateChange("Conversation: Action confirmation sent", {conversationId});
    }, (error) => {
      ME.reportIntermediateError("Conversation: Action confirmation sending error", error, {conversationId});
      setStatus({type: 'error', error: 'answer', reason: 'answer'});
    })
  }, []);

  const saveAnswer = useCallback((answer, question, forMember) => {
    setStatus(null);
    ME.reportStateChange("Conversation: Sending question answer", {
      conversationId: conversationId,
      label: question.label,
      forMember
    });
    return sendAnswer(conversationId, question.label, answer, forMember)
    .then(loadAnswers)
    .then(() => {
      ME.reportStateChange("Conversation: Answer sent", {conversationId});
    }, (error) => {
      ME.reportIntermediateError("Conversation: Answer sending error", error, {conversationId});
      setStatus({type: 'error', error: 'answer', reason: 'answer'});
    })
  }, []);

  const schedulePicked = (obj) => {
    return obj && obj.scheduleDate && obj.scheduleTimezone;
  }

  const convsScheduledCount = useMemo(() => {
    return Object.keys(nextConversationsDates).filter(k => schedulePicked(nextConversationsDates[k])).length;
  }, [nextConversationsDates]);

  const scheduleNextConversation = useCallback(() => {
    setStatus(null);
    ME.reportStateChange("Conversation: scheduling conversation", {conversationId});
    setAnswering(true);

    const nextConvsUpdated = Object.keys(nextConversationsDates).filter(k => schedulePicked(nextConversationsDates[k])).map(id => {
      const convObj = nextConversations.find(c => c.id == id);
      const dto = {
        scheduleTo: nextConversationsDates[id].scheduleDate,
        timezone: nextConversationsDates[id].scheduleTimezone,
        previousPeerConversationId: conversationId,
        reschedule: Boolean(convObj && convObj.scheduleDate && convObj.scheduleDate != nextConversationsDates[id].scheduleDate)
      };
      ME.reportStateChange("Conversation: scheduling conversation", {conversationId: id});
      return updateConversation({id}, dto)
      .then(
        () => ME.reportStateChange("Conversation: conversation scheduled", {conversationId: id})
        , (error) => {
          ME.reportIntermediateError("Conversation: conversation scheduling error", error, {conversationId: id})
          return Promise.reject(error);
        }
      )
    });

    return Promise.all(nextConvsUpdated)
    .then(
      () => {
        setAnswering(false);
        setNextConversationScheduled(true);
        loadConversations();
      }, (error) => {
        setAnswering(false)
        loadConversations();
        setStatus({type: 'error', error, reason: 'schedule'});
    })
  }, [nextConversationsDates, conversationId]);

  useEffect(() => {
    if(conversation && conversation.me && states.isBefore(conversation.me.state, states.Started)) {
      updateConversation(conversation, {state: states.Started})
      .then(setConversation);
    }
  }, [conversation]);

  useEffect(() => {
    if(!syncInterval.current && user) {
      syncVideoStatus(null, meFirst, nextConversationScheduled);
      syncInterval.current = setInterval(() => syncVideoStatus(null, meFirst, nextConversationScheduled), SYNC_INTERVAL);
    }
    if(!transitionAvailableInterval.current && conversation) {
      transitionAvailableInterval.current = setInterval(isTransitionAvailable, SYNC_INTERVAL);
    }
    return () => {
      clearInterval(syncInterval.current);
      syncInterval.current = null;
      clearInterval(transitionAvailableInterval.current);
      transitionAvailableInterval.current = null;
    }
  }, [syncInterval, user, transitionAvailableInterval, question, conversation, nextConversationScheduled, meFirst]);

  useEffect(() => {
    setLoading(true);
    ME.reportStateChange('Dashboard: loading');

    Promise.all([
        loadConversation(),
        loadDefinitions(),
        loadPreviousActions(),
        loadAnswers(),
        loadConfirmations(),
        loadCommitments(),
        loadRig(),
        loadConversations(),
        loadOneWords(),
        getUser(),
        loadConvQuestions(),
        loadRIGScores()
    ]).then(
      ([conversation]) => {
        if (conversation.state == states.Finished) {
          history.replace(`/peer-coaching/${conversation.id}`);
        } else setLoading(false);
      },
      () => setStatus('loading')
    )
  }, []);

  const saveRIGPart = useCallback((answers) => {
    const scores = {
      rigScores: answers,
      setLabel: conversation.label
    }

    setLoading(true)
    ME.reportStateChange("Conversation: saving rig score", {conversationId})

    sendConversationRIGScore(conversationId, scores)
    .then(() => {
      ME.reportStateChange("Conversation: rig score sent", {conversationId});
      getConversationRIGScore(conversationId)
      .then(setRigScore)
      .then(() => {
        loadRIGScores()
        .then(() => {
          showRigModal(false);
          setLoading(false);
        })
      })
    })
    .catch((err) => {
      ME.reportIntermediateError("Conversation: error sending rig score", err, {conversationId: conversationId});
      showRigModal(false)
      setLoading(false);
    });
  }, [conversation]);

  const saveConversationQuestionsAnswer = useCallback((answer = [], questionId) => {
    setLoading(true);
    ME.reportStateChange("Conversation: saving conversations question answer", {conversationId});
    const dto = answer.map(a => ({value: a, questionId}));

    return new Promise((resolve, reject) => {
      sendConversationQuestionsAnswer(conversationId, dto)
      .then((ans) => {
        ME.reportStateChange("Conversation: conversations question answer sent", {conversationId});
        getConversationQuestionsAnswers(conversationId)
        .then((res) => setConversationQuestionsAnswers(res.answers || []))
        .then(() => {
          setLoading(false);
          resolve(ans);
        })
      }).catch((err) => {
        ME.reportIntermediateError("Conversation: error sending conversation question answer", err, {conversationId});
        setLoading(false);
        reject(err);
      });
    })
  }, [conversationId]);


  const hideToast = (toastName) => {
    setToasts({
      ...toasts,
      [toastName]: 'shown'
    })
  };

  const showToast = (toastName) => {
    setToasts({
      ...toasts,
      [toastName]: setInterval((() => hideToast(toastName)), 12000)
    });
  };

  const allQuestionsAnswered = useMemo(() => {
    return questionsArray.every(isQuestionAnswered);
  }, [isQuestionAnswered, questionsArray, answers]);

  const shouldSetFinishedState = useMemo(() => {
    return questionsArray.filter(q => !q.oneWordAfter).every(isQuestionAnswered);
  }, [isQuestionAnswered, questionsArray, answers]);

  useEffect(() => {
    if(shouldSetFinishedState && user && conversation && (conversation.me.state == states.Started || conversation.me.state == states.Conducted)) {
      getConversation(conversationId)
      .then((c) => {
        const me = c.participant.find((p) => p.member.memberId == user.id);
        if(me.state == states.Started || me.state == states.Conducted) {
          ME.reportStateChange("Conversation: updating conversation", {conversationId: conversationId, state: states.Finished});
          updateConversation(c, {state: states.Finished})
          .then(setConversation)
        }
        else {
          setConversation(c);
        }
      })
    }
  }, [shouldSetFinishedState, user, conversation]);

  useEffect(() => {
    if (conversationIndexInSet == 0 && question) {
      if (question._i == 0 && imAsking && !toasts.YouAreAsking)
        showToast('YouAreAsking')
      if (question._i == 0 && !imAsking && !toasts.YouAreAnswering)
        showToast('YouAreAnswering')
    }
    return () => {
      Object.keys(toasts).map((key) => clearInterval(toasts[key]));
    }
  }, [conversationIndexInSet, question, toasts]);

  useEffect(() => {
    if(!notificationSent && shouldSendNotification) {
      sendJoinNotification(conversationId)
      .then(() => {
        setNotificationSent(true);
      })
    }
  }, [shouldSendNotification]);

  return (
    <div className="Questions page">
      <div className="Questions__agendaDesktop">
        {conversation &&
          <Agenda
            t={t}
            loading={loading}
            conversation={conversation}
            programsList={definitions}
            isQuestionAnswered={isQuestionAnswered}
            questions={questionsArray}
            startQuestions={[].concat(oneWordBeforeQuestions, actionConfirmations)}
            wrapUpQuestions={[].concat(actions, oneWordAfterQuestions)}
            datePaired={datePaired}
          />
        }
      </div>
      <main className="container">
        <Loader loading={loading} />
        {conversation &&
          <>
            {conversation.participant.length == 1 &&
              <Notification type='error' sticky className="Questions__notification">
                <div className="Questions__notificationContent">
                  <div className="Video__notificationIcon error">
                    <i className="fas fa-exclamation-triangle" />
                  </div>
                  <div>
                    <div className="Video__notificationTitle">This conversation is no longer available</div>
                  <div className="Video__notificationMessage">The previous partner is unmatched, so the conversation can{"'"}t be completed</div>
                  </div>
                </div>
              </Notification>
            }
            {warningVisible &&
              <Notification type='error' sticky className="Questions__notification">
                <div className="Questions__notificationContent">
                  <div className="Video__notificationIcon error">
                    <i className="fas fa-exclamation-triangle" />
                  </div>
                  <div>
                    <div className="Video__notificationTitle">Network connectivity issue</div>
                    <div className="Video__notificationMessage">The conversation could not be advanced due to a connecivity issue.
                      Please check that you are connected to the internet, and try again. If problem persists, contact your IT
                      specialist to troubleshoot the issue.</div>
                  </div>
                </div>
              </Notification>
            }
            {conversation.participant.length == 2 &&
              <div className="Questions__convo">
                <div className="Questions__convoVideo">
                  {rigModalVisible != null && !rigModalVisible && conversationQuestionsModalVisible != null && !conversationQuestionsModalVisible &&
                    <Video
                      partnerStatus={partnerStatus && partnerStatus.videoStatus}
                      conversation={conversation}
                      user={user}
                      externalVideoId="Questions__video"
                      updateParticipantStatus={(status) => syncVideoStatus(status, meFirst, nextConversationScheduled)}
                      videoEnabled={user && user.org.partnerConversationsSettings.videoEnabled}
                      notificationSent={notificationSent}
                      enableVideo={setVideoEnabled}
                    />
                  }
                  {rigModalVisible &&
                    <div className="Questions__convoVideo-blank" />
                  }
                </div>
                <div className="Questions__convoSheet">
                  <div className="Questions__convoSheetOptions">
                    <Tippy
                      content={<Toasts.YouAreAsking close={() => hideToast('YouAreAsking')} />}
                      className="Questions__tip"
                      arrow
                      visible={
                        question && question._i == 0 &&
                        toasts.YouAreAsking != null && toasts.YouAreAsking != 'shown' &&
                        !allQuestionsAnswered && imAsking
                      }
                      placement="top"
                    >
                      <div className={cx({"Questions__convoSheetActive": imAsking, "green": question && question.notInQuestionCount})}>
                        Ask + Type Response
                      </div>
                    </Tippy>
                    <span className="Questions__convoSheetOptionsDivider" />
                    <Tippy
                      content={<Toasts.YouAreAnswering close={() => hideToast('YouAreAnswering')} />}
                      className="Questions__tip"
                      arrow
                      visible={
                        question && question._i == 0 &&
                        toasts.YouAreAnswering != null && toasts.YouAreAnswering != 'shown' &&
                        !allQuestionsAnswered && !imAsking
                      }
                      placement="top"
                    >
                      <div className={cx({"Questions__convoSheetActive": imAsking == false, "green": question && question.notInQuestionCount})}>
                        Answer Question
                      </div>
                    </Tippy>
                  </div>
                  {question &&
                    <Question
                      questionId={question && `${question.label}/${question.imAsking}`}
                      question={question}
                      conversation={conversation}
                      rigScore={rigScore}
                      user={user}
                      saveOneWord={saveOneWord}
                      saveActionConfirmation={saveActionConfirmation}
                      saveActionCommitment={saveActionCommitment}
                      saveAnswer={saveAnswer}
                      conversationIndexInSet={conversationIndexInSet}
                      convsWithScores={convsWithScores}
                    />
                  }
                  {allQuestionsAnswered && conversation.state != states.Finished &&
                    <>
                      <div className="Questions__convoSheetOption">
                        <div className="Questions__row">
                          <img src={IMG_AARON} alt="" className="Questions__rowIcon" />
                          <div className="Questions__column max">
                            <h3 className="Questions__onboardingNames">
                              Please wait until your partner saves their answers.
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="Questions__convoSheetBtns">
                        <SaveAndExitBtn />
                      </div>
                    </>
                  }
                  {allQuestionsAnswered && conversation.state == states.Finished &&
                    <>
                      {nextConversation && !nextConversationScheduled &&
                        <>
                          <div className="Questions__convoSheetOption">
                            <div className="Questions__row">
                              <img src={IMG_AARON} alt="" className="Questions__rowIcon" />
                              <div className="Questions__column max">
                                <h3 className="Questions__onboardingNames">
                                  Your Conversation Scheduler
                                </h3>
                                <p className="Questions__onboardingDescription">
                                  Set yourselves up for success by scheduling your remaining conversations together. We recommend bi-weekly conversations. Please review our suggested dates and times below with {conversation && conversation.partner && conversation.partner.firstName}.
                                </p>
                              </div>
                            </div>
                            {meFirst && nextConversationsToSchedule.length > 0 &&
                              <ScheduleNextConversations
                                user={user}
                                partner={conversation && conversation.partner}
                                conversations={nextConversationsToSchedule}
                                nextConversationsDates={nextConversationsDates}
                                onChange={changeNextConvDate}
                                initDates={setNextConversationsDates}
                              />
                            }
                            {!meFirst &&
                              <div className="Questions__convoSheetAnswer">
                                <div className="Questions__convoSheetOptionDivider" />
                                <div className="Questions__convoSheetAnswerTip">(Partner will advance the conversation once done scheduling your next conversation.)</div>
                              </div>
                            }
                          </div>
                          <div className="Questions__convoSheetBtns">
                            <SaveAndExitBtn />
                            {meFirst &&
                              <button
                                className="btn btn_secondary btn_solid_bluePurple"
                                disabled={answering || !convsScheduledCount}
                                onClick={scheduleNextConversation}
                              >
                                {(answering
                                  ? <i className="fas fa-circle-notch fa-spin" />
                                  :
                                  <>Schedule {convsScheduledCount > 1 ? `(${convsScheduledCount}) conversations` : ''}</>
                                )}
                              </button>
                            }
                          </div>
                        </>
                      }
                      {(!nextConversation || (nextConversation.scheduleDate && nextConversationScheduled)) &&
                        <div className="Questions__convoSheetOption">
                          <div className="Questions__row">
                            <img src={IMG_AARON} alt="" className="Questions__rowIcon" />
                            <div className="Questions__column max">
                              <h3 className="Questions__onboardingNames">
                                Your guided conversation is complete and your notes are ready for review.
                              </h3>
                              {nextConversation && nextConversation.scheduleDate &&
                                <p className="Questions__onboardingDescription">
                                  Together you scheduled your next conversation, {t.find(`peerconversation.conversation.${nextConversation.label}.title`)} for <span className="Questions__onboardingDescriptionDate">{moment(nextConversation.scheduleDate).format('MMM DD, YYYY hh:mm a')}</span>.
                                  A calendar invite has been sent.
                                </p>
                              }
                              <button
                                className="btn btn_secondary btn_solid_bluePurple"
                                onClick={() => showFinishModal(true)}
                              >Exit conversation & Review notes</button>
                            </div>
                          </div>
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            }
          </>
        }
      </main>

      <ModalRIG
        opened={rigModalVisible || false}
        save={saveRIGPart}
      />

      <RelationshipModal
        opened={rigModalVisible === false && conversationQuestionsModalVisible}
        question={relationshipQuestion}
        save={saveConversationQuestionsAnswer}
        conversation={conversation}
        loading={loading}
        firstAnswer={firstRelationshipAnswer}
      />

      <Toast
        visible={status}
        close={() => setStatus(null)}
        type={status && status.type}
        message={status && MESSAGES[status.type] && MESSAGES[status.type][status.reason]}
      >
        {status && status.retry &&
          <button
            className="Questions__retry btn btn_secondary btn_white_black"
            onClick={() => status.retry()}
          >
            Retry
          </button>
        }
      </Toast>

      <Modal
        isOpen={finishModal}
        close={() => showFinishModal(false)}
        className="Questions__modal"
      >
        <div>
          <div className="Questions__cardHeader">
            Exit Conversation
          </div>
          <div className="Questions__modalContent">
            <p>Are you sure you want to exit the conversation?</p>
            <div className="Questions__btns margin-top-2">
              <button onClick={() => showFinishModal(false)} className="btn btn_secondary btn_outlined_bluePurple">
                Cancel
              </button>
              <Link to={`/peer-coaching/${conversationId}`} className="btn btn_secondary btn_solid_bluePurple">
                Exit
              </Link>
            </div>
          </div>
        </div>
      </Modal>

      {user &&
        <Onboarding
          opened={onboardingVisible}
          user={user}
        />
      }

      <Footer compressed />
    </div>
  )
}

export default Questions;
