import api from 'api';
import {merge, mergeDeepWith, concat} from 'ramda';

export const getJobHacks = (params) => {
  const p = mergeDeepWith(concat, {page: 0, size: 3}, params)
  return api.get("/jobhack", {params: p});
}

export const getCompetencies = () => {
  return api.get("/competency");
}

export const getRIGScores = () => {
  return api.get("/rig/peerconversations/scores");
}

export const getActionCommitments = () => {
  return api.get("/peerconversations/actions/commitments");
}

export const getActionConfirmations = () => {
  return api.get("/peerconversations/actions/confirmations");
}

export const getRigBaseline = (slug) => {
  return api.get(`/members/${slug}/rig/scores/baseline`);
}

export const getActionCommitmentStats = () => {
  return api.get("/peerconversations/actions/commitment/areas/statistics");
}

export const getRoleQuestion = () => {
  return api.get(`${api.surveys}/purpose-assessment`)
  .then((response) => {
    return response.questions && response.questions.find(q => q.questionType == 'role');
  })
}

export const getActionAreas = () => {
  return api.get("/peerconversations/actions/commitment/areas")
  .then((areas) => areas.sort((a, b) => a.id - b.id));
}

export const getSubInvestmentAreas = () => {
  return api.get("/peerconversations/actions/commitment/sub-investment-areas")
}

export const getColleague = (slug) => {
  return api.get(`/colleagues/${slug}`);
}
