import React from 'react'
import {composeComponent, withModal} from 'utils/react-tools'
import {withRouter, Link} from 'react-router-dom'
import {fetchUser} from 'auth/requests'
import qs from 'qs'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, mapProps, withProps, withState} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import {getUser} from 'api'
import * as ME from 'api/metrics'
import Avatar from 'theme/Avatar'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Subnav from 'theme/Subnav'
import Modal, * as M from 'theme/Modal'
import RSelect from 'react-select'
import {getConversations, getWorkshops, getMatchingGroups, getAdminConversationsDefinitions, deleteMatchingGroup, addConversation, getOrganizations } from 'matchingGroups/requests'
import {createWorkshop} from 'workshops/requests'
import NewGroup from 'matchingGroups/NewGroup'

import './MatchingGroups.sass'

STATE_MAP = [
  'Select partner'
  'Schedule conversation'
  'Conduct conversation'
  'Capture your insights'
  'Rate and mark as completed'
  'Finished'
]


export default composeComponent 'MatchingGroups',
  withRouter
  withModal

  withStateHandlers
    user: null
    loading: false
    matchingGroups: []
    activeConversation: null
    organization: null
    organizationSelectorVisible: true
    workshops: []
    conversationsDefinitions: []
    activeWorkshop: null
    pickedMembers: []
    conversations: []
    organizations: []
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setMatchingGroups: -> (matchingGroups) -> {matchingGroups}
    setWorkshops: -> (workshops) -> {workshops}
    setDefinitions: -> (conversationsDefinitions) -> {conversationsDefinitions}
    setConversations: -> (conversations) ->  {conversations}
    setWorkshop: -> (activeWorkshop) -> {activeWorkshop}
    setOrganization: -> (organization) -> {organization}
    setOrganizations: -> (organizations) -> {organizations}

  mapProps _.evolve
    workshops: _.map (workshop) ->
      count = workshop.members.length
      _.merge workshop,
        label: "#{workshop.name} (#{count} member#{if count is 1 then '' else 's'})"
        value: workshop.id

    matchingGroups: _.map (group) ->
      [program, set] = _.split '/', group.peerConversationSetLabel
      _.merge group, {program, set}

  withHandlers
    selectOrganization: ({setDefinitions, setOrganization, setMatchingGroups, setWorkshops, setWorkshop, setConversations, history, activeWorkshop }) -> (org) ->
      ME.reportStateChange "CPL PeerCoaching/Conversations: organization set", {orgId: org?.id, orgCode: org?.code}
      ME.reportStateChange "CPL PeerCoaching/Conversations: workshops loading"

      setOrganization org

      getWorkshops org.code
      .then (workshops) ->
        ME.reportStateChange "CPL PeerCoaching/Conversations: workshops loaded"

        search = qs.parse history.location.search, ignoreQueryPrefix: true
        foundWorkshop = if search.workshop then workshops.find (w) -> w.id is JSON.parse search.workshop

        workshop = foundWorkshop ? workshops?[0]
        ME.reportStateChange "CPL PeerCoaching/Conversations: workshop set", {workshopSlug: workshop?.slug || null}
        if workshop
          count = workshop.members.length
          setWorkshop  _.merge workshop,
            label: "#{workshop.name} (#{count} member#{if count > 1 then 's' else ''})"
            value: workshop.id

          ME.reportStateChange "CPL PeerCoaching/Conversations: matching groups loading", {workshopSlug: workshop?.slug}
          getMatchingGroups workshop
          .then setMatchingGroups
          .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: matching groups loading error", err)
        else
          setWorkshop(null)
          setMatchingGroups([])

        getAdminConversationsDefinitions(org.code)
        .then(setDefinitions)
        .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: admin conversations defs loading error", err)

        workshops: workshops
        workshop: workshop
      .then ({workshops, workshop}) ->
        setWorkshops workshops
        workshop
      .then (workshop) ->
        getConversations(org.code, workshopId: workshop?.id)
        .then(setConversations)
        .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: conversations loading error", err)
      .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: workshops loading error", err)


  withHandlers
    loadData: ({user, setUser, startLoading, endLoading, selectOrganization, setDefinitions, setOrganizations }) -> ->
      startLoading()
      ME.reportStateChange "CPL PeerCoaching/Conversations: data loading"

      a = fetchUser()
        .then (user) ->
          setUser user
          mPromise = selectOrganization(user.organization)
          orgPromise =
            if user.isSuperAdmin
              ME.reportStateChange "CPL PeerCoaching/Conversations: organizations loading", {isSuperAdmin: true}
              getOrganizations()
              .then(setOrganizations)
              .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: organizations loading error", err)
            else
              Promise.resolve()

          defPromise = getAdminConversationsDefinitions(user.organization.code)
          .then(setDefinitions)
          .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: admin conversations defs loading error", err)

          Promise.all([mPromise, orgPromise, defPromise])

      a
      .finally(endLoading)

    changeWorkshop: ({organization, setDefinitions, startLoading, endLoading, setMatchingGroups, setWorkshop, activeWorkshop, history, setConversations}) -> (workshop) ->
      unless workshop.id
        history.push "/peer-coaching/user-groups?new=true&conversation"
        return

      if activeWorkshop.id is workshop.id
        return

      ME.reportStateChange "CPL PeerCoaching/Conversations: new workshop data loading"

      startLoading()
      setWorkshop workshop

      getAdminConversationsDefinitions(organization.code)
      .then(setDefinitions)
      .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: admin conversations defs loading error", err)

      getMatchingGroups workshop
      .then setMatchingGroups
      .then ->
        getConversations(organization.code, workshopId: workshop.id)
        .then setConversations
        .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: conversations loading error", err)
      .then _, -> setWorkshop activeWorkshop
      .catch((err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: matching groups loading error", err)
      .finally endLoading

    isMemberPicked: ({pickedMembers}) -> (member) ->
      member.id in _.pluck 'id', pickedMembers

  withHandlers
    deleteGroup: ({showModal, activeWorkshop, loadData, closeModal}) -> (group, deleteNonEmpty) ->
      ME.reportStateChange "CPL PeerCoaching/Conversations: group deleting", {group: group?.id}
      deleteMatchingGroup group.workshopId, group.id, deleteNonEmpty
      .then loadData
      .then closeModal
      .catch (err) ->
        ME.reportIntermediateError "CPL PeerCoaching/Conversations: group deleting error", err
        showModal name: 'SecondAsk'

    conversationsInSet: ({conversations}) -> (set) ->
      conversations.filter (c) -> c.label.indexOf(set) isnt -1

    assignConversation: ({activeWorkshop, history}) -> (dto) ->
      ME.reportStateChange "CPL PeerCoaching/Conversations: match group adding", {peerConversationSetLabel: dto?.peerConversationSetLabel}
      addConversation activeWorkshop, dto
      .then (group) ->
        ME.reportStateChange "CPL PeerCoaching/Conversations: match group added", {group: group?.id, workshopId: group?.workshopId}
        history.push "/peer-coaching/manage/#{activeWorkshop.id}/group/#{group.id}/match"
        group
      .catch (err) -> ME.reportIntermediateError "CPL PeerCoaching/Conversations: match group adding error", err

  lifecycle
    componentDidMount: ->
      @props.loadData()
  ({
    loading
    current
    finished
    showConversationDetails
    activeConversation
    workshops
    activeWorkshop
    changeWorkshop
    isMemberPicked
    matchingGroups
    history
    user
    t
    addWorkshop
    showModal
    closeModal
    modal
    deleteGroup
    conversationsInSet
    assignConversation
    conversationsDefinitions
    organization
    organizations
    toggleOrganizationSelectorVisible
    organizationSelectorVisible
    selectOrganization
  }) ->
    dropdownOptions = _.append
      label: '+ Add New'
      value: null
    , workshops

    React.createElement("div", {"className": "MatchingGroups page"},
      React.createElement(Header, {"user": (user), "cpl": (true)}),

      React.createElement("div", {"className": "container", "id": "main"},
        React.createElement("div", {"className": "MatchingGroups__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "MatchingGroups__title"}, """
              Manage Conversations
"""),
            React.createElement("p", {"className": "title-description"}, "Assign conversations to cohort.")
          ),

          React.createElement("div", {"className": "MatchingGroups__headingFilter"},
            React.createElement("div", null,
              ( if user?.isSuperAdmin
                React.createElement("div", {"className": "MatchingGroups__label"}, "Organization")
              ),
              ( if user?.isSuperAdmin
                React.createElement(RSelect, { \
                  "className": "MatchingGroups__select",  \
                  "classNamePrefix": "MatchingGroups__select",  \
                  "placeholder": "Select an organization",  \
                  "options": (organizations),  \
                  "getOptionValue": ((o) -> o),  \
                  "getOptionLabel": ((o) -> o.name),  \
                  "value": (organization),  \
                  "onChange": (selectOrganization),  \
                  "disabled": (loading)
                })
              ),
              React.createElement("div", {"className": "MatchingGroups__label"}, "Cohort"),
              React.createElement(RSelect, { \
                "className": "MatchingGroups__select cohort",  \
                "classNamePrefix": "MatchingGroups__select",  \
                "placeholder": "Select a Cohort",  \
                "options": (dropdownOptions),  \
                "value": (activeWorkshop),  \
                "onChange": (changeWorkshop),  \
                "disabled": (loading)
              }),
              (if activeWorkshop?.id
                React.createElement(Link, { \
                  "className": "btn btn_secondary btn_outlined_bluePurple MatchingGroups__headingBtn",  \
                  "to": "/peer-coaching/user-groups/#{activeWorkshop.id}?conversation"
                }, """
                  Edit
""")
              )
            ),
            React.createElement("button", { \
              "onClick": (-> showModal name: 'Assign'),  \
              "className": (cx "btn btn_secondary btn_solid_bluePurple", disabled: not activeWorkshop?.id or loading)
            }, """
              Assign
""")
          ),

          React.createElement("div", {"className": "relative"},
            React.createElement(Loader, {"loading": (loading)}),

            React.createElement("h2", {"className": "MatchingGroups__subtitle"}, "Assigned Conversations"),

            React.createElement("div", {"className": "MatchingGroups__table"},
              React.createElement("table", null,
                React.createElement("thead", null,
                  React.createElement("tr", null,
                    React.createElement("th", null, "Program"),
                    React.createElement("th", null, "Set"),
                    React.createElement("th", null, "Description"),
                    React.createElement("th", null, "Completed"),
                    React.createElement("th", {"className": "invisible"}, "Actions")
                  )
                ),

                React.createElement("tbody", null,
                  (matchingGroups.map (group) ->
                    React.createElement("tr", { \
                      "key": (group.id),  \
                      "onClick": (-> history.push "/peer-coaching/manage/#{activeWorkshop.id}/group/#{group.id}/match")
                    },
                      React.createElement("td", null,
                        (t.find "peerconversation.program.#{group.program}.title")
                      ),
                      React.createElement("td", null,
                        (t.find "peerconversation.set.#{group.set}.title")
                      ),
                      React.createElement("td", null,
                        (group.description)
                      ),
                      React.createElement("td", null,
                        (
                          convs = conversationsInSet group.peerConversationSetLabel
                          unless convs.length
                            null
                          else
                            finishedConvs = convs.filter (c) ->
                              c.state is "Finished"
                            finishedPercentage = Math.round(finishedConvs.length / convs.length * 100)
                            "#{finishedPercentage}%"
                        )
                      ),
                      React.createElement("td", {"className": "MatchingGroups__actions"},
                        React.createElement("button", { \
                          "className": "btn btn_secondary btn_solid_red",  \
                          "onClick": ((e) ->
                            e.stopPropagation()
                            showModal {group, name: 'FirstAsk'}
                          )
                        }, "Delete")
                      )
                    )
                  )
                )
              )
            )
          )
        )

      ),

      React.createElement(Footer, null),

      React.createElement(Modal, { \
        "isOpen": (modal?.visible and modal?.name is 'Assign'),  \
        "className": "MatchingGroups__modal no-padding"
      },
        React.createElement(M.Header, {"close": (closeModal)}, "Assign"),
        React.createElement(M.Content, null,
          React.createElement(NewGroup, { \
            "submit": (assignConversation),  \
            "workshop": (activeWorkshop),  \
            "conversationsDefinitions": (conversationsDefinitions),  \
            "matchingGroups": (matchingGroups),  \
            "t": (t)
          })
        )
      ),

      React.createElement(Modal, {"isOpen": (modal?.visible and modal?.name isnt 'Assign'), "className": "confirm-modal", "close": (closeModal)},
        React.createElement("h2", {"className": "title"}, "Warning!"),
        React.createElement("div", null,
          React.createElement("p", null, """
            Are you sure you want to delete
""", React.createElement("strong", null, (t.find "peerconversation.program.#{modal?.group?.program}.title"), " \x2F ", (t.find "peerconversation.set.#{modal?.group?.set}.title")), """
            matching group?
"""),
          (if modal?.name is 'SecondAsk'
            React.createElement("p", {"className": "MatchingGroups__modalInfo"}, """
              This group is not empty, do you want to continue?
""")
          ),
          React.createElement("div", {"className": "btns"},
            React.createElement("button", {"onClick": (closeModal), "className": "btn btn_secondary btn_outlined_bluePurple"}, """
              Cancel
"""),
            (switch modal?.name
              when 'FirstAsk'
                React.createElement("button", {"onClick": (-> deleteGroup modal?.group), "className": "btn btn_secondary btn_solid_bluePurple"}, """
                  Delete
""")
              when 'SecondAsk'
                React.createElement("button", {"onClick": (-> deleteGroup modal?.group, true), "className": "btn btn_secondary btn_solid_red"}, """
                  Delete
""")
            )
          )
        )
      )

    )
