import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from 'stores/stores';
import Modal from 'theme/Modal';
import Loader from 'theme/Loader';
import {getConsentsFromStorage} from 'api';
import {updateConsents} from 'auth/requests';

import './ConsentsCheck.sass';

const ConsentsCheck = ({children}) => {
  const {userStore} = useStore();
  const {user} = userStore;
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [consents, setConsents] = useState(getConsentsFromStorage);
  const modalVisible = user && consents && !consents.consentTermsOfServiceAndPrivacyPolicyAccepted;

  useEffect(() => {
    setConsents(getConsentsFromStorage());
  }, [location]);

  const saveConsents = () => {
    if(!user) return;
    setLoading(true);
    updateConsents(user.slug, {consentTermsOfServiceAndPrivacyPolicyAccepted: true})
    .then(() => {
      setConsents(getConsentsFromStorage());
      setLoading(false);
    }, () => {
      setLoading(false);
    })
  }

  return (
    <>
      {children}
      <Modal
        isOpen={Boolean(modalVisible)}
        className="ConsentsCheck"
        overlayClassName="ConsentsCheck__overlay"
      >
        <h1 className="ConsentsCheck__title">Updates to our Terms and Privacy Policy</h1>
        <div className="relative">
          <Loader color="white" size="2" loading={loading} />
          <p>
            We’ve updated our <a href="https://www.imperative.com/terms-of-service" target="_blank">Terms</a> and <a href="https://www.imperative.com/privacy-policy" target="_blank">Privacy Policy</a>.
            Please take a moment to review these changes. By continuing, you agree to our updated <a href="https://www.imperative.com/terms-of-service" target="_blank">Terms</a>.
          </p>
        </div>
        <div className="ConsentsCheck__btns">
          <Link
            to="/logout"
            className="btn btn_secondary btn_outlined_bluePurple"
          >Exit</Link>
          <button
            className="btn btn_secondary btn_solid_bluePurple"
            onClick={saveConsents}
            disabled={loading}
          >Continue</button>
        </div>
      </Modal>
    </>
  )
}

export default observer(ConsentsCheck);
