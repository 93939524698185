import React from 'react'
import T from 'tcomb'
import cx from 'classnames'
import {composeComponent, setPropTypes} from 'utils/react-tools'


export default composeComponent 'Assessment.Radio',
  setPropTypes
    options: T.list T.interface
      value: T.Any
    onChange: T.Function
    value: T.Any
    question: T.Any

  ({options, value, onChange, question, t}) ->
    React.createElement("div", null,
      (options.map (o, i) ->
        selected = o.value is value
        React.createElement("label", { \
          "key": (i),  \
          "className": (cx "Assessment__option", selected and "selected"),  \
          "onClick": (-> onChange o.value)
        },
          React.createElement("input", { \
            "type": "checkbox",  \
            "checked": (selected),  \
            "onChange": (-> onChange o.value),  \
            "id": "Assessment__option#{o.value}"
          }),
          React.createElement("div", null,
            React.createElement("p", {"className": "Assessment__radioLabel"},
              (t.find "surveys.purpose-assessment.questions.#{question.id}.options.#{o.value}")
            ),
            React.createElement("i", {"className": (cx("Assessment__radioMark", "#{if selected then 'fas fa-check-circle' else 'fal fa-circle'}"))})
          )
        )
      )
    )
