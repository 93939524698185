DESCRIPTIONS =
  Society:
    intro: 'changes the world'
    Harmony:
      Knowledge:
        """
        through the insights and knowledge we uncover. Driven to promote fairness and
        equality, we work to build a greater understanding of complex issues, resulting
        in solutions that have significant, long-lasting impact. Our vast base of knowledge,
        inquisitive approach, and big-picture focus allow us to develop invaluable insights,
        bringing about changes that affect society as a whole. Our work feels especially
        worthwhile when our contributions succeed in leveling the playing field
        and expanding opportunities for all.
        """
      Community:
        """
        by connecting people and bringing them together. Driven to promote fairness
        and equality to ensure equal access to opportunities, we work to empower
        communities and to enable them to tackle significant challenges that bring
        about large-scale change. By connecting people through a shared vision and
        by fostering commitment and ownership, we ignite the collective energy and
        drive needed to move toward concrete societal progress. Our work feels
        especially worthwhile when we see our contributions leading to widespread
        changes that expand opportunities for all.
        """
      Structure:
        """
        through the tools and solutions we design and develop. Driven to promote equality,
        we work to create a foundation on which significant, long-lasting changes can
        take place. We are pragmatic, result-driven, big-picture thinkers who endeavor
        to create structural support systems that drive continual improvements with a
        wide-ranging impact. We feel our contributions are worthwhile when they expand
        opportunities for those who most need them.
        """
      Human:
        """
        by creating experiences that enable meaningful human connection and collaboration.
        Driven to promote fairness and equality, we work to create a humanistic environment
        that will lead to big-picture changes in society. Compassionate, visionary,
        and nurturing, we are able to bring out the best in others through the meaningful
        experiences we create. Our ability to integrate the needs and desires of others
        allows us to design solutions that have a significant, wide-ranging impact on society.
        We feel most invigorated by our work when we see that our contributions bring
        inspiration and meaning to those who most need them.
        """

    Karma:
      Knowledge:
        """
        through the insights and knowledge we uncover. Driven to empower people
        to realize their full potential and to succeed, we work to uncover valuable
        insights that expand our understanding of the complex big-picture issues that
        affect society. Our vast base of knowledge and investigative approach allow us
        to dig deep to create concrete solutions that have a wide-ranging impact.
        We shine when we are able to yield information that results in tangible
        advancements in society. Our work feels especially worthwhile when we see our
        contributions lead to solutions that maximize opportunity and impact the greater good.
        """
      Community:
        """
        by bringing people together and leveraging the strength of communities.
        Driven by a desire to empower people to realize their greatest potential,
        we work to build connection, collaboration, and ownership within communities,
        ultimately leading to large-scale societal progress. We shine when we are able
        to maximize community efforts that result in solutions with a wide-ranging,
        positive impact on society. Our work feels especially meaningful when we see
        that our community involvement brings about tangible, long-lasting impact
        that helps others to capitalize on their opportunities and to succeed.
        """
      Structure:
        """
        through the tools and solutions we design and develop. Driven by a desire
        to maximize opportunities that ultimately lead to success, we work behind
        the scenes architecting concrete, systematic solutions that drive society forward.
        Results-driven and pragmatic, we endeavor to build the conditions in which bigger
        picture changes can take place. The structural support we create drives efficiencies
        and continual improvements that have a wide-ranging impact. We feel especially
        invigorated when we see our contributions bring success to others and lead to
        tangible advancements in society.
        """
      Human:
        """
        by creating experiences that enable meaningful human connection and collaboration.
        Driven to empower people to make the most of their opportunities, we work to
        understand the human and environmental context of those we serve, uncovering
        new insights that ignite widespread change. Compassionate, visionary, and
        big-picture focused, we shine when we can deliver significant societal impact
        by tailoring environments for those involved. We feel most invigorated by our
        work when we see our contributions pave the way for large-scale advances that
        help people realize their greatest potential.
        """

  Individual:
    intro: 'changes lives'
    Harmony:
      Knowledge:
        """
        through the insights and information that we uncover. Our underlying motivation is
        to provide knowledge, insight, and understanding aimed at creating a more humane, just,
        and inclusive society. Through disciplined work, we make sense of the complex
        issues that touch individual lives and we find viable solutions to enrich them.
        Our most meaningful moments take place when our in-depth expertise or latest
        discovery has a direct impact on an individual's life.
        """
      Community:
        """
        by bringing people together to work in concert with one another. We leverage our
        thoughtful attention to people's needs and strong interpersonal relationships to
        empower individual contributors to unleash their full potential. We build ownership
        of issues that individuals face, and we facilitate meaningful connections within
        communities to identify and resolve issues that ultimately benefit each individual.
        Those we work with appreciate our personal manner and our ability to drive
        collaborative change that results in shared benefit. Our efforts feel immensely
        rewarding when we observe strengthened bonds across communities through a shared
        sense of belonging, connection, and commitment.
        """
      Structure:
        """
        by creating systems, structures, and processes that have a lasting impact.
        Driven by the desire to promote fairness and equality, we work to design
        support mechanisms that create efficiencies and drive continuous improvements
        for those we serve. Our practical, hands-on approach allows us to create
        the infrastructure and tools that break down barriers to opportunity and that
        create a more level playing field for all. We feel most rewarded by our work when
        we see the tools and systems we built directly improving someone’s life and well-being.
        """
      Human:
        """
        by addressing human needs, desires, and capabilities. We work to design the optimal
        conditions needed to improve individual lives. We enjoy uncovering the authentic
        needs and behaviors of our target audience and conceptualizing exciting new ways
        to create meaningful experiences for each individual. Driven by the desire to level
        the playing field and to create a more just society, we feel especially fulfilled
        when our contributions are open and accessible to everyone yet still serve individual needs.
        Our most meaningful moments occur when individuals share their personal
        stories about how our work contributes to their lives in a positive way.
        """

    Karma:
      Knowledge:
        """
        through the insights and information that we uncover.
        Driven by the desire to help others realize their full potential and to succeed,
        we work to advance our understanding and expertise in areas that relate to those we serve.
        Our insatiable curiosity and insight allow us to uncover pertinent information
        and to devise viable solutions that pave the way for people to prosper.
        Our work feels especially rewarding when we can directly help someone maximize
        opportunities and succeed through the clarity and direction we provide.
        """
      Community:
        """
        by connecting people and bringing them together. Driven to empower people to
        maximize their opportunities and succeed, we create meaningful connection,
        ignite communal engagement, and drive collaboration in an effort to improve
        the well-being of those we serve. Both compassionate and strategic, we work
        to create the bridges needed within teams and groups to generate opportunities
        for individuals to prosper and grow. Our work feels immensely rewarding when we
        observe the impact that a strong community has on individual lives.
        """
      Structure:
        """
        through the systems, structures, and processes we build. Driven to help people
        realize their full potential and to prosper, we work to streamline processes
        and develop efficient support mechanisms that create continuous improvements for
        those we serve. Pragmatic, observant, and methodical, we shine when we are able
        to create solid structures that deliver consistent results. We feel most rewarded
        by our work when we see how our contributions help others succeed.
        """
      Human:
        """
        the meaningful experiences we create. Driven by a desire to help people maximize
        opportunity and realize their full potential, we work to uncover human needs and
        to integrate them in ways that bring meaning and inspiration to the individuals we serve.
        Intuitive, empathetic, and nurturing, we bring out the best in others and create
        an environment that allows those we work with to prosper. We feel especially gratified
        when we can inspire each person we interact with and can visibly enhance
        their well-being and overall success.
        """

  Organization:
    Harmony:
      intro: 'helps organizations thrive'
      Knowledge:
        """
        by using our skills to research and provide valuable insights and knowledge.
        Driven to increase fairness and equality, we uncover fresh information
        to enlighten and improve team and organization effectiveness.
        Inquisitive, insightful, and conceptual, we shine when we are able
        to guide teams to solve complex problems. We feel especially invigorated
        by our work when we see the clarity and direction we bring to organizations
        and teams to level the playing field and expand equal access to opportunity.
        """
      Community:
        """
        by bringing people together and fostering connection. Driven to create more
        equality and justice, we see community as the ideal mechanism for creating impact
        on organizations and teams. When we connect and collaborate with team members,
        we feel exceptionally empowered, especially when we can leverage community-generated
        resources and support for organizations and teams. In our view, community is essential
        for creating significant, scalable impact and for building mutually supportive,
        meaningful relationships.
        """
      Structure:
        """
        by designing systems, structures, and processes that drive efficiency and cohesiveness.
        Motivated by a desire to create more just and equal organizations and teams,
        we most often work behind the scenes to create internal structures
        that keep things running smoothly. We shine when teams and organizations
        are able to maximize efficiency and quality using our systems, structures,
        and processes. Our work feels most meaningful when we level the playing
        field for everyone in teams and organizations.
        """
      Human:
        """
        by designing environments that ignite creativity and self-expression and that
        enable meaningful human connection and collaboration. Motivated by the desire
        to promote greater fairness and equality in organizations and teams, we deliver
        solutions that break down barriers to equal access and opportunities.
        We work to understand the human and environmental context of those we serve,
        uncovering new insights that effect widespread change. Our most meaningful moments
        occur when we see our work contribute to change that benefits organizations and teams.
        """

    Karma:
      intro: 'takes organizations to the next level'
      Knowledge:
        """
        by uncovering new insights and knowledge. Driven to help people maximize their
        opportunities to realize their full potential, we work to uncover breakthrough
        information that enables organizations to thrive and deliver real impact.
        Our investigative approach, vast base of knowledge, and real-world insights
        contribute to extraordinary results for teams and organizations.
        We feel especially fulfilled when we see that our contributions have brought
        clarity and direction to the teams and organizations we serve.
        """
      Community:
        """
        by bringing people together. Driven to empower people to realize their
        greatest potential, we work to propel teams and organizations forward by
        fostering collaboration and empathy. By connecting people and increasing
        collective engagement and ownership, we help organizations and groups to
        prosper and deliver extraordinary results. We bring clarity and direction
        to the groups and organizations we serve. We feel most invigorated when
        our contributions have paved the way for an organization’s success,
        especially when that effort results in a hard working and engaged community.
        """
      Structure:
        """
        by creating systems, structures, and processes that have a lasting impact.
        We strive to optimize the support mechanisms of our organization.
        In doing so, we ensure that internal systems are not only running smoothly
        but also are continuously evolving with the aim of maximizing organizational
        and team capabilities. Without us, a myriad of challenges could emerge,
        stalling key organizational activities and derailing efforts and engagement.
        We feel especially fulfilled when we are recognized for our contributions to
        setting up teams and organizations for success.
        """
      Human:
        """
        by designing experiences that enable meaningful human connection and collaboration.
        Driven to empower people to realize their greatest potential and to maximize opportunities,
        we work to propel teams and organizations forward by leveraging the strengths
        of human-centered design. We aspire to create a space where anything is possible
        and where teams work cohesively together to generate valuable insights and
        viable solutions to pesky challenges. We feel most invigorated when our designs
        and solutions facilitate a change that betters the organization and teams.
        """


module.exports = ({who, why, how}) ->
  intro = DESCRIPTIONS[who.driver][why.driver].intro ? DESCRIPTIONS[who.driver].intro
  rest = DESCRIPTIONS[who.driver][why.driver][how.driver]
  whole = "#{intro} #{rest}"
  {intro, rest, whole}
