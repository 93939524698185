import moment from 'moment';
// import * as emojis from 'theme/emojis';

const RIG_OPTIONS = [
    'Frustrated',
    'Unhappy',
    'Meh',
    'Happy',
    'PumpedUp',
]

const LABELS_MAP = {
    'Frustrated': 'assessment.rig.answer1',
    'Unhappy': 'assessment.rig.answer2',
    'Meh': 'assessment.rig.answer3',
    'Happy': 'assessment.rig.answer4',
    'PumpedUp': 'assessment.rig.answer5'
}
  
const findMostRecentCompletedConversation = (convs = []) => {
    return convs.reduce((res, c) => {
        if(c.state != 'Finished') return res;
        if(!res) return c;
        if(moment(res.participant[0].modifiedAt).isBefore(moment(c.participant[0].modifiedAt))) {
            return c
        } else return res;
    }, null);
}

const getOneWordBeforeForConversation = (member, conv) => {
    if(!conv || !member) return '';
    const memberData = conv.participant.find(p => p.member.memberId == (member.id || member.memberId || (member.member && member.member.memberId)));
    if (!memberData || !memberData.oneWord || !memberData.oneWord.preConvWord) return '';
    return memberData.oneWord.preConvWord;
}

const getOneWordAfterForConversation = (member, conv) => {
    if(!conv || !member) return '';
    const memberData = conv.participant.find(p => p.member.memberId == (member.id || member.memberId || (member.member && member.member.memberId)));
    if (!memberData || !memberData.oneWord || !memberData.oneWord.postConvWord) return '';
    return memberData.oneWord.postConvWord;
}

const getOneWordAfterForRecentConversation = (member, convs = []) => {
    const recent = findMostRecentCompletedConversation(convs);
    return getOneWordAfterForConversation(member, recent);
}

const getOneWordBeforeForRecentConversation = (member, convs = []) => {
    const recent = findMostRecentCompletedConversation(convs);
    return getOneWordBeforeForConversation(member, recent);
}

const getOneWordBeforeForAllConversations = (member, convs = []) => {
    const finished = convs.filter(c => c.state == 'Finished').map(c => getOneWordBeforeForConversation(member, c));
    if(finished.length == 0)
        return '';
    if (finished.length == 1)
        return finished[0];
    return finished.slice(0, -1).join(', ').concat(` and ${finished[finished.length - 1]}`);
}

const getOneWordAfterForAllConversations = (member, convs = []) => {
    const finished = convs.filter(c => c.state == 'Finished').map(c => getOneWordAfterForConversation(member, c));
    if(finished.length == 0)
        return '';
    if (finished.length == 1)
        return finished[0];
    return finished.slice(0, -1).join(', ').concat(` and ${finished[finished.length - 1]}`);
}

const getRecentRIG = (member, conv) => {
    if(!member || !conv) return;
    const memberData = conv.participant.find(p => p.member.memberId == (member.id || member.memberId || (member.member && member.member.memberId)));
    const score = memberData && memberData.rig && memberData.rig.reduce((result, o) => {
        if(!result) return o;
        if(result.dateScored < o.dateScored) return result;
        return o;
    }, null);
    return score;
}

const getRIGRelationshipsForConversation = (member, conv, t) => {
    if(!conv) return '';
    const score = getRecentRIG(member, conv);
    const value = score && score.scores && score.scores.relationship && score.scores.relationship.value;
    if(value == null)
        return '';
    // const emoji = emojis[RIG_OPTIONS[value]];
    // const label = t.find(LABELS_MAP[RIG_OPTIONS[value]]);
    return t.find(LABELS_MAP[RIG_OPTIONS[value]]);
}

const getRIGImpactForConversation = (member, conv, t) => {
    if(!conv) return '';
    const score = getRecentRIG(member, conv);
    const value = score && score.scores && score.scores.impact && score.scores.impact.value;
    if(value == null)
        return '';
    return t.find(LABELS_MAP[RIG_OPTIONS[value]]);
}
const getRIGGrowthForConversation = (member, conv, t) => {
    if(!conv) return '';
    const score = getRecentRIG(member, conv);
    const value = score && score.scores && score.scores.growth && score.scores.growth.value;
    if(value == null)
        return '';
    return t.find(LABELS_MAP[RIG_OPTIONS[value]]);
}

const getRIGRelationshipsByDatesForConversation = (member, convs = [], t) => {
    const scores = convs.map(c => {
        const score = getRecentRIG(member, c);
        const value = score && score.scores && score.scores.relationship && score.scores.relationship.value;
        if(value == null) return '';
        const date = score.dateScored;
        return `${moment(date).format('M/D')}: ${t.find(LABELS_MAP[RIG_OPTIONS[value]])}`
    }).filter(s => s.length);
    return scores.join(', ');
}
const getRIGImpactByDatesForConversation = (member, convs = [], t) => {
    const scores = convs.map(c => {
        const score = getRecentRIG(member, c);
        const value = score && score.scores && score.scores.impact && score.scores.impact.value;
        if(value == null) return '';
        const date = score.dateScored;
        return `${moment(date).format('M/D')}: ${t.find(LABELS_MAP[RIG_OPTIONS[value]])}`
    }).filter(s => s.length);
    return scores.join(', ');
}
const getRIGGrowthByDatesForConversation = (member, convs = [], t) => {
    const scores = convs.map(c => {
        const score = getRecentRIG(member, c);
        const value = score && score.scores && score.scores.growth && score.scores.growth.value;
        if(value == null) return '';
        const date = score.dateScored;
        return `${moment(date).format('M/D')}: ${t.find(LABELS_MAP[RIG_OPTIONS[value]])}`
    }).filter(s => s.length);
    return scores.join(', ');
}

export {
    getOneWordAfterForConversation,
    getOneWordBeforeForConversation,
    getOneWordBeforeForRecentConversation,
    getOneWordAfterForRecentConversation,
    getOneWordBeforeForAllConversations,
    getOneWordAfterForAllConversations,
    getRIGRelationshipsForConversation,
    getRIGImpactForConversation,
    getRIGGrowthForConversation,
    getRIGRelationshipsByDatesForConversation,
    getRIGImpactByDatesForConversation,
    getRIGGrowthByDatesForConversation,
}
