import React from 'react'
import {composeComponent} from 'utils/react-tools'
import cx from 'classnames'

import './Loader.sass'



export default composeComponent 'Loader',
  ({className, loading, size = '3', color = 'bg', position = 'center'}) ->
    React.createElement("div", {"className": (cx "Loader", className, visible: loading, color, position)},
      React.createElement("span", {"className": "far fa-circle-notch fa-#{size}x fa-spin "})
    )
