import React, { useEffect, useMemo } from 'react';
import cx from 'classnames'
import ReactSlider from 'react-slider';

import './Slider.sass';

const Slider = (props) => {
    const {onChange, value, defaultValue, options = [], className} = props;

    useEffect(() => {
        if(value == null && options.length && defaultValue != null) {
            const midOption = options[Math.floor(options.length / 2)];
            if(options && midOption) {
                onChange(midOption.value)
            } else {
                onChange(defaultValue);
            }
        }
    }, [value, options, defaultValue]);

    const min = useMemo(() => {
        return options[0] && options[0].value;
    }, [options]);

    const max = useMemo(() => {
        return options && options[options.length - 1].value;
    }, [options]);

    const dir = document.body.dir;

    const selectedOption = useMemo(() => {
        return options.find(o => o.value == value);
    }, [value, options]);
  
    const Thumb = (props) =>
        <div {...props} className={cx("AssessmentSlider__thumb", {flip: dir == 'rtl'})} />
  
    return (
        <div className={className}>
            {options.length == 1 &&
                <p className="AssessmentSlider__message">
                    {options[0].label}
                </p>
            }
            <div className="AssessmentSlider__sliderContainer">
                <div className="AssessmentSlider__labelContainer">
                    <div
                        className="AssessmentSlider__label"
                        style={{
                            left: (!dir || dir == 'ltr') && `${(value - min) / (max - min) * 100}%`,
                            right: !(!dir || dir == 'ltr') && `${(value - min) / (max - min) * 100}%`
                        }}
                    >
                        <div className={cx("AssessmentSlider__labelText", {flip: dir == 'rtl'})}>
                            <span>
                                {selectedOption && selectedOption.label}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="AssessmentSlider__notchContainer">
                    {new Array(max - min).fill(0).map((_, i) =>
                        <div key={i} className={cx("AssessmentSlider__notch", {flip: dir == 'rtl'})}  />
                    )}
                </div>
                <ReactSlider
                    className="AssessmentSlider__slider"
                    renderThumb={Thumb}
                    value={value}
                    invert={dir == 'rtl'}
                    min={min}
                    max={max}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default Slider;