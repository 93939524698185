import React, { useEffect, useState } from 'react';
import {getAssessmentData} from 'assessment/requests';
import DemographicQuestionsMissingModal from './DemographicQuestionsMissingModal';
import DemographicQuestionsUpdateModal from './DemographicQuestionsUpdateModal';

import './DemographicQuestionsCheck.sass';

const DemographicQuestionsCheck = ({children, user}) => {
  const [assessmentData, setAssessmentData] = useState([]);

  const getQuestions = () => {
    return getAssessmentData()
    .then(({questions}) => {
      setAssessmentData(questions);
    });
  }

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <>
      {children}
      {user &&
        <>
          <DemographicQuestionsUpdateModal assessmentData={assessmentData} user={user} />
        </>
      }
    </>
  )
}

export default DemographicQuestionsCheck;
