import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {getActionAreas} from 'conversations/requests';
import Markdown from 'theme/Markdown';
import Loader from 'theme/Loader';
import * as Fields from './Fields';


const ActionConfirmation = (props) => {
  const {answer, conversation, imAsking, t, answering, setAnswer, previousCommitment, user} = props;
  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [error, setError] = useState(null);

  const partner = (conversation.partner && conversation.partner.member) || {};

  const selectedArea = areas.find(a => a.id === previousCommitment.areaId);

  const onChange = (keyValue = {}) => {
    setAnswer((a) => {
      if(keyValue.performed === false) {
        return {...a, ...keyValue, impactedAreasIds: []}
      }
      return {...a, ...keyValue}
    })
  }

  const selectImpactedArea = (area) => {
    const impactedAreasIds = answer.impactedAreasIds || [];
    const selectedImpactAreaI = impactedAreasIds.indexOf(area.id);
    if(selectedImpactAreaI == -1) {
      onChange({impactedAreasIds: impactedAreasIds.concat(area.id)});
    } else {
      onChange({impactedAreasIds: impactedAreasIds.slice(0, selectedImpactAreaI).concat(impactedAreasIds.slice(selectedImpactAreaI + 1))});
    }
  }

  useEffect(() => {
    setLoading(true);
    getActionAreas(conversation.id)
    .then(setAreas)
    .then(
      () => setLoading(false),
      (reason) => {
        setLoading(false);
        setError({type: 'loading', reason});
      }
    );
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <div className="Questions__convoSheetOptionQuestion">
        <div className="Questions__questionTitle">
          <Markdown
            className="Questions__questionTitle"
            source={t.find("peerconversation.action.question")}
          />
        </div>
        <div className="Questions__questionRow col-2 margin-top-2">
          <div className="Questions__questionDynamicImg">
            <span className="fad fa-4x fa-bullseye-arrow ActionIconColors"></span>
          </div>
          <div className="Questions__questionContent dynamic">
            <span className="Questions__questionDynamicTitle green">
              {imAsking ? (`${partner.firstName}${partner.firstName[partner.firstName.length - 1] == 's' ? "'" : "'s"}`) : 'Your'}
              {" "}
              <i>Imperative</i>
              {" "}
              from {moment(previousCommitment.modifiedAt).format('MMMM D')}
            </span>
            {previousCommitment.oldConfirmation
            ? <Markdown
                className="Questions__questionDynamic"
                source={previousCommitment.content}
              />
            : <div className="Markdown Questions__questionDynamic">
                {imAsking
                ? <p>
                    {t.find("{{member.firstName}} commited to ", conversation.partner.member)} {selectedArea && selectedArea.areaName} - {t.find(previousCommitment.subInvestmentArea, user)}.<br/>
                    {t.find("{{member.firstName}}'s Imperative was ", conversation.partner.member)} {previousCommitment.what}.<br/>
                    {t.find("This was an Imperative for {{member.firstName}} because ", conversation.partner.member)} {previousCommitment.why}.
                  </p>
                : <p>
                    {t.find("You commited to ")} {selectedArea && selectedArea.areaName} - {t.find(previousCommitment.subInvestmentArea, user)}.<br/>
                    {t.find("Your Imperative was ")} {previousCommitment.what}.<br/>
                    {t.find("This was an Imperative for you because ")} {previousCommitment.why}.
                  </p>
                }
              </div>
            }
          </div>
        </div>
        {imAsking &&
          <div className="Questions__convoSheetAsking margin-top-2">
            <Fields.Radio
              loading={answering}
              isOptionActive={(i) => answer.performed && i == 0 || answer.performed == false && i == 1}
              onChange={(i) => onChange({performed: i == 0})}
              optionsCount={2}
              labelTranslated={(i) => t.find(i == 0 ? 'Yes' : 'No')}
              className="actionConfirmation"
            />
            {answer.performed &&
              <div className="margin-top-3">
                <p className="Questions__onboardingDescription">Did the action affect your...</p>
                <Fields.Radio
                  loading={answering}
                  isOptionActive={(o) => (answer.impactedAreasIds || []).indexOf(o.id) > -1}
                  onChange={(o) => selectImpactedArea(o)}
                  options={areas}
                  labelTranslated={(o) => o.areaName}
                  type="multi"
                  className="rig"
                />
                <Fields.Text
                  loading={answering}
                  value={answer.answer}
                  onChange={(answer) => onChange({answer})}
                  placeholder={t.find("What was the impact of taking that action?")}
                />
              </div>
            }
            {answer.performed === false &&
              <div className="margin-top-3">
                <p className="Questions__onboardingDescription">Share what happened. Was there a barrier you encountered?</p>
                <Fields.Text
                  loading={answering}
                  value={answer.answer}
                  onChange={(answer) => onChange({answer})}
                  placeholder={t.find("Type here...")}
                />
              </div>
            }
          </div>
        }
      </div>
    </>
  )
}

export default ActionConfirmation;
