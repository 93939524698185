import React from 'react'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import cx from 'classnames'
import {composeComponent} from 'utils/react-tools'
import {lifecycle} from 'recompose'
import * as ME from 'api/metrics'
import DownloadButton from 'reports/DownloadReportButton'
import Page from 'reports/PrintPage'

import './LeadershipTypeReport.sass'

DRIVERS_MAP =
  who: 'Impact',
  why: 'Values'
  how: 'Craft'

export default composeComponent 'LeadershipTypeReport',
  withRouter
  lifecycle
    componentDidMount: ->
      ME.reportStateChange('Leadership Type Report: loaded');

  ({match: {params}, t}) ->
    {who, why, how} = params

    drivers =
      who: who
      why: why
      how: how

    number = 0 # page numbering start
    date = moment().format 'MMMM Do, YYYY'
    types = ['strengths', 'challenges']

    Column = ({type, dimension}) ->
      goals =
        challenges: t.getSplitted "pattern.leadershipStyle.dominantDriver.#{dimension}.challenges"
        strengths: t.getSplitted "pattern.leadershipStyle.dominantDriver.#{dimension}.strengths"

      React.createElement("div", {"className": "LeadershipTypeReport__columnsColumn"},
        React.createElement("div", {"className": "LeadershipTypeReport__columnsHeader #{dimension}"},
          React.createElement("h1", null, "Your leadership ", (type))
        ),
        (goals[type].map (g, j) ->
          React.createElement("div", {"className": (cx "LeadershipTypeReport__listItem", "LeadershipTypeReport__listItem_bullet"), "key": (j)},
            React.createElement("span", null, (g))
          )
        )
      )

    footer = (number, visible) ->
      if visible
        React.createElement("span", {"className": (cx \
        "LeadershipTypeReport__footer"
        , right: number %% 2
        )},
        (unless number %% 2
          React.createElement("span", {"className": "LeadershipTypeReport__footerNumber"}, (number))
        ),
        (if number %% 2
          React.createElement("span", {"className": "LeadershipTypeReport__footerNumber"}, (number))
        ), """
        Leadership Type Report • Ⓒ Imperative
""")

    React.createElement("section", {"className": "LeadershipTypeReport"},
      React.createElement(DownloadButton, {"name": "LeadershipTypeReport"}),

      React.createElement("div", {"id": "ReportContent"},
        (for dimension, driver of drivers
          React.createElement(Page, {"footer": (footer number+=1, true), "marginSize": "sm", "key": (dimension)},
            React.createElement("div", {"className": "LeadershipTypeReport__item"},
              React.createElement("p", {"className": "LeadershipTypeReport__heading #{dimension}"}, (DRIVERS_MAP[dimension]), " Driven Leader"),
              React.createElement("p", {"className": "LeadershipTypeReport__subHeading"}, "If your ", React.createElement("span", null, (dimension)), " driver is dominant, you are a ", (DRIVERS_MAP[dimension]), " Driven Leader"),
              React.createElement("p", {"className": "LeadershipTypeReport__description"}, (t.find "pattern.leadershipStyle.dominantDriver.#{dimension}.paragraph")),
              React.createElement("div", {"className": "LeadershipTypeReport__quote"},
                React.createElement("p", {"className": "LeadershipTypeReport__quoteQuote"},
                  ('"'), (t.find "pattern.drivers.#{dimension}.#{driver}.quote.value"), ('"')
                ),
                React.createElement("p", {"className": "LeadershipTypeReport__quoteAuthor"}, " — ", (t.find "pattern.drivers.#{dimension}.#{driver}.quote.author"))
              )
            ),
            React.createElement("div", {"className": "LeadershipTypeReport__columns"},
              (types.map (type, i) ->
                React.createElement(Column, { \
                  "dimension": (dimension),  \
                  "type": (type),  \
                  "key": (i)
                })
              )
            )
          )
        )
      )
    )
