import React from 'react';
import './Dashboard.sass';

const NetworkGrowing = ({ networkStats }) => {
  const community = networkStats.communityCount || 0;

  return (
    <div className="NetworkGrowing card">
      <img src="https://assets.imperative.com/static.imperative.com/images/network_illustration.png" alt="" />
      <p className="section-intro">Your network is growing!</p>
      <p className="p-new">
        We're looking through your community for your next connection. Based on availability and applicable matching
        criteria, you'll receive an email introduction once you are matched.
      </p>
    </div>
  );
};

export default NetworkGrowing;
