const defaultOptions = {
  revision: 0,
  autorun: true,
  cookie_domain: !window.config.local ? 'imperative.com' : null,
  current_lang: 'en',
  auto_language: null,
  autoclear_cookies: true,
  cookie_name: 'cc_cookie',
  page_scripts: true,
  gui_options: {
    consent_modal: {
      layout: 'cloud', // box/cloud/bar
      position: 'bottom center', // bottom/middle/top + left/right/center
      transition: 'slide', // zoom/slide
    },
    settings_modal: {
      layout: 'box', // box/bar
      transition: 'slide', // zoom/slide
      swap_buttons: true,
    },
  },
  languages: {
    en: {
      consent_modal: {
        title: 'Imperative uses cookies to create a better experience for you.',
        description: `Imperative uses cookies and similar technologies on our site to enhance and personalize your browsing experience, analyze your use of our site, and to create a secure and effective site.
          <br><br>
          Click "Accept All" to Accept All cookies and go directly to the site or click "Manage Cookies" to choose which types of cookies to accept while on the site.
          <br><br>
          You can withdraw your consent for cookies at any time. Learn more in our <a href="https://www.imperative.com/privacy-policy" class="cc-link">Privacy policy</a>`,
        primary_btn: {
          text: 'Accept All',
          role: 'accept_all', //'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Manage Cookies',
          role: 'settings', //'settings' or 'accept_necessary'
        },
        revision_message: '<br><br> Hello there, terms and conditions have changed since the last time you visisted!',
      },
      settings_modal: {
        title: 'Cookie settings',
        save_settings_btn: 'Save current selection',
        accept_all_btn: 'Accept All',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }],
        blocks: [
          {
            title: 'Important Information regarding Cookies',
            description: `Cookies help us understand how you use our site so we can provide an enhanced browsing experience. 
              You can Accept All or manage them individually. You can update your preferences at any time or learn more by visiting our <a href="#" class="cc-link">Privacy Policy</a>.`,
          },
          {
            title: 'Necessary cookies (non-optional)',
            description: `
              <div class="!px-4">
                <p>
                  These cookies are essential to the operation of our Services. They cannot be removed or turned off. 
                  These cookies make the site usable and secure by enabling basic functions like page navigation, access 
                  to secure areas of the site. We use those cookies in a number of different ways, including:
                </p>
                <ul class="!list-disc !ml-4 !mt-2">
                  <li>Authentication - To remember your login state so you don't have to log in as you navigate through our site.
                  <li>Fraud Prevention and Detection - Cookies and similar technologies that we deploy through our site help us learn things about 
                  computers and web browsers used to access the services. This information helps us monitor for and detect potentially harmful or illegal use of our services.
                  <li>Security - To protect your data from unauthorized access.
                </ul>
              </div>
            `,
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, //cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Analytics & Performance cookies',
            description: `These cookies are used to track how you use our site and to help us improve the way it works. For example, we use these cookies to count the number of visitors to our site and to see how visitors move around the site. We also use these cookies to help us understand which pages are the most and least popular and to see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site and will not be able to monitor its performance.`,
            toggle: {
              value: 'analytics',
              enabled: true,
              readonly: false,
            },
          },
        ],
      },
    },
  },
};

export default defaultOptions;
