import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as _ from 'ramda'
import TranslationsContext from 'translations'
import {withHandlers, withProps, lifecycle, withState} from 'recompose'
import cx from 'classnames'
import RSlider from 'rc-slider';
import Tooltip from 'rc-tooltip'
import RichEditor from 'theme/RichTextEditor'

import './Questions.sass'

RHandle = RSlider.Handle;

export {default as AutosizeInput} from './AutosizeInput';

export Text = composeComponent 'Questions.Field.Text',
  withProps ({required}) ->
    required: required ? true
    defaultValue: ''

  withHandlers
    changeWithAutosize: ({value, onChange}) -> (value) ->
      onChange? value

  lifecycle
    componentDidMount: ->
      if @props.required and not @props.value
        @props.onChange? @props.defaultValue

  ({
    value
    onChange
    placeholder
    className
    required
    defaultValue
    loading
    changeWithAutosize
    maxLength
    rich = true
  }) ->
    React.createElement("div", {"className": (cx("Questions__field embed-submit-field", className))},
      (if rich
        React.createElement(RichEditor, { \
          "onChange": (changeWithAutosize),  \
          "initialValue": (value),  \
          "placeholder": (placeholder ? "Type your partner's answer...")
        })
      else
        React.createElement("input", { \
          "className": "Questions__fieldText small",  \
          "onChange": ((e) => changeWithAutosize(e.target.value)),  \
          "value": (value),  \
          "maxLength": (maxLength),  \
          "placeholder": (placeholder ? "Type your partner's answer...")
        })
      )
    )

export Slider = composeComponent 'Questions.Field.Slider',
  withProps ({min = 0, max = 6}) ->
    min: min
    max: max
    defaultValue: Math.floor((min + max) / 2)

  lifecycle
    componentDidMount: ->
      unless @props.value
        @props.onChange? @props.defaultValue

  ({
    value
    onChange
    min
    max
    label
    loading
    sliderLabel = true
    defaultValue
  }) ->
    val = parseInt(value ? defaultValue)

    React.createElement(TranslationsContext.Consumer, null,
      ((t) ->
        Handle = ({value, index, dragging, ...props}) ->
          React.createElement(Tooltip, { \
            "prefixCls": "rc-slider-tooltip",  \
            "overlay": (t.find "peerconversation.question.#{label}.option.#{value}"),  \
            "visible": (if loading? then not loading else true),  \
            "placement": "top",  \
            "key": (index)
          },
            React.createElement(RHandle, Object.assign({"value": (value)}, props ))
          )

        React.createElement("div", {"className": (cx "Questions__field Questions__fieldSlider", readonly: not onChange?)},
          React.createElement(RSlider, { \
            "step": (1),  \
            "min": (min),  \
            "max": (max - 1),  \
            "onChange": (onChange),  \
            "value": (val),  \
            "handle": (if sliderLabel then Handle)
          }),
          React.createElement("div", {"className": "Questions__fieldThumbs"},
            React.createElement("span", {"className": "fas fa-thumbs-down"}),
            React.createElement("span", {"className": "fas fa-thumbs-up"})
          )
        )
      )
    )

RadioBase = ({
    value
    onChange
    optionsCount
    options
    label
    isOptionActive
    loading
    type = 'radio' #'radio', 'multi'
    labelTranslated
    className
  }) ->
    multi = type is 'multi'

    React.createElement(TranslationsContext.Consumer, null,
      ((t) ->
        React.createElement("div", {"className": (cx "Questions__field Questions__fieldRadio", className)},
          ((options ? [0...optionsCount]).map (option, i) ->
            active = isOptionActive?(option) ? value is option
            React.createElement("div", { \
              "key": (i),  \
              "className": "Questions__fieldRadioContainer"
            },
              React.createElement("input", { \
                "type": "checkbox",  \
                "checked": (active),  \
                "onChange": (-> onChange option),  \
                "aria-label": "checkbox"
              }),
              React.createElement("div", { \
                "className": (cx "Questions__fieldRadioItem",
                  active: active
                  readonly: not onChange?
                ),  \
                "onClick": (-> onChange? option)
              },
                (labelTranslated?(option) ? t.find "peerconversation.question.#{label}.option.#{option}"),
                (if active
                  React.createElement("span", {"className": "fas fa-#{if multi then 'check' else 'dot'}-#{if multi then 'square' else 'circle'}"})
                else
                  React.createElement("span", {"className": "fal fa-#{if multi then 'square' else 'circle'}"})
                )
              )
            )
          )
        )
      )
    )


export Radio = composeComponent 'Questions.Field.Radio',
  withProps ({value}) ->
    value: if value then parseInt(value)

  (props) ->
    React.createElement(RadioBase, Object.assign({},  props ))


export Multi = composeComponent 'Questions.Field.Multi',
  withProps ({value}) ->
    value: if value
        value.split(',').map (v) -> parseInt(v)
      else []

  withHandlers
    onChange: ({onChange, value}) ->
      if onChange?
        (clickedValue) ->
          if clickedValue in value
            onChange _.without [clickedValue], value
          else
            onChange _.append clickedValue, value

    isOptionActive: ({value}) -> (i) ->
      i in value

  (props) ->
    React.createElement(RadioBase, Object.assign({},  props, {"type": "multi"}))

export Rating = composeComponent 'Questions.Field.Rating',
  withState 'focused', 'focus', false
  withHandlers
    handleKeyDown: ({focused, onChange, value, max = 5}) -> (e) ->
      if !focused
        return
      v = value ? 0
      switch e.key
        when 'ArrowRight'
          if v + 1 <= max
            onChange? v + 1
        when 'ArrowLeft'
          if v - 1 >= 0
            onChange? v - 1

  ({
    value
    onChange
    size = 'normal' #normal, xs
    focused
    focus
    handleKeyDown
    max = 5
  }) ->
    readonly = not onChange?

    React.createElement("div", { \
      "className": "Questions__fieldRating",  \
      "tabIndex": "0",  \
      "onFocus": (-> focus true),  \
      "onBlur": (-> focus false),  \
      "onKeyDown": (handleKeyDown)
    },
      ([0...max].map (i) ->
        selected = value >= i + 1
        React.createElement("span", { \
          "className": (cx "Questions__fieldRating-item", size,
            readonly: readonly
            'fas fa-star': selected
            'fal fa-star': !selected
          ),  \
          "onClick": (-> onChange? i + 1),  \
          "key": (i)
        })
      )
    )
