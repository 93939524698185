import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import posthog from 'posthog-js';

const config = window.config && window.config.posthog;

const PosthogTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if(!config) return;

    posthog.init(config.key, {
      api_host: config.host,
      autocapture: false,
      opt_in_site_apps: true,
    });

    posthog.register_once({
      'App Environment': config.env,
    });
  }, []);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  return children;
};

const identify = (user) => {
  if (user && user.organization) {
    posthog.identify(user.slug, {
      slug: user.slug,
      org_code: user.organization.code,
    });
    posthog.group('company', user.organization.code, {
      name: user.organization.name,
      code: user.organization.code,
    });
  }
};

const resetUser = () => {
  if(posthog.__loaded) {
    posthog.reset();
  }
};

export default PosthogTracker;
export { identify, resetUser };
