module.exports =
  ANIMALS: [
    'crock'
    'bear'
    'lion'
    'dolphin'
    'duck'
    'chick'
    'elephant'
    'commonfox'
    'giraffe'
    'lizard'
    'sheep'
    'octopus'
    'robin'
    'panda'
    'penguin'
    'pig'
    'bunny'
    'rhino'
    'seal'
    'sloth'
    'tiger'
    'beaver'
    'fox'
    'zebra'
  ]

  DRIVER_OVERVIEW_EXPLICATION:
    "Your purpose statement is shorthand to remind you and other people what you stand for in your
    work and life. Imperative determined your purpose statement based on your preferences in the
    Purpose Assessment for who you seek to impact, how you seek to make an impact and why
    you seek to make an impact."

  DRIVER_OVERVIEW_DOMINANT: (style) ->
    "You had the strongest preference for this driver. It is likely a stronger
    source of purpose than the other two and that you are an #{style.toUpperCase()}-DRIVEN-LEADER.
    Learn more about how you can lead with purpose on the Imperative platform."

  DRIVERS:
    who: [
      "Individual"
      "Organization"
      "Society"
    ]
    why: [
      "Karma"
      "Harmony"
    ]
    how: [
      "Human"
      "Community"
      "Structure"
      "Knowledge"
    ]

  DRIVER:
    who:
      "Everyone wants to make an impact in their work and lives. We each have a preference for
      what elevation of impact is most meaningful. When we work at our preferred elevation we
      are the most inspired."
    why:
      "What does a just world look like? The answer stems from your values and sense of what drives
      progress. It is at the core of your purpose and defines your sense of what is fair."
    how:
      "We each have a preference for how we seek to solve problems and add value. This preference is
      based on how we believe change happens, the talents we naturally bring to our work and the
      role we find most fulfilling."

  DRIVERS_OVERVIEW_QUESTIONS:
    who: "WHO do you want to impact?"
    why: "WHY is change needed?"
    how: "HOW do you like to solve problems?"

  AGENDA:
    title: "In this report"
    items: ({leadershipVisibility = false, volunteeringVisibility = false} = {}) ->
      [
        title: "Your In-depth Purpose Results"
        subitems: [
          "Purpose Type"
          "Drivers"
        ]
      ,
        title: "Your Purpose in Action"
        subitems: [
          "Leadership Style" if leadershipVisibility
          "Volunteering + Philanthropy" if volunteeringVisibility
          "Potential Biases"
        ]
      ]

  PURPOSE:
    intro:
      """
      When you orient your work around your purpose, it strengthens your relationships, increases
      your impact, and accelerates your growth. Here are six steps you can take to truly own and
      activate your personal purpose using Imperative.
      """
    arrows: [
        title: 'Voice Your Purpose'
        icon: 'bullhorn'
        text:
          """
          Use the Purpose Statement Builder to personalize and define your purpose in
          your own words. Doing this will help you understand and communicate your
          purpose with greater clarity, and provide brief but powerful insight into what matters most to you.
          """
      ,
        title: 'Purpose-Power Yourself'
        icon: 'laptop'
        text:
          """
          Explore the insights in your purpose assessment and consider how your drivers
          connect to your work, growth opportunities, potential biases, and relationships.
          How can you use these insights to leverage your purpose in a meaningful way?
          """
      ,
        title: 'Purpose-Power Your Team'
        icon: 'sitemap'
        text:
          """
          Get to know your team on a whole new level by understanding how purpose
          shows up at the individual and group levels. Develop your own insights to rally
          your team around a shared purpose and strategize so you can work together more effectively.
          """
      ,
        title: 'Purpose-Power Your Leadership Style'
        icon: 'child'
        text:
          """
          Step into your authentic leadership style by embracing your dominant purpose
          driver. What can you learn by exploring your unique strengths and struggles
          to be a more effective leader? Challenge yourself to know yourself and others in a more meaningful way.
          """
      ,
        title: 'Purpose-Power Your Role in the Community'
        icon: 'users'
        text:
          """
          How can you contribute your purpose to the community? Get direction for
          aligning your values with any potential volunteering opportunities and unlock
          deeper levels of fulfillment outside of work.
          """
      ,
        title: 'Make Purpose a Habit'
        icon: 'calendar'
        text:
          """
          Track your fulfillment every week and learn what brings meaning to your daily
          life and work. Based on your assessment results, goals, and current
          level of fulfillment, you will get weekly recommendations for how to thrive.
          """
    ]

  INFO:
    paragraphs: [
      """
      The Imperative Personal Purpose Profile has been used by people around the globe and across every industry to
      gain insights into how they derive and create meaning, motivation, and fulillment in their careers.
      """
      """
      The assessment that generated this report was developed in collaboration with academics and employers to be a
      valid instrument to develop your self-awareness about what creates meaning in your work as well as specifc
      recommendations on how to design your work intentionally to thrive.
      """
      """
      You have one of 24 different purpose types - combinations of the three dimensions. Your results and recommendations
      are even more specific for your given dominant driver, the one where you had the strongest preference. There are
      216 different Personal Purpose Profiles generated by the assessment including yours.
      """
      """
      The results are based on identifying your preferences within three dimensions of purpose with a total of nine drivers
      between them. Your preference for a given driver falls on a continuum between the different options. The one that
      is showing up on your report under each dimension is the one for which you expressed the highest preference.
      """
      """
      The reliability of the results is dependent on the strength of your preference within each dimension. Your dominant
      driver is likely to not change during your career but the other drivers might depending on your degree of preference.
      """
      """
      This is not a personality assessment and there is no correlation between your personality (e.g. being introverted
      or extroverted) and your purpose. This assessment is designed to help define purpose for a range of workforce
      orientations and help guide people regardless of their embrace of purpose overall.
      """
    ]
    agenda:
      title: "All rights reserved"
      items: [
          title: """
          No part of this publication may be reproduced, distributed, or transmitted in any form or by any
          means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of
          the publisher, except inthe case of brief quotations embodied in critical reviews and certain other non-commercial uses permitted by copyright law.
          """
        ,
          title: "Requests for permission may be sent to info@imperative.com"
      ]
