import React from 'react'

initials = ({firstName, lastName, slug}) ->
  if firstName? and lastName?
    "#{firstName.charAt 0}#{lastName.charAt 0}"
  else
    slug.slice 0, 2

export default ({user}) ->
  dominant = user?.pattern?.dominant?.toLowerCase()

  React.createElement("span", {"className": "SelfAssessmentReport__avatar"},
    (if user?.avatars?
      React.createElement("span", { \
        "className": "SelfAssessmentReport__avatarPhoto",  \
        "style": ({backgroundImage: "url(#{user?.avatars.normal})"})
      })
    else
      React.createElement("span", {"className": "SelfAssessmentReport__avatarInitials"},
        (initials user)
      )
    )
  )
