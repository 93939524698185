`/** @jsx R.DOM */`
import R from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import {replace} from 'ramda'
import t from 'translations'
import {URL_DRIVERS, IMG_LOGO_BLUE_MOBILE} from 'theme/assets/assets'

import {DRIVER_OVERVIEW_EXPLICATION, DRIVER_OVERVIEW_DOMINANT} from './content'

DRIVERS = ['who', 'why', 'how']

DRIVERS_MAP =
  who: 'Impact',
  why: 'Values'
  how: 'Craft'


export default ({report, user, t}) ->
  R.createElement("div", {"className": "ShortReport__driverOverview"},
    R.createElement("h1", {"className": "ShortReport__subtitle"}, (t.find 'report.shortReport.driverOverview.subtitle')),
    R.createElement("p", {"className": "ShortReport__text ShortReport__text_margin-top"},
      (t.find 'report.shortReport.driverOverview.explication')
    ),
    R.createElement("div", {"className": "ShortReport__driverOverviewPurpose"},
      R.createElement("div", {"className": "ShortReport__row"},
        R.createElement("h3", null,
          (report?.imperative.prefix.your),
          R.createElement("span", {"className": "ShortReport__driverOverviewPurposeValue ShortReport__driverOverviewPurpose_who"},
            (report?.imperative.who)
          )
        ),
        R.createElement("h5", {"className": "ShortReport__driverOverviewPurpose_who"}, (t.find 'report.shortReport.Impact.name'), ": ", (report?.drivers.who.title))
      ),
      R.createElement("div", {"className": "ShortReport__row"},
        R.createElement("h3", null,
          R.createElement("span", {"className": "ShortReport__driverOverviewPurposeValue ShortReport__driverOverviewPurpose_why"},
            (report?.imperative.why)
          )
        ),
        R.createElement("h5", {"className": "ShortReport__driverOverviewPurpose_why"}, (t.find 'report.shortReport.Values.name'), ": ", (report?.drivers.why.title))
      ),
      R.createElement("div", {"className": "ShortReport__row"},
        R.createElement("h3", null,
          R.createElement("span", {"className": "ShortReport__driverOverviewPurposeValue ShortReport__driverOverviewPurpose_how"},
            (report?.imperative.how)
          )
        ),
        R.createElement("h5", {"className": "ShortReport__driverOverviewPurpose_how"}, (t.find 'report.shortReport.Craft.name'), ": ", (report?.drivers.how.title))
      )
    ),

    R.createElement("div", {"className": "ShortReport__driverOverviewDrivers"},
      R.createElement("div", {"className": "ShortReport__row"},
        (DRIVERS.map (driver) ->
          R.createElement("div", {"className": "ShortReport__columnThree ShortReport__driverOverviewDriversColumn", "key": (driver)},
            R.createElement("img", {"src": ("#{URL_DRIVERS}/#{driver}#{user?.pattern[driver]}.png"), "alt": (user?.pattern[driver])}),
            R.createElement("h4", {"className": "ShortReport__driverOverviewPurpose_#{driver}"},
              (t.find "report.shortReport.#{DRIVERS_MAP[driver]}.name"), ": ", (report?.drivers?[driver].title)
            ),
            R.createElement("p", {"className": "ShortReport__text"},
              (report?.drivers?[driver].description)
            )
          )
        )
      ),
      R.createElement("div", {"className": "ShortReport__row"},
        (DRIVERS.map (driver) ->
          if report?.drivers?[driver].dominant
            R.createElement("div", {"className": "ShortReport__columnThree ShortReport__driverOverviewDriversColumn_dominant", "key": (driver)},
              R.createElement("h4", {"className": "ShortReport__driverOverviewPurpose_dominant"},
                (t.find 'report.shortReport.driverOverview.dominant')
              ),
              R.createElement("p", {"className": "ShortReport__text"},
                (t.find "report.shortReport.driverOverview.dominantdriver", report)
              ),
              R.createElement("img", {"src": (IMG_LOGO_BLUE_MOBILE), "className": "ShortReport__driverOverviewLogo", "alt": "Imperative logo"})
            )
          else
            R.createElement("div", {"className": "ShortReport__columnThree", "key": (driver)})
        )
      )
    )
  )
