import api from 'api';

export const inviteColleagues = (dto) => {
  return api.post("/colleagues/invite", dto);
}

export const getInvitations = (memberSlug) => {
  return api.get(`/colleagues/${memberSlug}/invitations`);
}

export const fetchActivityFeed = (params = {}) => {
  return api.get('/activity-feed', {params});
}

export const fetchActivity = (activityId) => {
  return api.get(`/activity-feed/${activityId}`);
}

export const postReaction = (activityId, reaction) => {
  return api.post(`/activity/${activityId}/reaction`, {reaction});
}

export const deleteReaction = (activityId, reaction) => {
  return api.delete(`/activity/${activityId}/reaction/${reaction}`);
}

export const postComment = (activityId, comment) => {
  return api.post(`/activity/${activityId}/comment`, {comment});
}

export const updateComment = (activityId, commentId, comment) => {
  return api.patch(`/activity/${activityId}/comment/${commentId}`, {comment});
}

export const deleteComment = (activityId, commentId) => {
  return api.delete(`/activity/${activityId}/comment/${commentId}`);
}

export const fetchNotSeenEventsCount = () => {
  return api.get('/activity/notifications');
}

export const updateLastSeenTimestamp = () => {
  return api.patch('/activity/notifications');
}

export const fetchCommunityMembers = (params = {}) => {
  //params={page, size, query, sort[Created, JobFunction, Connections, Conversations, Commitments]}
  return api.get('/community/members', {params});
}

export const fetchMember = (memberId) => {
  return api.get(`/community/members/${memberId}`);
}

export const getJobFunctions = () => {
  return api.get("/dictionaries/JobFunction");
}
export const getAssessmentData = () => {
  return api.get(`${api.surveys}/purpose-assessment`);
}